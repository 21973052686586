import axios from "axios";
import { handleError } from "./Errors";
import { DeviceType } from "src/utils/constants";

interface Update{
    version: number,
    rs: string,
    errorOnHardwareFailure?: boolean,
    skipHardwareCheck?: boolean,
    override?: boolean,
    baseInstall?: boolean,
}
interface NTRIP{
    username: string;
    password: string;
    address: string;
    port: number;
    mountpoint: string;
    version: number;
}
export interface Command {
    robotId: number,
    type: string,
    deviceType:number,
    args: Update | NTRIP,
}

export interface CommandReturn {
    id: number,
    robotId: number,
    type: string,
    args: {
        address?: string;
        rs: string,
    },
    issuedAt: string,
    startedAt: string,
    completedAt: string,
    byUser: string,
}

export interface CommandLog {
    id: number;
    commandId: number;
    message: string;
    createdAt: string;
    error: boolean;
}

export interface RobotVersion {
    value: string,
    timestamp: number,
    released: boolean,
    latest: boolean,
    access: boolean,
}



export default class CommandService {
    static async getCommands(command: Omit<Command,"args">): Promise<CommandReturn[]> {
        try {
            const response = await axios.get("/api/commands?robotId="+command.robotId+"&type="+command.type+"&deviceType="+command.deviceType);
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async postCommand(command: Command): Promise<void> {
        try {
            await axios.post("/api/commands",command);
        } catch (err) {
            return handleError(err, true);
        }
    }
    
    static async getLogs(commandId: number): Promise<CommandLog[]> {
        try {
            const response = await axios.get("/api/commands/logs/"+commandId);
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async cancelCommand(commandId: number): Promise<void> {
        try {
            await axios.delete("/api/commands/"+commandId);
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getRobotVersions(robotId: number): Promise<RobotVersion[]> {
        try {

            const response = await axios.get("/api/commands/robotVersions/"+robotId);
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getRobotsVersions(robotId: number[]): Promise<RobotVersion[]> {
        try {

            const response = await axios.get("/api/commands/robotVersions?robotId="+(robotId.join("&robotId=")));
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getRobotLatestVersion(robotId: number): Promise<string> {
        try {

            const response = await axios.get("/api/commands/robotVersions/"+robotId+"/latest");
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getBaseStationVersions(): Promise<RobotVersion[]> {
        try {

            const response = await axios.get("/api/commands/baseStationVersions/");
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getCommandAccessForRobot(robotId: number, deviceType: DeviceType): Promise<string[]> {
        try {
            const response = await axios.get("/api/commands/access/?robotId="+robotId+"&deviceType="+deviceType);
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getCommandAccessForRobots(robotId: number[], deviceType: DeviceType): Promise<string[]> {
        try {
            const response = await axios.get("/api/commands/deviceAccess/?robotId="+(robotId.join("&robotId="))+"&deviceType="+deviceType);
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
   
}
