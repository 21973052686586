import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { signOut } from "../redux/auth/actions";

import * as errors from "../services/Errors";

export type ValidationErrorHandler = (
    param: string,
    error: { type: string; message: string; types?: unknown }
) => void;

export interface ErrorHandlerOptions {
    authenticate?: boolean;
    onValidationError?: (
        param: string,
        error: { type: string; message: string; types?: unknown }
    ) => void;
    fallback?: (e? : any) => void;
}

export default function useErrorHandler(options: ErrorHandlerOptions = {}) {
    const { authenticate = true, onValidationError, fallback } = options;
    const dispatch = useDispatch();

    return useCallback(
        (error: any) => {
            if (authenticate && error instanceof errors.AuthenticationError) {
                dispatch(
                    signOut("Your session has expired. Please sign in again.")
                );
                return true;
            } else if (
                onValidationError !== undefined &&
                error instanceof errors.ValidationError
            ) {
                const valErrors = error.errors;
                valErrors.forEach((e) => {
                    onValidationError(e.param, {
                        type: "manual",
                        message: e.msg,
                    });
                });
                return true;
            } else if (fallback !== undefined) {
                fallback(error);
                return true;
            }
            return false;
        },
        [authenticate, onValidationError, fallback, dispatch]
    );
}
