import { useLayoutEffect, useCallback } from "react";
import {
    SharedState,
    SharedStateAction,
    SharedStateInstance,
    SharedStateHooks,
} from "src/components/SharedStateProvider";
import { useAsync } from "src/hooks";
import { UseReload } from "./useReload";
import ConfigurationsService from "src/services/ConfigurationsService";
import { useSelector } from "react-redux";
import { userAccessSelector } from "src/redux/auth/selectors";

export interface UseDeprecatedAppVersions {
    state: State;
}

export default function useAppVersions(hooks: SharedStateHooks) {
    hooks.useReducer.push(reducer);
    hooks.useInstance.push(useInstance as any);
}

const LOAD_DEPRECATED_APP_VERSIONS = "LOAD_DEPRECATED_APP_VERSIONS" as const;
const LOADED_DEPRECATED_APP_VERSIONS = "LOADED_DEPRECATED_APP_VERSIONS" as const;

const loadDeprecatedAppVersions = () => ({ type: LOAD_DEPRECATED_APP_VERSIONS });
const loadedDeprecatedAppVersions = (dto?: string[], error?: any) => ({
    type: LOADED_DEPRECATED_APP_VERSIONS,
    payload: {
        dto,
        error,
    },
});

type State = SharedState<{
    adeprecatedAppVersions: {
        dto: string[];
        loading: boolean;
        loaded: boolean;
        error?: any;
    };
}>;
type Action = SharedStateAction<
    | ReturnType<typeof loadedDeprecatedAppVersions>
    | ReturnType<typeof loadDeprecatedAppVersions>
>;

function reducer(state: State, action: Action): State {
    if (action.type === "INIT") {
        return {
            ...state,
            deprecatedAppVersions: {
                dto: [],
                loading: false,
                loaded: false,
            },
        };
    }

    if (action.type === LOAD_DEPRECATED_APP_VERSIONS) {
        return {
            ...state,
            deprecatedAppVersions: {
                ...state.deprecatedAppVersions,
                loading: false,
                loaded: false,
            },
        };
    }

    if (action.type === LOADED_DEPRECATED_APP_VERSIONS) {
        const { dto, error } = action.payload;

        if (error) {
            return {
                ...state,
                deprecatedAppVersions: {
                    ...state.deprecatedAppVersions,
                    loading: false,
                    loaded: false,
                    error,
                },
            };
        }

        return {
            ...state,
            deprecatedAppVersions: {
                dto: dto!,
                loaded: true,
                loading: false,
            },
        };
    }

    return state;
}

function useInstance(instance: UseReload & SharedStateInstance) {
    const {
        dispatch,
        state: {
            deprecatedAppVersions: { error: stateError },
        },
    } = instance;

    const tabletDeprecatedAppVersionsAccess=useSelector(userAccessSelector).includes('TabletDeprecatedAppVersion')

    const getDeprecatedAppVersions = useCallback(() => {
        if (tabletDeprecatedAppVersionsAccess)
            return ConfigurationsService.getDeprecatedAppVersions().catch(() => {return [];});
        else
            return Promise.resolve([]);
    }, [tabletDeprecatedAppVersionsAccess]);

    const { value, error, exec, pending } = useAsync(getDeprecatedAppVersions, {
        defaultPending: true,
    });

    useLayoutEffect(() => {
        if (pending) {
            dispatch(loadDeprecatedAppVersions());
        } else if (value) {
            dispatch(loadedDeprecatedAppVersions(value));
        } else if (error) {
            dispatch(loadedDeprecatedAppVersions(undefined, error));
        }
    }, [value, pending, error, dispatch]);

    if (stateError !== undefined) {
        instance.getHooks().useReload.push(exec);
    }

    instance
        .getHooks()
        .reloadingDeps.push((deps) => [
            ...deps,
            pending && stateError !== undefined,
        ]);
    instance
        .getHooks()
        .shouldReloadDeps.push((deps) => [...deps, stateError !== undefined]);
}
