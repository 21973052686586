import React, { createContext, useMemo, useContext, useReducer } from "react";
import type { DeviceDetails, DetailedDeviceDto, DeviceKeyGroup, DeviceData } from "src/services/DeviceDataService";
import type { TranslationsDictionary } from "src/views/Devices/TranslationProvider"
import type { BulkedEvent, DataPayload } from "src/events";
import deviceName from "src/utils/deviceName";

export interface CommonSection {
    dictionary: TranslationsDictionary;
    deviceName: string;
    deviceTypeId: number;
    groups: DeviceKeyGroup[];
}

export type PrimarySection = CommonSection & { device: DetailedDeviceDto };

export type SecondarySection = CommonSection & {
    devices: Record<number, DetailedDeviceDto>;
    deviceIds: number[];
};

export interface DashboardState {
    primary: PrimarySection;
    secondary?: SecondarySection;
}

export function initState(details: DeviceDetails): DashboardState {
    const mapKeyGroups = (grps: DeviceKeyGroup[]): DeviceKeyGroup[] => {
        // No group
        if (grps.length === 1 && grps[0].id === -1) {
            return [{ ...grps[0], name: "Uncategorized Data" }];
        }
        if (details.primary.deviceTypeId === 3) {
            const uncategorized = grps.find(g => g.id === -1);
            if (uncategorized !== undefined)
                uncategorized.name = "Uncategorized Keys";
            return grps;
        }
        return grps.filter((k) => k.id !== -1);
    };

    const { primary, secondary } = details;

    const {
        dictionary = {},
        groups = [],
        deviceTypeId,
        ...primaryDto
    } = primary;

    const ret: DashboardState = {
        primary: {
            deviceTypeId,
            dictionary,
            deviceName: deviceName(primary.deviceTypeId),
            groups: mapKeyGroups(groups),
            device: primaryDto,
        },
    };

    if (secondary) {
        ret.secondary = {
            deviceTypeId: secondary.deviceTypeId,
            dictionary: secondary.dictionary || {},
            deviceName: deviceName(secondary.deviceTypeId),
            groups: mapKeyGroups(secondary.groups || []),
            deviceIds: secondary.deviceIds,
            devices: {},
        };

        ret.secondary.deviceIds.forEach((id) => {
            const secondaryDto = secondary.devices[id];
            ret.secondary = {
                ...ret.secondary!,
                devices: {
                    ...ret.secondary!.devices,
                    [id]: secondaryDto,
                },
            };
        });
    }

    return ret;
}

export const update = (bulkedEvents: BulkedEvent<DataPayload>) => ({
    type: "UPDATE" as const,
    payload: { bulkedEvents },
});

export const init = (details: DeviceDetails) => ({
    type: "INIT" as const,
    payload: details,
});

type Action = ReturnType<typeof update> | ReturnType<typeof init>;

function mapUpdatedValues(
    deviceTypeId: number,
    deviceId: number,
    data: { [key: string]: DeviceData },
    entries: [string, string, number, number][]
): (DeviceData & { newKey: boolean })[] {
    return entries.flatMap(([key, value, created, inserted]) => {
        if (data[key]?.value === value) {
            return [];
        }
        const newKey = data[key] === undefined;
        return [
            {
                key,
                value,
                deviceTypeId,
                deviceId,
                created,
                inserted,
                newKey,
            },
        ];
    });
}

function filterValidEvents(
    event: BulkedEvent<DataPayload>,
    { primary, secondary }: DashboardState
) {
    const { events } = event;

    const primaryDeviceType = primary.deviceTypeId;
    const primaryDeviceId = primary.device.deviceId;

    const secondaryDeviceType: number =
        secondary?.deviceTypeId ?? ("NO_DEVICE_TYPE" as any);
    const secondaryDevices = secondary?.devices || {};

    // Filter valid events
    return events.flatMap(({ payload: { type, id, entries } }) => {
        if (type === primaryDeviceType && id === primaryDeviceId) {
            return mapUpdatedValues(type, id, primary.device.data, entries);
        }

        if (type === secondaryDeviceType && secondaryDevices[id]) {
            return mapUpdatedValues(
                type,
                id,
                secondaryDevices[id].data,
                entries
            );
        }

        return [];
    });
}

function reducer(state: DashboardState, action: Action): DashboardState {
    if (action.type === "INIT") {
        return initState(action.payload);
    }

    if (action.type === "UPDATE") {
        const validUpdates = filterValidEvents(
            action.payload.bulkedEvents,
            state
        );

        if (validUpdates.length) {
            return validUpdates.reduce((s, u) => {
                if (u.deviceTypeId === state.primary.deviceTypeId) {
                    return {
                        ...s,
                        primary: {
                            ...s.primary,
                            device: {
                                ...s.primary.device,
                                data: {
                                    ...s.primary.device.data,
                                    [u.key]: u,
                                },
                            },
                        },
                    };
                }

                return {
                    ...s,
                    secondary: {
                        ...s.secondary!,
                        devices: {
                            ...s.secondary!.devices,
                            [u.deviceId]: {
                                ...s.secondary!.devices[u.deviceId],
                                data: {
                                    ...s.secondary!.devices[u.deviceId]
                                        .data,
                                    [u.key]: u,
                                },
                            },
                        },
                    },
                };
            }, state);
        }
    }

    return state;
}

const DashboardContext = createContext<{state: DashboardState; dispatch: (action: Action) => void;}>(undefined!);

export default function DashboardProvider({
    details,
    children,
}: React.PropsWithChildren<{
    details: DeviceDetails;
}>) {
    const [state, dispatch] = useReducer(reducer, details, initState);

    const context = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <DashboardContext.Provider value={context}>
            {children}
        </DashboardContext.Provider>
    );
}

export function useDashboard() {
    return useContext(DashboardContext);
}
