import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";

import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import { useWidgetState } from "../plugins";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Tablet } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { customersSelector, tabletsSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import useDialog from "src/hooks/useDialog";
import { userAccessSelector } from "src/redux/auth/selectors";
import { FormControlLabel, Switch } from "@material-ui/core";

interface SetTabletAppVersionInput {
    tablet: Tablet | null;
    appVersion: string | null;
    oneTime: boolean ;
}

export default function SetTabletAppVersionWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        reset,
    } = useForm<SetTabletAppVersionInput>({
        defaultValues: { tablet: null, appVersion: null,oneTime: true },
    });

    const {
        state: { appVersions, tabletAppVersions, deprecatedAppVersions },
        setTabletAppVersion
    } = useWidgetState();
    const tablets = useSelector(tabletsSelector);
    const loadedDeviceInfo = tablets.length > 0;
    const { displayToast } = useToast();
    const customersDto = useSelector(customersSelector).dto;
    const { displayDialog } = useDialog();
    const [showDeprecated, setShowDeprecated] = useState(false);
    const tabletDeprecatedAppVersionAccess = useSelector(userAccessSelector).includes("TabletDeprecatedAppVersion")
    const [setTabletAppVersionWidgetLastTabletId,setSetTabletAppVersionWidgetLastTabletId]=useLocalStorage("SetTabletAppVersionWidgetLastTabletId",-1)
  
    useEffect(()=>{
        if (saveChanges){
            if (setTabletAppVersionWidgetLastTabletId){
                const tblt=tablets.find(t=>t.id===setTabletAppVersionWidgetLastTabletId)
                if (tblt){
                setValue('tablet',tblt)
                setValue("appVersion", tabletAppVersions.dto[tblt.id] ?? "UNLOCKED");}
            }

            
        }

    },[saveChanges,setValue,tablets,setTabletAppVersionWidgetLastTabletId,tabletAppVersions])

    const onComplete = useCallback(
        (input: SetTabletAppVersionInput) => {
            setTabletAppVersion(input.tablet!.id, input.appVersion!,input.oneTime!);
            if (!saveChanges)
            reset();
            displayToast({
                message: (input.appVersion===undefined||input.appVersion===null||input.appVersion==="")
                    ?`Successfully unlocked the app version of Tablet ${input.tablet!.id}`
                    :`Successfully locked the app version of Tablet ${input.tablet!.id}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [setTabletAppVersion, reset, displayToast,saveChanges]
    );

    const { exec, pending: settingAppVersion, error } = useAsync(
        async (input: SetTabletAppVersionInput) => {
            input.appVersion=input.appVersion==="UNLOCKED"?null:input.appVersion;
            const { tablet, appVersion,oneTime } = input;
            return ((displayDialog({negativeButtonText:"cancel",positiveButtonText:"continue",dialogText:(input.appVersion===undefined||input.appVersion===null||input.appVersion==="")
            ?`Unlock the app version of Tablet ${input.tablet!.id} ?`
            :`Lock the app version of Tablet ${input.tablet!.id} to ${input.appVersion}?`}))
            .then(r => {
                if(r)
                {
                    ConfigurationsService.setTabletAppVersion(tablet!.id, appVersion!,oneTime!);
                }
                else
                {
                    displayToast({
                        message: `Tablet version lock cancelled.`,
                        severity: "warning",
                        withCloseIcon: true,
                    });
                    throw new Error("");
                }

            }))
            .then(() =>{return input})
            
        },
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = settingAppVersion || !loadedDeviceInfo || !appVersions.loaded || !tabletAppVersions.loaded;

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Lock tablet to app version"
            subTitle="Lock the app version of your tablets. Selecting a tablet will display its locked app version."
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            {tabletDeprecatedAppVersionAccess && 
            <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"5px"}}>
                <FormControlLabel
                    control={
                        <Switch
                            size="small"
                            checked={showDeprecated}
                            onChange={(ev) => {
                                setShowDeprecated(ev.target.checked);
                            }}
                        />
                    }
                    label="Show deprecated versions"
                />
            </div>
            }
            <Controller
                name="tablet"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            disabled={loading}
                            options={tablets}
                            getOptionLabel={(t) => {
                                const customer = customersDto[t.customerId ?? -1];
                                let ret = t.id;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+t.customerId+"]";
                                return ret;
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                            noOptionsText="No such tablet"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Tablet"
                                        placeholder="Choose a tablet"
                                        required
                                        {...getErrorProps(errors, "tablet")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (val !== null)
                                    setValue("appVersion", tabletAppVersions.dto[val.id] ?? "UNLOCKED");
                                else
                                    setValue("appVersion", null);

                                if (saveChanges){
                                   setSetTabletAppVersionWidgetLastTabletId(val.id)
                                }
                                
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="appVersion"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...props }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...props}
                            disabled={loading}
                            options={["UNLOCKED", ...(showDeprecated ? deprecatedAppVersions.dto : appVersions.dto)]}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label={"App Version"}
                                        required
                                        {...getErrorProps(errors, "appVersion")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                onChange(val);
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="oneTime"
                control={control}
                render={(props) => {
                    return (
                        <FormControlLabel
                            control={
                                <Switch
                                checked={(props.value)}
                                onChange={(ev)=>setValue("oneTime",ev.target.checked)}
                                disabled={loading}
                                id={"oneTime"}
                                />
                            }
                            label="Unlock after update"
                            style={{width:"100%",margin:"10px 0"}}
                        />
                    );
                }}
            />
        </BaseWidget>
    );
}
