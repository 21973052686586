import moment from "moment";
import { DeviceData } from "src/services/DeviceDataService/types";
import { DeviceData as Device} from "src/views/HistoryOverview/HistoryOverview";


export default function generateCvs(statusData:Record<string, DeviceData>){
    let csvContent='data:Application/octet-stream,\r\n;'

    Object.keys(statusData).forEach((key)=>{
        const k:string=statusData[key]['key'];
        const v:string=statusData[key]['value']
         csvContent+=`"${k}";"${v}";\r\n;`;
    })
    return encodeURI( csvContent);
}

export  function generateCsvArray(data:Device[]){
    let csvContent='data:Application/octet-stream,\r\n;'
    csvContent+=`"Device Id:";"${data[0]?.data_key ?? "Field"}:";"Created:";\r\n;`
    data.forEach((element) => {
        csvContent+=`"${element.device_id}";"${element.data_val}";"${element.created}";\r\n;`;        
    });
    
    return encodeURI( csvContent);
}
export  function generateCsvArrayDeviceData(data:DeviceData[]){
    let csvContent='data:Application/octet-stream,\r\n;'
    csvContent+=`"key";"value";"created";"inserted";\r\n;`
    data.forEach((element) => {
        csvContent+=`"${element.key}";"${element.value}";"${moment(element.created*1000).utc().format("YYYY-MM-DD HH:mm:ss")}";"${moment(element.inserted*1000).utc().format("YYYY-MM-DD HH:mm:ss")}";\r\n;`;        
    });
    
    return encodeURI( csvContent);
}
export  function generateCsvArrayAny(data:Array<any>){
    let csvContent='data:Application/octet-stream,\r\n;'
    const columnHeaders = Object.getOwnPropertyNames(data[0] ?? {}).map(c => '"'+c+'"').join(";") + ";";
    csvContent+=`${columnHeaders}\r\n;`
    data.forEach((element) => {
        const csvElement = Object.values(element)
        .map(val => {
            if(moment(val as any, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid())
                return '"' + moment(val as any).utc().format("YYYY-MM-DD HH:mm:ss.SSS") + '"';
            return '"' + val + '"';
        })
        .join(";") + ";";

        csvContent+=`${csvElement}\r\n;`;        
    });
    return encodeURI( csvContent);
}