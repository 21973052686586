import axios from "axios";
import qs from "qs";
import * as types from "./types";
import FileService from "../FileService";
import { handleError, NotFoundError } from "../Errors";
import { TranslationsDictionary } from "src/views/Devices/TranslationProvider";

type LatestDataResult2<T> = { data: T[] };
type LatestDataResult1<T> = {
    data: T[];
    keys: string[];
    dictionary: TranslationsDictionary;
};

interface FilterResult<Data, Filter> {
    filter: Omit<Filter, "limit" | "skip">;
    pageOptions: { skip: number; limit: number };
    filteredCount: number;
    totalCount: number;
    pageCount: number;
    data: Data[];
}

interface DataFilterOptions {
    skip: number;
    limit: number;
    includeKeys?: string[];
    orderBy?: [
        keyof Omit<types.DeviceData, "deviceTypeId" | "deviceId">,
        "asc" | "desc"
    ][];
    notBefore?: Date;
    notAfter?: Date;
    timestampFilterColumn?: "created" | "inserted";
    globalSearch?: string;
}

interface TabletConnectionsFilterOptions {
    skip: number;
    limit: number;
    orderBy?: ["id" | "timestamp", "asc" | "desc"][];
    notBefore?: Date;
    notAfter?: Date;
}

export interface LayoutsFilterOptions {
    skip: number;
    limit:number;
    orderBy?: ["id"| "original_id"|"name"|"customer"|"template"|"generated"|"date_from"|"date_to"|"created_by_user", "desc" | "asc"][];
    notBefore?: moment.Moment;
    notAfter?: moment.Moment;
    globalSearch?:string;
}
export interface Layout{
    id: number;
    original_id: number;
    name: string;
    customer: number;
    template: string;
    generated: string;
    date_from: number;
    date_to: number;
    created_by_user: number;
}


export interface TemplatesDto {
    tabletId: number,
    groups: TemplateGroup[],
}

export interface TemplateGroup {
    name: string,
    purchased: boolean,
    templates: string[],
    counts: number[]
}

export default class DeviceDataService {
    static async getLatestDataFormatted(
        deviceType: number,
        opts?: { deviceId?: number; includeDictionary: true, includeAccessGroups?: boolean}
    ): Promise<LatestDataResult1<types.DeviceDto>>;
    static async getLatestDataFormatted(
        deviceType: number,
        opts?: { deviceId?: number; includeDictionary: false, includeAccessGroups?: boolean}
    ): Promise<LatestDataResult2<types.DeviceDto>>;
    static async getLatestDataFormatted(
        deviceType: number,
        opts: { deviceId?: number; includeDictionary?: boolean; includeAccessGroups?: boolean} = {}
    ) {
        const { deviceId, includeDictionary = true, includeAccessGroups = false } = opts;

        const path = `/api/data/${deviceType}/latest${
            deviceId !== undefined ? `/${deviceId}` : ""
        }?format=true&dictionary=${includeDictionary ? "true" : "false"}&accessgroups=${includeAccessGroups ? "true" : "false"}`;

        try {
            const response = await axios.get(path, {timeout: 200000});
            return response.data;
        } catch (err) {
            const res = (err as any).response;
            // Not found
            if (res && res.status === 404) {
                throw new NotFoundError(
                    "No data found for the specified device type"
                );
            }
            return handleError(err, false);
        }
    }

    static async getLatestData(
        deviceType: number,
        opts?: { deviceId?: number; includeDictionary: true }
    ): Promise<LatestDataResult1<types.DeviceData>>;
    static async getLatestData(
        deviceType: number,
        opts?: { deviceId?: number; includeDictionary: false }
    ): Promise<LatestDataResult2<types.DeviceData>>;
    static async getLatestData(
        deviceType: number,
        opts: { deviceId?: number; includeDictionary?: boolean } = {}
    ) {
        const { deviceId, includeDictionary = true } = opts;

        const path = `/api/data/${deviceType}/latest${
            deviceId !== undefined ? `/${deviceId}` : ""
        }?format=false&dictionary=${includeDictionary ? "true" : "false"}`;

        try {
            const response = await axios.get(path, {timeout: 200000});
            return response.data;
        } catch (err) {
            const res = (err as any).response;
            // Not found
            if (res && res.status === 404) {
                throw new NotFoundError(
                    "Data was not found for the specified device"
                );
            }
            return handleError(err, false);
        }
    }

    static async getDetailedDevice(
        deviceType: number,
        deviceId: number
    ): Promise<types.DeviceDetails> {
        try {
            const response = await axios.get(`/api/data/${deviceType}/${deviceId}?detailed=true`, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            const res = (err as any).response;
            // Not found
            if (res && res.status === 404) {
                throw new NotFoundError("The device was not found.");
            }
            return handleError(err, false);
        }
    }

    static async getData(
        deviceType: number,
        deviceId: number,
        options?: Partial<DataFilterOptions>,
        includeEdge = false,
    ): Promise<FilterResult<types.DeviceData, DataFilterOptions>> {
        if (includeEdge)
            options = {...options, includeEdge: true} as any;
        if (options && options.notAfter===undefined)
        options={...options,notAfter:(new Date())}
       
        if (options && options.notBefore===undefined)
        options={...options,notBefore:new Date(0)}

        

        const query = qs.stringify(options || {});

        try {
            const response = await axios.get(`/api/data/${deviceType}/${deviceId}${(query && "?" + query) || ""}`, {timeout: 60000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async downloadData(
        deviceType: number,
        deviceId: number,
        options?: Partial<Omit<DataFilterOptions, "skip" | "take">>,
        onProgress?: (ev: ProgressEvent) => void
    ): Promise<void> {
        const query = qs.stringify({ ...(options || {}), csv: true });
        return FileService.download(
            `/api/data/${deviceType}/${deviceId}?${query}`,
            {
                onProgress,
            }
        );
    }

    static async getTabletConnections(
        robotId: number,
        options?: Partial<TabletConnectionsFilterOptions>
    ): Promise<
        FilterResult<types.TabletConnection, TabletConnectionsFilterOptions>
    > {
        const query = qs.stringify(options || {});

        try {
            const response = await axios.get(`/api/data/tabletconnections/${robotId}${(query && "?" + query) || ""}`, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getLayouts(
        customer: number,
        options?: Partial<LayoutsFilterOptions>
    ): Promise<
        FilterResult<Layout, LayoutsFilterOptions>
    > {
        const query = qs.stringify(options || {});
        try {
            const response = await axios.get(`/api/data/layouts/${customer}${(query && "?" + query) || ""}`, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getBaseStationConnections(
        robotId: number,
        options?: Partial<TabletConnectionsFilterOptions>
    ): Promise<
        FilterResult<types.TabletConnection, TabletConnectionsFilterOptions>
    > {
        const query = qs.stringify(options || {});

        try {
            const response = await axios.get(`/api/data/basestationconnections/${robotId}${(query && "?" + query) || ""}`, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getDeviceInfo(): Promise<types.UserDeviceInfo> {
        try {
            const response = await axios.get("/api/data", {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async deleteHistoricData(deviceType : string | number, deviceId : number): Promise<void> {
        try {
            await axios.delete("/api/data/"+deviceType+"/"+deviceId+"/historic", {timeout: 200000});
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getTemplatesForTablet(
        tabletId: number,
    ): Promise<
        TemplatesDto
    > {
        try {
            const response = await axios.get("/api/data/templates/tablet/"+tabletId, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getTemplatesForCustomer(
        customerId: number,
    ): Promise<
        TemplateGroup[]
    > {
        try {
            const response = await axios.get("/api/data/templates/customer/"+customerId, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getAccessGroupsForRobot(robotId: number): Promise<Array<string>> {
        try {
            const response = await axios.get("/api/data/accessgroups/robot/"+robotId, {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getAccessGroupsForCustomer(customerId: number): Promise<Array<string>> {
        try {
            const response = await axios.get("/api/data/accessgroups/customer/"+customerId, {timeout: 20000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getDeviceDataKeys(): Promise<Array<string>>{
        try {
            const response = await axios.get("/api/data/devicedatakeys", {timeout: 200000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getProductModels() {
        try {
            const response = await axios.get("/api/data/productmodels");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

}

export * from "./types";
