import React, { useCallback, useMemo, useState, useRef, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Column, SortingRule, CellProps, Row, FilterProps, Filters, TableState, Hooks, useAsyncDebounce } from "react-table";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import VirtualizedTable from "src/components/VirtualizedTable";
import { useTranslator } from "../TranslationProvider";
import { NOT_IMPORTANT_KEYS, TimeFormats } from "src/utils/constants";
import { useAsync, useGetLatest, useToast } from "src/hooks";
import DeviceDataService, { DeviceData, Layout, TabletConnection } from "src/services/DeviceDataService";
import moment from "moment";
import { useSection } from "./SectionProvider";
import { TextField } from "@material-ui/core";
import { createSelector } from "reselect";
import { NumberColumnFilter, useDebouncedColumnFilter } from "src/components/FilteredTable/ColumnFilters";
import { timezone } from "src/App";
import { generateCsvArrayDeviceData } from "src/utils/csvGenerator";
import { useDispatch } from "react-redux";
import FilteredTable from "src/components/FilteredTable/FilteredTable";

const INSERTION_DELAY_TRESHOLD = 3600; //seconds

const useStyles = makeStyles((theme) => ({
    keyFilterOption: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1",
        minHeight: "auto",
        alignItems: "center",
        '&[aria-selected="true"]': {
            backgroundColor: "transparent",
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
        "& > :last-child": {
            marginLeft: "auto",
        },
        fontSize: theme.typography.pxToRem(13),
    },
    textField: {
        minWidth: 254,
    },
}));

function DateCell(cellProps : CellProps<DeviceData>) {
    const value = cellProps.cell.value;
    // This is used by the timestamp filter to render a 
    if (Array.isArray(value)) {
        return value
            .flatMap(
                (v: moment.Moment, i) =>
                
                    (v &&
                        `${i === 0 ? "Before: " : "After: "} ${moment(v).utcOffset(timezone).format(
                            TimeFormats.PresentationShort
                        )}`) ||
                    []
            )
            .join(" - ");
    }
    const dateString = moment.unix(value).utcOffset(timezone).format(TimeFormats.Presentation)+` (UTC${timezone>0?"+":""}${timezone===0?"":(timezone/60)})`;
    
    const cell = cellProps.cell;
    const row = cellProps.row;
    if ("created" in row.values &&
        "inserted" in row.values &&
        (cell.column.id === "created" || cell.column.id === "inserted") &&
        Math.abs(row.values.created - row.values.inserted) >= INSERTION_DELAY_TRESHOLD)
        return <div style={{ color:"red" }}>{dateString}</div>
    else
        return <>{dateString}</>;
}

function DataCell({
    cell: { value },
    column: { id },
    row: { values },
}: CellProps<DeviceData>) {
    const translator = useTranslator();
    const isKeyCell = id === "key";

    if (isKeyCell) {
        return <>{translator.translateKey(value)}</>;
    }

    const targetKey = values["key"];

    if (targetKey === "Location Link") {
        const datumYX = value.split(" ");
        const datumY = datumYX[0] || "";
        const datumX = datumYX[1] || "";

        const link =
            "https://www.google.com/maps/search/?api=1&query=" +
            datumY +
            "," +
            datumX;

        return (
            <Link href={link} target="_blank">
                Click Me
            </Link>
        );
    }

    return <>{translator.translate(targetKey, value)}</>;
}

function TimestampColumnFilter({
    column: { filterValue, setFilter },
}: FilterProps<DeviceData>) {
    const notBefore = (filterValue?.[0] && moment(filterValue[0])) || null;
    const notAfter = (filterValue?.[1] && moment(filterValue[1])) || null;
    return (
        <Box  style={{display:"flex",flex:"0 0 50%",width:"530px", gap:"5px"}}>
           
            {/* <Box> */}
                <KeyboardDateTimePicker
                    disableFuture
                    fullWidth
                    label="Not before"
                    margin="dense"
                    style={{width:"300px"}}
                    inputVariant="outlined"
                    value={notBefore}
                    onChange={(date) => {
                        if (notAfter === null || date?.isBefore(notAfter)) {
                            setFilter((old: any[] = []) => {
                                return [date, old[1]];
                            });
                        }
                    }}
                    maxDate={notAfter || undefined}
                    ampm={false}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    strictCompareDates
                    InputProps={{
                        readOnly: true,
                        endAdornment: notBefore !== null && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setFilter((old: any[]) =>
                                        old[1] ? [null, old[1]] : null
                                    );
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    KeyboardButtonProps={{ size: "small" }}
                    InputAdornmentProps={{ position: "start" }}
                />
            
                <KeyboardDateTimePicker
                    disableFuture
                    fullWidth
                    style={{width:"300px"}}
                    label="Not after"
                    inputVariant="outlined"
                    margin="dense"
                    ampm={false}
                    value={notAfter}
                    onChange={(date) => {
                        if (notBefore === null || date?.isAfter(notBefore)) {
                            setFilter((old: any[] = []) => {
                                return [old[0], date];
                            });
                        }
                    }}
                    minDate={notBefore || undefined}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    strictCompareDates
                    InputProps={{
                        readOnly: true,
                        endAdornment: notAfter !== null && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setFilter((old: any[]) =>
                                        old[0] ? [old[0], null] : null
                                    );
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    KeyboardButtonProps={{ size: "small" }}
                    InputAdornmentProps={{ position: "start" }}
                />
        </Box>
    );
}

const clearedKeyFilter: string[] = [];
export function KeyFilter({
    column: { filterValue = clearedKeyFilter, setFilter },
}: FilterProps<DeviceData>) {
    const { keys } = useSection();
    const translator = useTranslator();
    const classes = useStyles();

    const importantKeys = useMemo(() => {
        return keys.filter((k) => !NOT_IMPORTANT_KEYS.includes(k));
    }, [keys]);

   
    const getFilterValue = useGetLatest(filterValue);
    const [isOpen, setIsOpen] = useState(false);

    const orderedKeys = useMemo(() => {
        const filter = getFilterValue();
        
        return importantKeys.sort((a, b) => {
            // Display the selected labels first.
            let ai = filter.indexOf(a);
            ai = ai === -1 ? filter.length + importantKeys.indexOf(a) : ai;
            let bi = filter.indexOf(b);
            bi = bi === -1 ? filter.length + importantKeys.indexOf(b) : bi;
            return ai - bi;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importantKeys, getFilterValue, isOpen]);

    const { value, setValue } = useDebouncedColumnFilter<string[]>({
        filterValue,
        setFilter,
        clearedValue: clearedKeyFilter,
    });

    return (
        <Autocomplete
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            size="small"
            fullWidth
            multiple
            value={value}
            options={orderedKeys}
            limitTags={0}
            getLimitTagsText={(count) => (
                <Box ml={1}>
                    {count} {count === 1 ? "key" : "keys"} selected
                </Box>
            )}
            disableCloseOnSelect
            onChange={(_ev, opt) => {
                setValue(opt.length === 0 ? clearedKeyFilter : opt);
            }}
            renderInput={(props) => {
                return (
                    <TextField
                        variant="outlined"
                        label={`Select keys (${value.length}/10)`}
                        className={classes.textField}
                        {...props}
                    />
                );
            }}
            renderTags={() => null}
            getOptionLabel={translator.translateKey}
            getOptionDisabled={(opt) =>
                value.length === 10 && !value.includes(opt)
            }
            classes={{ option: classes.keyFilterOption }}
            renderOption={(opt, { selected }) => {
                return (
                    <>
                        <div>{translator.translateKey(opt)}</div>
                        <ClearIcon
                            fontSize="small"
                            style={{
                                visibility: selected ? "visible" : "hidden",
                            }}
                        />
                    </>
                );
            }}
        />
    );
}

const latestColumns: Column<DeviceData>[] = [
    {
        accessor: "key",
        Header: "Key",
        Cell: DataCell,
        hideable: false,
    },
    {
        accessor: "value",
        Header: "Value",
        Cell: DataCell,
        hideable: false,
    },
    {
        accessor: "created",
        Header: "Created At",
        Cell: DateCell,
        hideable: false,
    },
    {
        accessor: "inserted",
        Header: "Inserted At",
        Cell: DateCell,
        hideable: false,
    },
];

const layoutColumns: Column<Layout>[] = [
    {
        accessor: "id",
        Header: "Id",
        Cell: DataCell,
        hideable: false,
        width: 100,
    },
    {
        accessor: "original_id",
        Header: "Original Id",
        Cell: DataCell,
        hideable: false,
        width: 150
    },
    {
        accessor: "name",
        Header: "Name",
        Cell: DataCell,
        hideable: false,
        width: 200
    },
    {
        accessor: "template",
        Header: "Template",
        Cell: DataCell,
        hideable: false,
        width: 200,
    },
    {
        accessor: "generated",
        Header: "Generated",
        Cell: DataCell,
        hideable: false,
    },
    {
        accessor: "date_from",
        Header: "Date From",
        Cell: DateCell,
        hideable: false,
        width: 250,
        disableFilters: false,
        Filter: TimestampColumnFilter,
        filterLabel: "Omit data",
        filterShouldRenderCell: true,
        placeFilterOnToolbar: true,
    },
    {
        accessor: "date_to",
        Header: "Date To",
        Cell: DateCell,
        hideable: false,
        width: 250
    },
    {
        accessor: "created_by_user",
        Header: "Created by User",
        Cell: DataCell,
        hideable: false,
    }
];

const historicColumns: Column<DeviceData>[] = latestColumns.map((c) => {
    switch(c.accessor) {
        case "created":
            return {
                ...c,
                disableFilters: false,
                Filter: TimestampColumnFilter,
                filterLabel: "Omit data",
                filterShouldRenderCell: true,
                placeFilterOnToolbar: true,
            };
        case "key":
            return {
                ...c,
                disableFilters: false,
                Filter: KeyFilter,
                FilterChip: ({ filterValue }) => {
                    const displayedLabels = filterValue.slice(0, 2);
                    const translator = useTranslator();
                    return (
                        <>
                            Keys shown:{" "}
                            {displayedLabels.map((l: string, index: number) => (
                                <i key={l}>
                                    {translator.translateKey(l)}
                                    {index === 0 && displayedLabels.length !== 1
                                        ? ", "
                                        : ""}
                                </i>
                            ))}
                            {filterValue.length > 2
                                ? `... + ${
                                      filterValue.length - displayedLabels.length
                                  }`
                                : ""}
                        </>
                    );
                },
                filter: "includes",
                placeFilterOnToolbar: true,
            };
        default:
            return c;
    }
});

const tabletConnectionColumns: Column<TabletConnection>[] = [
    {
        accessor: "id",
        Header: "Connected Tablet",
        Cell: ({ cell: { value } }) => `Tablet ${value}`,
        hideable: false,
    },
    {
        accessor: "timestamp",
        Header: "Connected At",
        Cell: DateCell,
        hideable: false,
        disableFilters: false,
        Filter: TimestampColumnFilter,
        filterLabel: "Omit connections",
        filterShouldRenderCell: true,
    },
];

export interface TableProps {
    height: number;
    customerId?:number;
}

function mapFilters(filters: Filters<DeviceData>) {
    const timestampValues =
        filters
            .find((f) => f.id === "created")
            ?.value?.map(
                (v: any) => v && (moment.isMoment(v) ? v : moment(v)).toDate()
            ) || [];

    const includeKeysFilter = filters.find((f) => f.id === "key");

    return {
        notBefore: timestampValues[0] || undefined,
        notAfter: timestampValues[1] || undefined,
        includeKeys: includeKeysFilter?.value || [],
    };
}

const initialState: Partial<TableState<DeviceData>> = {
    sortBy: [{ id: "created", desc: true }],
};

interface ImportantState {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule<DeviceData>[];
    globalFilter: string;
    filters: Filters<DeviceData>;
}

const mapImportantState = createSelector(
    (s: TableState<DeviceData>) => s.pageIndex,
    (s:any) => s.pageSize,
    (s:any) => s.sortBy,
    (s:any) => s.globalFilter,
    (s:any) => s.filters,
    (pageIndex, pageSize, sortBy, globalFilter, filters) => ({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
        filters,
    })
);


const initialStateAll: Partial<TableState<DeviceData>> = {
    sortBy: [{ id: "created", desc: true }],
    pageSize: 500
};

export function AllDataTable({ height }: TableProps) {
    const { deviceTypeId, deviceId } = useSection();
    const dispatch = useDispatch();
    const { displayToast } = useToast();

    let fetchData_ = useCallback(
        ({
            pageIndex,
            pageSize,
            sortBy,
            globalFilter,
            filters,
        }: ImportantState) => {
            const importantFilters = mapFilters(filters);
            return DeviceDataService.getData(deviceTypeId, deviceId, {
                skip: pageIndex * pageSize || 0,
                limit: pageSize,
                orderBy: sortBy.map(({ id, desc }) => [id as any, desc ? "desc" : "asc"]),
                globalSearch: globalFilter,
                ...importantFilters,
            });
        },
        [deviceTypeId, deviceId]
    );

    fetchData_ = useAsyncDebounce(fetchData_, 1000);

    const download = useCallback(
        async ({
            sortBy,
            globalFilter,
            filters,
        }: ImportantState) => {
            const importantFilters = mapFilters(filters);
            const data = await DeviceDataService.getData(deviceTypeId, deviceId, {
                skip: 0,
                limit: 999999999,
                orderBy: sortBy.map(({ id, desc }) => [id as any, desc ? "desc" : "asc"]),
                globalSearch: globalFilter,
                ...importantFilters,
            })
            const csvData = generateCsvArrayDeviceData(data.data)
            
            const temp_link = document.createElement("a");
            document.body.appendChild(temp_link);
            const size=((csvData).split(/%..|./).length - 1)/1024/1024
            if (size>9){
            dispatch(displayToast({
                message: `size is ${size.toFixed(1)} mb. \n it should be less then 10mb to download.`,
                severity: "info",
                withCloseIcon: true,
            }));
            return;
            }
            temp_link.href = csvData
            temp_link.download = `status-${deviceTypeId}-${deviceId}.csv`;
            temp_link.click();
            temp_link.remove();
        },
        [deviceTypeId, deviceId,dispatch,displayToast]
    );

    const { value, error, pending, exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
        clearValueOnExec: false,
    });


    const onRowClick = (_idx: any, row: any) => {
        navigator.clipboard.writeText(row.values.value);
        displayToast({message: "value copied to clipboard", severity: "info", withCloseIcon: true});
    }

    return (
        <VirtualizedTable
            data={value?.data ?? []}
            pageCount={value?.filteredCount ?? 0}
            onStateChange={fetchData}
            mapStateChange={mapImportantState}
            exportData={download}
            loading={pending || (value === undefined && error === undefined)}
            columns={historicColumns}
            size="small"
            height={height}
            initialState={initialStateAll}
            onRowClick={onRowClick}
        ></VirtualizedTable>
    );
}

const rowsPerPageOptions = [50, { value: -1, label: "All" }];

export function LatestDataTable({ height }: TableProps) {
    const translator = useTranslator();
    const { data } = useSection();

    const skipPageResetRef = useRef(false);
    const [tableData, setTableData] = useState<DeviceData[]>([]);

    useLayoutEffect(() => {
        skipPageResetRef.current = true;
        setTableData(Object.values(data));
    }, [data]);

    useEffect(() => {
        skipPageResetRef.current = false;
    }, [tableData]);

    const globalRowFilter = useCallback(
        (
            rows: Row<DeviceData>[],
            _columnIds: string[],
            globalFilter: string
        ) => {
            if (globalFilter) {
                const regex = new RegExp(`.*${globalFilter.trim()}`, "i");

                return rows.filter(({ original }) => {
                    const key = original.key;
                    const value = original.value;
                    const translatedKey = translator.translateKey(key);
                    const translatedValue = translator.translate(key, value);
                    return [
                        key,
                        value,
                        translatedKey,
                        translatedValue,
                    ].some((v) => regex.test(v));
                });
            }

            return rows;
        },
        [translator]
    );

    const { displayToast } = useToast();

    const onRowClick = (_idx: any, row: any) => {
        navigator.clipboard.writeText(row.values.value);
        displayToast({message: "value copied to clipboard", severity: "info", withCloseIcon: true});
    }

    return (
        <VirtualizedTable
            height={height}
            columns={latestColumns}
            data={tableData}
            globalFilter={globalRowFilter}
            highlightRowOnUpdate
            size="small"
            rowsPerPageOptions={rowsPerPageOptions}
            hooks={[useDataUpdateHook(skipPageResetRef)]}
            initialState={initialState}
            onRowClick={onRowClick}
        ></VirtualizedTable>
    );
}

function useDataUpdateHook(ref: any) {
    return useCallback(function useDataUpdates(hooks: Hooks<any>) {
        const useOptions = useCallback((_otps, args) => {
            const skipReset = ref.current;
            const resetProps = {
                autoResetPage: !skipReset,
                autoResetGlobalFilter: !skipReset,
                autoResetFilters: !skipReset,
                autoResetSortBy: !skipReset,
            };
            return {
                ...args,
                ...resetProps,
            };
        }, []);
        hooks.useOptions.push(useOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

const mapImportantState2 = createSelector(
    (s: TableState<TabletConnection>) => s.pageIndex,
    (s:any) => s.pageSize,
    (s:any) => s.sortBy,
    (s:any) => s.filters,
    (pageIndex, pageSize, sortBy, filters) => ({
        pageIndex,
        pageSize,
        sortBy,
        filters,
    })
);

export function TabletConnectionsTable({ height }: TableProps) {
    const { deviceId } = useSection();
    const fetchData = useCallback(
        ({
            pageIndex,
            pageSize,
            sortBy,
            filters,
        }: {
            pageIndex: number;
            pageSize: number;
            sortBy: SortingRule<TabletConnection>[];
            filters: Filters<TabletConnection>;
        }) => {
            const timestampValues =
                filters
                    .find((f) => f.id === "timestamp")
                    ?.value?.map(
                        (v: any) =>
                            v && (moment.isMoment(v) ? v : moment(v)).toDate()
                    ) || [];

            const importantFilters = {
                notBefore: timestampValues[0],
                notAfter: timestampValues[1],
            };

            return DeviceDataService.getTabletConnections(deviceId, {
                skip: pageIndex * pageSize,
                limit: pageSize,
                orderBy: sortBy.map(({ id, desc }) => [
                    id as any,
                    desc ? "desc" : "asc",
                ]),

                ...importantFilters,
            }).then(({ pageCount, totalCount, filteredCount, data }) => {
                return {
                    totalCount,
                    pageCount,
                    data,
                    filteredCount,
                };
            });
        },
        [deviceId]
    );

    const { value, error, pending, exec } = useAsync(fetchData, {
        immediate: false,
        clearValueOnExec: false,
    });

    const { displayToast } = useToast();

    const onRowClick = (_idx: any, row: any) => {
        navigator.clipboard.writeText("Tablet "+row.values.id);
        displayToast({message: "value copied to clipboard", severity: "info", withCloseIcon: true});
    }

    return (
        <VirtualizedTable
            data={value?.data ?? []}
            pageCount={value?.filteredCount ?? 0}
            onStateChange={exec}
            mapStateChange={mapImportantState2}
            loading={pending || (value === undefined && error === undefined)}
            columns={tabletConnectionColumns}
            size="small"
            disableGlobalFilter
            height={height}
            onRowClick={onRowClick}
        />
    );
}

export function LayoutsTable({ height,customerId }: TableProps) {
    const customer= Number(customerId);
    const fetchData = useCallback(
        ({
            pageIndex,
            pageSize,
            sortBy,
            filters,
            globalFilter
        }: {
            pageIndex: number;
            pageSize: number;
            sortBy: SortingRule<Layout>[];
            filters: Filters<Layout>;
            globalFilter: string
        }) => {
            const timestampValues =
                filters
                    .find((f) => f.id === "date_from")
                    ?.value?.map(
                        (v: any) =>
                            v && (moment.isMoment(v) ? v : moment(v)).toDate()
                    ) || [];

            const importantFilters = {
                notBefore: timestampValues[0],
                notAfter: timestampValues[1],
            };

            return DeviceDataService.getLayouts(customer, {
                skip: pageIndex * pageSize,
                limit: pageSize,
                orderBy: sortBy.map(({ id, desc }) => [
                    id as any,
                    desc ? "desc" : "asc",
                ]),
                globalSearch: globalFilter,

                ...importantFilters,
            }).then(({ pageCount, totalCount, filteredCount, data }) => {
                return {
                    totalCount,
                    pageCount,
                    data,
                    filteredCount,
                };
            });
        },
        [customer]
    );
    
    const { value, error, pending, exec } = useAsync(fetchData, {
        immediate: false,
        clearValueOnExec: false,
    });

    const { displayToast } = useToast();

    const onRowClick = (_idx: any, row: any) => {
        navigator.clipboard.writeText(JSON.stringify(row.values));
        displayToast({message: "value copied to clipboard", severity: "info", withCloseIcon: true});
    }
    
    return (
        <VirtualizedTable
            height={height}
            data={value?.data ?? []}
            pageCount={value?.filteredCount ?? 0}
            onStateChange={exec}
            mapStateChange={mapImportantState}
            loading={pending || (value === undefined && error === undefined)}
            columns={layoutColumns}
            size="small"
            onRowClick={onRowClick}
            initialState={{pageSize:50, sortBy: [{ id: "date_from", desc: true }],
        }}
        />
    );
}