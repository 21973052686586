import React from "react";
import { makeStyles, SvgIconProps } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "src/utils/classNames";

const useStyles = makeStyles((theme) => ({
    icon: {
        opacity: 0,
        fontSize: 18,
        userSelect: "none",
        "-ms-user-select": "none",
        "-webkit-user-select": "none",
        transition: theme.transitions.create(["opacity", "transform"], {
            duration: theme.transitions.duration.shorter,
        }),
        "&:hover:not($asc):not($desc)": {
            opacity: 0.5,
        },
    },
    asc: {
        transform: "rotate(0deg)",
        opacity: 0.9,
        color: theme.palette.common.black,
    },
    desc: {
        transform: "rotate(180deg)",
        opacity: 0.9,
        color: theme.palette.common.black,
    },
}));

export interface SortIconProps extends SvgIconProps {
    sortDirection?: "asc" | "desc";
    title: string;
}
const SortIcon = React.memo(function SortIcon(props: SortIconProps) {
    const { sortDirection, title, className, ...rest } = props;
    const sorted = sortDirection !== undefined;
    const classes = useStyles();

    return (
        <Tooltip title={title} aria-label={title}>
            <ArrowUpwardIcon
                aria-hidden={!sorted}
                className={classNames(
                    classes.icon,
                    sorted && classes[sortDirection!],
                    className
                )}
                {...rest}
            />
        </Tooltip>
    );
});

export default SortIcon;
