import axios from "axios";
import { handleError } from "./Errors";
import { Customer, Robot, Tablet, TabletUserLevel } from "./DeviceDataService";
import CommandService from "./CommandService";

export interface CreateCustomerArgs {
    parentId: number;
    name: string;
    accessGroups: number[];
    isRootDealer?: boolean;
    company?: string;
}

export interface SetRobotNameArgs {
    robotId: number;
    name: string;
}

export interface SetNTRIPArgs  {
    username: string;
    password: string;
    address: string;
    port: number;
    mountpoint: string;
    version: number;
    robot:number;
    deviceTypeId:number
}


export interface SetCustomerNameArgs {
    customerId: number;
    name: string;
}

export interface ConnectDevicesArgs {
    robotId?: number;
    tabletId?: number;
    customerId: number;
}

export interface SetTabletUserLevelArgs {
    userLevel: TabletUserLevel;
    tabletId: number;
}

export interface CustomerInfo {
    username: string;
    tablets: Tablet[];
    robots: Robot[];
    customers: Customer[];
}

export interface Voucher {
    code? : string,
    usedAt? : number,
    customerId? : number,
    tabletId? : number,
}

export interface TabletAppVersions {[id: number]: string}

export interface Company {
    id: string,
    name: string,
    country: string,
}

export interface AccessGroup {
    Id: number;
    AccessGroupTypeId: number;
    AccessGroupType?: string;
    Name: string;
    ShortName: string;
    Inheritable: number;
}

export interface DDQueryParam {
    name: string;
    order: number;
}
export interface DDQueryExecuteParam {
    value: Date;
    order: number;
}
export interface DeviceDataQuery {
    id?: number,
    name: string,
    description?: string,
    whQuery: string,
    dbQuery: string,
    isPublic: boolean,
    username?: string,
    dbQueryFirst: boolean,
    params: DDQueryParam[],
}

export default class ConfigurationsService {
    static async createCustomer(args: CreateCustomerArgs): Promise<number> {
        if (args.isRootDealer == null)
            args.isRootDealer = false;
        try {
            const ret = await axios.post("/api/configurations/createCustomer",args);
            return (ret.data as any).id;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async setRobotName(args: SetRobotNameArgs): Promise<void> {
        try {
            await axios.post("/api/configurations/setRobotName", args);
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async connectDevices(args: ConnectDevicesArgs): Promise<void> {
        try {
            await axios.post("/api/configurations/connect", args);
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async setNTRIP(args: SetNTRIPArgs):Promise<void>{
        try {
            await CommandService.postCommand({
                robotId: args.robot,
                deviceType: args.deviceTypeId,
                type:"reconfigure_ntrip",
                args:{
                    username: args.username,
                    password: args.password,
                    address: args.address,
                    port: args.port,
                    mountpoint: args.mountpoint,
                    version: args.version
                }
            })
          
             
                
            
        } catch (error) {
            return handleError(error,true)
        }
    }

    static async setTabletUserLevel(
        args: SetTabletUserLevelArgs
    ): Promise<void> {
        try {
            await axios.post("/api/configurations/setTabletUserLevel", args);
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async setCustomerName(args: SetCustomerNameArgs): Promise<void> {
        try {
            await axios.post("/api/configurations/setCustomerName", args);
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getDevicesWhereCustomer(customerId : number): Promise<CustomerInfo> {
        try {
            return (await axios.get("/api/configurations/getDevicesForCustomer/" + customerId)).data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    
    static async deleteCustomer(customerId : number): Promise<void> {
        try {
            return await axios.delete("/api/configurations/deleteCustomer/" + customerId);
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getTabletAppVersions(): Promise<TabletAppVersions> {
        try {
            return (await axios.get("/api/configurations/getTabletAppVersions")).data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getTabletAppLatestVersion(tabletId: number): Promise<string> {
        try {

            const response = await axios.get("/api/configurations/getTabletAppVersions/"+tabletId+"/latest");
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getAppVersions(): Promise<string[]> {
        try {
            return (await axios.get("/api/configurations/getAppVersions")).data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async getDeprecatedAppVersions(): Promise<string[]> {
        try {
            return (await axios.get("/api/configurations/getDeprecatedAppVersions")).data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async setTabletAppVersion(tabletId: number, version: string, oneTime=false): Promise<void> {
        try {
            return await axios.post("/api/configurations/setTabletAppVersion",{tabletId, version,oneTime});
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async deprecateDevice(deviceType: number, oldId: number, newId?: number): Promise<void> {
        try {
            return await axios.post("/api/configurations/deprecateDevice",{deviceType, oldId, newId});
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async unDeprecateDevice(deviceType: number, id: number): Promise<void> {
        try {
            return await axios.post("/api/configurations/undeprecateDevice",{deviceType, id});
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async updateCustomerSalesRep(customerId: number, username: string): Promise<void> {
        try {
            return await axios.post("/api/configurations/salesrep",{customerId,username});
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async addBaseStation(customerId: number, basestationId: number): Promise<void> {
        try {
            return await axios.post("/api/configurations/addBaseStation",{customerId,basestationId});
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async setCustomerParent(customerId: number, parentId: number): Promise<void> {
        try {
            await axios.post("/api/configurations/setCustomerParent", {customerId,parentId});
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async checkVoucher(code: string): Promise<Voucher> {
        try {
            return (await axios.post("/api/configurations/checkVoucher", {code})).data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async getGoogleMapsToken() {
        try {
            return (await axios.get('/api/googlemap/token')).data
        } catch (error) {
            handleError(error,true)
        }
    }
    
    static async getRecentHubspotCompanies(): Promise<Array<Company>> {
        try {
            return (await axios.get("/api/configurations/getRecentHubspotCompanies")).data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async isDealerDescendant(customerId:number): Promise<boolean> {
        try {
            return ((await axios.get("/api/configurations/isDealerDescendant/"+customerId)).data as any).isDescendant;
        } catch (err) {
            return handleError(err, true);
        }
    }

    static async emergencyEmail(message:string,isTLM:boolean,isTS:boolean): Promise<boolean> {
        try {
            return ((await axios.post("/api/configurations/emergencyEmail",{message,isTLM,isTS})));
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async setWarranty(robotId : number,start:Date,end:Date): Promise<void> {
        try {
            await axios.post("/api/configurations/warranty", {start,end,robotId});
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async setSubscription(robotId : number,start:Date,end:Date): Promise<void> {
        try {
            await axios.post("/api/configurations/subscription", {start,end,robotId});
        } catch (err) {
            return handleError(err, false);
        }
    }
    static async postRSlock(robotId: number, rsAccessGroupId: number): Promise<boolean> {
        try {
            return (await axios.post("/api/configurations/lockRSAvailableUpdate",{robotId, rsAccessGroupId})).data as boolean;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async postRSunlock(robotId: number): Promise<boolean> {
        try {
            return (await axios.post("/api/configurations/unlockRSAvailableUpdate",{robotId})).data as boolean;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async getRSAaccessGroups(): Promise<AccessGroup[]> {
        try {

            const response = await axios.get("/api/configurations/RSAccessgroups");
            return response.data as AccessGroup[];
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async getAccessGroups(): Promise<AccessGroup[]> {
        try {

            const response = await axios.get("/api/configurations/accessgroups");
            return response.data as AccessGroup[];
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async executeDataQuery(whQuery: string, dbQuery: string, params: DDQueryExecuteParam[], dbQueryFirst: boolean): Promise<any[]> {
        try {
            const data = (await axios.post("/api/configurations/device-data-queries/execute",{whQuery, dbQuery, params, dbQueryFirst},{timeout:180000}))
            return data.data as any[];
        } catch (err :any) {
            return handleError(err, true);
        }
    }
    static async postDataQuery(query: DeviceDataQuery): Promise<void> {
        try {
            await axios.post("/api/configurations/device-data-queries", query)
        } catch (err :any) {
            return handleError(err, true);
        }
    }

    static async getAccessibleDataQueries(): Promise<DeviceDataQuery[]> {
        try {
           return (await axios.get("/api/configurations/device-data-queries")).data as any
        } catch (err :any) {
            return handleError(err, true);
        }
    }

    static async deleteDataQuery(queryId: number): Promise<void> {
        try {
            await axios.delete("/api/configurations/device-data-queries/"+ queryId)
        } catch (err :any) {
            return handleError(err, true);
        }
    }

    static async hubspotCompany(company:string,name:string,customer:number){
        try {
            await axios.post("/api/configurations/hubspotCompany", {company,name,customer},{timeout:30000})
        } catch (err :any) {
            return handleError(err, true);
        }
    }

    static async hubspotCompanyReviewed(customer:number){
        try {
            await axios.post("/api/configurations/hubspotCompanyReviewed", {customer},{timeout:30000})
        } catch (err :any) {
            return handleError(err, true);
        }
    }
    static async triggerBreakdownGreetings(isBreakdown: boolean): Promise<void> {
        try {
            await axios.post("/api/configurations/triggerBreakdownGreetings",{isBreakdown});
        } catch (err) {
            return handleError(err, false);
        }
    }
}
