/* eslint-disable eqeqeq */

/**
 * Calculate the levenshtein distance of a string to a target string
 * Taken from:
 *  https://stackoverflow.com/questions/11919065/sort-an-array-by-the-levenshtein-distance-with-best-performance-in-javascript
 */
export default function levenshtein(
    inputStr: string,
    targetStr: string
): number {
    const d = [] as any[]; //2d matrix

    // Step 1
    const n = inputStr.length;
    const m = targetStr.length;

    if (n == 0) return m;
    if (m == 0) return n;

    //Create an array of arrays in javascript (a descending loop is quicker)
    for (let i = n; i >= 0; i--) d[i] = [];

    // Step 2
    for (let i = n; i >= 0; i--) d[i][0] = i;
    for (let j = m; j >= 0; j--) d[0][j] = j;

    // Step 3
    for (let i = 1; i <= n; i++) {
        const s_i = inputStr.charAt(i - 1);

        // Step 4
        for (let j = 1; j <= m; j++) {
            //Check the jagged ld total so far
            if (i == j && d[i][j] > 4) return n;

            const t_j = targetStr.charAt(j - 1);
            const cost = s_i == t_j ? 0 : 1; // Step 5

            //Calculate the minimum
            let mi = d[i - 1][j] + 1;
            const b = d[i][j - 1] + 1;
            const c = d[i - 1][j - 1] + cost;

            if (b < mi) mi = b;
            if (c < mi) mi = c;

            d[i][j] = mi; // Step 6

            //Damerau transposition
            if (
                i > 1 &&
                j > 1 &&
                s_i == targetStr.charAt(j - 2) &&
                inputStr.charAt(i - 2) == t_j
            ) {
                d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
            }
        }
    }

    // Step 7
    return d[n][m];
}
