import React from "react";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import { ConnectionState } from "src/events";
import classNames from "src/utils/classNames";

const useStyles = makeStyles((theme) => ({
    icon: {
        transition: theme.transitions.create("color", {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.sharp,
        }),
    },
    content: {
        display: "flex",
        alignItems: "center",
        "& > :last-child": {
            fontSize: theme.typography.pxToRem(13.5),
            color: theme.tmrPalette.grey,
        },
        "& > :first-child": {
            paddingRight: theme.spacing(0.5),
        },
    },
    on: {
        color: theme.palette.success.light,
    },
    off: {
        color: theme.palette.error.light,
    },
    connecting: {
        color: theme.palette.warning.light,
    },
}));

export interface ConnectedIconProps {
    state: ConnectionState;
    className?: string;
}

const onTitle = "You are receiving live updates from the server.";
const offTitle = "You are not connected to the server.";
const waitingTitle =
    "You have lost connection to the server. An attempt to reconnect will happen shortly.";
const connectingTitle = "Connecting to the server...";

const titleMap = {
    [ConnectionState.Connected]: {
        title: onTitle,
        color: "on" as const,
    },
    [ConnectionState.Idle]: {
        title: offTitle,
        color: "off" as const,
    },
    [ConnectionState.Waiting]: {
        title: waitingTitle,
        color: "off" as const,
    },
    [ConnectionState.Connecting]: {
        title: connectingTitle,
        color: "connecting" as const,
    },
};

export default React.memo(function ConnectedIcon({
    state,
    className,
}: ConnectedIconProps) {
    const classes = useStyles();

    const { title, color } = titleMap[state];
    const colorClassName = classes[color];

    return (
        <Tooltip
            title={title}
            aria-label={title}
            leaveTouchDelay={3200}
            enterTouchDelay={200}
        >
            <div className={classNames(classes.content, className)}>
                <DotIcon
                    fontSize="small"
                    className={classNames(classes.icon, colorClassName)}
                />
                <Typography>Live Updates</Typography>
            </div>
        </Tooltip>
    );
});
