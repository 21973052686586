import {
    IconButton,
    Toolbar,
    Tooltip,
    Button,
    createStyles,
    makeStyles,
    useMediaQuery,
    Theme,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewColumnsIcon from "@material-ui/icons/ViewColumn";

import React, {
    MouseEventHandler,
    useCallback,
    useState,
    useMemo,
} from "react";
import { TableInstance, Filters as FiltersArray } from "react-table";

import { withInstance } from "../BasicTable/tableUtilities";
import classNames from "src/utils/classNames";
import HideColumns, { HideColumnsProps } from "./HideColumns";
import Filters from "./Filters";
import GlobalFilter from "./GlobalFilter";

export const useStyles = makeStyles((theme) =>
    createStyles({
        toolbar: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 48,
            flexWrap: "wrap",
            marginBottom: theme.spacing(1),
        },
        leftAdornment: {
            flexGrow: 1,
            display: "flex",
            padding: theme.spacing(1),
        },
        rightButtons: {
            display: "flex",
            flexWrap: "nowrap",
            paddingLeft: theme.spacing(1),
            "& > :not(:first-child)": {
                paddingRight: theme.spacing(1),
            },
            "& > :last-child": {
                paddingRight: theme.spacing(1),
            },
        },
        toolbarFilters: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
        },
        toolbarFilter: {
            padding: theme.spacing(1),
        },
        globalFilter: {
            display: "flex",
            width: 254,
            "& > *": {
                margin: 0,
            },
        },
        globalFilterAndStrictSearch: {
            display: "flex",
            width: "fit-content",
            "& > *": {
                margin: 0,
            },
        },
        leftIcons: {
            "&:first-of-type": {
                marginLeft: -12,
            },
        },
        rightIcons: {
            padding: 12,
            width: 48,
            height: 48,
        },
        button: {
            margin: theme.spacing(0.5),
        },
    })
);

type ActionButtonProps = {
    icon?: JSX.Element;
    onClick: MouseEventHandler;
    enabled?: boolean;
    label: string;
    variant?: "right" | "left";
};

const SmallIconActionButton = ({
    icon,
    onClick,
    label,
    enabled = true,
    variant,
}: ActionButtonProps) => {
    const classes = useStyles();
    return (
        <Tooltip title={label} aria-label={label}>
            <span>
                <IconButton
                    className={classNames({
                        [classes.rightIcons]: variant === "right",
                        [classes.leftIcons]: variant === "left",
                    })}
                    onClick={onClick}
                    disabled={!enabled}
                >
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableToolbarProps<T extends {}> {
    instance: TableInstance<T>;
    filters: FiltersArray<T>;
    LeftAdornment?: React.ReactNode;
    RightAdornment?: React.ReactNode;
    hideColumnsProps?: Pick<HideColumnsProps<any>, "filter">;
    enableStrictSearch?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TableToolbar = React.forwardRef(function TableToolbar<T extends {}>(
    {
        instance,
        LeftAdornment,
        RightAdornment,
        hideColumnsProps,
        enableStrictSearch,
    }: TableToolbarProps<T>,
    ref: React.Ref<HTMLDivElement> | React.RefObject<HTMLDivElement>
) {
    const {
        columns,
        visibleColumns: allVisibleColumns,
        hideColumnToggle = false,
    } = instance;
    const classes = useStyles();

    const isMediumSizedScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("sm")
    );
    const showSearchBar = !instance.disableGlobalFilter;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(
        undefined
    );

    const [columnsOpen, setColumnsOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);

    const hideableColumns = useMemo(
        () =>
            hideColumnToggle
                ? []
                : columns.filter((column) => column.hideable ?? true),
        [columns, hideColumnToggle]
    );

    const visibleColumns = useMemo(() => {
        return hideableColumns.filter((c) => c.isVisible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideableColumns, allVisibleColumns]);

    const filterableColumns = useMemo(
        () => columns.filter((it) => !it.disableFilters),
        [columns]
    );

    const toolbarFilters = useMemo(() => {
        return filterableColumns.filter((f) => f.placeFilterOnToolbar);
    }, [filterableColumns]);

    const handleColumnsClick = useCallback(
        (event: any) => {
            setAnchorEl(event.currentTarget);
            setColumnsOpen(true);
        },
        [setAnchorEl, setColumnsOpen]
    );

    const handleFilterClick = useCallback(
        (event: any) => {
            setAnchorEl(event.currentTarget);
            setFilterOpen(true);
        },
        [setAnchorEl, setFilterOpen]
    );

    const handleClose = useCallback(() => {
        setColumnsOpen(false);
        setFilterOpen(false);
        setAnchorEl(undefined);
    }, []);

    return (
        <Toolbar ref={ref} className={classes.toolbar}>
            <div className={classes.leftAdornment}>{LeftAdornment}</div>
            <div className={classes.rightButtons}>
                {!hideColumnToggle && (
                    <HideColumns
                        {...(hideColumnsProps || {})}
                        instance={instance}
                        onClose={handleClose}
                        open={columnsOpen}
                        anchorEl={anchorEl}
                    />
                )}
                <Filters
                    instance={instance}
                    onClose={handleClose}
                    open={filterOpen}
                    anchorEl={anchorEl}
                    enableStrictSearch={enableStrictSearch}
                />
                {!hideColumnToggle && hideableColumns.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleColumnsClick}
                        startIcon={<ViewColumnsIcon />}
                        className={classes.button}
                    >
                        Toggle Columns ({visibleColumns.length}/
                        {hideableColumns.length})
                    </Button>
                )}
                {(filterableColumns.length > toolbarFilters.length ||
                    (!isMediumSizedScreen && showSearchBar)) && (
                    <SmallIconActionButton
                        icon={<FilterListIcon />}
                        onClick={handleFilterClick}
                        label="Filters"
                        variant="right"
                    />
                )}
            </div>
            {toolbarFilters.length > 0 && (
                <div className={classes.toolbarFilters}>
                    {toolbarFilters.map((f) => (
                        <div key={f.id} className={classes.toolbarFilter}>
                            {f.render("Filter")}
                        </div>
                    ))}
                </div>
            )}
            {isMediumSizedScreen && showSearchBar && (
                <div key="globalFilter" className={enableStrictSearch ? classes.globalFilterAndStrictSearch : classes.globalFilter}>
                    <GlobalFilter instance={instance} fullWidth enableStrictSearchOption={enableStrictSearch}/>
                </div>
            )}
            {RightAdornment}
        </Toolbar>
    );
});

export default withInstance(
    (instance) => ({
        instance,
        filters: instance.state.filters,
        globalFilter: instance.state.globalFilter,
    }),
    TableToolbar
);
