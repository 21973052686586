import { Button, Checkbox, FormControlLabel, Grid, IconButton, makeStyles, MenuItem, Slider, Switch, TextField, Tooltip, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Loader as GMLoader,google as GMgoogle}  from "google-maps";
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { Customer } from "src/services/DeviceDataService";
import { useAsync, useToast } from "src/hooks";
import { useSelector } from "react-redux";
import { customersArraySelector, googleMapsTokenSelector } from "src/redux/app/selectors";
import ExportButton from "src/components/ExportButton";
import ToggleVisibility from "src/components/ToggleVisibility";
import RefreshButton from "src/components/RefreshButton";
import { TimeFormats } from "src/utils/constants";
import moment from "moment";
import { DateTimePicker } from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import LocationService, { distance, IonospherePolygon, LocationFilterOptions, RobotMap } from "src/services/LocationService";
import { getColorGradient } from "src/utils/common";
import useQueryString from "src/hooks/useQueryString";
import useBatchPromise from "src/hooks/useBatchPromise";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { Prompt } from "react-router";
import { timezone } from "src/App";
import { userAccessSelector } from "src/redux/auth/selectors";
import { alpha, hexToRgb } from "@mui/material";


const NEW_COLOR = "#99FF99";
const OLD_COLOR = "#FF9999";
const HISTORIC_MAX_LENGTH = 100;

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100vh - 40px)",
        paddingTop: "0px",
        padding: "10px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        "&>*": {
            marginBottom: "10px",
        },
        "&>*:last-child": {
            marginBottom: "0px",
        },
    },
    map: {
        position: "relative",
        width: "100%",
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "row",
        "&>*:nth-child(2)": {
            flexGrow: 1,
            height: "100%",
            border: "solid 1px black",
        },
       
    },
    map_sidebar: {
            flexGrow: 0,
            marginLeft: "10px",
            height: "100%",
            width: "230px",
            border: "solid 1px black",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            "&>*:nth-child(1)": {
                flexGrow: 0,
                borderBottom: "solid 1px black",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
            },
            "&>*:nth-child(2)": {
                flexGrow: 0,
                borderBottom: "solid 1px black",
                fontFamily: theme.typography.fontFamily,
                padding: "2px",
            },
            "&>*:nth-child(3)": {
                height: "0px",
                flexGrow: 1,
                overflowY: "auto",
                "&>*": {
                    cursor: "pointer",
                    padding: "1px",
                    fontFamily: theme.typography.fontFamily,
                    borderBottom: "solid 1px black",
                },
            },
        },
    legend: {
        cursor: "default",
        position: "absolute",
        zIndex: 1,
        top: "10px",
        left: "10px",
        backgroundColor: "#FFFFFF",
        borderRadius: "2px",
        padding: "10px",
        [theme.breakpoints.down('xs')]:{ marginTop:"200px"},
        "&>*": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
        },
        "&>*>*:nth-child(1)": {
            position: "relative",
            cursor: "pointer",
            border: "1px solid black",
            width:"12px",
            height: "12px",
            marginRight: "5px",
            "&>*": {
                position: "absolute",
                width:"16px",
                height: "16px",
                top: "-2px",
                left: "-2px",
            },
        },
        "&>*>*:nth-child(2)": {
            cursor: "pointer",
            fontFamily: theme.typography.fontFamily,
        },
    },
    legendEnabled: {
        "&:hover": {
            textDecoration: "line-through",
            color: "#FF0000",
        },
        "&:active": {
            color: "#FF5555",
        },
    },
    legendDisabled: {
        color: "#FFAAAA",
        textDecoration: "line-through",
        "&:hover": {
            color: "#00DD00",
            textDecoration: "none",
        },
        "&:active": {
            color: "#55DD55",
        },
    },
    control: {
        border: "solid 1px black",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: "row",
        padding:"15px",
        gap:"10px",
        "&>*": {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "row",
        },
    },
    filters: {
        minHeight: "50px",
        border: "solid 1px black",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        "&>*": {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "row",
        },
    },
    robotFilter: {
        minHeight: "40px",
        maxHeight: "72px",
        overflowY: "auto",
        marginTop: "5px",
        marginBottom: "5px",
        display: "flex",
        justifyContent: "start",
        alignItems: "top",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: "5px",
        "&>*": {
            cursor: "pointer",
            textAlign: "center",
            paddingLeft: "3px",
            paddingRight: "3px",
            height: "20px",
            border: "1px solid black",
            borderRadius: "10px",
            fontFamily: theme.typography.fontFamily,
            marginRight: "3px",
            marginBottom: "1px",
            marginTop: "1px",
        },
        "&>*:hover": {
            backgroundColor: "#FF9999",
        },
        "&>*:active": {
            backgroundColor: "#FF8888",
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    option:{
        "&>*":{minWidth:"110px"}
    },
    ionoGradient:{
        position: "absolute",
        bottom: 30,
        left: 30,
        width: "40%",
        height: "20px",
        background: "linear-gradient(to right ,LawnGreen 0%,yellow 30%, red 60%, MediumSlateBlue   100%)",
        pointerEvents: "none",
        border: "1px solid black"
    },
    ionoGradientPart:{
        width:"10%",
        height:"100%",
        border:"1px solid black",
        boxSizing:"border-box",
        position:"relative",
        
    },
    ionoGradientNumber:{
        position: "absolute",
        bottom: "-30px",
        right: "-6px",
    }
}));

function GetIconPath(label : string) : string {
    switch (label.length) {
        case 0:
        case 1:
        case 2:
            return "M 12 2 C 8.134 2 5 5.134 5 9 C 5 14.25 12 22 12 22 C 12 22 19 14.25 19 9 C 19 5.134 15.866 2 12 2 Z";
        default:
            const leftBorder = 2 - (2.5 * (label.length - 4));      //eslint-disable-line no-case-declarations
            const rightBorder = 22 + (2.5 * (label.length - 4));    //eslint-disable-line no-case-declarations
            const leftStretch = 6.5 - (2.5 * (label.length - 4));   //eslint-disable-line no-case-declarations
            const rightStretch = 17.5 + (2.5 * (label.length - 4)); //eslint-disable-line no-case-declarations
            return "M 12 2 C "+leftStretch+" 2 "+leftBorder+" 4 "+leftBorder+" 9 C "+leftBorder+" 17 8 16 12 22 C 16 16 "+rightBorder+" 17 "+rightBorder+" 9 C "+rightBorder+" 4 "+rightStretch+" 2 12 2 Z";
    }
}

function GetRobotString(device : RobotMap) : string {
    if (device.historyDisplacement !== undefined)
        return device.id+" "+device.robotName+" "+moment(device.timestamp*1000).utcOffset(timezone).format(TimeFormats.PresentationShort);
    else
        return device.id+" "+device.robotName+" ("+device.customerName+")";
}

//replace in future with "IsNotInOcean()"
function IsValidLocation(lat : number, lng : number) : boolean {
    return !(
        (lat < 1 && lat > -4 && lng < 4 && lng > -28) ||
        (lat < -60)
    );
}

/*
WARNING: the code that follows will make you cry; a safety pig is provided below for your benefit.

 _._ _..._ .-',     _.._(`))
'-. `     '  /-._.-'    ',/
   )         \            '.
  / _    _    |             \
 |  a    a    /              |
 \   .-.                     ;  
  '-('' ).-'       ,'       ;
     '-;           |      .'
        \           \    /
        | 7  .__  _.-\   \
        | |  |  ``/  /`  /
       /,_|  |   /,_/   /
          /,_/      '`-'
*/
export default function MapOfRobots() {
    const [location,setLocation]=useState({"swla": 54.57728371111735,"swln": 7.143787903642043,"nela": 57.954625418594205,"neln": 11.549305481767043})
    const fetchLocation = useCallback(
        () => {navigator.geolocation.getCurrentPosition((pos)=>setLocation({swla:pos.coords.latitude-2,swln:pos.coords.longitude-2,nela:pos.coords.latitude+2,neln:pos.coords.longitude+2}));return new Promise(()=>{})},//eslint-disable-line
        []
    );
    const {value} = useAsync(fetchLocation, {
        defaultPending: true,
    });
    const API_KEY=useSelector(googleMapsTokenSelector);
    const classes = useStyles();
    const { displayToast } = useToast();
    const historicLocationAccess = useSelector(userAccessSelector).includes('HistoricLocation');
    //robots, robotTypes, customers -> initializes only once
    const [robotTypes, setRobotTypes] = useState(new Array<string>(0));
    const customers : Customer[] = useSelector(customersArraySelector)!;
    //exclusion based on robot types
    const [typeFilter, setTypeFilter]= useQueryString("tyfi", new Array<string>(0));
    //inclusion based in robot ids
    const [robotFilter, setRobotFilter]= useQueryString("rofi", new Array<number>(0));
    const { value: robots, pending: pendingRobots, exec: fetchRobots } = useAsync(LocationService.getRobotsLocation, {
        immediate: false,
        clearValueOnExec: false,
        onComplete: (val) => {
            const temp = new Array<string>(0);
            for (const robo of val)
                if (!temp.includes(robo.type))
                    temp.push(robo.type);
            temp.sort();
            setRobotTypes(temp);
            
            setTypeFilter(typeFilter ?? temp);
        },
    });
    useEffect(() => {
        fetchRobots();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps
    
    //is historic, color code based on "historyDisplacement" else, color code on robotType
    const getColor = useCallback((robot : string | RobotMap) : string => {
        let type = "";
        if (typeof robot === "string")
            type = robot.toLowerCase();
        else if (robot.historyDisplacement !== undefined)
            return getColorGradient(OLD_COLOR, NEW_COLOR, robot.historyDisplacement);
        else if (robot.type !== undefined)
            type = robot.type.toLowerCase();
        let index = robotTypes.findIndex((val) => {return val.toLowerCase() === type;})
        if (index < 0)
            index = 0;
        return ("hsl("+(360*(index/robotTypes.length))+", 100%, 80%)");
    }, [robotTypes]);

    //readonly map objects -> initializes only once
    const [markerImage, setMarkerImage] = useState({});
    const [infowindow, setInfowindow] = useState(undefined as google.maps.InfoWindow | undefined);
    const [map, setMap] = useState(undefined as google.maps.Map | undefined);
    const [clusterer, setClusterer] = useState(undefined as MarkerClusterer | undefined);
    const [dummyMarker, setDummyMarker] = useState(undefined as google.maps.Marker | undefined);

    //triggers a redraw on the clusterer
    const redraw = useCallback(() => {
        if (map !== undefined && dummyMarker !== undefined) {
            clusterer?.addMarker(dummyMarker, true);
            clusterer?.removeMarker(dummyMarker, false);
            google.maps.event.trigger(map, "bounds_changed");
        }
    }, [map, clusterer, dummyMarker]);

    //listeners on the map
    const [listeners, setListeners] = useState(new Array<google.maps.MapsEventListener>(0));
    //result of current query
    const [allRobots, setAllRobots] = useState(new Array<RobotMap>(0));
    //filtered robots (from current query)
    const [filteredRobots, setFilteredRobots] = useState(new Array<RobotMap>(0));
    //robots in map viewport (from filtered robots)
    const [visibleRobots, setVisibleRobots] = useState(new Array<RobotMap>(0));
    //current bounds of the map viewport
    const [boundsLat1, setBoundsLat1] = useQueryString("swla", location.swla);
    const [boundsLng1, setBoundsLng1] = useQueryString("swln", location.swln);
    const [boundsLat2, setBoundsLat2] = useQueryString("nela", location.nela);
    const [boundsLng2, setBoundsLng2] = useQueryString("neln", location.neln);
    useEffect(() => {
        if (map !== undefined && boundsLat1 === location.swla && boundsLng1 === location.swln && boundsLat2 ===location.nela && boundsLng2 === location.neln)
            map.fitBounds(new google.maps.LatLngBounds(new google.maps.LatLng(boundsLat1, boundsLng1),new google.maps.LatLng(boundsLat2, boundsLng2)),0);
    }, [map, boundsLat1, boundsLng1, boundsLat2, boundsLng2]);  //eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
        setBoundsLat1(location.swla)
        setBoundsLng1(location.swln)
        setBoundsLat2(location.nela)
        setBoundsLng2(location.neln)
    },[JSON.stringify(location)]) //eslint-disable-line react-hooks/exhaustive-deps
    
    //time constrains (only for historic)
    const [from, setFrom] = useQueryString("from", moment.unix(moment.now()/1000).subtract(7, 'days').unix());
    const [until, setUntil] = useQueryString("unti", moment.unix(moment.now()/1000).unix());
    const [lastDays, setLastDays] = useQueryString("lada", -1);
    const [ionoDate,setIonoDate]=useQueryString("ionoDate",moment.unix(moment.now()/1000).unix())
    //gelocation constraint (only for historic)
    const [distanceDensity, setDistanceDensity] = useQueryString("dist", 0);
    const [distanceDensityVisual, setDistanceDensityVisual] = useState(distanceDensity);
    const [distanceDensityEach, setDistanceDensityEach] = useQueryString("ddea", true);
    //is historic
    const [historicData, setHistoricData] = useQueryString("hist", false);
    //excludes invalid geolocations
    const [hideUnkown, setHideUnkown] = useQueryString("hiun", true);
    //is clustered
    const [clusterMarkers, setClusterMarkers] = useQueryString("clus", true);
    //hides table of robots on the side
    const [showTable, setShowTable] = useQueryString("shta", true);
    //hides robots outside of viewport in the table
    const [onlyShowVisible, setOnlyShowVisible] = useQueryString("shvi", true);
    //tints entries in the table
    const [tintEntries, setTintEntries] = useQueryString("tint", true);
    //enables geocoding for export
    const [geocodeOnExport, setGeocodeOnExport] = useQueryString("geoc", true);

    const [fullscreen,setFullscreen]=useState(false)
    const [showIonosphere,setShowIonosphere]=useState(false)
    const [ionosphere, setIonosphere]=useState([] as IonospherePolygon[])
    const [ggl,setGgl]=useState(undefined as unknown as GMgoogle)
    useEffect(()=>{
        if (!ggl && API_KEY)
        {
           new GMLoader(API_KEY).load().then((gg) => {
                setGgl(gg)
           })
        }
    },[ggl,API_KEY])

    const {pending:pendingIo,exec:fetchIono}=useAsync(()=>LocationService.getIonosphere(moment.unix(ionoDate).toDate()),
        {immediate:false,
        onError: (val : any) => {
        displayToast({
            message: val.message,
            severity: "error",
            withCloseIcon: true,
        });
    }, onComplete:(val)=>{setIonosphere(val)}})
    useEffect(()=>{
        if (showIonosphere)
        {
            fetchIono()
        }
    },[showIonosphere,ionoDate]) //eslint-disable-line react-hooks/exhaustive-deps

    const [mapIniting,setMapIniting]=useState(false)
    //initializes map objects
    const initMap = useCallback(
        //loads google API
        async () =>  {
            
            //init marker image
            if (ggl){
            setMapIniting(true)
            setMarkerImage({
                anchor: new ggl.maps.Point(12,21),
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: "#000000",
                scale: 1.8,
                labelOrigin: new ggl.maps.Point(12,9),
            });
            //init infowindow
            const infowindow_ = new ggl.maps.InfoWindow({content: "",});
            setInfowindow(infowindow_);
            //init map
            const mapDiv_ = document.getElementById("map")!;
            const map_ = new ggl.maps.Map(
                mapDiv_,
                { 
                    minZoom: Math.log2(mapDiv_.clientWidth)-8,
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControlOptions: {position: ggl.maps.ControlPosition.TOP_RIGHT},
                },
            );
            map_.fitBounds(new ggl.maps.LatLngBounds(new ggl.maps.LatLng(boundsLat1, boundsLng1),new ggl.maps.LatLng(boundsLat2, boundsLng2)),0);
            //init clusterer
            setClusterer(new MarkerClusterer(
                map_,
                [],
                {
                    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                    calculator: (mrk: google.maps.Marker[], max: number) => {
                        let index = max;
                        if (mrk.length.toString().length < max)
                            index = mrk.length.toString().length;
                        let title = "";
                        for (let i = 0; i < mrk.length && mrk.length <= 10; i++) {
                            title += mrk[i].getLabel();
                            if (i < mrk.length-1)
                                title += "\n";
                        }
                        return {title, index, text: mrk.length.toString()};
                    },
                    gridSize: 45,
                }
            ));
            //listeners for closing the open infowindow and updating the bounds
            ggl.maps.event.addListener(map_, "dragstart", () => {
                infowindow_.close();
            });
            ggl.maps.event.addListener(map_, "click", () =>{
                infowindow_.close();
            });
            ggl.maps.event.addListener(map_, "dragend", () => {
                setBoundsLat1(map_.getBounds()?.getSouthWest().lat());
                setBoundsLng1(map_.getBounds()?.getSouthWest().lng());
                setBoundsLat2(map_.getBounds()?.getNorthEast().lat());
                setBoundsLng2(map_.getBounds()?.getNorthEast().lng());
            });
            ggl.maps.event.addListener(map_, "zoom_changed", () => {
                ggl.maps.event.trigger(map_, "dragend");
            });
            ggl.maps.event.addListenerOnce(map_,'tilesloaded',()=>{
                setTimeout(()=>setMapIniting(false),10)
            })
            //listen to map resizing and disable repeating earth pattern, by setting the minzoom
            new ResizeObserver(() => {
                ggl.maps.event.trigger(map_, "dragend");
                map_.setOptions({minZoom: Math.log2(mapDiv_.clientWidth)-8});
            }).observe(mapDiv_);
            //init dummy marker
            setDummyMarker(new ggl.maps.Marker({position: {lat: 0, lng: 0},}));
            if (ionosphere?.length>0){
                ionosphere.forEach((polygon:IonospherePolygon)=>{
                    const polyg= new ggl.maps.Polygon({
                        paths: polygon.coordinates.map((pol)=>{return {lat:Number(pol.lat),lng:Number(pol.lng)}}),
                        strokeColor: "#"+polygon.color.slice(6,8)+polygon.color.slice(4,6)+polygon.color.slice(2,4)+polygon.color.slice(0,2),
                        fillColor: "#"+polygon.color.slice(6,8)+polygon.color.slice(4,6)+polygon.color.slice(2,4)+polygon.color.slice(0,2),
                        strokeOpacity: 0.2,
                        fillOpacity:0.8
                        
                    })
                    polyg.setMap(map_!)
                })
                
            }
            setMap(map_);
        }
        }, [ionosphere,ggl] //eslint-disable-line react-hooks/exhaustive-deps
    );
    //fetches data
    const fetchData1 = useCallback(
        async () => {
            let options : Partial<LocationFilterOptions> | undefined;
            if (historicData && lastDays === -1 && robotFilter.length <= HISTORIC_MAX_LENGTH)
                options = {history: historicData, from: moment.unix(from).toDate(), until: moment.unix(until).toDate(), robots: robotFilter};
            else if (historicData && lastDays === -2 && robotFilter.length <= HISTORIC_MAX_LENGTH)
                options = {history: historicData, from: moment.unix(moment.now()/1000).subtract(1, 'month').set("date", 1).set("hour", 0).set("minute", 0).set("second", 0).set("ms", 0).toDate(), until: moment.unix(moment.now()/1000).set("date", 1).set("hour", 0).set("minute", 0).set("second", 0).set("ms", 0).toDate(), robots: robotFilter};
            else if (historicData && robotFilter.length <= HISTORIC_MAX_LENGTH)
                options = {history: historicData, from: moment.unix(moment.now()/1000).subtract(lastDays, 'days').toDate(), until: moment.unix(moment.now()/1000).toDate(), robots: robotFilter};
            const robots_ = (await LocationService.getRobotsLocation(options)).filter(r => (r.lat !== null && r.lng !== null));
            for (const robot_ of robots_) {
                //marker based on the location
                robot_.marker = new google.maps.Marker({
                    position: { lat: robot_.lat, lng: robot_.lng },
                    label: robot_.id.toString(),
                    icon: { ...markerImage, fillColor: getColor(robot_), path: GetIconPath(robot_.id.toString()) },
                });
                robot_.marker.setMap(null);
                //makeshift solution to initialize internal values of every marker (popup infowindow is not going to work without it)
                //recurive call, in case that the map is not loaded yet, with exponential timeout and upper limit to not freeze the browser
                let expTimeout = 10;
                const recursiveInit = () => setTimeout(() => {
                    if (map !== undefined) {
                        setTimeout(() => robot_.marker!.setMap(null), 10);
                    } else {
                        expTimeout = 2 * expTimeout;
                        if (expTimeout < 10000)
                            recursiveInit();
                    }
                }, expTimeout);
                if (clusterMarkers)
                    recursiveInit();
                google.maps.event.addListener(robot_.marker, "click", () => {
                    infowindow!.close();
                    infowindow!.setContent(GetRobotString(robot_)+"<br>"+robot_.customerName+" ("+robot_.customer+")<br>"+robot_.dealerName+" ("+robot_.dealer+")<br>");
                    infowindow!.open(
                        map,
                        robot_.marker
                    );
                });
                //function for enabling the marker on the map, without clustering
                robot_.enableStandalone = () => {
                    if (robot_.marker !== undefined) {
                        robot_.marker.setMap(map ?? null);
                        clusterer?.removeMarker(robot_.marker, true);
                    }
                };
                //function for enabling the marker on the map, with clustering
                robot_.enableCluster = () => {
                    if (robot_.marker !== undefined) {
                        robot_.marker.setMap(null);
                        if (!clusterer?.getMarkers().includes(robot_.marker))
                            clusterer?.addMarker(robot_.marker, true);
                    }
                };
                //function for disabling the marker on the map
                robot_.disable = () => {
                    if (robot_.marker !== undefined) {
                        robot_.marker.setMap(null);
                        clusterer?.removeMarker(robot_.marker, true);
                    }
                };
            }
            setAllRobots(robots_);
            return robots_;
        }, [clusterMarkers, historicData, from, until, lastDays, robotFilter, markerImage, infowindow, clusterer, map, getColor]
    );
    const { pending, exec: fetchData2 } = useAsync(fetchData1, {
        immediate: false,
        onError: (val : any) => {
            displayToast({
                message: val.message,
                severity: "error",
                withCloseIcon: true,
            });
        },
    });

    const fetchData = useCallback(() => {
        //remove old listeners
        if (robotTypes && robotTypes.length>0){
        for (const listener of listeners)
            google.maps.event.removeListener(listener);
        //disable old markers
        for (const robot of allRobots ?? [])
            robot.disable();
        redraw();
        clusterer?.clearMarkers();
        //clear robots
        setAllRobots([]);
        fetchData2();
         }
    }, [listeners, allRobots, clusterer, fetchData2, redraw,robotTypes]);
    //init map
    useEffect(() => {
        if (customers.length !== 0 && robots !== undefined && ggl ) {
            try{
            initMap().catch(() => {
                displayToast({
                    message: "failed to initialize map",
                    severity: "error",
                    withCloseIcon: true,
                });
            })}catch(e){console.log(e)}
        }
    }, [robots, customers, displayToast, initMap,ggl,ionosphere ]); //eslint-disable-line react-hooks/exhaustive-deps

    //fetch robots if historic
    const fetchIfHistoric = useCallback(() => {
            if (map !== undefined)
                if (historicData)
                    fetchData();
    }, [map, historicData, fetchData]);

    //fetch robots if historic and time constraint changed or new robot is added to the filters
    const [lastRobotFilter, setLastRobotFilter] = useState(new Array<number>(0));
    useEffect(() => {
        if (lastRobotFilter.length <= robotFilter.length)
            fetchIfHistoric();
        setLastRobotFilter(robotFilter);
    }, [from, until, lastDays, robotFilter]); //eslint-disable-line react-hooks/exhaustive-deps

    //fetch robots
    useEffect(() => {
        if (map !== undefined)
            fetchData();
    }, [map, historicData,robotTypes]); //eslint-disable-line react-hooks/exhaustive-deps

    //filter robots
    useEffect(() => {
        if (map !== undefined) {
            //handle historic querring limitation
            if ((robotFilter.length > HISTORIC_MAX_LENGTH) && historicData) {
                setHistoricData(false);
                displayToast({
                    message: "Can not query historic data for more than "+HISTORIC_MAX_LENGTH+" robots!",
                    severity: "warning",
                    withCloseIcon: true,
                });
                return;
            }
            //filter robots
            const filtered = allRobots.filter((robot, index, array) => {
                //filter based on robotFilter if not historic
                let res1 = true;
                if (robotFilter.length !== 0 || historicData)
                    res1 = robotFilter.includes(robot.id);
                //filter based on distanceDensity if historic
                let res2 = true;
                if (historicData) {
                    let history = new Array<RobotMap>(0);
                    for (let i = index - 1; i >= 0; i--)
                        if (!distanceDensityEach || array[i].id === robot.id)
                            history.push(array[i]);
                        else
                            break;
                    history.reverse();
                    for (let i = 1; i < history.length; i++)
                        if (history.slice(0, i).some((_r, j) => {return distanceDensity > distance(history[j].lat, history[j].lng, history[i].lat, history[i].lng)})) { //eslint-disable-line no-loop-func
                            history = history.filter((_r, j) => {return j !== i});
                            i--;
                        }
                    res2 = history.every((r) => {return distanceDensity <= distance(r.lat, r.lng, robot.lat, robot.lng)});
                }
                //filter based on typeFilter if not historic
                let res3 = true;
                if (typeFilter.length !== 0 && !historicData)
                    res3 = !typeFilter.includes(robot.type);
                //filter based on hideUnkown
                let res4 = true;
                if (hideUnkown)
                    res4 = IsValidLocation(robot.lat, robot.lng);
                return res1 && res2 && res3 && res4;
            });
            setFilteredRobots(filtered);
            //refresh visible robots
            setVisibleRobots(
                filtered.filter((robot) => {
                    return map.getBounds()!.contains(robot.marker!.getPosition()!);
                })
            );
        }
    }, [allRobots, robotFilter, distanceDensity, distanceDensityEach, historicData, typeFilter, hideUnkown]); //eslint-disable-line react-hooks/exhaustive-deps

    //refresh liteners on change of filteredRobots or clusterMarkers
    useEffect(() => {
        if (map !== undefined) {
            for (const listener of listeners)
                google.maps.event.removeListener(listener);
            const listeners_ = new Array<google.maps.MapsEventListener>(0);
            let firstTrigger = true;
            listeners_.push(google.maps.event.addListener(map, "bounds_changed", () =>
            {
                //refresh visible robots if bounds changed
                setVisibleRobots(
                    filteredRobots.filter((robot) => {
                        return map.getBounds()!.contains(robot.marker!.getPosition()!);
                    })
                );
                //weird bug, needs two useEffect hooks to function properly, one of them interrupting on the first trigger of the listener
                if (firstTrigger) {
                    firstTrigger = false;
                    return;
                }
                //turn on/off markers based on if they are in the map viewport to avoid lagg
                if (!clusterMarkers)
                    for (const robot of filteredRobots ?? [])
                        if (map.getBounds()!.contains(robot.marker!.getPosition()!))
                            robot.enableStandalone();
                        else
                            robot.disable();
            }));
            setListeners(listeners_);
        }
    }, [filteredRobots]); //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (map !== undefined) {
            for (const listener of listeners)
                google.maps.event.removeListener(listener);
            const listeners_ = new Array<google.maps.MapsEventListener>(0);
            listeners_.push(google.maps.event.addListener(map, "bounds_changed", () =>
            {
                setVisibleRobots(
                    filteredRobots.filter((robot) => {
                        return map.getBounds()!.contains(robot.marker!.getPosition()!);
                    })
                );
                if (!clusterMarkers)
                    for (const robot of filteredRobots ?? [])
                        if (map.getBounds()!.contains(robot.marker!.getPosition()!))
                            robot.enableStandalone();
                        else
                            robot.disable();
            }));
            setListeners(listeners_);
        }
    }, [clusterMarkers]); //eslint-disable-line react-hooks/exhaustive-deps

    //clustering change
    useEffect(() => {
        if (map !== undefined && !pending) {
            for (const robot of allRobots ?? [])
                if (!filteredRobots.includes(robot))
                    robot.disable();
                else if (clusterMarkers)
                    robot.enableCluster();
                else
                    if (map.getBounds()!.contains(robot.marker!.getPosition()!))
                        robot.enableStandalone();
                    else
                        robot.disable();
            if (!clusterMarkers)
                clusterer?.clearMarkers();
            redraw();
            if (clusterMarkers) {
                setTimeout(redraw, 500);
                setTimeout(redraw, 1000);
                setTimeout(redraw, 3000);
                setTimeout(redraw, 5000);
            }
        }
    }, [filteredRobots, clusterMarkers, pending]); //eslint-disable-line react-hooks/exhaustive-deps

    //prepare the table of robots
    const table = useMemo(() => {
        const keys = new Array<string>(0);
        return (onlyShowVisible ? visibleRobots : filteredRobots).sort((a, b) => {
            if (a.id < b.id)
                return -1;
            if (a.id > b.id)
                return 1;
            if (a.timestamp < b.timestamp)
                return -1;
            if (a.timestamp > b.timestamp)
                return 1;
            return 0;
        }).map((robot) => {
            return (
                <div
                    onClick={() => {
                        if (infowindow !== undefined && map !== undefined)
                        {
                            const content = GetRobotString(robot)+"<br>"+robot.customerName+" ("+robot.customer+")<br>"+robot.dealerName+" ("+robot.dealer+")<br>";
                            infowindow.close();
                            infowindow.setContent(content);
                            infowindow.open(map, robot.marker);
                            setTimeout(() => {
                                if (infowindow.getContent() === content)
                                    infowindow.open(map, robot.marker);
                            }, 1000);
                        }
                    }}
                    key={robot.id+" "+robot.timestamp+" "+robot.lat+" "+robot.lng}
                    style={tintEntries ? {backgroundColor: getColor(robot)} : {}}
                >
                    {GetRobotString(robot)}
                </div>
            );
        });
    }, [filteredRobots,visibleRobots,onlyShowVisible,tintEntries,getColor,map,infowindow]);

    //prepare robotFilter
    const robotFilterVisual = useMemo(() => {
        return robotFilter.map((id: number) => {
            const robot = robots?.find((robot_ : RobotMap) => {return robot_.id === id;});
            return (
                <div
                    key={id}
                    onClick={() => {
                        if(robotFilter.length === 1)
                            setTypeFilter(robotTypes);
                        setRobotFilter(robotFilter.filter((id_: number) => {return id_ !== id;}));}}
                >
                    {id+" "+robot?.robotName+" ✕"}&nbsp;
                </div>
            );
        });
    }, [robotFilter, robots, setTypeFilter, robotTypes, setRobotFilter]);

    //prepare legend
    const legend = useMemo(() => {
        if (historicData && lastDays === -1)
            return (
                <div>
                    <div style={{height: "80px", background: "linear-gradient("+OLD_COLOR+","+NEW_COLOR+")"}}></div>
                    <div style={{height: "80px",display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>{moment.unix(from).utcOffset(timezone).format(TimeFormats.PresentationShort)}</div>
                        <div>{moment.unix(until).utcOffset(timezone).format(TimeFormats.PresentationShort)}</div>
                    </div>
                </div>
            );
        else if (historicData)
            return (
                <div>
                    <div style={{height: "80px", background: "linear-gradient("+OLD_COLOR+","+NEW_COLOR+")"}}></div>
                    <div style={{height: "80px",display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>{moment.unix(moment.now()/1000).subtract(lastDays, 'days').utcOffset(timezone).format(TimeFormats.PresentationShort)}</div>
                        <div>{moment.unix(moment.now()/1000).utcOffset(timezone).format(TimeFormats.PresentationShort)}</div>
                    </div>
                </div>
            );
        else
            return robotTypes.map((type) => {
                const isChecked = !typeFilter.includes(type);
                const onClick = () => {
                    if (typeFilter.includes(type))
                        setTypeFilter(typeFilter.filter((t: string) => {return t !== type;}));
                    else
                        setTypeFilter([...typeFilter, type]);
                };
                return (
                    <div key={type}>
                        <div onClick={onClick} style={{backgroundColor:getColor(type)}}>{isChecked && <CheckIcon/>}</div>
                        <div onClick={onClick} className={!isChecked?classes.legendDisabled:classes.legendEnabled}>{type}</div>
                    </div>
                );
            });
    }, [historicData, from, until, lastDays, robotTypes, typeFilter, classes.legendDisabled, classes.legendEnabled, getColor, setTypeFilter]);

    //prepare csv, optionally geocode
    //needs refactoring in the future as it is spaghetti now, with the use of the "ↀ" special character, splitting and reemerging values
    const [progress, setPromises] = useBatchPromise();
    const csvHeaders = ["Robot Id","Robot Name","Robot Type","Customer Id","Customer Name","Dealer Id","Dealer Name","Timestamp","Location Link","CountryↀStateↀCityↀRoadↀPostcode"];
    const csvKeys = ["id","robotName","type","customer","customerName","dealer","dealerName","timestamp","location","address"];
    const exportCSV = () => {
        return new Promise<void>(async resolve => { //eslint-disable-line no-async-promise-executor
            let geocodingFailed = false;
            const promises = new Array<Promise<void>>(0);
            const unknownRobotIds = robotFilter.filter((id) => {return filteredRobots.find((robot) => {return robot.id === id;}) === undefined;});
            const unknownRobots = robots?.filter((robot : RobotMap) => {return unknownRobotIds.includes(robot.id);}) ?? [];
            const csv : any[][] = new Array<any>(csvHeaders.length);
            for (let i = 0; i < csvHeaders.length; i++)
                csv[i] = new Array<any>(filteredRobots.length + unknownRobots.length + 1);
            for (let i = 0; i < csvHeaders.length; i++) {
                const currentHeader = csvHeaders[i];
                const currentKey = csvKeys[i];
                csv[i][0] = currentHeader;
                let lastRobot = -1;
                for (let j = 0; j < filteredRobots.length; j++) {
                    const isRedundant = lastRobot === filteredRobots[j].id;
                    if (!isRedundant)
                        lastRobot = filteredRobots[j].id;
                    if (currentKey === "location") {
                        const swla = filteredRobots[j].lat - 0.1;
                        const swln = filteredRobots[j].lng - 0.2;
                        const nela = filteredRobots[j].lat + 0.1;
                        const neln = filteredRobots[j].lng + 0.2;
                        const from_ = (filteredRobots[j].timestamp - 1).toFixed(0);
                        const unti_ = (filteredRobots[j].timestamp + 1).toFixed(0);
                        csv[i][j + 1] = window.location.protocol+"//"+window.location.host+"/map?swla="+swla+"&swln="+swln+"&nela="+nela+"&neln="+neln+"&hist=true&rofi[0]="+filteredRobots[j].id+"&from="+from_+"&unti="+unti_;
                    }
                    else if (currentKey === "timestamp")
                        csv[i][j + 1] = moment.unix(filteredRobots[j].timestamp).utcOffset(timezone).format(TimeFormats.Presentation2);
                    else if (currentKey === "address") {
                        if (geocodeOnExport)
                            promises.push(LocationService.geocode(filteredRobots[j].lat,filteredRobots[j].lng).then((add) => { //eslint-disable-line no-loop-func
                                csv[i][j + 1] = add.country+"ↀ"+add.state+"ↀ"+add.city+"ↀ"+add.road+"ↀ"+add.postcode;
                                if (add.country === "?")
                                    geocodingFailed = true;
                            }).catch(() => { //eslint-disable-line no-loop-func
                                csv[i][j + 1] = "?ↀ?ↀ?ↀ?ↀ?";
                                geocodingFailed = true;
                            }));
                        else
                            csv[i][j + 1] = "?ↀ?ↀ?ↀ?ↀ?";
                    }
                    else if (!isRedundant)
                        csv[i][j + 1] = (filteredRobots[j] as {[key: string]: any})[currentKey];
                    else
                        csv[i][j + 1] = "";
                }
                for (let j = 0; j < unknownRobots.length; j++) {
                    if (currentKey === "location" || currentKey === "timestamp")
                        csv[i][j + 1 + filteredRobots.length] = "-";
                    else if (currentKey === "address")
                        csv[i][j + 1 + filteredRobots.length] = "-ↀ-ↀ-ↀ-ↀ-";
                    else
                        csv[i][j + 1 + filteredRobots.length] = (unknownRobots[j] as {[key: string]: any})[currentKey];
                }
            }
            setPromises(promises);
            await Promise.all(promises);
            if (geocodingFailed)
                displayToast({
                    message: "Geocoding failed, sorry for the inconvenience!",
                    severity: "warning",
                    withCloseIcon: true,
                });
            const currentLink = window.location.href.replace(/[\r\n]+/g," ").replaceAll(";",",");
            let csvContent = "data:text/csv;charset=utf-8,";
            for (let j = 0; j < filteredRobots.length + unknownRobots.length + 1; j++) {
                for (let i = 0; i < csvHeaders.length; i++) {
                    if (csv[i][j] != null) {
                        if (typeof csv[i][j] === "string" || csv[i][j] instanceof String)
                            csvContent += (csv[i][j] as string).replace(/[\r\n]+/g," ").replaceAll(";",",").replaceAll("ↀ",";");
                        else
                            csvContent += csv[i][j];
                    }
                    if (i !== csvHeaders.length - 1)
                        csvContent += ";";
                    else if (j === 0)
                        csvContent += ";Service Center Link";
                    else if (j === 1)
                        csvContent += ";"+currentLink;
                }
                if (j !== filteredRobots.length + unknownRobots.length)
                    csvContent += "\r\n";
            }
            const temp_link = document.createElement("a");
            document.body.appendChild(temp_link);
            temp_link.href = encodeURI(csvContent).replace(/#/g, '%23');
            temp_link.download = "robot_location_list.csv";
            temp_link.click();
            temp_link.remove();
            resolve();
        });
    };

    //fits all the markers on the map, into the viewport
    const fitMarkers = useCallback(() => {
        if (map !== undefined) {
            let swla = 90;
            let swln = 180;
            let nela = -90;
            let neln = -180;
            for (const robot of filteredRobots) {
                if (robot.lat < swla)
                    swla = robot.lat;
                if (robot.lat > nela)
                    nela = robot.lat;
                if (robot.lng < swln)
                    swln = robot.lng;
                if (robot.lng > neln)
                    neln = robot.lng;
            }
            map.fitBounds(new google.maps.LatLngBounds(new google.maps.LatLng(swla, swln),new google.maps.LatLng(nela, neln)),0);
        }
    }, [map, filteredRobots]);

    let fromDatepickerValue, untilDatepickerValue;
    if (lastDays === -1) {
        fromDatepickerValue = moment.unix(from);
        untilDatepickerValue = moment.unix(until);
    } else if (lastDays === -2) {
        fromDatepickerValue = moment.unix(moment.now()/1000).subtract(1, 'month').set("date", 1).set("hour", 0).set("minute", 0).set("second", 0).set("ms", 0);
        untilDatepickerValue = moment.unix(moment.now()/1000).set("date", 1).set("hour", 0).set("minute", 0).set("second", 0).set("ms", 0);
    } else {
        fromDatepickerValue = moment.unix(moment.now()/1000).subtract(lastDays, 'days');
        untilDatepickerValue = moment.unix(moment.now()/1000);
    }
    
    return (
        <div className={classes.wrapper}>
            <Prompt when={true} message={() => {
                if (map !== undefined)
                    google.maps.event.clearListeners(map, "dragend");
                return true;
            }}/>
            {!fullscreen && <div><div className={classes.control}>
                {/* <div> */}
                    <FormControlLabel
                        style={{marginLeft:"10px", marginRight:"10px"}}
                        control={
                            <Switch
                                checked={historicData}
                                size="small"
                                onChange={(e) => {
                                    if (robotFilter.length > HISTORIC_MAX_LENGTH)
                                        displayToast({
                                            message: "Can not query historic data for more than "+HISTORIC_MAX_LENGTH+" robots!",
                                            severity: "warning",
                                            withCloseIcon: true,
                                        });
                                    else
                                        setHistoricData(e.target.checked);
                                }}
                            />
                        }
                        label="Historic data"
                        disabled={pending || map === undefined || !historicLocationAccess}
                    />
                    <DateTimePicker
                        style={{width: "180px", marginRight: "10px"}}
                        disableFuture
                        fullWidth
                        label="From"
                        margin="dense"
                        inputVariant="outlined"
                        value={fromDatepickerValue}
                        onChange={(date) => {
                            if (date?.isBefore(moment.unix(until)))
                                setFrom(date.unix());
                        }}
                        maxDate={
                            (lastDays === -1) ?
                                moment.unix(until) || undefined
                            :
                                undefined
                        }
                        ampm={false}
                        strictCompareDates
                        disabled={pending || map === undefined || lastDays !== -1 || !historicData}
                    />
                    <DateTimePicker
                        style={{width: "180px"}}
                        disableFuture
                        fullWidth
                        label="Until"
                        margin="dense"
                        inputVariant="outlined"
                        value={untilDatepickerValue}
                        onChange={(date) => {
                            if (date?.isAfter(moment.unix(from)))
                                setUntil(date.unix());
                        }}
                        minDate={
                            (lastDays === -1) ?
                                moment.unix(from) || undefined
                            :
                                undefined
                        }
                        ampm={false}
                        strictCompareDates
                        disabled={pending || map === undefined || lastDays !== -1 || !historicData}
                    />
                    <TextField
                        style={{minWidth: "110px", marginLeft: "10px", marginRight: "20px"}}
                        className={classes.option}
                        label="Last n days"
                        margin="dense"
                        size="small"
                        select
                        value={lastDays}
                        onChange={(ev: any) => {setLastDays(ev.target.value);}}
                        variant="outlined"
                        disabled={pending || map === undefined || !historicData}
                    >
                        <MenuItem key={-1} value={-1}>—</MenuItem>
                        <MenuItem key={-2} value={-2}>Last Month (1st-31st)</MenuItem>
                        <MenuItem key={7} value={7}>7 Days</MenuItem>
                        <MenuItem key={30} value={30}>30 Days</MenuItem>
                        <MenuItem key={180} value={180}>180 Days</MenuItem>
                        <MenuItem key={365} value={365}>365 Days</MenuItem>
                    </TextField>
                    <Tooltip title={(pending || map === undefined || !historicData) ? "" : "Filters entries based on how close they are to each other."}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Slider
                                style={{width: "150px", marginRight: "20px"}}
                                value={distanceDensityVisual/100}
                                onChangeCommitted={(_e, val) => {setDistanceDensity(Number(val)*100);}}
                                onChange={(_e, val) => {setDistanceDensityVisual(Number(val)*100);}}
                                disabled={pending || map === undefined || !historicData}
                            />
                            <div>
                                <Typography
                                    style={(pending || map === undefined || !historicData) ? {color: "#AAAAAA", cursor: "default", whiteSpace: "nowrap"} : {cursor: "default", whiteSpace: "nowrap"}}
                                >
                                    Distance Density
                                </Typography>
                                <Typography
                                    style={(pending || map === undefined || !historicData) ? {color: "#AAAAAA", cursor: "default"} : {cursor: "default"}}
                                >
                                    {distanceDensityVisual < 1000 ? (distanceDensityVisual+" m") : ((distanceDensityVisual/1000).toFixed(1)+" km")}
                                </Typography>
                            </div>
                        </div>
                    </Tooltip>
                    <Tooltip title={(pending || map === undefined || !historicData) ? "" : "Applies the distance density filter for each robot separately."}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Checkbox
                                disabled={pending || map === undefined || !historicData}
                                checked={distanceDensityEach}
                                onClick={() => {setDistanceDensityEach(!distanceDensityEach);}}
                            />
                            <Typography
                                style={(pending || map === undefined || !historicData) ? {color: "#AAAAAA", cursor: "default"} : {cursor: "default"}}
                            >
                                For Each Robot
                            </Typography>
                        </div>
                    </Tooltip>
                {/* </div> */}
                {/* <div> */}
                    <FormControlLabel
                        style={{margin:"0px"}}
                        control={
                            <Switch
                                checked={hideUnkown}
                                size="small"
                                onChange={(e) => {setHideUnkown(e.target.checked);}}
                            />
                        }
                        label="Hide unknown"
                        disabled={pending || map === undefined}
                    />
                    <FormControlLabel
                        style={{margin:"0px"}}
                        control={
                            <Switch
                                checked={clusterMarkers}
                                size="small"
                                onChange={(e) => {setClusterMarkers(e.target.checked);}}
                            />
                        }
                        label="Cluster markers"
                        disabled={pending || map === undefined}
                    />
                    
                    <RefreshButton
                        loading={pending || map === undefined}
                        title="Refresh data"
                        onClick={fetchData}
                    />
                {/* </div> */}
            </div>
            <div className={classes.filters}>
                <div>
                    <AutocompleteV2
                        clearOnSelectAndBlur
                        style={{minWidth: "200px", marginLeft: "10px", marginBottom: "4px"}}
                        disabled={pendingRobots || pending || map === undefined}
                        options={robots ?? []}
                        getOptionLabel={(r : RobotMap) => `(${r.id}) ${r.robotName}`}
                        noOptionsText="robot not found"
                        onChange={(_e, val) => {
                            if (val !== null)
                                if (!robotFilter.includes(val.id)) {
                                    setRobotFilter([...robotFilter, val.id]);
                                    setTypeFilter(typeFilter.filter(f => f !== val?.type))
                                }
                        }}
                        renderInput={params =>
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                label="Add Robot"
                            />
                        }
                        loading={robots?.length === 0}
                    />
                    <AutocompleteV2
                        clearOnSelectAndBlur
                        style={{minWidth: "200px", marginLeft: "10px", marginBottom: "4px"}}
                        disabled={customers.length === 0 || pending || map === undefined}
                        options={customers}
                        getOptionLabel={(c) => `(${c.id}) ${c.name}`}
                        noOptionsText="customer not found"
                        onChange={(_e, val) => {
                            if (val !== null) {
                               
                                const temp = robots?.filter((robot : RobotMap) => {
                                    if (Number(robot.dealer) !== Number(val.id) && Number(robot.customer) !== Number(val.id))
                                        return false;
                                    return !robotFilter.includes(robot.id);
                                });
                                if (temp !== undefined)
                                {
                                    setRobotFilter([...robotFilter, ...temp.map((robot : RobotMap) => {return robot.id;})]);
                                    setTypeFilter(typeFilter.filter(f => !temp.map((robot : RobotMap) => {return robot.type;}).includes(f)))
                                }
                            }
                        }}
                        renderInput={params =>
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                label="Add Customer"
                            />
                        }
                    />
                    <div className={classes.robotFilter}>
                        {robotFilterVisual}
                    </div>
                </div>
                <div>
                    <Tooltip
                        title="Clear filters"
                    >
                        <IconButton
                            disabled={pending || map === undefined}
                            onClick={() => {if (robotFilter.length !== 0) setRobotFilter([]); setTypeFilter(robotTypes);}}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                    <Button
                        style={{minWidth: "110px"}}
                        className={classes.button}
                        onClick={fitMarkers}
                    >
                        Zoom to Fit
                    </Button>
                    <ToggleVisibility
                        title={showTable ? "Hide table" : "Show table"}
                        checked={showTable}
                        onClick={(val) => {setShowTable(val);}}
                        disabled={pending || map === undefined}
                    />
                </div>
            </div></div>}
            <div className={classes.map}>
              
                { <div className={!fullscreen?classes.legend:""}>
                    {!fullscreen?legend:<div></div>}    
               </div>}
              
                 <div id="map"></div>
                 {showIonosphere&&<Grid container direction="row" className={classes.ionoGradient}>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>1</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>2</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>3</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>4</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>5</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>6</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>7</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>8</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>9</div></div>
                    <div className={classes.ionoGradientPart}><div className={classes.ionoGradientNumber}>10</div></div>
                    
                 </Grid>}
                {!fullscreen?<div style={(showTable ) ? {} : {display: "none"}} className={classes.map_sidebar}>
                    <div>
                        <div>
                            <FormControlLabel
                                style={{margin:"0px"}}
                                control={
                                    <Switch
                                        checked={onlyShowVisible}
                                        size="small"
                                        onChange={(e) => {setOnlyShowVisible(e.target.checked);}}
                                    />
                                }
                                label="Only show visible"
                                disabled={pending || map === undefined}
                            />
                            <FormControlLabel
                                style={{margin:"0px"}}
                                control={
                                    <Switch
                                        checked={tintEntries}
                                        size="small"
                                        onChange={(e) => {setTintEntries(e.target.checked);}}
                                    />
                                }
                                label="Tint entries"
                                disabled={pending || map === undefined}
                            />
                            <FormControlLabel
                                style={{margin:"0px"}}
                                control={
                                    <Switch
                                        checked={geocodeOnExport}
                                        size="small"
                                        onChange={(e) => {setGeocodeOnExport(e.target.checked);}}
                                    />
                                }
                                label="Geocode on export"
                                disabled={pending || map === undefined}
                            />
                            <FormControlLabel
                                style={{margin:"0px"}}
                                control={
                                    <Switch
                                        checked={fullscreen}
                                        size="small"
                                        onChange={(e) => {setFullscreen(e.target.checked);}}
                                    />
                                }
                                label="Hide filters"
                                disabled={pending || map === undefined}
                            />
                            <FormControlLabel
                                style={{margin:"0px"}}
                                control={
                                    <Switch
                                        checked={showIonosphere}
                                        size="small"
                                        onChange={(e) => {
                                            setShowIonosphere(e.target.checked);
                                            if (!e.target.checked)
                                            setIonosphere([])
                                        }}
                                    />
                                }
                                label="Show Ionosphere"
                                disabled={pending || map === undefined || mapIniting}
                            />
                            {showIonosphere && 
                            <DateTimePicker
                                style={{width: "200px", margin: "10px"}}
                                disableFuture
                                fullWidth
                                label="Timestamp"
                                margin="dense"
                                inputVariant="outlined"
                                value={moment.unix(ionoDate)}
                                onChange={(date) => {
                                    if (date?.isBefore(moment.unix(until)))
                                        setIonoDate(date.unix());
                                }}
                                ampm={false}
                                strictCompareDates
                                disabled={pending || map === undefined || mapIniting}
                            />}
                        </div>
                        <ExportButton
                            progress={progress}
                            title="Export detailed CSV"
                            onClick={exportCSV}
                            disabled={pending || map === undefined}
                        />
                    </div>
                    <div>count: {table.length}</div>
                    <div>
                        {table}
                    </div>
                </div>:<div style={{position:"absolute",background:"white",right:0,padding:"5px"}}>
                <FormControlLabel
                style={{margin:"0px"}}
                control={
                    <Switch
                        checked={fullscreen}
                        size="small"
                        onChange={(e) => {setFullscreen(e.target.checked);}}
                    />
                }
                label="Hide filters"
                disabled={pending || map === undefined}
            /></div>
                }
            </div>
        </div>
    );
}