import { Box, Button, Checkbox, FormControlLabel, FormGroup,  Grid,    Switch,  TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AutocompleteV2 from "src/components/AutocompleteV2";
import LoadingButton from "src/components/LoadingButton";
import PasswordField from "src/components/PasswordField";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import {  customersArraySelector, customersLoadedSelector, userSelector, usersSelector } from "src/redux/app/selectors";
import UserService, {  Customer, User } from "src/services/UserService";
import getErrorProps from "src/utils/getErrorProps";
import BaseView from "../BaseView";
import NotFound from "../NotFound";
import useFormStyles from "./useFormStyles";
import { push } from "connected-react-router";
import { updateUser } from "src/redux/app/actions";
import useDialog from "src/hooks/useDialog";
import { userAccessSelector } from "src/redux/auth/selectors";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    accordion: {
        color: theme.palette.grey[700],
    },
    accordionBorder:{
        boxShadow:'none', 
        border: '1px solid rgb(150,150,150)'
    }
}));


type EditAccountInput = {
    username: string;
    password: string;
    confirmPassword: string;
    firstname: string;
    lastname: string;
    email: string;
    isTwoFactorAuthentificationEnabled: boolean;
};


const defaultRules = {
    required: "This field is required",
};

export default function EditAccount() {
    const classes = useFormStyles();
    const localClasses=useStyles()


    const own = !window.location.pathname.includes("accounts");
    const authorizedUser = useSelector(userSelector);
    const paramUsername = useParams<{username:string}>().username;
    const users = useSelector(usersSelector);
    const user = own ? authorizedUser : users.find(u => u.username === paramUsername);
    const history = useHistory();
    const { displayDialog } = useDialog();
    const { displayToast } = useToast();
    const dispatch = useDispatch();

    const [firstLoad, setfirstLoad] = useState(true);

    const fetchAccessFields = useCallback((() => UserService.getAccessFields()
    .then((afs)=>
    afs.sort((a,b)=>a.access_lvl>b.access_lvl?1:-1)
    )),[])
    const { value: accessFields, exec: fetchAccessFields_ } = useAsync(fetchAccessFields, {
        immediate: false,
    });
    useEffect(() => {
        fetchAccessFields_();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (firstLoad && user?.username!=="") {
            setfirstLoad(false);
    }}, [user]); //eslint-disable-line react-hooks/exhaustive-deps

    const customersLoaded = useSelector(customersLoadedSelector);
    const loading = !customersLoaded;
    const customers = useSelector(customersArraySelector);
    const [customer,setCustomers]= useState(user?.customerId.map((ci)=>customers.find((c)=>c.id===ci) as Customer).filter((c)=>c) ?? [{id: 0, name: "", parentId: 0, isDealer: false}])
    const [access,setAccess]=useState(accessFields?.map((af)=>{return {...af,hasAccess: Boolean(user?.userAccess.includes(af.tag))}})??[])


    const {
        handleSubmit,
        getValues,
        setValue,
        errors,
        setError,
        control,
    } = useForm<EditAccountInput>({ defaultValues: {...user }})

    useEffect(() => {
        if (user !== undefined && customers.length > 0) {
            for (const key of Object.keys(user))            
                setValue(key, (user as any)[key])
           setCustomers(user?.customerId.map((ci)=>customers?.find((c)=>c.id===ci) as Customer)?.filter((c)=>c)??[{id: 0, name: "", parentId: 0, isDealer: false}]);
           setAccess(accessFields?.map((af)=>{return {...af,hasAccess: user?.userAccess.includes(af.tag)}})??[] ) 
        }
    }, [user, customers, setValue,accessFields]);

    const onEditAccount = useCallback(
        async ({  username, ...rest }: EditAccountInput) => {
            if (authorizedUser.username === user?.username)
                if (!await displayDialog({dialogText: "Modifying your own account will result in interrupting all of your active sessions, and you will need to log in again.\nDo you wish to proceed?"})) {
                    displayToast({
                        message: "cancelled",
                        severity: "warning",
                        withCloseIcon: true,
                    });
                    return;
                }
            return UserService.updateUser(user?.username ?? "", { 
                customerId: customer.filter((c)=>c).map((cust:any)=>cust?.id),
                username: username === user?.username ? undefined : username,
                ...rest,
                userAccess: access.filter((af)=>af.hasAccess).map((af)=>af.tag),
            } as any);
        },
        [user, authorizedUser, displayDialog, displayToast,customer,access]
    );

    const onAccountEdited = useCallback(
        (newUser: User | undefined ) => {
            
            if (newUser === undefined)
                return;
            if (newUser.success===false){
                displayToast({message:newUser.message,severity:"error"})
                return}
            displayToast({
                message: "The account was updated.",
                severity: "success",
                withCloseIcon: true,
            });
            dispatch(updateUser({username: user?.username ?? "", user: newUser}));
            if (own)
                dispatch(push("/account/me"));
            else
                dispatch(push("/accounts/"+newUser.username));
        },
        [displayToast, dispatch, user, own]
    );

    const displayErrorToast = () => displayToast({
        message: "An unknown error has occurred. Please try reloading the page.",
        severity: "error",
    }); 

    const onAccountEditedError = useErrorHandler({
        onValidationError: setError,
        fallback: useCallback(displayErrorToast, [displayToast]), //eslint-disable-line react-hooks/exhaustive-deps
    });

    const { exec: editAccount, pending: editingAccount } = useAsync(
        onEditAccount,
        {
            immediate: false,
            onComplete: onAccountEdited,
            onError: onAccountEditedError,
        }
    );

    const onDeleteAccount = useCallback(
        async () => {
            if (authorizedUser.username === user?.username)
                if (!await displayDialog({dialogText: "You are about to delete your own account!\nDo you wish to proceed?"})) {
                    displayToast({
                        message: "cancelled",
                        severity: "warning",
                        withCloseIcon: true,
                    });
                    return;
                }
            return UserService.softdeleteUser(user?.username ?? "").then(u => {
                if (u !== undefined) {
                    dispatch(updateUser({username: user?.username ?? "", user: u}));
                    displayToast({
                        message: "The account was deleted.",
                        severity: "success",
                        withCloseIcon: true,
                    });
                    dispatch(push("/accounts/"+u.username));
                }
            }).catch(displayErrorToast);
        },
        [user, authorizedUser, dispatch, displayDialog, displayToast] //eslint-disable-line react-hooks/exhaustive-deps
    );
    const { exec: deleteAccount, pending: deletingAccount } = useAsync(
        onDeleteAccount,
        {
            immediate: false,
        }
    );

    const rootCustomer = useMemo(
        () => customers && customers.find((c) => c.id === 1),
        [customers]
    );
    type MappedInput = [keyof EditAccountInput, string, any];
    const userAccess = useSelector(userAccessSelector).includes("User");
    if ((user === undefined && users.length > 0) || (!userAccess && !own))
        return (<NotFound />);
    return (
        <BaseView loading={loading}>
            <Box component="main" className={classes.container}>
                <div className={classes.paperFlex}>
                    <Typography component="h1" variant="h4">
                        {own ? "Edit My Account" : "Edit Account"}
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(editAccount)}
                        noValidate
                    >
                        {([
                            ["username", "Username", defaultRules],
                            ["email", "Email", {}],
                            ["password", "Password", {}],
                            [
                                "confirmPassword",
                                "Confirm Password",
                                {
                                    validate: {
                                        matchesPreviousPassword: (
                                            value: string
                                        ) => {
                                            const { password } = getValues();
                                            return (
                                                value === password ||
                                                "Passwords should match!"
                                            );
                                        },
                                    },
                                },
                            ],
                        ] as MappedInput[]).map(([name, label, rule], idx) => {
                            return (
                                <Controller
                                    key={name}
                                    name={name}
                                    control={control}
                                    defaultValue=""
                                    rules={rule || defaultRules}
                                    render={(props) => {
                                        const Component =
                                            idx < 2
                                                ? TextField
                                                : PasswordField;
                                        return (
                                            <Component
                                                autoComplete="new-password"
                                                {...props}
                                                variant="outlined"
                                                margin="normal"
                                                required={rule.required !== undefined || (typeof rule.required === "boolean" && rule.required)}
                                                disabled={editingAccount}
                                                fullWidth
                                                id={name}
                                                label={label}
                                                {...getErrorProps(errors, name)}
                                            />
                                        );
                                    }}
                                />
                            );
                        })}
                        <Typography style={{color: "#777777"}}>To not modify the password, leave the fields blank!</Typography>
                        
                        <Grid container spacing={3} className={classes.selects}>
                           
                       
                        <Grid item xs={12} md={12}>
                            <Accordion className={localClasses.accordionBorder} >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}}/>} >
                                        <Box style={{display:"flex",justifyContent:"space-between",width:"100%",paddingRight:"20px"}}>
                                            <span style={{display: "flex",justifyContent: "center",alignItems: "center"}}>User Access</span>
                                            {userAccess && <Button 
                                            variant="contained"
                                            color="primary"
                                            onClick={(ev)=>{
                                                setAccess(accessFields?.map((af)=>{return {...af,hasAccess: user?.userAccess.includes(af.tag)??false}})??[]) 
                                                ev.stopPropagation()
                                            }}
                                        >Reset</Button>}
                                        </Box>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    {[...new Set(access?.map(af=>af.access_lvl))]?.map((lvl,index)=>
                                    (access?.filter((af)=>af.access_lvl===lvl).find((af)=>user?.userAccess.includes(af.tag)) || userAccess) 
                                    && (<Grid item xs={12} md={12} key={index}>
                                
                                        <Accordion className={localClasses.accordionBorder}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}} />} >
                                                <FormControlLabel 
                                                label={<span className={localClasses.accordion} onClick={(ev)=>{ev.stopPropagation()}}>{"Level "+lvl}</span>}
                                                style={{ pointerEvents: "none" }}
                                                control={
                                                    <Checkbox className={localClasses.accordion}
                                                    style={{color:"rgb(100,100,100)", pointerEvents: "auto"}}
                                                    checked={!(access?.filter((af)=>af.access_lvl===lvl).find((af)=>!af.hasAccess))}
                                                    indeterminate={Boolean(access.filter((af)=>af.access_lvl===lvl).find((af)=>!af.hasAccess)) && Boolean(access.filter((af)=>af.access_lvl===lvl).find((af)=>af.hasAccess))}
                                                    disabled={!userAccess}
                                                    onChange={
                                                        (ev)=>{
                                                            setAccess(access.map((acf)=>{return acf.access_lvl===lvl?{...acf,hasAccess:acf.tag==='Default' ? true : ev.target.checked}:acf}))
                                                        }
                                                    }
                                                    onClick={
                                                        (ev)=>{
                                                            ev.stopPropagation()
                                                        }
                                                    }
                                                    />
                                                }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>        
                                                <FormGroup>
                                                    {access?.filter((af)=>af.access_lvl===lvl).filter((af)=>(userAccess || user?.userAccess.includes(af.tag))).map((af,index)=>
                                                    <Tooltip title={(af.tag==='User' && !customer.find((cust)=>cust.id===1))?'Only Internal can have access':af.name}key = {index}>
                                                        <FormControlLabel
                                                        label={<span className={localClasses.accordion}>{af.tag}</span>}
                                                        style={{marginLeft:"10px"}}
                                                        disabled={af.tag==='Default' || !userAccess}
                                                        control={
                                                        <Checkbox 
                                                            checked={af.hasAccess}
                                                            style={{color:"rgb(100,100,100)"}}
                                                            disabled={ (af.tag==='User' && !customer.find((cust)=>cust.id===1)) || af.tag==='Default'}
                                                            onChange={(ev)=>{
                                                                setAccess(access.map((acf)=>{return acf.tag===af.tag?{...acf,hasAccess:ev.target.checked}:acf}))
                                                            }}
                                                            />}/>
                                                    </Tooltip>
                                                    )
                                                    }
                                                </FormGroup>
                                           
                                            </AccordionDetails>
                                          </Accordion>
                                
                                        </Grid>))}
                                    </AccordionDetails>   
                              </Accordion>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={3} className={classes.selects}>
                        {userAccess &&<Grid item xs={12} md={6}>
                              <Button
                            variant="contained"
                            color="primary"
                            style={{marginTop:"10px"}}
                            fullWidth
                            onClick={()=>setCustomers((cust:any)=>[...cust,{}])}>Add customer</Button>
                            </Grid>}
                            <Grid item xs={12} md={userAccess?6:12}>

                                {customer?.map((cust,index)=> {
                                   
                                return             (<AutocompleteV2
                                                key={index}
                                                style={{marginTop:index>0?20:0}}
                                                selectOnTab
                                               
                                                value={cust}
                                                disabled={
                                                    (cust ===
                                                        rootCustomer &&
                                                        userAccess) ||
                                                    editingAccount
                                                    || !userAccess
                                                }
                                                options={customers.filter((cus)=>!(customer?.find((c:any)=>c.id===cus.id) )|| cust.id===cus.id) || []}
                                                getOptionLabel={(opt) =>
                                                    opt?.name ?? ''
                                                }
                                                getOptionSelected={(c1,c2)=>c1.id===c2.id}
                                                noOptionsText="No such customer"
                                                renderInput={(params) => {
                                                return (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                            label={index===0?"Main Associated Customer":"Associated Customer"}
                                                            required
                                                        />
                                                    );
                                                }}
                                                
                                                onChange={(_ev, val) => {
                                                    setCustomers((cus:any)=>{
                                                        if (cus.length!==1 || val)
                                                        cus[index]=val                                                      
                                                        return cus.filter((c:any)=>c)
                                                    })
                                                    
                                                }}
                                            />)}
                                        )
                                     }
                                    
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    key="firstname"
                                    name="firstname"
                                    control={control}
                                    defaultValue=""
                                    render={(props) => {
                                        return (
                                            <TextField
                                                {...props}
                                                variant="outlined"
                                                fullWidth
                                                disabled={editingAccount}
                                                label="First Name"
                                                id={"firstname"}
                                                {...getErrorProps(
                                                    errors,
                                                    "firstname"
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    key="lastname"
                                    name="lastname"
                                    control={control}
                                    defaultValue=""
                                    render={(props) => {
                                        return (
                                            <TextField
                                                {...props}
                                                variant="outlined"
                                                fullWidth
                                                disabled={editingAccount}
                                                label="Last Name"
                                                id={"lastname"}
                                                {...getErrorProps(
                                                    errors,
                                                    "lastname"
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {user?.isTwoFactorAuthentificationEnabled && <Grid container spacing={3} className={classes.selects}>
                            <Controller
                                    key="isTwoFactorAuthentificationEnabled"
                                    name="isTwoFactorAuthentificationEnabled"
                                    control={control}
                                    defaultValue={true}
                                    label={"Two Factor Authentification"}
                                    render={(props) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                    checked={(props.value)}
                                                    onChange={(ev)=>setValue("isTwoFactorAuthentificationEnabled",ev.target.checked)}
                                                    disabled={editingAccount}
                                                    id={"isTwoFactorAuthentificationEnabled"}
                                                    />
                                                }
                                                label="Enable 2 Factor Authentication"
                                                style={{width:"100%",margin:"10px 0"}}
                                            />
                                        );
                                    }}
                                    {...getErrorProps(errors, "isTwoFactorAuthentificationEnabled")} 
                                />
                        </Grid>}
                        
                        {(own && (authorizedUser !== undefined && !(authorizedUser.verified ?? true))) &&
                            <Typography style={{color: "red", marginTop: "20px"}}>Your email is not verified. Please update and/or verify your email!</Typography>
                        }
                        <LoadingButton
                            className={classes.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={editingAccount}
                            disabled={!customer[0]}   
                        >
                            Save Changes
                        </LoadingButton>
                        <Button
                            className={classes.button}
                            variant="contained"
                            fullWidth
                            onClick={() => {history.goBack();}}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            className={classes.button+" "+classes.deleteButton}
                            variant="contained"
                            fullWidth
                            loading={deletingAccount}
                            onClick={deleteAccount}
                        >
                            Delete Account
                        </LoadingButton>
                    </form>
                </div>
            </Box>
        </BaseView>
    );
}