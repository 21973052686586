import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OrangeButton from "../Components/OrangeButton.jsx"


export default function AddPricePopup(props) {
    const [open, setOpen] = React.useState(false);
    const [featureGroup, setFeatureGroup] = React.useState(null);
    const [chosenAccessGroups, setChosenAccessGroups] = React.useState([]);
    const [price, setPrice] = React.useState('');
    const [currency, setCurrency] = React.useState(null);

    const [featureGroups, setFeatureGroups] = React.useState([]);
    const [accessGroups, setAccessGroups] = React.useState([]);
    const [currencies, setCurrencies] = React.useState([]);
    const [ownershipDuration, setOwnershipDuration] = React.useState(null);

    let now = new Date();
    let now_s = dateFormat(now, "yyyy-mm-dd");
    const [startDate, setStartDate] = React.useState(now_s);

    const updateFeatureGroups = () => {
        axios("/api/dbtool/featuregroups")
            .then(response => response.data[0])
            .then(state => setFeatureGroups(state));
    };

    const updateAccessGroups = () => {
        axios("/api/dbtool/accessgroups")
            .then(response => response.data[0])
            .then(state => setAccessGroups(state));
    };

    const updateCurrencies = () => {
        axios("/api/dbtool/currencies")
            .then(response => response.data[0])
            .then(state => setCurrencies(state));
    };


    const handleClickOpen = () => {

        // Update selects
        updateFeatureGroups();
        updateAccessGroups();
        updateCurrencies();
        setFeatureGroup(props.defaultFeatureGroup);

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {

        let price_value = parseFloat(price);
        let agids = chosenAccessGroups.map(ag => {
            return ag.Id;
        })
        if (!price_value && price_value !== 0)
            return false;
        if (!currency.ZeroDecimal)
            price_value *= 100;
        let data = {
            fid: featureGroup.Id,
            agids: agids,
            p: price_value,
            cid: currency.Id,
            d: startDate,
            f: true,
            od: ownershipDuration
        };
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };

        axios("/api/dbtool/grouppricing", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not create new price");
            });
    };

    return (

        <>
            <OrangeButton title="Add price" onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Add price</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={featureGroups}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Feature group" />}
                                onChange={(e, v) => setFeatureGroup(v)}
                                value={featureGroup}
                                style={{ height: "38px" }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                options={accessGroups}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                renderInput={(params) => <TextField {...params} label="Access group" />}
                                onChange={(e, v) => setChosenAccessGroups(v)}
                                value={chosenAccessGroups}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="price"
                                label="Price"
                                type="number"
                                style={{ width: "100%" }}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={currencies}
                                getOptionLabel={(option) => (option["CurrencyISO"] ? option["CurrencyISO"] : "")}
                                renderInput={(params) => <TextField {...params} label="Currency" />}
                                onChange={(e, v) => setCurrency(v)}
                                value={currency}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <TextField
                                id="date"
                                label="Start date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <TextField
                                id="ownershipDuration"
                                label="Ownership Duration (Days)"
                                type="number"
                                value={ownershipDuration ? ownershipDuration : ""}
                                onChange={(e) => setOwnershipDuration(e.target.value)}
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        CREATE
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
