import React, { useCallback, useMemo, useState } from "react";
import { Column, SortingRule, CellProps, FilterProps, Filters, TableState } from "react-table";
import Box from "@material-ui/core/Box";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import VirtualizedTable from "src/components/VirtualizedTable";
import { TimeFormats } from "src/utils/constants";
import { useAsync, useToast } from "src/hooks";
import moment from "moment";
import { createSelector } from "reselect";
import AdministrationService, { UserActionsLog } from "src/services/AdministrationService";
import { timezone } from "src/App";
import { useDebouncedColumnFilter } from "src/components/FilteredTable/ColumnFilters";
import { InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export interface TableProps {
    height: number;
}

interface UserActionsImportantState {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule<UserActionsLog>[];
    globalFilter: string;
    filters: Filters<UserActionsLog>;
}

const mapUserActionsImportantState = createSelector(
    (s: TableState<UserActionsLog>) => s.pageIndex,
    (s:any) => s.pageSize,
    (s:any) => s.sortBy,
    (s:any) => s.globalFilter,
    (s:any) => s.filters,
    (pageIndex, pageSize, sortBy, globalFilter, filters) => ({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
        filters,
    })
);

export function UserActionsLogTable({ height }: TableProps) {
    function DateCell({ cell: { value } }: CellProps<UserActionsLog>) {
        if (Array.isArray(value)) {
            return value
                .flatMap(
                    (v: moment.Moment, i) =>
                        (v &&
                            `${i === 0 ? "Before: " : "After: "} ${moment(v).utcOffset(timezone).format(
                                TimeFormats.PresentationShort2
                            )}`) ||
                        []
                )
                .join(" - ");
        }
        const dateString = moment.unix(value).utcOffset(timezone).format(TimeFormats.Presentation2);
        return <>{dateString}</>;
    }
    
    function DataCell({
        cell: { value },
        column: { id },
    }: CellProps<UserActionsLog>) {
        if (id === "statusCode" && value !== undefined && (value.toString().charAt(0) === "2" || value === 0 || value === "Success"))
            return <span style={{color: "#009900"}}>{value}</span>;
        else if (id === "statusCode")
            return <span style={{color: "#990000"}}>{value}</span>;
        else
            return <span>{value}</span>;
    }
function TextFilter({
    column: { filterValue, setFilter, Header},
}: FilterProps<UserActionsLog>) {
    const { value, setValue } = useDebouncedColumnFilter({
        filterValue,
        setFilter,
        delay: 500,
    });
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        console.log(event.target.value);
    }
    return (
        <TextField
            fullWidth
            placeholder={`Search ${Header} ...`}
            label={`Search ${Header}`}
            margin="dense"
            variant="outlined"
            value={value}
            onChange={onChange}
            size="small"
            InputProps={{
                endAdornment: (value) && (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            edge="end"
                            onClick={() => {
                                setValue("");
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            ></TextField>
    );
}
function StatusCodeFilter({
    column: { filterValue, setFilter},
}: FilterProps<UserActionsLog>) {
   
    const options = ["Success", "NotSuccess"];
    const [value, setValue] = React.useState(filterValue);

    return (
        <Autocomplete
            size="small"
            fullWidth
            value={value}
            options={options}
            onChange={(_ev, opt) => {
                if(opt){
                    setFilter(opt);
                    setValue(opt);
                }
                    
            }}
            renderInput={(props) => {
                return (
                    <TextField
                        variant="outlined"
                        label={`Status code`}
                        placeholder="Select status code"
                        {...props}
                    />
                );
            }}
            renderOption={(opt) => {
                if (opt === "Success")
                    return <div style={{color: "#009900"}}>Success (0, 20x)</div>;
                else if (opt === "NotSuccess")
                    return <div style={{color: "#990000"}}>Not success (40x, 50x, ...)</div>;
                else
                    return <div style={{color: "#990000"}}>{opt}</div>;
            }}
        />
    );
}

    function TimestampColumnFilter({
        column: { filterValue, setFilter },
    }: FilterProps<UserActionsLog>) {
        const notBefore = (filterValue?.[0] && moment(filterValue[0])) || null;
        const notAfter = (filterValue?.[1] && moment(filterValue[1])) || null;
    
        return (
            <Box p={0}>
                <Box mb={1}>
                    <KeyboardDateTimePicker
                        disableFuture
                        fullWidth
                        label="Not before"
                        margin="dense"
                        inputVariant="outlined"
                        value={notBefore}
                        onChange={(date) => {
                            if (notAfter === null || date?.isBefore(notAfter)) {
                                setFilter((old: any[] = []) => {
                                    return [date, old[1]];
                                });
                            }
                        }}
                        maxDate={notAfter || undefined}
                        ampm={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        strictCompareDates
                        InputProps={{
                            readOnly: true,
                            endAdornment: notBefore !== null && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setFilter((old: any[]) =>
                                            old[1] ? [null, old[1]] : null
                                        );
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        KeyboardButtonProps={{ size: "small" }}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </Box>
                <Box>
                    <KeyboardDateTimePicker
                        disableFuture
                        fullWidth
                        label="Not after"
                        inputVariant="outlined"
                        margin="dense"
                        ampm={false}
                        value={notAfter}
                        onChange={(date) => {
                            if (notBefore === null || date?.isAfter(notBefore)) {
                                setFilter((old: any[] = []) => {
                                    return [old[0], date];
                                });
                            }
                        }}
                        minDate={notBefore || undefined}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        strictCompareDates
                        InputProps={{
                            readOnly: true,
                            endAdornment: notAfter !== null && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setFilter((old: any[]) =>
                                            old[0] ? [old[0], null] : null
                                        );
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        KeyboardButtonProps={{ size: "small" }}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </Box>
            </Box>
        );
    }

    const latestColumns: Column<UserActionsLog>[] = [
        {
            accessor: "actionType",
            Header: "Action Type",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "statusCode",
            Header: "Status Code",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "timestamp",
            Header: "Timestamp",
            Cell: DateCell,
            hideable: false,
        },
        {
            accessor: "username",
            Header: "By User",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "value",
            Header: "Value",
            Cell: DataCell,
            hideable: false,
        },
    ];

    const historicColumns: Column<UserActionsLog>[] = latestColumns.map((c) =>{
        switch(c.accessor) {
            case "timestamp":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: TimestampColumnFilter,
                    filterLabel: "Omit data",
                    filterShouldRenderCell: true,
                    
                };
            case "username":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: TextFilter,
                    filter: "includes",
                    filterLabel: "Username",
                    filterShouldRenderCell: true,
                };
            case "actionType":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: TextFilter,
                    filter: "includes",
                    filterLabel: "Action Type",
                    filterShouldRenderCell: true,
                };
            case "statusCode":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: StatusCodeFilter,
                    filter: "includes",
                    filterLabel: "Status Code",
                    filterShouldRenderCell: true,
                };
            default:
                return c;
        }
    }
)

    function mapFilters(filters: Filters<UserActionsLog>) {
        const timestampValues =
            filters
                .find((f) => f.id === "timestamp")
                ?.value?.map(
                    (v: any) => v && (moment.isMoment(v) ? v : moment(v)).toDate()
                ) || [];
        const username = filters.find((f) => f.id === "username")?.value
        const actionType= filters.find((f) => f.id === "actionType")?.value
        const statusCode= filters.find((f) => f.id === "statusCode")?.value
        return {
            notBefore: timestampValues[0] || undefined,
            notAfter: timestampValues[1] || undefined,
            usernameSearch: username,
            actionTypeSearch: actionType,
            statusCodeSearch: statusCode,
        };
    }

    const initialStateAll: Partial<TableState<UserActionsLog>> = {
        sortBy: [{ id: "timestamp", desc: true }],
        pageSize: 500
    };

    const fetchData_ = useCallback(
        ({
            pageIndex,
            pageSize,
            sortBy,
            globalFilter,
            filters,
        }: UserActionsImportantState) => {
            const importantFilters = mapFilters(filters);
            return AdministrationService.getUserActionsLog({
                skip: pageIndex * pageSize || 0,
                limit: pageSize,
                orderBy: sortBy.map(({ id, desc }) => [
                    id as any,
                    desc ? "desc" : "asc",
                ]),
                globalSearch: globalFilter,
                ...importantFilters,
            });
        },
        []
    );

    const { value: logs, error, pending, exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
        clearValueOnExec: false,
    });

    const { pageCount, data } = useMemo(() => {
        return {
            pageCount: logs?.filteredCount ?? 0,
            data: logs?.data ?? [],
        };
    }, [logs]);

    const { displayToast } = useToast();

    const onRowClick = (_idx: any, row: any) => {
        let txt = row.values.value;
        try {
            txt = JSON.stringify(JSON.parse(txt),null,2);
        } catch {/*noop*/}
        navigator.clipboard.writeText(txt);
        displayToast({message: "value copied to clipboard",severity: "info",withCloseIcon: true});
    }

    return (
        <VirtualizedTable
            data={data}
            pageCount={pageCount}
            onStateChange={fetchData}
            mapStateChange={mapUserActionsImportantState}
            loading={pending || (logs === undefined && error === undefined)}
            columns={historicColumns}
            size="small"
            height={height}
            initialState={initialStateAll}
            onRowClick={onRowClick}
        ></VirtualizedTable>
    );
}