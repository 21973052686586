import React, { useCallback, useEffect, useState } from "react";
import BaseWidget, { WidgetProps, defaultRules } from "./BaseWidget";
import { useDispatch, useSelector } from "react-redux";
import { customersArraySelector,  } from "src/redux/app/selectors";
import { Controller, useForm } from "react-hook-form";
import { Customer } from "src/services/DeviceDataService";
import ConfigurationsService, { Company } from "src/services/ConfigurationsService";
import { Button, FormControlLabel, TextField } from "@material-ui/core";
import AutocompleteV2 from "src/components/AutocompleteV2";
import getErrorProps from "src/utils/getErrorProps";
import { useWidgetState } from "../plugins";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import UserService from "src/services/UserService";
import { loadCustomersDto } from "src/redux/app/actions";

interface ApproveCompanyInput{
    customer: Customer|null,
    company: Company|null,
    companyName:string|null
}

export default function ApproveCompanyWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets  }: WidgetProps) {
    const customers = useSelector(customersArraySelector)!.filter((c)=>!c.reviewed);
    const {
        state: { companies },
    } = useWidgetState();
    
    const { dto:companiesDto, loaded: companiesLoaded } = companies;
    
    
    const {
        handleSubmit,
        errors,
        control,
        setValue,
        watch,
        setError,
        reset,
        getValues
    } = useForm<ApproveCompanyInput>({
        defaultValues: {customer: null,company : null},
    });

    const { value:hubspotMatch, exec:getMatch, pending:loadingMatch } = useAsync(
        () =>UserService.getHubspotMatch(customers.map((cust)=>{return {id:(cust.id),name:cust.name}}))??[],
        { immediate: false, clearValueOnExec: false }
    );
    const [matched,setMatched]=useState([])
    const customer=watch("customer")
    const company=watch('company')
    useEffect(()=>{
        getMatch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if (customer && hubspotMatch)
        {
            setMatched(hubspotMatch?.find((m:any)=>m.name===customer?.name)?.matches.map((match:any)=>{return {...match,name:match.matchName}})??[])
        }
    },[customer,hubspotMatch,companiesDto,setValue])
    
    const dispatch = useDispatch();
    const { displayToast } = useToast();
    const approveCompany = useCallback(((prop:ApproveCompanyInput) => ConfigurationsService.hubspotCompany((prop.company as any)?.matchId!,(prop.company?.name==='new'?prop.companyName!:prop.company?.name!),prop.customer?.id!).then(()=>{dispatch(loadCustomersDto());reset()})
    ),[dispatch,reset])
    const discardCompany = useCallback(((prop:ApproveCompanyInput) => ConfigurationsService.hubspotCompanyReviewed(prop.customer?.id!).then(()=>{dispatch(loadCustomersDto());reset()})
    ),[dispatch,reset])
    const { exec, pending: updatingCompany, error } = useAsync(
        approveCompany,
        {
            immediate: false,
            onComplete:()=>{
                displayToast({
                    message: `Hubspot connection was created successfully.`,
                    severity: "success",
                    withCloseIcon: true,
                });
            },
            onError: useErrorHandler({ onValidationError: setError }),
        }
    );

    const { exec:discard, pending: discardingCompany, error:errorDiscard } = useAsync(
        discardCompany,
        {
            immediate: false,
            onComplete:()=>{
                displayToast({
                    message: `Customer was reviewed successfully.`,
                    severity: "success",
                    withCloseIcon: true,
                });
            },
            onError: useErrorHandler({ onValidationError: setError }),
        }
    );


    const loading=!companiesLoaded || loadingMatch || updatingCompany
    return (
        <BaseWidget
            widgetName={widgetName}
            title={`Approve Company`}
            subTitle={'Approve Hubspot companies for new customers ['+customers.length+']'}
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >  
                    <Controller
                    name="customer"
                    control={control}
                    rules={defaultRules}
                    render={({ onChange, ...props }) => {
                        return (
                            <AutocompleteV2
                                selectOnTab
                                {...props}
                                disabled={loading}
                                options={customers}
                                noOptionsText="No such customer"
                                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            label="Customer"
                                            placeholder="Choose a customer"
                                            required
                                            {...getErrorProps(errors, "customer")}
                                        />
                                    );
                                }}
                                onChange={(_ev, val) => {
                                    onChange(val);
                                }}
                            />
                        );
                    }}
                />
                <Controller
                    name="company"
                    control={control}
                    rules={defaultRules}
                    render={({ onChange, ...props }) => {
                        return (
                            <AutocompleteV2
                                selectOnTab
                                {...props}
                                disabled={loading}
                                options={[{name:'new',id:'',country:"",matchType:''},...matched,...companiesDto.filter((comp)=>!matched.includes(comp))]}
                                noOptionsText="No such company"
                                getOptionLabel={(r) => `${r.name}${r.matchType==='service_center'?' [Service center filled]':''}`}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            label="Company"
                                            placeholder="Choose a company"
                                            required
                                            {...getErrorProps(errors, "company")}
                                        />
                                    );
                                }}
                                onChange={(_ev, val) => {
                                    onChange(val);
                                }}
                            />
                        );
                    }}
                />
                {
                    company?.name==='new' && 
                    <Controller
                name="companyName"
                control={control}
                rules={{
                    ...defaultRules,
                }}
                render={({ onChange, onBlur, value }) => {
                    return (
                        <TextField
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label={"Name"}
                            placeholder="Choose a new name"
                            disabled={loading}
                            required
                            value={value}
                            onBlur={onBlur}
                            onChange={(ev) => onChange(ev.target.value)}
                            {...getErrorProps(errors, "companyName")}
                        />
                    );
                }}
            />
                }
                <Button onClick={()=>discard(getValues())} color="primary" variant="contained" disabled={!customer} style={{marginTop:5}}>
                    Discard
                </Button>
        </BaseWidget>
    )
}