import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import type { CustomersDto } from "src/services/UserService";
import type { DetailedDeviceDto, DeviceData, TemplatesDto } from "src/services/DeviceDataService";
import TranslationProvider, { useTranslator } from "../TranslationProvider";
import VitalsBar from "./VitalsBar";
import SectionHeader, { SectionHeader as StatelessSectionHeader } from "./SectionHeader";
import SectionBody from "./SectionBody";
import DialogBar from "./DialogBar";
import { useDashboard } from "./DashboardProvider";
import SectionProvider from "./SectionProvider";
import { useDidMountCheck } from "src/hooks";
import SupportHistory from "./SupportHistory";
import { useSelector } from "react-redux";
import useQueryString from "src/hooks/useQueryString";
import DeviceDataService from "src/services/DeviceDataService";
import TemplatesGrid from "./TemplatesGrid";
import { accessFieldsSelector, customersDtoSelector } from "src/redux/app/selectors";
import SupportNew from "./SupportNew";
import MiscGrid from "./MiscGrid";
import { deviceTypeIdToString } from "src/utils/common";
import { userAccessSelector } from "src/redux/auth/selectors";

const useStyles = makeStyles((theme) => ({
    hDivider: {
        marginBottom: theme.spacing(1),
    },
    root: {
        marginBottom: theme.spacing(3),
    },
    select: {
        padding: 0,
        margin: 0,
        marginLeft: theme.spacing(1),
        "& > *, & .MuiSelect-icon, & fieldset, & :hover": {
            color: `${theme.palette.common.white} !important`,
            borderColor: `${theme.palette.common.white} !important`,
        },
    },
    templatesBar: {
        paddingTop: theme.spacing(0.4),
        paddingBottom: theme.spacing(0.3),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
    },
}));

const commonKeys = {
    robotName: "robot_name",
    customer: "customer_id",
    customer2: "customer",
    dealer: "Dealer",
    country: "Country",
};

interface AdornmentProps {
    deviceId: number;
    deviceTypeId: number;
    data: Record<string, DeviceData>;
    secondary?: boolean;
    dialog?: JSX.Element;
}

function LeftAdornment(props: AdornmentProps) {
    const translator = useTranslator();
    const { deviceTypeId, data } = props;

    const customers = useSelector(customersDtoSelector);

    const customerId = Number(data[deviceTypeId===3?commonKeys.customer2:commonKeys.customer]?.value??"0");
    const customer = customers[customerId];
    const countries=customer ? JSON.parse(customer.countries): undefined;
    
    
    return (
        (customer && <Typography>
            {translator.translate(
                commonKeys.customer,
                data[commonKeys.customer]?.value || "",
                data
            )}
            {/*
                TODO: fix this bug again
                The sales rep part was commented out, presumably for fixing a bug?...
                Do NOT remove a feature just to fix a bug!!!
            */}
            {(customer && customer.salesRep)
                ? ` (${customer.salesRep})`
                : ``
            }
            {" | "}
            {translator.translate(
                commonKeys.dealer,
                data[commonKeys.dealer]?.value || "",
                data
            )}

            {(countries) &&
                (" | "+ ((countries.length>4)?countries.slice(0,3):countries))
            }
        </Typography>) ??
        (<Typography>NO CUSTOMER</Typography>)

    );
}

function RightAdornment(props: AdornmentProps) {
    const translator = useTranslator();
    const { deviceTypeId, deviceId, data, secondary } = props;

    if (secondary) {
        return (<>
            <Typography>{`Select ${deviceTypeIdToString(deviceTypeId)} ID`}</Typography>
            {props.dialog}
            <Typography>
                &nbsp;
                {data["appVersion"] !== undefined && data["appVersion"].value}
            </Typography>
        </>);
    }

    if (deviceTypeId === 1)
        return (
            <Typography>
                {deviceId}
                {" | "}
                {translator.translate(
                    commonKeys.robotName,
                    data[commonKeys.robotName]?.value
                )}
                {data["robotVersion"] !== undefined && " | "}
                {data["robotVersion"] !== undefined && data["robotVersion"].value}
            </Typography>
        );
    
    return (
        <Typography>
            {deviceId}
            {" | "}
            {deviceTypeId === 2 ? "Tablet" : "Device"}
            {data["appVersion"] !== undefined && " | "}
            {data["appVersion"] !== undefined && data["appVersion"].value}
        </Typography>
    );
}

interface SectionProps {
    primary?: boolean;
    primaryDeviceTypeId: number;
    customers: CustomersDto;
    inaccessibleDevices?: boolean
}

function PrimarySection(props: SectionProps) {
    const classes = useStyles();
    const primarySection = useDashboard().state.primary;

    const {
        deviceTypeId,
        dictionary,
        device: { data, deviceId },
    } = primarySection;
    const { customers } = props;

    const adornmentProps = {
        deviceTypeId,
        data: data,
        deviceId: deviceId,
    };

    useDidMountCheck("PrimarySection");

    const historyRef = useRef();

    const newSupportAccess=useSelector(userAccessSelector).includes('PostSupport')

    const [templates, setTemplates] = useState({tabletId: deviceId, groups: []} as TemplatesDto);
    useEffect(() => {
        if (deviceTypeId === 2)
            DeviceDataService.getTemplatesForTablet(deviceId).then(res => setTemplates(res)).catch(_e => {/*noop*/});
    }, [deviceId, deviceTypeId]);
    const [accessGroups, setAccessGroups] = useState([] as Array<string>);
    useEffect(() => {
        if (deviceTypeId === 1)
            DeviceDataService.getAccessGroupsForRobot(deviceId).then(res => setAccessGroups(res)).catch(_e => {/*noop*/});
    }, [deviceId, deviceTypeId]);

    return (
        <TranslationProvider
            deviceTypeId={deviceTypeId}
            customers={customers}
            translationsDictionary={dictionary || {}}
        >
            <SectionProvider section={primarySection} deviceId={deviceId}>
                <Paper className={classes.root}>
                    <Box>
                        <SectionHeader
                            primary
                            LeftAdornment={
                                <LeftAdornment {...adornmentProps} />
                            }
                            RightAdornment={
                                <RightAdornment {...adornmentProps} />
                            }
                        />
                        <VitalsBar />
                        <Divider className={classes.hDivider} />
                        <SectionBody />
                        <Divider />
                        <DialogBar />
                        {(newSupportAccess && deviceTypeId !== 3) && <>
                            <Divider />
                            <SupportNew 
                                notify={historyRef}
                            />
                        </>}
                        {(deviceTypeId !== 3) && <>
                            <Divider />
                            <SupportHistory
                                ref={historyRef}
                            />
                        </>}
                        {(deviceTypeId === 2) && <>
                            <Divider />
                            <Typography align="center" variant="h5" className={classes.templatesBar}><b>Templates</b></Typography>
                            <Divider />
                            <TemplatesGrid data={templates} />
                        </>}
                        {(deviceTypeId === 1) && <>
                            <Divider />
                            <Typography align="center" variant="h5" className={classes.templatesBar}><b>Miscellaneous</b></Typography>
                            <Divider />
                            <MiscGrid data={[{name:"Access Groups",data:accessGroups}]} />
                        </>}
                    </Box>
                </Paper>
            </SectionProvider>
        </TranslationProvider>
    );
}

function SecondarySection(props: SectionProps) {
    const classes = useStyles();
    const secondarySection = useDashboard().state.secondary!;
    const [activeDeviceId, setActiveDeviceId] = useQueryString("tablet", secondarySection.deviceIds[0]);
    const userAccess = useSelector(userAccessSelector)
    const accessAboveDefault = (useSelector(accessFieldsSelector)?.some(af => userAccess.includes(af.tag) && af.access_lvl > 0));
    
    let activeDevice: DetailedDeviceDto;
    if (!secondarySection.deviceIds.includes(activeDeviceId)) {
        setActiveDeviceId(secondarySection.deviceIds[0]);
        activeDevice = secondarySection.devices[secondarySection.deviceIds[0]];
    } else
        activeDevice = secondarySection.devices[activeDeviceId];
    const { deviceTypeId, dictionary } = secondarySection;
    const { customers } = props;

    const menuItems = useMemo(() => {
        return secondarySection.deviceIds.map((id) => {
            return (
                <MenuItem key={`${deviceTypeId}/${id}`} value={id}>
                    {id}
                </MenuItem>
            );
        });
    }, [secondarySection.deviceIds, deviceTypeId]);

    const hideMenu = menuItems.length === 1;

    const { data, deviceId } = activeDevice;
    const adornmentProps = { deviceTypeId, data, deviceId };

    useDidMountCheck("SecondarySection");

    const historyRef = useRef();

    const newSupportAccess=useSelector(userAccessSelector).includes("PostSupport")

    const [templates, setTemplates] = useState({tabletId: activeDevice.deviceId, groups: []} as TemplatesDto)
    useEffect(() => {
        if (deviceTypeId === 2)
            DeviceDataService.getTemplatesForTablet(activeDevice.deviceId).then(res => setTemplates(res)).catch(_e => {/*noop*/});
    }, [activeDevice, deviceTypeId]);

    return (
        <TranslationProvider
            deviceTypeId={deviceTypeId}
            customers={customers}
            translationsDictionary={dictionary || {}}
        >
            <SectionProvider section={secondarySection} deviceId={deviceId}>
                {props.inaccessibleDevices && accessAboveDefault &&
                    <Box boxShadow={2} mb={1} p={1} borderRadius={3}>
                        <Typography component="h6" variant="h6" align="center">
                            Some of the connected devices are not visible below because you do not have access to them.
                        </Typography>
                    </Box>
                }
                <Paper className={classes.root}>
                    <Box>
                        <SectionHeader
                            LeftAdornment={
                                <LeftAdornment {...adornmentProps} />
                            }
                            RightAdornment={
                                <>
                                    <RightAdornment
                                        {...adornmentProps}
                                        secondary={!hideMenu}
                                        dialog={!hideMenu ? (
                                            <TextField
                                                className={classes.select}
                                                margin="dense"
                                                size="small"
                                                id="device-select"
                                                select
                                                value={activeDevice.deviceId}
                                                onChange={(ev) =>
                                                    setActiveDeviceId(
                                                        +ev.target.value
                                                    )
                                                }
                                                variant="outlined"
                                            >
                                                {menuItems}
                                            </TextField>
                                        ) : undefined}
                                    />
                                </>
                            }
                        />
                        <VitalsBar key={`Vitals-${activeDevice.deviceId}`} />
                        <Divider className={classes.hDivider} />
                        <SectionBody />
                        <Divider />
                        <DialogBar />
                        {(newSupportAccess && deviceTypeId !== 3) && <>
                            <Divider />
                            <SupportNew 
                                notify={historyRef}
                            />
                        </>}
                        {<>
                            <Divider />
                            <SupportHistory
                                ref={historyRef}
                            />
                        </>}
                        {(deviceTypeId === 2) && <>
                            <Divider />
                            <Typography align="center" variant="h5" className={classes.templatesBar}><b>Templates</b></Typography>
                            <Divider />
                            <TemplatesGrid data={templates} />
                        </>}
                    </Box>
                </Paper>
            </SectionProvider>
        </TranslationProvider>
    );
}

function NotFoundSection(props: SectionProps) {
    const classes = useStyles();
    const {
        // This is the primary device type id
        primaryDeviceTypeId,
    } = props;

    let notFoundMessage = "";
    let deviceName = "";
    if (primaryDeviceTypeId === 1) {
        notFoundMessage = "No associated tablets found";
        deviceName = "Tablet";
        if(props.inaccessibleDevices)
            notFoundMessage = "You do not have access to any associated tablet";
    } else if (primaryDeviceTypeId === 2) {
        notFoundMessage = "No associated robot found";
        deviceName = "Robot";
    } else {
        return <></>;
    }

    return (
        <Paper className={classes.root}>
            <Box>
                <StatelessSectionHeader title={`${deviceName} Status`} />
                <Box display="flex" justifyContent="center" p={1}>
                    <Typography component="h5" variant="h6">
                        {notFoundMessage}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
}

export default function Section(props: SectionProps) {
    const { primary } = props;
    const state = useDashboard().state;

    if (!primary) {
        if (state.secondary === undefined) {
            return <NotFoundSection {...props} />;
        }

        return <SecondarySection {...props} />;
    }

    return <PrimarySection {...props} />;
}
