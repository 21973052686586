import axios from "axios";
import { handleError } from "./Errors";
import { getBrowser, getOS } from "src/utils/browserDetect";

export interface CustomerInfo {
    id: number;
    name: string;
    isDealer: boolean;
    parentId: number;
}

export interface User {
    username: string;
    customerId: number[];
    userAccess: string[],
    email?: string,
    firstname?: string,
    lastname?: string,
    verified?: boolean,
    password?: string,
    confirmPassword?: string,
    to?: number,
    from?: number,
    lastLogin?: number,
    contractAccess: string[],
    //
    twoFactorAuthentificationCode: string,
    isTwoFactorAuthentificationEnabled: boolean
}

export interface Customer {
    id: number;
    name: string;
    isDealer: boolean;
    parentId: number;
    /**
     * Refers to the root dealer
     */
    dealerId: number;
    salesRep?: string;
    countries: string;
    from: string;
    reviewed: boolean;
}


export interface CustomersDto {
    [customerId: number]: Customer;
}

export interface CustomerAccessGroup {
    id: number;
    name: string;
    shortName: string;
    inheritable: boolean;
    accessGroupType: string;
    accessGroupTypeId: number;
    from: string;
    to: string | null;
    required: boolean;
}

export interface AccessGroupsDto {
    [customerId: number]: CustomerAccessGroup[];
}

export interface AccessField {
    tag:string;
    name: string;
    access_lvl: number;
}


export default class UserService {
    static async createUser(user: User, generatePw: boolean): Promise<User> {
        if (user.email !== undefined && user.email === "")
            user.email = undefined;
        try {
            const response = await axios.post("/api/users?oneTimeLogin="+generatePw, user, {timeout:30000});
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async getUsers(): Promise<User[]> {
        try {
            const response = await axios.get("/api/users");
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async getUser(username: string): Promise<User> {
        try {
            const response = await axios.get("/api/users/user/"+username);
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async updateUser(username: string, user: User): Promise<User> {
        if (user.email !== undefined && user.email === "")
            user.email = undefined;
        if (user.password !== undefined && user.password === "")
            user.password = undefined;
        if (user.confirmPassword !== undefined && user.confirmPassword === "")
            user.confirmPassword = undefined;
        try {
            const response = await axios.put("/api/users/user/"+username, user, {timeout:20000});
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async sendVerification(): Promise<void> {
        try {
            await axios.get("/api/users/sendverification", {timeout:20000});
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async verifyEmail(token: string): Promise<void> {
        try {
            await axios.get("/api/users/verifyemail/"+token);
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async requestPasswordReset(username: string): Promise<{email: string}> {
        try {
            const response = await axios.post("/api/users/resetpassword/request", {username, browser: getBrowser(), os: getOS()}, {timeout:20000});
            return response.data as any;
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async confirmPasswordResetToken(token: string): Promise<void> {
        try {
            await axios.get("/api/users/resetpassword/confirm/"+token);
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async resetPassword(token: string, password: string, confirmPassword: string): Promise<void> {
        try {
            await axios.post("/api/users/resetpassword", {token, password, confirmPassword});
        } catch (err) {
            return handleError(err, true);
        }
    }
    static async softdeleteUser(username: string): Promise<User> {
        try {
            const response = await axios.delete("/api/users/softdelete/"+username);
            return response.data as any;

        } catch (err) {
            return handleError(err, true);
        }
    }
    static async getCustomersDto(): Promise<CustomersDto> {
        try {
            const response = await axios.get("/api/users/customers?dto=true");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }
    static async getAccessGroupsDto(
        customerIds: number[]
    ): Promise<AccessGroupsDto> {
        if (customerIds.length === 0) {
            return {};
        }
        try {
            const response = await axios.post("/api/users/accessgroups", {
                customerIds,
            });
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getAccessFields():Promise<Array<AccessField>>{
        try {
            const response = await axios.get("/api/users/accessFields");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getHubspotMatch(customers:Array<{id:number,name:string}>){
        try {
            return (await axios.post('/api/users/hubspotMatch',{data:customers})).data
        } catch (err) {
            return handleError(err, true);            
        }
    }
}
