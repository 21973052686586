import { makeStyles, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { useAsync } from "src/hooks";
import useQueryString from "src/hooks/useQueryString";
import UserService from "src/services/UserService";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "50px",
        position: "fixed",
        top: "0px",
        left: "0px",
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "space-around",
        alignItems: "center",
        '& *': {
            fontFamily: theme.typography.fontFamily,
        },
    }
}));

export default function VerifyEmail() {
    const classes = useStyles();
    const [token, _setToken] = useQueryString("token", "");

    const fetchData = useCallback(
        () => UserService.verifyEmail(token), [token]
    );
    const { error, pending } = useAsync(fetchData, {
        defaultPending: true,
    });

    return (<div className={classes.root}>
        {(!pending && error === undefined)
            ?
                <div>
                    <Typography variant="h4" style={{color: "#4CAF50", textAlign: "center"}}>Your email has been successfully verified!</Typography>
                    <Typography variant="body1" style={{textAlign: "center"}}>back to <a href={window.location.protocol+"//"+window.location.host}>home page</a></Typography>
                </div>
            :
                <div>
                    <Typography variant="h4" style={{whiteSpace: "pre-wrap"}}>{"Invalid link, or your email is already verified!\nWe could not verify your email, make sure to use the link from the last email you received!"}</Typography>
                </div>
        }
    </div>);
}