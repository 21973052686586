import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { displayDialog } from "src/redux/app/actions";
import { DialogPrompt } from "src/redux/app/types";
import { disableScrolling, enableScrolling } from "src/utils/scrolling";

export default function useDialog() {
    const dispatch = useDispatch();

    const display = useCallback(
        (prompt: DialogPrompt = {}) => {
            let resolveDialog, rejectDialog;
            const dialogPromise = new Promise<boolean>((resolve, _reject) => {
                resolveDialog = () => {enableScrolling(); resolve(true);};
                rejectDialog = () => {enableScrolling(); resolve(false);};
            });
            dispatch(displayDialog(true, prompt, resolveDialog, rejectDialog));
            disableScrolling();
            return dialogPromise;
        },
        [dispatch]
    );

    return {
        displayDialog: display,
    };
}
