import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import AssignRobotModelOverwritePopup from "./popups/AssignRobotModelOverwritePopup.jsx"

const small_style = {
  width: 1,
  whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

class ManageRobotProductModelOverwrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      robots: []
    };
    this.refreshRobotProductModelOverwrite = this.refreshRobotProductModelOverwrite.bind(this);

    this.headers = [{
      Header: 'Robot ID',
      key: 'RobotId',
      align: "left",
      style: small_style,
      search: "RobotId"
    },
    {
      Header: 'Robot Type',
      key: 'RobotType',
      align: "left",
      style: big_style,
      search: "RobotType"
    }
    ];
  }

  componentDidMount() {
    this.refreshRobotProductModelOverwrite()
  }


  refreshRobotProductModelOverwrite(event) {
    if (event)
      event.preventDefault();
    axios(`/api/dbtool/robotproductoverwrite`)
      .then(response => response.data[0])
      .then(state => this.setState({
        robots: state.map(r => {
          r.Id = r.RobotId;
          return r;
        })
      }));
  }

  render() {
    return (
      <React.Fragment>
        <Table id="robot_product_model_overwrite_table" headeres={this.headers} data={this.state.robots} refresh={this.refreshRobotProductModelOverwrite} />
        <div style={{ margin: "30px" }}>
          <AssignRobotModelOverwritePopup whenSuccess={this.refreshRobotProductModelOverwrite.bind(this)} />
        </div>
      </React.Fragment>
    );
  }
}

export default ManageRobotProductModelOverwrite;