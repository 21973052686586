import React from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import { connect } from "react-redux";
import { State } from "src/redux/createStore";
import { isAuthenticatedSelector } from "src/redux/auth/selectors";
import classNames from "src/utils/classNames";

const styles = (theme: Theme) =>
    createStyles({
        margin: {
            [theme.breakpoints.up("md")]: {
                marginLeft: theme.tmrOptions.drawerWidth,
            },
        },
        padding: {
            paddingTop: theme.spacing(1.5),
        },
    });

function CriticalError(props: { error: any; className: string }) {
    return (
        <Container
            component="main"
            maxWidth={false}
            className={props.className}
        >
            <Box display="flex" alignItems="flex-end" mb={1}>
                <Typography color="error" component="h1" variant="h5">
                    Error!!
                </Typography>
                <Box ml={1}>
                    <ErrorIcon color="error" aria-label="error" />
                </Box>
            </Box>
            <Typography>
                A critical error has occurred. Try reloading the page. Contact
                an administrator if the problem persists.
            </Typography>
            {process.env.NODE_ENV !== "production" && (
                <Box p={3}>
                    <Box mb={1}>
                        <code>{props.error.toString()}</code>
                    </Box>
                    {props.error.stack && (
                        <Box>
                            <code>{props.error.stack.toString()}</code>
                        </Box>
                    )}
                </Box>
            )}
        </Container>
    );
}

class Content extends React.Component<
    {
        children: React.ReactNode;
        authenticated: boolean;
    } & WithStyles<typeof styles>
> {
    state = { error: undefined! };

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    // TODO: Log error to server
    // componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    // }

    render() {
        const { children, authenticated, classes } = this.props;
        const { error } = this.state;

        if (error) {
            return (
                <CriticalError
                    error={error}
                    className={
                        classNames(
                            classes.padding,
                            authenticated && classes.margin
                        )!
                    }
                />
            );
        }

        return (
            <div
                className={classNames(
                    classes.padding,
                    authenticated && classes.margin
                )}
            >
                {children}
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    authenticated: isAuthenticatedSelector(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Content));
