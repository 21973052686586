import axios from "axios";

export interface HubSpotContact {
    id: string;
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    companies: string[];
    lastmodified: number;
    country: string;
}

export interface HubSpotCompany {
    id: string;
    name: string;
    country: string;
    city: string;
    address: string;
    zip: string;
    state: string;
    phone: string;
    contacts: string[];
    lastmodified: number;
}

export default class HubspotService {
    static async getContacts(): Promise<HubSpotContact[]> {
        try {
            const response = await axios.get("/api/hubspot/contacts", {timeout: 120000});
            return response.data as any;
        } catch (err) {
            return [];
        }
    }

    static async getCompanies(): Promise<HubSpotCompany[]> {
        try {
            const response = await axios.get("/api/hubspot/companies", {timeout: 120000});
            return response.data as any;
        } catch (err) {
            return [];
        }
    }

    static async getClosedate(company:string):Promise<string>{
        try {
            const response = await axios.post("/api/hubspot/closedate",{company});
            return response.data as any;
        } catch (err) {
            return '';
        }
    }
}