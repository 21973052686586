import React, { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useAsync, useGetLatest } from "src/hooks";

const useStyles = makeStyles(() => ({
    wrapper: {
        position: "relative",
    },
    downloadProgress: {
        position: "absolute",
        top: 5,
        left: 6.5,
        zIndex: 1,
        opacity: "0.5 !important",
    },
}));

export interface ExportButtonProps
    extends Omit<IconButtonProps, "onClick" | "disabled"> {
    onClick: () => Promise<void>;
    title?: string;
    tooltipProps?: Partial<TooltipProps>;
    disabled? : boolean;
    progress? : number;
}

const loadingTransitionStyles = { transitionDelay: "500ms" };
const transitionStyles = { transitionDelay: "0ms" };

export default function ExportButton(props: ExportButtonProps) {
    const {
        onClick,
        title = "Export as CSV",
        tooltipProps = {},
        disabled = false,
        progress,
        ...rest
    } = props;

    const getPropsTitle = useGetLatest(title);
    const [controlledTitle, setControlledTitle] = useState(title);
    const [open, setOpen] = useState(false);
    const { error, exec, pending } = useAsync(onClick, { immediate: false });

    const classes = useStyles();
    const onClickWrapped = (ev: React.MouseEvent<any>) => {
        ev.preventDefault();
        exec();
    };

    useEffect(() => {
        if (pending) {
            const fn = () => {
                setControlledTitle("The download is taking longer than usual");
                setOpen(true);
            };
            const timer = setTimeout(fn, 4500);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setControlledTitle(getPropsTitle());
        }
    }, [pending, getPropsTitle]);

    useEffect(() => {
        if (controlledTitle !== getPropsTitle()) {
            const fn = () => {
                setOpen(false);
            };
            const timer = setTimeout(fn, 2500);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [controlledTitle, getPropsTitle]);

    useEffect(() => {
        if (error) {
            setControlledTitle("Data export failed...");
        } else {
            setControlledTitle(getPropsTitle());
        }
    }, [error, getPropsTitle]);

    const fadeStyle = useMemo(() => {
        return pending ? loadingTransitionStyles : transitionStyles;
    }, [pending]);

    return (
        <Tooltip
            {...tooltipProps}
            title={disabled ? "" : controlledTitle}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <span className={classes.wrapper}>
                <IconButton
                    aria-label={title.toLowerCase()}
                    disabled={pending || disabled}
                    onClick={onClickWrapped}
                    {...rest}
                >
                    {(error && <ErrorOutlineIcon color="error" />) || (
                        <DownloadIcon />
                    )}
                </IconButton>
                <Fade in={pending} style={fadeStyle} unmountOnExit>
                    <CircularProgress
                        variant={progress !== undefined ? "static" : "indeterminate"}
                        value={progress}
                        size={35}
                        className={classes.downloadProgress}
                    />
                </Fade>
            </span>
        </Tooltip>
    );
}
