import React, { useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useSection } from "./SectionProvider";
import { DeviceType } from "src/utils/constants";
import { useAsync, useToast } from "src/hooks";
import useDialog from "src/hooks/useDialog";
import { Collapse, FormControlLabel, List, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import { TabletUserLevel } from "src/services/DeviceDataService";
import { toastError } from "src/utils/common";
import AutocompleteV2 from "src/components/AutocompleteV2";
import KnoxService from "src/services/KnoxService";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { userAccessSelector } from "src/redux/auth/selectors";

const commonKeys = { uid: "knoxUID" };
const isValidUIDRegex = /[a-f, 0-9]{32}/;
const isValidUID = (str: string) => isValidUIDRegex.test(str);

export default function TabletOptions() {
    const { deviceId, deviceTypeId, data } = useSection();

    const uid = data[commonKeys.uid]?.value || "";

    const devLevelAccess=useSelector(userAccessSelector).includes("TabletLevelDeveloper")
    const tabletAppVersionAccess=useSelector(userAccessSelector).includes('TabletAppVersion')
    const tabletUserLevelAccess=useSelector(userAccessSelector).includes('SetTabletUserLevel')
    const knoxMenuAccess=useSelector(userAccessSelector).includes('KnoxMenu')


    const { displayToast } = useToast();
    const { displayDialog } = useDialog();

    const [open, setOpen] = useState(false);

    const triggerKnoxMenu = () => {
        setOpen(!open);
    };
   
    const getAppVersionsCallback=useCallback(()=>{return ConfigurationsService.getAppVersions()},[])
    const getTabletAppVersionsCallback=useCallback(()=>{return ConfigurationsService.getTabletAppVersions()},[])

    const {value:appVersions}=useAsync(getAppVersionsCallback,{defaultPending: true,});
    const {value:tabletAppVersions}=useAsync(getTabletAppVersionsCallback,{defaultPending: true,});

    // So far only tablets have associated options
   
    if (deviceTypeId !== DeviceType.Tablet) {
        return <></>;
    }

    const changeUserLevel = async () => {
        let userLevel = (data["User level"]&&data["User level"].value&&data["User level"].value.toLowerCase())||"normal";
        if (!await displayDialog({positiveButtonText: "Ok", negativeButtonText: "Cancel", zIndex: 1300, dialogText: (
            <div>
                <Typography style={{marginBottom: "7px"}}>Change tablet user level to:</Typography>
                <TextField
                    style={{minWidth: "110px", marginLeft: "10px"}}
                    label="user level"
                    margin="dense"
                    size="small"
                    select
                    defaultValue={userLevel}
                    onChange={(ev: any) => {userLevel = ev.target.value;}}
                    variant="outlined"
                >
                    <MenuItem key={"normal"} value={"normal"}>Normal</MenuItem>
                    <MenuItem key={"super"} value={"super"}>Super</MenuItem>
                    <MenuItem key={"dealer"} value={"dealer"}>Dealer</MenuItem>
                    {devLevelAccess && <MenuItem key={"dev"} value={"dev"}>Developer</MenuItem>}
                </TextField>
            </div>
        )})) {
            displayToast({
                message: "canceled",
                severity: "warning",
                withCloseIcon: true,
            });
            return;
        }
        let tmp = userLevel === "dev" ? "developer" : userLevel;
        tmp = tmp.charAt(0).toUpperCase() + tmp.slice(1);
        ConfigurationsService.setTabletUserLevel({tabletId: deviceId, userLevel: userLevel as TabletUserLevel}).then(() => {
            displayToast({
                message: "Tablet level has been set to "+tmp+".",
                severity: "success",
                withCloseIcon: true,
            });
        }).catch(toastError(displayToast));
    };
    
    
    const lockVersion = async () => {
        let version=(tabletAppVersions as any)[deviceId]??"UNLOCKED"
        let oneTime=true;
        
        if (!await displayDialog({positiveButtonText: "Ok", negativeButtonText: "Cancel", zIndex: 1300, dialogText: (
            <div>
                <Typography style={{marginBottom: "7px"}}>Change tablet user level to:</Typography>
                
                        <AutocompleteV2
                            selectOnTab
                            defaultValue={version}
                            options={["UNLOCKED", ...appVersions as  string[]]}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label={"App Version"}
                                        required
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                version=val??"";
                            }}
                        />
                      
                        <FormControlLabel
                            control={
                                <Switch
                                defaultChecked={oneTime}
                                onChange={(ev)=>{oneTime=ev.target.checked}}
                                id={"oneTime"}
                                />
                            }
                            label="Unlock after update"
                            style={{width:"100%",margin:"10px 0"}}
                        />                   
            </div>
        )})) {
            displayToast({
                message: "canceled",
                severity: "warning",
                withCloseIcon: true,
            });
            return;
        }

        await ConfigurationsService.setTabletAppVersion(deviceId, version,oneTime)
        
    };

    const resetApp= async ()=>{
        await KnoxService.resetApp(uid)
    }

   
    
    return (
        <>
            <Box p={0.5}>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    target="_blank"
                    href="https://start.teamviewer.com/"
                >
                    TeamViewer Remote Support
                </Button>
            </Box>
            { knoxMenuAccess && <Box p={0.5}>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{width:"150px"}}
                    onClick={triggerKnoxMenu}
                >
                    Knox menu {open ? <ExpandLess /> : <ExpandMore />}
                </Button>
                
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Box>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                target="_blank"
                                style={{width:"150px",marginTop:"5px"}}
                                href="https://eu01.manage.samsungknox.com/emm/admin/secured/emm_main.do"
                            >
                                Open Knox
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                disabled={!isValidUID(uid)}
                                target="_blank"
                                style={{width:"150px",marginTop:"5px"}}
                                href={"https://eu01.manage.samsungknox.com/emm/remotesupport/login.do?deviceId="+uid+"&telNo=&pushType=Push&executeType=Force"}
                            >
                                Remote Support
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                disabled={!isValidUID(uid)}
                                onClick={resetApp}
                                style={{width:"150px",marginTop:"5px"}}
                            >
                                Reset app
                            </Button>
                        </Box>
                        {/* <ListItemButton sx={{ pl: 4 }}>
                        
                        </ListItemButton> */}
                    </List>
                </Collapse>

            </Box>}
            
            {tabletUserLevelAccess && <Box p={0.5}>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={changeUserLevel}
                >
                    Change User Level
                </Button>
            </Box>}
            {tabletAppVersionAccess && <Box p={0.5}>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={lockVersion}
                >
                    Lock version
                </Button>
            </Box>}
        </>
    );
}
