import { useCallback } from "react";
import { SharedStateHooks } from "src/components/SharedStateProvider";

export interface UseReloadHooks {
    useReload: (() => void)[];
    shouldReloadDeps: ((deps: boolean[]) => boolean[])[];
    reloadingDeps: ((deps: boolean[]) => boolean[])[];
}

export interface UseReload {
    getHooks: () => UseReloadHooks;
    reload: () => void;
    shouldReload: boolean;
    reloading: boolean;
}

export default function useReload(hooks: SharedStateHooks) {
    (hooks as any).useReload = [];
    (hooks as any).shouldReloadDeps = [];
    (hooks as any).reloadingDeps = [];
    hooks.useInstance.push(useInstance as any);
}

function useInstance(instance: UseReload) {
    const { getHooks } = instance;

    instance.reload = useCallback(() => {
        getHooks().useReload.forEach((r) => r());
    }, [getHooks]);

    instance.shouldReload = getHooks()
        .shouldReloadDeps.reduce((acc, next) => next(acc), [] as boolean[])
        .reduce((a, b) => a || b, false);

    instance.reloading = getHooks()
        .reloadingDeps.reduce((acc, next) => next(acc), [] as boolean[])
        .reduce((a, b) => a || b, false);
}
