import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

export default function EditRobotAccessGroupsPopup(props) {
  const [open, setOpen] = React.useState(false);

  /*
  props:
  Robot
  AccessGroups
  */
  const initialState = {
    chosenAccessGroups: [],
    accessGroups: []
  };
  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  )
  const updateAccessGroups = () => {
    axios("/api/dbtool/accessgroups")
      .then(response => response.data[0])
      .then(state => {
        let chosenAccessGroups = state.filter(g => { return props.AccessGroups.map(ag => ag.Id).indexOf(g.Id) >= 0 });
        setState({
          accessGroups: state,
          chosenAccessGroups: chosenAccessGroups
        });
      });
  };

  const handleClickOpen = () => {

    // Update selects
    updateAccessGroups();

    // open the popup
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    let data = {
      i: props.Robot.Id,
      g: state.chosenAccessGroups.map(g => { return { i: g.Id, p: 0 } })
    }
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: data
    };
    axios("/api/dbtool/RobotAccessGroups", requestOptions)
      .then(response => response.data)
      .then(resp => {
        if (resp.success) {
          if (props.whenSuccess)
            props.whenSuccess();
          handleClose();
        }
        else
          alert("AccessGroups for robot group " + state.Robot.Id + " could not be saved");
      });
  };

  return (

    <>
      <IconButton aria-label="Edit Robot" component="span" onClick={handleClickOpen} >
        <Edit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >

        <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Edit Robot {props.Robot.RobotIdName}</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={state.accessGroups}
                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                renderInput={(params) => <TextField {...params} label="Acess groups" />}
                onChange={(e, v) => setState({ chosenAccessGroups: v })}
                value={state.chosenAccessGroups}
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            SAVE
          </Button>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
