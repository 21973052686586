import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery, Theme, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import Widgets, { Widget } from "./Widgets";
import { useWidgetState } from "./plugins";
import SharedHeightProvider from "src/components/SharedHeightProvider";
import Loader from "src/components/Loader";
import Fade from "@material-ui/core/Fade";
import classNames from "src/utils/classNames";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserService from "src/services/UserService";
import { useAsync, useLocalStorage } from "src/hooks";
import { userAccessSelector } from "src/redux/auth/selectors";

const useStyles = makeStyles((theme) => ({
    xlBreakpoint: {
        [theme.breakpoints.up(
            theme.breakpoints.values.xl + theme.tmrOptions.drawerWidth
        )]: {
            maxWidth: "25%",
            flexBasis: "25%",
        },
    },
    container: {
        margin: 0,
        padding: 0,
        width: "100%",
        "& > *": {
            margin: 0,
            padding: "0px !important",
        },
    },
}));
interface WidgetLayoutProps{
    saveChanges:boolean
}
const WidgetLayout = React.memo(function WidgetLayout(props:WidgetLayoutProps) {
    const {saveChanges}=props
    
    const classes = useStyles();
    const [favouriteWidgets, setFavouriteWidgets] = useLocalStorage("favourite-widgets", [] as string[]);

    const userAccess=useSelector(userAccessSelector)

    const isSmallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm")
    );

    const isMediumScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    const isXLScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(
            theme.breakpoints.values.xl + theme.tmrOptions.drawerWidth
        )
    )
    
    let numColumns = 3;
    if (isMediumScreen)
        numColumns = 2;

    const masonry = useMemo(
        () =>{
            const categories=["Favourite", ...new Set(Widgets.map((w:any)=>w.category))]
            const widgets = categories.map((cat)=>{
                return {
                    category:cat,
                    items:Widgets.filter((w:any)=>{
                        const isFavourite = favouriteWidgets?.includes(w.nameGiven)
                            if(cat==="Favourite")
                                return isFavourite === true;
                            else
                                return w.category===cat
                        })
                    .reduce(
                        (acc, next) => {
                            if(next.requiredAccess && !userAccess?.includes(next.requiredAccess))
                                return acc
                            const current = acc[acc.length - 1];
                            if (current.length < numColumns) {
                                current.push(next);
                            } else {
                                acc.push([next]);
                            }
                            return acc;
                        },
                        [[]] as Widget[][]
                    )
                }}).filter((cat)=>{return cat.category === "Favourite" || cat.items[0].length>0})
            return widgets
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [numColumns, JSON.stringify(favouriteWidgets)]
    );

    const { reload, reloading, shouldReload } = useWidgetState();
    const [mount, setMount] = useState(!reloading && !shouldReload);
    return (
        <>
            <Loader
                retry={reload}
                loading={reloading}
                error={
                    shouldReload && !reloading
                        ? "An error occurred while loading one or more widgets"
                        : undefined
                }
                onEnter={() => setMount(false)}
                onExited={() => setMount(true)}
            />
            <Fade in={mount} timeout={{ enter: 500, exit: 0 }}>
                <Grid
                    container
                    direction="column"
                    spacing={1}
                    className={classes.container}
                >
                    {masonry.map((m:any,index)=>{
                        return <Accordion key={index} defaultExpanded style={{background:"rgba(0,0,0,0.2)"}}>
                            <AccordionSummary  expandIcon={<ExpandMoreIcon style={{fontSize:48}}/>}
                            aria-controls="panel1a-content"
                            ><Typography style={{fontSize:24}}>{m.category}</Typography></AccordionSummary>
                            <AccordionDetails>
                                {m.items.map((items:any, idx:any) => {
                        
                                    return (
                                        <SharedHeightProvider
                                            key={`${idx}/${items.length}`}
                                            disabled={isSmallScreen}
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                direction="row"
                                                spacing={2}
                                                alignItems="stretch"
                                            >
                                                {items.map((WidgetElement:any) => {
                                                    return (
                                                    <Grid
                                                        key={WidgetElement.nameGiven}
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        lg={4}
                                                        className={classNames(
                                                            (masonry[0] as any).length === 4 &&
                                                                classes.xlBreakpoint
                                                        )}
                                                    >
                                                        <WidgetElement widgetName={WidgetElement.nameGiven} saveChanges={saveChanges} favouriteWidgets={favouriteWidgets} setFavouriteWidgets={setFavouriteWidgets}/>
                                                    </Grid>
                                                )})}
                                            </Grid>
                                        </SharedHeightProvider>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    })
                    
                    
                    }
                </Grid>
            </Fade>
        </>
    );
});

export default WidgetLayout;
