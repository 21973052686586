import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import useDialog from "src/hooks/useDialog";

export default function PromptV2(props: {when : boolean, message? : string}) {
    const { when, message } = props;
    const dispatch = useDispatch();
    const { displayDialog } = useDialog();
    const [override, setOverride] = useState(false);

    return (
        <Prompt
            when={when}
            message={(location) => {
                if (!override)
                    displayDialog({dialogText: message ?? "You have unsaved changes, are you sure you want to leave?", positiveButtonText: "Yes", negativeButtonText: "Cancel"}).then((val) => {
                        if (val) {
                            setOverride(true);
                            dispatch(push(location.pathname));
                        }
                    });
                return override;
            }}
        />
    );
}