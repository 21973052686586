import { Box, makeStyles, Button,  TextField, MenuItem, Switch, FormControlLabel,  } from "@material-ui/core";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import UncontrolledDialog from "src/components/UncontrolledDialog";
import { useAsync, useToast } from "src/hooks";
import { useSection } from "./SectionProvider";
import CommandService from "src/services/CommandService";
import RefreshButton from "src/components/RefreshButton";
import moment from "moment";
import { DeviceType, TimeFormats } from "src/utils/constants";
import { OnlineIcon } from "../Icons";
import UpdateCommand from "./Commands/UpdateCommand";
import NTRIPCommand from "./Commands/NTRIPCommand";

const commonKeys = {
    online: "Server online",
    online2: "vpn_status",
    online3: "connected",
};

const commands=[
    {
        command:"update",
        name:"update",
        //filterRobots:[],
        atLeastSupport:false,
        component: (props: {atLeastSupport:boolean,deviceId:number,historyRef:any,deviceTypeId:number})=> <UpdateCommand atLeastSupport={props.atLeastSupport} deviceId={props.deviceId} historyRef={props.historyRef} deviceTypeId={props.deviceTypeId}></UpdateCommand>
    },
    {
        command:"reconfigure_ntrip",
        name:"NTRIP",
        //filterRobots:["TS1","TCS1","TSC1","Base Station"],
        atLeastSupport: true,
        component: (props:{deviceId:number,historyRef:any,atLeastSupport:boolean,deviceTypeId:number})=><NTRIPCommand robotId={props.deviceId} historyRef={props.historyRef} atLeastSupport={props.atLeastSupport} deviceTypeId={props.deviceTypeId}></NTRIPCommand> 
    }
]


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        '& *': {fontFamily: theme.typography.fontFamily},
    },
    history: {
        display: "flex",
        flexDirection: "row",
        alignItems: "top",
        '&>div:nth-child(2)': {
            flexShrink: 0,
            width: "450px",
            display: "flex",
            flexDirection: "column",
            '&>div:nth-child(3)': {
                '&>*': {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    '&>*:nth-child(1)': {width: "120px"},
                    '&>*:nth-child(2)': {width: "130px"},
                    '&>*:nth-child(3)': {flexGrow: 1},
                    padding: "5px",
                },
                '&>div:nth-child(1)': {fontWeight: "bold", cursor:"default"},
                '&>div:nth-child(n+2)': {cursor: "pointer"},
                '&>div:nth-child(2n)': {backgroundColor: "#DDDDDD"},
                '&>div:nth-child(n+2):nth-child(2n):hover': {backgroundColor: "#EEEEEE"},
                '&>div:nth-child(2n+1)': {backgroundColor: "#BBBBBB"},
                '&>div:nth-child(n+2):nth-child(2n+1):hover': {backgroundColor: "#CCCCCC"},
            },
            marginRight: "15px",
        },
        '&>div:nth-child(3)': {
            flexGrow: 1,
            cursor: "default",
            '&>*': {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom:"10px",
            },
            '&>*:nth-child(n+2)': {
                '&>*:nth-child(1)': {flexGrow: 1},
                '&>*:nth-child(2)': {width: "110px"},
                padding: "5px",
            },
            '&>div:nth-child(2)': {fontWeight: "bold"},
            '&>div:nth-child(n+2):nth-child(2n)': {backgroundColor: "#DDDDDD"},
            '&>div:nth-child(n+2):nth-child(2n+1)': {backgroundColor: "#FFFFFF"},
        },
        marginTop: "20px",
    },
    new: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        '&>*': {
            marginRight: "15px",
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));

const formatDate = (dateString: string) => {
    if (dateString === undefined || dateString === null || dateString === "")
        return "N/A";
    return moment(dateString).format(TimeFormats.Presentation2);
}

const CommandsHistory = forwardRef((props: {atLeastSupport:boolean,deviceId:number,command:string,deviceType:number}, ref) => {

    const { atLeastSupport, deviceId,command,deviceType } = props;

    const classes = useStyles();

    const { displayToast } = useToast();

    const displayErrorToast = (val:any) => displayToast({
        message: val.message,
        severity: "error",
        withCloseIcon: true,
    });

    const fetchCommands_ = useCallback(() => CommandService.getCommands({robotId: deviceId, type: command,deviceType:deviceType}), [deviceId, command,deviceType]);
    const { value: Commands, pending: CommandsLoading, exec: fetchCommands } = useAsync(fetchCommands_, {
        immediate: false,
        clearValueOnExec: false,
        onError: displayErrorToast,
    });
    useEffect(() => {
        fetchCommands();
    }, [command]); //eslint-disable-line react-hooks/exhaustive-deps

    const [selectedCommand, setSelectedCommand] = useState(undefined as number | undefined);
    const currentCommand = Commands?.find(c => c.id === selectedCommand);

    const [selectFirst, setSelectFirst] = useState(false);
    useEffect(() => {
        if (selectFirst && Commands && Commands.length > 0) {
            setSelectedCommand(Commands[0].id);
            setSelectFirst(false);
        }
    }, [Commands]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchCommandLogs_ = useCallback(() => CommandService.getLogs(selectedCommand!), [selectedCommand]);
    const { value: commandLogs, pending: commandLogsLoading, exec: fetchCommandLogs } = useAsync(fetchCommandLogs_, {
        immediate: false,
        clearValueOnExec: false,
        onError: displayErrorToast,
    });
    useEffect(() => {
        fetchCommands();
        if (selectedCommand)
            fetchCommandLogs();
        const id = setInterval(() => { //eslint-disable-line react-hooks/exhaustive-deps
            if (selectedCommand) {
                fetchCommands();
                fetchCommandLogs();
            }
        }, 3000);
        return () => clearInterval(id);
    }, [selectedCommand]); //eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
        fetchCommands,
        setSelectedCommand,
        setSelectFirst,
    }));
    const [filterMessage,setFilterMessage]=useState(true)
    
    return (
        <div className={classes.history}>
            <div key="type"></div>
            <div key="commands">
                <div style={{fontWeight:"bold"}}>Command History</div>
                <div style={{fontStyle:"italic"}}>Click on a command for more details!</div>
                <div>
                    <div><div>by</div><div>type</div><div>args</div></div>
                    {(Commands??[]).filter((c)=>c.type===command).map(c => 
                        <div key={c.id} onClick={() => {setSelectedCommand(c.id);}} style={selectedCommand===c.id?{border:"solid 1px black"}:{}}>
                            <div>{c.byUser}</div><div>{c.type}</div><div style={{whiteSpace: "pre-wrap"}}>{(atLeastSupport&&selectedCommand===c.id)?JSON.stringify(c.args,null,2):(c.type==="update"?c.args.rs:c.args.address)}</div>
                        </div>
                    )}
                </div>
            </div>
            {(currentCommand) && <div key="logs">
                <div style={{justifyContent:"space-between"}}>
                    <div>
                        issued at: <span style={{fontWeight:"bold"}}>{formatDate(currentCommand.issuedAt)}</span>;
                        started at: <span style={{fontWeight:"bold"}}>{formatDate(currentCommand.startedAt)}</span>;
                        completed at: <span style={{fontWeight:"bold"}}>{formatDate(currentCommand.completedAt)}</span>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginLeft:"10px"}}>
                        <RefreshButton
                            style={{margin: "0px"}}
                            loading={commandLogsLoading || CommandsLoading}
                            onClick={() => {
                                fetchCommandLogs();
                                fetchCommands();
                            }}
                        />
                    </div>
                </div>
                <div><div>Message</div><div>created at</div></div>
                {(commandLogs??[]).filter((c,index)=>{return filterMessage?(c.error||(c.message.includes('/'))||index===((commandLogs??[]).length-1)):true}).map(c => 
                    <div key={c.id} style={c.error?{color:"red"}:{}}>
                        <div>{c.message}</div><div>{formatDate(c.createdAt)}</div>
                    </div>
                )}


                        <Button
                    className={classes.button}
                    style={{marginTop:"10px",display:"inline-block"}}
                    size="medium"
                    variant="outlined"
                    disabled={currentCommand.startedAt!==null}
                    onClick={() => {
                        CommandService.cancelCommand(selectedCommand!).then(() => {
                            displayToast({
                                message: "Command cancelled!",
                                severity: "warning",
                                withCloseIcon: true,
                            });
                            fetchCommandLogs();
                        }).catch(() => {
                            displayToast({
                                message: "Error cancelling command!",
                                severity: "error",
                                withCloseIcon: true,
                            });
                        });
                    }}
                >
                    Cancel Command
                </Button>
                
                    <FormControlLabel
                    style={{display:"inline-block", margin:"auto 15px"}}
                        control={
                            <Switch
                                checked={filterMessage}
                                onChange={(ev) => setFilterMessage(ev.target.checked)}
                                size="small"
                                color="primary"
                            />
                        }
                        label="Show important only"
                    />
                

            </div>}
        </div>
    );
});
const Commands = (props: {atLeastSupport:boolean,deviceId:number|undefined,onlineStatus:any,deviceType:DeviceType}) => {
    const classes = useStyles();
    const { atLeastSupport, deviceId, onlineStatus, deviceType } = props;

    const historyRef = useRef();

    const fetchCommandsAccess_ = useCallback(() => CommandService.getCommandAccessForRobot(deviceId!, deviceType).then(r => {setNewType(r[0]); return r}), [deviceId, deviceType]);
    const { value: commandAccess, pending: accessLoading, exec: fetchCommandsAccess } = useAsync(fetchCommandsAccess_, {
        immediate: false,
        //clearValueOnExec: false,
    });
    
    const setSelectedCommand = (val:number|undefined) => {
        if (historyRef && historyRef.current)
            (historyRef.current as any).setSelectedCommand(val);
    }
    const [newType, setNewType] = useState("");

    return (
        <UncontrolledDialog

            closeOnClickAway={false}
            maxWidth={false}
            Button={({ onClick }) => (
                <Box p={0.5}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            onClick();
                            fetchCommandsAccess();
                        }}
                        disabled={deviceId === undefined}
                    >
                        Commands
                    </Button>
                </Box>
            )}
            onClose={() => {setSelectedCommand(undefined);
            setNewType("")}}
        >
            {{
                title: <React.Fragment ><div style={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"2px solid"}}>
                    <div>{"Commands - Robot "+deviceId+" -"}</div>
                    <div style={{marginLeft:"10px",marginRight:"10px",paddingTop:"4px"}}><OnlineIcon status={onlineStatus?.value??"Offline"}/></div>
                    <div style={{fontSize:"15px"}}>{onlineStatus?.value??"Offline"}</div>
                </div></React.Fragment>,
                content: (
                    <div className={classes.root} style={{marginBottom:"20px"}}>
                        <div className={classes.root} style={{borderBottom:"2px solid",paddingBottom:"20px"}}>
                            <div className={classes.new} style={{marginBottom:"30px",borderBottom:"2px solid",paddingBottom:"20px"}}>
                                <div style={{fontWeight: "bold"}}>NEW COMMAND</div>
                                <TextField
                                    style={{width: "250px"}}
                                    label="Command Type"
                                    margin="dense"
                                    size="small"
                                    select
                                    disabled={accessLoading}
                                    value={newType}
                                    onChange={(ev: any) => {setNewType(ev.target.value);}}
                                    variant="outlined"
                                >
                                    {commands.filter((c)=>commandAccess?.includes(c.name)).filter((c)=>!c.atLeastSupport || atLeastSupport).map(t =>
                                        <MenuItem key={t.command} value={t.command}>{t.name}</MenuItem>
                                    )}
                                </TextField>
                                
                            </div>
                            {commands.find((c)=>c.command===newType)?.component({atLeastSupport:atLeastSupport,deviceId:deviceId!,historyRef: historyRef,deviceTypeId: deviceType})}
                        </div>
                        
                        <CommandsHistory
                            atLeastSupport={atLeastSupport}
                            ref={historyRef}
                            deviceId={deviceId!}
                            command={newType}
                            deviceType={deviceType}
                        />
                    </div>
                ),
            }}
        </UncontrolledDialog>
    );
}

const CommandsWrapper = (props: {atLeastSupport:boolean}) => {
    const { deviceId, data: values } = useSection();

    let onlineStatus: any = values[commonKeys.online];
    if (onlineStatus && onlineStatus.value.toLowerCase() !== "online")
        if (values[commonKeys.online2] !== undefined)
            onlineStatus = values[commonKeys.online2];
    if (onlineStatus === undefined && values[commonKeys.online3] !== undefined)
        onlineStatus = {...values[commonKeys.online3], value: values[commonKeys.online3].value.toLowerCase() === "true" ? "Online" : "Offline"};

    return useMemo(() =>
        <Commands 
            atLeastSupport={props.atLeastSupport}
            deviceId={deviceId}
            onlineStatus={onlineStatus}
            deviceType={ values[Object.keys(values)[0]]?.deviceTypeId}
        />
    ,[deviceId,values["Robot type"],values["robot_name"]]); //eslint-disable-line react-hooks/exhaustive-deps
}
export const ProductionCommandsWrapper = (props: {atLeastSupport:boolean, deviceId: number | undefined, deviceType: DeviceType}) => {
    const { deviceId, atLeastSupport, deviceType } = props;

    return useMemo(() =>
        <Commands 
            atLeastSupport={atLeastSupport}
            deviceId={deviceId}
            onlineStatus={undefined}
            deviceType={deviceType}
        />
    ,[atLeastSupport, deviceId, deviceType]);
}
export default CommandsWrapper;