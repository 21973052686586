import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import {userAccessSelector } from "../../redux/auth/selectors";

import PrivateRoute from "../../components/PrivateRoute";
import NotFound from "../NotFound";

import UserSettings from "./User";

export default function Settings() {
    const { path } = useRouteMatch();
    
    const access=useSelector(userAccessSelector)
    const userAccess = access.includes('User')

    return (
        <Switch>
            <PrivateRoute exact path={`${path}/user`}>
                {(access.length !== 1 && <UserSettings />) || <NotFound />}
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/keys`}>
                {(userAccess && <></>) || <NotFound />}
            </PrivateRoute>
            <PrivateRoute>
                <NotFound />
            </PrivateRoute>
        </Switch>
    );
}
