import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "../utils/classNames";

const useStyles = makeStyles(() => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function LoadingButton(
    props: ButtonProps & {
        loading?: boolean;
        classes?: { button?: string; progress?: string };
    }
) {
    const classes = useStyles();
    const {
        loading,
        disabled,
        className,
        classes: propClasses,
        ...rest
    } = props;

    const shouldLoad = loading || false;
    return (
        <div className={classNames(classes.wrapper, className)}>
            <Button
                disabled={shouldLoad || disabled}
                className={propClasses?.button}
                {...rest}
            />
            {shouldLoad && (
                <CircularProgress
                    size={24}
                    className={classNames(
                        classes.buttonProgress,
                        propClasses?.progress
                    )}
                />
            )}
        </div>
    );
}
