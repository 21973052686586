import axios from "axios";
import { handleError } from "./Errors";

export default class FileService {
    static async downloadFile(
        filename: string,
        onProgress?: (ev: ProgressEvent) => void
    ): Promise<void> {
        return FileService.download(`/files/${filename}`, {
            defaultName: filename,
            onProgress: onProgress,
        });
    }

    static async download(
        path: string,
        options?: {
            onProgress?: (ev: ProgressEvent) => void;
            defaultName?: string;
            type?: string;
        }
    ): Promise<void> {
        const { onProgress, defaultName = `download-${Date.now()}.txt`, type } =
            options || {};

        return axios({
            url: path,
            method: "GET",
            responseType: "blob", // important
            onDownloadProgress: onProgress,
            timeout: 60000,
        })
            .then((response) => {
                const filename: string =
                    response.headers["content-disposition"]
                        ?.split("filename=")[1]
                        ?.replace(/"/g, "") || defaultName;

                const url = window.URL.createObjectURL(
                    new Blob([response.data as any], {
                        type: response.headers["content-type"] ?? type,
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.onclick = (ev) => {
                    ev.stopPropagation();
                };
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => handleError(err, false));
    }
}
