import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { toastSelector } from "../redux/app/selectors";
import { controlToast } from "../redux/app/actions";
import { ToastMessage as ToastMessageProps } from "src/redux/app/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.tmrOptions.drawerWidth / 2,
                marginTop: theme.spacing(8),
            },
        },
    })
);

function getSnackbarProps(
    toast: ToastMessageProps | undefined,
    close: () => void
) {
    if (!toast) {
        return { message: "" };
    }

    const { state, message, severity, actions, withCloseIcon } = toast;

    const props: Partial<SnackbarProps> = {};

    let action = withCloseIcon ? (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={close}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    ) : null;

    if (actions) {
        actions.reverse();
        actions.forEach(({ title, callback }) => {
            action = (
                <>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => callback(state)}
                    >
                        {title}
                    </Button>
                    {action}
                </>
            );
        });
    }

    if (severity === "default") {
        props.message = message;

        if (action) {
            props.action = action;
        }
    } else {
        props.children = (
            <Alert severity={severity} variant="filled" action={action}>
                {message}
            </Alert>
        );
    }

    return props;
}

export default function ToastMessage() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const close = useCallback(() => {
        dispatch(controlToast(false));
    }, [dispatch]);

    const { open, message } = useSelector(toastSelector);

    return (
        <Snackbar
            open={open}
            className={classes.root}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            {...getSnackbarProps(message, close)}
        />
    );
}
