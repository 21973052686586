import { createSelector } from "reselect";
import { AppState } from "./types";
import { Customer } from "src/services/UserService";
import { credentialsSelector } from "../auth/selectors";
import Tree, { TreeNode } from "src/utils/Tree";

type StateProxy = { app: AppState };

export const timezoneSelector = (state: StateProxy) => state.app.selectedTimezone;

export const excludeDeprecatedSelector = (state: StateProxy) => state.app.excludeDeprecated;

export const usersSelector = (state: StateProxy) => state.app.users;

export const userSelector = (state: StateProxy) => state.app.user;

export const robotsSelector = (state: StateProxy) => state.app.excludeDeprecated?state.app.robots.filter(r => r.deprecated===null):state.app.robots;

export const tabletsSelector = (state: StateProxy) => state.app.excludeDeprecated?state.app.tablets.filter(r => r.deprecated===null):state.app.tablets;

export const basestationsSelector = (state: StateProxy) => state.app.excludeDeprecated?state.app.basestations.filter(r => r.deprecated===null):state.app.basestations;

export const robotsSelectorUnfiltered = (state: StateProxy) => state.app.robots;

export const tabletsSelectorUnfiltered = (state: StateProxy) => state.app.tablets;

export const basestationsSelectorUnfiltered = (state: StateProxy) => state.app.basestations;

export const operationsSelector = (state: StateProxy) => state.app.operations;

export const quotesSelector = (state: StateProxy) => state.app.quotes;

export const contactsSelector = (state: StateProxy) => state.app.contacts;

export const companiesSelector = (state: StateProxy) => state.app.companies;

export const accessFieldsSelector = (state: StateProxy) => state.app.accessFields;

export const dialogSelector = (state: StateProxy) => state.app.dialog;

export const toastSelector = (state: StateProxy) => state.app.toast;

export const googleMapsTokenSelector= (state: StateProxy)=> state.app.googleMapsToken;

export const connectionStateSelector = (state: StateProxy) =>
    state.app.connectionState;

export const customersSelector = (state: StateProxy) => state.app.customers;

export const connectedSelector = createSelector(
    connectionStateSelector,
    (connectionState) => connectionState.connectedToServer
);
export const customersDtoSelector = createSelector(
    customersSelector,
    ({ dto }) => dto
);

export const customersLoadedSelector = createSelector(
    customersSelector,
    ({ loaded }) => loaded
);

export const customersArraySelector = createSelector(
    customersDtoSelector,
    (dto) => Object.values(dto) as Customer[]
);

export const customersTreeSelector = createSelector(
    customersDtoSelector,
    credentialsSelector,
    (dto, credentials) => {
        if (credentials === undefined) {
            return undefined;
        }

        // const self = dto[credentials.customerId[0]];
        const selves=credentials.customerId.map((c)=>dto[c])
        const values: Customer[] = Object.values(dto);
        

        if (selves === undefined || values.length === 0) {
            return undefined;
        }

        values.sort((a,b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });

        

        const mapTreeNode = (cust: Customer): TreeNode<Customer> => {
        const childrenCache = new Map<number, Customer[]>();

        values.forEach((customer) => {
                if (cust===customer)
                return;

            if (!childrenCache.has(customer.parentId)) {
                childrenCache.set(customer.parentId, []);
            }

            childrenCache.get(customer.parentId)!.push(customer);
        });
            return {
                item: cust,
                children: (childrenCache.get(cust.id) || []).map(
                    mapTreeNode
                ),
            };
        };
        return new Tree({item:{id: 0, name: "", parentId: 0, isDealer: false},children:selves.map((slf)=>(mapTreeNode(slf)))} as TreeNode<any>)
    }
);

export const dealersSelector = createSelector(
    customersArraySelector,
    (customers) => customers.filter((c) => c.isDealer)
);
