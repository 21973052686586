import { useState, useCallback, useEffect, useRef } from "react";
import useMakeCancelablePromise from "./useMakeCancellablePromise";
import useErrorHandler from "./useErrorHandler";
import type { PromiseResult } from "src/types";

type IsEmptyParameterFn<U extends (...args: any[]) => any> = Parameters<
    U
> extends []
    ? true
    : false;

type IncludeArg<
    T extends (...args: any[]) => Promise<any>,
    Immediate extends boolean
> = IsEmptyParameterFn<T> extends false
    ? Immediate extends true
        ? true
        : false
    : false;

type AppendArgs<
    U,
    T extends (...args: any[]) => Promise<any>,
    Immediate extends boolean
> = IncludeArg<T, Immediate> extends true
    ? U & {
          /**
           * Arguments passed to the function when immediate is true. These are cached.
           */
          args: Parameters<T>;
      }
    : U;

interface DefaultAsyncOptions<
    T extends (...args: any[]) => Promise<any>,
    Immediate extends boolean
> {
    /**
     * Is invoked when the async function has completed successfully.
     * Should be memoized.
     */
    onComplete?: (arg: PromiseResult<ReturnType<T>>) => void;
    /**
     * Is invoked when the async function throws an exception.
     * Should be memoized. Default: 'useErrorHandler'
     */
    onError?: (arg: PromiseResult<ReturnType<T>>) => boolean | void;
    /**
     * Invoke the callback immediately. Default: true
     */
    immediate?: Immediate;
    /**
     * Clear previous value when the returned function is invoked. Default: true
     */
    clearValueOnExec?: boolean;
    /**
     * Default value of pending. Default: false
     */
    defaultPending?: boolean;
}

export type AsyncOptions<
    T extends (...args: any[]) => Promise<any>,
    Immediate extends boolean
> = AppendArgs<DefaultAsyncOptions<T, Immediate>, T, Immediate>;

type UseAsyncArgs<
    T extends (...args: any[]) => Promise<any>,
    Immediate extends boolean
> = IncludeArg<T, Immediate> extends true
    ? [T, AsyncOptions<T, Immediate>]
    : [T, AsyncOptions<T, Immediate>] | [T];

export default function useAsync<
    T extends (...args: any[]) => Promise<any>,
    Immediate extends boolean
>(...params: UseAsyncArgs<T, Immediate>) {
    const makeCancelablePromise = useMakeCancelablePromise();
    const defaultErrorHandler = useErrorHandler();

    const [asyncFn, opts] = params;

    const {
        onComplete,
        onError = defaultErrorHandler,
        immediate = true,
        clearValueOnExec = true,
        defaultPending = false,
    } = opts || {};

    const fnArgs = (opts as any)?.args ?? [];
    const fnArgsRef = useRef<Parameters<T>>(fnArgs);

    const [pending, setPending] = useState(defaultPending);
    const [value, setValue] = useState<PromiseResult<ReturnType<T>>>();
    const [error, setError] = useState<any>();

    const handleComplete = useCallback(
        (arg: PromiseResult<ReturnType<T>>) => {
            setValue(arg);
            setPending(false);
            if (onComplete !== undefined) {
                onComplete(arg);
            }
        },
        [onComplete]
    );

    const handleError = useCallback(
        (err: any) => {
            if (err && err.isCanceled) {
                return;
            }
            setPending(false);
            if (onError !== undefined && onError(err)) {
                return;
            }

            setError(err);
        },
        [onError]
    );

    const exec = useCallback(
        (...args: Parameters<T>) => {
            setPending(true);
            setError(undefined);
            if (clearValueOnExec) {
                setValue(undefined);
            }
            makeCancelablePromise(asyncFn(...args))
                .then(handleComplete)
                .catch(handleError);
        },
        [
            asyncFn,
            handleComplete,
            handleError,
            makeCancelablePromise,
            clearValueOnExec,
        ]
    );

    useEffect(() => {
        if (immediate) {
            exec(...fnArgsRef.current);
        }
    }, [immediate, exec]);

    return { pending, value, error, exec };
}
