import React, { useCallback, useEffect, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useWidgetState } from "../plugins";
import mapInheritableAccessGroups from "../mapInheritableAccessGroups";
import ConfigurationsService from "src/services/ConfigurationsService";
import { useAsync, useErrorHandler, useToast, useGetLatest, useLocalStorage } from "src/hooks";
import { createCustomer } from "src/redux/app/actions";
import { dealersSelector, customersTreeSelector, customersArraySelector, userSelector } from "src/redux/app/selectors";
import type { Customer, CustomerAccessGroup } from "src/services/UserService";
import Typo from "src/utils/jarowinkler";
import useDialog from "src/hooks/useDialog";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { FormControlLabel, Switch } from "@material-ui/core";
import { userAccessSelector } from "src/redux/auth/selectors";

const TYPO_TRESHOLD = 0.9;

const emptyArray: any = [];

interface CreateCustomerInput {
    isRootDealer: boolean
    dealer: Customer | null;
    name: string;
    accessGroups: CustomerAccessGroup[] | null;
}

export default function CreateCustomerWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets  }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        reset,
        watch,
        register,
        formState,
        unregister,
        clearErrors,
    } = useForm<CreateCustomerInput>({
        defaultValues: { isRootDealer: false, dealer: null, name: "", accessGroups: []},
    });

    const { accessGroups: accessGroupsWatched } = watch()!;

    const dispatch = useDispatch();
    const { displayToast } = useToast();
    const { displayDialog } = useDialog();


    const isRootDealer = watch("isRootDealer");
    const disptxt = isRootDealer ? "root dealer" : "customer";

    const dealers = useSelector(dealersSelector).filter((dealer_) => {
        if (!isRootDealer)
            return dealer_.id !== 1;
        return true;
    });
    

    const customersTree = useSelector(customersTreeSelector)!;

    const dealer = watch("dealer");
    useEffect(() => {
        if(dealers.length === 1)
            setValue("dealer", dealers[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const children = useMemo(() => {
        if (dealer) {
            return (
                customersTree?.find((c) => c.id === dealer.id)?.toArray() || []
            ).map((c) => c.name);
        }
        return emptyArray as string[];
    }, [dealer, customersTree]);
    const { value:isHubSpot, exec:checkDescendant, pending:checkingDescendant } = useAsync(
        () => dealer?ConfigurationsService.isDealerDescendant(dealer.id):Promise.resolve(false),
        { immediate: false, clearValueOnExec: false }
    );

    const [createCustomerWidgetLastDealerId,setCreateCustomerWidgetLastDealerId]=useLocalStorage("CreateCustomerWidgetLastDealerId",-1)
    const [createCustomerWidgetLastIsRoot,setCreateCustomerWidgetLastIsRoot]=useLocalStorage("",false)

    useEffect(() => {
        checkDescendant();
    }, [dealer]); //eslint-disable-line react-hooks/exhaustive-deps


    const {
        state: { accessGroups,  },
        addCustomerAccessGroups,
    } = useWidgetState();
    const { dto: accessGroupDto, loaded: accessGroupsLoaded } = accessGroups;
    const user=useSelector(userSelector)
  

    const currentDealerId = dealer?.id;
    const [
        accessGroupsByType,
        accessGroupTypeSelects,
        accessGroupTypeDefaults,
    ] = useMemo(() => {
        return mapInheritableAccessGroups(currentDealerId, accessGroupDto);
    }, [currentDealerId, accessGroupDto]);

    useEffect(() => {
        accessGroupTypeSelects.forEach((type, index) => {
            if (accessGroupsByType[type][0].required)
                register(`accessGroups[${index}]`, defaultRules);
            else if (isRootDealer)
                register(`accessGroups[${index}]`);
        });
    }, [accessGroupsByType, accessGroupTypeSelects, register, clearErrors, isRootDealer]);

    useEffect(()=>{
        if (saveChanges){
           
            if (createCustomerWidgetLastIsRoot && createCustomerWidgetLastIsRoot!==isRootDealer)
            setValue('isRootDealer',createCustomerWidgetLastIsRoot)
            if (createCustomerWidgetLastDealerId && createCustomerWidgetLastDealerId>=0 && createCustomerWidgetLastDealerId!==dealer?.id){
                setValue('dealer',dealers.find(d=>d.id===createCustomerWidgetLastDealerId))
            }
        }
    },[saveChanges,createCustomerWidgetLastDealerId,createCustomerWidgetLastIsRoot,dealer,dealers,setValue,isRootDealer ])

   
   
    const getDefaults = useGetLatest([
        accessGroupsByType,
        accessGroupTypeDefaults,
    ] as const);

    const customers : string[] = useSelector(customersArraySelector)!.map(a => a.name);

    const createCustomerWrapped = useCallback(
        async ({ name: n, dealer: d, accessGroups: a }: CreateCustomerInput) => {
            const [groups, defaults] = getDefaults();
            a = [
                ...(a || []),
                ...defaults.flatMap((df) => groups[df]),
            ].filter(g => g !== undefined);
            const args = {
                name: n,
                parentId: d!.id,
                accessGroups: a.map((g) => g.id),
                isRootDealer: isRootDealer,
            };
            const customer = {
                isDealer: false,
                name: n,
                parentId: d!.id,
                accessGroups: a,
            } as Customer & { accessGroups: CustomerAccessGroup[] };
            const similarCustomer = Typo(customer.name, customers);
            let dialogResult = true;
            if (similarCustomer.rating >= TYPO_TRESHOLD)
                dialogResult = await displayDialog({dialogText: "Similar customer already exists with the name of \"" + similarCustomer.value + "\".\nDo you wish to proceed?"});
            if (dialogResult) {
                customer.id = await ConfigurationsService.createCustomer(args);
                dispatch(createCustomer({...customer,reviewed:!isHubSpot as boolean}));
                addCustomerAccessGroups(customer.id, a);
                if (!saveChanges)
                reset();
                displayToast({
                    message: `${disptxt} ${customer.name} was created successfully.`,
                    severity: "success",
                    withCloseIcon: true,
                });
            } else
                displayToast({
                    message: `${disptxt} creation cancelled.`,
                    severity: "warning",
                    withCloseIcon: true,
                });
        },
        [addCustomerAccessGroups, customers, dispatch, displayDialog, displayToast, getDefaults, reset, isRootDealer, disptxt,saveChanges,isHubSpot]
    );

    const { exec, pending: creatingCustomer, error } = useAsync(
        createCustomerWrapped,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
        }
    );

    const createRootDealerAccess=useSelector(userAccessSelector).includes('CreateRootDealer')

    let subTitle;
    if (isRootDealer)
        subTitle = "Create a new root dealer.\n PLEASE USE LEGAL NAME";
    else
        subTitle = "Create and associate a new customer with a dealer.\n PLEASE USE LEGAL NAME";

    const loading = !accessGroupsLoaded || creatingCustomer ||checkingDescendant;


    return (
        <BaseWidget
            widgetName={widgetName}
            title={`Create ${disptxt}`}
            subTitle={subTitle}
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            {createRootDealerAccess && (
                <Controller
                    name="isRootDealer"
                    control={control}
                    render={() => {
                        return (
                            <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"5px"}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isRootDealer}
                                            onChange={(ev) => {
                                                setValue("isRootDealer", ev.target.checked)
                                                if (saveChanges){
                                                    setCreateCustomerWidgetLastIsRoot(ev.target.checked)}
                                            }}
                                            name="isRootDealer"
                                            size="small"
                                        />
                                    }
                                    label="is root dealer"
                                />
                            </div>
                        );
                    }}
                />
            )}
            
                <Controller
                    name="dealer"
                    control={control}
                    rules={defaultRules}
                    render={({ onChange, ...props }) => {
                        return (
                            <AutocompleteV2
                                selectOnTab
                                {...props}
                                disabled={loading}
                                options={dealers}
                                noOptionsText="No such dealer"
                                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            label="Dealer"
                                            placeholder="Choose a dealer"
                                            required
                                            {...getErrorProps(errors, "dealer")}
                                        />
                                    );
                                }}
                                onChange={(_ev, val) => {
                                    clearErrors("accessGroups");
                                    unregister(
                                        accessGroupTypeSelects.map(
                                            (_, i) => `accessGroups[${i}]`
                                        )
                                    );
                                    
                                    onChange(val);
                                    if (saveChanges){
                                        setCreateCustomerWidgetLastDealerId(val.id??-1)}
                                    
                                }}
                            />
                        );
                    }}
                />
            
            
            <Controller
                name="name"
                control={control}
                rules={{
                    ...defaultRules,
                    validate: (value) => {
                        return (
                            (value &&
                                children.includes(value) &&
                                `A customer by this name already exists for dealer ${
                                    dealer!.name
                                }`) ||
                            true
                        );
                    },
                }}
                render={({ onChange, onBlur, value }) => {
                    // const rename = isHubSpot&&!isRootDealer&&user.customerId.includes(1)&&company?.id!=="new";
                    return (
                        <TextField
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label={"Name"}
                            placeholder="Choose a new name"
                            disabled={loading}
                            required
                            value={value}
                            onBlur={onBlur}
                            onChange={(ev) => onChange(ev.target.value)}
                            {...getErrorProps(errors, "name")}
                        />
                    );
                }}
            />
            {accessGroupTypeSelects.map((type, idx) => (
                <AutocompleteV2
                    selectOnTab
                    key={type}
                    options={accessGroupsByType[type]}
                    value={accessGroupsWatched?.[idx] ?? null}
                    disabled={loading}
                    getOptionLabel={(o) => {
                        if (o.name !== undefined && o.name !== o.shortName)
                            return "("+o.shortName+") "+o.name;
                        return o.shortName;
                    }}
                    getOptionSelected={(o1, o2) => o1.id === o2.id}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label={type}
                                required={accessGroupsByType[type][0].required}
                                {...getErrorProps(
                                    errors,
                                    `accessGroups[${idx}]` as any
                                )}
                            />
                        );
                    }}
                    onChange={(_ev, val) => {
                        setValue(`accessGroups[${idx}]`, val as any, {
                            shouldDirty: formState.isDirty,
                            shouldValidate: formState.isSubmitted,
                        });
                    }}
                />
            ))}
        </BaseWidget>
    );
}
