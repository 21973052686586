import { Box, makeStyles, Button, Tooltip, FormControlLabel, Switch, Slider, TextField, IconButton, SvgIcon, CircularProgress, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import AutocompleteV2 from "src/components/AutocompleteV2";
import RefreshButton from "src/components/RefreshButton";
import UncontrolledDialog from "src/components/UncontrolledDialog";
import { useAsync, useLocalStorage, useResizeObserver, useToast } from "src/hooks";
import { ToastMessage } from "src/redux/app/types";
import DeviceDataService, { BaseStationConnection, DeviceData, TabletConnection } from "src/services/DeviceDataService";
import { deviceTypeIdToString, formatDuration, getBackgroundColors, isNumber } from "src/utils/common";
import { NOT_IMPORTANT_KEYS, TimeFormats } from "src/utils/constants";
import { useTranslator } from "../TranslationProvider";
import { useSection } from "./SectionProvider";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
import { useAsyncDebounce } from "react-table";
import { useSelector } from "react-redux";
import { basestationsSelector, customersSelector, tabletsSelector } from "src/redux/app/selectors";
import { useDashboard } from "./DashboardProvider";
import { timezone } from "src/App";

//marginal constraints for numeric diagrams
const MIN_LINE_LENGTH = 1.5;
const MAX_RESIDUAL_LINE_LENGTH = 6;
//marginal constraints for timeline diagrams
const MIN_LABEL_WIDTH = 5;
const PREFERRED_MAX_RESIDUAL_LABEL_WIDTH = 20;
const STRICT_MAX_RESIDUAL_LABEL_WIDTH = 25;
//number of colors on timeline diagrams
const MAX_COLORS = 20;
//height for numeric diagrams
const SCALE_HEIGHT = 200;
const KEY_WIDTH = 150;
//numeric diagram keys
const NUMERIC_KEYS = [
    "HDOP",
    "Battery",
    "internet_ping",
    "proj_datum_x",
    "proj_datum_y",
    "GNSS fix",
    "ex_input_freq",
    "bt_input_freq",
    "Satellites",
    "Temp ch1",
    "Temp ch2",
    "Temp ic",
    "Antenna calibrate x",
    "Antenna calibrate y",
    "Tool calibrate y",
    "Tool calibrate x",
    " Satellites tracked",
    "Satellites solution",
    "Satellite observations",
    "Satellites tracked",
    "Paint length",
    "Paint width",
    "Line Run-up Before line",
    "Line Run-up On line",
    "Line length",
    "Tool Sideshift",
    "Line space",
    "internet ping",
    "Safety distance to post",
    "GPS satellites",
    "GLONASS satellites",
    "BeiDou satellites",
    "Galileo satellites",
    "temperature_case",
    "humidity_case",
    "temperature_cpu",
    "imu_roll",
    "imu_pitch",
    "time_since_last_ntrip"
];
const AMOUNTS={"m":1, "h/12":5, "h/4":15, "h/2":30, "h":60, "h*2":60*2, "h*4":60*4, "d":60*24, "w":60*24*7};
const TIMELINE_DISPLAY=20;
const ARROW_PATH="M14.59 7.41 18.17 11H6v2h12.17l-3.59 3.59L16 18l6-6-6-6-1.41 1.41zM2 6v12h2V6H2z";
//16x12 px
const ARROW_RIGHT_CURSOR="url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAABmJLR0QA/wD/AP+gvaeTAAAAZElEQVQoka3SMQ6AIAyF4V+vY7wNnkfc9MQODrpgNLHFB9qkC/R9oQkAe+pcRWD2Lt+ADtjSTKwBAMINebxEAbKICphIUxC2amk/hAFWKFth4FphPA9VwAyrgBtWgJ4fPtLkhQ/ToC6wbDx1twAAAABJRU5ErkJggg=='), e-resize";
const ARROW_LEFT_CURSOR=" url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAABmJLR0QA/wD/AP+gvaeTAAAAaklEQVQokZ2SQQqAMAwER/E34ofUf3jt0f/5GA8K9WIhYmu6LuRQwkwTWsgnAGuhlxLvysIROIBeFST4BEZ1AgtPDvwSqPBDEMxBrhbYK2/8zGJWmNUV/kqyz6hIqj7S4Am6ggCgATZnCi4eaTrwXTLKTAAAAABJRU5ErkJggg=='), w-resize";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    diagramBase: {
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        overflowX: "scroll",
        position: "relative",
    },
    diagramArea: {
        width: "max-content",
        flexGrow: 1,
        overflowY: "scroll",
        overflowX: "hidden",
        '& *': {
            fontFamily: theme.typography.fontFamily,
        },
    },
    timeline: {
        zIndex: 1,
        marginTop: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        cursor: "default",
        '& table': {
            marginLeft: "150px",
            marginRight: "17px",
            flexGrow: 1,
            borderCollapse: "collapse"
        },
        '& td': {
            borderTop: "black 4px solid",
            borderLeft: "black 2px solid",
            fontFamily: theme.typography.fontFamily,
            overflow: "hidden",
            textOverflow: "clip",
            whiteSpace: "normal",
        },
    },
    timelineDiagram: {
        marginTop: "5px",
        display: "flex",
        flexDirection: "row",
        '&>div:nth-child(1)': {
            //150px w margin
            marginRight: "5px",
            width: KEY_WIDTH - 5,
            overflow: "hidden",
            textOverflow: "clip",
            whiteSpace: "nowrap",
            cursor: "default",
        },
        '&>div:nth-child(2)': {
            position: "relative",
            '&>*': {
                top: "0px",
                position: "absolute",
                overflow: "hidden",
                textOverflow: "clip",
                whiteSpace: "nowrap",
                borderTop: "solid 1px black",
                borderBottom: "solid 1px black",
                cursor: "default",
                zIndex: 1,
                '&:hover': {
                    border: "solid 2px black",
                    zIndex: 2,
                    top: "-1px",
                    margin: "0px",
                },
            },
        },
    },
    numericDiagram: {
        position: "relative",
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        '&>div:nth-child(1)': {
            //150px w scale + margin
            marginRight: "3px",
            width: KEY_WIDTH - 50,
            overflow: "hidden",
            textOverflow: "clip",
            whiteSpace: "nowrap",
            cursor: "default",
        },
        '&>div:nth-child(2)': {
            //150px w label + margin
            marginRight: "5px",
            width: "42px",
            cursor: "default",
            '& table': {
                width: "100%",
                borderCollapse: "collapse",
                '& td': {
                    borderTop: "black 2px solid",
                    borderRight: "black 4px solid",
                    textAlign: "right",
                    verticalAlign: "text-top",
                },
            },
        },
        '&>div:nth-child(3)': {
            position: "relative",
            '&>*': {
                position: "absolute",
                top: "0px",
                height: "100%",
                zIndex: 1,
                '&>*:nth-child(1)': {
                    position: "absolute",
                    borderTop: "solid 2px black",
                    marginBottom: "-1px",
                },
                '&>*:nth-child(2)': {
                    position: "absolute",
                    borderRight: "dotted 1px black",
                },
            },
            '&>*:hover': {
                '&>*:nth-child(1)': {
                    borderTop: "solid 4px black",
                    marginBottom: "-2px",
                },
            },
        },
        '&>div:nth-child(4)': {
            position: "absolute",
            top: "1px",
            right: "0px",
        },
    },
    gridlineHorizontal: {
        position: "absolute",
        right: "0px",
        borderTop: "dashed 1px #6699FF",
        zIndex: 0,
    },
    gridlineVertical: {
        position: "absolute",
        top: "0px",
        borderRight: "dashed 1px #6699FF",
        zIndex: 0,
        height: "100%",
    },
    verticalGrid: {
        position: "absolute",
        left: "150px",
        top: "5px",
        height: "calc(100% - 5px)",
    },
    keyFilter: {
        minHeight: "40px",
        maxHeight: "72px",
        marginTop: "5px",
        marginBottom: "5px",
        display: "flex",
        justifyContent: "start",
        alignItems: "top",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: "5px",
        width: "auto",
        minWidth: "300px",
        maxWidth: "calc(100% - 1000px)",
        overflowX: "visible",
        overflowY: "auto",
        "&>*": {
            cursor: "pointer",
            textAlign: "center",
            paddingLeft: "3px",
            paddingRight: "3px",
            height: "20px",
            border: "1px solid black",
            borderRadius: "10px",
            fontFamily: theme.typography.fontFamily,
            marginRight: "3px",
            marginBottom: "1px",
            marginTop: "1px",
            whiteSpace: "nowrap",
        },
        "&>*:hover": {
            backgroundColor: "#FF9999",
        },
        "&>*:active": {
            backgroundColor: "#FF8888",
        },
    },
    tabletsControl: {
        position: "relative",
        zIndex: 6,
        borderTop: "solid 2px #BBBBBB",
        borderBottom: "solid 2px #BBBBBB",
        marginTop: "10px",
        marginBottom: "10px",
        paddingTop: "5px",
        paddingBottom: "7px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "row",
        alignItems: "space-between",
        '&>div:nth-child(1)': {
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        '&>div:nth-child(2)': {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
    },
}));

interface NumericDiagramProps {
    from: number,
    until: number,
    dataKey: string,
    values: {
        value: number,
        timestamp: number,
        duration: number,
    }[],
    timelineLength: number,
    timelineStartClipRatio: number,
    timelineEndClipRatio: number,
    classes: any,
    displayToast: (toast: ToastMessage) => void,
    showMarginal: boolean,
    notConnected?: {start: number, end: number}[],
}

interface TimelineDiagramProps {
    from: number,
    until: number,
    dataKey: string,
    values: {
        value: string,
        timestamp: number,
        duration: number,
    }[],
    timelineLength: number,
    timelineStartClipRatio: number,
    timelineEndClipRatio: number,
    classes: any,
    displayToast: (toast: ToastMessage) => void,
    showMarginal: boolean,
    notConnected?: {start: number, end: number}[],
}

interface DiagramData {
    key: string,
    translatedKey: string,
    values: {
        value: string | number,
        timestamp: number,
        duration?: number,
    }[]
}

interface TabletCompartmentProps {
    from: moment.Moment,
    until: moment.Moment,
    fromAdjustedUnix: number,
    untilAdjustedUnix: number,
    timelineLength: number,
    timelineStartClipRatio: number,
    timelineEndClipRatio: number,
    showMarginal: boolean,
    stateChange: number,
    availableTablets: TabletConnection[],
    loadingTablets: boolean,
}

interface DiagramProps {
    from: moment.Moment,
    until: moment.Moment,
    fromAdjusted: moment.Moment,
    untilAdjusted: moment.Moment,
    data: DiagramData[],
    showMarginal: boolean,
    cursorMode: "normal" | "start" | "end",
    handlePick: (timestamp: number) => void,
    loading: boolean,
    step: "m" | "h/12" | "h/4" | "h/2" | "h" | "h*2" | "h*4" | "d" | "w",
    timelineStartClipRatio: number,
    timelineEndClipRatio: number,
    onWheel: React.WheelEventHandler<HTMLDivElement>,
    stateChange: number,
    tablets: TabletConnection[],
    baseStations: BaseStationConnection[],
    loadingTablets: boolean,
    loadingBaseStations: boolean,
}

/*
WARNING: the code that follows will make you cry; a safety pig is provided below for your benefit.

 _._ _..._ .-',     _.._(`))
'-. `     '  /-._.-'    ',/
   )         \            '.
  / _    _    |             \
 |  a    a    /              |
 \   .-.                     ;  
  '-('' ).-'       ,'       ;
     '-;           |      .'
        \           \    /
        | 7  .__  _.-\   \
        | |  |  ``/  /`  /
       /,_|  |   /,_/   /
          /,_/      '`-'
*/
const NumericDiagram = (props: NumericDiagramProps) => {
    const { displayToast, classes, from, until, dataKey, values, timelineLength, timelineStartClipRatio, timelineEndClipRatio, showMarginal } = props;

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    const offsets = values.map(v => {
        if (v.value < min)
            min = v.value;
        if (v.value > max)
            max = v.value;
        return Math.floor(((v.timestamp - from) / (until - from)) * timelineLength);
    });
    let residual = 0;
    const widths = values.map((_v, i) => {
        let width = (i === offsets.length - 1) ? (timelineLength * timelineEndClipRatio - offsets[i]) : (offsets[i+1] - offsets[i]);
        if (showMarginal) {
            offsets[i] += residual;
            if (width < MIN_LINE_LENGTH && residual < MAX_RESIDUAL_LINE_LENGTH) {
                residual += (MIN_LINE_LENGTH - width);
                width = MIN_LINE_LENGTH;
            } else if (width > MIN_LINE_LENGTH && residual > 0) {
                let newWidth = width - residual;
                if (newWidth < MIN_LINE_LENGTH)
                    newWidth = MIN_LINE_LENGTH;
                residual -= (width - newWidth);
                width = newWidth;
            }
        }
        if (timelineLength * timelineEndClipRatio < width + offsets[i])
            width = timelineLength * timelineEndClipRatio - offsets[i];
        if (timelineLength * timelineStartClipRatio > offsets[i]) {
            width -= timelineLength * timelineStartClipRatio - offsets[i];
            offsets[i] = timelineLength * timelineStartClipRatio;
        }
        return width;
    });
    for (let i = 0; i < offsets.length; i++) {
        if (widths[i] === 0 || offsets[i] >= timelineLength * timelineEndClipRatio) {
            offsets.splice(i,1);
            widths.splice(i,1);
            values.splice(i,1);
            i--;
        }
        if (offsets[i] < 0) {
            widths[i] += offsets[i];
            if (widths[i] <= 0) {
                offsets.splice(i,1);
                widths.splice(i,1);
                values.splice(i,1);
                i--;
            }
            offsets[i] = 0;
        }
    }

    const scale = new Array<JSX.Element>(0);
    const grid = new Array<JSX.Element>(0);
    let intScale = max-min >= 5;
    if (!intScale) {
        const step = (max-min) / 4;
        let isFraction = false;
        for (let i = 4; i >= 0; i--) {
            const label = (min+i*step).toFixed(2);
            if (label.substring(label.length-3, label.length) !== ".00")
                isFraction = true;
        }
        if (!isFraction)
            intScale = true;
    }
    if (intScale) {
        min = Math.floor(min);
        max = Math.ceil(max);
        while ((max-min)%4!==0)
            max++;
        const step = (max-min) / 4;
        for (let i = 4; i >= 0; i--) {
            scale.push(<tr key={i} style={{height: SCALE_HEIGHT/5-2}}><td>{(min+i*step).toFixed(0)}</td></tr>);
            grid.push(<div key={i} className={classes.gridlineHorizontal} style={{width: timelineLength, top: (SCALE_HEIGHT/5-2)*i}}></div>);
        }
    } else {
        const step = (max-min) / 4;
        for (let i = 4; i >= 0; i--) {
            scale.push(<tr key={i} style={{height: SCALE_HEIGHT/5-2}}><Tooltip title={(min+i*step)}><td>{(min+i*step).toFixed(2)}</td></Tooltip></tr>);
            grid.push(<div key={i} className={classes.gridlineHorizontal} style={{width: timelineLength, top: (SCALE_HEIGHT/5-2)*i}}></div>);
        }
    }

    const sections = values.map((v, i) => {
        return (
            <Tooltip
                key={i.toString()}
                style={{left: offsets[i], width: widths[i]}}
                title={<React.Fragment><span style={{whiteSpace: "pre-wrap"}}>{
                    v.value+"\n"+
                    dataKey+"\n"+
                    moment.unix(v.timestamp).utcOffset(timezone).format("YY/MM/DD HH:mm:ss")+
                    (v.duration === 0 ? "" : ("\n"+formatDuration(v.duration)))
                }</span></React.Fragment>}
                PopperProps={{
                    anchorEl: {
                        clientHeight:0,
                        clientWidth:0,
                        getBoundingClientRect: () => {
                            return (
                                document.getElementById(dataKey+" "+offsets[i])?.getClientRects()[0]
                            ??
                                {height:0, width:0, x:100, y:100, bottom:0, left:0, right:0, top:0} as any
                            );
                        }
                    }
                }}
            >
                <div onClick={() => {
                        navigator.clipboard.writeText(v.value.toString());
                        displayToast({
                            message: "value copied to clipboard",
                            severity: "info",
                            withCloseIcon: true,
                        });
                    }}
                >
                    <div
                        id={dataKey+" "+offsets[i]}
                        style={{width: widths[i], bottom: ((v.value-min)/(max-min))*(SCALE_HEIGHT*4/5-10)+(SCALE_HEIGHT/5)-2}}
                    />
                    <div
                        style={(i === offsets.length - 1) ? {} : 
                            {
                                left: v.value > values[i+1].value ? widths[i] - 1 : widths[i],
                                bottom: (max-min) === 0 ? 0 : ((Math.min(v.value, values[i+1].value)-min)/(max-min))*(SCALE_HEIGHT*4/5-10)+(SCALE_HEIGHT/5),
                                height: (Math.abs(values[i+1].value - v.value)/(max-min))*(SCALE_HEIGHT*4/5-10),
                            }
                        }
                    />
                </div>
            </Tooltip>
        );
    });
    
    return (
        <div className={classes.numericDiagram}>
            <div onClick={() => {
                navigator.clipboard.writeText(dataKey);
                displayToast({
                    message: "key copied to clipboard",
                    severity: "info",
                    withCloseIcon: true,
                });
            }}>
                <Tooltip title={dataKey}>
                    <div>{dataKey}</div>
                </Tooltip>
            </div>
            <div>
                <table><tbody>
                    {scale}
                </tbody></table>
            </div>
            <div style={{width: timelineLength}}>
                {sections}
            </div>
            <div>
                {grid}
            </div>
        </div>
    );
}

const TimelineDiagram = (props: TimelineDiagramProps) => {
    const { displayToast, classes, from, until, dataKey, values, timelineLength, timelineStartClipRatio, timelineEndClipRatio, showMarginal, notConnected = [] } = props;

    const valuesColors = {} as any;
    const offsets = values.map(v => {
        if (!Object.keys(valuesColors).includes(v.value))
            valuesColors[v.value] = "#FFFFFF";
        return Math.floor(((v.timestamp - from) / (until - from)) * timelineLength);
    });
    const colors = getBackgroundColors(Object.keys(valuesColors).length > MAX_COLORS ? MAX_COLORS : Object.keys(valuesColors).length);
    for (let i = 0; i < Object.keys(valuesColors).length; i++)
        valuesColors[Object.keys(valuesColors)[i]] = colors[i % MAX_COLORS];
    let residual = 0;
    const labels = values.map((v, i) => {
        let width = (i === offsets.length - 1) ? (timelineLength * timelineEndClipRatio - offsets[i]) : (offsets[i+1] - offsets[i]);
        if (showMarginal) {
            offsets[i] += residual;
            if (width < MIN_LABEL_WIDTH && ((residual < PREFERRED_MAX_RESIDUAL_LABEL_WIDTH && width === 0) || (residual < STRICT_MAX_RESIDUAL_LABEL_WIDTH && width > 0))) {
                residual += (MIN_LABEL_WIDTH - width);
                width = MIN_LABEL_WIDTH;
            } else if (width > MIN_LABEL_WIDTH && residual > 0) {
                let newWidth = width - residual;
                if (newWidth < MIN_LABEL_WIDTH)
                    newWidth = MIN_LABEL_WIDTH;
                residual -= (width - newWidth);
                width = newWidth;
            }
        }
        if (width === 0 || offsets[i] >= timelineLength * timelineEndClipRatio)
            return (undefined);
        if (offsets[i] < 0) {
            width += offsets[i];
            if (width <= 0)
                return (undefined);
            offsets[i] = 0;
        }
        if (timelineLength * timelineEndClipRatio < width + offsets[i])
            width = timelineLength * timelineEndClipRatio - offsets[i];
        if (timelineLength * timelineStartClipRatio > offsets[i]) {
            width -= timelineLength * timelineStartClipRatio - offsets[i];
            offsets[i] = timelineLength * timelineStartClipRatio;
        }
        return (
            <Tooltip
                key={i.toString()}
                style={{
                    left: offsets[i],
                    width: width,
                    backgroundColor: (v.value !== undefined && v.value !== "") ? valuesColors[v.value] : "#BBBBBB",
                }}
                title={<React.Fragment><span style={{whiteSpace: "pre-wrap"}}>{
                    (v.value===""?"empty string":v.value)+"\n"+
                    dataKey+"\n"+
                    moment.unix(v.timestamp).utcOffset(timezone).format("YY/MM/DD HH:mm:ss")+
                    (v.duration === 0 ? "" : ("\n"+formatDuration(v.duration)))
                }</span></React.Fragment>}
            >
                <div onClick={() => {
                    if (dataKey === "Location Link") {
                        const datumYX = v.value?.split(" ");
                        const datumY = datumYX?.[0];
                        const datumX = datumYX?.[1];
                        if (datumY && datumX) {
                            window.open("https://www.google.com/maps/search/?api=1&query="+datumY+","+datumX);
                            return;
                        }
                    }
                    navigator.clipboard.writeText(v.value);
                    displayToast({
                        message: "value copied to clipboard",
                        severity: "info",
                        withCloseIcon: true,
                    });
                }}>{v.value}{(v.value === "" || width > MIN_LABEL_WIDTH) && <>&nbsp;</>}</div>
            </Tooltip>
        );
    });

    const notCon = notConnected.map((v, i) => {
        let width = ((v.end - v.start) / (until - from)) * timelineLength;
        let offset = ((v.start - from) / (until - from)) * timelineLength;
        if (timelineLength * timelineEndClipRatio < width + offset)
            width = timelineLength * timelineEndClipRatio - offset;
        if (timelineLength * timelineStartClipRatio > offset) {
            width -= timelineLength * timelineStartClipRatio - offset;
            offset = timelineLength * timelineStartClipRatio;
        }
        if (offset < 0) {
            width += offset;
            offset = 0;
        }
        if (width < 0)
            return undefined;
        return (
            <Tooltip
                key={i.toString()}
                style={{
                    left: offset,
                    width: width,
                    backgroundColor: "#BBBBBB",
                }}
                title={<React.Fragment><span style={{whiteSpace: "pre-wrap"}}>{
                    "the tablet is not connected to the robot in this timespan\n"+
                    moment.unix(v.start).utcOffset(timezone).format("YY/MM/DD HH:mm:ss")+"\n"+
                    moment.unix(v.end).utcOffset(timezone).format("YY/MM/DD HH:mm:ss")+"\n"+
                    formatDuration(Math.round(v.end-v.start))
                }</span></React.Fragment>}
            >
                <div>{"NOT_CONNECTED"}</div>
            </Tooltip>
        );
    });
    
    return (
        <div className={classes.timelineDiagram}>
            <div onClick={() => {
                navigator.clipboard.writeText(dataKey);
                displayToast({
                    message: "key copied to clipboard",
                    severity: "info",
                    withCloseIcon: true,
                });
            }}>
                <Tooltip title={dataKey ?? ""}>
                    <div>{dataKey}</div>
                </Tooltip>
            </div>
            <div style={{width: timelineLength}}>
                {labels}
                {notCon}
            </div>
        </div>
    );
}

const TabletCompartment = (props: TabletCompartmentProps) => {
    const classes = useStyles();

    const { from, until, fromAdjustedUnix, untilAdjustedUnix, timelineLength, timelineStartClipRatio, timelineEndClipRatio, showMarginal, stateChange } = props;

    const { deviceTypeId, deviceId } = useSection();
    const tablets = useSelector(tabletsSelector);
    const customers = useSelector(customersSelector);
    const secondarySection = useDashboard().state.secondary;
    const translator = useTranslator();

    const [includeTablets, setIncludeTablets] = useLocalStorage("historic-diagram-include-tablets-"+(deviceTypeId===1?deviceId:"N/A"), [] as Array<number>);
    const [keyFilter, setKeyFilter] = useLocalStorage("historic-diagram-keyfilter-"+(deviceTypeId===1?"[1,2]":"N/A"), [] as Array<string>);
    const [maskNotConnected, setMaskNotConnected] = useState(true);

    const importantKeysCached = useMemo(() => {
        if (deviceTypeId !== 1)
            return [];
        const keys = new Array<string>(0);
        for (const deviceKey of Object.keys(secondarySection?.devices ?? {})) {
            const device = secondarySection?.devices[Number(deviceKey)]!;
            for (const key of device?.keys)
                if (!NOT_IMPORTANT_KEYS.includes(key) && !keys.includes(key))
                    keys.push(key);
        }
        return keys;
    }, [deviceTypeId, secondarySection]);

    const fetchKeys_ = useCallback(async () => {
        const m = await Promise.all((includeTablets ?? []).map(t => {
            return DeviceDataService.getLatestData(2, {
                deviceId: t,
                includeDictionary: false,
            });
        }));
        const keys = new Array<string>(0);
        for (const d of m) {
            for (const e of d.data)
                if (!NOT_IMPORTANT_KEYS.includes(e.key) && !keys.includes(e.key))
                    keys.push(e.key);
        }
        return keys;
    }, [includeTablets]);
    const { value: importantKeys, pending: loadingKeys } = useAsync(fetchKeys_, {
        immediate: deviceTypeId === 1,
    });

    const keyFilterVisual = useMemo(() => {
        if (keyFilter === undefined)
            return [];
        return keyFilter.map((k) => {
            return (
                <div
                    key={k}
                    onClick={() => {setKeyFilter(keyFilter.filter((k_) => {return k_ !== k;}));}}
                >
                    {translator.translateKey(k)+" ✕"}&nbsp;
                </div>
            );
        });
    }, [keyFilter, setKeyFilter, translator]);

    const includeTabletsVisual = useMemo(() => {
        if (includeTablets === undefined)
            return [];
        return includeTablets.map((tabletId) => {
            const t = tablets.find(s => s.id === tabletId);
            const customer = customers.dto[t?.customerId ?? -1];
            let label = tabletId+" ";
            if (customer !== undefined)
                label += " ["+((customer.name.length>19)?customer.name.substring(0,16)+"...":customer.name)+"]";
            else if (t !== undefined)
                label += " ["+t.customerId+"]";
            return (
                <div
                    key={tabletId}
                    onClick={() => {setIncludeTablets(includeTablets.filter((t_) => {return t_ !== tabletId;}));}}
                >
                    {label+" ✕"}&nbsp;
                </div>
            );
        });
    }, [includeTablets, setIncludeTablets, customers.dto, tablets]);

    const fetchData_ = useCallback(async (from_: moment.Moment | undefined, until_: moment.Moment | undefined) => {
        let tmpFilter = keyFilter;
        if (keyFilter !== undefined && keyFilter.length !== 0 && !keyFilter.includes("robot"))
            tmpFilter = [...keyFilter, "robot"];
        const m = await Promise.all((includeTablets ?? []).map(t => {
            return DeviceDataService.getData(2, t, {
                skip: 0,
                limit: 99999999,
                orderBy: [["created", "desc"]],
                notBefore: from_?.toDate() ?? from.toDate(),
                notAfter: until_?.toDate() ?? until.toDate(),
                includeKeys: tmpFilter,
            }, true).then(o => {return {...o, deviceId: t};})
        }));
        const diagramDatas = {} as {[key: number]: DiagramData[]};
        const notConnecteds = {} as {[key: number]: {start: number, end: number}[]};
        for (const d of m) {
            const connectionHistory = new Array<DeviceData>(0);
            const diagramData = new Array<DiagramData>(0);
            d.data.reverse();
            for (const entry of d.data) {
                if (keyFilter !== undefined && keyFilter.length !== 0 && !keyFilter.includes(entry.key))
                    continue;
                if (entry.key === "robot")
                    connectionHistory.push(entry);
                const group = diagramData.find(dd => dd.key === entry.key);
                if (group === undefined)
                    diagramData.push({key: entry.key, translatedKey: translator.translateKey(entry.key), values: [{value: translator.translate(entry.key, entry.value), timestamp: Math.round(entry.created)}]});
                else
                    group.values.push({value: translator.translate(entry.key, entry.value), timestamp: Math.round(entry.created)});
            }
            diagramData.reverse();
            for (const group of diagramData)
                for (let i = 0; i < group.values.length; i++)
                    if (i === group.values.length-1)
                        group.values[i].duration = Math.floor((until_ ?? until).unix()- group.values[i].timestamp);
                    else
                        group.values[i].duration = Math.floor(group.values[i+1].timestamp - group.values[i].timestamp);
            diagramDatas[d.deviceId] = diagramData;
            const notConnected = new Array<{start: number, end: number}>(0);
            if (connectionHistory.length === 0) {
                const t = tablets.find(s => s.id.toString() === d.deviceId.toString());
                if (t !== undefined && t.connectedRobotId !== undefined && t.connectedRobotId !== null)
                    connectionHistory.push({
                        deviceTypeId: 2,
                        deviceId: d.deviceId,
                        key: "robot",
                        value: t.connectedRobotId.toString(),
                        created: 0,
                        inserted: 0,
                    });
            }
            for (let i = 0; i < connectionHistory.length; i++)
                if (connectionHistory[i].value !== deviceId.toString()) {
                    const start = connectionHistory[i].created;
                    let end = (i===connectionHistory.length-1)?((until_ ?? until).unix()):(connectionHistory[i+1].created);
                    for (let j = i+1; j < connectionHistory.length; j++) {
                        if (connectionHistory[j].value === deviceId.toString()) {
                            end = connectionHistory[j].created;
                            i = j;
                            break;
                        }
                        if (j === connectionHistory.length-1) {
                            i = connectionHistory.length-1;
                            end = (until_ ?? until).unix();
                        }
                    }
                    notConnected.push({start, end});
                }
            notConnecteds[d.deviceId] = notConnected;
        }
        return {data: diagramDatas, notConnected: notConnecteds};
        }, [from, until, translator, keyFilter, includeTablets /*,deviceId*/, tablets] //eslint-disable-line react-hooks/exhaustive-deps
    );
    const { value: data, pending: loading, exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
    });
    useEffect(() => {
        fetchData(undefined, undefined);
    }, [keyFilter, includeTablets, stateChange, from, until]); //eslint-disable-line react-hooks/exhaustive-deps

    const { displayToast } = useToast();

    const diagrams = React.useMemo<JSX.Element[]>(() => {
        let ret = new Array<JSX.Element>(0);
        if (data !== undefined)
            for (const id of includeTablets ?? []) {
                const t = tablets.find(s => s.id.toString() === id.toString());
                const o = props.availableTablets?.find(s => s.id.toString() === id.toString());
                const customer = customers.dto[t?.customerId ?? -1];
                let label = "Tablet "+id+" ";
                if (t?.connectedRobotId?.toString() === deviceId.toString())
                    label += " (currently connected)";
                if (customer !== undefined)
                    label += " ["+customer.name+"]";
                else if (t !== undefined)
                    label += " ["+t.customerId+"]";
                if (o !== undefined)
                    label += " "+moment.unix(o.timestamp).utcOffset(timezone).format(TimeFormats.PresentationShort2);
                ret.push(<div key={id} style={{display: "flex", alignItems: "center", flexDirection: "row", marginTop: "10px", marginBottom: "10px", width: timelineLength+KEY_WIDTH}}>
                    <Typography variant="h6">{label}</Typography><div style={{marginLeft: "10px", flexGrow: 1, borderTop: "solid 2px #888888"}}/>
                </div>);
                ret = ret.concat((data!.data[Number(id)] ?? []).map((group) => {
                    return (<TimelineDiagram
                        key={id+"-"+group.key}
                        from={fromAdjustedUnix}
                        until={untilAdjustedUnix}
                        dataKey={group.translatedKey}
                        values={group.values.map(v => {return {value: (v.value as string).trim(), timestamp: v.timestamp, duration: v.duration ?? 0};})}
                        timelineLength={timelineLength}
                        timelineStartClipRatio={timelineStartClipRatio}
                        timelineEndClipRatio={timelineEndClipRatio}
                        classes={classes}
                        displayToast={displayToast}
                        showMarginal={showMarginal}
                        notConnected={maskNotConnected ? data.notConnected[Number(id)] : []}
                    />);
                }));
        }
        return ret;
    }, [includeTablets, props.availableTablets, customers.dto, deviceId, tablets, data, fromAdjustedUnix, untilAdjustedUnix, timelineLength, showMarginal, timelineStartClipRatio, timelineEndClipRatio, displayToast, classes, maskNotConnected]);

    if (deviceTypeId !== 1)
        return (<></>);
    return (<div>
        <div className={classes.tabletsControl} style={{width: timelineLength+KEY_WIDTH}}>
            <div>
                <AutocompleteV2
                    loading={props.loadingTablets}
                    clearOnSelectAndBlur
                    style={{minWidth: "200px"}}
                    options={props.availableTablets ?? []}
                    getOptionLabel={(o) => {
                        const t = tablets.find(s => s.id === o.id);
                        const customer = customers.dto[t?.customerId ?? -1];
                        let ret = o.id+" ";
                        if (t?.connectedRobotId?.toString() === deviceId.toString())
                            ret += " (still connected)";
                        if (customer !== undefined)
                            ret += " ["+customer.name+"]";
                        else if (t !== undefined)
                            ret += " ["+t.customerId+"]";
                        ret += " "+moment.unix(o.timestamp).utcOffset(timezone).format(TimeFormats.PresentationShort2);
                        return ret;
                    }}
                    onChange={(_e, val) => {
                        if (val !== null && val !== undefined && includeTablets !== undefined)
                            if (!includeTablets.includes(val.id))
                                setIncludeTablets([...includeTablets, val.id]);
                    }}
                    renderInput={params =>
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            label="Add tablet"
                        />
                    }
                />
                <Tooltip title="Clear tablets.">
                    <IconButton onClick={() => {if (includeTablets !== undefined && includeTablets.length !== 0) setIncludeTablets([]);}}>
                        <ClearIcon/>
                    </IconButton>
                </Tooltip>
                <div className={classes.keyFilter} style={{minWidth: "150px", flexGrow: 0.5}}>
                    {includeTabletsVisual}
                </div>
                <AutocompleteV2
                    loading={loadingKeys}
                    clearOnSelectAndBlur
                    style={{minWidth: "200px", marginLeft: "20px"}}
                    options={(importantKeys?.length === 0 ? importantKeysCached : importantKeys) ?? importantKeysCached}
                    getOptionLabel={translator.translateKey}
                    onChange={(_e, val) => {
                        if (val !== null && val !== undefined && keyFilter !== undefined)
                            if (!keyFilter.includes(val))
                                setKeyFilter([...keyFilter, val]);
                    }}
                    renderInput={params =>
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            label="Add filter key"
                        />
                    }
                />
                <Tooltip title="Clear filters. (removing all filters will only show keys which has at least 1 entry in the given timeframe)">
                    <IconButton onClick={() => {if (keyFilter !== undefined && keyFilter.length !== 0) setKeyFilter([]);}}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                <div className={classes.keyFilter} style={{minWidth: "150px", flexGrow: 1}}>
                    {keyFilterVisual}
                </div>
            </div>
            <div>
                <Tooltip title="Can disable the NOT_CONNECTED overlays, to see underlying entries.">
                    <FormControlLabel
                        style={{
                            margin:"0px",
                            marginRight: "5px",
                        }}
                        control={
                            <Switch
                                checked={maskNotConnected}
                                size="small"
                                onChange={(e) => {setMaskNotConnected(e.target.checked);}}
                            />
                        }
                        label="Mask NOT_CONNECTED"
                    />
                </Tooltip>
            </div>
        </div>
        {diagrams}
        {loading && <div style={{width: timelineLength+KEY_WIDTH, minHeight: "25vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress size={70}/></div>}
    </div>);
}
const BaseStationCompartment = (props:any)=>{   /// to change 
    const classes = useStyles();
    const {timelineLength,from,until,stateChange,fromAdjustedUnix,untilAdjustedUnix,showMarginal,timelineEndClipRatio,timelineStartClipRatio} = props

    const { deviceTypeId, deviceId } = useSection();
    const basestations=useSelector(basestationsSelector)
    const customers=useSelector(customersSelector)
    const translator = useTranslator();
    const { displayToast } = useToast();

    const [includeBaseStations, setIncludeBaseStations] = useLocalStorage("historic-diagram-include-basestations-"+(deviceTypeId===1?deviceId:"N/A"), [] as Array<number>);
    const [keyFilter, setKeyFilter] = useLocalStorage("historic-diagram-keyfilter-"+(deviceTypeId===1?"[1,2,3]":"N/A"), [] as Array<string>);
    const [maskNotConnected, setMaskNotConnected] = useState(true);

    const fetchKeys_ = useCallback(async () => {
        const m = await Promise.all((includeBaseStations ?? []).map(t => {
            return DeviceDataService.getLatestData(3, {
                deviceId: t,
                includeDictionary: false,
            });
        }));
        const keys = new Array<string>(0);
        for (const d of m) {
            for (const e of d.data)
                if (!NOT_IMPORTANT_KEYS.includes(e.key) && !keys.includes(e.key))
                    keys.push(e.key);
        }
        return keys;
    }, [includeBaseStations]);
    const { value: importantKeys, pending: loadingKeys } = useAsync(fetchKeys_, {
        immediate: deviceTypeId === 1,
    });

    
    const keyFilterVisual = useMemo(() => {
        if (keyFilter === undefined)
            return [];
        return keyFilter.map((k) => {
            return (
                <div
                    key={k}
                    onClick={() => {setKeyFilter(keyFilter.filter((k_) => {return k_ !== k;}));}}
                >
                    {translator.translateKey(k)+" ✕"}&nbsp;
                </div>
            );
        });
    }, [keyFilter, setKeyFilter, translator]);

    const includeBaseStationsVisual = useMemo(() => {
        if (includeBaseStations === undefined)
            return [];
        return includeBaseStations.map((basestationId) => {
            const t = basestations.find(s => s.id === basestationId);
            const customer = customers.dto[t?.customerId ?? -1];
            let label = basestationId+" ";
            if (customer !== undefined)
                label += " ["+((customer.name.length>19)?customer.name.substring(0,16)+"...":customer.name)+"]";
            else if (t !== undefined)
                label += " ["+t.customerId+"]";
            return (
                <div
                    key={basestationId}
                    onClick={() => {setIncludeBaseStations(includeBaseStations.filter((t_) => {return t_ !== basestationId;}));}}
                >
                    {label+" ✕"}&nbsp;
                </div>
            );
        });
    }, [includeBaseStations, setIncludeBaseStations, customers.dto, basestations]);

    const fetchData_ = useCallback(async (from_: moment.Moment | undefined, until_: moment.Moment | undefined) => {
        const tmpFilter = keyFilter;
        const m = await Promise.all((includeBaseStations ?? []).map(t => {
            return DeviceDataService.getData(3, t, {
                skip: 0,
                limit: 99999999,
                orderBy: [["created", "desc"]],
                notBefore: from_?.toDate() ?? from.toDate(),
                notAfter: until_?.toDate() ?? until.toDate(),
                includeKeys: tmpFilter,
            }, true).then(o => {return {...o, deviceId: t};})
        }));
        const diagramDatas = {} as {[key: number]: DiagramData[]};
        const notConnecteds = {} as {[key: number]: {start: number, end: number}[]};
        for (const d of m) {
            const diagramData = new Array<DiagramData>(0);
            d.data.reverse();
            for (const entry of d.data) {
                if (keyFilter !== undefined && keyFilter.length !== 0 && !keyFilter.includes(entry.key))
                    continue;
                const group = diagramData.find(dd => dd.key === entry.key);
                if (group === undefined)
                    diagramData.push({key: entry.key, translatedKey: translator.translateKey(entry.key), values: [{value: translator.translate(entry.key, entry.value), timestamp: Math.round(entry.created)}]});
                else
                    group.values.push({value: translator.translate(entry.key, entry.value), timestamp: Math.round(entry.created)});
            }
            diagramData.reverse();
            for (const group of diagramData)
                for (let i = 0; i < group.values.length; i++)
                    if (i === group.values.length-1)
                        group.values[i].duration = Math.floor((until_ ?? until).unix()- group.values[i].timestamp);
                    else
                        group.values[i].duration = Math.floor(group.values[i+1].timestamp - group.values[i].timestamp);
            diagramDatas[d.deviceId] = diagramData;
            const notConnected = new Array<{start: number, end: number}>(0);
           
            notConnecteds[d.deviceId] = notConnected;
        }
        return {data: diagramDatas, notConnected: notConnecteds};
        }, [from, until, translator, keyFilter, includeBaseStations]
    );
    const { value: data, pending: loading, exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
    });
    useEffect(() => {
        fetchData(undefined, undefined);
    }, [keyFilter, includeBaseStations, stateChange, from, until]); //eslint-disable-line react-hooks/exhaustive-deps


    const diagrams = React.useMemo<JSX.Element[]>(() => {
        let ret = new Array<JSX.Element>(0);
        if (data !== undefined)
            for (const id of includeBaseStations ?? []) {
                const t = basestations.find(s => s.id.toString() === id.toString());
                const o = (props.availableBaseStations as BaseStationConnection[])?.find(s  => s.id.toString() === id.toString());
                const customer = customers.dto[t?.customerId ?? -1];
                let label = "Tablet "+id+" ";
                
                if (customer !== undefined)
                    label += " ["+customer.name+"]";
                else if (t !== undefined)
                    label += " ["+t.customerId+"]";
                if (o !== undefined)
                    label += " "+moment.unix(o.timestamp).utcOffset(timezone).format(TimeFormats.PresentationShort2);
                ret.push(<div key={id} style={{display: "flex", alignItems: "center", flexDirection: "row", marginTop: "10px", marginBottom: "10px", width: timelineLength+KEY_WIDTH}}>
                    <Typography variant="h6">{label}</Typography><div style={{marginLeft: "10px", flexGrow: 1, borderTop: "solid 2px #888888"}}/>
                </div>);
                ret = ret.concat((data!.data[Number(id)] ?? []).map((group) => {
                    return (<TimelineDiagram
                        key={id+"-"+group.key}
                        from={fromAdjustedUnix}
                        until={untilAdjustedUnix}
                        dataKey={group.translatedKey}
                        values={group.values.map(v => {return {value: (v.value as string).trim(), timestamp: v.timestamp, duration: v.duration ?? 0};})}
                        timelineLength={timelineLength}
                        timelineStartClipRatio={timelineStartClipRatio}
                        timelineEndClipRatio={timelineEndClipRatio}
                        classes={classes}
                        displayToast={displayToast}
                        showMarginal={showMarginal}
                        notConnected={maskNotConnected ? data.notConnected[Number(id)] : []}
                    />);
                }));
        }
        return ret;
    }, [includeBaseStations, props.availableBaseStations, customers.dto,  basestations, data, fromAdjustedUnix, untilAdjustedUnix, timelineLength, showMarginal, timelineStartClipRatio, timelineEndClipRatio, displayToast, classes, maskNotConnected]);

    if (deviceTypeId !== 1)
    return (<></>);
    return (
        <div>
        <div className={classes.tabletsControl} style={{width: timelineLength+KEY_WIDTH}}>
            <div>
                <AutocompleteV2
                    loading={props.loadingBaseStations}
                    clearOnSelectAndBlur
                    style={{minWidth: "200px"}}
                    options={props.availableBaseStations ?? []}
                    getOptionLabel={(o : BaseStationConnection) => {
                        const t = basestations.find(s => s.id === o.id);
                        const customer = customers.dto[t?.customerId ?? -1];
                        let ret = o.id+" ";
                        if (customer !== undefined)
                            ret += " ["+customer.name+"]";
                        else if (t !== undefined)
                            ret += " ["+t.customerId+"]";

                        ret += " "+moment.unix(o.timestamp).utcOffset(timezone).format(TimeFormats.PresentationShort2);
                        return ret;
                    }}

                    onChange={(_e, val) => {
                        if (val !== null && val !== undefined && includeBaseStations !== undefined)
                            if (!includeBaseStations.includes(val.id))
                                setIncludeBaseStations([...includeBaseStations, val.id]);
                    }}
                    renderInput={params =>
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            label="Add reference station"
                        />
                    }
                />
                <Tooltip title="Clear reference station.">
                    <IconButton onClick={() => {if (includeBaseStations !== undefined && includeBaseStations.length !== 0) setIncludeBaseStations([]);}}>
                        <ClearIcon/>
                    </IconButton>
                </Tooltip>
                <div className={classes.keyFilter} style={{minWidth: "150px", flexGrow: 0.5}}>
                    {includeBaseStationsVisual}
                </div>
                <AutocompleteV2
                    loading={loadingKeys}
                    clearOnSelectAndBlur
                    style={{minWidth: "200px", marginLeft: "20px"}}
                    options={importantKeys ?? []}
                    getOptionLabel={translator.translateKey}
                    onChange={(_e, val) => {
                        if (val !== null && val !== undefined && keyFilter !== undefined)
                            if (!keyFilter.includes(val))
                                setKeyFilter([...keyFilter, val]);
                    }}
                    renderInput={params =>
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            label="Add filter key"
                        />
                    }
                />
                <Tooltip title="Clear filters. (removing all filters will only show keys which has at least 1 entry in the given timeframe)">
                    <IconButton onClick={() => {if (keyFilter !== undefined && keyFilter.length !== 0) setKeyFilter([]);}}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                <div className={classes.keyFilter} style={{minWidth: "150px", flexGrow: 1}}>
                    {keyFilterVisual}
                </div>
            </div>
            <div>
                <Tooltip title="Can disable the NOT_CONNECTED overlays, to see underlying entries.">
                    <FormControlLabel
                        style={{
                            margin:"0px",
                            marginRight: "5px",
                        }}
                        control={
                            <Switch
                                checked={maskNotConnected}
                                size="small"
                                onChange={(e) => {setMaskNotConnected(e.target.checked);}}
                            />
                        }
                        label="Mask NOT_CONNECTED"
                    />
                </Tooltip>
            
            </div>

        </div>
        {diagrams}
        {loading && <div style={{width: timelineLength+KEY_WIDTH, minHeight: "25vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress size={70}/></div>}
        </div>        
    )
}
const DiagramBase = React.memo((props: DiagramProps) => {
    const classes = useStyles();

    const { from, until, fromAdjusted, untilAdjusted, data, showMarginal, cursorMode, handlePick, loading, step, timelineStartClipRatio, timelineEndClipRatio, onWheel, stateChange } = props;
    
    const timelineTable = new Array<JSX.Element>(0);
    const grid = new Array<JSX.Element>(0);
    let cnt = 0;
    for (let i = fromAdjusted.clone(); i.unix() <= untilAdjusted.unix(); i.add(AMOUNTS[step], "minute"))
        cnt++;
    let index = 0;
    for (let i = fromAdjusted.clone(); i.unix() <= untilAdjusted.unix(); i.add(AMOUNTS[step], "minute")) {
        let timeformat = "HH:mm";
        if (["d","w"].includes(step))
            timeformat = "YY/MM/DD";
        else if (["h*2","h*4"].includes(step))
            timeformat = "YY/MM/DD HH:mm";
        timelineTable.push(
            <td key={i.unix()} style={{width: 100/cnt+"%"}}>
                {i.utcOffset(timezone).format(timeformat)}
                {step === "w" ? "\n(week" : ""}
                &nbsp;
                {step === "w" ? i.week()+")" : ""}
            </td>
        );
        grid.push(<div key={index*2} className={classes.gridlineVertical} style={{left: 100/cnt*index+"%"}}></div>);
        grid.push(<div key={index*2+1} className={classes.gridlineVertical} style={{left: 50/cnt*(index*2+1)+"%"}}></div>);
        index++;
    }

    const [timelineLength, setTimelineLength] = useState(0);
    const timelineRef = React.useRef<HTMLTableElement | null>(null);
    useResizeObserver({
        ref: timelineRef,
        callback: () => {
            setTimelineLength(timelineRef.current?.offsetWidth ?? 0);
        },
    });

    const { displayToast } = useToast();

    const fromAdjustedUnix = fromAdjusted.unix();
    const untilAdjustedUnix = untilAdjusted.unix() + AMOUNTS[step]*60;

    const diagrams = React.useMemo<JSX.Element[]>(() => {
        return data.map((group) => {
            let isNumeric = NUMERIC_KEYS.includes(group.key);
            if (isNumeric)
                for (const entry of group.values)
                    if (!isNumber((entry.value as string).trim())) {
                        isNumeric = false;
                        break;
                    }
            if (isNumeric && group.values.length > 1)
                return (<NumericDiagram
                    key={group.key}
                    from={fromAdjustedUnix}
                    until={untilAdjustedUnix}
                    dataKey={group.translatedKey}
                    values={group.values.map(v => {return {value: Number((v.value as string).trim()), timestamp: v.timestamp, duration: v.duration ?? 0};})}
                    timelineLength={timelineLength}
                    timelineStartClipRatio={timelineStartClipRatio}
                    timelineEndClipRatio={timelineEndClipRatio}
                    classes={classes}
                    displayToast={displayToast}
                    showMarginal={showMarginal}
                />);
            return (<TimelineDiagram
                key={group.key}
                from={fromAdjustedUnix}
                until={untilAdjustedUnix}
                dataKey={group.translatedKey}
                values={group.values.map(v => {return {value: (v.value as string).trim(), timestamp: v.timestamp, duration: v.duration ?? 0};})}
                timelineLength={timelineLength}
                timelineStartClipRatio={timelineStartClipRatio}
                timelineEndClipRatio={timelineEndClipRatio}
                classes={classes}
                displayToast={displayToast}
                showMarginal={showMarginal}
            />);
        });
    }, [data, fromAdjustedUnix, untilAdjustedUnix, timelineLength, showMarginal, timelineStartClipRatio, timelineEndClipRatio, displayToast, classes]);

    let cursor;
    if (cursorMode==="start")
        cursor = ARROW_RIGHT_CURSOR;
    else if (cursorMode==="end")
        cursor = ARROW_LEFT_CURSOR;

    return (
        <div className={classes.diagramBase} onWheel={onWheel}>
            <div className={classes.diagramArea} id="diagram-area">
                {diagrams}
                {loading && <div style={{width: timelineLength+KEY_WIDTH, minHeight: "25vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress size={70}/></div>}
                {!loading && <TabletCompartment
                    from={from}
                    until={until}
                    fromAdjustedUnix={fromAdjustedUnix}
                    untilAdjustedUnix={untilAdjustedUnix}
                    timelineLength={timelineLength}
                    timelineStartClipRatio={timelineStartClipRatio}
                    timelineEndClipRatio={timelineEndClipRatio}
                    showMarginal={showMarginal}
                    stateChange={stateChange}
                    loadingTablets={props.loadingTablets}
                    availableTablets={props.tablets}
                />}
                {/* /base station to be added */}
                {!loading && <BaseStationCompartment
                    from={from}
                    until={until}
                    fromAdjustedUnix={fromAdjustedUnix}
                    untilAdjustedUnix={untilAdjustedUnix}
                    timelineLength={timelineLength}
                    timelineStartClipRatio={timelineStartClipRatio}
                    timelineEndClipRatio={timelineEndClipRatio}
                    showMarginal={showMarginal}
                    stateChange={stateChange}
                    loadingBaseStations={props.loadingBaseStations}
                    availableBaseStations={props.baseStations}
                />}
                
            </div>
            <div className={classes.timeline}>
                <table ref={timelineRef}><tbody>
                    <tr>
                        {timelineTable}
                    </tr>
                </tbody></table>
            </div>
            <div>
                <div
                    className={classes.verticalGrid}
                    style={{
                        width: timelineLength,
                        cursor,
                        zIndex: cursorMode!=="normal" ? 5 : undefined,
                    }}
                    onClick={(e) => {
                        if (cursorMode !== "normal") {
                            let px = e.clientX - document.getElementById("diagram-area")!.getBoundingClientRect().x - KEY_WIDTH;
                            if (cursorMode === "end")
                                px += 16;
                            handlePick(fromAdjusted.unix()+(untilAdjusted.unix()-fromAdjusted.unix())*px/timelineLength);
                        }
                    }}
                    onMouseMove={(e) => {
                        if (cursorMode !== "normal") {
                            const line = document.getElementById("picker-line");
                            if (line !== null) {
                                let px = e.clientX - document.getElementById("diagram-area")!.getBoundingClientRect().x - KEY_WIDTH;
                                if (cursorMode === "end")
                                    px += 14;
                                line.setAttribute("style", "left: "+px+"px;border-width:2px");
                            }
                        }
                    }}
                    onMouseLeave={() => {
                        if (cursorMode !== "normal") {
                            const line = document.getElementById("picker-line");
                            if (line !== null)
                                line.setAttribute("style", "display: hidden");
                        }
                    }}
                >
                    {(cursorMode !== "normal") &&
                        <div key="picker-line" id="picker-line" className={classes.gridlineVertical}></div>
                    }
                    {!loading && grid}
                </div>
            </div>
        </div>
    );
});

const HistoricDiagram = () => {
    const classes = useStyles();

    const { deviceTypeId, deviceId, keys } = useSection();
    const importantKeys = useMemo(() => {
        return keys.filter((k) => !NOT_IMPORTANT_KEYS.includes(k));
    }, [keys]);
    const translator = useTranslator();
    const tablets = useSelector(tabletsSelector);

    const [cursorMode, setCursorMode] = useState("normal" as "normal" | "start" | "end");
    const [showMarginal, setShowMarginal] = useState(false);
    const [from, setFrom] = useState(moment.unix(0));
    const [until, setUntil] = useState(moment.unix(0));
    const [localZoom, setLocalZoom] = useState([0,100]);
    const [localZoomVisual, setLocalZoomVisual] = useState(localZoom);
    const [keyFilter, setKeyFilter] = useLocalStorage("historic-diagram-keyfilter-"+deviceTypeId, [] as Array<string>);
    let step = "w" as "m" | "h/12" | "h/4" | "h/2" | "h" | "h*2" | "h*4" | "d" | "w";
    const [stateChange, setStateChange] = useState(0);
    const changeState = () => {
        if (stateChange > 0)
            setStateChange(stateChange-1);
        else
            setStateChange(stateChange+1);
    }

    const fetchData_ = useCallback(async (from_: moment.Moment | undefined, until_: moment.Moment | undefined) => {
        const d = await DeviceDataService.getData(deviceTypeId, deviceId, {
            skip: 0,
            limit: 99999999,
            orderBy: [["created", "desc"]],
            notBefore: from_?.toDate() ?? from.toDate(),
            notAfter: until_?.toDate() ?? until.toDate(),
            includeKeys: keyFilter,
        }, true);
        d.data.reverse();
        const diagramData = new Array<DiagramData>(0);
        for (const entry of d.data) {
            const group = diagramData.find(dd => dd.key === entry.key);
            if (group === undefined)
                diagramData.push({key: entry.key, translatedKey: translator.translateKey(entry.key), values: [{value: translator.translate(entry.key, entry.value), timestamp: Math.round(entry.created)}]});
            else
                group.values.push({value: translator.translate(entry.key, entry.value), timestamp: Math.round(entry.created)});
        }
        diagramData.reverse();
        for (const group of diagramData)
            for (let i = 0; i < group.values.length; i++)
                if (i === group.values.length-1)
                    group.values[i].duration = Math.floor((until_ ?? until).unix()- group.values[i].timestamp);
                else
                    group.values[i].duration = Math.floor(group.values[i+1].timestamp - group.values[i].timestamp);
        return diagramData;
    }, [/*deviceTypeId, deviceId,*/ from, until, translator, keyFilter]); // eslint-disable-line react-hooks/exhaustive-deps
    const { value: data, pending: loading, exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
    });
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        if (!firstRender)
            fetchData(undefined, undefined);
        else
            setFirstRender(false);
    }, [keyFilter]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchTablets_ = useCallback(async () => {
        const d = await DeviceDataService.getTabletConnections(deviceId, {
            skip: 0,
            limit: 99999999,
            orderBy: [["timestamp", "desc"]],
        });
        const ret = new Array<TabletConnection>(0);
        for (const t of d.data) {
            let contains = false;
            for (const c of ret)
                if (c.id === t.id)
                    contains = true;
            if (!contains)
                if (tablets.find(s => s.id === t.id)?.connectedRobotId?.toString() === deviceId.toString() ?? false)
                    ret.unshift(t);
                else
                    ret.push(t);
            else if ((ret.find((tb)=>tb.id===t.id)?.timestamp as number)<t.timestamp)
            ret[ret.findIndex((bs)=>bs.id===t.id)]=t
        }
        return ret;
    }, [deviceId, tablets]);

    const { value: availableTablets, pending: loadingTablets , exec: fetchTablets} = useAsync(fetchTablets_, {
        immediate: false,
    });

    const fetchBaseStations_ = useCallback(async () => {
        const d = await DeviceDataService.getBaseStationConnections(deviceId, {
            skip: 0,
            limit: 99999999,
            orderBy: [["timestamp", "desc"]],
        });
        const ret = new Array<any>(0);
        for (const t of d.data) {
            let contains = false;
            for (const c of ret)
                if (c.id === t.id)
                    contains = true;
            if (!contains )
                    ret.push(t);
                    else if (ret.find((bs)=>bs.id===t.id).timestamp<t.timestamp)
                    ret[ret.findIndex((bs)=>bs.id===t.id)]=t;
        }
        return ret;
    }, [deviceId]);
    const { value: availableBaseStations, pending: loadingBaseStations, exec: fetchBaseStations } = useAsync(fetchBaseStations_, {
        immediate: false,
    });

    const keyFilterVisual = useMemo(() => {
        if (keyFilter === undefined)
            return [];
        return keyFilter.map((k) => {
            return (
                <div
                    key={k}
                    onClick={() => {setKeyFilter(keyFilter.filter((k_) => {return k_ !== k;}));}}
                >
                    {translator.translateKey(k)+" ✕"}&nbsp;
                </div>
            );
        });
    }, [keyFilter, setKeyFilter, translator]);

    const handlePick = (timestamp: number) => {
        if (cursorMode === "start") {
            setCursorMode("normal");
            const zoomStart = (100 * (timestamp - from.unix()) / (until.unix() - from.unix()));
            setLocalZoom([zoomStart, localZoom[1]]);
            setLocalZoomVisual([zoomStart, localZoom[1]]);
        } else if (cursorMode === "end") {
            setCursorMode("normal");
            const zoomEnd = (100 * (timestamp - from.unix()) / (until.unix() - from.unix()));
            setLocalZoom([localZoom[0], zoomEnd]);
            setLocalZoomVisual([localZoom[0], zoomEnd]);
        }
    };

    const shiftTimeline = useAsyncDebounce((forward: boolean) => {
        const pct = 100 * (60 * AMOUNTS[step] / (until.unix() - from.unix()));
        let start = localZoom[0];
        let end = localZoom[1];
        if (forward) {
            if (end + pct > 100) {
                start += 100 - end;
                end = 100;
            } else {
                end += pct;
                start += pct;
            }
        } else {
            if (start - pct < 0) {
                end -= start;
                start = 0;
            } else {
                end -= pct;
                start -= pct;
            }
        }
        setLocalZoom([start, end]);
        setLocalZoomVisual([start, end]);
    }, 100);

    const fromAdjusted = moment.unix(from.unix()+(until.unix()-from.unix())*localZoom[0]/100);
    const untilAdjusted = moment.unix(from.unix()+(until.unix()-from.unix())*localZoom[1]/100);
    const timespan = untilAdjusted.unix() - fromAdjusted.unix();
    let timelineStartClipRatio = 0;
    let timelineEndClipRatio = 1;
    if (timespan <= TIMELINE_DISPLAY*60) {
        step = "m";
        fromAdjusted.set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-2, "minute");
        else
            untilAdjusted.add(-1, "minute");
        untilAdjusted.set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*5*60) {
        step = "h/12";
        fromAdjusted.set("minute", fromAdjusted.get("minute")-fromAdjusted.get("minute")%5).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("minute") === untilAdjusted.get("minute")-untilAdjusted.get("minute")%5 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-10, "minute");
        else
            untilAdjusted.add(-5, "minute");
        untilAdjusted.set("minute", untilAdjusted.get("minute")-untilAdjusted.get("minute")%5).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*5*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*5*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*15*60) {
        step = "h/4";
        fromAdjusted.set("minute", fromAdjusted.get("minute")-fromAdjusted.get("minute")%15).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("minute") === untilAdjusted.get("minute")-untilAdjusted.get("minute")%15 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-30, "minute");
        else
            untilAdjusted.add(-15, "minute");
        untilAdjusted.set("minute", untilAdjusted.get("minute")-untilAdjusted.get("minute")%15).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*15*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*15*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*30*60) {
        step = "h/2";
        fromAdjusted.set("minute", fromAdjusted.get("minute")-fromAdjusted.get("minute")%30).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("minute") === untilAdjusted.get("minute")-untilAdjusted.get("minute")%30 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-60, "minute");
        else
            untilAdjusted.add(-30, "minute");
        untilAdjusted.set("minute", untilAdjusted.get("minute")-untilAdjusted.get("minute")%30).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*30*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*30*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*60*60) {
        step = "h";
        fromAdjusted.set("minute", 0).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("minute") === 0 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-2, "hour");
        else
            untilAdjusted.add(-1, "hour");
        untilAdjusted.set("minute", 0).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*60*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*60*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*2*60*60) {
        step = "h*2";
        fromAdjusted.set("hour", fromAdjusted.get("hour")-fromAdjusted.get("hour")%2).set("minute", 0).set("second", 0).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("hour") === untilAdjusted.get("hour")-untilAdjusted.get("hour")%2 && untilAdjusted.get("minute") === 0 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-4, "hour");
        else
            untilAdjusted.add(-2, "hour");
        untilAdjusted.set("hour", untilAdjusted.get("hour")-untilAdjusted.get("hour")%2).set("minute", 0).set("second", 0).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*2*60*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*2*60*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*4*60*60) {
        step = "h*4";
        fromAdjusted.set("hour", fromAdjusted.get("hour")-fromAdjusted.get("hour")%4).set("minute", 0).set("second", 0).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("hour") === untilAdjusted.get("hour")-untilAdjusted.get("hour")%4 && untilAdjusted.get("minute") === 0 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-8, "hour");
        else
            untilAdjusted.add(-4, "hour");
        untilAdjusted.set("hour", untilAdjusted.get("hour")-untilAdjusted.get("hour")%4).set("minute", 0).set("second", 0).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*4*60*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*4*60*60-fromAdjusted.unix());
    } else if (timespan <= TIMELINE_DISPLAY*24*60*60) {
        step = "d";
        fromAdjusted.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("hour") === 0 && untilAdjusted.get("minute") === 0 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-2, "day");
        else
            untilAdjusted.add(-1, "day");
        untilAdjusted.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*24*60*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*24*60*60-fromAdjusted.unix());
    } else {
        fromAdjusted.set("day", 1).set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
        if (untilAdjusted.get("day") === 1 && untilAdjusted.get("hour") === 0 && untilAdjusted.get("minute") === 0 && untilAdjusted.get("second") === 0 && untilAdjusted.get("millisecond") === 0)
            untilAdjusted.add(-2, "week");
        else
            untilAdjusted.add(-1, "week");
        untilAdjusted.set("day", 1).set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
        timelineStartClipRatio = (from.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*7*24*60*60-fromAdjusted.unix());
        timelineEndClipRatio = (until.unix()-fromAdjusted.unix())/(untilAdjusted.unix()+2*7*24*60*60-fromAdjusted.unix());
    }

    return (
        <UncontrolledDialog
            fullHeight
            fullWidth
            maxWidth={false}
            Button={({ onClick }) => (
                <Box p={0.5}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            const from_ = moment.unix(moment.now()/1000).subtract(30, "minute").set("second", 0).set("millisecond", 0);
                            if (from_.get("minute") > 30)
                                from_.set("minute", 30);
                            else
                                from_.set("minute", 0);
                            const until_ = moment.unix(moment.now()/1000).set("second", 0).set("millisecond", 0);
                            setFrom(from_);
                            setUntil(until_);
                            setLocalZoom([0,100]);
                            setLocalZoomVisual([0,100]);
                            setShowMarginal(false);
                            fetchData(from_, until_);
                            fetchBaseStations();
                            fetchTablets();
                            onClick();
                            
                        }}
                    >
                        Historic Diagrams
                    </Button>
                </Box>
            )}
        >
            {{
                title: "Historic data - "+deviceTypeIdToString(deviceTypeId)+" "+deviceId,
                content: (
                    <div className={classes.root}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <DateTimePicker
                                    style={{width: "auto", marginRight: "10px"}}
                                    disableFuture
                                    fullWidth
                                    label="From"
                                    margin="dense"
                                    inputVariant="outlined"
                                    value={from}
                                    onChange={(date) => {
                                        if (date !== null) {
                                            setLocalZoom([0,100]);
                                            setLocalZoomVisual([0,100]);
                                            setFrom(date);
                                            fetchData(date, undefined);
                                        }
                                    }}
                                    ampm={false}
                                    strictCompareDates
                                />
                                <DateTimePicker
                                    style={{width: "auto", marginRight: "5px"}}
                                    disableFuture
                                    fullWidth
                                    label="Until"
                                    margin="dense"
                                    inputVariant="outlined"
                                    value={until}
                                    onChange={(date) => {
                                        if (date !== null) {
                                            setLocalZoom([0,100]);
                                            setLocalZoomVisual([0,100]);
                                            setUntil(date);
                                            fetchData(undefined, date);
                                        }
                                    }}
                                    ampm={false}
                                    strictCompareDates
                                />
                                <Tooltip title="Pick a start time, by clicking anywhere in the digaram area." placement="top">
                                    <IconButton
                                        onClick={() => {
                                            if (cursorMode === "start")
                                                setCursorMode("normal");
                                            else
                                                setCursorMode("start");
                                        }}
                                    >
                                        <SvgIcon style={cursorMode === "start" ? {backgroundColor: "#CCCCCC"} : {}}>
                                            <g fill="#444444"><path d={ARROW_PATH} /></g>
                                        </SvgIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Pick an end time, by clicking anywhere in the digaram area." placement="top">
                                    <IconButton
                                        onClick={() => {
                                            if (cursorMode === "end")
                                                setCursorMode("normal");
                                            else
                                                setCursorMode("end");
                                        }}
                                    >
                                        <SvgIcon style={cursorMode === "end" ? {backgroundColor: "#CCCCCC", transform: "scaleX(-1)"} : {transform: "scaleX(-1)"}}>
                                            <g fill="#444444"><path d={ARROW_PATH} /></g>
                                        </SvgIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={<React.Fragment><span style={{textAlign: "center", whiteSpace: "pre-wrap", fontSize: "16px"}}>{
                                    moment((from.unix()+(until.unix()-from.unix())*localZoomVisual[0]/100)*1000).utcOffset(timezone).format("MMMM Do HH:mm")
                                    +"\n"+
                                    moment((from.unix()+(until.unix()-from.unix())*localZoomVisual[1]/100)*1000).utcOffset(timezone).format("MMMM Do HH:mm")
                                }</span></React.Fragment>} placement="top">
                                    <Slider
                                        style={{width: "200px", marginLeft: "20px", marginRight: "20px"}}
                                        value={localZoomVisual}
                                        onChange={(_e, val) => {setLocalZoomVisual(val as number[]);}}
                                        onChangeCommitted={(_e, val) => {setLocalZoom(val as number[]);}}
                                        onWheel={(e) => {
                                            if (e.deltaX > 10 || e.deltaY < -10)
                                                shiftTimeline(true);
                                            else if (e.deltaX < -10 || e.deltaY > 10)
                                                shiftTimeline(false);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Shift the timeline towards the past." placement="top">
                                    <IconButton
                                        onClick={() => {shiftTimeline(false);}}
                                    >
                                        <ArrowIcon style={{transform: "scaleX(-1)"}}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Shift the timeline towards the future." placement="top">
                                    <IconButton onClick={() => {shiftTimeline(true);}}>
                                        <ArrowIcon/>
                                    </IconButton>
                                </Tooltip>
                                <AutocompleteV2
                                    clearOnSelectAndBlur
                                    style={{minWidth: "200px", marginLeft: "20px"}}
                                    options={importantKeys}
                                    getOptionLabel={translator.translateKey}
                                    onChange={(_e, val) => {
                                        if (val !== null && val !== undefined && keyFilter !== undefined)
                                            if (!keyFilter.includes(val))
                                                setKeyFilter([...keyFilter, val]);
                                    }}
                                    renderInput={params =>
                                        <TextField
                                            {...params}
                                            margin="dense"
                                            variant="outlined"
                                            label="Add filter key"
                                        />
                                    }
                                />
                                <Tooltip title="Clear filters. (removing all filters will only show keys which has at least 1 entry in the given timeframe)">
                                    <IconButton onClick={() => {if (keyFilter !== undefined && keyFilter.length !== 0) setKeyFilter([]);}}>
                                        <ClearIcon/>
                                    </IconButton>
                                </Tooltip>
                                <div className={classes.keyFilter}>
                                    {keyFilterVisual}
                                </div>
                            </div>
                            <div>
                                <Tooltip title="Enabling this will result in the slight distortion of the timeline, but could reveal entries with a marginal timespan, which were not displayed previously.">
                                    <FormControlLabel
                                        style={{
                                            margin:"0px",
                                            marginRight: "5px",
                                        }}
                                        control={
                                            <Switch
                                                checked={showMarginal}
                                                size="small"
                                                onChange={(e) => {setShowMarginal(e.target.checked);}}
                                            />
                                        }
                                        label="Show marginal"
                                    />
                                </Tooltip>
                                <RefreshButton
                                    onClick={() => {fetchData(undefined, undefined);changeState();}}
                                    loading={loading}
                                />
                            </div>
                        </div>
                        <DiagramBase
                            from={from}
                            until={until}
                            fromAdjusted={fromAdjusted}
                            untilAdjusted={untilAdjusted}
                            data={data ?? []}
                            showMarginal={showMarginal}
                            cursorMode={cursorMode}
                            handlePick={handlePick}
                            loading={loading || loadingTablets || loadingBaseStations}
                            step={step}
                            timelineStartClipRatio={timelineStartClipRatio}
                            timelineEndClipRatio={timelineEndClipRatio}
                            onWheel={(e) => {
                                if (e.deltaX > 10)
                                    shiftTimeline(true);
                                else if (e.deltaX < -10)
                                    shiftTimeline(false);
                            }}
                            stateChange={stateChange}
                            tablets={availableTablets ?? []}
                            baseStations={availableBaseStations ?? []}
                            loadingTablets={loadingTablets}
                            loadingBaseStations={loadingBaseStations}
                        />
                    </div>
                ),
            }}
        </UncontrolledDialog>
    );
}
export default HistoricDiagram;