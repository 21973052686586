import * as Comlink from "comlink";
import Worker from "worker-loader!./wsWorker"; // eslint-disable-line import/no-webpack-loader-syntax
import type { WorkerType } from "./wsWorker";
import type { EventTypes, EventMap, GroupedEvent } from "./types";

const instance = Comlink.wrap<WorkerType>(new Worker());

function onWrapped<T extends EventTypes>(
    type: T,
    cb: EventMap[T]
): Promise<() => Promise<void>>;
function onWrapped<T extends EventTypes>(
    type: T[],
    cb: (ev: GroupedEvent) => void
): Promise<() => Promise<void>>;
function onWrapped(type: any, cb: any) {
    const proxy = Comlink.proxy(cb);
    return instance.on(type, proxy);
}

export default {
    connect: instance.connect,
    disconnect: instance.disconnect,
    on: onWrapped,
};
