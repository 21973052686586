/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import TableCell, { TableCellProps as MuiTableCellProps } from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { TableCellProps, TableHeaderProps, Cell, MetaBase, ColumnInstance } from "react-table";
import { usePreview } from "react-dnd-preview";
import { useHighlightedUpdate } from "src/hooks";
import classNames from "src/utils/classNames";
import useColumnReorder, { ReorderOptions, DragAction } from "./useColumnReorder";

export function getHighlightProps<T extends {}>(
    _props: Partial<TableCellProps>,
    { cell, instance }: MetaBase<T> & { cell: Cell<T> }
) {
    if (instance.highlightRowOnUpdate) {
        return { original: cell.row.original };
    }
    return {};
}

export function HighlightedCell<T extends {}>({
    original,
    ...rest
}: { original: T } & MuiTableCellProps) {
    const { className, ...props } = rest;
    const { onTransitionEnd, className: transition } = useHighlightedUpdate([
        original,
    ]);
    return (
        <TableCell
            {...props}
            onTransitionEnd={onTransitionEnd}
            className={classNames(className, transition)}
        />
    );
}

export function getColumnReorderProps<T extends {}>(
    _props: Partial<TableHeaderProps>,
    { column, instance }: MetaBase<T> & { column: ColumnInstance<T> }
) {
    if (instance.enableColumnReorder) {
        return {
            column: column,
            visibleColumns: instance.visibleColumns,
            setColumnOrder: instance.setColumnOrder,
        };
    }
    return {};
}

export function ColumnReorderCell<T extends {}>({
    column,
    visibleColumns,
    setColumnOrder,
    children,
    ...rest
}: MuiTableCellProps & ReorderOptions<T>) {
    const {
        outerRef,
        outerTitle,
        showOuterTitle,
        innerRef,
        innerClassName,
    } = useColumnReorder({ column, visibleColumns, setColumnOrder });

    if (column.orderable === false) {
        return <TableCell {...rest} children={children} />;
    }

    const mappedChildren = React.Children.map(children, (child, idx) =>
        idx === 0 && React.isValidElement(child)
            ? React.cloneElement(child, {
                  ref: innerRef,
                  className: classNames(child.props.className, innerClassName),
              })
            : child
    );

    return (
        <Tooltip
            open={showOuterTitle}
            title={outerTitle}
            aria-label={outerTitle}
        >
            <TableCell ref={outerRef} {...rest}>
                {mappedChildren}
            </TableCell>
        </Tooltip>
    );
}

export function ColumnReorderCellPreview() {
    const { display, item, style } = usePreview<DragAction<any>>();
    if (!display) {
        return <></>;
    }
    return (
        <TableCell component="div" style={style}>
            {item.column?.render("Header")}
        </TableCell>
    );
}
