import React from "react";
import { makeStyles } from "@material-ui/core";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ReloadIcon from "@material-ui/icons/CachedRounded";
import classNames from "src/utils/classNames";

const useStyles = makeStyles({
    "@keyframes rotation": {
        from: {
            transform: "rotate(0deg)",
        },
        to: {
            transform: "rotate(-359deg)",
        },
    },
    btn: {
        "&[data-loading='true']": {
            animation: "$rotation 1s infinite linear",
        },
    },
});

export interface RefreshButtonProps
    extends Omit<IconButtonProps, "disabled" | "children"> {
    loading: boolean;
    title?: string;
    noTitle?: boolean;
}

export default function RefreshButton({
    loading,
    className,
    title,
    noTitle,
    ...rest
}: RefreshButtonProps) {
    const classes = useStyles();
    return (
        <Tooltip title={noTitle ? "" : (title || "Refresh")}>
            <span>
                <IconButton
                    aria-label={title?.toLowerCase() || "refresh"}
                    {...rest}
                    data-loading={loading}
                    disabled={loading}
                    className={classNames(className, classes.btn)}
                >
                    <ReloadIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}
