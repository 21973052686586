import {
    take,
    put,
    fork,
    call,
    delay,
    cancelled,
    actionChannel,
    race,
} from "redux-saga/effects";
import { eventChannel, Task, buffers } from "redux-saga";
import { SIGNED_IN, SIGN_OUT, SignedInAction } from "../auth/types";
import {
    LOAD_CUSTOMERS_DTO,
    DISPLAY_TOAST,
    CONTROL_TOAST,
    ToastMessage,
    DisplayToastAction,
    LoadCustomersDtoAction,
    AppAction,
} from "./types";
import {
    setConnectionState,
    loadingCustomersDto,
    setCustomersDto,
    controlToast,
} from "./actions";

import UserHttpService from "../../services/UserService";
import AuthService from "../../services/AuthService";
import events from "../../events";

const makeConnectionStateChannel = async () => {
    let subscribed = false;
    let fn: any;
    const unsubscribe = await events.on("connection-change", (state) => {
        if (subscribed && fn) {
            fn(setConnectionState(state));
        }
    });
    return eventChannel<AppAction>((emit) => {
        fn = emit;
        subscribed = true;
        return () => {
            subscribed = false;
            unsubscribe();
        };
    }, buffers.sliding(5));
};

function* watchConnectionState() {
    const connectionStateChannel = yield call(makeConnectionStateChannel);

    try {
        while (true) {
            const action = yield take(connectionStateChannel);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            connectionStateChannel.close();
        }
    }
}

function* connectToServer() {
    while (true) {
        try {
            const action: SignedInAction = yield take(SIGNED_IN);
            if (action.payload.success) {
                // Start monitoring event connection state
                const connectionStateTask: Task = yield fork(
                    watchConnectionState
                );

                yield call(events.connect, AuthService.getActiveToken()!);
                yield take(SIGN_OUT);

                connectionStateTask.cancel();

                yield call(events.disconnect);
            }
        } catch (err) {
            if (process.env.NODE_ENV === "production") {
                console.error(err);
            }
        }
    }
}

function* fetchCustomersDto() {
    while (true) {
        const action: SignedInAction | LoadCustomersDtoAction = yield take([
            SIGNED_IN,
            LOAD_CUSTOMERS_DTO,
        ]);
        if (action.type === LOAD_CUSTOMERS_DTO || action.payload.success) {
            while (true) {
                try {
                    yield put(loadingCustomersDto());
                    const dto = yield call(UserHttpService.getCustomersDto);
                    yield put(setCustomersDto(dto));
                    break;
                } catch (err) {
                    yield put(setCustomersDto({}, err));
                }
                yield delay(1000);
            }
        }
    }
}

function* handleToastMessage(message: ToastMessage) {
    yield put(controlToast(true, message));

    const { timeout } = yield race({
        timeout: delay(3000),
        cancel: take(CONTROL_TOAST),
    });

    if (timeout) {
        yield put(controlToast(false));
    }
}

function* monitorToast() {
    const toastChannel = yield actionChannel(DISPLAY_TOAST);
    while (true) {
        const { payload }: DisplayToastAction = yield take(toastChannel);
        yield call(handleToastMessage, payload);
        yield delay(300);
    }
}

export default function* sagas() {
    yield fork(fetchCustomersDto);
    yield fork(connectToServer);
    yield fork(monitorToast);
}
