import React, { useCallback,  useEffect, useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import PasswordField from "src/components/PasswordField";
import LoadingButton from "src/components/LoadingButton";

import BaseView from "src/views/BaseView";
import { useDispatch, useSelector } from "react-redux";
import useFormStyles from "./useFormStyles";
import { useAsync, useErrorHandler, useDebounced, useToast } from "src/hooks";
import getErrorProps from "src/utils/getErrorProps";
import UserService, { CustomerInfo, User } from "src/services/UserService";
import { useForm, Controller } from "react-hook-form";
import {
    customersArraySelector,
    customersLoadedSelector,
} from "src/redux/app/selectors";
import { isMobile } from "react-device-detect";
import AutocompleteV2 from "src/components/AutocompleteV2";
import NotFound from "../NotFound";
import { addUser } from "src/redux/app/actions";
import {  Checkbox, FormControlLabel, FormGroup, Tooltip, makeStyles } from "@material-ui/core";
import { userAccessSelector } from "src/redux/auth/selectors";
import { Accordion, AccordionDetails,  AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    accordion: {
        color: theme.palette.grey[700],
    },
    accordionBorder:{
        boxShadow:'none', 
        border: '1px solid rgb(150,150,150)'
    }
}));

type CreateAccountInput = {
    username: string;
    password: string;
    confirmPassword: string;

    customer: CustomerInfo | null;
    firstname: string;
    lastname: string;
    email: string;
};



const defaultRules = {
    required: "This field is required",
};

export default function CreateAccount() {
    const classes = useFormStyles();
    const localClasses=useStyles()
    const { displayToast } = useToast();
    const dispatch = useDispatch();
    const [createdAccountName, setCreatedAccountName] = useState("");
    const [generatePw, setGeneratePw] = useState(true);

    const {
        handleSubmit,
        getValues,
        setValue,
        errors,
        setError,
        clearErrors,
        control,
        reset,
    } = useForm<CreateAccountInput>({ defaultValues: { customer: null } });

    const fetchAccessFields = useCallback((() => UserService.getAccessFields()
    .then((afs)=>
    afs.sort((a,b)=>a.access_lvl>b.access_lvl?1:-1)
    )),[])
    const { value: accessFields, exec: fetchAccessFields_ } = useAsync(fetchAccessFields, {
        immediate: false,
    });
    useEffect(() => {
        fetchAccessFields_();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [access,setAccess]=useState(accessFields?.map((af)=>{return {...af,hasAccess: (af.access_lvl===0 && af.tag==='Default')}})??[])
    useEffect(()=>{
        setAccess(accessFields?.map((af)=>{return {...af,hasAccess: (af?.access_lvl===0 && af.tag==='Default')}})??[])
    },[accessFields])

    const customersLoaded = useSelector(customersLoadedSelector);
    const onCreateAccount = useCallback(
        ({ customer, ...rest }: CreateAccountInput) => {
            setCreatedAccountName("");
            return UserService.createUser({
                customerId: [customer!.id],
                userAccess: access.filter((af)=>af.hasAccess).map((af)=>af.tag),
                ...rest,
            } as any, generatePw);
        },
        [generatePw,access]
    );

    const onAccountCreated = useCallback(
        (newUser: User) => {
            dispatch(addUser(newUser));
            displayToast({
                message: "The account was created succesfully.",
                severity: "success",
            });
            reset({
                username: "",
                password: "",
                confirmPassword: "",
                customer: null,
            });
            setCreatedAccountName(newUser.username);
        },
        [displayToast, reset, dispatch]
    );

    const onAccountCreatedError = useErrorHandler({
        onValidationError: setError,
        fallback: useCallback((e: any) => {
            displayToast({
                message: (e.message !== undefined && e.message !== "") ? e.message.toString() : "An unknown error has occurred. Please try reloading the page.",
                severity: "error",
            });
        }, [displayToast]),
    });

    const { exec: createAccount, pending: creatingAccount } = useAsync(
        onCreateAccount,
        {
            immediate: false,
            onComplete: onAccountCreated,
            onError: onAccountCreatedError,
        }
    );

    const loading = !customersLoaded;
    const debouncedLoading = useDebounced(loading, 500);

    const customers = useSelector(customersArraySelector);

    const rootCustomer = useMemo(
        () => customers && customers.find((c) => c.id === 1),
        [customers]
    );

    type MappedInput = [keyof CreateAccountInput, string, any, boolean?, boolean?];

    const userAccess = useSelector(userAccessSelector).includes('User');
    if (!userAccess)
        return (<NotFound />);

    return (
        <BaseView loading={debouncedLoading}>
            <Box component="main" className={classes.container}>
                <div className={classes.paperFlex}>
                    <Typography component="h1" variant="h4">
                        Create Account
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(createAccount)}
                        noValidate
                    >
                        {([
                            ["username", "Username"],
                            [
                                "email",
                                "Email",
                                generatePw ? {
                                    required: "Please enter valid email",
                                    validate: {
                                        validEmail: (value: string) => {
                                            return (value.toLowerCase().match(
                                                /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/
                                            ) || "Invalud email!");
                                        },
                                    },
                                } : {},
                                generatePw
                            ],
                            ["password", "Password", generatePw ? {} : undefined, !generatePw, generatePw],
                            [
                                "confirmPassword",
                                "Confirm Password",
                                generatePw ? {} : {
                                    required: "Please confirm password",
                                    validate: {
                                        matchesPreviousPassword: (
                                            value: string
                                        ) => {
                                            const { password } = getValues();
                                            return (
                                                value === password ||
                                                "Passwords should match!"
                                            );
                                        },
                                    },
                                },
                                !generatePw,
                                generatePw
                            ],
                        ] as MappedInput[]).map(([name, label, rule, req, dis], idx) => {
                            return (
                                <Controller
                                    key={name}
                                    name={name}
                                    control={control}
                                    defaultValue=""
                                    rules={rule || defaultRules}
                                    render={(props) => {
                                        const Component =
                                            idx < 2
                                                ? TextField
                                                : PasswordField;
                                        return (
                                            <Component
                                                {...props}
                                                variant="outlined"
                                                margin="normal"
                                                required={req ?? true}
                                                disabled={creatingAccount || (dis ?? false)}
                                                fullWidth
                                                id={name}
                                                label={label}
                                                autoFocus={
                                                    idx === 0 && !isMobile
                                                }
                                                {...getErrorProps(errors, name)}
                                            />
                                        );
                                    }}
                                />
                            );
                        })}
                        <Tooltip title="Generates a one time login token for the user where they can set their password, and sends it to them in email." placement="left">
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Checkbox
                                    disabled={creatingAccount}
                                    checked={generatePw}
                                    onClick={() => {
                                        setGeneratePw(!generatePw);
                                        if (generatePw)
                                            clearErrors(["email"]);
                                        else {
                                            clearErrors(["password","confirmPassword"]);
                                            setValue("password","");
                                            setValue("confirmPassword","");
                                        }
                                    }}
                                />
                                <Typography
                                    style={creatingAccount ? {color: "#AAAAAA", cursor: "default"} : {cursor: "default"}}
                                >
                                    generate one time login {"&"} send email
                                </Typography>
                            </div>
                        </Tooltip>
                        <Grid container spacing={3} className={classes.selects}>
                           
                       
                            <Grid item xs={12} md={12}>
                            <Accordion className={localClasses.accordionBorder} >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}}/>} >
                                        <Box style={{display:"flex",justifyContent:"space-between",width:"100%",paddingRight:"20px"}}>
                                            <span style={{display: "flex",justifyContent: "center",alignItems: "center"}}>User Access</span>
                                        </Box>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    {[...new Set(access?.map(af=>af.access_lvl))]?.map((lvl,index)=> 
                                     (<Grid item xs={12} md={12} key={index}>
                                
                                        <Accordion className={localClasses.accordionBorder}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}} />} >
                                                <FormControlLabel 
                                                label={<span className={localClasses.accordion} onClick={(ev)=>{ev.stopPropagation()}}>{"Level "+lvl}</span>}
                                                style={{ pointerEvents: "none"}}
                                                control={
                                                    <Checkbox className={localClasses.accordion}
                                                    style={{color:"rgb(100,100,100)", pointerEvents: "auto"}}
                                                    checked={!(access?.filter((af)=>af.access_lvl===lvl).find((af)=>!af.hasAccess))}
                                                    indeterminate={Boolean(access.filter((af)=>af.access_lvl===lvl).find((af)=>!af.hasAccess)) && Boolean(access.filter((af)=>af.access_lvl===lvl).find((af)=>af.hasAccess))}
                                                    disabled={!userAccess}
                                                    onChange={
                                                        (ev)=>{
                                                            setAccess(access.map((acf)=>{return acf.access_lvl===lvl?{...acf, hasAccess: (acf.tag === 'Default' ? true : ev.target.checked)}:acf}))
                                                        }
                                                    }
                                                    onClick={
                                                        (ev)=>{
                                                            ev.stopPropagation()
                                                        }
                                                    }
                                                    />
                                                }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>        
                                                <FormGroup>
                                                    {access?.filter((af)=>af.access_lvl===lvl).filter((af)=>(userAccess)).map((af,index)=>
                                                    <Tooltip title={af.name}key = {index}>
                                                        <FormControlLabel
                                                        label={<span className={localClasses.accordion}>{af.tag}</span>}
                                                        style={{marginLeft:"10px"}}
                                                        disabled={af.tag === 'Default'}
                                                        control={
                                                        <Checkbox 
                                                            checked={af.hasAccess}
                                                            style={{color:"rgb(100,100,100)"}}
                                                            disabled={!userAccess}
                                                            onChange={(ev)=>{
                                                                setAccess(access.map((acf)=>{return acf.tag===af.tag?{...acf,hasAccess:ev.target.checked}:acf}))
                                                            }}
                                                            />}/>
                                                    </Tooltip>
                                                    )
                                                    }
                                                </FormGroup>
                                           
                                            </AccordionDetails>
                                          </Accordion>
                                
                                        </Grid>))}
                                    </AccordionDetails>   
                              </Accordion>
                            </Grid>
                        </Grid>    
                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12}>
                                <Controller
                                    name="customer"
                                    control={control}
                                    rules={defaultRules}
                                    render={({ onChange, ...rest }) => {
                                        return (
                                            <AutocompleteV2
                                                selectOnTab
                                                {...rest}
                                                disabled={
                                                    (getValues().customer ===
                                                        rootCustomer &&
                                                        userAccess) ||
                                                    creatingAccount
                                                }
                                                options={customers || []}
                                                getOptionLabel={(opt) =>
                                                    opt.name
                                                }
                                                noOptionsText="No such customer"
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Associated Customer"
                                                            {...getErrorProps(
                                                                errors,
                                                                "customer"
                                                            )}
                                                            required
                                                        />
                                                    );
                                                }}
                                                onChange={(_ev, val) => {
                                                    onChange(val);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            </Grid>
                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    key="firstname"
                                    name="firstname"
                                    control={control}
                                    defaultValue=""
                                    render={(props) => {
                                        return (
                                            <TextField
                                                {...props}
                                                variant="outlined"
                                                fullWidth
                                                disabled={creatingAccount}
                                                label="First Name"
                                                id={"firstname"}
                                                {...getErrorProps(
                                                    errors,
                                                    "firstname"
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    key="lastname"
                                    name="lastname"
                                    control={control}
                                    defaultValue=""
                                    render={(props) => {
                                        return (
                                            <TextField
                                                {...props}
                                                variant="outlined"
                                                fullWidth
                                                disabled={creatingAccount}
                                                label="Last Name"
                                                id={"lastname"}
                                                {...getErrorProps(
                                                    errors,
                                                    "lastname"
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {createdAccountName && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={2}
                                color="success.main"
                            >
                                <Typography>
                                    Account <b>{createdAccountName}</b> was
                                    created succesfully.
                                </Typography>
                            </Box>
                        )}
                        <LoadingButton
                            className={classes.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={creatingAccount}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                </div>
            </Box>
        </BaseView>
    );
}
