import { createSelector } from "reselect";
import { AuthState } from "./types";

type StateProxy = { auth: AuthState };

export const identitySelector = (state: StateProxy) => state.auth.identity;
export const signingInSelector = (state: StateProxy) => state.auth.signingIn;
export const TFANeededSelector = (state: StateProxy) => state.auth.TFANeeded;
export const authErrorSelector = (state: StateProxy) => state.auth.error;
export const credentialsSelector = createSelector(
    identitySelector,
    (identity) => (identity.authenticated ? identity.credentials : undefined)
);
export const authStatusSelector = createSelector(
    signingInSelector,
    authErrorSelector,
    TFANeededSelector,
    (signingIn, authError,TFANeeded) => ({ signingIn, authError, TFANeeded })
);

export const isAuthenticatedSelector = createSelector(
    identitySelector,
    (identity) => identity.authenticated
);


export const userAccessSelector = createSelector(
    identitySelector,
    (identity)=>
        identity.authenticated ? identity.credentials.userAccess:([])
)
