import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import {  useSelector } from "react-redux";
import type { Tablet } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler,  useToast } from "src/hooks";
import { customersDtoSelector,  tabletsSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import KnoxService from "src/services/KnoxService";
import Checkbox from "react-three-state-checkbox";

interface lockTabletInput {
    tablet: Tablet | null;
    lock: boolean;
    msg: string;
}


function lockTablet(input: lockTabletInput) {
    const { tablet,lock,msg} = input;
    if (tablet)
    return KnoxService.lockDevice(
        tablet.id,
        lock,
        msg
).then(() => input);
else return Promise.reject("Tablet not found");
}

export default function LockTabletWidget({ widgetName, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        register,
        watch,
        setValue
    } = useForm<lockTabletInput>({
        defaultValues: { tablet: null,lock:true,msg:"" },
    });

    const tablets = useSelector(tabletsSelector);
    const customersDto = useSelector(customersDtoSelector);
    useEffect(()=>{
        register("msg",{})
    },[register])

    const messageUs=`
    Your robot has been suspended due to missing payment of your service agreement.
    Please contact TinyMobileRobots to remedy this.
    finance@tinymobilerobots.com
    404-595-1145

    `
    const messageRest=`
    Your robot has been suspended due to missing payment of your service agreement.
    Please contact TinyMobileRobots to remedy this.
    finance@tinymobilerobots.com
    +45 35 15 62 29

    `        

  
    const { displayToast } = useToast();
    const onComplete = useCallback(
        (input: lockTabletInput) => {
            displayToast({
                message: `Successfully locked/unlocked Tablet ${
                    input.tablet!.id
                }`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast]
    );

    const { exec, pending: locking, error } = useAsync(
        lockTablet,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const message=watch("msg")
    const loading = locking || tablets === null;
    
    return (
        <BaseWidget
            widgetName={widgetName}
            title="Lock/Unlock tablet"
            subTitle="Lock or unlock a tablet"
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
            
        >
            <Controller
                name="tablet"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            disabled={loading}
                            options={tablets}
                            getOptionLabel={(t) => {
                                const customer = customersDto[t.customerId ?? -1];
                                let ret = t.id;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+t.customerId+"]";
                                return ret;
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                            noOptionsText="No such tablet"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Tablet"
                                        placeholder="Choose a tablet"
                                        required
                                        {...getErrorProps(errors, "tablet")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (customersDto[val.customerId ?? -1]?.countries?.includes("United States"))
                                    setValue("msg", messageUs);
                                else
                                    setValue("msg", messageRest);
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="lock"
                control={control}
                render={({ onChange, ...rest }) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', margin:5 }}>
                            <Checkbox
                                {...rest}
                                disabled={loading}
                                checked={rest.value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                            <label style={{ fontWeight: 'bold', color: rest.value ? 'red' : 'green' }}>
                                {rest.value ? "Lock" : "Unlock"}
                            </label>
                        </div>
                    );
                }
            }></Controller>
            <TextField value={message} disabled fullWidth multiline></TextField>

            
            
        </BaseWidget>
    );
}
