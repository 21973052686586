import axios from "axios";
import { handleError } from "./Errors";
import { FilterResult } from "./SupportService";
import qs from "qs";

export interface NewDevicesFilterOptions {
    skip: number;
    limit: number;
    orderBy?: [
        keyof NewDevice,
        "desc" | "asc"
    ][];
    notBefore?: moment.Moment;
    notAfter?: moment.Moment;
    globalSearch?: string;
    status?: string;
    productModel?: string;
}

export interface NewDevice {
    device_id: number;
    device_type: number;
    order: number;
    hardware_id: string;
    status: string;
    updated_at?: number;
    robot_type: string;
    server_status: string;
}

export default class ProductionService {

    static async getRobotIds(): Promise<Array<number>> {
        try {
            const response = await axios.get("/api/production/getRobotIds");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getNewRobotIds(): Promise<Array<number>> {
        try {
            const response = await axios.get("/api/production/getNewRobotIds");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getNewDevices(
        options?: Partial<NewDevicesFilterOptions>
    ): Promise<FilterResult<NewDevice, NewDevicesFilterOptions>> {
        const query = qs.stringify(options || {});

        try {
            const response = await axios.get(
                `/api/production/getNewDevices${
                    (query && "?" + query) || ""
                }`
            );
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async postAvailableDeviceIds(ids: number[], product_model: string, order_start?: number): Promise<void> {
        try {
            const response = await axios.post("/api/production/postAvailableDeviceIds", {ids, product_model, order_start});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async redeployDevice(ids: number[]): Promise<void> {
        try {
            const response = await axios.post("/api/production/redeployDevice", {ids});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async deleteDevice(ids: number[]): Promise<void> {
        try {
            const response = await axios.post("/api/production/deleteDevice", {ids});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

}