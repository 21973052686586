import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { HeaderCell, TranslatedCell } from "../Devices/Overview/Cells";
import { CellRendererProps, GridColumn } from "src/components/DataGrid";
import { UserOv } from "./AccountOverview";
import moment from "moment";
import { TimeFormats } from "src/utils/constants";
import { makeStyles } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { timezone } from "src/App";

function makeColumns(keys: string[]) {
    return {
        fixed: {
            Header: HeaderCell,
            id: "Username",
            accessor: "username",
            width: isMobile ? 110 : 150,
            name: `Username`,
            Cell: TranslatedCell,
        } as GridColumn<UserOv> & { width: number },
        keys: keys.map(
            (key) => {
                switch (key) {
                    case "verified":
                        return {
                            Header: HeaderCell,
                            accessor: `data.${key}`,
                            Cell: BooleanCell,
                            id: key,
                            name: key,
                        };
                    case "from":
                    case "to":
                    case "lastLogin":
                        return {
                            Header: HeaderCell,
                            accessor: `data.${key}`,
                            Cell: DateCell,
                            id: key,
                            name: key,
                        };
                    default:
                        return {
                            Header: HeaderCell,
                            accessor: `data.${key}`,
                            Cell: TranslatedCell,
                            id: key,
                            name: key,
                        };
                }
            }
        ) as GridColumn<UserOv>[],
    };
}

export default function useColumns(keys: string[]) {
    const [cols] = useState(() => makeColumns(keys));
    return cols;
}

const useBooleanCellStyles = makeStyles((theme) => ({
    open: {
        color: theme.palette.error.dark,
    },
    closed: {
        color: theme.palette.success.light,
    },
}));

export function BooleanCell<T>(props: CellRendererProps<T>) {
    const classes = useBooleanCellStyles();
    const {
        value,
    } = props;

    return (
        <>
            {value
                ?
                <CheckIcon
                    className={classes.closed}
                />
                :
                <ClearIcon
                    className={classes.open}
                />
            }
        </>
    );
}

function DateCell<T>(props: CellRendererProps<T>) {
    const {
        value,
    } = props;
    
    const dateString = moment.unix(value).utcOffset(timezone).format(TimeFormats.Presentation2);

    return <>{value === undefined ? "" : dateString}</>;
}