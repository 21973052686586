import { isArray } from "lodash";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";

const stateSetters = new Array<React.Dispatch<any>>(0);
const initialValues = new Array<any>(0);

export function setQueryStringWithoutPageReload(qsValue: string) {
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + qsValue;
    window.history.replaceState({path: newurl}, "", newurl);
    if (qsValue === "")
        for (let i = 0; i < stateSetters.length; i++)
            stateSetters[i](initialValues[i]);
}

function setQueryStringValue(key: string, value: any) { 
    let current = window.location.search;
    if (current.charAt(0)==="?")
        current = current.substring(1);
    const values = qs.parse(current, {arrayLimit: 999});
    let newQsValue;
    if (value !== undefined)
        newQsValue = qs.stringify({...values, [key]: value});
    else {
        delete values[key];
        newQsValue = qs.stringify(values);
    }
    setQueryStringWithoutPageReload(newQsValue);
}

function isNumber(value : string) : boolean { 
    const match = value.match(/[+-]?(\d+[.,])?\d+/);
    if (match === null)
        return false;
    return value === match[0];
}

function getQueryStringValue(key: string) : boolean | number | string | boolean[] | number[] | string[] | undefined { 
    let current = window.location.search;
    if (current.charAt(0)==="?")
        current = current.substring(1);
    const values = qs.parse(current, {arrayLimit: 999});
    if (typeof values[key] === "string") {
        if (values[key] === "true")
            return true as boolean;
        else if (values[key] === "false")
            return false as boolean;
        else if (isNumber(values[key] as string))
            return Number(values[key]);
        return values[key] as string;
    } else if (isArray(values[key])) {
        const arr = values[key] as Array<string>;
        if (arr.length === 0)
            return arr;
        else if (arr[0] === "true" || arr[0] === "false")
            return arr.map((val) => {return (val === "true");});
        else if (isNumber(arr[0]))
            return arr.map((val) => {return Number(val);});
        return arr;
    }
}

export default function useQueryString<T>(key: string, initialValue: T) {
    let temp : any = getQueryStringValue(key);
    if (temp === undefined)
        temp = initialValue;
    const [value, setValue] = useState(temp);
    useEffect(() => {
        stateSetters.push(setValue);
        initialValues.push(initialValue);
        return () => {
            const index = stateSetters.findIndex(s => s === setValue);
            stateSetters.splice(index, 1);
            initialValues.splice(index, 1);
        }
    }, [setValue]); //eslint-disable-line react-hooks/exhaustive-deps
    const onSetValue = useCallback(
        newValue => {
            setValue(newValue);
            setQueryStringValue(key, newValue);
        }, [key]
    );
    return [value, onSetValue] as [T, (newValue: T | undefined) => void];
}