export enum TimeFormats {
    Presentation = "HH:mm:ss, MMM D, YY",
    PresentationShort = "YY/MM/DD",
    Presentation2 = "YY/MM/DD HH:mm",
    PresentationShort2 = "YY/MM/DD",
    Sort = "x",
    Debug = "HH:mm:ss, Do of MMMM",
}

export enum DeviceType {
    Unknown = 0,
    Robot = 1,
    Tablet = 2,
    BaseStation = 3,
}

export const NOT_IMPORTANT_KEYS = [
    "Dealer",
    "Current Tablets",
    "system_message_1",
    "system_message_2",
    "system_message_3",
    "system_message_4",
    "system_message_5",
    "modem_operator_1",
    "modem_operator_2",
    "modem_operator_3",
    "modem_operator_4",
    "modem_operator_5",
]