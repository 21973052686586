import React, { useCallback, useMemo, useState } from "react";
import { Column, SortingRule, CellProps, FilterProps, Filters, TableState } from "react-table";
import Box from "@material-ui/core/Box";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { TimeFormats } from "src/utils/constants";
import { useAsync } from "src/hooks";
import moment from "moment";
import { createSelector } from "reselect";
import { timezone } from "src/App";
import ProductionService, { NewDevice } from "src/services/ProductionService";
import { TextField, debounce } from "@material-ui/core";
import FilteredTable from "src/components/FilteredTable/FilteredTable";

export interface TableProps {
    height: number | string;
    name: string;
    innerRef: any;
}

interface NewDevicesImportantState {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule<NewDevice>[];
    globalFilter: string;
    filters: Filters<NewDevice>;
}

const mapNewDevicesImportantState = createSelector(
    (s: TableState<NewDevice>) => s.pageIndex,
    (s:any) => s.pageSize,
    (s:any) => s.sortBy,
    (s:any) => s.globalFilter,
    (s:any) => s.filters,
    (pageIndex, pageSize, sortBy, globalFilter, filters) => ({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
        filters,
    })
);

export function NewDevicesTable({ height, name, innerRef }: TableProps) {
    function DateCell({ cell: { value } }: CellProps<NewDevice>) {
        if (Array.isArray(value)) {
            return value
                .flatMap(
                    (v: moment.Moment, i) =>
                        (v &&
                            `${i === 0 ? "Before: " : "After: "} ${moment(v).utcOffset(timezone).format(
                                TimeFormats.PresentationShort2
                            )}`) ||
                        []
                )
                .join(" - ");
        }
        const dateString = moment.unix(value).utcOffset(timezone).format(TimeFormats.Presentation2);
        return <>{dateString}</>;
    }
    
    function DataCell({
        cell: { value },
    }: CellProps<NewDevice>) {
        return <div style={{whiteSpace: "normal"}}>{value}</div>;
    }
    
    function TimestampColumnFilter({
        column: { filterValue, setFilter },
    }: FilterProps<NewDevice>) {
        const [notBefore,setNotBefore] = useState((filterValue?.[0] && moment(filterValue[0])) || null);
        const [notAfter,setNotAfter] = useState((filterValue?.[1] && moment(filterValue[1])) || null);
    
        return (
            <Box p={0}>
                <Box mb={1}>
                    <KeyboardDateTimePicker
                        disableFuture
                        fullWidth
                        label="Not before"
                        margin="dense"
                        inputVariant="outlined"
                        value={notBefore}
                        onChange={(date) => {
                            if (notAfter === null || date?.isBefore(notAfter)) {
                                setFilter((old: any[] = []) => {
                                    return [date, old[1]];
                                });
                                setNotBefore(date)
                            }
                        }}
                        maxDate={notAfter || undefined}
                        ampm={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        strictCompareDates
                        InputProps={{
                            readOnly: true,
                            endAdornment: notBefore !== null && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setFilter((old: any[]) =>
                                            old[1] ? [null, old[1]] : null
                                        );
                                        setNotBefore(null)
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        KeyboardButtonProps={{ size: "small" }}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </Box>
                <Box>
                    <KeyboardDateTimePicker
                        disableFuture
                        fullWidth
                        label="Not after"
                        inputVariant="outlined"
                        margin="dense"
                        ampm={false}
                        value={notAfter}
                        onChange={(date) => {
                            if (notBefore === null || date?.isAfter(notBefore)) {
                                setFilter((old: any[] = []) => {
                                    return [old[0], date];
                                });
                                setNotAfter(date)
                            }
                        }}
                        minDate={notBefore || undefined}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        strictCompareDates
                        InputProps={{
                            readOnly: true,
                            endAdornment: notAfter !== null && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setFilter((old: any[]) =>
                                            old[0] ? [old[0], null] : null
                                        );
                                        setNotAfter(null)
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        KeyboardButtonProps={{ size: "small" }}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </Box>
            </Box>
        );
    }

    const CreateTextFilter = (label:string) => ({
        column: { filterValue, setFilter },
    }: FilterProps<NewDevice>) => {
        const debounced = debounce((str:string) => setFilter(str), 500);
        return (
            <Box p={0}>
                <Box mb={1}>
                    <TextField
                        fullWidth
                        label={label}
                        margin="dense"
                        variant="outlined"
                        defaultValue={filterValue}
                        onChange={(e) => {
                            debounced(e.target.value);
                        }}
                        size="small"
                    ></TextField>
                </Box>
            </Box>
        );
    };

    const latestColumns: Column<NewDevice>[] = [
        {
            accessor: "device_id",
            Header: "Device ID",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "order",
            Header: "Priority",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "hardware_id",
            Header: "Hardware ID",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "status",
            Header: "Status",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "updated_at",
            Header: "Updated At",
            Cell: DateCell,
            hideable: false,
        },
        {
            accessor: "robot_type",
            Header: "Product Model",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "server_status",
            Header: "Server Status",
            Cell: DataCell,
            hideable: false,
        },
    ];

    const historicColumns: Column<NewDevice>[] = latestColumns.map((c) => {
        switch (c.accessor) {
            case "updated_at":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: TimestampColumnFilter,
                    filterLabel: "Omit data",
                    filterShouldRenderCell: true,
                };
            case "status":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: CreateTextFilter("Status"),
                    filterLabel: "Status",
                    filterShouldRenderCell: true,
                };
            case "robot_type":
                return {
                    ...c,
                    disableFilters: false,
                    Filter: CreateTextFilter("Product Model"),
                    filterLabel: "Product Model",
                    filterShouldRenderCell: true,
                };
            default:
                return c;
        }
    });

    function mapFilters(filters: Filters<NewDevice>) {
        const timestampValues =
            filters
                .find((f) => f.id === "updated_at")
                ?.value?.map(
                    (v: any) => v && (moment.isMoment(v) ? v : moment(v)).toDate()
                ) || [];
        
        const status = filters.find((f) => f.id === "status")?.value;
        const productModel = filters.find((f) => f.id === "robot_type")?.value;
    
        return {
            notBefore: timestampValues[0] || undefined,
            notAfter: timestampValues[1] || undefined,
            status: status || undefined,
            productModel: productModel || undefined,
        };
    }

    const initialStateAll: Partial<TableState<NewDevice>> = {
        sortBy: [{ id: "updated_at", desc: true }],
        pageSize: 25
    };

    const fetchData_ = useCallback(
        ({
            pageIndex,
            pageSize,
            sortBy,
            globalFilter,
            filters,
        }: NewDevicesImportantState) => {
            const importantFilters = mapFilters(filters);
            return ProductionService.getNewDevices({
                skip: pageIndex * pageSize || 0,
                limit: pageSize,
                orderBy: sortBy.map(({ id, desc }) => [
                    id as any,
                    desc ? "desc" : "asc",
                ]),
                globalSearch: globalFilter,
                ...importantFilters,
            });
        },
        []
    );

    const { value, error, pending, exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
        clearValueOnExec: false,
    });

    const { pageCount, data } = useMemo(() => {
        return {
            pageCount: value?.filteredCount ?? 0,
            data: value?.data ?? [],
        };
    }, [value]);
    return (
        <FilteredTable
            h={height}
            autoRefresh
            name={name}
            data={data}
            pageCount={pageCount}
            onStateChange={fetchData}
            mapStateChange={mapNewDevicesImportantState}
            loading={pending || (value === undefined && error === undefined)}
            columns={historicColumns}
            size="small"
            initialState={initialStateAll}
            innerRef={innerRef}
        ></FilteredTable>
    );
}