import React, { useState, useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default function PasswordField(
    props: Omit<TextFieldProps, "type" | "InputProps">
) {
    const [showPassword, setShowPassword] = useState(false);

    const toggle = useCallback(() => {
        setShowPassword((show) => !show);
    }, []);

    return (
        <TextField
            {...props}
            type={showPassword ? undefined : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={toggle}
                            disableFocusRipple
                            disableTouchRipple
                            aria-label="toggle password visibility"
                            edge="end"
                            disabled={props.disabled}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
