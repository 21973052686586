/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from "react";
import { TextField, InputAdornment, IconButton, Box, FormControlLabel, Switch, makeStyles } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { TableInstance } from "react-table";
import { useDebouncedColumnFilter } from "./ColumnFilters";
import { withInstance } from "../BasicTable";

export interface GlobalFilterProps<T extends {}> {
    instance: TableInstance<T>;
    fullWidth?: boolean;
    enableStrictSearchOption?: boolean;
}
const useStyles = makeStyles((theme) => ({
    searchField: {
        paddingRight: theme.spacing(1.5),
        
    },
    searchSwitch: {
        whiteSpace:"nowrap"
    }
   
}));
function GlobalFilter<T extends {}>({
    instance,
    fullWidth,
    enableStrictSearchOption,
}: GlobalFilterProps<T>) {
    const {
        state: { globalFilter },
        setGlobalFilter,
    } = instance;
    const classes = useStyles();

    useEffect(() =>{
        if(enableStrictSearchOption && typeof globalFilter != "object")
            setGlobalFilter({keyword: globalFilter ?? "", strict: false});
        else if(!enableStrictSearchOption &&  typeof globalFilter == "object")
            setGlobalFilter(globalFilter?.keyword === "" ? undefined : globalFilter?.keyword)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const { value, setValue } = useDebouncedColumnFilter({
        filterValue: globalFilter,
        setFilter: setGlobalFilter,
        clearedValue: enableStrictSearchOption ? {keyword: "", strict: false}:"",
        delay: 300,
    });
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(enableStrictSearchOption ? {keyword: event.target.value?? "", strict: value.strict ?? false}: event.target.value);
    };


    return (
        <Box display="inline-flex" alignItems="center" justifyContent={"space-between"}>
            
            <TextField
            fullWidth={fullWidth}
            placeholder="Search all columns..."
            label="Search"
            margin="dense"
            variant="outlined"
            value={enableStrictSearchOption ? value.keyword : value}
            onChange={onChange}
            size="small"
            className={classes.searchField}
            InputProps={{
                endAdornment: (enableStrictSearchOption ? value.keyword : value) && (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            edge="end"
                            onClick={() => {
                                setValue(enableStrictSearchOption ? {keyword:"", strict: value.strict} : "");
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            ></TextField>
            {enableStrictSearchOption && <FormControlLabel 
                    className={classes.searchSwitch}
                    control={
                        <Switch size="small" checked={value.strict} onChange={(ev) => {setValue({keyword: value.keyword, strict: ev.target.checked})}} />
                    }
                    label={`Strict search`}
                />}
        </Box>
        
    );
}

export default withInstance(
    (instance) => ({ globalFilter: instance.state.globalFilter, instance }),
    GlobalFilter
);
