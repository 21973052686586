import { Operation } from "src/services/AdministrationService";
import { BaseStation, Robot, Tablet } from "src/services/DeviceDataService";
import { HubSpotCompany, HubSpotContact } from "src/services/HubspotService";
import { Quote } from "src/services/QuoteService";
import { ConnectionState } from "../../events";
import { CustomersDto, Customer, User, AccessField } from "../../services/UserService";
export { ConnectionState };

export interface ToastMessageMeta {
    undo: {
        text: string;
        cb: () => Promise<void>;
    };
}

export interface ToastMessageCallback {
    title: string;
    callback: (state?: any) => void;
}

export type ToastSeverity =
    | "error"
    | "warning"
    | "info"
    | "success"
    | "default";

export interface ToastMessage {
    message: string;
    state?: any;
    severity?: ToastSeverity;
    actions?: ToastMessageCallback[];
    withCloseIcon?: boolean;
}

export interface DialogPrompt {
    dialogText? : string | JSX.Element;
    positiveButtonText? : string;
    negativeButtonText? : string;
    zIndex?: number;
}

export interface AppState {
    selectedTimezone: number,
    excludeDeprecated: boolean,
    users: User[],
    user: User,
    robots: Robot[],
    tablets: Tablet[],
    basestations: BaseStation[],
    operations: Operation[],
    quotes: Quote,
    contacts: HubSpotContact[],
    companies: HubSpotCompany[],
    accessFields: AccessField[],
    dialog: {
        active? : boolean;
        prompt? : DialogPrompt;
        positiveButtonAction? : () => void;
        negativeButtonAction? : () => void;
    };
    toast: {
        message?: ToastMessage;
        open: boolean;
    };
    connectionState: {
        connectedToServer: boolean;
        connectionState: ConnectionState;
    };
    // Holds the customer DTO for the active session
    // This is used by various components and utilities
    customers: {
        loaded: boolean;
        loading: boolean;
        error?: any;
        dto: CustomersDto;
    };
    googleMapsToken: string;
}

export const SET_TIMEZONE = "SET_TIMEZONE";
export const SET_EXCLUDE_DEPRECATED = "SET_EXCLUDE_DEPRECATED";

export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const ADD_USER = "ADD_USER";
export const SET_USERS = "SET_USERS";
export const SET_USER = "SET_USER";

export const SET_ROBOTS = "SET_ROBOTS";
export const SET_TABLETS = "SET_TABLETS";
export const SET_BASESTATIONS = "SET_BASESTATIONS";
export const SET_OPERATIONS = "SET_OPERATIONS";
export const SET_QUOTES = "SET_QUOTES";
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_ACCESS_FIELDS = "SET_ACCESS_FIELDS"

export const CONTROL_DIALOG = "CONTROL_DIALOG";

export const DISPLAY_TOAST = "DISPLAY_TOAST"; // Used by components
export const CONTROL_TOAST = "CONTROL_TOAST"; // Used by middleware

export const SET_CONNECTION_STATE = "SET_CONNECTION_STATE";

export const LOAD_CUSTOMERS_DTO = "LOAD_CUSTOMERS_DTO";
export const LOADING_CUSTOMERS_DTO = "LOADING_CUSTOMERS_DTO";
export const SET_CUSTOMERS_DTO = "SET_CUSTOMERS_DTO";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export const SET_GOOGLE_MAPS_TOKEN="SET_GOOGLE_MAPS_TOKEN"

export interface SetGoogleMapsToken{
    type: typeof SET_GOOGLE_MAPS_TOKEN;
    payload: string;
}

export interface SetTimezoneAction {
    type: typeof SET_TIMEZONE;
    payload: {timezone: number};
}

export interface SetExcludeDeprecatedAction {
    type: typeof SET_EXCLUDE_DEPRECATED;
    payload: {excludeDeprecated: boolean};
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER;
    payload: {username: string, user: User};
}

export interface RemoveUserAction {
    type: typeof REMOVE_USER;
    payload: User;
}

export interface AddUserAction {
    type: typeof ADD_USER;
    payload: User;
}

export interface SetUsersAction {
    type: typeof SET_USERS;
    payload: User[];
}

export interface SetUserAction {
    type: typeof SET_USER;
    payload: User;
}

export interface SetRobotsAction {
    type: typeof SET_ROBOTS;
    payload: Robot[];
}

export interface SetTabletsAction {
    type: typeof SET_TABLETS;
    payload: Tablet[];
}

export interface SetBaseStationsAction {
    type: typeof SET_BASESTATIONS;
    payload: BaseStation[];
}

export interface SetOperationsAction {
    type: typeof SET_OPERATIONS;
    payload: Operation[];
}

export interface SetQuotesAction {
    type: typeof SET_QUOTES;
    payload: Quote;
}


export interface SetContactsAction {
    type: typeof SET_CONTACTS;
    payload: HubSpotContact[];
}

export interface SetCompaniesAction {
    type: typeof SET_COMPANIES;
    payload: HubSpotCompany[];
}

export interface SetAccessFieldsAction {
    type: typeof SET_ACCESS_FIELDS;
    payload: AccessField[];
}

export interface ControlDialogAction {
    type: typeof CONTROL_DIALOG;
    payload: {
        active? : boolean;
        prompt? : DialogPrompt;
        positiveButtonAction? : () => void;
        negativeButtonAction? : () => void;
    };
}

export interface DisplayToastAction {
    type: typeof DISPLAY_TOAST;
    payload: ToastMessage;
}

export interface ControlToastAction {
    type: typeof CONTROL_TOAST;
    payload: { open: boolean; message?: ToastMessage };
}

export interface SetConnectionStateAction {
    type: typeof SET_CONNECTION_STATE;
    payload: {
        connectionState: ConnectionState;
    };
}

export interface LoadCustomersDtoAction {
    type: typeof LOAD_CUSTOMERS_DTO;
}

export interface LoadingCustomersDtoAction {
    type: typeof LOADING_CUSTOMERS_DTO;
}

export interface SetCustomersDtoAction {
    type: typeof SET_CUSTOMERS_DTO;
    payload: {
        dto: CustomersDto;
        error?: any;
    };
}

export interface CreateCustomerAction {
    type: typeof CREATE_CUSTOMER;
    payload: Customer;
}

export interface DeleteCustomerAction {
    type: typeof DELETE_CUSTOMER;
    payload: {id: number;};
}

export type AppAction =
    | SetTimezoneAction
    | SetExcludeDeprecatedAction
    | UpdateUserAction
    | RemoveUserAction
    | AddUserAction
    | SetUsersAction
    | SetUserAction
    | SetRobotsAction
    | SetTabletsAction
    | SetBaseStationsAction
    | SetOperationsAction
    | SetQuotesAction
    | SetAccessFieldsAction
    | ControlDialogAction
    | DisplayToastAction
    | ControlToastAction
    | SetConnectionStateAction
    | LoadCustomersDtoAction
    | LoadingCustomersDtoAction
    | SetCustomersDtoAction
    | CreateCustomerAction
    | DeleteCustomerAction
    | SetContactsAction
    | SetCompaniesAction
    | SetGoogleMapsToken