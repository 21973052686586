import React from 'react';
import Switch from '@material-ui/core/Switch';

class SectionCollapse extends React.Component {
  constructor(props) {
    super(props);

    this.headerText = props.header;
    this.content = props.content;
    this.state = JSON.parse(window.localStorage.getItem(this.headerText + "_showContent")) || {
      showContent: false
    }

  }

  setState(state) {
    window.localStorage.setItem(this.headerText + "_showContent", JSON.stringify(state));
    super.setState(state);
  }

  render() {
    return (<>
      <h1 onClick={e => this.setState({ showContent: !this.state.showContent })}><Switch checked={this.state.showContent} onChange={event => this.setState({ showContent: event.target.checked })} name="checkedCB" />{this.headerText}</h1>
      <div style={{ display: (this.state.showContent ? "" : "none") }} >{this.content}</div>
    </>
    );
  }
}

export default SectionCollapse;