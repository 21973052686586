/* eslint-disable @typescript-eslint/ban-types */
import React, { PropsWithChildren, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import _MuiTablePagination, { LabelDisplayedRowsArgs } from "@material-ui/core/TablePagination";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { TableInstance, TableState, useGetLatest } from "react-table";
import { useDidMountCheck } from "src/hooks";

const useStyles = makeStyles((theme) => ({
    paginationActionsRoot: {
        alignSelf: "center",
        display: "flex",
        flexShrink: 0,
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.up(780)]: {
            marginLeft: theme.spacing(2.5),
        },
        "& > :first-child, & > :last-child": {
            display: "none",
            [theme.breakpoints.up(780)]: {
                display: "unset",
            },
        },
    },
    paginationBar: {
        "& > :first-child": {
            [theme.breakpoints.down(780)]: {
                paddingLeft: theme.spacing(1),
                paddingRight: 0,
            },
        },
    },
}));

// avoid all of the redraws caused by the internal withStyles
const interestingPropsEqual = (prevProps: any, nextProps: any) =>
    prevProps.count === nextProps.count &&
    prevProps.rowsPerPage === nextProps.rowsPerPage &&
    prevProps.page === nextProps.page &&
    prevProps.onChangePage === nextProps.onChangePage &&
    prevProps.onChangeRowsPerPage === nextProps.onChangeRowsPerPage;

// a bit of a type hack to keep OverridableComponent working as desired
type T = typeof _MuiTablePagination;
const MuiTablePagination: T = React.memo(
    _MuiTablePagination,
    interestingPropsEqual
) as T;

interface PaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}

function PaginationActions(props: PaginationActionsProps) {
    const { count, page, rowsPerPage, onChangePage } = props;
    const classes = useStyles();

    const handleFirstPageButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onChangePage(event, 0);
        },
        [onChangePage]
    );

    const handleBackButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onChangePage(event, page - 1);
        },
        [onChangePage, page]
    );

    const handleNextButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onChangePage(event, page + 1);
        },
        [onChangePage, page]
    );

    const handleLastPageButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onChangePage(
                event,
                Math.max(0, Math.ceil(count / rowsPerPage) - 1)
            );
        },
        [onChangePage, count, rowsPerPage]
    );

    return (
        <div className={classes.paginationActionsRoot}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
}

function DisplayRowsLabel({ count, from, to }: LabelDisplayedRowsArgs) {
    return (
        <>
            <Hidden xsDown>
                {to === -1 ? (
                    <>1 to {count}</>
                ) : (
                    <>
                        {from}-{to} of {count}
                    </>
                )}
            </Hidden>
            <Hidden smUp>
                {to === -1 ? (
                    <>1 to {count}</>
                ) : (
                    <>
                        {from}-{to}
                    </>
                )}
            </Hidden>
        </>
    );
}

export type PaginationProps<K extends {}> = PropsWithChildren<{
    instance: TableInstance<K>;
    rowsPerPageOptions: (number | { value: number; label: string })[];
}>;

export default function Pagination<K extends {}>({
    instance,
    rowsPerPageOptions,
}: PaginationProps<K>) {
    const classes = useStyles();
    const { state, gotoPage, setPageSize } = instance;

    useDidMountCheck("Pagination");

    const totalCount = instance.data.length;
    const rowCount = instance.manualPagination
        ? instance.pageCount
        : instance.rows.length;

    const { pageIndex, pageSize } = state as TableState;

    const handleChangePage = useCallback(
        (
            _ev: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
            newPage: number
        ) => {
            gotoPage(newPage);
        },
        [gotoPage]
    );

    const getTotalCount = useGetLatest(totalCount);
    const handleChangePageSize = useCallback(
        (e) => {
            e.preventDefault();
            const value = Number(e.target.value);
            if (value === -1) {
                return setPageSize(getTotalCount());
            }
            setPageSize(value);
        },
        [getTotalCount, setPageSize]
    );

    if (!rowCount)
        return null;

    return <MuiTablePagination
        className={classes.paginationBar}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rowCount}
        rowsPerPage={(!instance.manualPagination && pageSize === totalCount) ? -1 : pageSize}
        page={(rowCount < pageSize || (!instance.manualPagination && pageSize === totalCount)) ? 0 : pageIndex}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangePageSize}
        ActionsComponent={PaginationActions}
        labelRowsPerPage="Page size:"
        labelDisplayedRows={DisplayRowsLabel}
        SelectProps={{
            MenuProps: { disablePortal: true },
        }}
    />;
}
