import React from "react";
import Fade from "@material-ui/core/Fade";
import { useParams } from "react-router-dom";

import Dashboard from "./Dashboard";
import { deviceTypeStringToId } from "src/utils/common";

interface DashboardParams {
    device: "tablets" | "robots" | string;
    deviceId: string;
}

export default function DashboardIndex() {
    const { device, deviceId: inputId } = useParams<DashboardParams>();

    const deviceType = deviceTypeStringToId(device);
    const deviceId = +inputId;
    const fadeKey = `DashboardFade-${deviceType}/${deviceId}`;

    return (
        <Fade in timeout={450} key={fadeKey}>
            <div>
                <Dashboard
                    key={device}
                    deviceTypeId={deviceType}
                    deviceId={deviceId}
                />
            </div>
        </Fade>
    );
}
