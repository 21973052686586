import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { formatAsMoney } from "src/utils/common";
import { SavingsInput } from "./Savings";

export interface SavingsState extends SavingsInput{
    pdf: Blob;
}
const currencySign=(currency:number)=>{
    switch(currency){
        case 1: return "$"
        default: return '€'
    }
}

export async function generateSavings(args:SavingsState) {
    const initAllFields=args.largeFields+args.mediumFields+args.smallFields+args.otherFields
    const initInitSumm=args.largeInit+args.mediumInit+args.smallInit+args.otherInit
    const initHoursSumm=args.largeInitTime+args.mediumInitTime+args.smallInitTime+args.otherInitTime

    const initSmallTimeTotal=args.smallFields*args.smallInit*args.smallInitTime;
    const initMediumTimeTotal=args.mediumFields*args.mediumInit*args.mediumInitTime;
    const initLargeTimeTotal=args.largeFields*args.largeInit*args.largeInitTime;
    const initOtherTimeTotal=args.otherFields*args.otherInit*args.otherInitTime;

    const initSmallCost=initSmallTimeTotal*args.trueWage
    const initMediumCost=initMediumTimeTotal*args.trueWage
    const initLargeCost=initLargeTimeTotal*args.trueWage
    const initOtherCost=initOtherTimeTotal*args.trueWage

    const initTotalHours=initSmallTimeTotal+initMediumTimeTotal+initLargeTimeTotal+initOtherTimeTotal
    const initotalCost=initTotalHours*args.trueWage


    const reAllFields=args.reLargeFields+args.reMediumFields+args.reSmallFields+args.reOtherFields
    const reSessionsSumm=args.reLargeFieldsSessions+args.reMediumFieldsSessions+args.reSmallFieldsSessions+args.reOtherFieldsSessions
    const reHoursSum=args.reLargeFieldsTime+args.reMediumFieldsTime+args.reSmallFieldsTime+args.reOtherFieldsTime

    const reSmallTimeTotal=args.reSmallFields*args.reSmallFieldsSessions*args.reSmallFieldsTime
    const reMediumTimeTotal=args.reMediumFields*args.reMediumFieldsSessions*args.reMediumFieldsTime
    const reLargeTimeTotal=args.reLargeFields*args.reLargeFieldsSessions*args.reLargeFieldsTime
    const reOtherTimeTotal=args.reOtherFields*args.reOtherFieldsSessions*args.reOtherFieldsTime

    const reSmallCost=reSmallTimeTotal*args.trueWage
    const reMediumCost=reMediumTimeTotal*args.trueWage
    const reLargeCost=reLargeTimeTotal*args.trueWage
    const reOtherCost=reOtherTimeTotal*args.trueWage

    const reTotalHours=reLargeTimeTotal+reOtherTimeTotal+reSmallTimeTotal+reMediumTimeTotal
    const reTotalHoursTmr=reTotalHours*0.3
    const reTotalCost=reTotalHours*args.trueWage
    const reTotalCostTmr=reTotalHoursTmr*args.trueWage


    const smallTotalHours=initSmallTimeTotal+reSmallTimeTotal
    const mediumTotalHours=initMediumTimeTotal+reMediumTimeTotal
    const largeTotalHours=initLargeTimeTotal+reLargeTimeTotal
    const otherTotalHours=initOtherTimeTotal+reOtherTimeTotal

    const smallTotalCost=initSmallCost+reSmallCost
    const mediumTotalCost=initMediumCost+reMediumCost
    const largeTotalCost=initLargeCost+reLargeCost
    const otherTotalCost=initOtherCost+reOtherCost

    const totalTime=smallTotalHours+mediumTotalHours+largeTotalHours+otherTotalHours
    const totalCost=totalTime*args.trueWage


    const smallPaintSessions=args.smallFields*args.smallInit+args.reSmallFields*args.reSmallFieldsSessions
    const mediumPaintSessions=args.mediumFields*args.mediumInit+args.reMediumFields*args.reMediumFieldsSessions
    const largePaintSessions=args.largeFields*args.largeInit+args.reLargeFields*args.reLargeFieldsSessions
    const otherPaintSessions=args.otherFields*args.otherInit+args.reOtherFields*args.reOtherFieldsSessions


    const smallTotalPaintManual=smallPaintSessions*args.smallFieldsGalonsManual
    const mediumTotalPaintManual=mediumPaintSessions*args.mediumFieldsGalonsManual
    const largeTotalPaintManual=largePaintSessions*args.largeFieldsGalonsManual
    const otherTotalPaintManual=otherPaintSessions*args.otherFieldsGalonsManual

    const smallTotalPaintTmr=smallPaintSessions*args.smallFieldsGalonsTmr
    const mediumTotalPaintTmr=mediumPaintSessions*args.mediumFieldsGalonsTmr
    const largeTotalPaintTmr=largePaintSessions*args.largeFieldsGalonsTmr
    const otherTotalPaintTmr=otherPaintSessions*args.otherFieldsGalonsTmr

    const totalPaintManual=smallTotalPaintManual+mediumTotalPaintManual+largeTotalPaintManual+otherTotalPaintManual
    const totalPaintTmr=smallTotalPaintTmr+mediumTotalPaintTmr+largeTotalPaintTmr+otherTotalPaintTmr
    // const totalPaintManualAnnual=args.reSmallFields*args.reSmallFieldsSessions*args.smallFieldsGalonsManual+
    // args.reMediumFields*args.reMediumFieldsSessions*args.mediumFieldsGalonsManual+
    // args.reLargeFields*args.reLargeFieldsSessions*args.largeFieldsGalonsManual+
    // args.reOtherFields*args.reOtherFieldsSessions*args.otherFieldsGalonsManual;
    // const totalPaintTmrAnnual=args.reSmallFields*args.reSmallFieldsSessions*args.smallFieldsGalonsTmr+
    // args.reMediumFields*args.reMediumFieldsSessions*args.mediumFieldsGalonsTmr+
    // args.reLargeFields*args.reLargeFieldsSessions*args.largeFieldsGalonsTmr+
    // args.reOtherFields*args.reOtherFieldsSessions*args.otherFieldsGalonsTmr;

    const pdfCover = await PDFDocument.load(await args.pdf.arrayBuffer());
    const mergedPdf = await PDFDocument.create();
    (await mergedPdf.copyPages(pdfCover, pdfCover.getPageIndices())).forEach((page) => mergedPdf.addPage(page));
    const font = await mergedPdf.embedFont(StandardFonts.Helvetica);
    const isDot=false
    //first page
    mergedPdf.getPage(0).drawText((args.customer),{x:665-font.widthOfTextAtSize((args.customer),32)/2,y:482,size:32})
    mergedPdf.getPage(0).drawText((args.customer),{x:665-font.widthOfTextAtSize((args.customer),32)/2,y:482,size:32})


    mergedPdf.getPage(0).drawText(((totalTime-reTotalHoursTmr).toFixed(1)),{x:245-font.widthOfTextAtSize(((totalTime-reTotalHoursTmr).toFixed(1)), 18)/2,y:328,size:18,color: rgb(0.2,0.6,0.2),})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney((totalTime-reTotalHoursTmr)*args.trueWage,isDot),{x:245-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney((totalTime-reTotalHoursTmr)*args.trueWage,isDot), 18)/2,y:277,size:18,color: rgb(0.4,0.6,0.3)})
    mergedPdf.getPage(0).drawText(((totalPaintManual-totalPaintTmr).toFixed(1)),{x:775-font.widthOfTextAtSize(((totalPaintManual-totalPaintTmr).toFixed(1)), 18)/2,y:328,size:18,color: rgb(0.2,0.6,0.2)})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney((totalPaintManual-totalPaintTmr)*args.paintCost,isDot),{x:775-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney((totalPaintManual-totalPaintTmr)*args.paintCost,isDot), 18)/2,y:277,size:18,color: rgb(0.4,0.6,0.3)})
    mergedPdf.getPage(0).drawText(((totalTime-reTotalHoursTmr).toFixed(1)),{x:245-font.widthOfTextAtSize(((totalTime-reTotalHoursTmr).toFixed(1)), 18)/2,y:328,size:18,color: rgb(0.2,0.6,0.2),})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney((totalTime-reTotalHoursTmr)*args.trueWage,isDot),{x:245-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney((totalTime-reTotalHoursTmr)*args.trueWage,isDot), 18)/2,y:277,size:18,color: rgb(0.4,0.6,0.3)})
    mergedPdf.getPage(0).drawText(((totalPaintManual-totalPaintTmr).toFixed(1)),{x:775-font.widthOfTextAtSize(((totalPaintManual-totalPaintTmr).toFixed(1)), 18)/2,y:328,size:18,color: rgb(0.2,0.6,0.2)})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney((totalPaintManual-totalPaintTmr)*args.paintCost,isDot),{x:775-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney((totalPaintManual-totalPaintTmr)*args.paintCost,isDot), 18)/2,y:277,size:18,color: rgb(0.4,0.6,0.3)})


    mergedPdf.getPage(0).drawText((totalTime.toFixed(1)),{x:455-font.widthOfTextAtSize((totalTime.toFixed(1)),12),y:167,size:12})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney(totalCost,isDot),{x:455-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalCost,isDot),12),y:149,size:12})
    mergedPdf.getPage(0).drawText((totalPaintManual.toFixed(1)),{x:455-font.widthOfTextAtSize((totalPaintManual.toFixed(1)),12),y:130,size:12})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney(totalPaintManual*args.paintCost,isDot),{x:455-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalPaintManual*args.paintCost,isDot),12),y:110,size:12})

    mergedPdf.getPage(0).drawText((reTotalHoursTmr.toFixed(1)),{x:980-font.widthOfTextAtSize((reTotalHoursTmr.toFixed(1)),12),y:167,size:12})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),{x:980-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),12),y:149,size:12})
    mergedPdf.getPage(0).drawText((totalPaintTmr.toFixed(1)),{x:980-font.widthOfTextAtSize((totalPaintTmr.toFixed(1)),12),y:130,size:12})
    mergedPdf.getPage(0).drawText(currencySign(args.currency)+formatAsMoney(totalPaintTmr*args.paintCost,isDot),{x:980-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalPaintTmr*args.paintCost,isDot),12),y:110,size:12})
    //second page 1 left table

    mergedPdf.getPage(1).drawText((initTotalHours.toFixed(1)),{x:230-font.widthOfTextAtSize((initTotalHours.toFixed(1)),8)/2,y:446,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(initotalCost,isDot),{x:230-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(initotalCost,isDot),8)/2,y:434,size:8})

    mergedPdf.getPage(1).drawText((reTotalHours.toFixed(1)),{x:230-font.widthOfTextAtSize((reTotalHours.toFixed(1)),8)/2,y:374,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reTotalCost,isDot),{x:230-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reTotalCost,isDot),8)/2,y:363,size:8})
    mergedPdf.getPage(1).drawText("3.3 Times Faster",{x:310-font.widthOfTextAtSize("3.3 Times Faster",8)/2,y:374,size:8})
    mergedPdf.getPage(1).drawText("3.3 Times Faster",{x:310-font.widthOfTextAtSize("3.3 Times Faster",8)/2,y:374,size:8})
    mergedPdf.getPage(1).drawText((reTotalHoursTmr.toFixed(1)),{x:390-font.widthOfTextAtSize((reTotalHoursTmr.toFixed(1)),8)/2,y:374,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),{x:390-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),8)/2,y:363,size:8})
    
    mergedPdf.getPage(1).drawText((totalTime.toFixed(1)),{x:315-font.widthOfTextAtSize((totalTime.toFixed(1)),10)/2,y:325,size:10})
    mergedPdf.getPage(1).drawText((reTotalHoursTmr.toFixed(1)),{x:315-font.widthOfTextAtSize((reTotalHoursTmr.toFixed(1)),10)/2,y:315,size:10})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(totalCost,isDot),{x:315-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalCost,isDot),10)/2,y:278,size:10})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),{x:315-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),10)/2,y:266,size:10})
    mergedPdf.getPage(1).drawText((totalTime.toFixed(1)),{x:315-font.widthOfTextAtSize((totalTime.toFixed(1)),10)/2,y:325,size:10})
    mergedPdf.getPage(1).drawText((reTotalHoursTmr.toFixed(1)),{x:315-font.widthOfTextAtSize((reTotalHoursTmr.toFixed(1)),10)/2,y:315,size:10})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(totalCost,isDot),{x:315-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalCost,isDot),10)/2,y:278,size:10})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),{x:315-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reTotalCostTmr,isDot),10)/2,y:266,size:10})

    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(args.baseWage,isDot),{x:390-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(args.baseWage,isDot),10)/2,y:231,size:10})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(args.trueWage,isDot),{x:390-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(args.trueWage,isDot),10)/2,y:218,size:10})
    //second page 2 left table
    mergedPdf.getPage(1).drawText((args.customer),{x:665-font.widthOfTextAtSize((args.customer),24)/2,y:538,size:24})
    mergedPdf.getPage(1).drawText((args.customer),{x:665-font.widthOfTextAtSize((args.customer),24)/2,y:538,size:24})


    mergedPdf.getPage(1).drawText((totalPaintManual.toFixed(1)),{x:230-font.widthOfTextAtSize((totalPaintManual.toFixed(1)),8)/2,y:118,size:8})
    mergedPdf.getPage(1).drawText((totalPaintTmr.toFixed(1)),{x:390-font.widthOfTextAtSize((totalPaintTmr.toFixed(1)),8)/2,y:118,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(args.paintCost,isDot),{x:230-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(args.paintCost,isDot),8)/2,y:70,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(args.paintCost,isDot),{x:390-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(args.paintCost,isDot),8)/2,y:70,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(totalPaintManual*args.paintCost,isDot),{x:230-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalPaintManual*args.paintCost,isDot),8)/2,y:58,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(totalPaintTmr*args.paintCost,isDot),{x:390-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalPaintTmr*args.paintCost,isDot),8)/2,y:58,size:8})

    //second page 1 right table
    mergedPdf.getPage(1).drawText((args.smallFields.toFixed(1)),{x:642-font.widthOfTextAtSize((args.smallFields.toFixed(1)),8)/2,y:446,size:8})
    mergedPdf.getPage(1).drawText((args.smallInit.toFixed(1)),{x:642-font.widthOfTextAtSize((args.smallInit.toFixed(1)),8)/2,y:434,size:8})
    mergedPdf.getPage(1).drawText((args.smallInitTime.toFixed(1)),{x:642-font.widthOfTextAtSize((args.smallInitTime.toFixed(1)),8)/2,y:422,size:8})
    mergedPdf.getPage(1).drawText((initSmallTimeTotal.toFixed(1)),{x:642-font.widthOfTextAtSize((initSmallTimeTotal.toFixed(1)),8)/2,y:398,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(initSmallCost,isDot),{x:642-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(initSmallCost,isDot),8)/2,y:386,size:8})

    mergedPdf.getPage(1).drawText((args.mediumFields.toFixed(1)),{x:700-font.widthOfTextAtSize((args.mediumFields.toFixed(1)),8)/2,y:446,size:8})
    mergedPdf.getPage(1).drawText((args.mediumInit.toFixed(1)),{x:700-font.widthOfTextAtSize((args.mediumInit.toFixed(1)),8)/2,y:434,size:8})
    mergedPdf.getPage(1).drawText((args.mediumInitTime.toFixed(1)),{x:700-font.widthOfTextAtSize((args.mediumInitTime.toFixed(1)),8)/2,y:422,size:8})
    mergedPdf.getPage(1).drawText((initMediumTimeTotal.toFixed(1)),{x:700-font.widthOfTextAtSize((initMediumTimeTotal.toFixed(1)),8)/2,y:398,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(initMediumCost,isDot),{x:700-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(initMediumCost,isDot),8)/2,y:386,size:8})

    mergedPdf.getPage(1).drawText((args.largeFields.toFixed(1)),{x:761-font.widthOfTextAtSize((args.largeFields.toFixed(1)),8)/2,y:446,size:8})
    mergedPdf.getPage(1).drawText((args.largeInit.toFixed(1)),{x:761-font.widthOfTextAtSize((args.largeInit.toFixed(1)),8)/2,y:434,size:8})
    mergedPdf.getPage(1).drawText((args.largeInitTime.toFixed(1)),{x:761-font.widthOfTextAtSize((args.largeInitTime.toFixed(1)),8)/2,y:422,size:8})
    mergedPdf.getPage(1).drawText((initLargeTimeTotal.toFixed(1)),{x:761-font.widthOfTextAtSize((initLargeTimeTotal.toFixed(1)),8)/2,y:398,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(initLargeCost,isDot),{x:761-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(initLargeCost,isDot),8)/2,y:386,size:8})

    mergedPdf.getPage(1).drawText((args.otherFields.toFixed(1)),{x:825-font.widthOfTextAtSize((args.otherFields.toFixed(1)),8)/2,y:446,size:8})
    mergedPdf.getPage(1).drawText((args.otherInit.toFixed(1)),{x:825-font.widthOfTextAtSize((args.otherInit.toFixed(1)),8)/2,y:434,size:8})
    mergedPdf.getPage(1).drawText((args.otherInitTime.toFixed(1)),{x:825-font.widthOfTextAtSize((args.otherInitTime.toFixed(1)),8)/2,y:422,size:8})
    mergedPdf.getPage(1).drawText((initOtherTimeTotal.toFixed(1)),{x:825-font.widthOfTextAtSize((initOtherTimeTotal.toFixed(1)),8)/2,y:398,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(initOtherCost,isDot),{x:825-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(initOtherCost,isDot),8)/2,y:386,size:8})

    mergedPdf.getPage(1).drawText((initAllFields.toFixed(1)),{x:952-font.widthOfTextAtSize((initAllFields.toFixed(1)),8)/2,y:446,size:8})
    mergedPdf.getPage(1).drawText((initInitSumm.toFixed(1)),{x:952-font.widthOfTextAtSize((initInitSumm.toFixed(1)),8)/2,y:434,size:8})
    mergedPdf.getPage(1).drawText((initHoursSumm.toFixed(1)),{x:952-font.widthOfTextAtSize((initHoursSumm.toFixed(1)),8)/2,y:422,size:8})
    mergedPdf.getPage(1).drawText((initTotalHours.toFixed(1)),{x:952-font.widthOfTextAtSize((initSmallTimeTotal.toFixed(1)),8)/2,y:398,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(initotalCost,isDot),{x:952-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(initotalCost,isDot),8)/2,y:386,size:8})


    mergedPdf.getPage(1).drawText((args.reSmallFields.toFixed(1)),{x:642-font.widthOfTextAtSize((args.reSmallFields.toFixed(1)),8)/2,y:326,size:8})
    mergedPdf.getPage(1).drawText((args.reSmallFieldsSessions.toFixed(1)),{x:642-font.widthOfTextAtSize((args.reSmallFieldsSessions.toFixed(1)),8)/2,y:314,size:8})
    mergedPdf.getPage(1).drawText((args.reSmallFieldsTime.toFixed(1)),{x:642-font.widthOfTextAtSize((args.reSmallFieldsTime.toFixed(1)),8)/2,y:302,size:8})
    mergedPdf.getPage(1).drawText((reSmallTimeTotal.toFixed(1)),{x:642-font.widthOfTextAtSize((reSmallTimeTotal.toFixed(1)),8)/2,y:278,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reSmallCost,isDot),{x:642-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reSmallCost,isDot),8)/2,y:266,size:8})

    mergedPdf.getPage(1).drawText((args.reMediumFields.toFixed(1)),{x:700-font.widthOfTextAtSize((args.reMediumFields.toFixed(1)),8)/2,y:326,size:8})
    mergedPdf.getPage(1).drawText((args.reMediumFieldsSessions.toFixed(1)),{x:700-font.widthOfTextAtSize((args.reLargeFieldsSessions.toFixed(1)),8)/2,y:314,size:8})
    mergedPdf.getPage(1).drawText((args.reMediumFieldsTime.toFixed(1)),{x:700-font.widthOfTextAtSize((args.reMediumFieldsTime.toFixed(1)),8)/2,y:302,size:8})
    mergedPdf.getPage(1).drawText((reMediumTimeTotal.toFixed(1)),{x:700-font.widthOfTextAtSize((reMediumTimeTotal.toFixed(1)),8)/2,y:278,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reMediumCost,isDot),{x:700-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reMediumCost,isDot),8)/2,y:266,size:8})

    mergedPdf.getPage(1).drawText((args.reLargeFields.toFixed(1)),{x:761-font.widthOfTextAtSize((args.reLargeFields.toFixed(1)),8)/2,y:326,size:8})
    mergedPdf.getPage(1).drawText((args.reLargeFieldsSessions.toFixed(1)),{x:761-font.widthOfTextAtSize((args.reLargeFieldsSessions.toFixed(1)),8)/2,y:314,size:8})
    mergedPdf.getPage(1).drawText((args.reLargeFieldsTime.toFixed(1)),{x:761-font.widthOfTextAtSize((args.reLargeFieldsTime.toFixed(1)),8)/2,y:302,size:8})
    mergedPdf.getPage(1).drawText((reLargeTimeTotal.toFixed(1)),{x:761-font.widthOfTextAtSize((reLargeTimeTotal.toFixed(1)),8)/2,y:278,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reLargeCost,isDot),{x:761-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reLargeCost,isDot),8)/2,y:266,size:8})

    mergedPdf.getPage(1).drawText((args.reOtherFields.toFixed(1)),{x:825-font.widthOfTextAtSize((args.reOtherFields.toFixed(1)),8)/2,y:326,size:8})
    mergedPdf.getPage(1).drawText((args.reOtherFieldsSessions.toFixed(1)),{x:825-font.widthOfTextAtSize((args.reOtherFieldsSessions.toFixed(1)),8)/2,y:314,size:8})
    mergedPdf.getPage(1).drawText((args.reOtherFieldsTime.toFixed(1)),{x:825-font.widthOfTextAtSize((args.reOtherFieldsTime.toFixed(1)),8)/2,y:302,size:8})
    mergedPdf.getPage(1).drawText((reOtherTimeTotal.toFixed(1)),{x:825-font.widthOfTextAtSize((reOtherTimeTotal.toFixed(1)),8)/2,y:278,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reOtherCost,isDot),{x:825-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reOtherCost,isDot),8)/2,y:266,size:8})

    mergedPdf.getPage(1).drawText((reAllFields.toFixed(1)),{x:952-font.widthOfTextAtSize((reAllFields.toFixed(1)),8)/2,y:326,size:8})
    mergedPdf.getPage(1).drawText((reSessionsSumm.toFixed(1)),{x:952-font.widthOfTextAtSize((reSessionsSumm.toFixed(1)),8)/2,y:314,size:8})
    mergedPdf.getPage(1).drawText((reHoursSum.toFixed(1)),{x:952-font.widthOfTextAtSize((reHoursSum.toFixed(1)),8)/2,y:302,size:8})
    mergedPdf.getPage(1).drawText((reTotalHours.toFixed(1)),{x:952-font.widthOfTextAtSize((reTotalHours.toFixed(1)),8)/2,y:278,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(reTotalCost,isDot),{x:952-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(reTotalCost,isDot),8)/2,y:266,size:8})


    mergedPdf.getPage(1).drawText((smallTotalHours.toFixed(1)),{x:642-font.widthOfTextAtSize((smallTotalHours.toFixed(1)),8)/2,y:206,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(smallTotalCost,isDot),{x:642-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(smallTotalCost,isDot),8)/2,y:192,size:8})

    mergedPdf.getPage(1).drawText((mediumTotalHours.toFixed(1)),{x:700-font.widthOfTextAtSize((mediumTotalHours.toFixed(1)),8)/2,y:206,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(mediumTotalCost,isDot),{x:700-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(mediumTotalCost,isDot),8)/2,y:192,size:8})
    
    mergedPdf.getPage(1).drawText((largeTotalHours.toFixed(1)),{x:761-font.widthOfTextAtSize((largeTotalHours.toFixed(1)),8)/2,y:206,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(largeTotalCost,isDot),{x:761-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(largeTotalCost,isDot),8)/2,y:192,size:8})

    mergedPdf.getPage(1).drawText((otherTotalHours.toFixed(1)),{x:825-font.widthOfTextAtSize((otherTotalHours.toFixed(1)),8)/2,y:206,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(otherTotalCost,isDot),{x:825-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(otherTotalCost,isDot),8)/2,y:192,size:8})

    mergedPdf.getPage(1).drawText((totalTime.toFixed(1)),{x:952-font.widthOfTextAtSize((totalTime.toFixed(1)),8)/2,y:206,size:8})
    mergedPdf.getPage(1).drawText(currencySign(args.currency)+formatAsMoney(totalCost,isDot),{x:952-font.widthOfTextAtSize(currencySign(args.currency)+formatAsMoney(totalCost,isDot),8)/2,y:192,size:8})

    // second page 2 table
    mergedPdf.getPage(1).drawText((smallPaintSessions.toFixed(1)),{x:642-font.widthOfTextAtSize((smallPaintSessions.toFixed(1)),8)/2,y:118,size:8})
    mergedPdf.getPage(1).drawText((args.smallFieldsGalonsManual.toFixed(1)),{x:642-font.widthOfTextAtSize((args.smallFieldsGalonsManual.toFixed(1)),8)/2,y:106,size:8})
    mergedPdf.getPage(1).drawText((smallTotalPaintManual.toFixed(1)),{x:642-font.widthOfTextAtSize((smallTotalPaintManual.toFixed(1)),8)/2,y:94,size:8})
    mergedPdf.getPage(1).drawText((args.smallFieldsGalonsTmr.toFixed(1)),{x:642-font.widthOfTextAtSize((args.smallFieldsGalonsTmr.toFixed(1)),8)/2,y:70,size:8})
    mergedPdf.getPage(1).drawText((smallTotalPaintTmr.toFixed(1)),{x:642-font.widthOfTextAtSize((smallTotalPaintTmr.toFixed(1)),8)/2,y:58,size:8})

    mergedPdf.getPage(1).drawText((mediumPaintSessions.toFixed(1)),{x:700-font.widthOfTextAtSize((mediumPaintSessions.toFixed(1)),8)/2,y:118,size:8})
    mergedPdf.getPage(1).drawText((args.mediumFieldsGalonsManual.toFixed(1)),{x:700-font.widthOfTextAtSize((args.mediumFieldsGalonsManual.toFixed(1)),8)/2,y:106,size:8})
    mergedPdf.getPage(1).drawText((mediumTotalPaintManual.toFixed(1)),{x:700-font.widthOfTextAtSize((mediumTotalPaintManual.toFixed(1)),8)/2,y:94,size:8})
    mergedPdf.getPage(1).drawText((args.mediumFieldsGalonsTmr.toFixed(1)),{x:700-font.widthOfTextAtSize((args.mediumFieldsGalonsTmr.toFixed(1)),8)/2,y:70,size:8})
    mergedPdf.getPage(1).drawText((mediumTotalPaintTmr.toFixed(1)),{x:700-font.widthOfTextAtSize((mediumTotalPaintTmr.toFixed(1)),8)/2,y:58,size:8})

    mergedPdf.getPage(1).drawText((largePaintSessions.toFixed(1)),{x:761-font.widthOfTextAtSize((largePaintSessions.toFixed(1)),8)/2,y:118,size:8})
    mergedPdf.getPage(1).drawText((args.largeFieldsGalonsManual.toFixed(1)),{x:761-font.widthOfTextAtSize((args.largeFieldsGalonsManual.toFixed(1)),8)/2,y:106,size:8})
    mergedPdf.getPage(1).drawText((mediumTotalPaintManual.toFixed(1)),{x:761-font.widthOfTextAtSize((mediumTotalPaintManual.toFixed(1)),8)/2,y:94,size:8})
    mergedPdf.getPage(1).drawText((args.largeFieldsGalonsTmr.toFixed(1)),{x:761-font.widthOfTextAtSize((args.largeFieldsGalonsTmr.toFixed(1)),8)/2,y:70,size:8})
    mergedPdf.getPage(1).drawText((largeTotalPaintTmr.toFixed(1)),{x:761-font.widthOfTextAtSize((largeTotalPaintTmr.toFixed(1)),8)/2,y:58,size:8})

    mergedPdf.getPage(1).drawText((otherPaintSessions.toFixed(1)),{x:825-font.widthOfTextAtSize((otherPaintSessions.toFixed(1)),8)/2,y:118,size:8})
    mergedPdf.getPage(1).drawText((args.otherFieldsGalonsManual.toFixed(1)),{x:825-font.widthOfTextAtSize((args.otherFieldsGalonsManual.toFixed(1)),8)/2,y:106,size:8})
    mergedPdf.getPage(1).drawText((largeTotalPaintManual.toFixed(1)),{x:825-font.widthOfTextAtSize((largeTotalPaintManual.toFixed(1)),8)/2,y:94,size:8})
    mergedPdf.getPage(1).drawText((args.largeFieldsGalonsTmr.toFixed(1)),{x:825-font.widthOfTextAtSize((args.largeFieldsGalonsTmr.toFixed(1)),8)/2,y:70,size:8})
    mergedPdf.getPage(1).drawText((otherTotalPaintTmr.toFixed(1)),{x:825-font.widthOfTextAtSize((otherTotalPaintTmr.toFixed(1)),8)/2,y:58,size:8})

   
    mergedPdf.getPage(1).drawText((totalPaintManual.toFixed(1)),{x:952-font.widthOfTextAtSize((totalPaintManual.toFixed(1)),8)/2,y:94,size:8})
    mergedPdf.getPage(1).drawText(totalPaintTmr.toFixed(1),{x:952-font.widthOfTextAtSize(totalPaintTmr.toFixed(1),8)/2,y:58,size:8})

    const blob = new Blob([(await mergedPdf.save())], {type: "application/pdf"});
    const url = URL.createObjectURL(blob);
    const file = new File([blob], "quotation.pdf");
    return { file, url };
}