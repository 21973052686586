import React from 'react';
import axios from 'axios';
import Chip from '@material-ui/core/Chip';
import Table from "./Components/Table.jsx"
import Tooltip from '@material-ui/core/Tooltip';

import dateFormat from 'dateformat';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import EditFeaturePopup from "./popups/EditFeaturePopup.jsx";
import EditFeatureConfigurationPopup from "./popups/EditFeatureConfigurationPopup.jsx";

function stringifyArray(a) {
    return a?.slice(1).reduce((t, n) => { return t + "," + n; }, a[0]);
}

function removeDuplicates(array) {
    return array?.filter((a, b) => array?.indexOf(a) === b)
}

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

function FeatureOptionsCell(props) {
    return (<>
        {props.options.map((opt, i) => (
            <Tooltip title={opt.description ? opt.description : "No description"} key={opt.Id}>
                <Chip label={opt.Tag} />
            </Tooltip>)
        )}
    </>);
}
function MetaDataCell(props) {
    let now = new Date();
    let now_s = dateFormat(now, "yyyy-mm-dd");
    return (<>
        {props.metadata.map((md, i) => (
            <Chip label={md.k + ":" + md.v} color={(md.f > now_s) ? "secondary" : "primary"} key={md.Id} />)
        )}
    </>);
}

function TagCell(props) {
    let opt = props.options;
    return (<>
        <EditFeaturePopup feature={props.feature} whenSuccess={props.refreshFeatures} />
        <Tooltip title={opt.Description ? opt.Description : "No description"} key={opt.Id}>
            <span>{opt.Tag}</span>
        </Tooltip>
    </>
    );
}

function FeatureConfigurationCell(props) {
    let now_s = dateFormat(new Date(), "yyyy-mm-dd");
    return (<>
        {props.configs.map((conf, i) => {
            let all_old = true;
            let period_lines = conf.From.map((a, i) => {
                if (conf.To[i] && (conf.To[i] < now_s))
                    return <div key={i} style={{ color: "yellow" }}>Available from: {conf.From[i]} to {(conf.To[i] ? conf.To[i] : "indefinitely")}<br /></div>
                else {
                    all_old = false;
                    return <div key={i}>Available from: {conf.From[i]} to {(conf.To[i] ? conf.To[i] : "indefinitely")}<br /></div>
                }
            });
            let tooltopText = (
                <div>{period_lines}<br />
                    Options: <br /> {conf.OptionString ? conf.OptionString : "No options"} </div>);

            return (
                <EditFeatureConfigurationPopup
                    key={conf.ConfigurationId}
                    tooltip={tooltopText}
                    chipLabel={conf.AccessGroups}
                    featureConfig={props.configs[i]}
                    whenSuccess={props.whenSuccessEdit}
                    forceSecondaryChipColor={all_old}
                    chipStyleBtn
                />
            )
        })}
        <EditFeatureConfigurationPopup
            chipLabel="+"
            whenSuccess={props.whenSuccessEdit}
            tooltip={"Create new feature configuration"}
            featureConfig={{ Tag: props.featureTag, FeatureId: props.featureId }}
            chipStyleBtn
            noIcon
            accessGroupsSaveId={"create_new_access_groups"}
            forceSecondaryChipColor={true}
        />
    </>);
}

class ManageFeaturesSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [],
            onlyShowEmpty: false
        };
        this.refreshFeatures = this.refreshFeatures.bind(this);

        this.headers = [{
            Header: 'Tag',
            key: 'TagElement',
            align: "left",
            style: small_style,
            search: "Tag"
        },
        {
            Header: 'Feature group',
            key: 'GroupElement',
            align: "left",
            style: small_style,
            search: "Group"
        },
        {
            Header: 'Options',
            key: 'OptionsElement',
            align: "left",
            style: small_style,
            search: "OptionsSearch"
        },
        {
            Header: 'MetaData',
            key: 'MetaDataElement',
            align: "left",
            style: small_style,
            search: "MetaDataSearch"
        },
        {
            Header: 'Feature configuration',
            key: 'FeatureConfigurationElement',
            align: "left",
            search: "Configations",
            ArraySeach: "AccessGroups"
        }
        ];
    }

    componentDidMount() {
        this.refreshFeatures()
    }

    refreshFeatures(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/featuresandoptions`)
            .then(response => response.data[0])
            .then(state => {

                let dict = {};
                let optionIdsUsed = {};
                let metaDataIdsUsed = {};
                for (let d of state) {
                    if (!dict[d.FeatureId])
                        dict[d.FeatureId] = { Tag: d.Tag, Group: d.Group, GroupId: d.GroupId, Id: d.FeatureId, Description: d.Description, Options: [], MetaData: [], Configations: {} };
                    if (d.OptionId && !optionIdsUsed[d.OptionId]) {
                        dict[d.FeatureId].Options.push({ Id: d.OptionId, Tag: d.FeatureOption, description: d.FeatureOptionDescription, created: d.FeatureOptionCreated.replace("T", " ").replace("Z", ""), deleted: d.FeatureOptionDeleted?.replace("T", " ") ?? null})
                        optionIdsUsed[d.OptionId] = 1;
                    }
                    if (d.MetaDataId && !metaDataIdsUsed[d.MetaDataId]) {
                        dict[d.FeatureId].MetaData.push({ Id: d.MetaDataId, k: d.MetaDataKey, v: d.MetaDataValue, f: d.MetaDataFrom.split("T")[0], t: (d.MetaDataTo ? d.MetaDataTo.split("T")[0] : null), o: d.MetaDataOrder })
                        metaDataIdsUsed[d.MetaDataId] = 1;
                    }
                }

                function compare(a, b) {
                    if (a.o < b.o) {
                        return -1;
                    }
                    if (a.o > b.o) {
                        return 1;
                    }

                    if (a.k < b.k) {
                        return -1;
                    }
                    if (a.k > b.k) {
                        return 1;
                    }

                    if (a.v < b.v) {
                        return -1;
                    }
                    if (a.v > b.v) {
                        return 1;
                    }
                    return 0;
                }
                for (let f of Object.keys(dict)) {
                    dict[f].MetaData = dict[f].MetaData.sort(compare);
                }


                axios(`/api/dbtool/featuresconfigurations`)
                    .then(response => response.data[0])
                    .then(state => {

                        let afcifi = {};
                        for (let c of state) {
                            c.AccessGroups = stringifyArray(removeDuplicates(c.AccessGroups?.split(",")));
                            c.AccessGroupIds = removeDuplicates(c.AccessGroupIds?.split(","));
                            if (c.Options) {
                                c.Options = removeDuplicates(c.Options.split(","));
                                c.OptionString = stringifyArray(c.Options);
                                c.OptionIds = removeDuplicates(c.OptionIds.split(","));
                            }
                            c.From = [c.From?.split("T")[0]];
                            c.To = [("" + c.To).split("T")[0].replace("0000-00-00 00:00:00", "").replace("null", "")];

                            if (!afcifi[c.AccessGroupCollectionId + "," + c.FeatureId]) {
                                afcifi[c.AccessGroupCollectionId + "," + c.FeatureId] = c;
                                dict[c.FeatureId].Configations[c.ConfigurationId] = c;
                            }
                            else {
                                afcifi[c.AccessGroupCollectionId + "," + c.FeatureId].From.push(c.From[0]);
                                afcifi[c.AccessGroupCollectionId + "," + c.FeatureId].To.push(c.To[0]);
                            }

                        }

                        let data = Object.values(dict);
                        data = data.map((d) => {
                            d.Configations = Object.values(d.Configations);
                            for (let c of d.Configations) {
                                c.AccessGroupIds = c.AccessGroupIds?.map(i => parseInt(i));
                                if (c.OptionIds)
                                    c.OptionIds = c.OptionIds.map(i => parseInt(i));
                            }
                            return d;
                        });

                        this.setState({
                            features: data.map((r, i) => {
                                r.TagElement = (<TagCell options={{ Tag: r.Tag, Description: r.Description, Id: r.Id }} feature={r} refreshFeatures={this.refreshFeatures} />);
                                r.GroupElement = r.Group;
                                r.OptionsElement = (<FeatureOptionsCell options={r.Options} />);
                                r.MetaDataElement = (<MetaDataCell metadata={r.MetaData} />)
                                r.OptionsSearch = r.Options.slice(1).reduce((t, n) => { return t + "," + n.Tag; }, r.Options.length ? r.Options[0].Tag : "")
                                r.MetaDataSearchKey = r.MetaData.slice(1).reduce((t, n) => { return t + "," + n.k; }, r.MetaData.length ? r.MetaData[0].k : "")
                                r.MetaDataSearchValue = r.MetaData.slice(1).reduce((t, n) => { return t + "," + n.v; }, r.MetaData.length ? r.MetaData[0].v : "")
                                r.MetaDataSearch = r.MetaDataSearchKey + (r.MetaDataSearchKey.length ? "," : "") + r.MetaDataSearchValue;
                                r.FeatureConfigurationElement = (<FeatureConfigurationCell configs={r.Configations} featureTag={r.Tag} featureId={r.Id} whenSuccessEdit={this.refreshFeatures} />);
                                return r;
                            })
                        })
                    });
            });
    }

    render() {
        let showFeautes = this.state.features;
        if (this.state.onlyShowEmpty) {
            showFeautes = this.state.features.filter(f => {
                return !f.Configations.length;
            });
        }


        return (
            <React.Fragment>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.onlyShowEmpty}
                            onChange={event => this.setState({ onlyShowEmpty: event.target.checked })}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Only show features with no feature configurations"
                />
                <br></br>
                <Table id="features_table" headeres={this.headers} data={showFeautes} refresh={this.refreshFeatures} />
                <div style={{ margin: "30px" }}>
                    <EditFeaturePopup whenSuccess={this.refreshFeatures} />
                </div>

            </React.Fragment>
        );
    }
}

export default ManageFeaturesSection;