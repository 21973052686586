import React, { useCallback } from "react";
import Container from "@material-ui/core/Container";

import { useSelector } from "react-redux";

import BaseView from "src/views/BaseView";
import Section from "./Section";
import DeviceDataService, { DeviceData } from "src/services/DeviceDataService";
import { useAsync, useDidMountCheck, useEvents } from "src/hooks";
import { customersSelector } from "src/redux/app/selectors";
import DashboardProvider, { update, useDashboard } from "./DashboardProvider";
import { DeviceType } from "src/utils/constants";
import CommandService from "src/services/CommandService";
import ConfigurationsService from "src/services/ConfigurationsService";

export interface DashboardProps {
    deviceTypeId: number;
    deviceId: number;
}

function Updater() {
    const { dispatch } = useDashboard();
    const updater = useCallback(
        (events: any) => {
            dispatch(update(events));
        },
        [dispatch]
    );
    useEvents("data", updater);
    return <></>;
}
export default React.memo(function Dashboard(props: DashboardProps) {
    const { deviceTypeId, deviceId } = props;

    const getDetailsCallback = useCallback(() => {
        return DeviceDataService.getDetailedDevice(deviceTypeId, deviceId).then(async r =>
            {
                if(deviceTypeId === 1)
                {
                    const version = await CommandService.getRobotLatestVersion(deviceId);
                    const index = r.primary.groups?.findIndex(x => x.name === "Miscellaneous");

                    //append latest available version for robot and tablets as device data
                    if(r.primary.groups && index !== undefined)
                    {
                        r.primary.groups[index].keys.push("Latest Version");
                        const latestVersionAsDeviceData = {
                            deviceId: deviceId,
                            deviceTypeId: 1,
                            key: "Latest Version",
                            value: version,
                            created: 0,
                            inserted: 0
                        } as DeviceData

                        r.primary.data["Latest Version"] = latestVersionAsDeviceData;

                        if(r.secondary?.groups && r.secondary?.deviceTypeId === 2)
                        {
                            const stateIndex = r.secondary.groups?.findIndex(x => x.name === "State");
                            r.secondary.groups[stateIndex].keys.push("Latest App Version");
                            for(const secondaryDeviceId of r.secondary.deviceIds)
                            {
                                const appVersion = await ConfigurationsService.getTabletAppLatestVersion(secondaryDeviceId);
                                const latestAppVersionAsDeviceData = {
                                    deviceId: secondaryDeviceId,
                                    deviceTypeId: 2,
                                    key: "Latest App Version",
                                    value: appVersion,
                                    created: 0,
                                    inserted: 0
                                } as DeviceData;

                                if(r.secondary)
                                {
                                    r.secondary.devices[secondaryDeviceId].keys.push("Latest App Version");
                                    r.secondary.devices[secondaryDeviceId].data["Latest App Version"] = latestAppVersionAsDeviceData;
                                } 
                            }
                        }
                    }
                }
                return r;
            });
    }, [deviceTypeId, deviceId]);

    const { dto: customers, loaded: customersLoaded } = useSelector(
        customersSelector
    );
    const { value: data, pending, error, exec: getDetails } = useAsync(
        getDetailsCallback,
        {
            defaultPending: true,
        }
    );

    const loading =
        (data === undefined && error === undefined) ||
        pending ||
        !customersLoaded;

    useDidMountCheck("Dashboard");

    return (
        <BaseView loading={loading} error={error} retry={getDetails}>
            <DashboardProvider details={data!}>
                <Container component="main" maxWidth="xl">
                    <Updater />
                    <Section
                        primaryDeviceTypeId={deviceTypeId}
                        customers={customers}
                        primary={deviceTypeId !== DeviceType.Tablet}
                    />
                    <Section
                        primaryDeviceTypeId={deviceTypeId}
                        customers={customers}
                        primary={deviceTypeId === DeviceType.Tablet}
                        inaccessibleDevices={data?.omittedInaccessibleDevices}
                    />
                </Container>
            </DashboardProvider>
        </BaseView>
    );
});
