/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { DndProvider as DefaultDndProvider, DndProviderProps } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import { Preview as PreviewComponent } from "react-dnd-preview";

const backend = isMobile ? TouchBackend : HTML5Backend;

export default function DndProvider(
    props: React.PropsWithChildren<
        Omit<
            DndProviderProps<any, any> & {
                Preview?: typeof PreviewComponent;
            },
            "backend"
        >
    >
) {
    const { children, Preview, ...rest } = props;
    return (
        <DefaultDndProvider {...rest} backend={backend}>
            {children}
            {isMobile && Preview && <Preview />}
        </DefaultDndProvider>
    );
}
