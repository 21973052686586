import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoadingButton from "src/components/LoadingButton";
import { useSharedHeight } from "src/components/SharedHeightProvider";
import { ServiceUnreachableError } from "src/services/Errors";
import { ToggleButton } from "@material-ui/lab";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& form": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
        '& label': {
            zIndex: 0
        },
        
        
    },
    header: {
        minHeight: 75,
    },
    content: {
        marginTop: -theme.spacing(2),
        flex: 1,
    },
    actions: {
        marginTop: -theme.spacing(2),
    },
    "@keyframes notifyError": {
        "0%": {
            transform: "scale(0.9)",
        },
        "50%": {
            transform: "scale(1.05)",
        },
        "100%": {
            transform: "scale(1.0)",
        },
    },
    error: {
        animationName: "$notifyError",
        animationDuration: "500ms",
        animationTimingFunction: "ease-out",
    },
}));

function mapError(error: any) {
    if (error === undefined) {
        return error;
    }

    if (error instanceof ServiceUnreachableError) {
        return "Failed to contact the appropriate service.";
    }

    return (
        error.message ??
        "An unknown error occured while processing your request."
    );
}

export interface WidgetProps {
    widgetName: string;
    saveChanges?:boolean;
    favouriteWidgets: string[];
    setFavouriteWidgets: (value: string[] | ((t: string[]) => string[])) => void;
}

export interface BaseWidgetProps extends WidgetProps {
    title: string;
    subTitle?: string;

    onSubmit(): void;

    children: React.ReactNode;

    error?: any;
    loading?: boolean;
}

export default function BaseWidget(props: BaseWidgetProps) {
    const classes = useStyles();
    const { elementStyle, sizerStyle, sizerRef } = useSharedHeight(
        props.widgetName
    );
        const [selected, setSelected] =  React.useState(props.favouriteWidgets?.includes(props.widgetName) ?? false);
    useEffect(() =>{
       setSelected(props.favouriteWidgets?.includes(props.widgetName) ?? false) 
    },[props.favouriteWidgets, props.widgetName])
    return (
        <Card className={classes.root} style={{overflow:"visible"}}>
            <form onSubmit={props.onSubmit} noValidate>
                <CardHeader
                    className={classes.header}
                    title={props.title}
                    subheader={
                        <div ref={sizerRef} style={{...sizerStyle,whiteSpace:"pre-line"}}>
                            {props.subTitle}
                        </div>
                    }
                    subheaderTypographyProps={{
                        style: elementStyle,
                    }}
                />
                <CardContent className={classes.content}>
                    {props.children}
                </CardContent>
                <CardActions className={classes.actions} disableSpacing>
                    <Box px={1}>
                        <ToggleButton
                            tabIndex={-1}
                            size="small"
                            value="favourite"
                            selected={selected}
                            onChange={() => {
                                if(props.favouriteWidgets)
                                {
                                    props.setFavouriteWidgets(selected?[...props.favouriteWidgets.filter(w=>w!==props.widgetName)]:[...props.favouriteWidgets, props.widgetName])
                                    setSelected(!selected);
                                }
                            }}
                            >
                            {selected ? <StarRoundedIcon color="warning" /> : <StarBorderRoundedIcon color="warning" />}
                        </ToggleButton>
                    </Box>
                    {props.error && (
                        <Box
                            width="100%"
                            textAlign="right"
                            className={classes.error}
                            mr={2}
                        >
                            <Typography color="error" variant="caption">
                                {mapError(props.error)}
                            </Typography>
                        </Box>
                    )}
                    <Box p={1} ml="auto">
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={props.loading}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </CardActions>
            </form>
        </Card>
    );
}

export const defaultRules = {
    required: "This field is required",
};
