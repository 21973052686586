import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Tablet, TabletUserLevel } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { customersSelector, tabletsSelector, tabletsSelectorUnfiltered } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { setTablets } from "src/redux/app/actions";
import { userAccessSelector } from "src/redux/auth/selectors";

interface SetTabletUserLevelInput {
    tablet: Tablet | null;
    userLevel: TabletUserLevel;
}

function updateTablets(input: SetTabletUserLevelInput) {
    return (tablets: Tablet[]) => {
        const { userLevel, tablet } = input;
        return tablets.map((t) =>
            t.id === tablet!.id ? { ...t, userLevel } : t
        );
    };
}

const adminUserLevels = ["dev", "dealer", "super", "normal"] as TabletUserLevel[];
const normalUserLevels = ["dealer", "super", "normal"] as TabletUserLevel[];

function displayTabletUserLevel(level: TabletUserLevel) {
    switch (level) {
        case "dev":
            return "Developer";
        case "dealer":
            return "Dealer";
        case "super":
            return "Super";
        case "normal":
            return "Normal";
        case "limited":
            return "Limited";
        default:
            return "Unknown level";
    }
}

function setTabletUserLevel(input: SetTabletUserLevelInput) {
    const { tablet, userLevel } = input;
    return ConfigurationsService.setTabletUserLevel({
        tabletId: tablet!.id,
        userLevel,
    }).then(() => input);
}

export default function SetTabletUserLevelWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        reset,
        getValues,
    } = useForm<SetTabletUserLevelInput>({
        defaultValues: { tablet: null, userLevel: null! },
    });

    const tablets = useSelector(tabletsSelector);
    const tabletsUnfiltered = useSelector(tabletsSelectorUnfiltered);
    const loadedDeviceInfo = tablets.length > 0;
    const { displayToast } = useToast();
    const tabletLevelDeveloperAccess=useSelector(userAccessSelector).includes('TabletLevelDeveloper')
    const customersDto = useSelector(customersSelector).dto;
    const dispatch = useDispatch();

    const [setTabletUserLevelWidgetLastTabletId,setSetTabletUserLevelWidgetLastTabletId]=useLocalStorage("SetTabletUserLevelWidgetLastTabletId",-1)

    useEffect(()=>{
        if (saveChanges){
            
            if (setTabletUserLevelWidgetLastTabletId && setTabletUserLevelWidgetLastTabletId!==getValues().tablet?.id){
            const tblt=tablets.find(t=>t.id===setTabletUserLevelWidgetLastTabletId)
            if(tblt){
                setValue('tablet',tblt)
            setValue('userLevel',tblt?.userLevel) 
            }
               
            }
        }
    },[saveChanges,setTabletUserLevelWidgetLastTabletId,setValue,getValues,tablets])

    const onComplete = useCallback(
        (input: SetTabletUserLevelInput) => {
            dispatch(setTablets(updateTablets(input)(tabletsUnfiltered)));
            if (!saveChanges)
            reset();
            displayToast({
                message: `Successfully sat the user level of Tablet ${
                    input.tablet!.id
                } to ${displayTabletUserLevel(input.userLevel)}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [dispatch, tabletsUnfiltered, reset, displayToast,saveChanges]
    );

    const { exec, pending: settingUserLevel, error } = useAsync(
        setTabletUserLevel,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const { tablet: currentTablet } = getValues();
    const loading = settingUserLevel || !loadedDeviceInfo;

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Change tablet user level"
            subTitle="Change the user level of your tablets. Selecting a tablet will display its user level."
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <Controller
                name="tablet"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            disabled={loading}
                            options={tablets}
                            getOptionLabel={(t) => {
                                const customer = customersDto[t.customerId ?? -1];
                                let ret = t.id;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+t.customerId+"]";
                                return ret;
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                            noOptionsText="No such tablet"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Tablet"
                                        placeholder="Choose a tablet"
                                        required
                                        {...getErrorProps(errors, "tablet")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                onChange(val);
                                    if (saveChanges){
                                    setSetTabletUserLevelWidgetLastTabletId(val.id)
                                }
                                if (val !== null) {
                                
                                    setValue("userLevel", val.userLevel);
                                }
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="userLevel"
                control={control}
                rules={{
                    ...defaultRules,
                    validate: (value) => {
                        return value === currentTablet?.userLevel
                            ? "Pick a new user level"
                            : true;
                    },
                }}
                render={({ onChange, ...props }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...props}
                            disabled={loading}
                            options={
                                tabletLevelDeveloperAccess
                                    ? adminUserLevels
                                    : normalUserLevels
                            }
                            getOptionLabel={displayTabletUserLevel}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="User level"
                                        placeholder="Choose a user level"
                                        required
                                        {...getErrorProps(errors, "userLevel")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                onChange(val);
                            }}
                        />
                    );
                }}
            />
        </BaseWidget>
    );
}
