import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

export default function EditVATPopup(props) {
    const [open, setOpen] = React.useState(false);

    const initialState = {
        accessGroup: null,
        accessGroups: [],
        VAT: 0
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )
    const updateAccessGroups = () => {
        axios("/api/dbtool/accessgroups")
            .then(response => response.data[0])
            .then(state => {
                let chosenAccessGroup = state.filter(g => { return g.Id === props.vat.AccessGroupId })[0];
                setState({
                    accessGroups: state,
                    VAT: props.vat.VAT,
                    accessGroup: chosenAccessGroup
                });
            });
    };

    const handleClickOpen = () => {

        // Update selects
        updateAccessGroups();

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let data = {
            i: props.vat.Id,
            v: state.VAT,
            g: state.accessGroup.Id
        }
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        axios("/api/dbtool/vat", requestOptions)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("VAT for access group " + state.accessGroup.Name + " allready exists");
            });
    };

    return (

        <>
            <IconButton aria-label="Edit VAT" component="span" onClick={handleClickOpen} >
                <Edit />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Edit VAT</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={state.accessGroups}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Acess group" />}
                                onChange={(e, v) => setState({ accessGroup: v })}
                                value={state.accessGroup}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="VAT"
                                value={state.VAT}
                                onChange={(e) => setState({ VAT: parseFloat(e.target.value) })}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        SAVE
          </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
          </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
