import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { displayToast } from "src/redux/app/actions";
import { ToastMessage } from "src/redux/app/types";

export default function useToast() {
    const dispatch = useDispatch();
    const display = useCallback(
        (toast: ToastMessage) => {
            dispatch(displayToast(toast));
        },
        [dispatch]
    );

    return {
        displayToast: display,
    };
}
