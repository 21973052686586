import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { displayDialog } from "src/redux/app/actions";
import { dialogSelector } from "src/redux/app/selectors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogOverlay: {
            position: "fixed",
            backgroundColor: "rgba(50, 50, 50, 0.6)",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: 1301,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dialogBox: isMobile ? {
            maxWidth: "100vh",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "30px",
        } : {
            minWidth: "350px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "30px",
        },
        dialogButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    })
);

export default function DialogBox() {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const { active, prompt, negativeButtonAction, positiveButtonAction } = useSelector(dialogSelector);

    const positiveAction = useCallback(() => {
        dispatch(displayDialog(false));
        if (positiveButtonAction !== undefined)
            positiveButtonAction();
    }, [dispatch, positiveButtonAction]);

    const negativeAction = useCallback(() => {
        dispatch(displayDialog(false));
        if (negativeButtonAction !== undefined)
            negativeButtonAction();
    }, [dispatch, negativeButtonAction]);

    if (!active)
        return (<></>);

    const zIndex = prompt?.zIndex;

    return (
        <div className={classes.dialogOverlay} style={zIndex === undefined ? {} : {zIndex}}>
            <div className={classes.dialogBox}>
                {typeof prompt?.dialogText === "string"
                    ? <Typography style={{whiteSpace: "pre-line", marginBottom: "20px"}}>{prompt?.dialogText}</Typography>
                    : <div style={{marginBottom: "20px"}}>{prompt?.dialogText}</div>
                }
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    {(prompt?.positiveButtonText) && <Button className={classes.dialogButton} onClick={positiveAction}>{prompt?.positiveButtonText}</Button>}
                    {(prompt?.negativeButtonText) && <Button className={classes.dialogButton} onClick={negativeAction}>{prompt?.negativeButtonText}</Button>}
                </div>
            </div>
        </div>
    );
}
