import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import type { BaseStation, Robot, Tablet } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { basestationsSelector, basestationsSelectorUnfiltered, customersDtoSelector, robotsSelector, robotsSelectorUnfiltered, tabletsSelector, tabletsSelectorUnfiltered } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { setBaseStations, setRobots, setTablets } from "src/redux/app/actions";
import ConfigurationsService from "src/services/ConfigurationsService";
import { FormControlLabel, MenuItem, Switch } from "@material-ui/core";
import useDialog from "src/hooks/useDialog";

type Device = Tablet | BaseStation | Robot;

type DeprecateDeviceInput = {
    undeprecateToggle: boolean;
    deviceType: number;
    oldDevice: Device | null;
    newDevice: Device | null;
};

function updateDevices<T extends Device>(input: DeprecateDeviceInput) {
    return (devices: Array<T>) => {
        const { oldDevice, newDevice } = input;
        if (oldDevice)
            return devices.map((r: Device) => {
                if (r.id === oldDevice.id) {
                    if (newDevice?.id === -1) //un-deprecate
                        return { ...r, deprecated: null };
                    return { ...r, deprecated: newDevice===null ? "" : newDevice.id };
                }
                return r;
            }) as T[];
        return devices;
    };
}

export default function DeprecateDeviceWidget({ widgetName, saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        reset,
        setValue,
        watch,
    } = useForm<DeprecateDeviceInput>({
        defaultValues: { undeprecateToggle: false, oldDevice: null, newDevice: null, deviceType: 1 },
    });
    
    const robotsUnfiltered = useSelector(robotsSelectorUnfiltered);
    const tabletsUnfiltered = useSelector(tabletsSelectorUnfiltered);
    const stationsUnfiltered = useSelector(basestationsSelectorUnfiltered);
    const loadedDeviceInfo = robotsUnfiltered.length > 0 && tabletsUnfiltered.length > 0;
    const { displayToast } = useToast();
    const customersDto = useSelector(customersDtoSelector);

    const dispatch = useDispatch();
    
    const [robotsDeprecated,setRobotsDeprecated] = useState([] as Robot[])
    const [tabletsDeprecated,setTabletsDeprecated] = useState([] as Tablet[])
    const [stationsDeprecated,setStationsDeprecated] = useState([] as BaseStation[])

    
    const getDeprecatedRobots = useCallback(() =>{
        return robotsUnfiltered.filter(r => r.deprecated!==null)
    },[robotsUnfiltered])

    const getDeprecatedTablets = useCallback(() =>{
        return tabletsUnfiltered.filter(r => r.deprecated!==null)
    },[tabletsUnfiltered])

    const getDeprecatedStations = useCallback(() =>{
        return stationsUnfiltered.filter(r => r.deprecated!==null)
    },[stationsUnfiltered])

    useEffect(() => {
         setRobotsDeprecated(getDeprecatedRobots())
    },[getDeprecatedRobots])

    useEffect(() => {
        setTabletsDeprecated(getDeprecatedTablets())
    },[getDeprecatedTablets])

    useEffect(() => {
        setStationsDeprecated(getDeprecatedStations())
    },[getDeprecatedStations])

    const [deprecateDeviceWidgetLastUndeprecateToggle,setDeprecateDeviceWidgetLastUndeprecateToggle]=useLocalStorage("DeprecateDeviceWidgetLastUndeprecateToggle", false)
    const [deprecateDeviceWidgetLastDeviceType,setDeprecateDeviceWidgetLastDeviceType]=useLocalStorage("DeprecateDeviceWidgetLastDeviceType",1)
    const [deprecateDeviceWidgetLastDeviceOldId,setDeprecateDeviceWidgetLastDeviceOldId]=useLocalStorage("DeprecateDeviceWidgetLastDeviceOldId",-1)
    const [deprecateDeviceWidgetLastDeviceNewId,setDeprecateDeviceWidgetLastDeviceNewId]=useLocalStorage("DeprecateDeviceWidgetLastDeviceNewId",-1)

    const { displayDialog } = useDialog();
   
    const onComplete = useCallback(
        (input: DeprecateDeviceInput) => {
            switch (input.deviceType) {
                case 1:
                    dispatch(setRobots(updateDevices(input)(robotsUnfiltered) as Robot[]));
                    break;
                case 2:
                    dispatch(setTablets(updateDevices(input)(tabletsUnfiltered) as Tablet[]));
                    break;
                case 3:
                    dispatch(setBaseStations(updateDevices(input)(stationsUnfiltered) as BaseStation[]));
                    break;
            }
            if (!saveChanges)
            reset();
            let devString = "Robot";
            if (input.deviceType === 2)
                devString = "Tablet";
            else if (input.deviceType === 3)
                devString = "Reference Station";
            displayToast({
                message: `Successfully ${input.newDevice?.id===-1?"un-":""}deprecated ${devString} ${input.oldDevice?.id} ${input.newDevice?.id!==-1&&input.newDevice!==null?(" to "+devString+" "+input.newDevice.id):""}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [dispatch, robotsUnfiltered, tabletsUnfiltered, stationsUnfiltered, displayToast, reset,saveChanges]
    );

    const deprecateDeviceWrapped = useCallback(
        async (input: DeprecateDeviceInput) => {
            let devString  = "Robot";
            if (input.deviceType === 2)
                devString = "Tablet";
            else if (input.deviceType === 3)
                devString = "Reference Station";
            return ((displayDialog({negativeButtonText:"cancel",positiveButtonText:"continue",dialogText:`${input.newDevice?.id === -1 ? "Undeprecate":"Deprecate"} ${devString} ${input.oldDevice?.id} ${input.newDevice?.id!==-1&&input.newDevice!==null?(" to "+devString+" "+input.newDevice.id):""} ?`}))
            .then(r => {
                if(r)
                {
                    if (input.newDevice?.id === -1)
                    ConfigurationsService.unDeprecateDevice(input.deviceType, input.oldDevice!.id);
                    else
                        ConfigurationsService.deprecateDevice(input.deviceType, input.oldDevice!.id, input.newDevice?.id);
                    return input;
                }
                else
                {
                    displayToast({
                        message: `Device deprecation cancelled.`,
                        severity: "warning",
                        withCloseIcon: true,
                    });
                    throw new Error("");
                }
            })).then(()=>{return input})
        },
        [displayDialog, displayToast]
    );

    const { exec, pending, error } = useAsync(
        deprecateDeviceWrapped,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = pending || !loadedDeviceInfo;

    const deviceType = watch("deviceType");
    const oldDevice = watch("oldDevice");
    const newDevice = watch("newDevice");
    const undeprecateToggle = watch("undeprecateToggle");

    const robots = useSelector(robotsSelector);
    const tablets = useSelector(tabletsSelector);
    const stations = useSelector(basestationsSelector);
    let options = undeprecateToggle ? robotsDeprecated : robots as Array<Device>;
    let deviceName = "robot";
    let deviceNameCapital = "Robot";
    if (deviceType === 2) {
        options =  undeprecateToggle ? tabletsDeprecated : tablets;
        deviceName = "tablet";
        deviceNameCapital = "Tablet";
    } else if (deviceType === 3) {
        options =  undeprecateToggle ? stationsDeprecated : stations;
        deviceName = "basestation";
        deviceNameCapital = "Reference Station";
    }

    useEffect(()=>{
        if (saveChanges){
            
            if (deprecateDeviceWidgetLastDeviceType && deprecateDeviceWidgetLastDeviceType!==deviceType){
                setValue('deviceType',deprecateDeviceWidgetLastDeviceType)
            }
            if (deprecateDeviceWidgetLastDeviceOldId && deprecateDeviceWidgetLastDeviceOldId>=0){
                let oldDevice;
                if (deprecateDeviceWidgetLastDeviceType===1)
                oldDevice=robots.find(r=>r.id===deprecateDeviceWidgetLastDeviceOldId)

                if (deprecateDeviceWidgetLastDeviceType===2)
                oldDevice=tablets.find(r=>r.id===deprecateDeviceWidgetLastDeviceOldId)

                if (deprecateDeviceWidgetLastDeviceType===3)
                oldDevice=stations.find(r=>r.id===deprecateDeviceWidgetLastDeviceOldId)

                if (oldDevice)
                setValue("oldDevice",oldDevice)
            }
            if (deprecateDeviceWidgetLastDeviceNewId && deprecateDeviceWidgetLastDeviceNewId>=0){
                let newDevice;
                if (deprecateDeviceWidgetLastDeviceType===1)
                newDevice=robots.find(r=>r.id===deprecateDeviceWidgetLastDeviceNewId)

                if (deprecateDeviceWidgetLastDeviceType===2)
                newDevice=tablets.find(r=>r.id===deprecateDeviceWidgetLastDeviceNewId)

                if (deprecateDeviceWidgetLastDeviceType===3)
                newDevice=stations.find(r=>r.id===deprecateDeviceWidgetLastDeviceNewId)

                if (newDevice)
                setValue("newDevice",newDevice)
            }
            if(deprecateDeviceWidgetLastUndeprecateToggle && deprecateDeviceWidgetLastUndeprecateToggle !== undeprecateToggle)
                setValue('undeprecateToggle', deprecateDeviceWidgetLastUndeprecateToggle)
        }
    },[setValue,deprecateDeviceWidgetLastDeviceNewId,deprecateDeviceWidgetLastDeviceOldId,deprecateDeviceWidgetLastDeviceType,deprecateDeviceWidgetLastUndeprecateToggle,robots,tablets,stations,deviceType,undeprecateToggle,saveChanges])

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Deprecate device"
            subTitle="Optionally, pick a new device to indicate the replacement of the old device."
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <Controller
                name="undeprecateToggle"
                control={control}
                render={() => {
                    return (
                        <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"5px"}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={undeprecateToggle}
                                        onChange={(ev) => {
                                            setValue("undeprecateToggle", ev.target.checked)
                                            setValue("oldDevice", null)
                                            if(ev.target.checked)
                                                setValue("newDevice", {id:-1,name:"UN-DEPRECATE",customerId:-1});
                                            else
                                                setValue("newDevice", null);

                                            if (saveChanges){
                                                setDeprecateDeviceWidgetLastUndeprecateToggle(ev.target.checked)}
                                        }}
                                        name="undeprecateToggle"
                                        size="small"
                                    />
                                }
                                defaultChecked={false}
                                label="undeprecate"
                            />
                        </div>
                    );
                }}
            />
            <Controller
                name="deviceType"
                control={control}
                rules={defaultRules}
                render={(rest) => {
                    return (
                        <TextField
                            {...rest}
                            value={deviceType}
                            onChange={(e) => {
                                setValue("deviceType", Number(e.target.value??1));
                                setValue("oldDevice", null);
                                setValue("newDevice", null);
                                if (saveChanges){
                                    setDeprecateDeviceWidgetLastDeviceType(Number(e.target.value))
                                    setDeprecateDeviceWidgetLastDeviceNewId(-1)
                                    setDeprecateDeviceWidgetLastDeviceOldId(-1)
                                }

                                
                            }}
                            disabled={loading}
                            label="Device Type"
                            margin="dense"
                            size="small"
                            select
                            fullWidth
                            variant="outlined"
                            required
                        >
                            <MenuItem key={1} value={1}>Robot</MenuItem>
                            <MenuItem key={2} value={2}>Tablet</MenuItem>
                            <MenuItem key={3} value={3}>Reference Station</MenuItem>
                        </TextField>
                    );
                }}
            />
            <Controller
                name="oldDevice"
                control={control}
                rules={defaultRules}
                render={(rest) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            value={oldDevice}
                            onChange={(_ev, val) => {
                                setValue("oldDevice", val);
                                if (saveChanges){
                                    setDeprecateDeviceWidgetLastDeviceOldId(val?.id??-1)                                    
                                }
                            }}
                            disabled={loading}
                            options={options}
                            getOptionLabel={(d:any) => {
                                const customer = customersDto[d.customerId ?? -1];
                                let ret = "("+d.id+")";
                                if (d.name !== undefined)
                                    ret += " "+d.name;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+d.customerId+"]";
                                return ret;
                            }}
                            noOptionsText={"No such "+deviceName}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label={"Old "+deviceNameCapital}
                                        placeholder={"Choose a "+deviceName+" to deprecate"}
                                        required
                                        {...getErrorProps(errors, "oldDevice")}
                                    />
                                );
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                            renderOption={(d:any) => {
                                const customer = customersDto[d.customerId ?? -1];
                                let ret = "("+d.id+")";
                                if (d.name !== undefined)
                                    ret += " "+d.name;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+d.customerId+"]";
                                if (d.deprecated !== undefined && d.deprecated !== null)
                                    return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                                return (<div>{ret}</div>);
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="newDevice"
                control={control}
                rules={{}}
                render={(rest) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            value={newDevice}
                            onChange={(_ev, val) => {
                                setValue("newDevice", val);
                                if (saveChanges){
                                    setDeprecateDeviceWidgetLastDeviceNewId(val?.id??-1)
                                }
                            }}
                            disabled={loading || undeprecateToggle}
                            options={options.concat([{id:-1,name:"UN-DEPRECATE",customerId:-1}])}
                            getOptionLabel={(d:any) => {
                                if (d.id === -1)
                                    return d.name;
                                const customer = customersDto[d.customerId ?? -1];
                                let ret = "("+d.id+")";
                                if (d.name !== undefined)
                                    ret += " "+d.name;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+d.customerId+"]";
                                return ret;
                            }}
                            noOptionsText={"No such "+deviceName}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label={"New "+deviceNameCapital}
                                        placeholder={"Choose a replacement "+deviceName}
                                        {...getErrorProps(errors, "newDevice")}
                                    />
                                );
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                            renderOption={(d:any) => {
                                if (d.id === -1)
                                    return (<div style={{color: "red"}}>UN-DEPRECATE</div>);
                                const customer = customersDto[d.customerId ?? -1];
                                let ret = "("+d.id+")";
                                if (d.name !== undefined)
                                    ret += " "+d.name;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+d.customerId+"]";
                                if (d.deprecated !== undefined && d.deprecated !== null)
                                    return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                                return (<div>{ret}</div>);
                            }}
                        />
                    );
                }}
            />
        </BaseWidget>
    );
}
