import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import ConfigurationsService from "src/services/ConfigurationsService";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import useDialog from "src/hooks/useDialog";
import { FormControlLabel, Switch, TextareaAutosize } from "@material-ui/core";

type EmergencyEmailInput = {
    message: string;
    isTLM: boolean;
    isTS: boolean;
    t: any;
};

function sendMail(input: EmergencyEmailInput) {
    console.log("befo")
    return ConfigurationsService.emergencyEmail(input.message.replaceAll('\n','<br>'),input.isTLM,input.isTS);
}

export default function EmergencyEmailWidget({ widgetName, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<EmergencyEmailInput>({
        defaultValues: {isTLM:true, isTS: true, message: 
        `Dear partner \n\nWe are currently having issues with \nAs a result you cannot \nFor the customer this would mean: \n\nWe are working hard on fixing the issue. \nWe will write out when it is resolved.`},
    });

    const { displayDialog } = useDialog();
    const { displayToast } = useToast();

    const message = watch("message");
    const isTS = watch("isTS");
    const isTLM = watch("isTLM");

    useEffect(() => {
        register("message", defaultRules);
        register("isTS");
        register("isTLM");
    }, [register]);



    const onComplete = useCallback(
        () => {
            reset();
            displayToast({
                message: `Successfully sent email`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [reset, displayToast]
    );
    

    const sendEmailWrapped=useCallback(
        async (input:EmergencyEmailInput)=>{
                const dialogText = "Do you wish to proceed?";
                const dialogResult = await displayDialog({dialogText});
            
            if (dialogResult) 
            await sendMail(input)
        },
        [displayDialog]
    )


    const { exec, pending, error, value } = useAsync(
        sendEmailWrapped,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

   
    return (
        <BaseWidget
            widgetName={widgetName}
            title="Send mail"
            subTitle="Send emergency letters"
            onSubmit={handleSubmit(exec)}
            loading={pending}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <TextareaAutosize  value={message} style={{width:"100%"}} onChange={(ev)=>{setValue("message",ev.target.value)}}></TextareaAutosize>
            
            <Switch checked={isTLM} onChange={(ev)=>setValue('isTLM',ev.target.checked)}></Switch>Send to TLM dealers 
            <Switch checked={isTS} onChange={(ev)=>{setValue('isTS',ev.target.checked)}}></Switch>Send to TS dealers 
            
          
        </BaseWidget>
    );
}
