import { useRef, useLayoutEffect, DependencyList } from "react";

export default function useMountedLayoutEffect(
    fn: () => void,
    deps: DependencyList
) {
    const mountedRef = useRef(false);
    useLayoutEffect(() => {
        if (mountedRef.current) {
            fn();
        } else {
            mountedRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
