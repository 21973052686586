
import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import AddPricePopup from "./popups/AddPricePopup.jsx";
import CreateFeatureGroupPopup from "./popups/CreateFeatureGroupPopup.jsx";

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

class ManageFeatureAndPricingsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prices: [],
            featureGroups: []
        };
        this.featureGroupSelected = -1
        this.refreshPrices = this.refreshPrices.bind(this);
        this.refreshFeatureGroups = this.refreshFeatureGroups.bind(this);
        this.changeSelectedFeatureGroup = this.changeSelectedFeatureGroup.bind(this);
        this.refreshAll = this.refreshAll.bind(this);

        this.headers = [{
            Header: 'Access group(s)',
            key: 'NameElement',
            align: "left",
            style: small_style,
            search: "Name"
        },
        {
            Header: 'Price',
            key: 'PriceElement',
            align: "left",
            style: small_style,
            search: "Type"
        },
        {
            Header: 'Currency',
            key: 'CurrencyElement',
            align: "left",
            style: small_style
        },
        {
            Header: 'Duration (Days)',
            key: 'OwnershipDurationElement',
            align: "left",
            style: small_style
        },
        {
            Header: 'Running from',
            key: 'RunningFromElement',
            align: "left",
            style: big_style
        },
        {
            Header: '',
            key: 'DeleteElement',
            align: "left",
            style: small_style
        }
        ];

    }

    getFeatureGroup(Id) {
        let fg = {}
        let featureGroupElement = this.state.featureGroups.filter((featureGroup) => {
            return featureGroup.Id === Id;
        })[0];
        if (featureGroupElement)
            fg = featureGroupElement;
        return fg;
    }

    componentDidMount() {
        this.refreshFeatureGroups();
    }

    changeSelectedFeatureGroup(event) {
        this.featureGroupSelected = event.target.value;
        this.refreshPrices();
    }

    refreshFeatureGroups(event) {
        if (event)
            event.preventDefault();
        axios("/api/dbtool/featureGroups")
            .then(response => response.data[0])
            .then(state => {
                this.setState({ featureGroups: state });
                if (this.featureGroupSelected === -1)
                    this.featureGroupSelected = state[0].Id;
                this.refreshPrices();
            });
    }

    priceCompareName(a, b) {
        if (a.NameElement < b.NameElement) {
            return -1;
        }
        if (a.NameElement > b.NameElement) {
            return 1;
        }
        return 0;
    }

    refreshPrices(event) {
        if (event)
            event.preventDefault();
        if (this.featureGroupSelected)
            axios("/api/dbtool/grouppricings/" + this.featureGroupSelected)
                .then(response => {return response.data[0]})
                .then(state => {

                    let prices = state.map(r => {
                        r.NameElement = r.Name;
                        r.PriceElement = r.ZeroDecimal ? r.Price : (r.Price / 100);
                        r.CurrencyElement = r.CurrencyISO;
                        r.RunningFromElement = r.From.split("T")[0];
                        r.OwnershipDurationElement = r.OwnershipDuration ? r.OwnershipDuration : "Infinity";
                        r.DeleteElement = <IconButton component="span" onClick={this.removePrice.bind(this, r.Id)} ><DeleteIcon /></IconButton>;
                        return r;
                    });

                    prices = prices.sort(this.priceCompareName);

                    this.setState({ prices: prices });
                });
    }

    removePrice(priceId) {
        let should_do_to = window.confirm("Do you want to remove price " + priceId, false);
        if (should_do_to) {

            let requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };
            axios("/api/dbtool/grouppricing?p="+priceId, requestOptions)
                .then(response => {return response.data})
                .then(state => {
                    if (state.success) {
                        console.log("success")
                        setTimeout(this.refreshAll,1000)
                        console.log("end")
                    }
                    else
                        alert("Could not remove price");
                });
        }
    }

    refreshAll(event) {
        if (event)
            event.preventDefault();
        this.refreshFeatureGroups();
        this.refreshPrices();
    }

    render() {
        return (
            <>
                Displaying pricings for feature group: <Select
                    value={this.featureGroupSelected >= 0 ? this.featureGroupSelected : ''}
                    onChange={this.changeSelectedFeatureGroup}
                    style={{ "marginTop": "7px", marginLeft: "5px" }}
                >
                    {this.state.featureGroups.map((group) => (
                        <MenuItem value={group.Id} key={group.Id}>{group.Name}</MenuItem>
                    ))}
                </Select>
                <span style={{ marginLeft: "15px" }}><CreateFeatureGroupPopup whenSuccess={this.refreshAll} /></span>

                <Table id="feature_and_pricing_table" headeres={this.headers} data={this.state.prices} refresh={this.refreshAll} />
                <div style={{ margin: "30px" }}>
                    <AddPricePopup defaultFeatureGroup={this.getFeatureGroup(this.featureGroupSelected)} whenSuccess={this.refreshAll} />
                </div>
            </>
        );
    }
}

export default ManageFeatureAndPricingsSection;