export default function deviceName(type: number) {
    switch (type) {
        case 1:
            return "Robot";
        case 2:
            return "Tablet";
        case 3:
            return "Reference Station";
        default:
            return "Device";
    }
}
