
import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OrangeButton from "../Components/OrangeButton.jsx"


export default function AssignTabletAppVersionPopup(props) {
    const [open, setOpen] = React.useState(false);

    const initialState = {
        RobotId: null,
        RobotIds: [],
        Model: null,
        Models: []
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const updateData = () => {
        axios("/api/dbtool/productmodels")
            .then(response => response.data[0])
            .then(productmodels => {
                axios("/api/dbtool/robots")
                    .then(response => response.data[0])
                    .then(robots => {

                        robots.forEach(r => r.Name = ("Tinybox" + r.RobotId));

                        let newState = {
                            RobotIds: robots,
                            Models: productmodels,
                            RobotId: null,
                            Model: null
                        }
                        setState(newState);
                    });
            });
    };


    const handleClickOpen = () => {

        // Update selects
        updateData();

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let data = {
            r: state.RobotId.RobotId,
            m: state.Model.Id
        };

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        axios("/api/dbtool/robotproductoverwrite", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not assign model overwrite");
            });
    };

    return (

        <>
            <OrangeButton title="Assign Robot product model overwrite" onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Assign Appversion</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={state.RobotIds}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Robot" />}
                                onChange={(e, v) => setState({ RobotId: v })}
                                value={state.RobotId}
                                style={{ width: "200px" }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={state.Models}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Model" />}
                                onChange={(e, v) => setState({ Model: v })}
                                value={state.Model}
                                style={{ width: "200px" }}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        ASSIGN
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
