import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import CreateVATPopup from "./popups/CreateVATPopup.jsx"
import EditVATPopup from "./popups/EditVATPopup.jsx"

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

class ManageVatSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vats: []
        };
        this.refreshVat = this.refreshVat.bind(this);

        this.headers = [{
            Header: 'AccessGroup',
            key: 'AccessGroupElement',
            align: "left",
            style: small_style,
            search: "AccessGroup"
        },
        {
            Header: 'VAT',
            key: 'VATElement',
            align: "left",
            style: big_style,
            search: "VAT"
        },
        {
            Header: '',
            key: 'EditElement',
            align: "left",
            style: small_style
        }
        ];
    }

    componentDidMount() {
        this.refreshVat()
    }

    refreshVat(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/vats`)
            .then(response => response.data[0])
            .then(state => this.setState({
                vats: state.map(r => {
                    r.AccessGroupElement = r.AccessGroup;
                    r.VATElement = r.VAT + "%";
                    r.EditElement = <EditVATPopup vat={r} whenSuccess={this.refreshVat.bind(this)} />
                    return r;
                })
            }));
    }

    render() {
        return (
            <React.Fragment>
                <Table id="vats_table" headeres={this.headers} data={this.state.vats} refresh={this.refreshVat} />
                <div style={{ margin: "30px" }}>
                    <CreateVATPopup whenSuccess={this.refreshVat.bind(this)} />
                </div>
            </React.Fragment>
        );
    }
}

export default ManageVatSection;