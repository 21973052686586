import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import ConfigurationsService, { Voucher } from "src/services/ConfigurationsService";
import { useAsync, useErrorHandler } from "src/hooks";
import { customersDtoSelector } from "src/redux/app/selectors";
import moment from "moment";
import { TimeFormats } from "src/utils/constants";

type CheckVoucherInput = {
    code: string;
    t: any;
};

function checkVoucher(input: CheckVoucherInput) {
    return ConfigurationsService.checkVoucher(input.code);
}

export default function VoucherCheckerWidget({ widgetName, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<CheckVoucherInput>({
        defaultValues: { code: "" },
    });

    const customersDto = useSelector(customersDtoSelector);

    const code = watch("code");

    useEffect(() => {
        register("code", defaultRules);
    }, [register]);



    const onComplete = useCallback(
        (_returned: Voucher) => {
            reset();
        },
        [reset]
    );

    const { exec, pending, error, value } = useAsync(
        checkVoucher,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    let color, message;
    if (value) {
        if (value.code===undefined) {
            color = "red";
            message = "Invalid Voucher!";
        } else if (value.usedAt===undefined) {
            color = "green";
            message = "Valid Voucher!";
        } else {
            message = `Redeemed at: ${moment.unix(value.usedAt!).format(TimeFormats.Presentation2)}\nRedeemed by: (${value.customerId!}) ${customersDto[value.customerId!].name}\nTablet id: ${value.tabletId!}`;
        }
    }

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Check voucher"
            subTitle="Check details about your voucher."
            onSubmit={handleSubmit(exec)}
            loading={pending}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={pending}
                fullWidth
                label="Voucher"
                placeholder="Code"
                value={code}
                onChange={(ev) => {
                    setValue("code", ev.target.value);
                }}
                {...getErrorProps(errors, "code")}
            />
            {(value) && <div style={{color, whiteSpace:"pre-wrap"}}>{message}</div>}
        </BaseWidget>
    );
}
