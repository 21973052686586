import React, { useMemo, useReducer, useCallback } from "react";
import { useGetLatest } from "src/hooks";

export type SharedState<WidgetState = Record<string, any>> = Record<
    string,
    any
> &
    WidgetState;
export type SharedStateAction<
    Action extends { type: string } = { type: string }
> = Action | { type: "INIT" };

export interface SharedStateHooks {
    useReducer: ((
        state: SharedState<any>,
        action: SharedStateAction<any>,
        previousState: SharedState<any>
    ) => SharedState<any>)[];
    useInstance: ((instance: SharedStateInstance<any>) => void)[];
}

export type SharedStatePlugin = (hooks: SharedStateHooks) => void;

export interface SharedStateInstance<State = Record<string, any>> {
    state: SharedState<State>;
    dispatch: <Action extends { type: string }>(action: Action) => void;
    getHooks: () => SharedStateHooks;
}

function useSharedStatePlugins(...plugins: SharedStatePlugin[]) {
    const hooks: SharedStateHooks = {
        useReducer: [],
        useInstance: [],
    };
    plugins.forEach((p) => p(hooks));
    const getHooks = useGetLatest(hooks);

    const reducer = useCallback(
        (s: SharedState, a: SharedStateAction) => {
            return getHooks().useReducer.reduce(
                (acc, next) => next(acc, a, s),
                s
            );
        },
        [getHooks]
    );

    const [state, dispatch] = useReducer(reducer, undefined, () =>
        reducer({}, { type: "INIT" })
    );

    const instance = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    ) as SharedStateInstance;

    instance.getHooks = getHooks;
    getHooks().useInstance.forEach((hook) => hook(instance));

    return instance;
}

const SharedStateContext = React.createContext<SharedStateInstance>(undefined!);

export interface SharedStateProviderProps {
    plugins: SharedStatePlugin[];
    children: React.ReactNode;
}

export default function SharedStateProvider(props: SharedStateProviderProps) {
    const instance = useSharedStatePlugins(...props.plugins);
    return (
        <SharedStateContext.Provider value={instance}>
            {props.children}
        </SharedStateContext.Provider>
    );
}

export function useSharedState() {
    return React.useContext(SharedStateContext);
}
