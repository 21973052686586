import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { userAccessSelector } from "src/redux/auth/selectors";
import { useSelector } from "react-redux";
import NotFound from "../NotFound";
import useQueryString from "src/hooks/useQueryString";
import DeveloperService from "src/services/DeveloperService";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { useToast } from "src/hooks";
import { debounce } from "lodash";
import Loading from "src/components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        fontFamily: theme.typography.fontFamily,
        paddingLeft: "20px",
        paddingRight: "20px",
        minHeight: "calc(100vh - 140px)",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "30px",
        marginBottom: "20px",
        '& > a': {
            cursor: "pointer",
            color: "#0000EE",
            textDecoration: "underline",
            '&:hover': {
                color: "#551A8B",
            },
        },
        '& > div': {
            marginLeft: "10px",
            marginRight: "10px",
        },
    },
    content: {
        flexGrow: 1,
        width: "100%",
        '& > .menu': {
            marginTop: "20px",
            '& li': {
                fontSize: "25px",
            },
            '& a': {
                fontSize: "25px",
                cursor: "pointer",
                color: "#0000BB",
                textDecoration: "underline",
                '&:hover': {
                    color: "#551A8B",
                },
                marginBottom: "10px",
            },
        },
        '& > .user_actions': {
            '& table': {
                borderCollapse: "collapse",
                border: "black 2px solid",
                '& th, td': {
                    padding: "4px",
                    textAlign: "left",
                    border: "black 1px solid",
                },
                '& th': {
                    borderBottom: "black 2px solid",
                },
                '& td:nth-child(1)': {
                    backgroundColor: "#FFFF6D",
                },
                '& td:nth-child(1):empty': {
                    backgroundColor: "unset",
                },
                '& td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7)': {
                    backgroundColor: "#D4EA6B",
                },
                '& td:nth-child(3):empty, td:nth-child(4):empty, td:nth-child(5):empty, td:nth-child(6):empty, td:nth-child(7):empty': {
                    backgroundColor: "#FF6D6D",
                },
                '& td:nth-child(9)': {
                    '& a': {
                        float: "left",
                        marginRight: "5px",
                    },
                    textAlign: "right",
                },
            },
        },
        '& > .scheduled_actions': {
            '& table': {
                borderCollapse: "collapse",
                border: "black 2px solid",
                '& th, td': {
                    padding: "4px",
                    textAlign: "left",
                    border: "black 1px solid",
                },
                '& th': {
                    borderBottom: "black 2px solid",
                },
                '& td:nth-child(1)': {
                    backgroundColor: "#FFFF6D",
                },
                '& td:nth-child(1):empty': {
                    backgroundColor: "unset",
                },
                '& td:nth-child(5)': {
                    backgroundColor: "#D4EA6B",
                },
                '& td:nth-child(5):empty': {
                    backgroundColor: "#FF6D6D",
                },
                '& td:nth-child(8)': {
                    '& a': {
                        float: "left",
                        marginRight: "5px",
                    },
                    textAlign: "right",
                },
            },
        },
        '& > .api_env': {
            '& table': {
                borderCollapse: "collapse",
                border: "black 2px solid",
                '& th, td': {
                    padding: "4px",
                    textAlign: "left",
                    border: "black 1px solid",
                },
                '& th': {
                    borderBottom: "black 2px solid",
                },
            },
        },
        '& > .image': {
            position: "relative",
            width: "100%",
            '& > div:nth-child(1)': {
                maxHeight: "calc(100vh - 90px)",
                overflow: "auto",
                border: "solid 2px black",
                '& > img': {
                    width: "100%",
                    marginBottom: "-6px",
                },
            },
            '& > div:nth-child(n+2)': {
                position: "absolute",
                right: "20px",
                width: "35px",
                height: "35px",
                textAlign: "center",
                fontSize: "28px",
                backgroundColor: "#BBBBBB",
                cursor: "pointer",
                userSelect: "none",
                border: "solid 1px black",
            },
            '& > div:nth-child(2)': {
                top: "20px",
                borderRadius: "5px 5px 0px 0px",
            },
            '& > div:nth-child(3)': {
                top: "55px",
                borderRadius: "0px 0px 5px 5px",
            },
            '& > div:hover': {
                backgroundColor: "#999999",
            },
            '& > div:active': {
                backgroundColor: "#888888",
            },
        },
    },
}));

const menus = [
    {
        name: "ServiceCenter",
        children: [
            {
                name: "Access and Actions",
                children: [
                    {
                        name: "User Access",
                    },
                    {
                        name: "Scheduled Actions",
                    },
                ],
            },
            {
                name: "REST API",
                children: [
                    {
                        name: "Open Api",
                        children: [
                            {
                                name: "Download",
                                file: "ServiceCenterApi.yaml",
                            },
                            {
                                name: "View",
                                swagger: true,
                            },
                        ],
                    },
                    {
                        name: "Administration Controller",
                        swagger: true,
                    },
                    {
                        name: "Command Controller",
                        swagger: true,
                    },
                    {
                        name: "Configurations Controller",
                        swagger: true,
                    },
                    {
                        name: "Data Controller",
                        swagger: true,
                    },
                    {
                        name: "Developer Controller",
                        swagger: true,
                    },
                    {
                        name: "HubSpot Controller",
                        swagger: true,
                    },
                    {
                        name: "Location Controller",
                        swagger: true,
                    },
                    {
                        name: "Monday Controller",
                        swagger: true,
                    },
                    {
                        name: "Production Controller",
                        swagger: true,
                    },
                    {
                        name: "Quote Controller",
                        swagger: true,
                    },
                    {
                        name: "Stats Controller",
                        swagger: true,
                    },
                    {
                        name: "Support Controller",
                        swagger: true,
                    },
                    {
                        name: "Token Controller",
                        swagger: true,
                    },
                    {
                        name: "User Controller",
                        swagger: true,
                    },
                    {
                        name: "Webhook Controller",
                        swagger: true,
                    },
                ],
            },
            {
                name: "Data Model Diagram",
                image: true,
            },
            {
                name: "Deployment",
                children: [
                    {
                        name: "Backend Environment Variables",
                    },
                    {
                        name: "Frontend Environment Variables",
                    },
                ],
            },
        ],
    },
    {
        name: "TinyMobileRobots",
        children: [
            {
                name: "Integrations",
                image: true,
            },
            {
                name: "Deployment Diagram",
                image: true,
            },
            {
                name: "System Architecture",
                image: true,
            },
            {
                name: "Database",
                children: [
                    {
                        name: "Data Model Diagram",
                        image: true,
                    },
                    {
                        name: "Daily Dump",
                        file: "Database.sql",
                    },
                    {
                        name: "Current Dump",
                        file: "Database.sql",
                    },
                    {
                        name: "Docker",
                        file: "MySQL.sh",
                    },
                    {
                        name: "Warehouse",
                        pdf: true
                    }
                ],
            },
        ],
    },
    {
        name: "Spraytool Calibration Software",
        pdf: true,
    },
    {
        name: "CI∕CD",
        children: [
            {
                name: "Multibranch Pipeline",
                pdf: true,
            },
            {
                name: "Jenkins Maintenance",
                pdf: true,
            },
            {
                name: "SonarQube Maintenance",
                pdf: true,
            },
        ],
    },
    {
        name: "Monitoring and Alerting",
        pdf: true,
    },
];

export default function Developer() {
    const classes = useStyles();

    const { displayToast } = useToast();

    const developerAccess=useSelector(userAccessSelector).includes('Developer')

    const [location, setLocation] = useQueryString("location","");

    const header = [];

    header.push(<a key="-1" href="/developer?location=" onClick={(e) => {e.preventDefault();setLocation("");}}>Documentation</a>);
    let s = {children: menus} as any;
    if (location !== "") {
        const splitted = location.split(">");
        let loc = "";
        for (let i = 0; i < splitted.length; i++) {
            if (i<splitted.length-1 && s.children === undefined) {
                setLocation("");
                break;
            }
            const tmp = s.children.find((o:any) => o.name===splitted[i]);
            if (tmp === undefined) {
                setLocation("");
                break;
            }
            if (i>0)
                loc += ">";
            loc += tmp.name;
            const tloc = loc+"";
            header.push(<div key={i+"a"}>{">"}</div>);
            header.push(<a key={i+"b"} href={"/developer?location="+tloc} onClick={(e) => {e.preventDefault();setLocation(tloc);}}>{tmp.name}</a>);
            s = tmp;
        }
    }

    const [content, setContent] = useState("" as any);

    const [loading, setLoading] = useState(false);

    const displayErrorToast = (val:any) => displayToast({
        message: val.message,
        severity: "error",
        withCloseIcon: true,
    });

    const downloadFile = (locationString: string, fileName: string) => {
        setLoading(true);
        DeveloperService.getContent(locationString, true).catch(displayErrorToast).then(link => {
            const temp_link = document.createElement("a");
            document.body.appendChild(temp_link);
            temp_link.href = link as string;
            temp_link.download = fileName;
            temp_link.click();
            temp_link.remove();
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        let search = {children: menus} as any;
        if (location !== "") {
            const splitted = location.split(">");
            let loc = "";
            for (let i = 0; i < splitted.length; i++) {
                if (i<splitted.length-1 && search.children === undefined) {
                    setLocation("");
                    break;
                }
                const tmp = search.children.find((o:any) => o.name===splitted[i]);
                if (tmp === undefined) {
                    setLocation("");
                    break;
                }
                if (tmp.file !== undefined) {
                    setLocation(loc);
                    downloadFile(loc, tmp.file);
                    break;
                }
                if (i>0)
                    loc += ">";
                loc += tmp.name;
                const tloc = loc+"";
                header.push(<div key={i+"a"}>{">"}</div>);
                header.push(<a key={i+"b"} href={"/developer?location="+tloc} onClick={(e) => {e.preventDefault();setLocation(tloc);}}>{tmp.name}</a>);
                search = tmp;
            }
        }

        if (search.children !== undefined) {
            const items = [];
            let i = 0;
            for (const child of search.children) {
                const tloc = (location===""?child.name:(location+">"+child.name));
                if (child.file !== undefined)
                    items.push(<li key={i++}><a href={"/developer?location="+tloc} onClick={(e) => {
                        e.preventDefault();
                        downloadFile(tloc, child.file);
                    }}>{child.name}</a></li>);
                else
                    items.push(<li key={i++}><a href={"/developer?location="+tloc} onClick={(e) => {e.preventDefault();setLocation(tloc);}}>{child.name}</a></li>);
            }
            setContent(<div className="menu"><ul>{items}</ul></div>);
        } else {
            DeveloperService.getContent(location, search.image || search.pdf).then(ret => {
                if (search.swagger) {
                    setContent(<SwaggerUI
                        docExpansion="list"
                        onComplete={() => {
                            const dropdown = document.getElementById("operations-tag-default");
                            if (dropdown)
                                dropdown.remove();
                        }}
                        spec={ret}
                    />);
                } else if (search.image) {
                    const zoom = debounce((enlarge: boolean, x=0, y=0) => {
                        const img = document.getElementById("developer-display-image");
                        const con = document.getElementById("developer-display-image-container");
                        if (img && con) {
                            const relX = (con.scrollLeft+x)/img.clientWidth;
                            const relY = (con.scrollTop+y)/img.clientHeight;
                            const style = (img.getAttribute("style")??"").replace(/[^\d.]/g,"");
                            let pct = 100;
                            if (style)
                                pct = Number(style);
                            if (enlarge) {
                                pct += pct*0.1;
                            } else {
                                pct -= pct*0.091;
                                if (pct < 100)
                                    pct = 100;
                            }
                            img.setAttribute("style",`width:${pct}%`);
                            con.scrollTo(img.clientWidth*relX-x,img.clientHeight*relY-y);
                        }
                    },10);
                    const listener = (e0:React.MouseEvent<HTMLDivElement,MouseEvent>) => {
                        const top = e0.currentTarget.offsetTop;
                        const left = e0.currentTarget.offsetLeft;
                        return (e:any) => {
                            if(e.ctrlKey) {
                                e.preventDefault();
                                if (e.deltaY < 0) {
                                    zoom(true,e.clientX-left,e.clientY-top);
                                } else if (e.deltaY > 0) {
                                    zoom(false,e.clientX-left,e.clientY-top);
                                }
                            }
                        };
                    };
                    setContent(
                    <div className="image"
                        onMouseEnter={(e) => window.addEventListener("wheel",listener(e),{passive:false})}
                        onMouseLeave={(e) => window.removeEventListener("wheel",listener(e))}
                    >
                        <div id="developer-display-image-container">
                            <img
                                src={ret}
                                onLoad={(e) => {
                                    const con = document.getElementById("developer-display-image-container")!;
                                    if (con.clientWidth > e.currentTarget.naturalWidth) {
                                        con.setAttribute("style",`width:${e.currentTarget.naturalWidth}px;`);
                                        document.getElementById("developer-display-image-zoom-in")!.setAttribute("style","display:none;");
                                        document.getElementById("developer-display-image-zoom-out")!.setAttribute("style","display:none;");
                                    } else {
                                        con.removeAttribute("style");
                                        document.getElementById("developer-display-image-zoom-in")!.removeAttribute("style");
                                        document.getElementById("developer-display-image-zoom-out")!.removeAttribute("style");
                                    }
                                }}
                                alt={search.name}
                                id="developer-display-image"
                                style={{width:"100%"}}
                            />
                        </div>
                        <div id="developer-display-image-zoom-in" onClick={() => zoom(true)}>+</div>
                        <div id="developer-display-image-zoom-out" onClick={() => zoom(false)}>–</div>
                    </div>);
                } else if (search.pdf) {
                    setContent(
                    <object data={ret} style={{width: "100%", height: "calc(100vh - 90px)"}} title="pdf">
                        <iframe src={ret} style={{width: "100%", height: "calc(100vh - 90px)"}} title="pdf"/>
                    </object>);
                } else {
                    setContent(ret);
                }
            }).catch(displayErrorToast);
        }
    }, [location]); //eslint-disable-line react-hooks/exhaustive-deps

    if (!developerAccess)
        return (<NotFound/>);
    
    return (<div className={classes.root}>
        {(loading) && <Loading/>}
        <div className={classes.header}>{header}</div>
        {(typeof content === "string")
            ? <div className={classes.content} dangerouslySetInnerHTML={{__html: content}}></div>
            : <div className={classes.content}>{content}</div>
        }          
    </div>);
}