/* eslint-disable @typescript-eslint/ban-types */
import { makeStyles } from "@material-ui/core/styles";
import { ColumnInstance, IdType } from "react-table";
import { useDrag, useDrop, DragSourceMonitor } from "react-dnd";
import classNames from "src/utils/classNames";

const useStyles = makeStyles(() => ({
    dragging: {
        opacity: 0.5,
    },
    canDrag: {
        cursor: "move",
    },
}));

function reorder<T extends {}>(
    columns: ColumnInstance<T>[],
    old_position: ColumnInstance<T>,
    new_position: ColumnInstance<T>
) {
    const cache = {} as Record<string, number>;
    columns.forEach((c, idx) => {
        if (c === old_position) {
            cache.old_idx = idx;
        }
        if (c === new_position) {
            cache.new_idx = idx;
        }
    });

    if (Object.keys(cache).length !== 2) {
        return columns;
    }

    const { old_idx, new_idx } = cache;
    const ret = [...columns];
    ret.splice(new_idx, 0, ret.splice(old_idx, 1)[0]);
    return ret;
}

enum DragTypes {
    DraggableCell = "DRAGGABLE_CELL",
}

export interface DragAction<T extends {}> {
    type: DragTypes.DraggableCell;
    column: ColumnInstance<T>;
}

export interface ReorderOptions<T extends {}> {
    column: ColumnInstance<T>;
    visibleColumns: ColumnInstance<T>[];
    setColumnOrder: (
        updater: ((order: IdType<T>[]) => IdType<T>[]) | IdType<T>[]
    ) => void;
}

export default function useColumnReorder<T extends {}>(
    options: ReorderOptions<T>
) {
    const classes = useStyles();
    const { column, visibleColumns, setColumnOrder } = options;

    const [{ className }, dragRef] = useDrag({
        item: { type: DragTypes.DraggableCell, column },
        end: (item: DragAction<T> | undefined, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                const reordered = reorder(
                    visibleColumns,
                    item.column,
                    dropResult.column
                );
                if (reordered !== visibleColumns) {
                    setColumnOrder(reordered.map((c) => c.id));
                }
            }
        },
        collect: (monitor) => ({
            className: classNames(
                monitor.isDragging() && classes.dragging,
                monitor.canDrag() && classes.canDrag
            ),
        }),
        canDrag: () => column.orderable ?? true,
    });

    const [{ showDropTitle }, drop] = useDrop({
        accept: DragTypes.DraggableCell,
        drop: () => ({ column }),
        canDrop: (item: DragAction<T>) => {
            return item.column !== column && (column.orderable ?? true);
        },
        collect: (monitor) => ({
            showDropTitle: monitor.isOver() && monitor.canDrop(),
        }),
    });

    const dropTitle = "Drop to rearrange columns";

    return {
        outerRef: drop,
        outerTitle: dropTitle,
        showOuterTitle: showDropTitle,
        innerRef: dragRef,
        innerClassName: className,
    };
}
