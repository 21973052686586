export class ServiceError {
    constructor(public message: string) {}
}

export class NotFoundError extends ServiceError {}

export class ForbiddenError extends ServiceError {}

export class BadRequestError extends ServiceError {}

export class AuthenticationError extends ServiceError {}

export class AuthorizationError extends ServiceError {}

export class UnknownError extends ServiceError {}

export class ServiceUnreachableError extends ServiceError {}

export class ValidationError extends ServiceError {
    constructor(
        message: string,
        public errors: {
            location: "body" | "query" | "param";
            msg: string;
            param: string;
            value: string;
        }[] = []
    ) {
        super(message);
    }
}

export function handleError(err: any, validate: boolean): any {
    if (err instanceof AuthorizationError || err instanceof AuthenticationError)
        throw err;
    else if (validate && err?.response?.data?.validationErrors) {
        throw new ValidationError(
            err.response.data.message || "Bad input.",
            err.response.data.validationErrors
        );
    } else if (err?.response?.status === 400) {
        throw new BadRequestError(
            err.response.data?.message || "Bad request."
        );
    } else if (err?.response?.status === 503 || err?.response?.status === 504) {
        throw new ServiceUnreachableError(
            err.response.data?.message || "Could not contact service."
        );
    } else if (err?.response?.status === 404) {
        throw new NotFoundError(
            err.response.data?.message || "Resource not found."
        );
    } else if (err?.response?.status === 403) {
        throw new ForbiddenError(
            err.response.data?.message || "Forbidden."
        );
    }
    throw new UnknownError("An unknown error has occured.");
}
