import React from "react";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import MaterialLampIcon from "@material-ui/icons/EmojiObjects";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "../../utils/classNames";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import moment from "moment";

const useStyles1 = makeStyles((theme) => ({
    online: {
        color: theme.palette.success.light,
    },
    offline: {
        color: theme.palette.error.dark,
    },
    indeterminate: {
        color: theme.palette.grey[400],
    },
}));

interface IconProps {
    fontSize?: "inherit" | "large" | "default" | "small";
    className?: string;
}

interface OnlineIconProps extends IconProps {
    status: "Online" | "Offline" | undefined;
    msgOverride?: string;
}

export const OnlineIcon = React.memo(function OnlineIcon({
    status,
    className,
    fontSize,
    msgOverride,
}: OnlineIconProps) {
    const classes = useStyles1();
    const onlineValue = status?.trim().toLowerCase();
    const isOnline = onlineValue === "online";
    const isIndeterminate = onlineValue !== "offline";

    let title =
        isIndeterminate && !isOnline
            ? "Online status unknown"
            : "The device is " + onlineValue;
    
    if (msgOverride !== undefined && msgOverride !== null && msgOverride !== "")
        title = msgOverride;
    
    let c = "online" as "online"|"indeterminate"|"offline";
    if (!isOnline)
        c = isIndeterminate ? "indeterminate" : "offline";

    return (
        <Tooltip title={title} aria-label={title}>
            <PowerSettingsNewIcon
                fontSize={fontSize}
                className={classNames(classes[c], className)}
            />
        </Tooltip>
    );
});

const useStyles2 = makeStyles((theme) => ({
    ok: {
        color: theme.palette.success.light,
    },
    decent: {
        color: theme.palette.success.dark,
    },
    belowHalf: {
        color: "#ffeb3f",
    },
    notGood: {
        color: theme.palette.error.light,
    },
    bad: {
        color: theme.palette.error.dark,
    },
    unknown: {
        color: theme.palette.grey[400],
    },
}));

export interface BatteryIconProps extends IconProps {
    status?: string | number;
}

export const BatteryIcon = React.memo(function BatteryIcon(
    props: BatteryIconProps
) {
    const classes = useStyles2();
    const level = props.status && +props.status;

    let Icon: any;
    let className = classes.unknown;

    if (typeof level === "number") {
        if (level >= 95) {
            Icon = BatteryFullIcon;
            className = classes.ok;
        } else if (level >= 90) {
            Icon = Battery90Icon;
            className = classes.ok;
        } else if (level >= 80) {
            Icon = Battery80Icon;
            className = classes.decent;
        } else if (level >= 60) {
            Icon = Battery60Icon;
            className = classes.decent;
        } else if (level >= 45) {
            Icon = Battery50Icon;
            className = classes.decent;
        } else if (level >= 30) {
            Icon = Battery30Icon;
            className = classes.belowHalf;
        } else {
            Icon = Battery20Icon;
            className = classes.bad;
        }
    } else {
        Icon = BatteryUnknownIcon;
    }

    return (
        <Tooltip
            title={
                (className === classes.unknown && "Battery status unknown") ||
                `Battery: ${level}%`
            }
        >
            <Icon
                fontSize={props.fontSize}
                className={classNames(className, props.className)}
            />
        </Tooltip>
    );
});

export interface LampProps extends IconProps {
    title?: string;
    status?: string;
}

export const LampIcon = React.memo(function LampIcon(props: LampProps) {
    const classes = useStyles2();
    
    let className = classes.unknown;
    if (props.status === "System running")
        className = classes.ok;
    else if ((props.status || "").indexOf("HDOP") !== -1)
        className = classes.belowHalf;
    else if (props.status !== undefined)
        className = classes.bad;

    const title = props.status || "System status unknown";

    return (
        <Tooltip title={title}>
            <MaterialLampIcon
                fontSize={props.fontSize}
                className={classNames(className, props.className)}
            />
        </Tooltip>
    );
});

const useStyles3 = makeStyles((theme) => ({
    open: {
        color: theme.palette.error.dark,
    },
    closed: {
        color: theme.palette.success.light,
    },
}));

interface ResolvedIconProps extends IconProps {
    values: any;
}

export const ResolvedIcon = React.memo(function ResolvedIcon({
    values,
    className,
    fontSize,
}: ResolvedIconProps) {
    const classes = useStyles3();
    const isOpen = !["closed","solved","pending"].includes(values["status"]);
    const isOld = moment.now()/1000 - values["created_at"] >= 7*24*60*60;

    if (!isOpen)
        return <Tooltip title={values["status"]} aria-label={values["status"]}>
            <CheckIcon fontSize={fontSize} className={classNames(classes.closed,className)}/>
        </Tooltip>;
    
    if (isOld)
        return <Tooltip title={values["status"]} aria-label={values["status"]}>
            <WarningIcon fontSize={fontSize} className={classNames(classes.open,className)}/>
        </Tooltip>;

    return <Tooltip title={values["status"]} aria-label={values["status"]}>
        <ClearIcon fontSize={fontSize} className={classNames(classes.open,className)}/>
    </Tooltip>;
});