import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dateFormat from 'dateformat';

import OrangeButton from "../Components/OrangeButton.jsx"
import { AccordionDetails,Accordion, AccordionSummary,FormGroup,Tooltip, FormControlLabel,Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function CreatePurchasePopup(props) {
    const [open, setOpen] = React.useState(false);

    let now = new Date();
    let now_s = dateFormat(now, "yyyy-mm-dd");

    const initialState = {
        customer: null,
        customers: [],
        featureGroup: null,
        featureGroups: [],
        from: now_s,
        to: ""
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )
    const updateCustomers = () => {
        axios("/api/dbtool/customers")
            .then(response => response.data[0])
            .then(state => {
                setState({ customers: state });
            });
    };
    const updateFeatureGroups = () => {
        axios("/api/dbtool/featuregroups")
            .then(response => response.data[0])
            .then(state => {
                setState({ featureGroups: state });
            });
    };

    const setStartDate = (newStartDate, i) => {
        state.from = newStartDate;
        if (state.from > state.to && state.to)
            state.to = newStartDate;
        setState({ from: state.from, to: state.to });
    };

    const handleClickOpen = () => {

        // Update selects
        updateCustomers();
        updateFeatureGroups();

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let data = {
            ci: state.customer.Id,
            // fi: state.featureGroup.Id,
            f: state.from,
            t: state.to
        }
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // data:(data)
        };
        featureGroups.map((fg,ind)=>{
            return axios("/api/dbtool/purchase", {...requestOptions,data:{...data,fi:fg}})
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    if (ind===featureGroups.length-1){
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();}
                }
                else
                    alert("Purchase could not be completed");
            });
        })
        setFeatureGroups([])
        
    };
    const [featureGroups,setFeatureGroups]=useState([])
    return (

        <>
            <OrangeButton title="Create purchase" onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Create purchase</DialogTitle>
                <DialogContent style={{ overflow: "hidden", minWidth: "450px" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={state.customers}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Customer" />}
                                onChange={(e, v) => setState({ customer: v })}
                                value={state.customer}
                            />
                        </Grid>
                        <Grid item xs={6}>
                                          <Accordion >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}} />} >
                                                <span >{"Feature groups"}</span>
                                            </AccordionSummary>
                                            <AccordionDetails style={{maxHeight: "300px",  overflowY: "scroll"}}>        
                                                <FormGroup>
                                                    {state.featureGroups?.map((af,index)=>
                                                    <Tooltip title={af.Nane} key = {index}>
                                                        <FormControlLabel
                                                        label={<span >{af.Name}</span>}
                                                        style={{marginLeft:"10px"}}
                                                        control={
                                                        <Checkbox 
                                                            checked={featureGroups.includes(af.Id)}
                                                            style={{color:"rgb(100,100,100)"}}
                                                            onClick={(ev)=>{
                                                                
                                                                if (ev.target.checked)
                                                                setFeatureGroups([...featureGroups,af.Id])
                                                                else
                                                                setFeatureGroups([...featureGroups.filter((fg)=>fg!==af.Id)])
                                                            }}
                                                            />}/>
                                                    </Tooltip>
                                                    )
                                                    }
                                                </FormGroup>
                                           
                                            </AccordionDetails>
                                          </Accordion>
                            {/* <Autocomplete
                                options={state.featureGroups}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Feature group" />}
                                onChange={(e, v) => setState({ featureGroup: v })}
                                value={state.featureGroup}
                            /> */}
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                style={{ width: "100%" }}
                                id="from_date"
                                label="Start date (inclusive)"
                                type="date"
                                value={state.from}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                style={{ width: "100%" }}
                                id="to_date"
                                label="End date (exclusive)"
                                type="date"
                                value={state.to}
                                onChange={(e) => setState({ to: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        CREATE
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
