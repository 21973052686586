import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import type { Robot } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import { customersDtoSelector, robotsSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import ConfigurationsService, { AccessGroup } from "src/services/ConfigurationsService";

type SetRobotRSAccessgroupInput = {
    accessGroup: AccessGroup | null;
    robot: Robot | null;
};

function setRobotRSag(input: SetRobotRSAccessgroupInput) {
    if(input.accessGroup!.Id === -1)
        return ConfigurationsService.postRSunlock(input.robot!.id).then(r => {
            if(!r)
            throw new Error("Device already unlocked")
        }).then(() => input)

    return ConfigurationsService.postRSlock(
        input.robot!.id,
        input.accessGroup!.Id,
    ).then(r => {
        if(!r)
            throw new Error("Device already locked")
    }).then(() => input);
} 

export default function LockRSUpdateWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<SetRobotRSAccessgroupInput>({
        defaultValues: { robot: null, accessGroup: null },
    });

    
    const robots = useSelector(robotsSelector);
    const loadedDeviceInfo = robots.length > 0;
    const { displayToast } = useToast();
    const customersDto = useSelector(customersDtoSelector);

    const accessGroup = watch("accessGroup");
    const robot = watch("robot");

    const fetchVersions = useCallback(() => ConfigurationsService.getRSAaccessGroups(),[])
    const { value: versions, pending: loadingVersions, exec: f } = useAsync(fetchVersions, {
        immediate: false,
        onError: (val : any) => displayToast({message: val.message, severity: "error", withCloseIcon: true }),
    });
    useEffect(() => {
        f()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        register("robot", defaultRules);
        register("accessGroup", defaultRules);
    }, [register]);

    const onComplete = useCallback(
        (input: SetRobotRSAccessgroupInput) => {
            if (!saveChanges) reset();
            displayToast({
                message: `Successfully ${input.accessGroup?.Id === -1 ? 'unlocked' : 'locked'} Robot ${
                    input.robot!.id
                } max. version ${input.accessGroup?.Id === -1 ? '' : 'to '+input.accessGroup?.Name}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast, reset,saveChanges]
    );

    const { exec: setAG, pending: settingVersion, error } = useAsync(
        setRobotRSag,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = settingVersion || !loadedDeviceInfo || loadingVersions;

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Lock robot max. RS update version"
            subTitle={`Lock/unlock the maximum Robot Software major version that the robot can update to. WARNING: The list might display versions not yet available for certain types of robots`}
            onSubmit={handleSubmit(setAG)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <AutocompleteV2
                selectOnTab
                value={robot}
                disabled={loading}
                options={robots}
                getOptionLabel={(r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    return ret;
                }}
                noOptionsText="No such robot"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Robot"
                            placeholder="Choose a robot"
                            required
                            {...getErrorProps(errors, "robot")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("robot", val);
                }}
                
                getOptionSelected={(a, b) => a.id === b.id}
                renderOption={(r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    if (r.deprecated !== undefined && r.deprecated !== null)
                        return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                    return (<div>{ret}</div>);
                }}
            />
            <AutocompleteV2
                selectOnTab
                value={accessGroup}
                disabled={loading}
                options={versions?.concat([{Id: -1, Name: "UNLOCK", ShortName: "UNLOCK", AccessGroupTypeId: 16, Inheritable: 0}]) ?? []}
                getOptionLabel={(r) => {
                    
                    return r.Name;
                }}
                noOptionsText="No versions"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Version"
                            placeholder="Choose a version"
                            required
                            {...getErrorProps(errors, "accessGroup")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                     setValue("accessGroup", val);
                }}
                
                getOptionSelected={(a, b) => a.Id === b.Id}
                renderOption={(r) => {
                    return (<div>{r.Name}</div>);
                }}
            />
            
        </BaseWidget>
        
    );
}
