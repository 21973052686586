import React from "react";
import Typography from "@material-ui/core/Typography";
import { TableInstance } from "react-table";

// eslint-disable-next-line @typescript-eslint/ban-types
export default function Pagination<T extends {}>({
    instance,
}: {
    instance: TableInstance<T>;
}) {
    const { rows, data } = instance;
    return (
        <div className="dg-pagination">
            <Typography>
                {rows.length} of {data.length} entries shown
            </Typography>
        </div>
    );
}
