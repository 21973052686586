import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useTranslator } from "./TranslationProvider";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.info.main,
        marginLeft: 0 - theme.spacing(1),
    },
}));

const title = "Certain values are masked behind a more human-readable format. Enable or disable this setting here.";

interface ToggleTranslateProps {
    className?: string;
    label?: React.ReactNode;
}

export default function ToggleTranslate(props: ToggleTranslateProps) {
    const { className, label } = props;
    const { enabled, toggleTranslate } = useTranslator();
    const classes = useStyles();

    const toggle = useCallback(
        (_ev: any, value: boolean) => toggleTranslate(!value),
        [toggleTranslate]
    );

    return (
        <Box display="inline-flex" alignItems="center" className={className}>
            <FormControlLabel
                control={
                    <Switch size="small" checked={enabled} onChange={toggle} />
                }
                label={label !== undefined ? label : "Translate data"}
            />
            <Tooltip title={title}>
                <InfoIcon fontSize="small" className={classes.icon} />
            </Tooltip>
        </Box>
    );
}
