import React, { useRef, useState } from "react";
import { getDocument } from "pdfjs-dist";
import { useAsyncDebounce } from "react-table";
import { Button, makeStyles, Typography } from "@material-ui/core";

const MAX_WIDTH = 1000;

if ((window as any).pdfjsWorker === undefined)
    (window as any).pdfjsWorker = require("pdfjs-dist/build/pdf.worker");

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: "#38383D",
        color: "#C4C4C6",
        fontWeight: "bold",
        fontSize: "20px",
        padding: "0px",
        '&:hover': {
            backgroundColor: "#444444",
        },
    },
}));

export default function PdfPreview({src, style}:{src:string, style:React.CSSProperties}) {

    const classes = useStyles();

    const stl = {backgroundColor: "#2A2A2A", ...style} as React.CSSProperties;

    const base = useRef<HTMLDivElement>(null);
    const pdfViewer = useRef<HTMLDivElement>(null);
    const scrollable = useRef<HTMLDivElement>(null);
    const [promises, setPromises] = useState(new Array<Promise<void>>(0));
    const [source, setSource] = useState(src);
    const [zoom, setZoom] = useState(1);
    const [scrollPos, setScrollPos] = useState(0);

    const generatePreview = useAsyncDebounce(async (z: number) => {
        const loadingTask = getDocument(src);
        const prom = loadingTask.promise
        .then(async function (doc) {
            const pgs = new Array<HTMLCanvasElement>(0);
            for (let i = 1; i <= doc.numPages; i++) {
                const pdfPage = await doc.getPage(i);
                const page = document.createElement("canvas");
                pgs.push(page);
                if (i < doc.numPages)
                    page.setAttribute("style", "margin-bottom: 10px;");
                let viewport = pdfPage.getViewport({ scale: 1 });
                page.width = scrollable.current?.clientWidth ?? viewport.width;
                page.width = z*page.width;
                page.width -= 20;
                if (page.width > MAX_WIDTH*z)
                    page.width = MAX_WIDTH*z;
                page.height = page.width/viewport.width*viewport.height;
                viewport = pdfPage.getViewport({ scale: page.width/viewport.width });
                const ctx = page.getContext("2d")!;
                const renderTask = pdfPage.render({
                    canvasContext: ctx,
                    viewport,
                });
                await renderTask.promise;
            }
            while (pdfViewer.current?.firstChild)
                pdfViewer.current.removeChild(pdfViewer.current.firstChild);
            for (const pg of pgs)
                pdfViewer.current!.appendChild(pg);
            if (scrollable.current !== null)
                scrollable.current.scrollTop = scrollPos;
        })
        .catch(e => {
            console.error(e);
        });
        setPromises(promises.concat([prom]));
        await promises[promises.length-1];
    }, 100);

    if (source !== src) {
        setSource(src);
        generatePreview(zoom);
    }

    const scrollableViewportWidth = base.current===null?"100%":base.current.clientWidth-20+"px";
    let scrollableMargin = "0px";
    if (pdfViewer.current !== null && scrollable.current !== null && pdfViewer.current.clientWidth > scrollable.current.clientWidth)
        scrollableMargin = (pdfViewer.current.clientWidth - scrollable.current.clientWidth).toString() + "px";

    return(<div style={stl}>
        <div
            ref={base}
            style={{padding: "10px", height: "calc( 100% - 20px )", width: "calc( 100% - 20px )"}}
        >
            <div
                style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "10px", height: "35px"}}
            >
                <Button
                    className={classes.button}
                    style={{marginRight: "10px"}}
                    onClick={() => {
                        setZoom(zoom+0.1);
                        generatePreview(zoom+0.1);
                    }}
                >+</Button>
                <Button
                    className={classes.button}
                    style={{marginRight: "10px"}}
                    onClick={() => {
                        if (zoom > 0.1) {
                            setZoom(zoom-0.1);
                            generatePreview(zoom-0.1);
                        }
                    }}
                >-</Button>
                <Typography style={{color: "#C4C4C6", fontWeight: "bold", cursor: "default"}}>{Math.round(zoom*100)+"%"}</Typography>
            </div>
            <div
                ref={scrollable}
                style={{overflowY: "auto", height: "calc( 100% - 45px )", width: scrollableViewportWidth, display: "flex", flexDirection: "column", alignItems: "center"}}
                onScroll={(e) => {
                    setScrollPos(e.currentTarget.scrollTop);
                }}
            >
                <div
                    ref={pdfViewer}
                    style={{display: "flex", flexDirection: "column", width: "fit-content", marginLeft: scrollableMargin}}
                />
            </div>
        </div>
    </div>);
}