import {  Button, Checkbox, FormControlLabel, Switch, TextField, Tooltip, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { useAsync, useToast } from "src/hooks";
import CommandService, { RobotVersion } from "src/services/CommandService";
import illustration from "src/robot_commands.png";
import { Alert, Backdrop } from "@mui/material";
import { useDashboard } from "../DashboardProvider";
import { useSection } from "../SectionProvider";
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        '& *': {fontFamily: theme.typography.fontFamily},
    },
    new: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        '&>*': {
            marginRight: "15px",
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));

export default function UpdateCommand(props: {atLeastSupport:boolean,deviceId:number,historyRef:any,deviceTypeId:number}) {

    const { atLeastSupport, deviceId, historyRef,deviceTypeId } = props;
    const curVersion = useDashboard()?.state.primary.device.data.robotVersion?.value ?? "", agreementValid=((useDashboard()?.state.primary.device.data.service_agreement_valid?.value??'true')==='true');
    

    const classes = useStyles();

    const { displayToast } = useToast();

    const displayErrorToast = (val:any) => displayToast({
        message: val.message,
        severity: "error",
        withCloseIcon: true,
    });
    const badModem=useSection()?.data['Modem id']?.value.includes('PLS63')??false
    const fetchRobotVersions_ = useCallback(() => deviceTypeId===3?CommandService.getBaseStationVersions():CommandService.getRobotVersions(deviceId).then((val)=>badModem?val.filter((val)=>{
      const sp=    val.value.split('.')
      if (Number(sp[0])<9)
      return false
      if (Number(sp[0])===9 && Number(sp[1])<8)
      return false
      if (Number(sp[0])===9 && Number(sp[1])===8 && Number(sp[2])<3)
      return false
      return true
    }):val), [deviceId,deviceTypeId,badModem]);
    
    const { value: robotVersions, pending: robotVersionsLoading, exec: fetchRobotVersions } = useAsync(fetchRobotVersions_, {
        immediate: false,
        clearValueOnExec: false,
        onError: displayErrorToast,
    });
    useEffect(() => {
        fetchRobotVersions();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const [filterTestVersions, setFilterTestVersions] = useState(deviceTypeId!==3);

    const [newVersion, setNewVersion] = useState(null as RobotVersion | null);
    const [sending, setSending] = useState(false);
    const [errorOnHardwareFailure, setErrorOnHardwareFailure] = useState(true);
    const [override, setOverride] = useState(false);

    const fetchCommands = () => {
        if (historyRef && historyRef.current)
            (historyRef.current as any).fetchCommands();
    }
    const setSelectFirst = (val:boolean) => {
        if (historyRef && historyRef.current)
            (historyRef.current as any).setSelectFirst(val);
    }
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    
    const isSmaller = (version:string) => {
        const sp = version.split("."),cursp=curVersion.split('.');
        if (sp.length < 3)
            return false;
        let ve1:number, ve2:number, ve3:number,v1:number,v2:number,v3:number;
        
        try {v1 = Number(cursp[0].replace("RS",''));} catch {v1 = 0;}
        try {v2 = Number(cursp[1]);} catch {v2 = 0;}
        try {v3 = Number(cursp[2]);} catch {v3 = 0;}

        try {ve1 = Number(sp[0]);} catch {ve1 = 0;}
        try {ve2 = Number(sp[1]);} catch {ve2 = 0;}
        try {ve3 = Number(sp[2]);} catch {ve3 = 0;}
        if (ve1 < v1)
            return true;
        else if (ve1 > v1)
            return false;
        if (ve2 < v2)
            return true;
        else if (ve2 > v2)
            return false;
        if (ve3 <= v3)
            return true;
        return false;
    }

    return (
        <div className={classes.root}>

           {(robotVersions && (!isSmaller(robotVersions?.find((v)=>v.latest)?.value??"")) && ((<Alert severity={agreementValid?"info":'warning'}> {agreementValid?'Newer version available '+(robotVersions?.find((v)=>v.latest)?.value??""):"Newer version available. Subscription needed."} </Alert>)))}



           <div className={classes.new}>
            {(atLeastSupport) && <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={filterTestVersions}
                            onChange={(ev) => setFilterTestVersions(ev.target.checked)}
                            size="small"
                        />
                    }
                    label="hide test versions"
                />}
                <AutocompleteV2
                    style={{width: "350px"}}
                    selectOnTab
                    value={newVersion}
                    disabled={robotVersionsLoading || sending}
                    options={(robotVersions??[]).filter(v => {
                        if (atLeastSupport){
                            if (filterTestVersions)
                            return v.released
                            else return true
                        } else 
                        if (filterTestVersions)
                            return v.released && v.access;
                            else
                        return v.access;
                    })}
                    getOptionLabel={v => v.value}
                    renderOption={(v) => <div style={{display:"flex",flexDirection:"row"}}><div style={v.access&&atLeastSupport?{fontWeight:"bold"}:{}}>{v.value}</div>{(v.latest&&atLeastSupport) && <div>&nbsp;(latest)</div>}</div>}
                    noOptionsText="No versions available"
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label="Software Version"
                                placeholder="Choose a Software Version"
                            />
                        );
                    }}
                    onChange={(_ev, val) => {
                        setNewVersion(val);
                    }}
                />
                <RefreshIcon onClick={()=>{if (!robotVersionsLoading) fetchRobotVersions()}}/>
                
            {(atLeastSupport) && <div className={classes.new}>
                <Tooltip title="If the hardware check at the end of the install fails, stops installing and reverts!">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={errorOnHardwareFailure}
                                onChange={(ev) => setErrorOnHardwareFailure(ev.target.checked)}
                                size="small"
                                color="primary"
                            />
                        }
                        label="errorOnHardwareFailure"
                    />
                </Tooltip>
                <Tooltip title="Allows downgrading if the desired version is older than the currently installed one!">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={override}
                                onChange={(ev) => setOverride(ev.target.checked)}
                                size="small"
                                color="primary"
                            />
                        }
                        label="override"
                    />
                </Tooltip>
            </div>}
            </div>
            
           
            <div className={classes.new} style={{width:"100%",alignItems:"center"}}>
                <div style={{whiteSpace:"pre-wrap",display:"flex",flexDirection:"column",alignItems:"start",maxWidth:"calc(100% - 311px)"}}> 
                <span className={classes.new} style={{marginTop:"5px",fontWeight:"bold",marginBottom:"5px"}}>Command Description:</span>
                    Send an update command for the robot to update it to the specified software version.<br></br>
                      The robot can be VPN offline or VPN online when sending the command.
                    <br></br>
                     When VPN offline, the command will be executed when the robot gets VPN online.
                     
                    <span style={{fontWeight:"bold",fontStyle:"italic"}}>
                        The robot may not be operating or turned off while performing the update. <br></br>
You must ensure that the update is successful before using the robot again, 
otherwise you will leave the robot in an unspecified state</span>
                </div>
                    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                    >
                        <div style={{ background:"white", display:"flex",alignItems:"center",justifyContent:"center",padding:"50px"}}>
                            <img src={illustration} alt="lamp" style={{height:"80vh"}}/>
                        </div>
                    </Backdrop>
                    <div onClick={handleOpen}>
                    <img src={illustration} alt="lamp" style={{width:"300px",marginRight:"-300px"}} />
                    <div style={{marginTop:"5px",textAlign:"center"}}>Click to open</div>
                    </div>
                
            </div>
            <div className={classes.new}>
                
                <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    disabled={sending||newVersion===null}
                    onClick={() => {
                        setSending(true);
                        CommandService.postCommand({
                            robotId:deviceId,
                            type:"update",
                            deviceType:deviceTypeId,
                            args:{
                                version:1,
                                rs:(deviceTypeId===3?"BS":"")+ newVersion?.value??"",
                                errorOnHardwareFailure,
                                override,
                            }
                        }).then(() => {
                            displayToast({
                                message: "Command sent!",
                                severity: "success",
                                withCloseIcon: true,
                            });
                            setSelectFirst(true);
                            fetchCommands();
                            setSending(false);
                        }).catch(() => {
                            displayToast({
                                message: "Error sending command!",
                                severity: "error",
                                withCloseIcon: true,
                            });
                            fetchCommands();
                            setSending(false);
                        });
                    }}
                >
                    Send Command
                </Button>
            </div>
        </div>
    );
}