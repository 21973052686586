import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAsync, useErrorHandler } from "src/hooks";
import UserService from "src/services/UserService";
import { customersArraySelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import KnoxService from "src/services/KnoxService";
import { displayToast } from "src/redux/app/actions";

type CreateGroupInput = {
    accessgroups: number[];
    name: string
};

 function createGroup(input:CreateGroupInput){
    return KnoxService.createGroup(input.accessgroups,input.name)
}

export default function CreateGroupWidget({ widgetName, favouriteWidgets, setFavouriteWidgets}: WidgetProps) {
    const {
        handleSubmit,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<CreateGroupInput>({
        defaultValues: { accessgroups: [] , name:''},
    });

    const customers = useSelector(customersArraySelector);
    
    const getAccessGroups = useCallback(() => {
        return UserService.getAccessGroupsDto(customers.map((c)=>c.id))
    }, [customers]);

    const { value, error,  pending } = useAsync(getAccessGroups, {
        defaultPending: true,
    }); 

    const accessGroup=watch('accessgroups')
    const name=watch('name')

    useEffect(() => {
        register("accessgroups", defaultRules);
        register('name',defaultRules);
    }, [register]);


    const onComplete = useCallback(
        () => {
            reset();
            displayToast({
                message: `Successfully created group`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [reset]
    );
    
    const { exec: createKnoxGroup, pending: creatingGroup, error: error2 } = useAsync(
        createGroup,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    useEffect(()=>{setValue('name',accessGroup.map((ag:number)=>(accessGroups.find((agg:any)=>agg.id===ag)as any).name).join(','))},[JSON.stringify(accessGroup)]) //eslint-disable-line react-hooks/exhaustive-deps

    const groups=(Object.values(value??{} as any).flat(1))
    const accessGroups=groups.filter((g:any,index)=>index===groups.findIndex((g1:any)=>g1.id===g.id));
    
    return (
        <BaseWidget
            widgetName={widgetName}
            title="Create knox group"
            subTitle="Create knox group for tablets"
            onSubmit={handleSubmit(createKnoxGroup)}
            loading={pending||creatingGroup}
            error={error||error2}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            {(accessGroup as any).map((group:any,index:number)=>{
                return     <AutocompleteV2
                key={index}
                selectOnTab
                value={accessGroups.find((ag:any)=>ag.id===group)}
                disabled={pending}
                options={accessGroups.filter((ag:any)=>(!accessGroup.find((gn)=>gn===ag.id)||ag.id===group))}
                getOptionLabel={(ag:any) => {
                    return ag.name;
                }}
                noOptionsText="No such access group"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Access group"
                            placeholder="Choose access group"
                            required
                             
                        />
                    );
                }}
                onChange={(_ev, val:any) => {
                    if (val)
                    accessGroup[index]=val.id 
                    else
                    accessGroup.splice(index,1)
                    setValue('acessgroups',accessGroup)
                }
            }
                
                getOptionSelected={(a, b) => a.id === b.id}
                renderOption={(ag) => {
                    return (<div>{ag.name}</div>);
                }}
            />
           
            })}
            <AutocompleteV2
                selectOnTab
                // defaultValue={{}}
                value={{}}
                
                disabled={pending}
                options={accessGroups.filter((ag:any)=>!accessGroup.find((gn)=>gn===ag.id))}
                getOptionLabel={(ag:any) => {
                    return ag.name??'';
                }}
                noOptionsText="No such access group"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Access group"
                            placeholder="Choose access group"
                            required
                        />
                    );
                }}
                onChange={(_ev, val:any) => {
                    if (val)
                    setValue('accessgroups',[...accessGroup,val.id])
                    
                }
            }
                
                getOptionSelected={(a, b) => a.id === b.id}
                renderOption={(ag) => {
                    return (<div>{ag.name}</div>);
                }}
            />
            <TextField
                variant="outlined"
                margin="dense"
                disabled={pending}
                fullWidth
                required
                label="Name"
                placeholder="Choose name"
                value={name}
                onChange={(ev) => {
                    setValue("name", ev.target.value);
                }}
         
            />
         
        </BaseWidget>
    );
}
