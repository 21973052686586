import { useReducer, useEffect} from "react";
import { SupportDto } from "src/services/SupportService";

interface DataState {
    lookUp: Record<number, SupportDto>;
    data: SupportDto[];
}

const SET = "SET";
const CLEAR = "CLEAR";

const set = (data: SupportDto[]) => ({
    type: SET as typeof SET,
    data,
});

const clear = () => ({ type: CLEAR as typeof CLEAR });

type Action =
    | ReturnType<typeof set>
    | ReturnType<typeof clear>;

function dataReducer(state: DataState, action: Action): DataState {
    switch (action.type) {
        case SET: {
            const { data } = action;
            if (data.length === state.data.length) {
                break;
            }
            return {
                lookUp: data.reduce(
                    (acc, next) => {
                        acc[next.issueId] = next;
                        return (acc[next.issueId]) && acc;
                    },
                    {} as Record<number, SupportDto>
                ),
                data,
            };
        }
        case CLEAR: {
            if (state.data.length === 0) {
                break;
            }
            return {
                lookUp: {},
                data: [],
            };
        }
        default:
            break;
    }

    return state;
}

export default function useControlledData(
    uncontrolled: SupportDto[] | undefined
): { data: SupportDto[] } {
    const [{ data }, dispatch] = useReducer(dataReducer, {
        lookUp: {},
        data: [],
    });

    useEffect(() => {
        if (uncontrolled === undefined) {
            dispatch(clear());
            return;
        }

        dispatch(set(uncontrolled));
    }, [uncontrolled]);

    return {
        data,
    };
}
