import {  Grid, InputAdornment, MenuItem, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import LoadingButton from "src/components/LoadingButton";
import { isMobile } from "react-device-detect";
import PdfPreview from "src/components/PdfPreview";
import { useAsyncDebounce } from "react-table";
import { generateSavings } from "./SavingsPdfGenerator";
import QuoteService from "src/services/QuoteService";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const isChrome = (window as any).chrome !== undefined && (window as any).chrome !== null;


export type SavingsInput={
    largeFields:number,
    mediumFields: number,
    smallFields: number,
    otherFields:number,

    baseWage:number,
    trueWage:number,
    
    smallInit:number,
    mediumInit:number,
    largeInit:number,
    otherInit:number,

    smallInitTime:number,
    mediumInitTime:number,
    largeInitTime:number,
    otherInitTime:number,

    reLargeFields:number,
    reMediumFields: number,
    reSmallFields: number,
    reOtherFields:number,

    reLargeFieldsSessions:number,
    reMediumFieldsSessions: number,
    reSmallFieldsSessions: number,
    reOtherFieldsSessions:number,

    reLargeFieldsTime:number,
    reMediumFieldsTime: number,
    reSmallFieldsTime: number,
    reOtherFieldsTime:number,

    largeFieldsGalonsManual:number,
    mediumFieldsGalonsManual: number,
    smallFieldsGalonsManual: number,
    otherFieldsGalonsManual:number,

    largeFieldsGalonsTmr:number,
    mediumFieldsGalonsTmr: number,
    smallFieldsGalonsTmr: number,
    otherFieldsGalonsTmr:number,

    paintCost:number,
    currency:number;
    customer:string;
}
const importantKeys={
    'largeFields':'Large Fields',
    'mediumFields':"Medium Fields",
    'smallFields':"Small Fields",
    'otherFields':'Other Fields',
    'reLargeFields':"Large fields to remark",
    'reMediumFields':"Medium fields to remark",
    'reSmallFields':"Small fields to remark",
    'reOtherFields':"Other fields to remark",
},
keys={
    'baseWage':'Base Wage',
    'trueWage':"True Wage",
    
    'smallInit':"Initial Layouts per small field",
    'mediumInit':"Initial Layouts per medium field",
    'largeInit':"Initial Layouts per large field",
    'otherInit':"Initial Layouts per other field",

    'smallInitTime':"Time per initial layout for small field",
    'mediumInitTime':"Time per initial layout for medium field",
    'largeInitTime':"Time per initial layout for large field",
    'otherInitTime':"Time per initial layout for other field",

   

    'reLargeFieldsSessions':"Remark sessions for large fields",
    'reMediumFieldsSessions':"Remark sessions for medium fields",
    'reSmallFieldsSessions':"Remark sessions for small fields",
    'reOtherFieldsSessions':"Remark sessions for other fields",

    'reLargeFieldsTime':"Time per remarking for large field",
    'reMediumFieldsTime':"Time per remarking for medium field",
    'reSmallFieldsTime':"Time per remarking for small field",
    'reOtherFieldsTime':"Time per remarking for other field",

    'largeFieldsGalonsManual':"Manual: Estimated gallons per large field",
    'mediumFieldsGalonsManual':"Manual: Estimated gallons per medium field",
    'smallFieldsGalonsManual':"Manual: Estimated gallons per small field",
    'otherFieldsGalonsManual':"Manual: Estimated gallons per other field",

    'largeFieldsGalonsTmr':"Robot: Estimated gallons per large field",
    'mediumFieldsGalonsTmr':"Robot: Estimated gallons per medium field",
    'smallFieldsGalonsTmr':'Robot: Estimated gallons per small field',
    'otherFieldsGalonsTmr':"Robot: Estimated gallons per other field",

    'paintCost':'Paint Cost',
    'currency':"Currency"
}
const useStyles = makeStyles((theme) => ({
    accordion:{
        
        boxShadow:'none', 
        border: '1px solid rgb(150,150,150)'
    },
    wrapper:{
        width: "calc(100% - 40px)",
        height: "calc(100vh - 70px)",
    },
    
}));

export default function Savings(){
    const styles=useStyles()

    const {
        handleSubmit,
        setValue,
        register,
        watch
    } = useForm<SavingsInput>({ defaultValues: {largeFields:0,mediumFields:0,smallFields:0,otherFields:0,baseWage:28,trueWage:49,smallInit:1,mediumInit:1,largeInit:1,otherInit:0,smallInitTime:6
    ,mediumInitTime:12,largeInitTime:15,otherInitTime:0,reLargeFields:0,reMediumFields:0,reSmallFields:0,reOtherFields:0,reLargeFieldsSessions:16,reMediumFieldsSessions:16,reSmallFieldsSessions:16,reOtherFieldsSessions:0,
reLargeFieldsTime:6,reMediumFieldsTime:4,reSmallFieldsTime:2,reOtherFieldsTime:0,largeFieldsGalonsManual:9,mediumFieldsGalonsManual:6,smallFieldsGalonsManual:3,otherFieldsGalonsManual:0,largeFieldsGalonsTmr:4.5,mediumFieldsGalonsTmr:3,smallFieldsGalonsTmr:1.5,otherFieldsGalonsTmr:0,
paintCost:15,currency:0,customer:""}})
    
    useEffect(() => {
        for (const key of [...Object.keys(importantKeys),...Object.keys(keys),'customer']){
            register(key as keyof SavingsInput)
        }

    }, [register]);

    const values={} as SavingsInput
    for (const key of [...Object.keys(importantKeys),...Object.keys(keys),'customer']){
        values[key as (keyof SavingsInput)]=watch(key as any)
    }
    
    const [pdfPreview, setPdfPreview] = useState("");
    const [pdf, setPdf] = useState(undefined as unknown as  Blob );

    useEffect(()=>{
        QuoteService.getSavingsPdf().then((pdf)=>setPdf(pdf))
    },[])

    const createPreviewDebounced = useAsyncDebounce(generateSavings, 500);

    const preview=()=>{
            createPreviewDebounced({...values,pdf}).then((r:any )=> {
                URL.revokeObjectURL(pdfPreview);
                setPdfPreview(r.url);
            });
        }
    return ( <Grid container className={styles.wrapper}>
                    <Typography component="h1" variant="h4" align="center" style={{width:'100%'}}>
                        Savings Calculator
                    </Typography>
                    <Grid  item container xs={12} style={{height:'100%'}}>
                        <Grid container item sm={12} xl={4} style={{padding:20}} direction="column" spacing={2}>
                            <Grid item>
                                 <Typography> 
                               Parameters:
                                </Typography>
                            </Grid>
                              

                                    <Grid   item container spacing={2}>
                                        <Grid   item container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField variant="outlined" value={values['customer']} size="small" style={{width:"350px"}}
                                            onChange={(e)=>setValue('customer',(e.target.value))}
                                            label="Customer Name"
                                            />
                                        </Grid>
                                        
                                            {Object.keys(importantKeys).map((key: any)=>(<Grid  item key={key}>
                                            <TextField variant="outlined" type="number" value={values[key as keyof SavingsInput]} size="small" style={{width:"350px"}}
                                            onChange={(e)=>setValue(key,Number(e.target.value))}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{importantKeys[key as keyof typeof importantKeys]}</InputAdornment>,
                                            }}/>
                                        </Grid>))}
                                        </Grid>
                                        <Grid item >
                                                <Accordion style={{width:'350px'}} className={styles.accordion}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}}/>} 
                                                    >Additional parameters </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                             {Object.keys(keys).map((key: any)=>(<Grid  item key={key}>
                                                                {key==='currency'?
                                                                <Select variant="outlined" value={values[key as keyof SavingsInput]} style={{width:"320px",height:'40px'}}
                                                                onChange={(e)=>setValue(key,(e.target.value))}
                                                               
                                                                >
                                                                    <MenuItem value={0}>EUR</MenuItem>
                                                                    <MenuItem value={1}>USD</MenuItem>
                                                                </Select>:
                                                                <TextField variant="outlined" type="number" value={values[key as keyof SavingsInput]} size="small" style={{width:"320px"}}
                                                                onChange={(e)=>setValue(key,Number(e.target.value))}
                                                               label={keys[key as keyof typeof keys]}
                                                                />}
                                                                </Grid>))}
                                                        </Grid>
                                                        
                                                         
                                                    </AccordionDetails>
                                                </Accordion>

                                        </Grid>
                                    </Grid>
                            <Grid item>
                                <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            loading={false}
                            style={{width:"350px"}}
                            onClick={handleSubmit(preview)}
                            >
                                Calculate Savings
                            </LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} xl={8}>
                        <object data={pdfPreview} style={{width: "100%", height: "100%"}} title="pdf preview">
                            {(isChrome && !isMobile)
                                ? <iframe src={pdfPreview} style={{width: "100%", height: "100%"}} title="pdf preview"/>
                                : <PdfPreview src={pdfPreview} style={{width: "100%", height: "100%"}}/>
                            }
                        </object>
                        </Grid>
                    </Grid>
                </Grid>
    )
}