import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AutoSizer from "react-virtualized-auto-sizer";
import DataGridV2 from "src/components/DataGrid";
import BaseView from "src/views/BaseView";
import ToggleTranslate from "src/views/Devices/ToggleTranslate";
import useColumns from "./useColumns";
import useControlledData from "./useControlledData";
import TranslationProvider, { useTranslator } from "src/views/Devices/TranslationProvider";
import { useAsync, useGetLatest } from "src/hooks";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { isMobile } from "react-device-detect";
import { Row, IdType, ColumnInstance } from "react-table";
import ExportButton from "src/components/ExportButton";
import SupportService, { SupportDto } from "src/services/SupportService";
import { customersSelector } from "src/redux/app/selectors";
import ExportCSV from "../Devices/Overview/ExportCSV";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginBottom: theme.spacing(2),
        "& + *": {
            marginBottom: theme.spacing(2),
        },
    },
    container: {
        minHeight: 600,
        height: "calc(100vh - 100px)",
        [theme.breakpoints.up("md")]: {
            height: "calc(98.5vh - 16px)",
        },
        [theme.breakpoints.up("xl")]: {
            height: "calc(99vh - 16px)",
        },
        "& > :last-child": {
            height: "calc(100% - 48px - 48px - 12px)",
            "& > :first-child": {
                width: "unset !important",
            },
        },
    },
    containerWrapper: {
        [theme.breakpoints.up("md")]: {
            overflow: "hidden",
        },
    },
    newIssueButton: {
        marginLeft: "20px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    legend: {
        "&>*": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
        },
        "&>*>*:nth-child(1)": {
            border: "1px solid black",
            width:"12px",
            height: "12px",
            marginRight: "5px",
        },
    },
    dialogTable: {
        maxHeight: "calc(30vh - 200px)",
        overflow: "auto",
        '& table': {
            width: "100%",
            borderCollapse: "collapse",
            border: "solid 1px black",
        },
        '& td': {
            border: "solid 1px black",
        },
        '& th': {
            border: "solid 1px black",
        },
    },
}));

const SupportOverview = React.memo(function Overview() {
    const classes = useStyles();

    const fetchData = useCallback(
        () => SupportService.getLatestSupportIssue(), []
    );

    const { dto: customers, loaded: customersLoaded } = useSelector(
        customersSelector
    );

    const { value, error, exec } = useAsync(fetchData, {
        clearValueOnExec: false,
    });
    const { data, keys, dictionary } = value! || {};

    const [currentTranslator, setCurrentTranslator] = useState({} as any);
    const [currentGrid, setCurrentGrid] = useState({columns: [], filteredData : []} as {columns: ColumnInstance<any>[], filteredData : Row<any>[]});

    return (
        <TranslationProvider
            deviceTypeId={-1}
            customers={customers}
            translationsDictionary={dictionary || {}}
        >
            <BaseView loading={value === undefined || !customersLoaded} error={error} retry={exec}>
                <Container
                    component="main"
                    maxWidth={false}
                    className={classes.container}
                >
                    <div style={{display: "flex",justifyContent: "space-between",flexDirection: "row"}}>
                        <Typography
                            component="h1"
                            variant="h5"
                            className={classes.titleText}
                        >
                            {"Support Overview"}
                        </Typography>
                        <div style={{display: "flex",justifyContent: "flex-start",flexDirection: "row"}}>
                            <ExportButton
                                onClick={ExportCSV(currentGrid,currentTranslator)}
                            />
                        </div>
                    </div>
                    <Typography component="section">
                        Support Issues can be seen below. Clicking on{" "}
                        {isMobile ? "the ID column" : "a row"} will bring you to
                        the detailed view of the issue.
                    </Typography>
                    <div>
                        <OverviewContent
                            {...{ keys, data, setCurrentTranslator, setCurrentGrid, isSelectorMode:false }}
                        />
                    </div>
                </Container>
            </BaseView>
        </TranslationProvider>
    );
});

interface OverviewContentProps {
    keys: string[];
    data: SupportDto[];
    setCurrentTranslator?: React.Dispatch<any>;
    setCurrentGrid?: React.Dispatch<any>;
    isSelectorMode: boolean;
}

function OverviewContent(props: OverviewContentProps) {
    const { keys, data: uncontrolledData, setCurrentTranslator, setCurrentGrid, isSelectorMode } = props;

    const { data } = useControlledData(uncontrolledData);
    const { fixed: fixedHeader, keys: keyHeaders } = useColumns({
        keys,
        isSelectorMode,
    })!;

    // Navigate on click
    const dispatch = useDispatch();
    const navigate = useCallback(
        (original: SupportDto) => {
            const url = "/support/" + original.issueId;
            dispatch(push(url));
        },
        [dispatch]
    );

    const translator = useTranslator();
    const getTranslator = useGetLatest(translator);
    useEffect(() => {
        if (setCurrentTranslator !== undefined)
            setCurrentTranslator(getTranslator());
    }, [setCurrentTranslator, getTranslator]);
    const globalFilter = useCallback(
        (
            rows: Row<SupportDto>[],
            columnIds: IdType<SupportDto>[],
            filterValue: any
        ) => {

            if(filterValue?.keyword !== undefined)
            {
                if(filterValue.strict && filterValue.keyword.length){
                    return rows.filter((row) => {
                        return columnIds.some((id) => {
                            return String(getTranslator().translate(id, row.values[id])) === filterValue.keyword;
                        });
                    });
                }
                else{ 
                    const keyword = String(filterValue.keyword).toLowerCase();
                    return rows.filter((row) => {
                        return columnIds.some((id) => {
                            return String(getTranslator().translate(id, row.values[id]))
                                .toLowerCase()
                                .includes(keyword);
                        });
                    });
                }
            }
            else{
                filterValue = String(filterValue).toLowerCase();
                return rows.filter((row) => {
                    return columnIds.some((id) => {
                        return String(getTranslator().translate(id, row.values[id]))
                            .toLowerCase()
                            .includes(filterValue);
                    });
                });
            }
        },
        [getTranslator]
    );

    (globalFilter as any).autoRemove = useCallback((val: any) => !val, []);

    const toolbarProps = useMemo(
        () => ({
            LeftAdornment: <ToggleTranslate />,
            hideColumnsProps: {
                filter: (
                    columns: ColumnInstance<SupportDto>[],
                    filter: string
                ) => {
                    if (filter) {
                        const reg = new RegExp(filter.trim(), "i");
                        return columns.filter((c) =>
                            reg.test(getTranslator().translateKey(c.id))
                        );
                    }
                    return columns;
                },
            },
            enableStrictSearch: true,
        }),
        [getTranslator]
    );

    return (
        <AutoSizer>
            {({ height, width }) => (
                <DataGridV2<SupportDto>
                    name={"tmr/overview-table/support-issues-readonly"}
                    fixedColumn={fixedHeader}
                    columns={keyHeaders}
                    data={data}
                    toolbarProps={toolbarProps}
                    onCellClick={navigate}
                    limitClickToFixed={isMobile}
                    globalFilter={globalFilter}
                    height={height}
                    width={width}
                    setCurrentGrid={setCurrentGrid}
                />
            )}
        </AutoSizer>
    );
}
export default SupportOverview;