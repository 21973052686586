import React from "react";
import { makeStyles } from "@material-ui/core";
import { TableResizerProps } from "react-table";
import classNames from "src/utils/classNames";

const useStyles = makeStyles((theme) => ({
    resizeHandle: {
        position: "absolute",
        cursor: "col-resize !important",
        zIndex: 1,
        opacity: 0,
        borderLeft: `1px solid ${theme.palette.primary.light}`,
        borderRight: `1px solid ${theme.palette.primary.light}`,
        height: "50%",
        top: "25%",
        transition: "all linear 100ms",
        right: -2,
        width: 4,
        "&.handleActive": {
            opacity: 1,
            border: "none",
            backgroundColor: theme.palette.primary.light,
            height: "calc(100% - 4px)",
            top: "2px",
            right: -1,
            width: 1,
        },
    },
}));

export interface ColumnResizerProps {
    getResizerProps: (props?: TableResizerProps) => TableResizerProps;
    isResizing?: boolean;
    className?: string;
}

export default React.memo(function ColumnResizer(props: ColumnResizerProps) {
    const classes = useStyles();
    const { getResizerProps, isResizing, className } = props;

    return (
        <div
            {...getResizerProps({
                className: classNames(
                    {
                        handleActive: isResizing,
                    },
                    classes.resizeHandle,
                    className
                ),
            })}
        />
    );
});
