import React from "react";
import { useSelector } from "react-redux";
import { userAccessSelector } from "src/redux/auth/selectors";
import NotFound from "../NotFound";
import { UserActionsLogTable } from "../Administration/Tables";
import Administration from "./AdministrationOperations";

export function UserActionsLog() {
    const administrationAccess=useSelector(userAccessSelector).includes('UserActions')
    const { innerHeight: height } = window;

    if (!administrationAccess) return (<NotFound />);
    return (
        <UserActionsLogTable height={height} />
    );
}

export function AdministrationOperations() {
    return (
        <Administration/>
    );
}