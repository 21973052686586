import { CustomersDto } from "src/services/UserService";
import { removeItem, utcTimezone } from "src/utils/common";
import {
    CONTROL_TOAST,
    SET_CONNECTION_STATE,
    LOADING_CUSTOMERS_DTO,
    SET_CUSTOMERS_DTO,
    AppAction,
    AppState,
    ConnectionState,
    CREATE_CUSTOMER,
    DELETE_CUSTOMER,
    CONTROL_DIALOG,
    SET_OPERATIONS,
    SET_QUOTES,
    SET_ROBOTS,
    SET_TABLETS,
    SET_USER,
    SET_USERS,
    ADD_USER,
    REMOVE_USER,
    UPDATE_USER,
    SET_TIMEZONE,
    SET_CONTACTS,
    SET_COMPANIES,
    SET_BASESTATIONS,
    SET_EXCLUDE_DEPRECATED,
    SET_GOOGLE_MAPS_TOKEN,
    SET_ACCESS_FIELDS,
} from "./types";

const initialState: AppState = {
    selectedTimezone: utcTimezone,
    excludeDeprecated: true,
    users: [],
    user: {username: "", customerId: [1], userAccess: [], contractAccess: [],twoFactorAuthentificationCode:"",isTwoFactorAuthentificationEnabled:false},
    robots: [],
    tablets: [],
    basestations: [],
    operations: [],
    quotes: {freeTemplates: [], freeTemplatesQty: 0, allFreeAfterQty: 999, quotationNumber: 0, contracts: []},
    contacts: [],
    companies: [],
    accessFields: [],
    dialog: {
        active : false,
        prompt : {
            dialogText : "Do you wish to proceed?",
            positiveButtonText : "yes",
            negativeButtonText : "no",
        },
        positiveButtonAction : () => {/*noop*/},
        negativeButtonAction : () => {/*noop*/},
    },
    toast: {
        open: false,
    },
    connectionState: {
        connectedToServer: false,
        connectionState: ConnectionState.Idle,
    },
    customers: {
        dto: {},
        loading: false,
        loaded: false,
    },
    googleMapsToken:""
};

export default function reducer(
    state = initialState,
    action?: AppAction
): AppState {
    switch (action?.type) {
        case SET_TIMEZONE: {
            return {
                ...state,
                selectedTimezone: action.payload.timezone,
            };
        }
        case SET_EXCLUDE_DEPRECATED: {
            return {
                ...state,
                excludeDeprecated: action.payload.excludeDeprecated,
            };
        }
        case UPDATE_USER: {
            const user = action.payload.user;
            const index = state.users.findIndex(u => u.username === action.payload.username);
            return {
                ...state,
                users: [...state.users.slice(0, index),user,...state.users.slice(index+1)],
            };
        }
        case REMOVE_USER: {
            const {
                payload: user,
            } = action;
            const index = state.users.findIndex(u => u.username === user.username);
            return {
                ...state,
                users: [...state.users.slice(0, index),...state.users.slice(index+1)],
            };
        }
        case ADD_USER: {
            const {
                payload: user,
            } = action;
            return {
                ...state,
                users: [...state.users, user],
            };
        }
        case SET_USERS: {
            const {
                payload: users,
            } = action;
            return {
                ...state,
                users,
            };
        }
        case SET_USER: {
            const {
                payload: user,
            } = action;
            return {
                ...state,
                user,
            };
        }
        case SET_ROBOTS: {
            const {
                payload: robots,
            } = action;
            return {
                ...state,
                robots,
            };
        }
        case SET_TABLETS: {
            const {
                payload: tablets,
            } = action;
            return {
                ...state,
                tablets,
            };
        }
        case SET_BASESTATIONS: {
            const {
                payload: basestations,
            } = action;
            return {
                ...state,
                basestations,
            };
        }
        case SET_OPERATIONS: {
            const {
                payload: operations,
            } = action;
            return {
                ...state,
                operations,
            };
        }
        case SET_QUOTES: {
            const {
                payload: quotes,
            } = action;
            return {
                ...state,
                quotes,
            };
        }
        case SET_CONTACTS: {
            const {
                payload: contacts,
            } = action;
            return {
                ...state,
                contacts,
            };
        }
        case SET_COMPANIES: {
            const {
                payload: companies,
            } = action;
            return {
                ...state,
                companies,
            };
        }
        case SET_ACCESS_FIELDS: {
            const {
                payload: accessFields,
            } = action;
            return {
                ...state,
                accessFields,
            };
        }
        case CONTROL_DIALOG: {
            return {
                ...state,
                dialog: {
                    active : action.payload.active ?? state.dialog.active,
                    prompt : {
                        dialogText : action.payload.prompt?.dialogText ?? "Do you wish to proceed?",
                        positiveButtonText : action.payload.prompt?.positiveButtonText ?? "yes",
                        negativeButtonText : action.payload.prompt?.negativeButtonText ?? "no",
                        zIndex : action.payload.prompt?.zIndex,
                    },
                    positiveButtonAction : action.payload.positiveButtonAction,
                    negativeButtonAction : action.payload.negativeButtonAction,
                },
            };
        }
        case CONTROL_TOAST: {
            return {
                ...state,
                toast: {
                    open: action.payload.open,
                    message: action.payload.message || state.toast.message,
                },
            };
        }
        case SET_CONNECTION_STATE: {
            const {
                payload: { connectionState },
            } = action;
            if (state.connectionState.connectionState === connectionState) {
                break;
            }
            return {
                ...state,
                connectionState: {
                    connectedToServer:
                        connectionState === ConnectionState.Connected,
                    connectionState: connectionState,
                },
            };
        }
        case LOADING_CUSTOMERS_DTO: {
            return {
                ...state,
                customers: {
                    ...state.customers,
                    error: undefined,
                    loading: true,
                },
            };
        }
        case SET_CUSTOMERS_DTO: {
            const { dto, error } = action.payload;

            return {
                ...state,
                customers: {
                    loaded: error === undefined,
                    loading: false,
                    error,
                    dto: error === undefined ? dto : state.customers.dto,
                },
            };
        }
        case CREATE_CUSTOMER: {
            const id = action.payload.id;

            return {
                ...state,
                customers: {
                    ...state.customers,
                    dto: {
                        ...state.customers.dto,
                        [id]: {
                            ...action.payload,
                        },
                    },
                },
            };
        }
        case DELETE_CUSTOMER: {
            const id = action.payload.id;

            return {
                ...state,
                customers: {
                    ...state.customers,
                    dto: removeItem<CustomersDto>(state.customers.dto, id)
                },
            };
        }
        case SET_GOOGLE_MAPS_TOKEN:{
            
            const token=action.payload
            return {
                ...state,
                googleMapsToken: token
            }
        }
        default:
            return state;
    }
}