import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';

import EditCustomerAccessGroupsPopup from "./popups/EditCustomerAccessGroupsPopup.jsx"

const small_style = {
  width: 1,
  whiteSpace: "nowrap"
}

const big_style = {};

function AccessGroupRow(props) {
  return (
    <>
      {props.AccessGroups.map(ag => <Chip label={ag.Name} key={ag.Id} />)}
    </>
  )
}
function CustomersRow(props) {
  return (
    <>
      {props.Customers.map(c => <Chip label={c.Name} key={c.Id} />)}
    </>
  )
}

class ManageCustomerAccessGroupsSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      accessGroups: [],
      showAccessGroups: false,
      onlyShowEmpty: false
    };
    this.refresh = this.refresh.bind(this);
    this.refreshAfterEdit = this.refreshAfterEdit.bind(this);

    this.CustomerHeaders = [
      {
        Header: 'Customer',
        key: 'Name',
        align: "left",
        style: small_style,
        search: "Name"
      },
      {
        Header: 'Dealer',
        key: 'DealerName',
        align: "left",
        style: small_style,
        search: "DealerName"
      },
      {
        Header: 'AccessGroups',
        key: 'AccessGroupsElement',
        align: "left",
        style: big_style
      },
      {
        Header: '',
        key: 'EditElement',
        align: "left",
        style: small_style
      }
    ];

    this.AccessGroupHeaders = [
      {
        Header: 'AccessGroup',
        key: 'Name',
        align: "left",
        style: small_style,
        search: "Name"
      },
      {
        Header: 'Customers',
        key: 'CustomerElement',
        align: "left",
        style: big_style
      },
      {
        Header: '',
        key: 'EditElement',
        align: "left",
        style: small_style
      }
    ];

  }



  componentDidMount() {
    this.refresh()
  }

  refreshAfterEdit() {
    setTimeout(this.refresh, 1000);
  }

  refresh(event) {
    if (event)
      event.preventDefault();
    axios(`/api/dbtool/customer_accessgroups`)
      .then(response => response.data[0])
      .then(state => {

        let customers = {};
        let accessGroups = {};

        for (let r of state) {
          if (r.AccessGroupId && !accessGroups[r.AccessGroupId])
            accessGroups[r.AccessGroupId] = { Id: r.AccessGroupId, Name: r.AccessGroupName, Customers: {} };
          if (r.CustomerId) {
            if (!customers[r.CustomerId])
              customers[r.CustomerId] = { Id: r.CustomerId, Name: r.CustomerName, DealerName: r.DealerName, AccessGroups: {} };
            if (r.AccessGroupId) {
              accessGroups[r.AccessGroupId].Customers[r.CustomerId] = customers[r.CustomerId];
              customers[r.CustomerId].AccessGroups[r.AccessGroupId] = accessGroups[r.AccessGroupId];
            }
          }
        }

        customers = Object.values(customers);
        accessGroups = Object.values(accessGroups);


        for (let c of customers) {
          c.AccessGroups = Object.values(c.AccessGroups);
          c.AccessGroupsElement = AccessGroupRow({ AccessGroups: c.AccessGroups });
          c.EditElement = <EditCustomerAccessGroupsPopup Customer={c} AccessGroups={c.AccessGroups} whenSuccess={this.refreshAfterEdit} />
        }

        for (let a of accessGroups) {
          a.Customers = Object.values(a.Customers);
          a.CustomerElement = CustomersRow({ Customers: a.Customers });
        }

        customers = customers.sort((a, b) => {
          return a.Name > b.Name;
        });

        this.setState({ customers: customers, accessGroups: accessGroups });
      }
      );
  }

  render() {
    return (
      <React.Fragment>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Accessgroups on customers</Grid>
          <Grid item>
            <Switch checked={this.state.showAccessGroups} onChange={event => this.setState({ showAccessGroups: event.target.checked })} name="checkedC" />
          </Grid>
          <Grid item>Customers on Accessgroups</Grid>
        </Grid>
        {
          !this.state.showAccessGroups ?
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Only show empty</Grid>
              <Grid item>
                <Switch checked={this.state.onlyShowEmpty} onChange={event => this.setState({ onlyShowEmpty: event.target.checked })} name="checkedCB" />
              </Grid>

            </Grid>
            :
            <React.Fragment></React.Fragment>
        }


        {
          this.state.showAccessGroups ?
            <Table id="customer_access_groups_table" headeres={this.AccessGroupHeaders} data={this.state.accessGroups} refresh={this.refresh} />
            :
            <Table id="customer_access_groups_table" headeres={this.CustomerHeaders} data={this.state.onlyShowEmpty ? this.state.customers.filter(c => !(Object.keys(c.AccessGroups)?.length)) : this.state.customers} refresh={this.refresh} />
        }

      </React.Fragment>
    );
  }
}

export default ManageCustomerAccessGroupsSections;