import { ReactNode, ReactElement, isValidElement } from "react";

export type RenderProps<T = any> = ReactNode | ReactElement<T>;

export default function mapRenderProps<T>(props: RenderProps<T>, arg?: T) {
    if (isValidElement(props)) {
        return props;
    }

    if (typeof props === "function") {
        return props(arg);
    }

    return props;
}
