import axios from "axios";

type TFAGenerateResponse={
    link: string
}
type TFACheckTokenResponse={
    valid: boolean
}

export default class TwoFactorAuthentificationService{
    static async getCode(){
        const username=localStorage.getItem('tmr/user') as string
        const res=await axios.post('/api/2fa/generate?username='+username)      
        return ((res.data as TFAGenerateResponse).link)
    }
    static async enable(enabled:boolean,token:number){
       
            const username=localStorage.getItem('tmr/user') as string
            
            
            if (username && token){
                try {
                    const res=(await axios.post('/api/2fa/turnon?username='+username+'&token='+token+"&on="+enabled))
                    return res.status

                } catch (error) {
                    return 400
                }
            
            
            }
       
    }
    static async checkToken(token:number){
        const username=localStorage.getItem('tmr/user') as string
        const res=await axios.post('/api/2fa/verify?token='+token+"&username="+username)
        
        return (res.data as TFACheckTokenResponse).valid
    }
}