import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "src/components/LoadingButton";
import { useAsync, useErrorHandler } from "src/hooks";
import UserService from "src/services/UserService";
import getErrorProps from "src/utils/getErrorProps";
import BaseView from "../BaseView";
import useFormStyles from "./useFormStyles";

const useStyles = makeStyles(() => ({
    root: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        left: "0px",
        top: "0px",
    },
}));

type RequestPwResetInput = {
    username: string;
};

const defaultRules = {
    required: "This field is required",
};

export default function ForgotPassword() {
    const notLoggedInClasses = useStyles();
    const classes = useFormStyles();
    const [requestPwResetMessage, setRequestPwResetMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const {
        handleSubmit,
        errors,
        setError,
        control,
    } = useForm<RequestPwResetInput>();

    const onRequestPwReset = useCallback(
        (input: RequestPwResetInput) => {
            setRequestPwResetMessage("");
            return UserService.requestPasswordReset(input.username);
        },
        []
    );

    const onPwResetSent = useCallback(
        (res) => {
            setSuccess(true);
            setRequestPwResetMessage("We sent a password reset email to \""+res.email+"\".");
        }, []
    );

    const onRequestPwResetError = useErrorHandler({
        onValidationError: setError as any,
        fallback: useCallback((e) => {
            if (e !== undefined && e.message !== undefined && e.message.includes("not found"))
                setRequestPwResetMessage("User not found!");
            else
                setRequestPwResetMessage("An unknown error has occurred. Please try again.");
        }, []),
    });

    const { exec: requestPwReset, pending } = useAsync(
        onRequestPwReset,
        {
            immediate: false,
            onComplete: onPwResetSent,
            onError: onRequestPwResetError,
            defaultPending: false,
        }
    );

    return (<div className={notLoggedInClasses.root}>
        <BaseView loading={false}>
            <Box component="main" className={classes.container}>
                <div className={classes.paperFlex}>
                    <Typography component="h1" variant="h4">
                        Forgot Password
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(requestPwReset)}
                        noValidate
                    >
                        <Controller
                            key={"username"}
                            name={"username"}
                            control={control}
                            defaultValue=""
                            rules={defaultRules}
                            render={(props) => {
                                return (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        margin="normal"
                                        required={true}
                                        disabled={pending || (requestPwResetMessage !== "" && success)}
                                        fullWidth
                                        id={"username"}
                                        label={"Username"}
                                        {...getErrorProps(errors, "username")}
                                    />
                                );
                            }}
                        />
                        {(requestPwResetMessage !== "") && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={2}
                                color={success ? "success.main" : "error.main"}
                            >
                                <Typography style={{whiteSpace:"pre-wrap"}}>{requestPwResetMessage}</Typography>
                            </Box>
                        )}
                        <LoadingButton
                            className={classes.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={pending}
                            disabled={(requestPwResetMessage !== "" && success)}
                        >
                            Request Password Reset Email
                        </LoadingButton>
                    </form>
                </div>
            </Box>
        </BaseView>
    </div>);
}