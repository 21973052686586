import React, { useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "../redux/auth/selectors";
import { userSelector } from "src/redux/app/selectors";
import useDialog from "src/hooks/useDialog";
export const disclaimer='Access to the service center is strictly individual and credentials must not be shared.\n Please maintain the confidentiality of your access information. \nAll activities are monitored, and in the event of suspicious activity, access may be terminated without prior notice.'
export default function PrivateRoute({
    children,
    needVerified,
    ...rest
}: Omit<RouteProps, "component"> & {needVerified?: boolean}) {
    const authenticated = useSelector(isAuthenticatedSelector);
    const isVerified = useSelector(userSelector).verified ?? true;
    if (needVerified === undefined)
        needVerified = false;
    const {displayDialog}=useDialog()
    const hashCode = (s:string) => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)
    useEffect(()=>{
    if (authenticated && Number(window.localStorage.getItem('disclaimer'))!==hashCode(disclaimer)){
        displayDialog({dialogText:disclaimer,positiveButtonText:"agree",negativeButtonText:""}).then(()=> window.localStorage.setItem('disclaimer',hashCode(disclaimer)+''))
    }},[authenticated,displayDialog])
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (!authenticated)
                    return (<Redirect
                        to={{
                            pathname: "/account/signin",
                            state: { from: location },
                        }}
                    />);
                if (needVerified && !isVerified)
                    return (<Redirect
                        to={{
                            pathname: "/account/me",
                            state: { from: location },
                        }}
                    />);
                return (children);
            }}
        />
    );
}
