import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';


export default function EditPurchacePopup(props) {
    const [open, setOpen] = React.useState(false);


    const initialState = {
        json: ""
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )


    const handleClickOpen = () => {

        // Update selects
        //updateCustomers();
        //updateFeatureGroups();
        setState({ json: "" });

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefund = () => {
        let data = {
            i: props.purchase.Id,
            j: state.json
        }
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        axios("/api/dbtool/purchase_refund", requestOptions)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Purchase could not be refunded");
            });
    }

    return (

        <>
            <IconButton component="span" onClick={handleClickOpen} >
                <MoneyOffIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Edit purchase</DialogTitle>
                <DialogContent style={{ overflow: "hidden", minWidth: "450px" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Customer"
                                value={props.purchase.Customer}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Feature group"
                                value={props.purchase.FeatureGroup}
                                disabled
                            />
                        </Grid>

                        {props.purchase.TransactionId ? (
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Transaction ID"
                                        value={props.purchase.TransactionId}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" gutterBottom style={{ color: "red" }}>
                                        REMEMBER TO REFUND ON STRIPE BEFORE CLICKING THE REFUND BUTTON HERE
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="JSON"
                                        multiline
                                        rowsMax={8}
                                        value={state.json}
                                        onChange={(e) => setState({ json: e.target.value })}
                                    />
                                </Grid>
                            </>
                        ) : <></>}
                        <Grid item xs={12}>
                            <DialogActions>
                                <Button onClick={handleRefund} color="primary" style={{ width: "100%" }}>
                                    REFUND
                                </Button>
                            </DialogActions>
                        </Grid>


                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        CLOSE
          </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
