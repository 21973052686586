import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import dateFormat from 'dateformat';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import DeprecateIcon from '@material-ui/icons/Archive';
import EditRobotVersionConfigurationPopup from "./popups/EditRobotVersionConfigurationPopup.jsx";

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

function TagCell(props) {
    let opt = props.options;
    return (<>
        <span key={opt.Id}>{opt.Tag}</span>
    </>
    );
}

function VersionConfigurationCell(props) {
    let now_s = dateFormat(new Date(), "yyyy-mm-dd");
    return (<>
        {props.data.Configations.map((conf, i) => {
            let period_lines = conf.From.map((a, i) => {
                if (conf.To[i] && (conf.To[i] < now_s))
                    return <div key={i} style={{ color: "yellow" }}>Available from: {conf.From[i]} to {(conf.To[i] ? conf.To[i] : "indefinitely")}<br /></div>
                else
                    return <div key={i}>Available from: {conf.From[i]} to {(conf.To[i] ? conf.To[i] : "indefinitely")}<br /></div>
            });
            let tooltopText = (
                <div>{period_lines}<br />
                    Priority: {conf.Priority}<br />
                    {conf.IsRC ? "This is released as an RC version" : ""} </div>);

            return (
                <EditRobotVersionConfigurationPopup
                    key={conf.ConfigurationId}
                    config={conf}
                    parentData={props.data}
                    tooltip={tooltopText}
                    chipLabel={conf.AccessGroupsLabel}
                    whenSuccess={props.whenSuccessEdit}
                    chipStyleBtn
                />
            )
        })}
        <EditRobotVersionConfigurationPopup
            chipLabel="+"
            whenSuccess={props.whenSuccessEdit}
            tooltip={"Create new feature configuration"}
            parentData={props.data}
            chipStyleBtn
            noIcon
            accessGroupsSaveId={"create_new_access_groups_for_robot_version"}
        />
    </>);
}

class ManageRobotSoftwareVersionSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            versions: [],
            configurations: [],
            only_show_active: true,
            hide_depricated: true,
            versions_on_confs: false,
            only_show_release_versions: true,
        };
        this.refreshVersions = this.refreshVersions.bind(this);

        this.headers = [{
            Header: 'Tag',
            key: 'TagElement',
            align: "left",
            style: small_style,
            search: "Tag"
        },
        {
            Header: 'Deprecated',
            key: 'Deprecated',
            align: "left",
            style: small_style,
            search: "Tag"
        },
        {
            Header: 'RobotSoftware version configuration',
            key: 'VersionConfigurationElement',
            align: "left",
            search: "Configations",
            ArraySeach: "AccessGroups",
            style: big_style
        },
        {
            Header: '',
            key: 'DeprecateElement',
            align: "right",
            style: small_style
        }


        ];

        this.confs_headers = [
            {
                Header: 'RobotSoftware version configuration',
                key: 'ConfLabel',
                align: "left",
                style: small_style,
                search: "ConfLabel"
            },
            {
                Header: 'Is RC',
                key: 'is_rc',
                align: "left",
                style: small_style
            },
            {
                Header: 'RobotSoftware version configuration',
                key: 'VersionConfigurationElement',
                align: "left",
                search: "Configations",
                ArraySeach: "AccessGroups"
            }
        ]
    }

    componentDidMount() {
        this.refreshVersions()
    }

    refreshVersions(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/robotversion_accessgroups_collections`)
            .then(response => response.data[0])
            .then(state => {

                let dict = {};
                let confs_dict = {};
                let used_access_groups = {};
                let confi_id_to_label = {};
                for (let a of state) {
                    if (!dict[a.RobotVersionId]) {
                        dict[a.RobotVersionId] = { Tag: a.RobotVersionTag, Id: a.RobotVersionId, Deprecated: (a.Deprecated) ? "Yes" : "", Configations: {} };
                        used_access_groups[a.RobotVersionId] = {};
                    }
                    if (a.AccessGroupsCollectionId) {
                        let confi_id = a.AccessGroupsCollectionId + "_" + a.IsRC;
                        let add_time_frame = false;
                        if (!dict[a.RobotVersionId].Configations[confi_id]) {
                            dict[a.RobotVersionId].Configations[confi_id] = { IsRC: a.IsRC, Priority: a.Priority, AccessGroupsCollectionId: a.AccessGroupsCollectionId, ConfigurationId: a.ConfigurationId, From: [], To: [], AccessGroups: [] };
                            used_access_groups[a.RobotVersionId][confi_id] = [];
                            add_time_frame = true;
                        }
                        if ((used_access_groups[a.RobotVersionId][confi_id].indexOf(a.AccessGroupId) === -1)) {
                            dict[a.RobotVersionId].Configations[confi_id].AccessGroups.push({ Id: a.AccessGroupId, Short: a.ShortName, Long: a.Name });
                            used_access_groups[a.RobotVersionId][confi_id].push(a.AccessGroupId);

                            if (dict[a.RobotVersionId].Configations[confi_id].AccessGroupsLabel)
                                dict[a.RobotVersionId].Configations[confi_id].AccessGroupsLabel += ","
                            else
                                dict[a.RobotVersionId].Configations[confi_id].AccessGroupsLabel = ""
                            dict[a.RobotVersionId].Configations[confi_id].AccessGroupsLabel += a.ShortName
                            confi_id_to_label[confi_id] = dict[a.RobotVersionId].Configations[confi_id].AccessGroupsLabel;
                        } else
                            add_time_frame = true;

                        if (add_time_frame) {
                            dict[a.RobotVersionId].Configations[confi_id].From.push(a.From.split("T")[0]);
                            if (a.To)
                                dict[a.RobotVersionId].Configations[confi_id].To.push(a.To.split("T")[0]);
                            else
                                dict[a.RobotVersionId].Configations[confi_id].To.push("");
                        }


                        if (!confs_dict[confi_id])
                            confs_dict[confi_id] = [];
                        if (confs_dict[confi_id].indexOf(a.RobotVersionId) === -1)
                            confs_dict[confi_id].push(a.RobotVersionId)

                    }
                }

                let versions = [];
                for (let v in dict) {
                    let confs = [];
                    for (let c in dict[v].Configations) {
                        confs.push(dict[v].Configations[c]);
                    }
                    dict[v].Configations = confs;
                    versions.push(dict[v]);
                }
                versions.reverse();

                let configurations = [];
                for (let c in confs_dict) {
                    let p = c.split("_");
                    let AccessGroupsCollectionId = parseInt(p[0])
                    let is_rc = p[1] === "1";
                    let ConfLabel = confi_id_to_label[c];

                    let VersionIds = confs_dict[c];
                    let Versions = VersionIds.map(VersionId => {
                        let VersionTag = dict[VersionId].Tag;
                        return { VersionId: VersionId, VersionTag: VersionTag };
                    });

                    configurations.push({
                        ConfLabel: ConfLabel,
                        AccessGroupsCollectionId: AccessGroupsCollectionId,
                        is_rc: is_rc,
                        versions: Versions
                    });
                }


                this.setState({
                    versions: versions.map((r, i) => {
                        r.TagElement = (<TagCell options={{ Tag: r.Tag, Id: r.Id }} version={r} refreshVersions={this.refreshVersions} />);
                        r.VersionConfigurationElement = "T"
                        r.VersionConfigurationElement = (<VersionConfigurationCell data={r} whenSuccessEdit={this.refreshVersions} />);
                        r.DeprecateElement = r.Deprecated ? <></> : <IconButton component="span" onClick={this.deprecateVersion.bind(this, r.Id)} ><DeprecateIcon /></IconButton>;
                        return r;
                    }),
                    configurations: configurations
                });

            });
    }

    deprecateVersion(VersionId) {
        let requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            
        };
        axios("/api/dbtool/deprecaterobotversion?vi="+VersionId, requestOptions)
            .then(response => response.data)
            .then(state => {
                
                if (state.success) {
                    this.refreshVersions();
                }
                else
                    alert("Could not deprecate robotversion");
            });
    }

    render() {
        let showVersions = this.state.versions;
        if (this.state.only_show_active) {
            showVersions = showVersions.filter(f => {
                return f.Configations.length;
            });
        }
        if (this.state.hide_depricated) {
            showVersions = showVersions.filter(f => {
                return f.Configations.length || !f.Deprecated;
            });
        }
        if (this.state.only_show_release_versions) {
            showVersions = showVersions.filter(f => {
                return f.Tag.match(/^\d+\.\d+\.\d+$/) || f.Configations.length;
            });
        }




        return (
            <React.Fragment>

                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.versions_on_confs}
                            onChange={event => this.setState({ versions_on_confs: event.target.checked })}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="versions on configurations"
                /> */}
                <br></br>

                {!this.state.versions_on_confs ?
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.only_show_active}
                                    onChange={event => this.setState({ only_show_active: event.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Only show used robot software versions"
                        />
                        <br></br>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.hide_depricated}
                                    onChange={event => this.setState({ hide_depricated: event.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Hide deprecated"
                        />
                        <br></br>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.only_show_release_versions}
                                    onChange={event => this.setState({ only_show_release_versions: event.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Only show release versions"
                        />

                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                }

                <br></br>
                {
                    this.state.versions_on_confs ?
                        <Table id="robot_versions_table" headeres={this.confs_headers} data={this.state.configurations} refresh={this.refreshVersions} />
                        :
                        <Table id="robot_versions_configurations_table" headeres={this.headers} data={showVersions} refresh={this.refreshVersions} />
                }


            </React.Fragment>
        );
    }
}

export default ManageRobotSoftwareVersionSection;