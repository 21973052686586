import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Robot } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { customersDtoSelector, robotsSelector, robotsSelectorUnfiltered } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { setRobots } from "src/redux/app/actions";

type SetRobotNameInput = {
    name: string;
    robot: Robot | null;
};

function updateRobots(input: SetRobotNameInput) {
    return (robots: Robot[]) => {
        const { robot, name } = input;
        if (robot) {
            return robots.map((r) => (r.id === robot.id ? { ...r, name } : r));
        }
        return robots;
    };
}

function setRobotName(input: SetRobotNameInput) {
    return ConfigurationsService.setRobotName({
        name: input.name,
        robotId: input.robot!.id,
    }).then(() => input);
}

export default function RobotNameWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<SetRobotNameInput>({
        defaultValues: { robot: null, name: "" },
    });

    
   

    const robots = useSelector(robotsSelector);
    const robotsUnfiltered = useSelector(robotsSelectorUnfiltered);
    const loadedDeviceInfo = robots.length > 0;
    const { displayToast } = useToast();
    const customersDto = useSelector(customersDtoSelector);
    const dispatch = useDispatch();
    
    
    const [robotNameWidgetLastRobotId,setRobotNameWidgetLastRobotId]=useLocalStorage("RobotNameWidgetLastRobotId",-1)
    

    const name = watch("name");
    const robot = watch("robot");



    useEffect(() => {
        register("robot", defaultRules);
        register("name", defaultRules);
    }, [register]);

    const onComplete = useCallback(
        (input: SetRobotNameInput) => {
            dispatch(setRobots(updateRobots(input)(robotsUnfiltered)));
            if (!saveChanges) reset();
            displayToast({
                message: `Successfully set the name of Robot ${
                    input.robot!.id
                } to ${input.name}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [dispatch, robotsUnfiltered, displayToast, reset,saveChanges]
    );

    const { exec: setName, pending: settingName, error } = useAsync(
        setRobotName,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = settingName || !loadedDeviceInfo;


    useEffect(()=>{
        
        if (saveChanges){
            if (robotNameWidgetLastRobotId && robotNameWidgetLastRobotId!==robot?.id){
            const rbt=robots.find(r=>r.id===robotNameWidgetLastRobotId)
            if (rbt){
            setValue('robot',rbt)
            setValue("name", rbt?.name ?? "");
            }}
        
    }
        
    },[saveChanges,robots,setValue,robot,robotNameWidgetLastRobotId])

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Change robot name"
            subTitle="Change the name of your robots."
            onSubmit={handleSubmit(setName)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <AutocompleteV2
                selectOnTab
                value={robot}
                disabled={loading}
                options={robots}
                getOptionLabel={(r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    return ret;
                }}
                noOptionsText="No such robot"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Robot"
                            placeholder="Choose a robot"
                            required
                            {...getErrorProps(errors, "robot")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("name", val?.name ?? "");
                    setValue("robot", val);
                    if (saveChanges){
                    setRobotNameWidgetLastRobotId(val?.id??-1)}
                }}
                
                getOptionSelected={(a, b) => a.id === b.id}
                renderOption={(r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    if (r.deprecated !== undefined && r.deprecated !== null)
                        return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                    return (<div>{ret}</div>);
                }}
            />
            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                label="Name"
                placeholder="Choose a new name"
                value={name}
                onChange={(ev) => {
                    setValue("name", ev.target.value);
                }}
                {...getErrorProps(errors, "name")}
            />
        </BaseWidget>
    );
}
