import moment from "moment";
import { ToastMessage } from "src/redux/app/types";

export const ZERO_WIDTH_WHITESPACE = "​";
export const HAIR_WIDTH_WHITESPACE = " ";

export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const toastError = (displayToast : (toast: ToastMessage) => void) => {
    return (val : any) => {
        displayToast({
            message: val.message,
            severity: "error",
            withCloseIcon: true,
        });
    };
};

export function removeItem<V extends Record<any, any>>(dict : V, key : string | number | symbol) : V {
    const filteredDict : NonNullable<any> = {};
    for (const currentKey in dict)
        if (currentKey != key) //eslint-disable-line eqeqeq
            filteredDict[currentKey] = dict[currentKey];
    return filteredDict;
}

export function getBackgroundColors(count: number, offset = 0) : string[] {
    if (count <= 0)
        return [];
    count = Math.ceil(count);
    if (offset < 0)
        offset = 0;
    if (offset > 1)
        offset = 1;
    const colors = new Array<string>(0);
    for (let i = 0; i < count; i++)
        colors.push("hsl("+(360*(i/count+offset))+", 100%, 80%)");
    return colors;
}

export function getColorGradient(color1 : string, color2 : string, weight : number) : string {
    const R1 = parseInt(color1.substring(1, 3), 16);
    const G1 = parseInt(color1.substring(3, 5), 16);
    const B1 = parseInt(color1.substring(5, 7), 16);
    const R2 = parseInt(color2.substring(1, 2), 16);
    const G2 = parseInt(color2.substring(3, 5), 16);
    const B2 = parseInt(color2.substring(5, 7), 16);
    const R = Math.round(R1 + (R2 - R1) * weight);
    const G = Math.round(G1 + (G2 - G1) * weight);
    const B = Math.round(B1 + (B2 - B1) * weight);
    let sR = R.toString(16);
    let sG = G.toString(16);
    let sB = B.toString(16);
    while (sR.length < 2)
        sR = "0"+sR;
    if (sR.length > 2)
        sR = "FF";
    while (sG.length < 2)
        sG = "0"+sG;
    if (sG.length > 2)
        sG = "FF";
    while (sB.length < 2)
        sB = "0"+sB;
    if (sB.length > 2)
        sB = "FF";
    return "#"+sR+sG+sB;
}

export function mixColors(colors : string[]) : string {
    let R = 0;
    let G = 0;
    let B = 0;
    for (const color of colors) {
        R += parseInt(color.substring(1, 3), 16);
        G += parseInt(color.substring(3, 5), 16);
        B += parseInt(color.substring(5, 7), 16);
    }
    R = R / colors.length;
    G = G / colors.length;
    B = B / colors.length;
    return "#"+R.toString(16)+G.toString(16)+B.toString(16);
}

export function ARGBtoRGBA(color : string) : string {
    const A = parseInt(color.substring(0, 2), 16);
    const R = parseInt(color.substring(2, 4), 16);
    const G = parseInt(color.substring(4, 6), 16);
    const B = parseInt(color.substring(6, 8), 16);
    return `rgba(${R},${G},${B},${(A/255).toFixed(1)})`;
}

const ABC = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function AbcFromNumber(number : number) : string {
    let rixit;
    let residual = Math.floor(number);
    let result = '';
    do {
        rixit = residual % ABC.length;
        result = ABC.charAt(rixit) + result;
        residual = Math.floor(residual / ABC.length);
    } while (residual !== 0)
    if (result.length > 1)
        result = ABC.charAt(ABC.indexOf(result.charAt(0))-1) + result.substring(1);
    return result;
}

export function NumberFromAbc(abc : string) : number {
    if (abc.length > 1)
        abc = ABC.charAt(ABC.indexOf(abc.charAt(0))+1) + abc.substring(1);
    let result = 0;
    const splitted = abc.split('');
    for (const chunk of splitted)
        result = (result * ABC.length) + ABC.indexOf(chunk);
    return result;
}

export async function sleep(milis : number) {
    await new Promise(resolve => setTimeout(resolve, milis));
}

export function parseDate(str: string) : moment.Moment {
    if (str === undefined || str.trim() === "")
        return moment.invalid();
    let date = moment(str, true);
    if (!date.isValid()) {
        if (str.length >= 8 && str.length <= 10) {
            const splitted = str.split(/[./-]/g);
            if (splitted.length === 3) {
                if (splitted[0].length === 4)
                    //YYYY.MM.DD
                    date = moment.unix(0).set("y", Number(splitted[0])).set("months", Number(splitted[1])-1).set("date", Number(splitted[2]));
                else if (splitted[2].length === 4)
                    //DD.MM.YYYY
                    date = moment.unix(0).set("y", Number(splitted[2])).set("months", Number(splitted[1])-1).set("date", Number(splitted[0]));
            }
        }
    }
    return date;
}

export function isNumber(value : string) : boolean {
    const match = value.match(/[+-]?(\d+[.,])?\d+/);
    if (match === null)
        return false;
    return value === match[0];
}

export function isInteger(value : string, isPositive?: boolean) : boolean {
    let sign = "[+-]";
    if (isPositive!==undefined&&isPositive)
        sign = "[+]";
    else if (isPositive!==undefined&&!isPositive)
        sign = "[-]";
    const match = value.match(sign+"?[0-9]+");
    if (match === null)
        return false;
    return value === match[0];
}

export const isNonEmptyString = (str: any): str is string =>
    typeof str === "string" && str !== "";

export function formatDuration(seconds : number) : string {
    const years = Math.floor(seconds/(365*24*60*60));
    seconds -= years * (365*24*60*60);
    const days = Math.floor(seconds/(24*60*60));
    seconds -= days * (24*60*60);
    const hours =  Math.floor(seconds/(60*60));
    seconds -= hours * (60*60);
    const minutes =  Math.floor(seconds/(60));
    seconds -= minutes * (60);
    let str = "";
    if (years > 0)
        str += years + " y, ";
    if (days > 0)
        str += days + " d, ";
    if (hours > 0 || days > 0)
        str += hours + " h, ";
    if (minutes > 0 || hours > 0 || days > 0)
        str += minutes + " m, ";
    str += seconds + "s";
    return str;
}

export function deviceTypeIdToString(deviceTypeId: number | undefined) : string {
    switch (deviceTypeId) {
        case 1:
            return "Robot";
        case 2:
            return "Tablet";
        case 3:
            return "Reference Station";
        default:
            return "Device";
    }
}

export function deviceTypeStringToId(deviceType: string | undefined) : number {
    if (typeof deviceType !== "string")
        return deviceType as any;
    switch (deviceType.toLowerCase()) {
        case "robot":
        case "robots":
            return 1;
        case "tablet":
        case "tablets":
            return 2;
        case "basestation":
        case "base station":
        case "basestations":
        case "base stations":
        case "reference station":
        case "referencestation":    
        case "reference stations":
        case "referencestations":
            return 3;
        default:
            return 0;
    }
}

export function generateRandomString(length: number) : string {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++)
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
}
export function formatAsMoney(amount: number,isDot:boolean) : string {
    let txt = amount.toFixed(2);
    let extra = 3;
    if (txt.charAt(txt.length-1) === "0") {
        txt = txt.substring(0,txt.length-1);
        extra = 2;
        if (txt.charAt(txt.length-1) === "0") {
            txt = txt.substring(0,txt.length-2);
            extra = 0;
        }
    }
    const chars = txt.replace('.',isDot?'.':',').split("");
    let j = 0;
    let ret = "";
    for (let i = chars.length-1-extra; i >= 0; i--) {
        j++;
        if (j%3===0 && i > 0)
            ret=(isDot?",":".")+chars[i]+ret;
        else
            ret=chars[i]+ret;
    }
    return ret+txt.substring(txt.length-extra,txt.length).replace('.',isDot?'.':',');
}

export const utcTimezone = new Date().getTimezoneOffset()/-60;