import React, { useMemo } from "react";
import { makeStyles, useMediaQuery, Theme, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[1],
        borderTopLeftRadius: theme.spacing(1) / 2,
        borderTopRightRadius: theme.spacing(1) / 2,
        borderBottomLeftRadius: theme.spacing(1) / 2,
        borderBottomRightRadius: theme.spacing(1) / 2,
    },
    header: {
        maxHeight: 30,
        minHeight: 30,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderTopLeftRadius: "inherit",
        borderTopRightRadius: "inherit",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        "& > :first-child": {
            fontWeight: "bold",
        },
    },
    body: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            display: "flex",
            backgroundColor: theme.tmrPalette.ligtherGrey,
            maxHeight: 24,
            alignItems: "center",
            paddingLeft: "3px",
            paddingRight: "3px",
        },
        "& > :nth-child(even)": {
            backgroundColor: theme.tmrPalette.whiteGrey,
        },
        borderBottom: `1px solid ${theme.tmrPalette.grey}`,
        borderRight: `1px solid ${theme.tmrPalette.grey}`,
        borderLeft: `1px solid ${theme.tmrPalette.grey}`,
    },
}));

function Items(props : {data: Array<{name:string,data:Array<string>}>}) {
    const classes = useStyles();
    return (
        <>
            {props.data.map((group) => (
                <Grid key={group.name} item xs style={{flexGrow: 0}}>
                    <div className={classes.root}>
                        <div className={classes.header}>
                            <Typography component="h6" variant="h6">
                                {group.name}
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            {group.data.map((str, idx) => <Typography variant="body1" style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace:"nowrap"}} key={idx}>{str}</Typography>)}
                        </div>
                    </div>
                </Grid>
            ))}
        </>
    );
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}));

export default function MiscGrid(props: {data: Array<{name:string,data:Array<string>}>}) {
    const classes = useStyles1();
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

    const columns_ = useMemo(() => {
        const desiredColumns = isLargeScreen ? 4 : 3;
        const columns: {name:string,data:Array<string>}[][] = [];

        for (let i = 0; i < desiredColumns; i++)
            columns[i] = [];
        for (let i = 0; i < props.data.length; i++)
            columns[i%desiredColumns].push(props.data[i]);

        return columns.map((col, idx) => (
            <Grid
                key={idx}
                container
                direction="column"
                justify="flex-start"
                spacing={1}
                item
                xs={12}
                md={4}
                lg={3}
            >
                <Items data={col} />
            </Grid>
        ));
    }, [props.data, isLargeScreen]);

    return (
        <Grid
            key={`body-2/misc`}
            container
            direction="row"
            spacing={1}
            justify="center"
            className={classes.root}
        >
            {columns_}
        </Grid>
    );
}