import { Box, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import bckg from '../../TinyMobileRobots_TinyLineMarker_Sport_01_web.webp';
import logo from '../../TinyMobileRobots_white_trademark.svg'

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundImage: `url(${bckg})`,
        height: "98vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "#fff",
        fontSize: "4rem",
        minHeight: 700,        

        [theme.breakpoints.down("sm")]: {
          minHeight: 500,
          fontSize: "3em"
        }
      },
      banner_picture: {
        height: 80,
        [theme.breakpoints.down("md")]: {
          maxWidth: "95%",
          maxHeight: 60,
          fontSize: "3em"
        },
      },
      container: {
        paddingTop: theme.spacing(5)
      },
      title: {
        paddingBottom: theme.spacing(3),
        fontSize: "2.5em"
      },
      welcome: {
        maxWidth: "90%",
        marginBottom: "max(30vh,300px)",
      },
      nowrap: {
        whiteSpace: "nowrap",
      },
  }));
  
  const Home = () => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    return (
    <div>
        <Box className={classes.banner}>
          <img className={classes.banner_picture} src={logo} alt="logo"/>
          <Typography align="center" className={classes.welcome} variant={isSmallScreen?"h4":"h3"} >
                Welcome to the TinyMobileRobots <span className={classes.nowrap}>Service Center!</span>
          </Typography>
        </Box>
    </div>
    );
  };
  
  export default Home;