import React, { useState } from "react";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSection } from "./SectionProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Button, MenuItem, TextField, Typography } from "@material-ui/core";
import SupportService from "src/services/SupportService";
import { useToast } from "src/hooks";
import PromptV2 from "src/components/PromptV2";
import { toastError } from "src/utils/common";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { userSelector } from "src/redux/app/selectors";

const useStyles = makeStyles((theme: any) => ({
    supportNewHeader: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        padding: "10px",
        '&:hover': {
            background: "rgba(30, 30, 30, 0.3)",
        },
        '&:active': {
            background: "rgba(30, 30, 30, 0.5)",
        },
    },
    supportSubmit: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    supportWrapper: (isMobile ? {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        marginTop: "5px",
        marginBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        '&>div:nth-child(1)': {
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            '&>*': {
                marginBottom: "10px",
            },
        },
        '&>div:nth-child(2)': {
            flex: 1,
        },
    } : {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "row",
        marginTop: "5px",
        marginBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        '&>div:nth-child(1)': {
            width: "300px",
            marginRight: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            '&>*': {
                marginBottom: "10px",
            },
        },
        '&>div:nth-child(2)': {
            flex: 1,
        },
    }),
}));

export const categoryItems = [
    <MenuItem key="antenna" value="antenna">Antenna</MenuItem>,
    <MenuItem key="app" value="app">App</MenuItem>,
    <MenuItem key="battery" value="battery">Battery</MenuItem>,
    <MenuItem key="bluetooth" value="bluetooth">Bluetooth</MenuItem>,
    <MenuItem key="bumper" value="bumper">Bumper</MenuItem>,
    <MenuItem key="calibration" value="calibration">Calibration</MenuItem>,
    <MenuItem key="cloud" value="cloud">Cloud</MenuItem>,
    <MenuItem key="gps" value="gps">GPS</MenuItem>,
    <MenuItem key="imu" value="imu">IMU</MenuItem>,
    <MenuItem key="modem_internet" value="modem_internet">Modem / Internet</MenuItem>,
    <MenuItem key="motor_controller" value="motor_controller">Motor Controller</MenuItem>,
    <MenuItem key="ntrip" value="ntrip">Ntrip</MenuItem>,
    <MenuItem key="other_hw" value="other_hw">Other Hardware</MenuItem>,
    <MenuItem key="power_board" value="power_board">Power Board</MenuItem>,
    <MenuItem key="pump_system" value="pump_system">Pump System</MenuItem>,
    <MenuItem key="robot_sw" value="robot_sw">Robot Software</MenuItem>,
    <MenuItem key="robot_sw_update" value="robot_sw_update">Robot SW Update</MenuItem>,
    <MenuItem key="sim" value="sim">SIM</MenuItem>,
    <MenuItem key="sales" value="sales">Sales</MenuItem>,
    <MenuItem key="tablet_category" value="tablet_category">Tablet</MenuItem>,
    <MenuItem key="template" value="template">Template</MenuItem>,
    <MenuItem key="wheel_sport" value="wheel_sport">Wheel Sport</MenuItem>,
    <MenuItem key="wheel_tlm_ts" value="wheel_tlm_ts">Wheel TLM/TS</MenuItem>,
    <MenuItem key="other_category" value="other_category">Other</MenuItem>,
    <MenuItem key="outdated" value="outdated">Outdated</MenuItem>,
    <MenuItem key="suspended" value="suspended">Suspended</MenuItem>
];

export default function SupportNew(props: {notify? : any}) {
    const user=useSelector(userSelector)
    const classes = useStyles();
    const { deviceId, deviceTypeId } = useSection();

    const { displayToast } = useToast();

    const [disabledControl, setDisabledControl] = useState(false);
    const [isActive, setActive] = useState(false);
    const [category, setCategory] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [subject, setSubject] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState("");

    const extend = () => {
        setActive(!isActive);
    };

    const submit = async () => {
        if (comment === "")
            setCommentError("can not be empty");
        if (subject === "")
            setSubjectError("can not be empty");
        if (category === "")
            setCategoryError("can not be empty");
        if (comment === "" || subject === "" || category === "") {
            displayToast({
                message: "check fields",
                severity: "warning",
                withCloseIcon: true,
            });
            return;
        }
        setDisabledControl(true);
        SupportService.postClosedDeviceTicket(deviceTypeId, deviceId,{subject,category,body:comment,requester_username:user.username}).then(() => {
            displayToast({
                message: "closed support issue submitted",
                severity: "success",
                withCloseIcon: true,
            });
            props.notify?.current.addLocalIssue({subject,category,body:comment});
            setComment("");
            setCategory("");
            setSubject("");
        }).catch(toastError(displayToast)).finally(() => {
            setDisabledControl(false);
        });
    };

    return (
        <>
            <PromptV2
                when={
                    comment!=="" || subject!=="" || category!==""
                }
            />
            {/* <div className={classes.supportNewHeader} onClick={extend}>
                {isActive
                    ? <DropDownIcon style={{marginRight: "10px"}}/>
                    : <DropDownIcon style={{transform: "rotate(-90deg)", marginRight: "10px"}}/>
                }
                <Typography>New Support Issue</Typography>
            </div> */}
            {isActive && <div className={classes.supportWrapper}>
                <div>
                    <TextField
                        label="Category"
                        margin="dense"
                        size="small"
                        select
                        value={category}
                        onChange={(ev: any) => {setCategory(ev.target.value);setCategoryError("");}}
                        variant="outlined"
                        error={categoryError!==""}
                        helperText={categoryError}
                        disabled={disabledControl}
                    >
                        {categoryItems}
                    </TextField>
                    <TextField
                        label="Subject"
                        margin="dense"
                        size="small"
                        value={subject}
                        onChange={(ev: any) => {setSubject(ev.target.value);setSubjectError("");}}
                        variant="outlined"
                        error={subjectError!==""}
                        helperText={subjectError}
                        disabled={disabledControl}
                    />
                    <Button
                        className={classes.supportSubmit}
                        size="medium"
                        variant="outlined"
                        onClick={() => {submit();}}
                        disabled={disabledControl}
                    >
                        Submit Closed Entry
                    </Button>
                </div>
                <div>
                    <TextField
                        style={{width: "100%"}}
                        margin="dense"
                        size="small"
                        multiline
                        rows={7}
                        placeholder="Issue"
                        value={comment}
                        onChange={(ev: any) => {setComment(ev.target.value);setCommentError("");}}
                        variant="outlined"
                        error={commentError!==""}
                        helperText={commentError}
                        disabled={disabledControl}
                    />
                </div>
            </div>}
        </>
    );
}