import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import AssignServerDnsPopup from "./popups/AssignServerDnsPopup.jsx"
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const small_style = {
  width: 1,
  whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

class ManageServerDnsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      only_show_deprecated: false,
    };
    this.refreshServerDnsData = this.refreshServerDnsData.bind(this);

    this.headers = [{
      Header: 'Robot ID',
      key: 'RobotId',
      align: "left",
      style: small_style,
      search: "RobotId"
    },
    {
      Header: 'Server',
      key: 'FriendlyName',
      align: "left",
      style: big_style,
      search: "FriendlyName"
    },
    {
      Header: '',
      key: 'DeleteElement',
      align: "left",
      style: small_style
    }
    ];
  }

  componentDidMount() {
    this.refreshServerDnsData()
  }

  removeRobotDNS(RobotId) {
    let should_do_to = window.confirm("Do you want to remove robot specific dns from robot " + RobotId, false);
    if (should_do_to) {

      let requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      };
      axios("/api/dbtool/robotaddress?r="+RobotId, requestOptions)
        .then(response => response.data)
        .then(state => {
          if (state.success) {
            setTimeout(this.refreshServerDnsData, 1001);
          }
          else
            alert("Could not remove robot DNS");
        });
    }
  }

  refreshServerDnsData(event) {
    if (event)
      event.preventDefault();
    axios(`/api/dbtool/robotaddresses`)
      .then(response => {return response.data[0]})
      .then(state => this.setState({
        data: state.map(r => {
          r.Id = r.RobotId = parseInt(r.RobotId);
          r.Deprecated = (r.Deprecated) ? true : false
          r.setSecondayColor = r.Deprecated;
          r.Secure = parseInt(r.Secure?.data ? r.Secure?.data[0]:0);
          r.PrependHostname = parseInt(r.PrependHostname?.data?r.PrependHostname?.data[0]:0);
          r.AddressString = (r.Secure ? "wss://" : "ws://") + (r.PrependHostname ? "[hostname]." : "") + r.Address + ":" + r.Port;
          r.FriendlyName = (r.ServerDesciption ? r.ServerDesciption : (r.AddressString ? r.AddressString : "")) + (r.Deprecated ? " (deprecated)" : "");

          r.DeleteElement = <IconButton component="span" onClick={this.removeRobotDNS.bind(this, r.RobotId)} ><DeleteIcon /></IconButton>;
          return r;
        })
      }));
  }

  render() {

    let showRows = this.state.data;

    if (this.state.only_show_deprecated) {
      showRows = showRows.filter(r => {
        return r.Deprecated;
      });
    }
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.only_show_deprecated}
              onChange={event => this.setState({ only_show_deprecated: event.target.checked })}
              name="checkedB"
              color="primary"
            />
          }
          label="Only show robots connected to deprecated server"
        />
        <Table id="server_dns_table" headeres={this.headers} data={showRows} refresh={this.refreshServerDnsData} />
        <div style={{ margin: "30px" }}>
          <AssignServerDnsPopup whenSuccess={this.refreshServerDnsData} />
        </div>
      </React.Fragment>
    );
  }
}

export default ManageServerDnsSection;