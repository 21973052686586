import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { CellProps, Column } from "react-table";
import VirtualizedTable from "src/components/VirtualizedTable";
import { generateCsvArrayAny } from "src/utils/csvGenerator";

function DataCell({
    cell: { value },
}: CellProps<any>) {

    if(moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid())
        return <div>{moment(value).utc().format("YYYY-MM-DD HH:mm:ss.SSS")}</div>;
    return <div>{value}</div>;
}

export default function DynamicColumnsTable({data, loading} : {data: any[] , loading: boolean}) {
    const download = useCallback(
        async () => {
            const csvData = generateCsvArrayAny(data ?? [])
            
            const temp_link = document.createElement("a");
            document.body.appendChild(temp_link);
            temp_link.href = csvData
            temp_link.download = `query-result.csv`;
            temp_link.click();
            temp_link.remove();
        },
        [data]
    );
        const dynamicColumns: Column<any>[] = Object.getOwnPropertyNames(data?.at(0) ? data?.at(0) : {results: ""}).map(column => {
            return {
                accessor: column,
                Header: column,
                Cell: DataCell,
                hideable: false,
            }
        })

        return (
            <VirtualizedTable 
                height={500}
                size="small"
                pageCount={data?.length}
                columns={dynamicColumns}
                data={data ?? []}
                loading={loading}
                disableGlobalFilter
                disableFilters
                exportData={download}
            />
        )
    
}
