import {  makeStyles, Button,  TextField, MenuItem, Switch, FormControlLabel,  } from "@material-ui/core";
import React, {  forwardRef, useCallback, useEffect, useImperativeHandle,  useRef, useState } from "react";
import { useAsync,  useToast } from "src/hooks";
import CommandService from "src/services/CommandService";
import RefreshButton from "src/components/RefreshButton";
import moment from "moment";
import {  TimeFormats } from "src/utils/constants";
import { useSelector } from "react-redux";
import { basestationsSelector, customersArraySelector, robotsSelector, userSelector } from "src/redux/app/selectors";
import { BaseStation, Robot } from "src/services/DeviceDataService";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { useAsyncDebounce } from "react-table";
import UpdateCommand from "./UpdateCommand";

const commonKeys = {
    online: "Server online",
    online2: "vpn_status",
    online3: "connected",
};

const commands=[
    {
        command:"update",
        name:"update",
        //filterRobots:[],
        allCommandsAccess:false,
        component: (props: {allCommandsAccess:boolean,devices:number[],historyRef:any,deviceTypeId:number})=>  <UpdateCommand allCommandsAccess={props.allCommandsAccess} devices={props.devices} historyRef={props.historyRef} deviceTypeId={props.deviceTypeId}></UpdateCommand>
    },
]


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        '& *': {fontFamily: theme.typography.fontFamily},
    },
    history: {
        display: "flex",
        flexDirection: "row",
        alignItems: "top",
        '&>div:nth-child(2)': {
            flexShrink: 0,
            width: "850px",
            display: "flex",
            flexDirection: "column",
            '&>div:nth-child(3)': {
                '&>*': {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    '&>*:nth-child(1)': {width: "80px"},
                    '&>*:nth-child(2)': {width: "70px"},
                    '&>*:nth-child(3)': {width: "80px"},
                    '&>*:nth-child(4)': {width: "220px"},
                    '&>*:nth-child(5)': {flexGrow:1},
                    padding: "5px",
                },
                '&>div:nth-child(1)': {fontWeight: "bold", cursor:"default"},
                '&>div:nth-child(n+2)': {cursor: "pointer"},
                '&>div:nth-child(2n)': {backgroundColor: "#DDDDDD"},
                '&>div:nth-child(n+2):nth-child(2n):hover': {backgroundColor: "#EEEEEE"},
                '&>div:nth-child(2n+1)': {backgroundColor: "#BBBBBB"},
                '&>div:nth-child(n+2):nth-child(2n+1):hover': {backgroundColor: "#CCCCCC"},
            },
            marginRight: "15px",
        },
        '&>div:nth-child(3)': {
            cursor: "default",
            '&>*': {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom:"10px",
            },
            '&>*:nth-child(n+2)': {
                '&>*:nth-child(1)': {flexGrow: 1},
                '&>*:nth-child(2)': {width: "110px"},
                padding: "5px",
            },
            '&>div:nth-child(2)': {fontWeight: "bold"},
            '&>div:nth-child(n+2):nth-child(2n)': {backgroundColor: "#DDDDDD"},
            '&>div:nth-child(n+2):nth-child(2n+1)': {backgroundColor: "#FFFFFF"},
        },
        marginTop: "20px",
    },
    new: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        '&>*': {
            marginRight: "15px",
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    device:{border:"1px solid black",borderRadius:5, padding:5,margin:5,display:'inline-block',"&:hover":{borderColor:"red"}}
}));

const formatDate = (dateString: string) => {
    if (dateString === undefined || dateString === null || dateString === "")
        return "N/A";
    return moment(dateString).format(TimeFormats.Presentation2);
}

const CommandsHistory = forwardRef((props: {allCommandsAccess:boolean,devices:number[],command:string,deviceType:number}, ref) => {

    const { allCommandsAccess, devices,command,deviceType } = props;
    const classes = useStyles();

    const { displayToast } = useToast();

    const displayErrorToast = (val:any) => displayToast({
        message: val.message,
        severity: "error",
        withCloseIcon: true,
    });

    const fetchCommands_ = useCallback(() => Promise.all(devices.map((deviceId)=>CommandService.getCommands({robotId: deviceId, type: command,deviceType:deviceType}))).then((h)=>h.flat(1)), [devices, command,deviceType]);
    const { value: Commands, pending: CommandsLoading, exec: fetchCommands } = useAsync(fetchCommands_, {
        immediate: false,
        clearValueOnExec: false,
        onError: displayErrorToast,
    });
    useEffect(() => {
        fetchCommands();
    }, [command,JSON.stringify(devices)]); //eslint-disable-line react-hooks/exhaustive-deps

    const [selectedCommand, setSelectedCommand] = useState(undefined as number | undefined);
    const currentCommand = Commands?.find(c => c.id === selectedCommand);

    const [selectFirst, setSelectFirst] = useState(false);
    useEffect(() => {
        if (selectFirst && Commands && Commands.length > 0) {
            setSelectedCommand(Commands[0].id);
            setSelectFirst(false);
        }
    }, [Commands]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchCommandLogs_ = useCallback(() => CommandService.getLogs(selectedCommand!), [selectedCommand]);
    const { value: commandLogs, pending: commandLogsLoading, exec: fetchCommandLogs } = useAsync(fetchCommandLogs_, {
        immediate: false,
        clearValueOnExec: false,
        onError: displayErrorToast,
    });
    useEffect(() => {
        fetchCommands();
        if (selectedCommand)
            fetchCommandLogs();
        const id = setInterval(() => { //eslint-disable-line react-hooks/exhaustive-deps
            if (selectedCommand) {
                fetchCommands();
                fetchCommandLogs();
            }
        }, 3000);
        return () => clearInterval(id);
    }, [selectedCommand]); //eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
        fetchCommands,
        setSelectedCommand,
        setSelectFirst,
    }));
    const [filterMessage,setFilterMessage]=useState(true)
    
    return (
        <div className={classes.history}>
            <div key="type"></div>
            <div key="commands">
                <div style={{fontWeight:"bold"}}>Command History</div>
                <div style={{fontStyle:"italic"}}>Click on a command for more details!</div>
                <div>
                    <div><div>device id</div><div>by</div><div>type</div><div>issued at</div><div>args</div></div>
                    {(Commands??[]).filter((c)=>c.type===command).sort((a,b)=>moment(b.issuedAt).diff(moment(a.issuedAt))).map(c => 
                        <div key={c.id} onClick={() => {setSelectedCommand(c.id);}} style={selectedCommand===c.id?{border:"solid 1px black"}:{}}>
                            <div>{c.robotId}</div><div>{c.byUser}</div><div>{c.type}</div><div>{moment(c.issuedAt).format('DD-MM-YYYY HH:mm:ss')}</div><div style={{whiteSpace: "pre-wrap"}}>{(allCommandsAccess&&selectedCommand===c.id)?JSON.stringify(c.args,null,2):(c.type==="update"?c.args.rs:c.args.address)}</div>
                        </div>
                    )}
                </div>
            </div>
            {(currentCommand) && <div key="logs">
                <div style={{justifyContent:"space-between"}}>
                    <div>
                        issued at: <span style={{fontWeight:"bold"}}>{formatDate(currentCommand.issuedAt)}</span>;
                        started at: <span style={{fontWeight:"bold"}}>{formatDate(currentCommand.startedAt)}</span>;
                        completed at: <span style={{fontWeight:"bold"}}>{formatDate(currentCommand.completedAt)}</span>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginLeft:"10px"}}>
                        <RefreshButton
                            style={{margin: "0px"}}
                            loading={commandLogsLoading || CommandsLoading}
                            onClick={() => {
                                fetchCommandLogs();
                                fetchCommands();
                            }}
                        />
                    </div>
                </div>
                <div><div>Message</div><div>created at</div></div>
                {(commandLogs??[]).filter((c,index)=>{return filterMessage?(c.error||(c.message.includes('/'))||index===((commandLogs??[]).length-1)):true}).map(c => 
                    <div key={c.id} style={c.error?{color:"red"}:{}}>
                        <div>{c.message}</div><div>{formatDate(c.createdAt)}</div>
                    </div>
                )}


                        <Button
                    className={classes.button}
                    style={{marginTop:"10px",display:"inline-block"}}
                    size="medium"
                    variant="outlined"
                    disabled={currentCommand.startedAt!==null}
                    onClick={() => {
                        CommandService.cancelCommand(selectedCommand!).then(() => {
                            displayToast({
                                message: "Command cancelled!",
                                severity: "warning",
                                withCloseIcon: true,
                            });
                            fetchCommandLogs();
                        }).catch(() => {
                            displayToast({
                                message: "Error cancelling command!",
                                severity: "error",
                                withCloseIcon: true,
                            });
                        });
                    }}
                >
                    Cancel Command
                </Button>
                
                    <FormControlLabel
                    style={{display:"inline-block", margin:"auto 15px"}}
                        control={
                            <Switch
                                checked={filterMessage}
                                onChange={(ev) => setFilterMessage(ev.target.checked)}
                                size="small"
                                color="primary"
                            />
                        }
                        label="Show important only"
                    />
                

            </div>}
        </div>
    );
});
export const CommandsTab = () => {
    const classes = useStyles();
    const allCommandsAccess=useSelector(userSelector).userAccess.includes('CommandsSuper')

    const historyRef = useRef();
    
    const [newType, setNewType] = useState("");

    const [deviceType,setDeviceType]=useState(1)
    const [devicesSelected,setDevicesSelected]=useState([] as Array<Robot|BaseStation>)
    const robots=useSelector(robotsSelector)
    const basestatinos=useSelector(basestationsSelector)
    const customers=useSelector(customersArraySelector)

    const fetchCommandsAccess_ = useCallback(() => (devicesSelected.length>1?CommandService.getCommandAccessForRobots(devicesSelected.map((d)=>d.id), deviceType):CommandService.getCommandAccessForRobot(devicesSelected.map((d)=>d.id)[0], deviceType)).then(r => {setNewType(r[0]); return r}), [devicesSelected,deviceType]);
    const fetchCommandsAccessDebounced=useAsyncDebounce(fetchCommandsAccess_,300)
    const { value: commandAccess, pending: accessLoading, exec: fetchCommandsAccess } = useAsync(fetchCommandsAccessDebounced, {
        immediate: false,
        //clearValueOnExec: false,
    });
    useEffect(()=>{
        if (devicesSelected.length>0)
        fetchCommandsAccess()
    },[(devicesSelected),fetchCommandsAccess])
    return (
                    <div className={classes.root} style={{marginBottom:"20px"}}>
                        <div className={classes.root} style={{borderBottom:"2px solid",paddingBottom:"20px"}}>
                        <div className={classes.new} style={{marginBottom:"30px",borderBottom:"2px solid",paddingBottom:"20px",alignItems:"center"}}>
                                <TextField
                                    style={{width: "250px"}}
                                    label="Device Type"
                                    margin="dense"
                                    size="small"
                                    select
                                    // disabled={accessLoading}
                                    value={deviceType}
                                    onChange={(ev: any) => {setDeviceType(ev.target.value);setDevicesSelected([])}}
                                    variant="outlined"
                                >
                                        <MenuItem key={1} value={1}>{"Robot"}</MenuItem>
                                        <MenuItem key={3} value={3}>{"Reference Station"}</MenuItem>
                                </TextField>
                                <AutocompleteV2
                                
                                        options={deviceType===1?robots:basestatinos}
                                        renderInput={
                                            (params:any) => 
                                            { 
                                            return <TextField
                                                {...params}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                label="Add device"
                                            />}
                                        }
                                        getOptionDisabled={(option:Robot|BaseStation) => {return devicesSelected.some((df)=>df.id===option.id)}}
                                        getOptionLabel={((c:Robot|BaseStation)=>c.id.toString())}
                                        getOptionSelected={(a:Robot|BaseStation, b:Robot|BaseStation) => a.id === b.id}
                                        renderOption={(c:Robot|BaseStation)=>{
                                        return <div>{"("+c.id+") "+((Object.keys(c).includes('name'))?(c as Robot).name:'')+' ['+customers.find((cc)=>cc.id===(Number(c.customerId)))?.name+']'}</div>}}
                                        
                                        onChange={(_e:any, val:Robot|null|BaseStation) => {
                                            if (val){
                                            setDevicesSelected((prev)=>[...prev,val])
                                            }
                                        }}
                                        style={{width:"300px"}}
                                        ></AutocompleteV2> 
                              <div>
                                {devicesSelected.map((r)=><div key={r.id} className={classes.device} onClick={()=>setDevicesSelected((prev)=>prev.filter((rr)=>rr.id!==r.id))}>
                                    {r.id+' - '+((Object.keys(r).includes('name'))?((r as Robot).name):(customers.find((cc)=>cc.id===r.customerId)?.name)??'')+' ✕'}
                                </div>)}
                            </div>
                            </div>
                            
                            <div className={classes.new} style={{marginBottom:"30px",borderBottom:"2px solid",paddingBottom:"20px"}}>
                                <div style={{fontWeight: "bold"}}>NEW COMMAND</div>
                                <TextField
                                    style={{width: "250px"}}
                                    label="Command Type"
                                    margin="dense"
                                    size="small"
                                    select
                                    disabled={accessLoading}
                                    value={newType}
                                    onChange={(ev: any) => {setNewType(ev.target.value);}}
                                    variant="outlined"
                                >
                                    {commands.filter((c)=>commandAccess?.includes(c.name)).filter((c)=>!c.allCommandsAccess || allCommandsAccess).map(t =>
                                        <MenuItem key={t.command} value={t.command}>{t.name}</MenuItem>
                                    )}
                                </TextField>
                                
                            </div>
                            {commands.find((c)=>c.command===newType)?.component({allCommandsAccess:allCommandsAccess,devices:devicesSelected.map((d)=>d.id)!,historyRef: historyRef,deviceTypeId: deviceType})}
                        </div>
                        
                        <CommandsHistory
                            allCommandsAccess={allCommandsAccess}
                            ref={historyRef}
                            devices={devicesSelected.map((d)=>d.id)!}
                            command={newType}
                            deviceType={deviceType}
                        />
                    </div>
               
    );
}
