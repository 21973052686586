import axios from "axios";
import { handleError } from "./Errors";

export interface VersionHistory {
    name: string,
    released: number | undefined,
    issues: Array<{name: string, translated: string, product: Array<string>}>,
    features: Array<{name: string, translated: string, product: Array<string>}>,
    templates: Array<{name: string, translated: string}>,
}

export default class MondayService {
    static async getSoftwareVersions(deviceTypeId: number): Promise<Array<VersionHistory>> {
        try {
            const response = await axios.get("/api/monday/softwareVersions?deviceTypeId="+deviceTypeId);
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async refreshChangelog(deviceTypeId: number): Promise<void> {
        try {
            return await axios.post("/api/monday/refreshChangelog/"+deviceTypeId, {}, {timeout: 600000});
        } catch (err) {
            return handleError(err, true);
        }
    }
}