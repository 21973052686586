import { Button, CircularProgress, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useAsync, useLocalStorage, useToast } from "src/hooks";
import { useSelector } from "react-redux";
import { customersArraySelector, customersDtoSelector, customersLoadedSelector, customersTreeSelector, robotsSelector, tabletsSelector } from "src/redux/app/selectors";
import { TreeNode } from "src/utils/Tree";
import { Customer } from "src/services/UserService";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AutocompleteV2 from "src/components/AutocompleteV2";
import useQueryString from "src/hooks/useQueryString";
import { useAsyncDebounce } from "react-table";
import { useKeyGroupStyles } from "../Devices/Dashboard/KeyGroup";
import TemplatesGrid from "../Devices/Dashboard/TemplatesGrid"
import classNames from "src/utils/classNames";
import DeviceDataService from "src/services/DeviceDataService";
import { Link } from "react-router-dom";
import MiscGrid from "../Devices/Dashboard/MiscGrid";
import moment from "moment";
import { TableDialog } from "../Devices/Dashboard/DialogBar";
import TranslationProvider from "src/views/Devices/TranslationProvider";
import { useDashboard } from "../Devices/Dashboard/DashboardProvider";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "row",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    tree: {
        overflowY: "auto",
        height: "calc(100vh - 150px)",
        minWidth: "200px",
    },
    details: {
        overflowY: "auto",
        height: "calc(100vh - 50px)",
        flexGrow: 1,
        marginTop: "10px",
    },
}));

function KeyValuePair({keyVal, value}:{keyVal: string, value: string}) {
    const classes = useKeyGroupStyles();

    return (
        <div>
            <div className={classes.key}>
                <Typography>{keyVal}</Typography>
            </div>
            <div className={classes.value}>
                {value}
            </div>
        </div>
    );
}

function KeyValuePairLink({keyVal, value, link}:{keyVal: string, value: string, link: string}) {
    const classes = useKeyGroupStyles();

    return (
        <div>
            <div className={classes.key}>
                <Typography>{keyVal}</Typography>
            </div>
            <div className={classNames(classes.value, "link-value")}>
                {link && (
                    <Link to={link} target="_blank">
                        {value}
                    </Link>
                )}
            </div>
        </div>
    );
}

export default function TreeOverview() {
    const classes = useStyles();
    const classes1 = useKeyGroupStyles();

    const { displayToast } = useToast();

    const [expanded, setExpanded] = useLocalStorage("customers-expanded", ["1"]);
    const [selected, setSelected] = useQueryString("selected", undefined as string | undefined);

    const loaded = useSelector(customersLoadedSelector);
    const customers = useSelector(customersArraySelector);
    const customersDto = useSelector(customersDtoSelector);
    const robots = useSelector(robotsSelector);
    const tablets = useSelector(tabletsSelector);
    const customerTree = useSelector(customersTreeSelector)?.top ?? {item: {id: 1, name: "", isDealer: false, parentId: 0, dealerId: 0, from: ""}, children: []};



    const renderTree = (nodes: TreeNode<Customer>) => {
       return nodes.item.id>0 ? <TreeItem
            key={nodes.item.id.toString()}
            nodeId={nodes.item.id.toString()}
            label={<React.Fragment>
                <span style={nodes.item.isDealer ? {fontWeight: "bold"} : {}}>
                    {`${nodes.item.name}`+((nodes.item.salesRep!==undefined&&nodes.item.salesRep!==null&&nodes.item.salesRep!=="")?` (${nodes.item.salesRep})`:``)}
                </span>
            </React.Fragment>}
        >
            {nodes.children.map((node) => renderTree(node))}
        </TreeItem>:nodes.children.map((node) => renderTree(node))}
    ;

    const fetchData = useCallback(async (customerId: string) => {
        const numId = Number(customerId);
        const self = customersDto[numId];
        const parent = customersDto[self.parentId];
        const dealer = customersDto[self.dealerId];
        const templates = await DeviceDataService.getTemplatesForCustomer(numId);
        const accessGroups = await DeviceDataService.getAccessGroupsForCustomer(numId);
        
        return (
            <div style={{display: "flex", flexDirection: "column", paddingBottom: "10px", width: "calc(100% - 10px)"}}>
                <Grid container direction="row" justify="space-between" style={{padding:"0 8px"}}>
                    <Typography variant="h5" style={{margin: "15px", marginTop: "0px"}}>Customer {customerId}</Typography>
                    <TableDialog
                    type={"layouts"}
                    deviceId={0}
                    deviceTypeId={1}
                    customerId={numId}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    justify="center"
                >
                    <Grid
                        key="customer"
                        container
                        direction="column"
                        justify="flex-start"
                        spacing={1}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid key="customer" item xs style={{flexGrow: 0}}>
                            <div className={classes1.root}>
                                <div className={classes1.header}>
                                    <Typography component="h6" variant="h5">Customer</Typography>
                                </div>
                                <div className={classes1.body}>
                                    <KeyValuePairLink key="self" keyVal="Self" value={`${self.name} (${self.id})`} link={"/customers?selected="+self.id}/>
                                    {parent !== undefined &&
                                        <KeyValuePairLink key="parent" keyVal="Parent" value={`${parent.name} (${parent.id})`} link={"/customers?selected="+parent.id}/>
                                    }
                                    {dealer !== undefined &&
                                        <KeyValuePairLink key="dealer" keyVal="Dealer" value={`${dealer.name} (${dealer.id})`} link={"/customers?selected="+dealer.id}/>
                                    }
                                    <KeyValuePair key="isdealer" keyVal="Is Dealer" value={self.isDealer.toString()}/>
                                    {self.salesRep !== null &&
                                        <KeyValuePairLink key="sales" keyVal="Sales Rep" value={`${self.salesRep}`} link={"/accounts/"+self.salesRep}/>
                                    }
                                    <KeyValuePair key="from" keyVal="From" value={moment(self.from).format("YYYY-MM-DD")}/>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        key="robot"
                        container
                        direction="column"
                        justify="flex-start"
                        spacing={1}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid key="robot" item xs style={{flexGrow: 0}}>
                            <div className={classes1.root}>
                                <div className={classes1.header}>
                                    <Typography component="h6" variant="h5">{"Robots ("+robots.filter(r => r.customerId === numId).length+")"}</Typography>
                                </div>
                                <div className={classes1.body}>
                                    {robots.filter(r => r.customerId === numId).map(r => <KeyValuePairLink key={r.id} keyVal={r.id.toString()} value={r.name} link={"/devices/robots/"+r.id}/>)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        key="tablet"
                        container
                        direction="column"
                        justify="flex-start"
                        spacing={1}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid key="tablet" item xs style={{flexGrow: 0}}>
                            <div className={classes1.root}>
                                <div className={classes1.header}>
                                    <Typography component="h6" variant="h5">{"Tablets ("+tablets.filter(r => r.customerId === numId).length+")"}</Typography>
                                </div>
                                <div className={classes1.body}>
                                    {tablets.filter(t => t.customerId === numId).map(t => <KeyValuePairLink key={t.id} keyVal={t.id.toString()} value={t.id.toString()} link={"/devices/tablets/"+t.id}/>)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography variant="h5" style={{margin: "15px"}}>Templates</Typography>
                <TemplatesGrid data={{tabletId: 0, groups: templates}} />
                <Typography variant="h5" style={{margin: "15px"}}>Miscellaneous</Typography>
                <MiscGrid data={[{name:"Access Groups",data:accessGroups}]} />
            </div>
        );
    }, [customersDto, robots, tablets, classes1.root, classes1.body, classes1.header]);
    const { value, exec, pending } = useAsync(fetchData, {
        immediate: false,
        onError: (val : any) => {
            displayToast({
                message: val.message,
                severity: "error",
                withCloseIcon: true,
            });
        },
    });

    const debouncedCustomerChange = useAsyncDebounce((customerId: string) => {
        
        exec(customerId);
    }, 100);

    useEffect(() => {
        if (selected !== undefined && loaded && robots.length > 0 && tablets.length > 0)
            debouncedCustomerChange(selected);
    }, [loaded, JSON.stringify(robots), JSON.stringify(tablets)]); // eslint-disable-line react-hooks/exhaustive-deps

    const dict={};
    return (
    <TranslationProvider
    deviceTypeId={-1} translationsDictionary={dict} customers={customers}>
        <div className={classes.root}>
            <div>
                <AutocompleteV2
                    clearOnSelectAndBlur
                    options={customers}
                    getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                    noOptionsText="customer not found"
                    onChange={(_ev, val) => {
                        if (val !== undefined && val !== null) {
                            if (selected !== val.id.toString()) {
                                setSelected(val.id.toString());
                                debouncedCustomerChange(val.id.toString());
                            }
                            let currentParent = customersDto[val.id];
                            const add = [];
                            while (currentParent.id !== 1) {
                                currentParent = customersDto[currentParent.parentId];
                                if (!expanded?.includes(currentParent.id.toString()))
                                    add.push(currentParent.id.toString());
                            }
                            if (add.length > 0 && expanded !== undefined)
                                setExpanded(expanded.concat(add));
                        }
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                label="Search"
                            />
                        );
                    }}
                    loading={customers.length === 0}
                />
                <Button
                    className={classes.button}
                    style={{marginTop: "10px", marginBottom: "10px"}}
                    onClick={() => {
                        if (expanded?.length === 0 || (expanded?.length === 1 && expanded[0] === "1"))
                            setExpanded(customers.map(c => c.id.toString()));
                        else
                            setExpanded(["1"]);
                    }
                }>
                    {expanded?.length === 0 || (expanded?.length === 1 && expanded[0] === "1") ? 'Expand all' : 'Collapse all'}
                </Button>
                <TreeView
                    className={classes.tree}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected?.toString()??""}
                    
                    onNodeToggle={(_e, nodeIds) => {setExpanded(nodeIds);}}
                    onNodeSelect={(_e: any, nodeId: any) => {
                        if (selected !== nodeId) {
                            setSelected(nodeId);
                            debouncedCustomerChange(nodeId);
                        }
                    }}
                >
                    {renderTree(customerTree)}
                </TreeView>
            </div>
            <div className={classes.details}>
                {pending || !loaded || robots.length === 0 || tablets.length === 0
                    ? <div style={{width:"100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress size={70}/></div>
                    : <div>{value}</div>
                }
            </div>
        </div>
    </TranslationProvider>
);
}