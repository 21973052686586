import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslator } from "../TranslationProvider";
import { useSection } from "./SectionProvider";
import { useHighlightedUpdate } from "src/hooks";
import type { DeviceKeyGroup } from "src/services/DeviceDataService";
import Link from "@material-ui/core/Link";
import classNames from "src/utils/classNames";
import moment from "moment";
import { parseDate } from "src/utils/common";
import { timezone } from "src/App";

export const useKeyGroupStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[1],
        borderTopLeftRadius: theme.spacing(1) / 2,
        borderTopRightRadius: theme.spacing(1) / 2,
        borderBottomLeftRadius: theme.spacing(1) / 2,
        borderBottomRightRadius: theme.spacing(1) / 2,
    },
    header: {
        maxHeight: 40,
        minHeight: 40,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderTopLeftRadius: "inherit",
        borderTopRightRadius: "inherit",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > :first-child": {
            fontWeight: "bold",
        },
    },
    body: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            display: "flex",
            backgroundColor: theme.tmrPalette.ligtherGrey,
            maxHeight: 24,
            alignItems: "center",
        },
        "& > :nth-child(even)": {
            backgroundColor: theme.tmrPalette.whiteGrey,
        },
        "& > * > $key > :first-child, & > * > $value > :first-child": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        borderBottom: `1px solid ${theme.tmrPalette.grey}`,
        borderRight: `1px solid ${theme.tmrPalette.grey}`,
        borderLeft: `1px solid ${theme.tmrPalette.grey}`,
    },
    key: {
        userSelect: "none",
        marginRight: "auto",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        maxWidth: "50%",
        overflow: "hidden",
        "& :first-child": {
            fontWeight: 525,
        },
    },
    value: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        maxWidth: "50%",
        overflow: "hidden",
        height: "100%",
        "&:not(.link-value)": {
            "& :first-child": {
                color: theme.tmrPalette.grey,
            },
        },
    },
    tooltipDivider: {
        backgroundColor: theme.tmrPalette.lightGrey,
    },
}));

interface KeyValuePairProps {
    dataKey: string;
    value: string;
    style?: any;
}

function KeyValuePair({ dataKey, value, style }: KeyValuePairProps) {
    const classes = useKeyGroupStyles();
    const translator = useTranslator();
    const transitionProps = useHighlightedUpdate([value]);

    const key = translator.translateKey(dataKey);
    const val = translator.translate(dataKey, value);

    return (
        <Tooltip
            enterDelay={500}
            enterTouchDelay={250}
            enterNextDelay={250}
            title={
                <>
                    <Typography variant="caption">
                        <b>{key}</b>
                    </Typography>
                    {val && (
                        <>
                            <Divider
                                className={classes.tooltipDivider}
                                orientation="horizontal"
                            />
                            <Typography variant="caption">
                                <em>{val}</em>
                            </Typography>
                        </>
                    )}
                </>
            }
        >
            <div {...transitionProps}>
                <div className={classes.key}>
                    <Typography >{key}</Typography>
                </div>
                <div className={classes.value}>
                    <Typography style={style ?? {}}>{val}</Typography>
                </div>
            </div>
        </Tooltip>
    );
}

function KeyValuePairLink({ dataKey, value }: KeyValuePairProps) {
    const classes = useKeyGroupStyles();

    const datumYX = value.split(" ");
    const datumY = datumYX?.[0];
    const datumX = datumYX?.[1];

    const transitionProps = useHighlightedUpdate([value]);

    const link =
        datumY !== undefined && datumX !== undefined
            ? "https://www.google.com/maps/search/?api=1&query=" +
              datumY +
              "," +
              datumX
            : null;

    return (
        <div {...transitionProps}>
            <div className={classes.key}>
                <Typography>{dataKey}</Typography>
            </div>
            <div className={classNames(classes.value, "link-value")}>
                {link && (
                    <Link color="primary" href={link} target="_blank">
                        Click Me
                    </Link>
                )}
            </div>
        </div>
    );
}

export default React.memo(function KeyGroup(props: DeviceKeyGroup) {
    const classes = useKeyGroupStyles();
    const { data } = useSection();
    const { name, keys } = props;

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography component="h6" variant="h5">
                    {name}
                </Typography>
            </div>
            <div className={classes.body}>
                {keys.map((key) => {
                    let style : any;
                    let endDate : moment.Moment;
                    let date : moment.Moment;
                    switch(key) {
                        case "Location Link":
                            return (
                                <KeyValuePairLink
                                    key={key}
                                    dataKey={key}
                                    value={data[key]?.value || ""}
                                />
                            );
                        case "shipping_date":
                            date = parseDate(data[key]?.value);
                            return (
                                <KeyValuePair
                                    key={key}
                                    dataKey={key}
                                    value={date.isValid() ? date.utcOffset(timezone).format("YYYY/MM/DD") : data[key]?.value || ""}
                                />
                            );
                        case "warranty_start":
                        case "warranty_end":
                            style = {};
                            endDate = parseDate(data["warranty_end"]?.value);
                            if (endDate.isValid() && moment.unix(moment.now()/1000).isAfter(endDate))
                                style.color = "red";
                            date = parseDate(data[key]?.value);
                            return (
                                <KeyValuePair
                                    style={style}
                                    key={key}
                                    dataKey={key}
                                    value={date.isValid() ? date.utcOffset(timezone).format("YYYY/MM/DD") : data[key]?.value || ""}
                                />
                            );
                        case "service_agreement_start":
                        case "service_agreement_end":
                            style = {};
                            endDate = parseDate(data["service_agreement_end"]?.value);
                            if (endDate.isValid() && moment.unix(moment.now()/1000).isAfter(endDate))
                                style.color = "red";
                            date = parseDate(data[key]?.value);
                            return (
                                <KeyValuePair
                                    style={style}
                                    key={key}
                                    dataKey={key}
                                    value={date.isValid() ? date.utcOffset(timezone).format("YYYY/MM/DD") : data[key]?.value || ""}
                                />
                            );
                        default:
                            return (
                                <KeyValuePair
                                    key={key}
                                    dataKey={key}
                                    value={data[key]?.value || ""}
                                />
                            );
                    }
                })}
            </div>
        </div>
    );
});
