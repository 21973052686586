import React, { Dispatch, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSection } from "./SectionProvider";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import SupportService, { SupportIssue } from "src/services/SupportService";
import { useAsync, useToast } from "src/hooks";
import { ClassNameMap } from "@material-ui/styles";
import moment from "moment";
import { TimeFormats } from "src/utils/constants";
import useDialog from "src/hooks/useDialog";
import PromptV2 from "src/components/PromptV2";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { toastError } from "src/utils/common";
import { isMobile } from "react-device-detect";
import Linkify from "react-linkify";
import { timezone } from "src/App";

const useStyles = makeStyles((theme: any) => ({
    supportHistoryHeader: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        padding: "10px",
        '&:hover': {
            background: "rgba(30, 30, 30, 0.3)",
        },
        '&:active': {
            background: "rgba(30, 30, 30, 0.5)",
        },
    },
    supportHistorySubHeader: {
        paddingTop: "0px",
        paddingBottom: "0px",
    },
    supportHistoryWrapper: {
        cursor: "default",
        paddingLeft: "40px",
        '& a': {
            color: "inherit",
            textDecoration: "none",
            '&:hover': {
                color: "inherit",
                textDecoration: "none",
            },
            '&:focus': {
                color: "inherit",
                textDecoration: "none",
            },
            '&:active': {
                color: "inherit",
                textDecoration: "none",
            },
            '&:link': {
                color: "inherit",
                textDecoration: "none",
            },
            '&:visited': {
                color: "inherit",
                extDecoration: "none",
            },
        },
    },
    supportHistoryContentWrapper: {
        marginTop: "5px",
        paddingLeft: "45px",
    },
    commentWrapper: {
        marginTop: "-5px",
        paddingRight: "10px",
        paddingBottom: "2px",
    },
    commentControlWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        paddingRight: "10px",
        marginBottom: "5px",
    },
    commentControlLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    },
    commentControlRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",
    },
    commentPost: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    issueClose: {
        backgroundColor: "#008800",
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: "#00BB00",
        },
        marginLeft: "5px",
        marginRight: "5px",
    },
    issueDelete: {
        backgroundColor: "#9f0000",
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: "#e80000",
        },
    },
    supportHistoryLoadMore: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
    },
    isOpen: {
        backgroundColor: "#FFDDDD",
        '&:hover': {
            background: "#FFEEEE",
        },
        '&:active': {
            background: "#FFEFEF",
        },
    },
    isClosed: {
        backgroundColor: "#DDFFDD",
        '&:hover': {
            background: "#EEFFEE",
        },
        '&:active': {
            background: "#EFFFEF",
        },
    },
    isLogbook: {
        backgroundColor: "#DDDDFF",
        '&:hover': {
            background: "#EEEEFF",
        },
        '&:active': {
            background: "#EFEFFF",
        },
    },
    supportHistoryTableRow: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        flexGrow: 1,
        minWidth: "0px",
        minHeight: "48px",
        '&>div': {
            flexShrink: 0,
            flexGrow: 0,
            height: "100%",
            fontFamily: theme.typography.fontFamily,
            borderRight: "2px solid black",
            paddingLeft: "2px",
            paddingRight: "2px",
            paddingTop: "6px",
            paddingBottom: "6px",
            marginTop: "4px",
            marginBottom: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        '&>div:nth-child(1)': {
            flexBasis: "65px",
        },
        '&>div:nth-child(2)': {
            flexBasis: "140px",
        },
        '&>div:nth-child(3)': {
            flexBasis: "125px",
        },
        '&>div:nth-child(4)': {
            flexBasis: "130px",
        },
        '&>div:nth-child(5)': {
            flexBasis: "140px",
        },
        '&>div:nth-child(6)': {
            flexBasis: "140px",
        },
        '&>div:nth-child(7)': {
            flexBasis: "70px",
        },
        '&>div:nth-child(8)': {
            minWidth: "0px",
            flexBasis: "0px",
            flexGrow: 1,
            borderRight: "0px",
        },
    },
    supportHistoryTableHeader: {
        cursor: "default",
        marginLeft: "84px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        '&>div': {
            flexBasis: "120px",
            fontFamily: theme.typography.fontFamily,
            fontWeight: "bold",
            borderRight: "2px solid black",
            borderBottom: "1px solid black",
            paddingLeft: "2px",
            paddingRight: "2px",
            paddingTop: "6px",
            paddingBottom: "6px",
            marginTop: "4px",
            marginBottom: "4px",
        },
        '&>div:nth-child(1)': {
            flexBasis: "65px",
        },
        '&>div:nth-child(2)': {
            flexBasis: "140px",
        },
        '&>div:nth-child(3)': {
            flexBasis: "125px",
        },
        '&>div:nth-child(4)': {
            flexBasis: "130px",
        },
        '&>div:nth-child(5)': {
            flexBasis: "140px",
        },
        '&>div:nth-child(6)': {
            flexBasis: "140px",
        },
        '&>div:nth-child(7)': {
            flexBasis: "70px",
        },
        '&>div:nth-child(8)': {
            borderRight: "0px",
        },
    },
    issueHistory: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems:"top", flexDirection:"row",
        marginBottom: "15px",
        '&:last-child': {
            marginBottom: "0px",
        },
        '&>*:nth-child(1)>*': {
            whiteSpace: "nowrap",
            fontStyle: "italic",
        },
        '&>*:nth-child(2)': {
            flexGrow: 1,
            whiteSpace: "pre-line",
            marginLeft: "10px"
        },
    },
    font: {
        fontFamily: theme.typography.fontFamily,
        '& *': {
            fontFamily: theme.typography.fontFamily,
        },
    },
    link: {
        fontFamily: theme.typography.fontFamily,
        color: "#0000EE !important",
        textDecoration: "underline !important",
        '&:hover': {
            color: "#551A8B !important",
        },
        '&:visited': {
            color: "#551A8B !important",
        },
    },
    mobileWrapper: (isMobile ? {
        overflowY: "auto",
        '&>*': {
            minWidth: "1000px",
        },
    } : {}),
}));

interface IssueState {
    isActive : boolean,
    comment : string,
    commentError : string,
    disabledControl : boolean,
    cursorInElsewhere : boolean,
}

function SupportHistoryIssue(
    classes : ClassNameMap,
    issue : SupportIssue,
    state : IssueState, 
    setState : (value: IssueState) => void,
    dispatch : Dispatch<any>,
) {
    let isActive = state?.isActive ?? false;
    const comment = state?.comment ?? "";
    const commentError = state?.commentError ?? "";
    const disabledControl = state?.disabledControl ?? false;
    let cursorInElsewhere = state?.cursorInElsewhere ?? false;

    const extend = () => {
        if (cursorInElsewhere)
            return;
        isActive = !isActive;
        setState({isActive,comment,commentError,disabledControl,cursorInElsewhere});
    };
    const setCursorInElsewhere = (value : boolean) => {
        cursorInElsewhere = value;
        setState({isActive,comment,commentError,disabledControl,cursorInElsewhere});
    }

    const issueHistory = [<div className={classes.issueHistory} key="subject">{issue?.subject}</div>];
    if (issue.comments !== null && issue.comments !== undefined)
        for (let i = 0; i < issue.comments.length; i++)
            issueHistory.push(
            <div className={classes.issueHistory} key={i}>
                <div>
                    <div>{moment.unix(issue.comments[i].created_at as number).utcOffset(timezone).format(TimeFormats.Presentation2)+" "+issue.comments[i].author}:</div>
                </div>
                <div>
                    {issue.comments[i].body}
                </div>
            </div>);

    let appendedComments = issue.subject+" ";
    for (let i = 0; i < (issue.comments === undefined ? 0 : issue.comments?.length); i++)
        appendedComments += issue.comments![i].body + " ";
    return (<div key={issue.id}>
        <PromptV2
            when={comment!==""}
        />
        <div className={classes.supportHistoryWrapper}>
            <div className={classes.supportHistoryHeader+" "+classes.supportHistorySubHeader+" "+(["closed","solved","pending"].includes(issue.status)?classes.isClosed:classes.isOpen)} onClick={extend}>
                {isActive
                    ? <DropDownIcon style={{marginRight: "10px"}}/>
                    : <DropDownIcon style={{transform: "rotate(-90deg)", marginRight: "10px"}}/>
                }
                <div className={classes.supportHistoryTableRow}>
                    <div>{issue.id}</div>
                    <div>{issue.assignee === "" ? "Unknown" : issue.assignee}</div>
                    <div style={{color: issue.category==="Missing"?"red":undefined}}>{issue.category}</div>
                    {issue.requester_phone === "" ?
                        <div>&nbsp;</div>
                    :
                        <div>{issue.requester_phone}</div>
                    }
                    {(issue.requester_email === "") ?
                        <div>&nbsp;</div>
                    :
                        <div
                            className={classes.link}
                            onMouseEnter={() => {setCursorInElsewhere(true);}}
                            onMouseLeave={() => {setCursorInElsewhere(false);}}
                            onClick={() => {window.open("mailto:"+issue.requester_email);}}
                        >
                            {issue.requester_email}
                        </div>
                    }
                    <div>{issue.requester === "" ? "Unknown" : issue.requester}</div>
                    <div>{moment.unix(issue.created_at as number).utcOffset(timezone).format(TimeFormats.PresentationShort2)}</div>
                    <div>{!isActive ? appendedComments : ""}</div>
                    <Tooltip
                        title="individual view"
                        placement="right"
                    >
                        <IconButton
                            onClick={() => {dispatch(push("/support/"+issue.id));}}
                            onMouseEnter={() => {setCursorInElsewhere(true);}}
                            onMouseLeave={() => {setCursorInElsewhere(false);}}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {isActive &&
                <div className={classes.supportHistoryContentWrapper}>
                    <div className={classes.font}>
                        <Linkify componentDecorator={(href, content, key) => (<a target="blank" href={href} key={key} className={classes.link}>{content}</a>)}>
                            {issueHistory}
                        </Linkify>
                    </div>
                </div>
            }
        </div>
    </div>);
}

const SupportHistory = forwardRef((_props, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const { displayToast } = useToast();
    const { displayDialog } = useDialog();

    const { deviceId, deviceTypeId } = useSection();
    const [isActive, setActive] = useState(false);
    const [history, setHistory] = useState({} as {[id: number]: IssueState});
    const [localValue, setLocalValue] = useState(undefined as SupportIssue[] | undefined);

    const fetchData_ = useCallback(
        () => {
            return SupportService.getDeviceLogbookHistory(deviceTypeId, deviceId);
        },
        [deviceId, deviceTypeId]
    );
    const { exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
        clearValueOnExec: false,
        onError: toastError(displayToast),
        onComplete: (val : any) => {
            setLocalValue(val);
        },
    });
    useEffect(() => {
        fetchData();
    }, [deviceId]); //eslint-disable-line react-hooks/exhaustive-deps

    const issues = useMemo(() => {
        const setIssueStateById = (id: number, value: IssueState) => {
            const cloneHistory = ({} as {[id: number]: IssueState});
            for (const key in history)
                cloneHistory[key] = history[key];
            cloneHistory[id] = value;
            setHistory(cloneHistory);
        }
        return localValue?.map((issue) => {
            return SupportHistoryIssue(
                classes,
                issue,
                history[issue.id as number],
                (value : IssueState) => {setIssueStateById(issue.id as number, value);},
                dispatch,
            )}) ?? new Array<JSX.Element>(0);
    }, [localValue, history, classes, fetchData, displayToast, displayDialog]); //eslint-disable-line react-hooks/exhaustive-deps

    const [cursorInElsewhere] = useState(false);

    const extend = () => {
        if (!cursorInElsewhere)
            setActive(!isActive);
    };

    useImperativeHandle(ref, () => ({
        addLocalIssue(issue: SupportIssue) {
            fetchData();
            if (localValue === undefined)
                return;
            setLocalValue([issue].concat(localValue));
        }
    }));

    return (
        <>
            <div className={classes.supportHistoryHeader} onClick={extend}>
                {isActive
                    ? <DropDownIcon style={{marginRight: "10px"}}/>
                    : <DropDownIcon style={{transform: "rotate(-90deg)", marginRight: "10px"}}/>
                }
                <Typography>Support History</Typography>
            </div>
            {isActive &&
                <div className={classes.mobileWrapper}>
                    <div>
                        <div className={classes.supportHistoryTableHeader}>
                            <div>Issue ID</div>
                            <div>Assigned To</div>
                            <div>Category</div>
                            <div>Contact Phone</div>
                            <div>Contact Email</div>
                            <div>Contact Name</div>
                            <div>Date</div>
                            <div>Issue</div>
                        </div>
                        {issues}
                        <div style={{height: "10px"}}></div>
                    </div>
                </div>
            }
        </>
    );
});
export default SupportHistory;