import { useLayoutEffect, useCallback } from "react";
import { SharedState, SharedStateAction, SharedStateInstance, SharedStateHooks } from "src/components/SharedStateProvider";
import { useAsync } from "src/hooks";
import { UseReload } from "./useReload";
import ConfigurationsService, { Company } from "src/services/ConfigurationsService";

export interface UseCompanies {
    state: State;
}

export default function useCompanies(hooks: SharedStateHooks) {
    hooks.useReducer.push(reducer);
    hooks.useInstance.push(useInstance as any);
}

const LOAD_COMPANIES = "LOAD_COMPANIES" as const;
const LOADED_COMPANIES = "LOADED_COMPANIES" as const;

const loadCompanies = () => ({ type: LOAD_COMPANIES });
const loadedCompanies = (dto?: Array<Company>, error?: any) => ({
    type: LOADED_COMPANIES,
    payload: {
        dto,
        error,
    },
});

type State = SharedState<{
    companies: {
        dto: Array<Company>;
        loading: boolean;
        loaded: boolean;
        error?: any;
    };
}>;
type Action = SharedStateAction<
    | ReturnType<typeof loadedCompanies>
    | ReturnType<typeof loadCompanies>
>;

function reducer(state: State, action: Action): State {
    if (action.type === "INIT") {
        return {
            ...state,
            companies: {
                dto: [],
                loading: false,
                loaded: false,
            },
        };
    }

    if (action.type === LOAD_COMPANIES) {
        return {
            ...state,
            companies: {
                ...state.companies,
                loading: false,
                loaded: false,
            },
        };
    }

    if (action.type === LOADED_COMPANIES) {
        const { dto, error } = action.payload;

        if (error) {
            return {
                ...state,
                companies: {
                    ...state.companies,
                    loading: false,
                    loaded: false,
                    error,
                },
            };
        }

        return {
            ...state,
            companies: {
                dto: dto!,
                loaded: true,
                loading: false,
            },
        };
    }

    return state;
}

function useInstance(instance: UseReload & SharedStateInstance) {
    const {
        dispatch,
        state: {
            companies: { error: stateError },
        },
    } = instance;

    const getCompanies = useCallback(() => {
        return ConfigurationsService.getRecentHubspotCompanies().catch(() => {return [];});
    }, []);

    const { value, error, exec, pending } = useAsync(getCompanies, {
        defaultPending: true,
    });

    useLayoutEffect(() => {
        if (pending) {
            dispatch(loadCompanies());
        } else if (value) {
            dispatch(loadedCompanies(value));
        } else if (error) {
            dispatch(loadedCompanies(undefined, error));
        }
    }, [value, pending, error, dispatch]);

    if (stateError !== undefined) {
        instance.getHooks().useReload.push(exec);
    }

    instance
        .getHooks()
        .reloadingDeps.push((deps) => [
            ...deps,
            pending && stateError !== undefined,
        ]);
    instance
        .getHooks()
        .shouldReloadDeps.push((deps) => [...deps, stateError !== undefined]);
}
