import {
    createStore as createReduxStore,
    combineReducers,
    applyMiddleware,
    Reducer,
    compose,
    StoreEnhancer,
} from "redux";
import {
    connectRouter,
    routerMiddleware,
    RouterState,
} from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";

import { AppState, AppAction } from "./app/types";
import { AuthAction, AuthState, SIGN_OUT } from "./auth/types";

import appReducer from "./app/reducer";
import appSagas from "./app/sagas";
import authReducer from "./auth/reducer";
import authSagas from "./auth/sagas";

declare global {
    interface Window {
        process?: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose: <Ext0, StateExt0>( //eslint-disable-line @typescript-eslint/no-unused-vars
    f1: StoreEnhancer<Ext0, StateExt0>
) => StoreEnhancer<Ext0, StateExt0> =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type Action = AppAction | AuthAction;
export interface State {
    app: AppState;
    auth: AuthState;
    router: RouterState;
}

export const browserHistory = createBrowserHistory();

// This enhancer makes sure to reset state when a user logs out
const reducerEnhancer = (
    reducer: Reducer<State, Action>
): Reducer<State, Action> => (state: State | undefined, action: Action) => {
    if (action.type !== SIGN_OUT) return reducer(state, action);
    const newState = reducer(undefined, action);
    if (state) {
        newState.router = state.router;
    }
    return newState;
};

export default function createStore() {
    const sagaMiddleware = createSagaMiddleware();
    const store = createReduxStore(
        reducerEnhancer(
            combineReducers<State>({
                app: appReducer,
                auth: authReducer,
                router: connectRouter(browserHistory),
            })
        ),
        applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory))
    );
    sagaMiddleware.run(appSagas);
    sagaMiddleware.run(authSagas);
    return {
        ...store,
        runSaga: function (saga: any) {
            sagaMiddleware.run(saga);
        },
    };
}
