import { useLayoutEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { customersArraySelector } from "src/redux/app/selectors";
import UserHttpService, {
    AccessGroupsDto,
    CustomerAccessGroup,
} from "src/services/UserService";
import {
    SharedState,
    SharedStateAction,
    SharedStateInstance,
    SharedStateHooks,
} from "src/components/SharedStateProvider";
import { useAsync, useGetLatest } from "src/hooks";
import { UseReload } from "./useReload";

export interface UseAccessGroups {
    state: State;
    addCustomerAccessGroups: (
        customerId: number,
        accessGroups: CustomerAccessGroup[]
    ) => void;
}

export default function useAccessGroups(hooks: SharedStateHooks) {
    hooks.useReducer.push(reducer);
    hooks.useInstance.push(useInstance as any);
}

const LOAD_ACCESS_GROUPS = "LOAD_ACCESS_GROUPS" as const;
const LOADED_ACCESS_GROUPS = "LOADED_ACCESS_GROUPS" as const;
const ADD_CUSTOMER_ACCESS_GROUPS = "ADD_CUSTOMER_ACCESS_GROUPS" as const;

const loadAccessGroups = () => ({ type: LOAD_ACCESS_GROUPS });
const loadedAccessGroups = (dto?: AccessGroupsDto, error?: any) => ({
    type: LOADED_ACCESS_GROUPS,
    payload: {
        dto,
        error,
    },
});

const addAccessGroups = (
    customerId: number,
    accessGroups: CustomerAccessGroup[]
) => ({
    type: ADD_CUSTOMER_ACCESS_GROUPS,
    payload: {
        customerId,
        accessGroups,
    },
});

type State = SharedState<{
    accessGroups: {
        dto: AccessGroupsDto;
        loading: boolean;
        loaded: boolean;
        error?: any;
    };
}>;
type Action = SharedStateAction<
    | ReturnType<typeof loadedAccessGroups>
    | ReturnType<typeof loadAccessGroups>
    | ReturnType<typeof addAccessGroups>
>;

function reducer(state: State, action: Action): State {
    if (action.type === "INIT") {
        return {
            ...state,
            accessGroups: {
                dto: {},
                loading: false,
                loaded: false,
            },
        };
    }

    if (action.type === LOAD_ACCESS_GROUPS) {
        return {
            ...state,
            accessGroups: {
                ...state.accessGroups,
                loading: false,
                loaded: false,
            },
        };
    }

    if (action.type === LOADED_ACCESS_GROUPS) {
        const { dto, error } = action.payload;

        if (error) {
            return {
                ...state,
                accessGroups: {
                    ...state.accessGroups,
                    loading: false,
                    loaded: false,
                    error,
                },
            };
        }

        return {
            ...state,
            accessGroups: {
                dto: dto!,
                loaded: true,
                loading: false,
            },
        };
    }

    if (action.type === ADD_CUSTOMER_ACCESS_GROUPS) {
        const { customerId, accessGroups } = action.payload;
        return {
            ...state,
            accessGroups: {
                ...state.accessGroups,
                dto: {
                    ...state.accessGroups.dto,
                    [customerId]: accessGroups,
                },
            },
        };
    }

    return state;
}

function useInstance(instance: UseReload & SharedStateInstance) {
    const {
        dispatch,
        state: {
            accessGroups: { error: stateError },
        },
    } = instance;

    const customers = useSelector(customersArraySelector);
    const customerIds = useMemo(() => customers.map((c) => c.id), [customers]);
    const getCustomerIds = useGetLatest(customerIds);

    const getAccessGroups = useCallback(() => {
        return UserHttpService.getAccessGroupsDto(getCustomerIds());
    }, [getCustomerIds]);

    const { value, error, exec, pending } = useAsync(getAccessGroups, {
        defaultPending: true,
    });

    useLayoutEffect(() => {
        if (pending) {
            dispatch(loadAccessGroups());
        } else if (value) {
            dispatch(loadedAccessGroups(value));
        } else if (error) {
            dispatch(loadedAccessGroups(undefined, error));
        }
    }, [value, pending, error, dispatch]);

    const addCustomerAccessGroups = useCallback(
        (customerId: number, accessGroups: CustomerAccessGroup[]) => {
            dispatch(addAccessGroups(customerId, accessGroups));
        },
        [dispatch]
    );

    Object.assign(instance, {
        addCustomerAccessGroups,
    });

    if (stateError !== undefined) {
        instance.getHooks().useReload.push(exec);
    }

    instance
        .getHooks()
        .reloadingDeps.push((deps) => [
            ...deps,
            pending && stateError !== undefined,
        ]);
    instance
        .getHooks()
        .shouldReloadDeps.push((deps) => [...deps, stateError !== undefined]);
}
