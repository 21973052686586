import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import dateFormat from 'dateformat';

import OrangeButton from "../Components/OrangeButton.jsx"

export default function EditFeatureConfigurationPopup(props) {

    let now = new Date();
    let now_s = dateFormat(now, "yyyy-mm-dd");

    let featureConfig = props.featureConfig ? props.featureConfig : { From: [now_s], To: [""] };
    if (!props.ConfigurationId) {
        featureConfig.From = [now_s];
        featureConfig.To = [""];
        if (props.accessGroupsSaveId) {
            featureConfig.AccessGroupIds = JSON.parse(window.sessionStorage.getItem(props.accessGroupsSaveId + "_setAccessGroups")) || [];
            featureConfig.AccessGroupIds = featureConfig.AccessGroupIds.map(ag => ag.Id);
        }
    }

    featureConfig.From = featureConfig.From ? featureConfig.From : [];
    featureConfig.To = featureConfig.To ? featureConfig.To : [];
    featureConfig.AccessGroupIds = featureConfig.AccessGroupIds ? featureConfig.AccessGroupIds : [];

    const [open, setOpen] = React.useState(false);


    let noEndDates = featureConfig.To.map(t => { return !t; });
    let timeFrames = featureConfig.From.map((d, i) => {
        return { start: featureConfig.From[i], end: featureConfig.To[i], noEnd: noEndDates[i] }
    });

    const initialState = {
        feature: null,
        features: [],
        chosenAccessGroups: [],
        accessGroups: [],
        chosenOptions: {},
        timeFrames: timeFrames,
    };

    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const setFeature = (feature) => {
        setState({ feature: feature });
    };
    const setChosenAccessGroups = (chosenAccessGroups) => {
        if (props.accessGroupsSaveId)
            window.sessionStorage.setItem(props.accessGroupsSaveId + "_setAccessGroups", JSON.stringify(chosenAccessGroups));
        setState({ chosenAccessGroups: chosenAccessGroups });
    };
    const setAccessGroups = (accessGroups) => {
        setState({ accessGroups: accessGroups });
    };

    const sortTimeFrameLists = () => {
        state.timeFrames = state.timeFrames.sort((a, b) => {
            if (a.start === "")
                return 1;
            if (a.end === "")
                return -1;
            if (a.start > b.start)
                return 1;
            if (a.start < b.start)
                return -1;
            return 0;
        });
    };

    const setStartDate = (newStartDate, i) => {
        state.timeFrames[i].start = newStartDate;
        if (state.timeFrames[i].start > state.timeFrames[i].end)
            state.timeFrames[i].end = newStartDate;
        setState({ timeFrames: state.timeFrames });
    };
    const setEndDate = (newEndDate, i) => {
        state.timeFrames[i].end = newEndDate;
        setState({ timeFrames: state.timeFrames });
    };
    const setNoEndDate = (newNoEndDate, i) => {
        state.timeFrames[i].noEnd = newNoEndDate;
        setState({ timeFrames: state.timeFrames });
    };
    const addTimeFrame = () => {
        state.timeFrames.push({ start: "", end: "", noEnd: true })
        setState({ timeFrames: state.timeFrames });
    }

    const deleteTimeFrame = (index) => {
        state.timeFrames.splice(index, 1);
        setState({ timeFrames: state.timeFrames });
    }

    const updateAccessGroups = () => {
        axios("/api/dbtool/accessgroups")
            .then(response => response.data[0])
            .then(state => {
                setAccessGroups(state);
                setChosenAccessGroups(state.filter(ag => {
                    return featureConfig.AccessGroupIds.indexOf(ag.Id) >= 0;
                }));
            });
    };

    const updateFeatures = () => {
        axios(`/api/dbtool/featuresandoptions`)
            .then(response => response.data[0])
            .then(state => {

                let dict = {};
                for (let d of state) {
                    if (!dict[d.FeatureId])
                        dict[d.FeatureId] = { Tag: d.Tag, Group: d.Group, GroupId: d.GroupId, Id: d.FeatureId, Options: [], Configations: {} };
                    if (d.OptionId) {
                        dict[d.FeatureId].Options.push({ Id: d.OptionId, Tag: d.FeatureOption, description: d.FeatureOptionDescription })
                    }
                }
                let data = Object.values(dict);
                data = data.map((d) => {
                    d.Configations = Object.values(d.Configations);
                    return d;
                });
                let chosenFeature = data.filter(f => {
                    return f.Id === featureConfig.FeatureId;
                })[0];
                let newChosenOptions = {};
                if (featureConfig.OptionIds) {
                    featureConfig.OptionIds.forEach(oid => {
                        newChosenOptions[oid] = true;
                    })
                }

                setState({
                    features: data,
                    feature: chosenFeature,
                    chosenOptions: newChosenOptions
                });
            })
    };

    const handleClickOpen = () => {

        // Update selects
        updateFeatures();
        updateAccessGroups();

        setState(initialState);

        // open the popup
        setOpen(true);
    };

    const handleOptionToggle = (option, e) => {
        let enabled = e.target.checked;
        state.chosenOptions[option.Id] = enabled;
        if (!enabled)
            delete state.chosenOptions[option.Id];
        setState({ chosenOptions: state.chosenOptions })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let AccessGroupIds = state.chosenAccessGroups.map(ag => {
            return ag.Id;
        });
        let optionIds = Object.keys(state.chosenOptions).map(o => parseInt(o));
        let From = state.timeFrames.map((tf, i) => {
            return tf.start;
        });
        let To = state.timeFrames.map((tf, i) => {
            if (!tf.noEnd)
                return tf.end;
            else
                return "";
        });
        let data = {
            id: featureConfig.ConfigurationId,

            agci: featureConfig.AccessGroupCollectionId,
            fi: state.feature.Id,

            gr: AccessGroupIds,
            fr: From,
            to: To,
            o: optionIds
        };
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        axios("/api/dbtool/featureconfiguration", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not edit feature configuration");
            });
    };

    const handleDelete = () => {
        let requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
           
               
           
        };
        axios("/api/dbtool/featureconfiguration?agci="+featureConfig.AccessGroupCollectionId+"&fi="+state.feature.Id, requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not delete feature configuration");
            });
    }

    let ico = <Edit />
    if (props.noIcon)
        ico = <></>

    let chip;
    let secondary_styling = false;
    if (!(now_s >= featureConfig.From[0] && featureConfig.ConfigurationId))
        secondary_styling = true;
    if (props.forceSecondaryChipColor)
        secondary_styling = true;

    if (!secondary_styling)
        chip = <Chip label={props.chipLabel} variant="outlined" icon={ico} onClick={handleClickOpen} />;
    else
        chip = <Chip label={props.chipLabel} color="secondary" icon={ico} onClick={handleClickOpen} />;

    let btn = chip;
    if (props.tooltip)
        btn = <Tooltip title={props.tooltip}>{chip}</Tooltip>;

    let title = "Edit feature configuration";

    if (!props.chipStyleBtn) {
        btn = <OrangeButton title="Create feature configuration" onClick={handleClickOpen} />;
        title = "Create feature configuration";
    }

    sortTimeFrameLists();
    return (

        <>
            {btn}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>{title}</DialogTitle>
                <DialogContent style={{ overflowY: "visible" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                disabled={!!featureConfig.ConfigurationId}
                                style={{ minWidth: "290px" }}
                                options={state.accessGroups}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Access groups" />}
                                onChange={(e, v) => setChosenAccessGroups(v)}
                                value={state.chosenAccessGroups}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={!!featureConfig.ConfigurationId}
                                style={{ minWidth: "290px" }}
                                options={state.features}
                                getOptionLabel={(option) => (option["Tag"] ? option["Tag"] : "")}
                                getOptionSelected={(o, t) => { return (o && t) ? (o.Id === t.Id) : false }}
                                renderInput={(params) => <TextField {...params} label="Feature" />}
                                onChange={(e, v) => setFeature(v)}
                                value={state.feature}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormGroup row>
                                {(state.feature ? state.feature.Options : []
                                ).map((row, i) => (
                                    <FormControlLabel
                                        key={row.Id}
                                        control={<Checkbox checked={!!state.chosenOptions[row.Id]} onChange={handleOptionToggle.bind(this, row)} color="primary" />}
                                        label={row.Tag}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>



                        {state.timeFrames.map((tf, i) => (
                            <Grid container item xs={12} key={i}>
                                <Grid item xs={6} >
                                    <TextField
                                        id="from_date"
                                        label="Start date"
                                        type="date"
                                        value={tf.start}
                                        onChange={(e) => setStartDate(e.target.value, i)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} >
                                    <Checkbox checked={!tf.noEnd} onChange={(e) => setNoEndDate(!e.target.checked, i)} color="primary" />
                                    {(tf.noEnd ?
                                        <></> :
                                        <TextField
                                            id="end_date"
                                            label="End date"
                                            type="date"
                                            value={tf.end}
                                            onChange={(e) => setEndDate(e.target.value, i)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}

                                </Grid>

                                <Grid item xs={1} >
                                    <IconButton aria-label="delete" onClick={deleteTimeFrame.bind(this, i)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}

                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button onClick={addTimeFrame}>Add timeframe</Button>
                        </Grid>

                        {featureConfig.AccessGroupCollectionId ?
                            <Grid item xs={6}>
                                <Button onClick={handleDelete} variant="contained" color="secondary">
                                    DELETE
                                </Button>
                            </Grid> : <></>}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        SAVE
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
