import axios from "axios";
import { handleError } from "./Errors";
import { QCquestion } from "src/views/QCApp/QCApp";

export interface Chapter{
    name:string,
    version:number,
    allowedUsers:Array<string>,
    questions:Array<QCquestion>
}

export default class QCService {
    static async getWorkflows() {
        try {
            const login = await axios.post("https://xh76u2dlvb.execute-api.eu-central-1.amazonaws.com/v1/login",{username:"admin",password:"admin"});
            const response = await axios.post("https://xh76u2dlvb.execute-api.eu-central-1.amazonaws.com/v1/workflow/all",{},{headers:{accessToken:(login.data as any).data.accessToken}});
            
            const data=(response.data as any).data as any
            return data
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async postWorkflow(workflow:any){
        try {
            const login = await axios.post("https://xh76u2dlvb.execute-api.eu-central-1.amazonaws.com/v1/login",{username:"admin",password:"admin"});
            const response = await axios.put("https://xh76u2dlvb.execute-api.eu-central-1.amazonaws.com/v1/workflow",workflow,{headers:{accessToken:(login.data as any).data.accessToken}});

        } catch (error) {
            return handleError(error,false)
        }
    }


    static async getImage(image:string){
        try {
            const login = await axios.post("https://xh76u2dlvb.execute-api.eu-central-1.amazonaws.com/v1/login",{username:"admin",password:"admin"});
            const response = await axios.post("https://xh76u2dlvb.execute-api.eu-central-1.amazonaws.com/v1/utils/image",{image},{headers:{accessToken:(login.data as any).data.accessToken}});

            const data=(response.data as any).data.image
            return data
        } catch (error) {
            return handleError(error,false)
        }
    }
}