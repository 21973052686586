import { jaroWinkler } from "jaro-winkler-typescript";

export default function jarowinkler(target : string, dictionary : string[] | string) {
    dictionary = Array.isArray(dictionary) ? dictionary : [dictionary];
    let maxValue = -1;
    let maxTarget = "";
    for (const element of dictionary) {
        const current = jaroWinkler(target, element, {caseSensitive : false});
        if (current > maxValue) {
            maxValue = current;
            maxTarget = element;
        }
    }
    return { value : maxTarget, rating : maxValue };
}