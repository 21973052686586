import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import dateFormat from 'dateformat';

import OrangeButton from "../Components/OrangeButton.jsx"

export default function EditVersionConfigurationPopup(props) {

    let now = new Date();
    let now_s = dateFormat(now, "yyyy-mm-dd");
    let VersionConfig = props.config ? props.config : { From: [now_s], To: [""] };
    if (!props.config) {
        VersionConfig.From = [now_s];
        VersionConfig.To = [""];
        if (props.accessGroupsSaveId) {
            VersionConfig.AccessGroupIds = JSON.parse(window.sessionStorage.getItem(props.accessGroupsSaveId + "_setAccessGroups")) || [];
            VersionConfig.AccessGroupIds = VersionConfig.AccessGroupIds.map(ag => ag.Id);
        }
    } else {
        VersionConfig.AccessGroupIds = VersionConfig.AccessGroups.map(a => { return a.Id; });
    }

    VersionConfig.From = VersionConfig.From ? VersionConfig.From : [];
    VersionConfig.To = VersionConfig.To ? VersionConfig.To : [];
    VersionConfig.AccessGroupIds = VersionConfig.AccessGroupIds ? VersionConfig.AccessGroupIds : [];

    const [open, setOpen] = React.useState(false);


    let noEndDates = VersionConfig.To.map(t => { return !t; });
    let timeFrames = VersionConfig.From.map((d, i) => {
        return { start: VersionConfig.From[i], end: VersionConfig.To[i], noEnd: noEndDates[i] }
    });

    const initialState = {
        version: null,
        versions: [],
        chosenAccessGroups: [],
        accessGroups: [],
        timeFrames: timeFrames,
        is_rc: VersionConfig.IsRC ? VersionConfig.IsRC : false,
        priority: VersionConfig.Priority ? VersionConfig.Priority : 100
    };

    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const setVersion = (version) => {
        setState({ version: version });
    };
    const setChosenAccessGroups = (chosenAccessGroups) => {
        if (props.accessGroupsSaveId)
            window.sessionStorage.setItem(props.accessGroupsSaveId + "_setAccessGroups", JSON.stringify(chosenAccessGroups));
        setState({ chosenAccessGroups: chosenAccessGroups });
    };
    const setAccessGroups = (accessGroups) => {
        setState({ accessGroups: accessGroups });
    };
    const setIsRC = (IsRC) => {
        setState({ is_rc: IsRC });
    };
    const setPriority = (prio) => {
        setState({ priority: prio });
    };


    const sortTimeFrameLists = () => {
        state.timeFrames = state.timeFrames.sort((a, b) => {
            if (a.start === "")
                return 1;
            if (a.end === "")
                return -1;
            if (a.start > b.start)
                return 1;
            if (a.start < b.start)
                return -1;
            return 0;
        });
    };

    const setStartDate = (newStartDate, i) => {
        state.timeFrames[i].start = newStartDate;
        if (state.timeFrames[i].start > state.timeFrames[i].end)
            state.timeFrames[i].end = newStartDate;
        setState({ timeFrames: state.timeFrames });
    };
    const setEndDate = (newEndDate, i) => {
        state.timeFrames[i].end = newEndDate;
        setState({ timeFrames: state.timeFrames });
    };
    const setNoEndDate = (newNoEndDate, i) => {
        state.timeFrames[i].noEnd = newNoEndDate;
        setState({ timeFrames: state.timeFrames });
    };
    const addTimeFrame = () => {
        state.timeFrames.push({ start: "", end: "", noEnd: true })
        setState({ timeFrames: state.timeFrames });
    }

    const deleteTimeFrame = (index) => {
        state.timeFrames.splice(index, 1);
        setState({ timeFrames: state.timeFrames });
    }

    const updateAccessGroups = () => {
        axios("/api/dbtool/accessgroups")
            .then(response => response.data[0])
            .then(state => {
                setAccessGroups(state);
                setChosenAccessGroups(state.filter(ag => {
                    return VersionConfig.AccessGroupIds.indexOf(ag.Id) >= 0;
                }));
            });
    };

    const updateVersions = () => {
        axios(`/api/dbtool/robotversion_accessgroups_collections`)
            .then(response => response.data[0])
            .then(state => {

                let dict = {};
                for (let d of state) {
                    if (!dict[d.RobotVersionId])
                        dict[d.RobotVersionId] = { Tag: d.RobotVersionTag, Id: d.RobotVersionId };

                }
                let data = Object.values(dict);

                let chosenVersion = data.filter(f => {
                    return f.Id === props.parentData.Id;
                })[0];


                setState({
                    versions: data,
                    version: chosenVersion,
                });
            })
    };

    const handleClickOpen = () => {

        // Update selects
        updateVersions();
        updateAccessGroups();

        setState(initialState);

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {

        let old_cid;
        let old_agid = [];
        let old_version_id;

        if (VersionConfig.ConfigurationId) {
            old_cid = VersionConfig.AccessGroupsCollectionId;
            old_agid = VersionConfig.AccessGroups.map(ag => {
                return ag.Id;
            });
            old_version_id = props.parentData.Id;
        }

        let AccessGroupIds = state.chosenAccessGroups.map(ag => {
            return ag.Id;
        });
        let From = state.timeFrames.map((tf, i) => {
            return tf.start;
        });
        let To = state.timeFrames.map((tf, i) => {
            if (!tf.noEnd)
                return tf.end;
            else
                return "";
        });
        let data = {
            id: VersionConfig.ConfigurationId,
            gr: AccessGroupIds,
            vi: state.version.Id,
            agci: VersionConfig.AccessGroupsCollectionId,
            rc: state.is_rc,
            p: state.priority,
            fr: From,
            to: To
        };

        let delete_old = false;
        if (state.version.Id !== old_version_id) {
            delete_old = true;
        }
        if (old_agid.toString() !== AccessGroupIds.toString()) {
            delete_old = true;
        }
        if (VersionConfig.ConfigurationId && delete_old) {
            data.id = undefined;
            console.log("delete old first");

            doDelete(old_cid, old_version_id, VersionConfig.IsRC, (state) => {
                doSave(data);
            });

        } else
            doSave(data);
    };

    const doSave = (data) => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        axios("/api/dbtool/robotversionconfiguration", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not save robot version configuration, because: " + state.message + "");
            });
    };

    const handleDelete = () => {

        doDelete(VersionConfig.AccessGroupsCollectionId, props.parentData.Id, VersionConfig.IsRC, (state) => {
            if (state.success) {
                if (props.whenSuccess)
                    props.whenSuccess();
                handleClose();
            }
            else
                alert("Could not delete feature configuration");
        });
    }
    const doDelete = (agci, vi, rc, callback) => {
        let requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            
        };
        axios("/api/dbtool/robotversionconfiguration?agci="+agci+"&vi="+vi+"&rc="+rc, requestOptions)
            .then(response => response.data)
            .then(state => {
                callback(state);
            });
    }

    let ico = <Edit />
    if (props.noIcon)
        ico = <></>

    let chip;
    let color = "";
    if (now_s >= VersionConfig.From[0] && VersionConfig.ConfigurationId) {
        if (VersionConfig.IsRC)
            color = "orange";
    }
    else
        color = "#f50057"
    chip = <Chip label={props.chipLabel} variant="outlined" icon={ico} style={{ backgroundColor: color }} onClick={handleClickOpen} />;

    let btn = chip;
    if (props.tooltip)
        btn = <Tooltip title={props.tooltip}>{chip}</Tooltip>;

    let title = "Edit robot software version configuration";

    if (!props.chipStyleBtn) {
        btn = <OrangeButton title="Create feature configuration" onClick={handleClickOpen} />;
        title = "Create feature configuration";
    }

    sortTimeFrameLists();
    return (

        <>
            {btn}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>{title}</DialogTitle>
                <DialogContent style={{ overflowY: "visible" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                disabled={!!VersionConfig.ConfigurationId}
                                style={{ minWidth: "290px" }}
                                options={state.accessGroups}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                renderInput={(params) => <TextField {...params} label="Access groups" />}
                                onChange={(e, v) => setChosenAccessGroups(v)}
                                value={state.chosenAccessGroups}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                style={{ minWidth: "290px" }}
                                options={state.versions}
                                getOptionLabel={(option) => (option["Tag"] ? option["Tag"] : "")}
                                getOptionSelected={(o, t) => { return (o && t) ? (o.Id === t.Id) : false }}
                                renderInput={(params) => <TextField {...params} label="RobotSoftware version" />}
                                onChange={(e, v) => setVersion(v)}
                                value={state.version}
                            />
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={6} >
                                <TextField
                                    label="Priority"
                                    type="number"
                                    value={state.priority}
                                    onChange={(e) => setPriority(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} >
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={!!state.is_rc}
                                            onChange={(e) => setIsRC(e.target.checked)}
                                            color="primary"
                                        />
                                    } label="Is RC" />
                                </FormGroup>
                            </Grid>
                        </Grid>

                        {state.timeFrames.map((tf, i) => (
                            <Grid container item xs={12} key={i}>
                                <Grid item xs={6} >
                                    <TextField
                                        id="from_date"
                                        label="Start date"
                                        type="date"
                                        value={tf.start}
                                        onChange={(e) => setStartDate(e.target.value, i)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} >
                                    <Checkbox checked={!tf.noEnd} onChange={(e) => setNoEndDate(!e.target.checked, i)} color="primary" />
                                    {(tf.noEnd ?
                                        <></> :
                                        <TextField
                                            id="end_date"
                                            label="End date"
                                            type="date"
                                            value={tf.end}
                                            onChange={(e) => setEndDate(e.target.value, i)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}

                                </Grid>

                                <Grid item xs={1} >
                                    <IconButton aria-label="delete" onClick={deleteTimeFrame.bind(this, i)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}

                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button onClick={addTimeFrame}>Add timeframe</Button>
                        </Grid>

                        {props.config ?
                            <Grid item xs={6}>
                                <Button onClick={handleDelete} variant="contained" color="secondary">
                                    DELETE
                                </Button>
                            </Grid> : <></>}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        SAVE
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
