/* eslint-disable @typescript-eslint/ban-types */
import { Box, Button, LinearProgress} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { CellProps, Column } from "react-table";
import { useAsync } from "src/hooks";
import HistoryService from "src/services/HistoryService";
import BasicTable from "src/components/BasicTable";
import { SearchForm } from "./SearchForm";
import ExportButtonV2 from "src/ExportButtonV2";
import moment from "moment";
import { timezone } from "src/App";
import { TimeFormats } from "src/utils/constants";
import { useSelector } from "react-redux";
import { userAccessSelector } from "src/redux/auth/selectors";
import NotFound from "../NotFound";

export interface DeviceData{
    device_id: number;
    data_key: string;
    device_type: number,
    inserted: Date;
    data_val:string;  
    created: Date;  
}

interface HistroricOverviewProps{
    height: string
}

const DATA_PER_LOAD=50;

export function HistoryOverview(props:HistroricOverviewProps) {
    const {height}=props
    function DateCell({ cell: { value } }: CellProps<DeviceData>) {
        const dateString = moment(value).utcOffset(timezone).format(TimeFormats.Presentation);

        return <>{dateString}</>;
    }
    
    function DataCell({
        cell: { value },
        column: { id },
    }: CellProps<DeviceData>) {
            return <span>{value}</span>;
    }

    const [pageNumber,setPageNumber] = useState(0)
    const [data,setData]=useState([] as DeviceData[]);
    const [lastPrompt,setLastPrompt]=useState('');
    const [lastType,setLastType]=useState(1);
    const [lastField,setLastField]=useState('');
    const [noDataLeft,setNoDataLeft]=useState(false);
    const [lastDateFrom,setLastDateFrom]=useState(new Date())
    const [lastDateTo,setLastDateTo]=useState(new Date())

    const latestColumns: Column<DeviceData>[] = [
        {
            accessor: "device_id",
            Header: "Device Id",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "data_val",
            Header: lastField===''?"Enter field":lastField,
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "created",
            Header: "Created",
            Cell: DateCell,
            hideable: false,
        },
        {
            accessor: "inserted",
            Header: "Inserted",
            Cell: DateCell,
            hideable: false,
        },
       
    ];

    const fetchData_ = useCallback(
        async (
            field='',
            prompt='',
            clear=true,
            type=1,
            dateFrom,
            dateTo,
            pageNum=pageNumber
        ) => {
          
            setLastPrompt(prompt); 
            setLastType(type);
            setLastField(field);
            setLastDateFrom(dateFrom);
            setLastDateTo(dateTo)
            setPageNumber(pageNum)
            if (clear)
            setNoDataLeft(false)
            const newdata=(await HistoryService.getHistory(dateFrom,dateTo,field,prompt,type,DATA_PER_LOAD,pageNum) as any)[0]  as DeviceData[]

            if ((newdata as DeviceData[]).length<DATA_PER_LOAD)
            setNoDataLeft(true);
            if (clear)
            setData(newdata as DeviceData[])
            else
            setData((prevData:DeviceData[])=>prevData.concat(newdata as DeviceData[]))

        },
        [pageNumber]
    );

    const { error, pending, exec: fetchData} =useAsync(fetchData_, {
        immediate: false,
        clearValueOnExec: false,
    });
    
    function loadMore() {
        fetchData(lastField,lastPrompt,false,lastType,lastDateFrom,lastDateTo,pageNumber+1);
    }
    function clearTable() {
        setData([]); 
        setPageNumber(0);
        setNoDataLeft(false);
    }
    function loadAll() {
        return HistoryService.getHistory(lastDateFrom,lastDateTo,lastField,lastPrompt,lastType,2147483647,0);
    }

    const historyAccess=useSelector(userAccessSelector).includes('History')

    if (!historyAccess)
    return (<NotFound></NotFound>)
    return (
        <Box height={Number(height)-50}>
            <Box> 
                <SearchForm fetchData={fetchData} clearTable={clearTable}></SearchForm>
               
            </Box>
            
             <BasicTable
             h={'calc( 100vh - 258px )'}
            loading={pending || (data === undefined && error === undefined)}
            columns={latestColumns}
            data={(data ?? [] )as DeviceData[]}
            initialState={{pageSize:100000}}
            TablePagination={null}
            size="small"/>
            <Box style={{paddingTop:20,display:"flex",flexWrap:"wrap", justifyContent:"space-between"}}>
            {(pending || (data === undefined && error === undefined)) && <div style={{flexBasis:"100%",margin:"30px"}}><LinearProgress /></div>}
                <Button style={{height:50}} variant="outlined" onClick={loadMore} disabled={data.length===0 || noDataLeft || pending}>{noDataLeft?"No data left":"Load more"}</Button>  
                <Box style={{display:"flex",flexDirection:"column",alignItems:"start"}}>
                    <ExportButtonV2 dataArray={data} name="history" label='Displayed only' disabled={lastField===''}></ExportButtonV2>
                    <ExportButtonV2 loadData={loadAll} name="allhistory" label="All (may take some time)" disabled={lastField===''}></ExportButtonV2>
                </Box>
            </Box>
        </Box>
    );
}