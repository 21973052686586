import React, { useCallback, useEffect, useMemo } from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller, ValidationRules } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Robot, Tablet } from "src/services/DeviceDataService";
import type { Customer } from "src/services/UserService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { dealersSelector, customersTreeSelector, customersSelector, robotsSelector, tabletsSelector, robotsSelectorUnfiltered, tabletsSelectorUnfiltered, accessFieldsSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { AutocompleteProps, createFilterOptions } from "@material-ui/lab";
import { setRobots, setTablets } from "src/redux/app/actions";
import useDialog from "src/hooks/useDialog";
import { userAccessSelector } from "src/redux/auth/selectors";

interface ConnectDevicesInput {
    robot: Robot | null;
    tablet: Tablet | null;
    dealer: Customer | null;
    customer: Customer | null;
}

function mapSuccessMessage({
    robot,
    tablet,
    customer,
}: Omit<ConnectDevicesInput, "dealer">) {
    const parts: string[] = [];
    if (robot) {
        parts.push(`Robot ${robot.id}`);
    }
    if (tablet) {
        parts.push(`Tablet ${tablet.id}`);
    }
    return `Successfully connected ${parts.join(" with ")} at ${
        customer!.name
    }`;
}

function updateRobots(input: Omit<ConnectDevicesInput, "dealer">) {
    return (robots: Robot[]) => {
        const { robot, customer } = input;
        if (robot) {
            return robots.map((r) =>
                r.id === robot.id ? { ...r, customerId: customer!.id } : r
            );
        }
        return robots;
    };
}

function updateTablets(input: Omit<ConnectDevicesInput, "dealer">) {
    return (tablets: Tablet[]) => {
        const { robot, tablet, customer } = input;

        return tablets.map((t) => {
            if (t.id === tablet?.id)
                return {...t, customerId: customer!.id, connectedRobotId: robot?.id ?? t.connectedRobotId,};
            
            if (robot?.id !== undefined && t.connectedRobotId === robot.id)
                return {...t, connectedRobotId: null}

            return t;
        });
    };
}

const noCustomer: Customer = {
    id: -1,
    parentId: -1,
    dealerId: -1,
    isDealer: false,
    name: "No customer",
    countries: "",
    from: ""
};



type Field<K extends keyof ConnectDevicesInput = any> = {
    name: keyof ConnectDevicesInput;
    textProps?: Partial<TextFieldProps>;
    autocompleteProps?: Partial<AutocompleteProps<any, false, false, false>>;
    adminOnly?: boolean;
    validate?: (
        value: ConnectDevicesInput[K],
        values: ConnectDevicesInput
    ) => {
        result: string | boolean | undefined;
        fieldsToSanitize: (keyof ConnectDevicesInput)[];
    };
    rules?: Omit<ValidationRules, "validate">;
};

const getCustomerLabel = (c: any) => c.name;
const defaultAutoCompleteProps = {
    getOptionSelected: (a: any, b: any) => {
        return a.id === b.id;
    },
};

const validateDeviceField = (device: "robot" | "tablet") => {
    const other = device === "robot" ? ("tablet" as const) : ("robot" as const);
    return (value: any, { [other]: otherValue }: ConnectDevicesInput) => {
        otherValue = otherValue || null;
        const result =
            (value === null &&
                otherValue === null &&
                "Tablet or robot is required.") ||
            true;
        return {
            result,
            fieldsToSanitize: [other],
        };
    };
};

export default function ConnectWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        reset,
        getValues,
        clearErrors,
        watch,
        register,
    } = useForm<ConnectDevicesInput>({
        defaultValues: {
            robot: null,
            tablet: null,
            dealer: null,
            customer: null,
        },
    });

    const robots = useSelector(robotsSelector);
    const tablets = useSelector(tabletsSelector);
    const robotsUnfiltered = useSelector(robotsSelectorUnfiltered);
    const tabletsUnfiltered = useSelector(tabletsSelectorUnfiltered);
    const loadedDeviceInfo = robots.length > 0 && tablets.length > 0;
    const { displayToast } = useToast();
    const dispatch = useDispatch();
    
    const [connectWidgetLastTabletId,setConnectWidgetLastTabletId]=useLocalStorage("ConnectWidgetLastTabletId",-1 )
    const [connectWidgetLastRobotId,setConnectWidgetLastRobotId]=useLocalStorage("ConnectWidgetLastRobotId",-1 )
    const [connectWidgetLastDealerId,setConnectWidgetLastDealerId]=useLocalStorage("ConnectWidgetLastDealerId",-1)
    const [connectWidgetLastCustomerId,setConnectWidgetLastCustomerId]=useLocalStorage("ConnectWidgetLastCustomerId",-1)

    const dealers = useSelector(dealersSelector);
    const userAccess = useSelector(userAccessSelector);
    const isNormalUser = !(useSelector(accessFieldsSelector)?.some(af => userAccess.includes(af.tag) && af.access_lvl > 0));
    const customersTree = useSelector(customersTreeSelector)!;
    const customersDto = useSelector(customersSelector).dto;

    
    function connectDevicesWrapped({
    dealer,
    customer,
    ...devices
    }: ConnectDevicesInput) {
        const targetCustomer = customer === noCustomer ? dealer : customer;
                return ConfigurationsService.connectDevices({
                customerId: targetCustomer!.id,
                robotId: devices.robot?.id,
                tabletId: devices.tablet?.id,
                })
        .then(() => ({
            ...devices,
            customer: targetCustomer,
        }))
    }

    
    const fields: Field[] = [
        {
            name: "tablet",
            textProps: {
                label: "Tablet",
                placeholder: "Choose a tablet",
            },
            autocompleteProps: {
                ...defaultAutoCompleteProps,
                getOptionLabel: (t) => {
                    const customer = customersDto[t.customerId ?? -1];
                    let ret = t.id;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+t.customerId+"]";
                    return ret;
                },
            },
            validate: validateDeviceField("tablet"),
            rules: {},
        },
        {
            name: "robot",
            textProps: {
                label: "Robot",
                placeholder: "Choose a robot",
            },
            autocompleteProps: {
                ...defaultAutoCompleteProps,
                getOptionLabel: (r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    return ret;
                },
                getOptionSelected: (a, b) => a.id === b.id,
                renderOption: (r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    if (r.deprecated !== undefined && r.deprecated !== null)
                        return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                    return (<div>{ret}</div>);
                },
            },
            validate: validateDeviceField("robot"),
            rules: {},
        },
        {
            name: "dealer",
            textProps: {
                label: "Dealer",
                placeholder: "Choose a dealer",
            },
            autocompleteProps: {
                ...defaultAutoCompleteProps,
                getOptionLabel: getCustomerLabel,
            },
            adminOnly: true,
        },
        {
            name: "customer",
            textProps: {
                label: "Customer",
                placeholder: "Choose a customer",
            },
            autocompleteProps: {
                ...defaultAutoCompleteProps,
                getOptionLabel: getCustomerLabel,
            },
        },
    ];

    const dealer = watch("dealer");

    useEffect(() => {
        if (isNormalUser) {
            register("dealer");
        }
    }, [isNormalUser, register]);

    useEffect(() => {
        if (isNormalUser) {
            const self = customersTree.top?.item;
            if (self !== undefined && self !== dealer) {
                setValue("dealer", self);
            }
        }
    }, [isNormalUser, customersTree, setValue, dealer]);

    useEffect(() => {
        setValue("customer", null);
    }, [dealer, setValue]);

    useEffect(() => {
        const robot = getValues("robot");
        if (robot) {
            const current = robots.find((r) => r.id === robot.id);
            if (current && current !== robot) {
                setValue("robot", current);
            }
        }
    }, [robots, getValues, setValue]);    

    const customers = useMemo(() => {
        if (dealer) {
            return [
                noCustomer,
                ...(customersTree
                    ?.find((c) => c.id === dealer.id)
                    ?.toArray()
                    .slice(1) || []),
            ];
        }
        return [];
    }, [dealer, customersTree]);
   
    useEffect(()=>{
            if (saveChanges){
          
            if (connectWidgetLastRobotId && connectWidgetLastRobotId!==getValues().robot?.id && robots.find(r=>r.id===connectWidgetLastRobotId))
            setValue('robot',robots.find(r=>r.id===connectWidgetLastRobotId))
            if (connectWidgetLastDealerId && connectWidgetLastDealerId!==getValues().dealer?.id && dealers.find(r=>r.id===connectWidgetLastDealerId))
            setValue('dealer',dealers.find(r=>r.id===connectWidgetLastDealerId))
            if (connectWidgetLastCustomerId && connectWidgetLastCustomerId!==getValues().customer?.id && customers.find(r=>r.id===connectWidgetLastCustomerId))
            setValue('customer',customers.find(r=>r.id===connectWidgetLastCustomerId))
            if (connectWidgetLastTabletId && connectWidgetLastTabletId!==getValues().tablet?.id && tablets.find(r=>r.id===connectWidgetLastTabletId))
            setValue('tablet',tablets.find(r=>r.id===connectWidgetLastTabletId))

        } 
    },[saveChanges,robots,tablets,customers,dealers,setValue,getValues,connectWidgetLastCustomerId,connectWidgetLastDealerId,connectWidgetLastRobotId,connectWidgetLastTabletId])

    const onComplete = useCallback(
        (connectedDevices: Omit<ConnectDevicesInput, "dealer">) => {
            dispatch(setRobots(updateRobots(connectedDevices)(robotsUnfiltered)));
            dispatch(setTablets(updateTablets(connectedDevices)(tabletsUnfiltered)));
            if (!saveChanges)
            reset(undefined, {});
            displayToast({
                message: mapSuccessMessage(connectedDevices),
                severity: "success",
                withCloseIcon: true,
            });
        },
        [dispatch, robotsUnfiltered, tabletsUnfiltered, reset, displayToast,saveChanges]
    );

    const { exec, pending: connectingDevices, error } = useAsync(
        connectDevicesWrapped,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const optionsMap = {
        robot: robots,
        tablet: tablets,
        dealer: dealers,
        customer: customers,
    };

    const loading = connectingDevices || !loadedDeviceInfo;

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Connect devices"
            subTitle="Connect tablet with robot at customer. Leaving either tablet or robot blank is possible and will assign only the specified device."
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            {fields.map(
                ({
                    name,
                    adminOnly,
                    textProps,
                    autocompleteProps,
                    validate,
                    rules,
                }) => {
                    rules = rules || defaultRules;
                    rules = validate
                        ? ({
                              ...rules,
                              validate: (value: any) => {
                                  const { result, fieldsToSanitize } = validate(
                                      value,
                                      getValues()
                                  );
                                  if (result && fieldsToSanitize.length) {
                                      clearErrors(fieldsToSanitize);
                                  }
                                  return result;
                              },
                          } as any)
                        : rules;
                    return (
                        (!adminOnly || !isNormalUser) && (
                            <Controller
                                key={name}
                                name={name}
                                control={control}
                                rules={rules}
                                render={({ onChange, ...rest }) => (
                                    <AutocompleteV2
                                        selectOnTab
                                        {...rest}
                                        {...(autocompleteProps || {})}
                                        disabled={loading}
                                        options={optionsMap[name]}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...getErrorProps(
                                                        errors,
                                                        name
                                                    )}
                                                    {...params}
                                                    {...(textProps || {})}
                                                    margin="dense"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                />
                                            );
                                        }}
                                        onChange={(_ev, val) => {
                                           
                                            if (saveChanges){
                                                if (!val){
                                                    if (name==='robot'){
                                                        setConnectWidgetLastRobotId(-1)                                                        
                                                    } else
                                                    if (name==='tablet'){
                                                        setConnectWidgetLastTabletId(-1)                                                        
                                                    } else
                                                    if (name==='customer'){
                                                        setConnectWidgetLastCustomerId(-1)                                                        
                                                    } else
                                                    if (name==='dealer'){
                                                        setConnectWidgetLastDealerId(-1)                                                        
                                                    }
                                                }else{

                                                                     
                                                    if (robots.find(r=>r===val))
                                                    setConnectWidgetLastRobotId(val.id)
                                                    else if (tablets.find(t=>t===val))
                                                    setConnectWidgetLastTabletId(val.id)
                                                    else if (customers.find(t=>t===val))
                                                    setConnectWidgetLastCustomerId(val.id)
                                                    else if (dealers.find(t=>t===val))
                                                    setConnectWidgetLastDealerId(val.id)
                                                }
                                            }
                                            onChange(val);
                                        }}
                                    />
                                )}
                            />
                        )
                    );
                }
            )}
        </BaseWidget>
    );
}
