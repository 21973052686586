import React from "react";
import { FilterProps } from "react-table";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SupportDto } from "src/services/SupportService";
import moment from "moment";
import ClearIcon from "@material-ui/icons/Clear";
import { Box, IconButton } from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { DateColumnFilterOptions } from "src/components/FilteredTable/filterTypes";
import { useSelector } from "react-redux";
import { userSelector } from "src/redux/app/selectors";

export function OpenColumnFilter({
    column: { filterValue, setFilter },
}: FilterProps<SupportDto>) {
    const checked : boolean = filterValue;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setFilter(
            newChecked
                ? {
                      columnIds: ["status"],
                      value: "(open|new)",
                  }
                : undefined
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!!checked}
                    onChange={handleChange}
                    name="hide-closed"
                    size="small"
                />
            }
            label="Hide closed issues"
        />
    );
}

export function AssignedToMeColumnFilter({
    column: { filterValue, setFilter },
}: FilterProps<SupportDto>) {
    const checked : boolean = filterValue;

    const user = useSelector(userSelector);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setFilter(
            newChecked
                ? {
                      columnIds: ["assignee_email"],
                      value: user.email,
                  }
                : undefined
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!!checked}
                    onChange={handleChange}
                    name="show-mine"
                    size="small"
                />
            }
            label="Only show mine"
        />
    );
}

export function TimestampColumnFilter({
    column: { filterValue, setFilter },
}: FilterProps<SupportDto>) {
    const notBefore = (filterValue?.values[0] && moment.unix(filterValue.values[0])) || null;
    const notAfter = (filterValue?.values[1] && moment.unix(filterValue.values[1])) || null;

    return (
        <Box p={0}>
            <Box mb={1}>
                <KeyboardDateTimePicker
                    disableFuture
                    fullWidth
                    label="Not before"
                    margin="dense"
                    inputVariant="outlined"
                    value={notBefore}
                    onChange={(date) => {
                        if (notAfter === null || date?.isBefore(notAfter)) {
                            setFilter((old : DateColumnFilterOptions = {values: [null,null]}) => {
                                return {
                                    columnIds: ["created_at","created_at"],
                                    values: [date!.valueOf()/1000, old.values[1]],
                                };
                            });
                        }
                    }}
                    maxDate={notAfter || undefined}
                    ampm={false}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    strictCompareDates
                    InputProps={{
                        readOnly: true,
                        endAdornment: notBefore !== null && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setFilter((old : DateColumnFilterOptions = {values: [null,null]}) => {
                                        return {
                                            columnIds: ["created_at","created_at"],
                                            values: [null, old.values[1]],
                                        };
                                    });
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    KeyboardButtonProps={{ size: "small" }}
                    InputAdornmentProps={{ position: "start" }}
                />
            </Box>
            <Box>
                <KeyboardDateTimePicker
                    disableFuture
                    fullWidth
                    label="Not after"
                    inputVariant="outlined"
                    margin="dense"
                    ampm={false}
                    value={notAfter}
                    onChange={(date) => {
                        if (notBefore === null || date?.isAfter(notBefore)) {
                            setFilter((old : DateColumnFilterOptions = {values: [null,null]}) => {
                                return {
                                    columnIds: ["created_at","created_at"],
                                    values: [old.values[0], date!.valueOf()/1000],
                                };
                            });
                        }
                    }}
                    minDate={notBefore || undefined}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    strictCompareDates
                    InputProps={{
                        readOnly: true,
                        endAdornment: notAfter !== null && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setFilter((old : DateColumnFilterOptions = {values: [null,null]}) => {
                                        return {
                                            columnIds: ["created_at","created_at"],
                                            values: [old.values[0], null],
                                        };
                                    });
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    KeyboardButtonProps={{ size: "small" }}
                    InputAdornmentProps={{ position: "start" }}
                />
            </Box>
        </Box>
    );
}