import { TFARequest } from "src/services/AuthService";
import {
    SIGN_IN,
    SIGNING_IN,
    SIGNED_IN,
    SIGN_OUT,
    INIT_AUTH,
    AuthAction,
    UserCredentials,
} from "./types";

export const signIn = (username: string, password: string,confirmationCode?:string): AuthAction => ({
    type: SIGN_IN,
    payload: {
        username: username || "",
        password: password || "",
        confirmationCode: confirmationCode,
    },
});

export const signingIn = (): AuthAction => ({
    type: SIGNING_IN,
});

export const signedIn = (
    credentials?: UserCredentials,
    error?: any,
    redirect?: boolean,
    TFANeeded?:TFARequest,
): AuthAction => {
    if (TFANeeded){
        return {
            type: SIGNED_IN,
            payload: {
                success: false,
                error: null,
                TFANeeded: true,
            },
        };

    }
    if (credentials && error === undefined) {
        return {
            type: SIGNED_IN,
            payload: {
                success: true,
                credentials,
                redirect: redirect === undefined ? true : redirect,
            },
        };
    }
    return {
        type: SIGNED_IN,
        payload: {
            success: false,
            error,
            TFANeeded: false
        },
    };
};

export const signOut = (message?: string): AuthAction => ({
    type: SIGN_OUT,
    meta: message ? { message } : undefined,
});

export const initAuth = (): AuthAction => ({ type: INIT_AUTH });
