/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useRef, useMemo } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { List } from "react-virtualized";
import { TableRendererProps, GetRowProps, applyUserProps } from "../BasicTable";
import { HighlightedCell, getHighlightProps } from "../BasicTable/Cells";
import FilteredTable, { FilteredTableProps } from "../FilteredTable";
import { useScrollbarWidth } from "src/hooks";
import { Hooks } from "react-table";

const rowsPerPageOptions = [50, 100, 500, 1000, 5000];

export interface VirtualizedTableProps<T extends {}>
    extends FilteredTableProps<T> {
    height: number;
    expand?: boolean;
}

export default function VirtualizedTable<T extends {}>({
    hooks: passedHooks,
    ...rest
}: VirtualizedTableProps<T>) {
    return (
        <FilteredTable
            rowsPerPageOptions={rowsPerPageOptions}
            {...rest}
            Body={VirtualizedBody}
            hooks={[...(passedHooks || []), useVirtualizedTable]}
        />
    );
}

export const useHeaderWithScrollableBodyStyles = makeStyles((theme) => ({
    headerRow: {
        "& > *": {
            borderBottom: "none",
        },
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

function useVirtualizedTable<T extends {}>(hooks: Hooks<T>) {
    // The first child of the table body is the element we're interested in
    const { ref, width } = useScrollbarWidth(true);
    const { headerRow } = useHeaderWithScrollableBodyStyles();

    const headerProps = useMemo(() => {
        return {
            style: { paddingRight: width },
            className: headerRow,
        };
    }, [width, headerRow]);

    hooks.getHeaderGroupProps.push(headerProps);
    hooks.headerGroupsDeps.push((d) => [...d, headerProps]);
    hooks.getTableBodyProps.push({ ref } as any);
}

const fixedListStyles = { maxWidth: "100%" };

function VirtualizedBody<T extends {}>({
    classes,
    instance,
    rowProps,
}: TableRendererProps<
    T,
    {
        rowProps?: GetRowProps<T>;
    }
>) {
    const {
        prepareRow,
        highlightRowOnUpdate = false,
        totalColumnsWidth,
        visibleColumns,
        page,
        loading,
        onRowClick,
    } = instance;

    const { height, expand = true } = instance as any;

    const Cell = highlightRowOnUpdate
        ? (HighlightedCell as typeof TableCell)
        : TableCell;

    const RenderEmpty = useCallback(
        () => (
            <TableRow className={classes.row} component="div">
                <TableCell
                    className={classes.cell}
                    colSpan={visibleColumns.length}
                    component="div"
                >
                    {instance.controlled && loading
                        ? "Loading..."
                        : "No results found"}
                </TableCell>
            </TableRow>
        ),
        [instance, loading, classes.row, classes.cell, visibleColumns.length]
    );
   
    const RenderRow = useCallback(
        ({ index, style, key }: any) => {
            const row = page[index];            
            prepareRow(row);
            return (
                <TableRow
                    onClick={() => {if (onRowClick !== undefined) onRowClick(index, row);}}
                    key={key}
                    component="div"
                    {...row.getRowProps(
                        applyUserProps(rowProps, {
                            className: ` ${((index%2===0)?(classes.rowWhiteGreyBckg):(classes.rowWhiteBckg))}`,
                            style,
                        })
                    )}
                >
                    {row.cells.map((cell) => {
                        return (
                            <Cell
                                {...cell.getCellProps(
                                    applyUserProps(
                                        undefined,
                                        getHighlightProps,
                                        {
                                            className: classes.cell,
                                        }
                                    )
                                )}
                                component="div"
                            >
                                <Tooltip title={cell.value}><div>{cell.render("Cell")}</div></Tooltip>
                            </Cell>
                        );
                    })}
                </TableRow>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [classes.cell, classes.row, Cell, page, prepareRow, rowProps]
    );

    const rowHeight = 30;
    const maxHeight =
        Math.max(500, height + 37) - 240 - (instance.state.filters.length ? 40 : 0);
    const actualHeight = (page.length || 1) * 35;

    const listRef = useRef<List>(null);

    const { pageIndex, filters, globalFilter, sortBy } = instance.state;
    const autoScrollDependency = instance.controlled
        ? [page]
        : [instance.data, pageIndex, filters, globalFilter, sortBy];

    useEffect(() => {
        if (
            listRef.current &&
            (instance.controlled || instance.autoResetPage)
        ) {
            listRef.current.scrollToRow(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, ...autoScrollDependency]);

    return (
        <List
            ref={listRef}
            height={Math.min(maxHeight, expand ? maxHeight : actualHeight)}
            rowCount={page.length}
            rowHeight={rowHeight}
            width={totalColumnsWidth}
            autoWidth
            rowRenderer={RenderRow}
            noRowsRenderer={RenderEmpty}
            containerStyle={fixedListStyles}
            className={classes.scrollContainer}
        />
    );
}
