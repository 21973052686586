import React, { useCallback, useEffect, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { deleteCustomer as deleteCustomerRedux } from "src/redux/app/actions";
import ConfigurationsService from "src/services/ConfigurationsService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { dealersSelector, customersTreeSelector, customersArraySelector } from "src/redux/app/selectors";
import type { Customer } from "src/services/UserService";
import useDialog from "src/hooks/useDialog";
import AutocompleteV2 from "src/components/AutocompleteV2";

interface DeleteCustomerInput {
    dealer: Customer | null;
    customer: Customer | null;
}

export default function DeleteCustomerWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        reset,
        watch,
        setValue
    } = useForm<DeleteCustomerInput>({
        defaultValues: { dealer: null, customer: null },
    });

    const dispatch = useDispatch();
    const { displayToast } = useToast();
    const { displayDialog } = useDialog();

    const customers = useSelector(customersArraySelector);
    const dealers = useSelector(dealersSelector);
    const customersTree = useSelector(customersTreeSelector)!;

    const dealer = watch("dealer");
    const children = useMemo(() => {
        if (dealer) {
            return (
                customersTree?.find((c) => c.id === dealer.id)?.toArray() || customers
            );
        }
        return customers;
    }, [customers, dealer, customersTree]);

    
    const [deleteCustomerWidgetLastDealerId,setDeleteCustomerWidgetLastDealerId]=useLocalStorage("DeleteCustomerWidgetLastDealerId",-1)

    useEffect(()=>{
        if (saveChanges){
            if (deleteCustomerWidgetLastDealerId && deleteCustomerWidgetLastDealerId!==dealer?.id)
            {
                const dlr=dealers.find(d=>d.id===deleteCustomerWidgetLastDealerId)
                if (dlr)
                setValue('dealer',dlr)
            }
        }
    },[saveChanges,dealers,deleteCustomerWidgetLastDealerId,dealer,watch,setValue])

    const deleteCustomerWrapped = useCallback(
        async ({ customer }: DeleteCustomerInput) => {
            const customerData = await ConfigurationsService.getDevicesWhereCustomer(customer!.id);
            let dialogResult = true;
            if (customerData.customers.length !== 0 || customerData.tablets.length !== 0 || customerData.robots.length !== 0) {
                let dialogText = "The selected customer (" + customer!.name + ") still has assigned:";
                if (customerData.customers.length !== 0) {
                    dialogText += "\n• customers:";
                    for (let i = 0; i < customerData.customers.length && i < 3; i++)
                        dialogText += "\n- " + customerData.customers[i].name + " (" + customerData.customers[i].id + ")";
                    if (customerData.customers.length > 3)
                        dialogText += "\n...(and more)"
                }
                if (customerData.tablets.length !== 0) {
                    dialogText += "\n• tablets:";
                    for (let i = 0; i < customerData.tablets.length && i < 3; i++)
                        dialogText += "\n- " + customerData.tablets[i].id;
                    if (customerData.tablets.length > 3)
                        dialogText += "\n...(and more)"
                }
                if (customerData.robots.length !== 0) {
                    dialogText += "\n• robots:";
                    for (let i = 0; i < customerData.robots.length && i < 3; i++)
                        dialogText += "\n- " + customerData.robots[i].name + " (" + customerData.robots[i].id + ")";
                    if (customerData.robots.length > 3)
                        dialogText += "\n...(and more)"
                }
                dialogText += "\n\nDo you wish to proceed?";
                dialogResult = await displayDialog({dialogText});
            }
            if (dialogResult) {
                await ConfigurationsService.deleteCustomer(customer!.id);
                dispatch(deleteCustomerRedux(customer!));
                if (!saveChanges)
                reset();
                displayToast({
                    message: `Successfully deleted Customer ${customer!.name} (id: ${customer!.id})`,
                    severity: "success",
                    withCloseIcon: true,
                });
            } else
                displayToast({
                    message: `Customer deletion cancelled.`,
                    severity: "warning",
                    withCloseIcon: true,
                });
        },
        [dispatch, displayDialog, displayToast, reset,saveChanges]
    );

    const { exec, pending: deletingCustomer, error } = useAsync(
        deleteCustomerWrapped,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
        }
    );

    const loading = deletingCustomer;

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Delete customer"
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <Controller
                name="dealer"
                control={control}
                render={({ onChange, ...props }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...props}
                            disabled={loading}
                            options={dealers}
                            noOptionsText="No such dealer"
                            getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Dealer"
                                        placeholder="Choose a dealer"
                                        {...getErrorProps(errors, "dealer")}
                                    />
                                );
                            }}
                            onChange={(_e, val) => {
                                onChange(val);
                                if (saveChanges){
                                    setDeleteCustomerWidgetLastDealerId(val.id)
                                
                            }}}
                        />
                    );
                }}
            />
            <Controller
                name="customer"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...props }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...props}
                            disabled={loading}
                            options={children}
                            noOptionsText="No such customer"
                            getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Customer"
                                        placeholder="Choose a customer"
                                        required
                                        {...getErrorProps(errors, "customer")}
                                    />
                                );
                            }}
                            onChange={(_e, val) => {onChange(val);}}
                        />
                    );
                }}
            />
        </BaseWidget>
    );
}