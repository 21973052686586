import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import OrangeButton from "../Components/OrangeButton.jsx"


export default function CreateFeatureGroupPopup(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [isTemplateGroup, setIsTemplateGroup] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { name: name, isTemplateGroup: isTemplateGroup }
        };
        axios("/api/dbtool/featuregroup", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not create new feature group");
            });
    };


    return (
        <>
            <OrangeButton title="Create feature group" onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Create feature group</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                id="name"
                                label="Name"
                                type="text"
                                style={{ width: "100%" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={isTemplateGroup} onChange={(e) => setIsTemplateGroup(e.target.checked)} />}
                                label="Is template group"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        CREATE
          </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
          </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
