import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import mapRenderProps, { RenderProps } from "../../../utils/mapRenderProps";
import { useSection } from "./SectionProvider";
import { DeviceData } from "src/services/DeviceDataService";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    deprecatedBar: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: "red",
        color: theme.palette.common.white,
        padding: theme.spacing(1) / 2,
        height: 42,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: 42,
    },
    statusBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(1) / 2,
        "& > *": {
            height: 42,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: 42,
        },
    },
    title: {
        fontWeight: "bolder",
    },
    leftContent: {
        padding: "0 16px 0 0",
    },
    rightContent: {
        padding: "0 0 0 16px",
    },
    centerContent: {
        padding: "0 16px 0 16px",
    },
}));

export interface SectionHeaderProps {
    title: string;
    deviceType: number;
    LeftAdornment?: RenderProps<unknown>;
    RightAdornment?: RenderProps<unknown>;
    deprecated?: DeviceData;
}

export const SectionHeader = React.memo(function SectionHeader(
    props: SectionHeaderProps
) {
    const { LeftAdornment, RightAdornment, title, deprecated, deviceType } = props;
    const classes = useStyles();
    const leftContent = mapRenderProps(LeftAdornment || null);
    const rightContent = mapRenderProps(RightAdornment || null);
    const hideSideContent = leftContent === null && rightContent === null;
    const isDeprecated = deprecated !== undefined && deprecated !== null;
    return (
        <div>
            {(isDeprecated) && <div className={classes.deprecatedBar}>
                <Typography variant="h6" style={{marginRight: "10px"}}>Deprecated {deviceType===1?"Robot":"Tablet"}!</Typography>
                {(deprecated!.value !== "") && <Button variant="contained" color="success" href={"/devices/"+(deviceType===1?"robots":"tablets")+"/"+deprecated!.value}>new {deviceType===1?"robot":"tablet"}</Button>}
            </div>}
            <Grid
                style={isDeprecated?{}:{borderTopLeftRadius:3,borderTopRightRadius:3}}
                container
                className={classes.statusBar}
                justify="center"
                alignContent="center"
            >
                {!hideSideContent && (
                    <Hidden mdDown>
                        <Grid xs={4} item className={classes.leftContent}>
                            {leftContent}
                        </Grid>
                    </Hidden>
                )}

                <Grid xs={12} lg={4} item className={classes.centerContent}>
                    <Typography
                        className={classes.title}
                        component="h1"
                        variant="h5"
                    >
                        {title}
                    </Typography>
                </Grid>
                {!hideSideContent && (
                    <>
                        <Hidden lgUp>
                            <Grid
                                xs={6}
                                md={4}
                                item
                                className={classes.leftContent}
                            >
                                {leftContent}
                            </Grid>
                        </Hidden>
                        <Grid xs={6} md={4} item className={classes.rightContent}>
                            {rightContent}
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
});

export default function SectionHeaderWrapped(
    props: Omit<SectionHeaderProps, "title"> & { primary?: boolean }
) {
    const { deviceName, data, deviceTypeId } = useSection();
    return <SectionHeader
        title={`${deviceName} Status`}
        {...props}
        deprecated={data.deprecated as any}
        deviceType={deviceTypeId}
    />;
}
