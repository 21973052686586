import React from "react";
import BasicTable from "src/components/BasicTable";
import { CellProps, Column } from "react-table";
import tcm1 from "src/TCM1.png";
import tcm2 from "src/TCM2.png";
import tcp1 from "src/TCP1.png";
import { Button, Modal, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    
    button: {
        marginTop: "2px",
        height: "40px",
        
        '&:hover': {
            borderColor: theme.palette.primary.light,
        },
    },
}));


interface ProductModel
{
    model_id: string;
    product_name: string;
    frame: string;
    platform: string;
    family: string;
    image_src: string;
}

function hardcodedRobots() : ProductModel[]
{
    const robots : ProductModel[] = []
    robots.push({
        model_id: "TCM1",
        product_name:"TLM Sport",
        frame: "C1",
        platform: "B",
        family: "linemarker",
        image_src: tcm1
    })
    robots.push({
        model_id: "TCM2",
        product_name:"TLM Sport 2",
        frame: "C12",
        platform: "B",
        family: "linemarker",
        image_src: tcm2
    })
    robots.push({
        model_id: "TCS1",
        product_name:"TS Plotter",
        frame: "C2",
        platform: "B",
        family: "surveyor",
        image_src: ""
    })
    robots.push({
        model_id: "TCP1",
        product_name:"TS Plotter GNSS",
        frame: "C2",
        platform: "B",
        family: "surveyor",
        image_src: tcp1
    })
    robots.push({
        model_id: "TLP1",
        product_name:"TLM ProX",
        frame: "B4",
        platform: "B",
        family: "linemarker",
        image_src: ""
    })
    robots.push({
        model_id: "TPC1",
        product_name:"TLM ProX CAD",
        frame: "B4",
        platform: "B",
        family: "surveyor",
        image_src: ""
    })

    return robots;
}

export function ProductTable()
{
    const classes = useStyles();
    const productModels = hardcodedRobots();
    const [open, setOpen] = React.useState(false);
    const [currentImage, setImage] = React.useState("");
    const handleClose = () => {
        if(currentImage)
        {
            setImage("");
        }
        else
        {
            setOpen(false);
        }
         
    };
    const handleOpen = () => {
        setOpen(true);
    };
    function DataCell({
        cell: { value },
        column: { id },
    }: CellProps<ProductModel>) {
            return <span>{value}</span>;
    }
    function ImageCell({
        cell: { value },
        column: { id },
    }: CellProps<ProductModel>) {
            return <span>
                    {(value) && <Button
                        className={classes.button}
                        size="small"
                        variant="outlined"
                        onClick={(event) =>{
                            event.preventDefault();
                            if(!value)
                                value="no_image"
                            setImage(value);
                            handleOpen();
                        }} 
                        >
                        View Schema
                    </Button>}
                    {(!value) && ("No schema")}
                </span>
    }
    const pruductColumns: Column<ProductModel>[] = [
        {
            accessor: "model_id",
            Header: "Model Id",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "product_name",
            Header: "Product name",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "frame",
            Header: "Frame",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "platform",
            Header: "Platform",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "family",
            Header: "Family",
            Cell: DataCell,
            hideable: false,
        },
        {
            accessor: "image_src",
            Header: "Schema",
            Cell: ImageCell,
            hideable: false,
        },
       
    ];
    return (
        <div>
            <Modal
            style={{ color: '#fff', display:"flex",alignItems:"center",justifyContent:"center"}}
            open={open}
            onClose={handleClose}
            >
                <div>
                    {(!currentImage) && <div style={{minWidth: '60vw'}}>
                        <BasicTable
                        loading={(productModels === undefined)}
                        columns={pruductColumns}
                        data={(productModels ?? [] ) as ProductModel[]}
                        initialState={{pageSize:10}}
                        TablePagination={null}
                        size="small"/>
                    </div>}

                    {(currentImage && currentImage !== "no_image") && <div>
                        <img src={currentImage} alt="robot" style={{height:"60vh"}}/>
                    </div>}
                </div>
            </Modal>
            
            <Button
                className={classes.button}
                size="medium"
                variant="outlined"
                onClick={handleOpen} 
            >
                Show product models information
            </Button>
        </div>
    )
}