import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    container: {
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
    },
    paperFlex: {
        [theme.breakpoints.up("lg")]: {
            marginTop: theme.spacing(8),
        },
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        maxWidth: 500,
        [theme.breakpoints.up("sm")]: {
            width: "70%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    paper: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
    selects: {
        marginTop: `${theme.spacing(1)}px !important`,
    },
    links: {
        cursor: "pointer",
        color: "#0000EE",
        textDecoration: "underline",
        '&:hover': {
            color: "#551A8B",
        },
    },
    deleteButton: {
        '& button': {
            backgroundColor: "#9f0000",
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: "#e80000",
            },
        }
    },
}));
