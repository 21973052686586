import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";
import { DeviceType } from "src/utils/constants";
import { ProductionCommandsWrapper } from "../Devices/Dashboard/Commands";
import { useSelector } from "react-redux";
import { userAccessSelector } from "src/redux/auth/selectors";
import { useDebounced } from "src/hooks";

export default function Commands() {

    const [deviceType, setDeviceType] = React.useState<DeviceType>(DeviceType.Robot);
    const [deviceId, setDeviceId] = React.useState<number | undefined>(undefined);

    const commandAccess = useSelector(userAccessSelector).includes("Commands");
    const commandSuperAccess = useSelector(userAccessSelector).includes("CommandsSuper")
    const debouncedDeviceId = useDebounced(deviceId, 1000);
    return (
    commandAccess ? <Box>
        <FormControl fullWidth style={{width:"300px"}} size="small" required variant="outlined">
            <InputLabel id="device-label">Device Type</InputLabel>
            <Select
                labelId="device-label"
                id="device"
                fullWidth
                variant="outlined"
                margin = "dense"
                value={deviceType}
                required
                label="Device Type  f"
                onChange={(_ev) => {
                    setDeviceType(_ev.target.value as number);
                }}
            >
                <MenuItem value={1}>Robot</MenuItem>
                <MenuItem value={3}>Base Station</MenuItem>
            </Select>
        </FormControl>
        
        <TextField
            style={{width:"300px"}}
            label={deviceType === DeviceType.Robot ? "Robot ID" : "Base Station ID"}
            required
            margin="dense"
            type="number"
            value={deviceId??""}
            onChange={(ev: any) => {setDeviceId(ev.target.value === "" ? undefined : ev.target.value);}}
            variant="outlined"
        />
        <ProductionCommandsWrapper deviceId={debouncedDeviceId} deviceType={deviceType} atLeastSupport={commandSuperAccess}/>
    </Box> : <></>)
}