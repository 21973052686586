import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Link, Modal, Switch, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { push } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ReadonlyField from "src/components/ReadonlyField";
import { useAsync, useLocalStorage, useToast } from "src/hooks";
import { customersSelector, userSelector, usersSelector } from "src/redux/app/selectors";
import UserService from "src/services/UserService";
import NotFound from "../NotFound";
import useFormStyles from "./useFormStyles";
import TwoFactorAuthentificationService from "src/services/TwoFactorAuthentificationService";
import { userAccessSelector } from "src/redux/auth/selectors";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    accordion: {
        color: theme.palette.grey[700],
    },
    accordionBorder:{
        boxShadow:'none', 
        border: '1px solid rgb(150,150,150)'
    }
}));


export default function ViewAccount() {
    const classes = useFormStyles()
    const localClasses=useStyles()

    const own = !window.location.pathname.includes("accounts");
    const authorizedUser = useSelector(userSelector);
    const paramUsername = useParams<{username:string}>().username;
    const users = useSelector(usersSelector);
    const customers = useSelector(customersSelector);
    const user = own ? authorizedUser : users.find(u => u.username === paramUsername);

    const dispatch = useDispatch();
    const { displayToast } = useToast();
    const userAccess=useSelector(userAccessSelector).includes('User')

    const fetchAccessFields = useCallback((() => UserService.getAccessFields()
    .then((afs)=>
    afs.sort((a,b)=>a.access_lvl>b.access_lvl?1:-1)
    )),[])
    const { value: accessFields, exec: fetchAccessFields_ } = useAsync(fetchAccessFields, {
        immediate: false,
    });
    useEffect(() => {
        fetchAccessFields_();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const edit = () => {
        if (own)
            dispatch(push("/account/me/edit"));
        else
            dispatch(push("/accounts/"+user?.username+"/edit"));
    }

    const resend = async () => {
        try {
            await UserService.sendVerification();
            displayToast({
                message: "email sent",
                severity: "success",
                withCloseIcon: true,
            });
        } catch(_e) {
            displayToast({
                message: "an error occurred while trying to send the email",
                severity: "error",
                withCloseIcon: true,
            });
        }
    }
    
    
    const [tFAEnabled,setTFAEnabled]=useLocalStorage('2FAEnabled',false)
    const [qr,setQr]=useState(<></>)
    const [triggerPrompt,setTriggerPrompt]=useState(false);
    const [code,setCode]=useState(0)

    const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

    if (user === undefined && users.length > 0)
        return (<NotFound />);

        const getUserLvl=(lvl:number)=>{
            switch(lvl)
            {
                case 0: return 'Customer'
                case 1: return "Dealer"
                case 2: return "Support"
                case 3: return "Admin"
                default: return "Other"
            }
        }    
    const lvls=[...new Set(accessFields?.map(af=>af.access_lvl))].map((lvl)=>{return {lvl:lvl,coverage: (accessFields?.filter((af)=>(af.access_lvl===lvl && user?.userAccess.includes(af.tag)))?.length ?? 0)/(accessFields?.filter((af)=>af.access_lvl===lvl).length??1)}}).sort(
        (a,b)=>{
            if (a.coverage>b.coverage)
            return -1
            else if (b.coverage>a.coverage)
            return 1
            else return a.lvl>b.lvl?-1:1
        }
    )

    return (<>
            <Box component="main" className={classes.container}>
                <div className={classes.paperFlex}>
                    <Typography component="h1" variant="h4">
                        {own ? "View My Account" : "View Account"}
                    </Typography>
                    <div className={classes.form}>
                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12} md={12}>
                                <ReadonlyField
                                    value={user?.username}
                                    label={"Username"}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12} md={12}>
                                <ReadonlyField
                                    value={user?.email}
                                    label={"Email"}
                                />
                            </Grid>
                        </Grid>
                        {(own && (authorizedUser !== undefined && !(authorizedUser.verified ?? true))) &&
                            <Typography style={{marginTop: "10px"}}>Did not receive verification email? Click <span className={classes.links} onClick={resend}>here</span> to resend it.</Typography>
                        }
                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12} md={12}>
                                <ReadonlyField
                                    value={(customers.loaded && user !== undefined) ? user?.customerId?.map((cust:number,index)=>customers.dto[cust]?.name+((index===0 && user?.customerId?.length>1)?' (Main)':'')).join('\n')  : ""}
                                    label={"Associated Customer"}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.selects}>
                            <Grid item xs={12} md={6}>
                                <ReadonlyField
                                    value={user?.firstname}
                                    label={"First Name"}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ReadonlyField
                                    value={user?.lastname}
                                    label={"Last Name"}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.selects}>
                        <Grid item xs={12} md={12}>
                            <Accordion className={localClasses.accordionBorder}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}} />} >
                                    {/* eslint-disable-next-line */}
                                        {`User Access - ${Number((lvls[0]?.coverage>0 && lvls[0]?.lvl))} LVL (${getUserLvl(Number((lvls[0]?.coverage>0 && lvls[0]?.lvl)))}) ${(lvls?.find((lvl)=>lvl.lvl>lvls[0].lvl)?.coverage??0)>0?'+':''}`}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    {[...new Set(accessFields?.map(af=>af.access_lvl))]?.map((lvl,index)=>
                                    accessFields?.filter((af)=>af.access_lvl===lvl).find((af)=>user?.userAccess.includes(af.tag)) 
                                    && (<Grid item xs={12} md={12} key={index}>
                                
                                        <Accordion className={localClasses.accordionBorder}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize:24}} />} >
                                                <FormControlLabel 
                                                label={<span className={localClasses.accordion}>{"Level "+lvl}</span>}
                                                
                                                control={
                                                    <Checkbox className={localClasses.accordion}
                                                    style={{color:"rgb(100,100,100)"}}
                                                    checked={!(accessFields?.filter((af)=>af.access_lvl===lvl).find((af)=>!user?.userAccess.includes(af.tag)))}
                                                    indeterminate={Boolean(accessFields?.filter((af)=>af.access_lvl===lvl).find((af)=>!user?.userAccess.includes(af.tag)))}
                                                    disabled
                                                    />
                                                }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>        
                                                <FormGroup>
                                                    {accessFields?.filter((af)=>af.access_lvl===lvl).filter((af)=>(userAccess || user?.userAccess.includes(af.tag))).map((af,index)=>
                                                    <Tooltip title={af.name}key = {index}>
                                                        <FormControlLabel
                                                        
                                                        label={<span className={localClasses.accordion}>{af.tag}</span>}
                                                        style={{marginLeft:"10px"}}
                                                        control={
                                                        <Checkbox 
                                                            checked={user?.userAccess.includes(af.tag)}
                                                            style={{color:"rgb(100,100,100)"}}
                                                            disabled
                                                            />}/>
                                                    </Tooltip>
                                                    )
                                                    }
                                                </FormGroup>
                                           
                                            </AccordionDetails>
                                          </Accordion>
                                
                                        </Grid>))}
                                    </AccordionDetails>   
                              </Accordion>
                            </Grid>
                        </Grid>
                       
                        {(own && (authorizedUser !== undefined && !(authorizedUser.verified ?? true))) &&
                            <Typography style={{color: "red", marginTop: "20px"}}>Your email is not verified. Please update and/or verify your email!</Typography>
                        }
                        {(own || userAccess) &&
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={edit}
                            >
                                Edit Details
                            </Button>
                        }
                        {(!own) &&
                            <Button
                                className={classes.button}
                                variant="contained"
                                fullWidth
                                onClick={() => {dispatch(push("/accounts"));}}
                            >
                                Back
                            </Button>
                        } 

                        {(own) &&
                            <Button
                                onClick={async ()=>{
                                    setQr(<></>)
                                    const link=(await TwoFactorAuthentificationService.getCode())
                                    setQr((<Link href={link} target="_blank"><QRCode value={link} ></QRCode></Link>))
                                    handleOpen()
                                }}
                                variant="contained"
                                fullWidth color="primary"
                                style={{marginTop:"50px"}}
                            >Connect 2 Factor Authenticator</Button>
                        }

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box style={{position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: '2px solid #000',
                                        boxShadow: "24",
                                        padding: "40px",textAlign:"center"}}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">Please connect using your 2FA app</Typography>
                                <Typography id="modal-modal-description" style={{ marginTop:"10" }}>{qr}</Typography>
                            </Box>
                        </Modal>
                        
                        {(own) &&
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={Boolean(tFAEnabled)}
                                        onClick={()=>{
                                            setTriggerPrompt(true);
                                        }}/>
                                }
                                label="Enable 2 Factor Authentication"
                                style={{width:"100%",margin:"10px 0"}}
                            />
                        }

                        {triggerPrompt && <Box style={{display:"flex",justifyContent:"space-between",marginTop:"30px"}}>
                            <TextField
                                type="text"
                                name="token"
                                id="token"
                                inputMode="numeric"
                                autoComplete="one-time-code"
                                style={{width:"200px"}}
                                variant="outlined"
                                label="2FA code"
                                onChange={(e)=>setCode(Number(e.target.value))}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{width:"200px"}}
                                onClick={async ()=>{
                                    const res=await TwoFactorAuthentificationService.enable(!tFAEnabled??false,Number(code));
                                    if (res===200)
                                        setTFAEnabled(!tFAEnabled)
                                    else
                                        alert('Wrong code')
                                    setTriggerPrompt(false)
                                }
                            }>Confirm</Button>
                        </Box>}

                    </div>
                </div>
            </Box>
    </>);
}