import React, {
    useState,
    useCallback,
    useMemo,
    useContext,
    useRef,
} from "react";
import { useGetLatest, useResizeObserver } from "src/hooks";

interface SharedHeightInstance {
    style?: React.CSSProperties;
    onHeightChange: (
        name: string,
        height: React.MutableRefObject<{ scrollHeight: number } | null>
    ) => void;
}

const SharedHeightContext = React.createContext<SharedHeightInstance>(
    undefined!
);

export default function SharedHeightProvider({
    children,
    disabled,
}: React.PropsWithChildren<{ disabled?: boolean }>) {
    const heightRef = useRef<Record<string, number>>({});
    const [state, setState] = useState<{ minHeight: number }>({
        minHeight: 0,
    });

    const getDisabled = useGetLatest(disabled ?? false);
    const onHeightChange = useCallback(
        (
            name: string,
            ref: React.MutableRefObject<{ scrollHeight: number } | null>
        ) => {
            const heightState = heightRef.current;
            heightState[name] = ref.current?.scrollHeight || 0;
            if (!getDisabled()) {
                const minHeight = Object.values(heightState).reduce(
                    (acc, next) => (next > acc ? next : acc),
                    0
                );
                setState((s) =>
                    s.minHeight === minHeight ? s : { minHeight }
                );
            }
        },
        [getDisabled]
    );

    const instance: SharedHeightInstance = useMemo(() => {
        const style = disabled ? undefined : state;
        return {
            style,
            onHeightChange,
        };
    }, [state, disabled, onHeightChange]);

    return (
        <SharedHeightContext.Provider value={instance}>
            {children}
        </SharedHeightContext.Provider>
    );
}

export function useSharedHeight(
    name: string
): {
    elementStyle?: React.CSSProperties;
    sizerStyle?: React.CSSProperties;
    sizerRef: React.MutableRefObject<any>;
} {
    const { style, onHeightChange } = useContext(SharedHeightContext);
    const elementRef = useRef<Element>(null);

    const minHeight = style?.minHeight ?? -1;
    const sizerStyle = React.useMemo(
        () =>
            minHeight !== -1 ? { height: 0, overflowY: "visible" } : undefined,
        [minHeight]
    );

    useResizeObserver({
        ref: elementRef,
        callback: () => {
            onHeightChange(name, elementRef);
        },
    });

    return {
        elementStyle: style,
        sizerStyle,
        sizerRef: elementRef,
    } as any;
}
