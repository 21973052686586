import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { operationsSelector } from "src/redux/app/selectors";
import Dropzone, { FileError, FileRejection } from "react-dropzone";
import { useToast } from "src/hooks";
import useDialog from "src/hooks/useDialog";
import ExcelJS from "exceljs";
import CsvReadableStream from "csv-reader";
import { Button, Checkbox, FormControlLabel, makeStyles, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import AdministrationService, { BulkOperation, Operation } from "src/services/AdministrationService";
import { AbcFromNumber, ARGBtoRGBA, deviceTypeIdToString } from "src/utils/common";
import moment, { isDate } from "moment";
import TriStateCheckbox from "react-three-state-checkbox";
import { timezone } from "src/App";

const FILE_TYPES = ["xlsx","csv"];
const ID_COLUMN_COLOR = "#FF0000";
const DATA_COLUMN_COLOR = "#0000FF";
const VALID_COLOR = "#009933";
const MATCHING_DATA_COLOR = "#00FF55";
const ID_NOT_NUMBER_COLOR = "#DDDD00";
const EMPTY_DATA_COLOR = "#DDDD00";
const INVALID_ID_COLOR = "#FF2222";
const CONFLICTING_COLOR = "#FF9900";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "row",
    },
    left: {
        flexBasis: "300px",
        flexShrink: 0,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        marginRight: "15px",
        '&>*': {
            marginBottom: "10px",
        },
    },
    right: {
        maxWidth: "calc(100% - 320px)",
        '&>div:nth-child(1)': {
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "top",
            flexDirection: "row",
            '&>div:nth-child(1)': {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
            },
            '&>div:nth-child(2)': {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
            },
            '&>div:nth-child(1)>*': {
                marginRight: "10px",
            },
            '&>div:nth-child(2)>*': {
                marginLeft: "10px",
            },
        },
        '&>div:nth-child(2)': {
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
            border: "solid 1px black",
        },
    },
    dropzone: {
        border: "dashed 1px black",
        cursor: "pointer",
        padding: "10px",
        color: "#0000EE",
        '&:hover': {
            color: "#551A8B",
        },
        '&>p': {
            marginTop: "3px",
            marginRight: "6px",
            margin: "0px",
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
        },
    },
    file: {
        cursor: "pointer",
        textAlign: "center",
        minHeight: "20px",
        border: "1px solid black",
        borderRadius: "10px",
        fontFamily: theme.typography.fontFamily,
        paddingRight: "4px",
        paddingBottom: "1px",
        paddingTop: "5px",
        backgroundColor: "#00CC00",
        '&:hover': {
            backgroundColor: "#FF9999",
        },
        '&:active': {
            backgroundColor: "#FF8888",
        },
    },
    execute: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    legend: {
        "&>*": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
        },
        "&>*>*:nth-child(1)": {
            border: "1px solid black",
            width:"12px",
            height: "12px",
            marginRight: "5px",
        },
    },
    table: {
        borderSpacing: "0px",
        '& td': {
            border: "solid 1px black",
        },
        '& th': {
            fontSize: "17px",
            fontFamily: theme.typography.fontFamily,
            paddingTop: "6px",
            border: "solid 1px black",
            fontWeight: "bold",
        },
    },
    dialogTable: {
        maxHeight: "calc(30vh - 200px)",
        overflow: "auto",
        '& table': {
            width: "100%",
            borderCollapse: "collapse",
            border: "solid 1px black",
        },
        '& td': {
            border: "solid 1px black",
        },
        '& th': {
            border: "solid 1px black",
        },
    },
}));

interface ExcelCell {
    value?: string;
    type?: string;
    style?: {
        color?: string;
        backgroundColor?: string;
        fontStyle?: string;
        fontWeight?: number;
        textDecoration?: string;
    }
}

/*
WARNING: the code that follows will make you cry; a safety pig is provided below for your benefit.

 _._ _..._ .-',     _.._(`))
'-. `     '  /-._.-'    ',/
   )         \            '.
  / _    _    |             \
 |  a    a    /              |
 \   .-.                     ;  
  '-('' ).-'       ,'       ;
     '-;           |      .'
        \           \    /
        | 7  .__  _.-\   \
        | |  |  ``/  /`  /
       /,_|  |   /,_/   /
          /,_/      '`-'
*/
export default function AdministrationOperations() {
    const classes = useStyles();

    const allOperations = useSelector(operationsSelector);

    const { displayToast } = useToast();
    const { displayDialog } = useDialog();

    const [file, setFile] = useState(undefined as undefined | File);
    const [workbook, setWorkbook] = useState(undefined as undefined | ExcelJS.Workbook);
    const [sheet, setSheet] = useState(null as null | number);
    const [lastDelimiter, setDelimiter] = useState(";");
    const [headers, setHeaders] = useState([] as Array<string>);
    const [data, setData] = useState([] as Array<Array<ExcelCell>>);
    const [operationType, setOperationType] = useState(null as null | string);
    const [operation, setOperation] = useState(null as null | Operation);
    const [idColumn, setIdColumn] = useState(null as null | number);
    const [dataColumn, setDataColumn] = useState(null as null | number);
    const [autoselect, setAutoselect] = useState(true);
    const [splitColumn, setSplitColumn] = useState("");
    const [rowFilter, setRowFilter] = useState([] as Array<boolean>);

    const readXlsx = useCallback(async (xlsxFile: File) => {
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.onload = () => {
            const buffer = reader.result;
            if (buffer !== null && typeof buffer !== "string") {
                wb.xlsx.load(buffer).then(loaded => {
                    setWorkbook(loaded);
                })
            }
        }
        reader.readAsArrayBuffer(xlsxFile);
    }, []);

    //new workbook
    useEffect(() => {
        if (workbook !== undefined) {
            setSheet(0);
            setIdColumn(0);
            setDataColumn(1);
        } else {
            setSheet(null);
            setData([]);
            setIdColumn(null);
            setDataColumn(null);
        }
    }, [workbook]);

    //new sheet
    useEffect(() => {
        if (workbook !== undefined && sheet !== null) {
            setIdColumn(0);
            setDataColumn(1);
            let columns = 0;
            const res = new Array<Array<ExcelCell>>(0);
            workbook.worksheets[sheet].eachRow((row, rowNumber) => {
                const resRow = new Array<ExcelCell>(0);
                row.eachCell((cell, colNumber) => {
                    if (colNumber === 0)
                        return;
                    const resCell = {value: "", type: "string", style: {}} as ExcelCell;
                    if (typeof cell.value === "number" || typeof cell.value === "string" || typeof cell.value === "boolean") {
                        resCell.value = cell.value as string;
                        resCell.type = typeof cell.value;
                    } else if (cell.value === undefined || cell.value === null) {
                        resCell.value = "";
                        resCell.type = "null";
                    } else if (isDate(cell.value)) {
                        resCell.value = moment(cell.value.getTime()).utcOffset(timezone).format("DD.MM.YYYY");
                        resCell.type = "date";
                    } else {
                        resCell.value = cell.text as string;
                        resCell.type = typeof cell.value;
                    }
                    resRow[colNumber-1] = resCell;
                });
                if (resRow.length > columns)
                    columns = resRow.length;
                res[rowNumber-1] = resRow;
            })
            for (let j = 0; j < res.length; j++)
                for (let i = 0; i < columns; i++) {
                    if (res[j] === undefined)
                        res[j] = new Array<ExcelCell>(columns);
                    if (res[j][i] === undefined)
                        res[j][i] = {value: "", type: "string", style: {}};
                    const cell = workbook.worksheets[sheet].getCell(j+1,i+1);
                    if (cell.style.font !== undefined && cell.style.font.color !== undefined && cell.style.font.color.argb !== undefined)
                        res[j][i].style!.color = ARGBtoRGBA(cell.style.font.color.argb);
                    if (cell.style.fill !== undefined && (cell.style.fill as any).fgColor !== undefined && (cell.style.fill as any).fgColor.argb !== undefined)
                        res[j][i].style!.backgroundColor = ARGBtoRGBA((cell.style.fill as any).fgColor.argb);
                    if (cell.style.font !== undefined && cell.style.font.bold !== undefined && (cell.style.font.bold as boolean))
                        res[j][i].style!.fontWeight = 700;
                    if (cell.style.font !== undefined && cell.style.font.italic !== undefined && (cell.style.font.italic as boolean))
                        res[j][i].style!.fontStyle = "italic";
                    if (cell.style.font !== undefined && cell.style.font.underline !== undefined && (cell.style.font.underline as boolean))
                        res[j][i].style!.textDecoration = "underline";
                    if (cell.style.font !== undefined && cell.style.font.strike !== undefined && (cell.style.font.strike as boolean))
                        if (res[j][i].style!.textDecoration !== undefined)
                            res[j][i].style!.textDecoration += " line-through";
                        else
                            res[j][i].style!.textDecoration = "line-through";
                }
            const splittedHeaders = new Array<string>(0);
            for (let i = 0; i < columns; i++)
                splittedHeaders.push(AbcFromNumber(i));
            if (splitColumn !== "") {
                for (let i = 0; i < columns; i++) {
                    let amount = 1;
                    for (const row of res) {
                        const splitted = row[i].value!.toString().split(splitColumn);
                        if (splitted.length > amount)
                            amount = splitted.length;
                    }
                    if (amount > 1) {
                        const original = splittedHeaders[i];
                        for (let k = 0; k < amount; k++)
                            splittedHeaders.splice(i+k,k===0?1:0,original+(k+1));
                    }
                    for (const row of res) {
                        const splitted = row[i].value!.toString().split(splitColumn);
                        while (splitted.length < amount)
                            splitted.push("");
                        for (let k = 0; k < amount; k++) {
                            const temp = {...row[i]};
                            temp.value = splitted[k];
                            row.splice(i+k,k===0?1:0,temp);
                        }
                    }
                    columns += amount-1;
                    i += amount-1;
                }
            }
            setHeaders(splittedHeaders);
            setData(res);
        }
    }, [workbook, sheet, splitColumn]);

    const readCsv = useCallback(async (csvFile: File, askDelimiter = true) => {
        setWorkbook(undefined);
        const txt = await csvFile.text();
        let delimiter = ";";
        const semicolonCount = (txt.match(/;/g) || []).length;
        const commaCount = (txt.match(/,/g) || []).length;
        if (commaCount > semicolonCount)
            delimiter = ",";
        setDelimiter(delimiter);
        if (askDelimiter)
            if (!await displayDialog({positiveButtonText: "Ok", negativeButtonText: "Cancel", dialogText: (
                <div>
                    <Typography style={{marginBottom: "5px"}}>Please choose a csv delimiter!</Typography>
                    <TextField
                        label="Delimiter"
                        margin="dense"
                        size="small"
                        defaultValue={delimiter}
                        onChange={(e) => {
                            delimiter = e.target.value;
                            setDelimiter(delimiter);
                        }}
                        variant="outlined"
                    />
                </div>
            )})) {
                setFile(undefined);
                displayToast({
                    message: "canceled",
                    severity: "warning",
                    withCloseIcon: true,
                });
                return;
            }
        const csv = new CsvReadableStream({ delimiter: askDelimiter ? delimiter : lastDelimiter});
        const res = new Array<Array<ExcelCell>>(0);
        let columns = 0;
        csv.on('data', (row) => {
            if (Array.isArray(row)) {
                res.push(row.map((v: any) => {return {value: v as string, type: "string", style: {}};}));
                if (columns < row.length)
                    columns = row.length;
            }
        }).on('finish', () => {
            for (let j = 0; j < res.length; j++)
                for (let i = 0; i < columns; i++) {
                    if (res[j] === undefined)
                        res[j] = new Array<ExcelCell>(columns);
                    if (res[j][i] === undefined)
                        res[j][i] = {value: "", type: "string", style: {}};
                }
            const splittedHeaders = new Array<string>(0);
            for (let i = 0; i < columns; i++)
                splittedHeaders.push(AbcFromNumber(i));
            if (splitColumn !== "") {
                for (let i = 0; i < columns; i++) {
                    let amount = 1;
                    for (const row of res) {
                        const splitted = row[i].value!.toString().split(splitColumn);
                        if (splitted.length > amount)
                            amount = splitted.length;
                    }
                    if (amount > 1) {
                        const original = splittedHeaders[i];
                        for (let k = 0; k < amount; k++)
                            splittedHeaders.splice(i+k,k===0?1:0,original+(k+1));
                    }
                    for (const row of res) {
                        const splitted = row[i].value!.toString().split(splitColumn);
                        while (splitted.length < amount)
                            splitted.push("");
                        for (let k = 0; k < amount; k++) {
                            const temp = {...row[i]};
                            temp.value = splitted[k];
                            row.splice(i+k,k===0?1:0,temp);
                        }
                    }
                    columns += amount-1;
                    i += amount-1;
                }
            }
            setHeaders(splittedHeaders);
            setData(res);
            setIdColumn(0);
            setDataColumn(1);
        });
        csv.write(txt);
        csv.end();
    }, [splitColumn]); //eslint-disable-line react-hooks/exhaustive-deps

    //splitcolumn 
    useEffect(() => {
        if (file === undefined)
            return;
        const splitted = file.name.split(".");
        if (splitted[splitted.length-1].toLowerCase() === "csv")
            readCsv(file, false);
    }, [splitColumn]); //eslint-disable-line react-hooks/exhaustive-deps

    //new column
    useEffect(() => {
        if ((idColumn !== null && dataColumn !== null) && data.length !== 0) {
            if (autoselect) {
                const filter = new Array<boolean>(data.length);
                for (let i = 0; i < filter.length; i++)
                    filter[i] = isId(data[i][idColumn].value!.toString()) && !isEmpty(data[i][dataColumn].value!.toString());
                setRowFilter(filter);
            }
        }
    }, [idColumn, dataColumn, data, autoselect]);
    useEffect(() => {
        if ((idColumn !== null && dataColumn !== null) && data.length !== 0) {
            if (!autoselect) {
                const filter = new Array<boolean>(data.length);
                for (let i = 0; i < filter.length; i++)
                    filter[i] = true;
                setRowFilter(filter);
            }
        }
    }, [data]); //eslint-disable-line react-hooks/exhaustive-deps

    const operationTypes = useMemo(() => {
        let types = new Array<string>(0);
        for (const op of allOperations)
            if (!types.includes(op.operationType))
                types.push(op.operationType);
        types = types.sort((a,b) => a.length - b.length);
        return types.map((t) => {
            return (
                <MenuItem key={t} value={t}>
                    {t}
                </MenuItem>
            );
        });
    }, [allOperations]);

    const operations = useMemo(() => {
        const ops = allOperations.filter(op => operationType === op.operationType);
        return ops.map((o) => {
            let t = "";
            if (o.deviceTypeId === 1)
                t = " (robot)";
            else if (o.deviceTypeId === 2)
                t = " (tablet)";
            else
                t = " (deviceTypeId: "+o.deviceTypeId+")";
            return (
                <MenuItem key={o.dataKey+" "+o.deviceTypeId} value={o.dataKey+" "+o.deviceTypeId}>
                    {o.translatedKey+t}
                </MenuItem>
            );
        });
    }, [allOperations, operationType]);

    const sheets = useMemo(() => {
        if (workbook === undefined)
            return [];
        return workbook.worksheets.map((w, i) => {
            return (
                <MenuItem key={i} value={i}>
                    {w.name}
                </MenuItem>
            );
        });
    }, [workbook]);

    const columnDropdownItems = useMemo(() => {
        const res = new Array<JSX.Element>(0);
        for (let i = 0; i < headers.length; i++)
            res.push(
                <MenuItem key={i} value={i}>
                    {headers[i]}
                </MenuItem>
            );
        return res;
    }, [headers]);

    const rows = useMemo<JSX.Element[][]>(() => {
        if (data.length === 0 || data[0].length === 0)
            return ([]);
        const headerRow = new Array<JSX.Element>(0);
        for (let i = 0; i < data[0].length; i++) {
            let style = {};
            if (i === idColumn)
                style = {
                    color: ID_COLUMN_COLOR,
                    borderLeft: "solid 2px "+ID_COLUMN_COLOR,
                    borderRight: "solid 2px "+ID_COLUMN_COLOR,
                };
            else if (i === dataColumn)
                style = {
                    color: DATA_COLUMN_COLOR,
                    borderLeft: "solid 2px "+DATA_COLUMN_COLOR,
                    borderRight: "solid 2px "+DATA_COLUMN_COLOR,
                };
            headerRow.push(<th key={i} style={style}>{headers[i]}</th>);
        }
        const ret = new Array<JSX.Element[]>(0);
        ret.push(headerRow);
        for (const row of data) {
            const cells = new Array<JSX.Element>(0);
            for (let i = 0; i < row.length; i++) {
                let style = {};
            if (i === idColumn)
                style = {
                    borderLeft: "solid 2px "+ID_COLUMN_COLOR,
                    borderRight: "solid 2px "+ID_COLUMN_COLOR,
                };
            else if (i === dataColumn)
                style = {
                    borderLeft: "solid 2px "+DATA_COLUMN_COLOR,
                    borderRight: "solid 2px "+DATA_COLUMN_COLOR,
                };
                const cell = row[i];
                cells.push(
                    <td key={i} style={{...cell.style, ...style}}>
                        {cell.value}
                    </td>
                );
            }
            ret.push(cells);
        }
        return ret;
    }, [headers, data, idColumn, dataColumn]);

    const checkboxes = useMemo<JSX.Element[]>(() => {
        if (data.length === 0 || data[0].length === 0)
            return ([]);
        let hasChecked = false;
        let hasUnchecked = false;
        for (const checkbox of rowFilter)
            if (checkbox && !hasChecked)
                hasChecked = true;
            else if (!checkbox && !hasUnchecked)
                hasUnchecked = true;
        const boxes = new Array<JSX.Element>(0);
        boxes.push(
            <td>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                    <TriStateCheckbox
                        checked={hasChecked && !hasUnchecked}
                        indeterminate={hasChecked && hasUnchecked}
                        onChange={(ev) => {
                            const filter = new Array<boolean>(data.length);
                            for (let i = 0; i < filter.length; i++)
                                filter[i] = ev.target.checked;
                            setRowFilter(filter);
                        }}
                    />
                </div>
            </td>
        );
        for (let i = 0; i < data.length; i++) {
            const j = i;
            boxes.push(
                <td>
                    <Checkbox
                        checked={rowFilter[j]}
                        onClick={() => {
                            const filter = [...rowFilter];
                            filter[j] = !rowFilter[j];
                            setRowFilter(filter);
                        }}
                    />
                </td>
            );
        }
        return boxes;
    }, [rowFilter, setRowFilter]); //eslint-disable-line react-hooks/exhaustive-deps
    
    const table = useMemo<JSX.Element>(() => {
        if (rows.length === 0)
            return (<></>);
        const r = new Array<JSX.Element>(0);
        for (let i = 0; i < rows.length; i++)
            r.push(<tr  key={i}>{checkboxes[i]}{rows[i]}</tr>)
        return (<table className={classes.table}><tbody>{r}</tbody></table>);
    }, [rows, checkboxes, classes.table]);

    const execute = async () => {
        const op : BulkOperation = {
            values: data.filter((e, i) => (rowFilter[i] && isId(e[idColumn!].value!)) && !isEmpty(e[dataColumn!].value!)).map(e => {return {id: Number(e[idColumn!].value!), value: e[dataColumn!].value!}}),
            operation: operation!,
        };
        const preparedOp : BulkOperation = {
            values: [],
            operation: operation!,
        };
        const confilcts = await AdministrationService.getConflicts(op);
        let sum = 0;
        let hasValid = false;
        let hasMatching = false;
        let hasNotId = false;
        let hasEmpty = false;
        let hasInvalidId = false;
        let hasConflicting = false;
        const confirmRowsValid = new Array<JSX.Element>(0);
        const confirmRowsInvalid = new Array<JSX.Element>(0);
        const confirmRowsConflict = new Array<JSX.Element>(0);
        data.filter((_e, i) => rowFilter[i]).forEach((e, i) => {
            const id = e[idColumn!].value!;
            const val = e[dataColumn!].value!;
            let color = VALID_COLOR;
            if (!isId(id)) {
                hasNotId = true;
                color = ID_NOT_NUMBER_COLOR;
            } else if (isEmpty(val)) {
                hasEmpty = true;
                color = EMPTY_DATA_COLOR;
            } else if (confilcts.invalidIds.includes(Number(id))) {
                hasInvalidId = true;
                color = INVALID_ID_COLOR;
            } else if (confilcts.conflictingIds.includes(Number(id))) {
                hasConflicting = true;
                color = CONFLICTING_COLOR;
            } else if (confilcts.matchingDataIds.includes(Number(id))) {
                hasMatching = true;
                color = MATCHING_DATA_COLOR;
            } else {
                preparedOp.values.push({id: Number(id), value: val});
                sum++;
                hasValid = true;
            }
            const element = (<tr key={i.toString()}><td style={{backgroundColor: color}}>{id}</td><td style={{backgroundColor: color}}>{val}</td></tr>);
            switch (color) {
                case ID_NOT_NUMBER_COLOR:
                case EMPTY_DATA_COLOR:
                case INVALID_ID_COLOR:
                    confirmRowsInvalid.push(element);
                    break;
                case CONFLICTING_COLOR:
                    confirmRowsConflict.push(element);
                    break;
                case VALID_COLOR:
                case MATCHING_DATA_COLOR:
                    confirmRowsValid.push(element);
                    break;
                default:
                    confirmRowsInvalid.push(element);
                    break;
            }
        });
        const legendValid = new Array<JSX.Element>(0);
        const legendInvalid = new Array<JSX.Element>(0);
        const legendConflict = new Array<JSX.Element>(0);
        if (hasValid)
            legendValid.push(<div key="valid"><div style={{backgroundColor: VALID_COLOR}}/><div>Valid</div></div>);
        if (hasMatching)
            legendValid.push(<div key="matching"><div style={{backgroundColor: MATCHING_DATA_COLOR}}/><div>Matching data value (will not be updated)</div></div>);
        if (hasNotId)
            legendInvalid.push(<div key="nan"><div style={{backgroundColor: ID_NOT_NUMBER_COLOR}}/><div>ID not a number</div></div>);
        if (hasEmpty)
            legendInvalid.push(<div key="empty"><div style={{backgroundColor: EMPTY_DATA_COLOR}}/><div>Empty data value</div></div>);
        if (hasInvalidId)
            legendInvalid.push(<div key="invalidid"><div style={{backgroundColor: INVALID_ID_COLOR}}/><div>Invalid ID</div></div>);
        if (hasConflicting) {
            let msg = "";
            if (operation?.operationType === "Insert")
                msg = " (entry can not be inserted as it already has a value)";
            else if (operation?.operationType === "Update")
                msg = " (entry can not be updated as it does not have a value yet)";
            legendConflict.push(<div key="conflictingentry"><div style={{backgroundColor: CONFLICTING_COLOR}}/><div>Conflicting entry{msg}</div></div>);
        }
        const dialogText = (<div>
            <div style={{marginBottom: "10px"}}>The following modifications will be made:</div>
            <div style={{marginBottom: "5px"}}>Device Type: {deviceTypeIdToString(operation?.deviceTypeId)}</div>
            {legendValid.length > 0 && <>
                <div style={{marginBottom: "5px"}} className={classes.legend}>{legendValid}</div>
                <div className={classes.dialogTable}><table><tbody>
                    <tr><th>ID</th><th>{operation?.translatedKey}</th></tr>
                    {confirmRowsValid}
                </tbody></table></div>
            </>}
            {legendInvalid.length > 0 && <>
                <div style={{marginBottom: "5px", marginTop: "5px"}} className={classes.legend}>{legendInvalid}</div>
                <div className={classes.dialogTable}><table><tbody>
                    <tr><th>ID</th><th>{operation?.translatedKey}</th></tr>
                    {confirmRowsInvalid}
                </tbody></table></div>
            </>}
            {legendConflict.length > 0 && <>
                <div style={{marginBottom: "5px", marginTop: "5px"}} className={classes.legend}>{legendConflict}</div>
                <div className={classes.dialogTable}><table><tbody>
                    <tr><th>ID</th><th>{operation?.translatedKey}</th></tr>
                    {confirmRowsConflict}
                </tbody></table></div>
            </>}
            <div style={{marginTop: "10px"}}>Do you wish to continue?</div>
            <div>({sum} records will be {operationType?.replace("Update","Updated").replace("Insert","Inserted")})</div>
        </div>);
        if (!await displayDialog({dialogText})) {
            displayToast({
                message: "canceled",
                severity: "warning",
                withCloseIcon: true,
            });
            return;
        }
        AdministrationService.executeOperation(preparedOp).then(() => {
            displayToast({
                message: sum + " record(s) have been " + operationType?.replace("Update","Updated").replace("Insert","Inserted"),
                severity: "success",
                withCloseIcon: true,
            });
        }).catch((val) => {
            displayToast({
                message: val.message,
                severity: "error",
                withCloseIcon: true,
            });
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.left}>
                {file !== undefined &&
                    <div
                        className={classes.file}
                        onClick={() => {
                            setFile(undefined);
                            setWorkbook(undefined);
                            setData([]);
                        }}
                    >
                        {file.name}
                    </div>
                }
                <Dropzone
                    onDropAccepted={(acceptedFiles: File[]) => {
                        setFile(acceptedFiles[0]);
                        const splitted = acceptedFiles[0].name.split(".");
                        if (splitted[splitted.length-1].toLowerCase() === "xlsx")
                            readXlsx(acceptedFiles[0]);
                        else if (splitted[splitted.length-1].toLowerCase() === "csv")
                            readCsv(acceptedFiles[0]);
                    }}
                    maxFiles={1}
                    onDropRejected={(val: FileRejection[]) => {
                        displayToast({
                            message: val[0].errors[0].message,
                            severity: "error",
                            withCloseIcon: true,
                        });
                    }}
                    validator={(f: File) => {
                        const splitted = f.name.split(".");
                        if (!FILE_TYPES.includes(splitted[splitted.length-1].toLowerCase()))
                            return { message: "Invalid type", code: "invalid-type" } as FileError;
                        return null;
                    }}
                >
                    {({getRootProps, getInputProps}) => (
                        <section>
                        <div {...getRootProps()} className={classes.dropzone}>
                            <input {...getInputProps()} />
                            <p>drag 'n' drop .xlsx .csv  file here, or click to select file</p>
                        </div>
                        </section>
                    )}
                </Dropzone>
                <TextField
                    label="Operation Type"
                    margin="dense"
                    size="small"
                    select
                    value={operationType===null ? "" : operationType}
                    onChange={(ev: any) => {
                        setOperationType(ev.target.value);
                        setOperation(allOperations.find(op => (op.operationType === ev.target.value && op.deviceTypeId === operation?.deviceTypeId && op.dataKey === operation?.dataKey)) ?? null);
                    }}
                    variant="outlined"
                    disabled={data.length===0}
                >
                    {operationTypes}
                </TextField>
                <TextField
                    label="Operation"
                    margin="dense"
                    size="small"
                    select
                    value={operation===null ? "" : operation?.dataKey+" "+operation?.deviceTypeId}
                    onChange={(ev: any) => {
                        const splitted = ev.target.value.split(" ");
                        setOperation(allOperations.find(op => (op.operationType === operationType && op.deviceTypeId === Number(splitted[1]) && op.dataKey === splitted[0])) ?? null);
                    }}
                    variant="outlined"
                    disabled={operationType===null||data.length===0}
                >
                    {operations}
                </TextField>
                <Button
                    className={classes.execute}
                    disabled={operation===null||data.length===0}
                    onClick={execute}
                >
                    Execute
                </Button>
            </div>
            <div className={classes.right}>
                    <div>
                        <div>
                            {workbook !== undefined &&
                                <TextField
                                    style={{minWidth: "100px"}}
                                    label="Sheet"
                                    margin="dense"
                                    size="small"
                                    select
                                    value={sheet===null ? "" : sheet}
                                    onChange={(ev: any) => {
                                        setSheet(ev.target.value);
                                    }}
                                    variant="outlined"
                                    disabled={data.length===0}
                                >
                                    {sheets}
                                </TextField>
                            }
                            <TextField
                                style={{minWidth: "135px"}}
                                label="ID Column"
                                margin="dense"
                                size="small"
                                select
                                value={idColumn===null ? "" : idColumn}
                                onChange={(ev: any) => {
                                    setIdColumn(ev.target.value);
                                }}
                                variant="outlined"
                                disabled={data.length===0}
                                InputLabelProps={data.length===0?{}:{style: {color: ID_COLUMN_COLOR}}}
                                InputProps={data.length===0?{}:{style: {color: ID_COLUMN_COLOR}}}
                            >
                                {columnDropdownItems}
                            </TextField>
                            <TextField
                                style={{minWidth: "135px"}}
                                label="Data Column"
                                margin="dense"
                                size="small"
                                select
                                value={dataColumn===null ? "" : dataColumn}
                                onChange={(ev: any) => {
                                    setDataColumn(ev.target.value);
                                }}
                                variant="outlined"
                                disabled={data.length===0}
                                InputLabelProps={data.length===0?{}:{style: {color: DATA_COLUMN_COLOR}}}
                                InputProps={data.length===0?{}:{style: {color: DATA_COLUMN_COLOR}}}
                            >
                                {columnDropdownItems}
                            </TextField>
                        </div>
                        <div>
                            <FormControlLabel
                                style={{margin:"0px"}}
                                control={
                                    <Switch
                                        checked={autoselect}
                                        size="small"
                                        onChange={(e) => {setAutoselect(e.target.checked);}}
                                    />
                                }
                                label="Autoselect Valid Data"
                                disabled={data.length===0}
                            />
                            <TextField
                                label="Split columns at"
                                margin="dense"
                                size="small"
                                value={splitColumn}
                                onChange={(ev: any) => {
                                    setSplitColumn(ev.target.value);
                                }}
                                variant="outlined"
                                disabled={data.length===0}
                            />
                        </div>
                    </div>
                    {data.length !== 0 &&
                        <div>
                            {table}
                        </div>
                    }
            </div>
        </div>
    );
}

function isId(data : string) : boolean {
    if (typeof data === "number")
        data = (data as number).toString();
    if (data === undefined)
        return false;
    return /^\d+$/.test(data.trim());
}

function isEmpty(data : string) : boolean {
    if (data === undefined)
        return true;
    return data.trim() === "";
}