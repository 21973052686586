/* eslint-disable @typescript-eslint/ban-types */
import {
    Chip,
    createStyles,
    makeStyles,
    useMediaQuery,
    Theme,
} from "@material-ui/core";
import React, { useCallback } from "react";
import {
    ColumnInstance,
    FilterValue,
    IdType,
    TableInstance,
    Filters,
} from "react-table";
import { withInstance } from "../BasicTable/tableUtilities";

const useStyles = makeStyles((theme) =>
    createStyles({
        filtersActiveLabel: {
            ...theme.typography.caption,
            color: theme.palette.common.black,
            fontWeight: 600,
            fontSize: "12px",
            paddingRight: 10,
            alignSelf: "center",
        },
        chipZone: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            paddingLeft: theme.spacing(1.5),
            paddingBottom: theme.spacing(1),
        },
        chipLabel: {
            fontWeight: 500,
            marginRight: 5,
        },
        filterChip: {
            marginRight: 4,
            color: "#222",
            marginTop: 3,
            marginBottom: 3,
        },
    })
);

const getFilterValue = (
    column: ColumnInstance<any>,
    filterValue: FilterValue
) => {
    if (column.filterShouldRenderCell === true) {
        return <>{column.render("Cell", { cell: { value: filterValue} })}</>;
    }

    switch (column.filter) {
        case "between":
            // eslint-disable-next-line no-case-declarations
            const min = filterValue[0];
            // eslint-disable-next-line no-case-declarations
            const max = filterValue[1];
            if (min) {
                if (max)
                    return `${min}-${max}`;
                return `>=${min}`;
            }
            return `<=${max}`;
        case "matches": {
            return "";
        }
        case "includes": {
            return "";
        }
    }
    return <>{filterValue.filter((fv:string)=>fv!=='').join(" or ")}</>;
};

export interface ChipbarProps<T extends {}> {
    instance: TableInstance<T>;
    filters: Filters<T>;
    globalFilter: any;
}

const Chipbar = React.forwardRef(function Chipbar<T extends {}>(
    { instance, filters, globalFilter }: ChipbarProps<T>,
    ref: React.Ref<HTMLDivElement> | React.RefObject<HTMLDivElement>
) {
    const classes = useStyles({} as any);
    const {
        allColumns,
        setFilter,
        setGlobalFilter,
        disableGlobalFilter,
    } = instance;

    const handleDelete = useCallback(
        (id: string | number) => {
            setFilter(id as IdType<T>, undefined);
        },
        [setFilter]
    );

    const showGlobalFilter =
        !useMediaQuery((theme: Theme) => theme.breakpoints.up("sm")) &&
        !disableGlobalFilter;

    const filterChips = [
        ...allColumns.flatMap((column) => {
            const filter = filters.find((f) => f.id === column.id);
            if (!filter) {
                return [];
            }
            const value = filter.value;
            return [
                <Chip
                    className={classes.filterChip}
                    key={column.id}
                    label={
                        (column.FilterChip &&
                            column.render("FilterChip", {
                                filterValue: value,
                            })) || (
                            <>
                                <span className={classes.chipLabel}>
                                    {column.filterLabel ??
                                        column.render("Header")}
                                    :
                                </span>
                                {getFilterValue(column, value)}
                            </>
                        )
                    }
                    onDelete={() => handleDelete(column.id)}
                    variant="outlined"
                />,
            ];
        }),
        ...((globalFilter &&
            showGlobalFilter && [
                <Chip
                    className={classes.filterChip}
                    key="global-search"
                    label={
                        <>
                            <span className={classes.chipLabel}>Search:</span>
                            {((globalFilter?.keyword !== undefined && globalFilter?.strict !== undefined) ? globalFilter.keyword+", strict: "+ globalFilter.strict : globalFilter)}
                        </>
                    }
                    variant="outlined"
                    onDelete={() => setGlobalFilter((globalFilter?.keyword !== undefined && globalFilter?.strict !== undefined) ? {keyword: "", strict: false}:null)}
                ></Chip>,
            ]) ||
            []),
    ];
    return (
        <div ref={ref}>
            {(filterChips.length > 0 && (
                <div className={classes.chipZone}>
                    <span className={classes.filtersActiveLabel}>
                        Active filters:
                    </span>
                    {filterChips}
                </div>
            )) ||
                null}
        </div>
    );
});

export default withInstance(
    (instance) => ({
        instance,
        filters: instance.state.filters,
        globalFilter: instance.state.globalFilter,
    }),
    Chipbar
);
