/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { GridChildComponentProps } from "react-window";
import { TableInstance, ColumnInstance } from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import SortIcon from "src/components/SortIcon";
import ColumnResizer from "./ColumnResizer";
import useColumnReorder from "../BasicTable/useColumnReorder";

export interface GridHeaderData<T extends {}> {
    headers: ColumnInstance<T>[];
    fixed: boolean;
    instance: TableInstance<T>;
}

export interface GridHeaderCellProps<T> extends GridChildComponentProps {
    data: GridHeaderData<T>;
}

export default function GridHeaderCell<T>({
    style,
    data,
    columnIndex,
}: GridHeaderCellProps<T>) {
    const { headers, fixed, instance } = data;
    const header = headers[columnIndex];

    const {
        outerRef,
        outerTitle,
        showOuterTitle,
        innerRef,
        innerClassName,
    } = useColumnReorder({
        column: header || {},
        visibleColumns: instance.visibleColumns,
        setColumnOrder: instance.setColumnOrder,
    });

    if (columnIndex === headers.length) {
        return (
            <div key="h-last" style={style}>
                &shy;
            </div>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { title: _ = "", ...wrapperProps } = header.canSort
        ? (header.getSortByToggleProps() as any)
        : {};
    
    let sortDirection = undefined as "desc"|"asc"|undefined;
    if (header.isSorted)
        sortDirection = header.isSortedDesc ? "desc" : "asc";

    return (
        <div
            key={header.id}
            style={style}
            className="dg-hcell"
            data-fixed={fixed}
            data-col={columnIndex}
            ref={outerRef}
        >
            <Tooltip
                open={showOuterTitle}
                title={outerTitle}
                aria-label={outerTitle}
            >
                <div
                    ref={innerRef}
                    className={innerClassName}
                    {...wrapperProps}
                >
                    {header.render("Header")}
                    {header.canSort && (
                        <SortIcon
                            title="Sort column"
                            sortDirection={sortDirection}
                            className="dg-sort"
                        />
                    )}
                </div>
            </Tooltip>

            {!fixed && header.canResize && (
                <ColumnResizer
                    isResizing={header.isResizing}
                    getResizerProps={header.getResizerProps}
                />
            )}
        </div>
    );
}
