/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useImperativeHandle, useState } from "react";
import MUITable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import { useFilters, useFlexLayout, usePagination, useSortBy, useTable, useResizeColumns, useGlobalFilter, useColumnOrder, TableOptions, TableState, PluginHook, TableInstance, HeaderGroup } from "react-table";
import { useSticky } from "react-table-sticky";
import Pagination, { PaginationProps } from "./Pagination";
import ColumnResizer from "./ColumnResizer";
import ColumnSorter from "./ColumnSorter";
import { HighlightedCell, getHighlightProps, ColumnReorderCell, ColumnReorderCellPreview, getColumnReorderProps } from "./Cells";
import useStyles from "./useTableStyles";
import { withInstance, defaultStorageMapper, defaultRowsPerPageOptions, applyUserProps, GetRowProps, GetBodyProps } from "./tableUtilities";
import { isMobile } from "react-device-detect";
import resolve from "src/utils/resolve";
import { useLocalStorage, useDebounced, useDidMountCheck } from "src/hooks";
import classNames from "src/utils/classNames";

const OutlinedPaper = React.forwardRef(
    (props: Omit<PaperProps, "variant">, ref: any) => (
        <Paper ref={ref} {...props} variant="outlined" />
    )
);

export const hooks = [
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder,
    useSticky,
];

export type TableRendererProps<T extends {}, Other = {}> = {
    instance: TableInstance<T>;
    classes: ReturnType<typeof useStyles>;
} & Other;

export type TableRenderer<T extends {}, Other = {}> = (
    props: TableRendererProps<T, Other>
) => JSX.Element;

export type BodyRenderer<T, Other = {}> = TableRenderer<
    T,
    { rowProps?: GetRowProps<T> } & Other
>;
export type HeaderRenderer<T, Other = {}> = TableRenderer<
    T,
    { headerRowProps?: GetRowProps<T> } & Other
>;
export type FooterRenderer<T, Other = {}> = TableRenderer<
    T,
    { footerRowProps?: GetRowProps<T> } & Other
>;

export interface BasicTableProps<T extends {}>
    extends Omit<TableOptions<T>, "initialState">,
        Partial<Pick<PaginationProps<any>, "rowsPerPageOptions">> {
    
    innerRef?: any,
    h?: number | string,
    autoRefresh?: boolean,
    
    // Layout
    size?: "small" | "medium";
    className?: string;
    Body?: BodyRenderer<T>;
    Header?: HeaderRenderer<T>;
    Footer?: FooterRenderer<T>;

    Toolbar?: TableRenderer<T>;
    TablePagination?: TableRenderer<T> | null;

    classes?: Partial<ReturnType<typeof useStyles>>;

    // Prop getters
    rowProps?: GetRowProps<T>;
    headerRowProps?: GetRowProps<T>;
    footerRowProps?: GetRowProps<T>;
    bodyProps?: GetBodyProps<T>;

    // State and hooks
    name?: string;
    initialState?: (() => Partial<TableState<T>>) | Partial<TableState<T>>;
    mapHooks?: (base: PluginHook<T>[]) => PluginHook<T>[];
    hooks?: PluginHook<T>[];

    // Misc
    highlightRowOnUpdate?: boolean;
    enableColumnReorder?: boolean;
    mapStorage?: (state: TableState<T>) => Partial<TableState<T>>;
}

export default function BasicTable<T extends {}>(props: BasicTableProps<T>) {
    const {
        innerRef,
        h,
        autoRefresh = false,
        // Layout
        size,
        className,
        Body,
        Header = DefaultHeader,
        Footer,
        Toolbar,
        TablePagination = Pagination,
        classes: classesOverride,
        rowsPerPageOptions = defaultRowsPerPageOptions,
        // Prop getters
        rowProps,
        headerRowProps,
        footerRowProps,
        bodyProps,
        // State
        name,
        initialState,
        defaultColumn: defaultColumn_,
        mapHooks,
        hooks: passedHooks,
        // Misc
        highlightRowOnUpdate,
        enableColumnReorder,
        mapStorage = defaultStorageMapper,
        ...rest
    } = props;
    
    const classes = useStyles({ classes: classesOverride });

    const initialPageSize = rowsPerPageOptions[0] || 10;
    const getInitialState = () => ({
        pageSize:
            typeof initialPageSize === "number"
                ? initialPageSize
                : initialPageSize.value,
        ...(resolve(initialState) || {}),
    });
    const [storedState, setStoredState] = useLocalStorage<any>(
        name && `tmr/base_table_state:${name}`,
        getInitialState
    );

    const [defaultColumn] = useState({
        minWidth: 50,
        maxWidth: 400,
        ...(defaultColumn_ ? defaultColumn_ : {}),
    });

    const instance = useTable(
        {
            ...rest,
            defaultColumn,
            highlightRowOnUpdate,
            enableColumnReorder,
            mapStorage,
            initialState: storedState || getInitialState(),
        },
        ...((mapHooks && mapHooks(hooks)) || hooks),
        ...(passedHooks || [])
    );

    useImperativeHandle(innerRef, () => ({
        reloadData() {
            instance.reloadData();
        }
    }));

    const { getTableProps, getTableBodyProps, state, containerRef } = instance;

    const debouncedState = useDebounced(mapStorage(state), 500);

    useEffect(() => {
        setStoredState(debouncedState);
    }, [setStoredState, debouncedState]);

    useDidMountCheck(`BaseTable${(name && ("-"+name)) || ""}`);

    const renderProps = {
        instance,
        classes,
    };
  


    useEffect(() => {
        const id = setInterval(() => { //eslint-disable-line react-hooks/exhaustive-deps
            if (autoRefresh)
                instance.reloadData();
        }, 10000);
        return () => clearInterval(id);
    }, [autoRefresh]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {Toolbar && <Toolbar {...renderProps} />}
            <TableContainer
                component={OutlinedPaper}
                className={classNames(className, classes.scrollContainer)}
                style={{paddingRight:3}}
                ref={containerRef}
            >
                <MUITable
                    {...getTableProps({ className: classes.table })}
                    component="div"
                    size={size}
                >
                    <TableHead className={classes.header} component="div">
                        <Header
                            {...renderProps}
                            headerRowProps={headerRowProps}
                        />
                    </TableHead>
                    <TableBody
                        {...getTableBodyProps(
                            applyUserProps(bodyProps, {
                                className: classes.body,
                            })
                        )}
                        component="div"
                    >
                        {(Body)
                            ? <Body {...renderProps} rowProps={rowProps} />
                            : <DefaultBody {...renderProps} rowProps={rowProps} height={h} name={name} />
                        }
                    </TableBody>
                    {Footer && (
                        <TableFooter className={classes.footer} component="div">
                            <Footer
                                {...renderProps}
                                footerRowProps={footerRowProps}
                            />
                        </TableFooter>
                    )}
                </MUITable>
            </TableContainer>
            {TablePagination && (
                <TablePagination
                    {...renderProps}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            )}
            {enableColumnReorder && isMobile && <ColumnReorderCellPreview />}
        </>
    );
}

const DefaultHeader = withInstance(
    (instance) => ({
        instance,
        headerGroups: instance.headerGroups,
        sort: instance.state.sortBy,
        resize: instance.state.columnResizing,
    }),
    function DefaultHeader<T extends {}>({
        instance,
        classes,
        headerGroups,
        headerRowProps,
    }: TableRendererProps<T> & {
        headerGroups: HeaderGroup<T>[];
        headerRowProps?: GetRowProps<T>;
    }) {
        const Cell = instance.enableColumnReorder
            ? (ColumnReorderCell as typeof TableCell)
            : TableCell;
        return (
            <>
                {headerGroups.map((headerGroup) => {
                    return (
                        <TableRow
                            {...headerGroup.getHeaderGroupProps(
                                applyUserProps(headerRowProps, {
                                    className: classes.row,
                                })
                            )}
                            component="div"
                        >
                            {headerGroup.headers.map((column) => {
                                return (
                                    <Cell
                                        component="div"
                                        {...column.getHeaderProps(
                                            applyUserProps(
                                                undefined,
                                                getColumnReorderProps,
                                                { className: classes.cell }
                                            )
                                        )}
                                    >
                                        <div>
                                            {column.render("Header")}
                                            {column.canSort && (
                                                <ColumnSorter
                                                    getSortByToggleProps={
                                                        column.getSortByToggleProps
                                                    }
                                                    isSorted={column.isSorted}
                                                    isSortedDesc={
                                                        column.isSortedDesc
                                                    }
                                                />
                                            )}
                                        </div>
                                        {column.canResize && (
                                            <ColumnResizer
                                                getResizerProps={
                                                    column.getResizerProps
                                                }
                                                isResizing={column.isResizing}
                                                className={classes.resizer}
                                            />
                                        )}
                                    </Cell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </>
        );
    }
) as HeaderRenderer<any>;

function DefaultBody<T extends {}>({
    classes,
    instance,
    rowProps,
    height,
    name,
}: TableRendererProps<T, { rowProps?: GetRowProps<T>, height?: number|string, name?: string }>) {
    const { page, prepareRow, highlightRowOnUpdate = false } = instance;
    const Cell = highlightRowOnUpdate
        ? (HighlightedCell as typeof TableCell)
        : TableCell;
    
    return (
        <div style={{maxHeight:height,overflowY:"auto"}} id={`${name}-table-body`}>
            {page.map((row) => {
                prepareRow(row);
                return (
                    <TableRow
                        component="div"
                        {...row.getRowProps(
                            applyUserProps(rowProps, { className: classes.row })
                        )}
                    >
                        {row.cells.map((cell) => {
                            return (
                                <Cell
                                    {...cell.getCellProps(
                                        applyUserProps(
                                            undefined,
                                            getHighlightProps,
                                            {
                                                className: classes.cell,
                                            }
                                        )
                                    )}
                                    component="div"
                                >
                                    <div>{cell.render("Cell")}</div>
                                </Cell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </div>
    );
}
