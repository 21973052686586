import React, { useMemo, useContext, useCallback, useState } from "react";
import { makeTranslator } from "src/utils/DataTranslator";
import { DeviceData } from "src/services/DeviceDataService";
import type { CustomersDto } from "src/services/UserService";
import { AccessGroup } from "src/services/ConfigurationsService";

/* Property names are device keys */
export interface TranslationsDictionary {
    [deviceKey: string]: {
        description?: string;
        group: number | null;
        order: number | null;
        alias: string | null;
        translations: {
            [targetValue: string]: {
                translatedValue: string | null;
                description: string | null;
            };
        };
    };
}

interface TranslationContextI {
    enabled: boolean;
    translate(
        key: string,
        value: string,
        values?: Record<string, string> | Record<string, DeviceData>
    ): string;
    translateKey(key: string): string;
    toggleTranslate(value: boolean): void;
}

const TranslationContext = React.createContext<TranslationContextI>(undefined!);

export interface TranslationProviderProps {
    deviceTypeId: number;
    customers: CustomersDto;
    translationsDictionary: TranslationsDictionary;
    accessGroups?: AccessGroup[];
    children: React.ReactChild;
}

export default function TranslationProvider(props: TranslationProviderProps) {
    const { deviceTypeId, customers, translationsDictionary, accessGroups, children } = props;

    const translator = useMemo(() => {
        return makeTranslator(
            deviceTypeId,
            customers,
            translationsDictionary,
            accessGroups
        );
    }, [deviceTypeId, customers, translationsDictionary, accessGroups]);
    const [enabled, setEnabled] = useState(translator.shouldTranslate);

    const translate = useCallback(
        (
            key: string,
            value: string,
            values?: Record<string, string> | Record<string, DeviceData>
        ) => {
            return translator.translate(key, value, values);
        },
        [translator]
    );
    const translateKey = useCallback(
        (key: string) => translator.translateKey(key),
        [translator]
    );
    const toggleTranslate = useCallback(
        (value: boolean) => {
            translator.shouldTranslate = !value;
            setEnabled(translator.shouldTranslate);
        },
        [translator]
    );

    const store = useMemo(
        () => ({
            enabled,
            translate,
            translateKey,
            toggleTranslate,
        }),
        [enabled, translate, translateKey, toggleTranslate]
    );

    return (
        <TranslationContext.Provider value={store}>
            {children}
        </TranslationContext.Provider>
    );
}

export function useTranslator() {
    return useContext(TranslationContext);
}
