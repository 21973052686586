import axios from "axios";
import { handleError } from "./Errors";

export default class DeveloperService {
    static async getContent(key: string, isFile = false): Promise<string> {
        try {
            if (isFile) {
                const response = await axios.get("/api/developer/content/"+key, {responseType:"blob",timeout:600000});
                const tmp = window.URL.createObjectURL(new Blob([response.data as any],{type:response.headers["content-type"]}));
                return tmp;
            } else {
                const response = await axios.get("/api/developer/content/"+key);
                return (response.data as any).html;
            }
        } catch (err) {
            return handleError(err, false);
        }
    }
}