import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "../../components/PrivateRoute";
import NotFound from "../NotFound";

import Overview from "./Overview";
import Dashboard from "./Dashboard";

export default function Devices() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <PrivateRoute exact path={`${path}/:device(tablets|robots|referencestations|\\d+)`}>
                <Overview />
            </PrivateRoute>
            <PrivateRoute
                exact
                path={`${path}/:device(tablets|robots|referencestations|\\d+)/:deviceId([+-]?\\d+)`}
            >
                <Dashboard />
            </PrivateRoute>
            <PrivateRoute>
                <NotFound />
            </PrivateRoute>
        </Switch>
    );
}
