import React, { useMemo, useRef, useState } from "react";
import { makeStyles, MenuItem, TextField, Typography, Button, Modal } from "@material-ui/core";
import { useAsync, useResizeObserver, useToast } from "src/hooks";
import { isInteger } from "src/utils/common";
import { isNonEmptyString } from "../../utils/common";
import ProductionService from "src/services/ProductionService";
import { NewDevicesTable } from "./Tables";
import useDialog from "src/hooks/useDialog";
import { ProductTable } from "./ProductModelsTableModal";
import Commands from "./Commands";


const useStyles = makeStyles((theme) => ({
    controlHeader: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
        '& *': {fontFamily: theme.typography.fontFamily},
        '&>*': {marginBottom: "10px"},
    },
    newIds: {
        '&>*:nth-child(1)': {
            fontStyle: "italic",
            fontWeight: "bold",
        },
        display: "flex",
        flexDirection: "column",
        '&>*:nth-child(2)': {
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            '&>*': {marginRight: "10px"},
        },
    },
    redeployRobot: {
        '&>*:nth-child(1)': {
            fontStyle: "italic",
            fontWeight: "bold",
        },
        display: "flex",
        flexDirection: "column",
        '&>*:nth-child(2)': {
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            '&>*': {marginRight: "10px"},
        },
    },
    commands: {
        '&>*:nth-child(1)': {
            fontStyle: "italic",
            fontWeight: "bold",
        },
        display: "flex",
        flexDirection: "column",
        '&>*:nth-child(2)': {
            marginBottom: "10px",
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            '&>*': {marginRight: "10px"},
        },
    },
    button: {
        marginTop: "2px",
        height: "40px",
        
        '&:hover': {
            borderColor: theme.palette.primary.light,
        },
    },
}));



export default function Production() {
    
    const classes = useStyles();
    const { displayToast } = useToast();

    const [redeployIdsRaw, setRedeployIdsRaw] = useState("");

    const [idsRaw, setIdsRaw] = useState("");
    const [orderStart, setOrderStart] = useState("");
    const [productModel, setProductModel] = useState("");
    const [sending, setSending] = useState(false);

    const displayErrorToast = (val:any) => displayToast({message: val.message, severity: "error", withCloseIcon: true});
    
    const controlHeader = useRef<HTMLDivElement>(null);
    const table = useRef();
    
    useResizeObserver({
        ref: controlHeader,
        callback: () => {
            const h = controlHeader.current?.clientHeight;
            const body = document.getElementById("production-new-devices-table-body");
            if (h !== undefined && body !== undefined && body !== null)
                body.setAttribute("style",`overflow-y:auto;max-height:calc(100vh - ${(h+250)}px);min-height:300px`);
        },
    });

    let error = "";
    let redeployError = "";

    const stringToIdArray = (idRawString: string)  => {
        const ids = [] as number[];
        const temp = idRawString.split(",");
        let errorMessage = "";
        for (const part of temp) {
            const p = part.split("-");
            try {
                if (isNonEmptyString(idRawString.trim()) && p.some(n => !isInteger(n.trim(), true)))
                    throw new Error("bad string");
                if (p.length === 1) {
                    const num = Number(p[0].trim());
                    if (!ids.includes(num))
                        ids.push(num);
                } else if (p.length === 2) {
                    const start = Number(p[0].trim());
                    const end = Number(p[1].trim());
                    if (start >= end)
                        throw new Error("bad string");
                    for (let i = start; i <= end; i++)
                        if (!ids.includes(i))
                            ids.push(i);
                } else
                    throw new Error("bad string");
            } catch {
                errorMessage += "List not recognized! ";
                break;
            }
        }
    return {ids, errorMessage}
    }

    const stringToIdsArrayResult = stringToIdArray(idsRaw);
    const idsSplitted = stringToIdsArrayResult.ids
    error += stringToIdsArrayResult.errorMessage

    const redeployStringToIdsArrayResult = stringToIdArray(redeployIdsRaw);
    const redeployIdsSplitted = redeployStringToIdsArrayResult.ids
    redeployError += redeployStringToIdsArrayResult.errorMessage

    const { value: robotIds, exec: fetchRobotIds } = useAsync(ProductionService.getRobotIds, {clearValueOnExec: false});
    const { value: newRobotIds, exec: fetchNewRobotIds } = useAsync(ProductionService.getNewRobotIds, {clearValueOnExec: false});

    const idsToRanges = (ids:number[] | undefined)  => {
        const ranges = new Array<{s:number,e:number}>(0);
        if (!ids || ids.length === 0)
            return ranges;
        let currentStart = ids[0];
        if(ids.length === 1)
        {
            ranges.push({s:currentStart,e:currentStart});
            return ranges;
        }
        for (let i = 1; i < ids.length; i++) {
            if (ids[i-1]+1 !== ids[i]) {
                ranges.push({s:currentStart,e:ids[i-1]});
                currentStart = ids[i];
            }
            if (i === ids.length-1) {
                ranges.push({s:currentStart,e:ids[i]});
            }
        }
        return ranges;
    }

    const robotIdRanges = useMemo(() => {
        return idsToRanges(robotIds)
    }, [robotIds]);
    const newRobotIdRanges = useMemo(() => {
        return idsToRanges(newRobotIds)
    }, [newRobotIds]);

    


    const rangesDisplay = (ranges:Array<{s:number,e:number}>) => {
        const ret = [] as any[];
        for (let i = 0; i < ranges.length; i++)
        {
            if (ret.length > 0)
                ret.push(<div key={ret.length}>,&nbsp;</div>);
            ret.push(<div key={ret.length} style={{color:"orange",fontWeight:"bold"}}>{idRangeToString(ranges[i])}</div>)
        }
        return ret;
    }


    
    const DISPLAY_RANGE_BEFORE = 3;
    const DISPLAY_RANGE_AFTER = 8;

    const idRangeToString = (range:{s:number,e:number}) => {
        if (range.s === range.e)
            return `${range.s}`;
        return `${range.s}-${range.e}`;
    }

    const getRangeConflicts = (ids:Array<number>,ranges:Array<{s:number,e:number}>,color?:string) => {
        const len = ranges.length;
        const rangesMask = new Array(len).fill(false);
        const rangesEdgeMask = new Array(len).fill(false);
        for (let i = 0; i < len; i++)
            if (ids.some(id => (ranges[i].s <= id && id <= ranges[i].e)))
                rangesMask[i] = true;
        for (let i = 0; i < len-1; i++)
            if (!rangesMask[i] && rangesMask[i+1])
                for (let j = i; j >= 0 && j > i-DISPLAY_RANGE_BEFORE; j--)
                    if (!rangesMask[j])
                        rangesEdgeMask[j] = true;
        for (let i = len-1; i >= 1; i--)
            if (!rangesMask[i] && rangesMask[i-1])
                for (let j = i; j < len && j < i+DISPLAY_RANGE_AFTER; j++)
                    if (!rangesMask[j])
                        rangesEdgeMask[j] = true;
        const ret = [] as any[];
        for (let i = 0; i < len; i++)
            if (rangesMask[i] || rangesEdgeMask[i]) {
                if (ret.length > 0)
                    ret.push(<div key={ret.length}>,&nbsp;</div>);
                ret.push(<div key={ret.length} style={rangesMask[i]?{color,fontWeight:"bold"}:{}}>{idRangeToString(ranges[i])}</div>)
            }
        return ret;
    }


    const robotIdConflicts = getRangeConflicts(idsSplitted, robotIdRanges, "orange");
    const newRobotIdConflicts = getRangeConflicts(idsSplitted, newRobotIdRanges, "red");
    const redeployIdsNotPresentInNewRobots = (redeployIdsSplitted??[]).filter(r => !(newRobotIds??[]).includes(r));
    const redeployIdsRangesNotPresentInNewRobots = idsToRanges(redeployIdsNotPresentInNewRobots);
    const redeployIdsRangesNotPresentInNewRobotsHTML = rangesDisplay(redeployIdsRangesNotPresentInNewRobots);
    const reloadData = () => {
        if (table.current)
            (table.current as any).reloadData();
        fetchRobotIds();
        fetchNewRobotIds();
    }

    const { displayDialog } = useDialog();

    return (
        <div>
        <div className={classes.controlHeader} ref={controlHeader}>
            <Typography variant="h4">Production</Typography>
            <div key="newids" className={classes.newIds}>
                <div key="1">add available robot id(s)</div>
                <div key="3">
                    <TextField
                        style={{width:"300px"}}
                        label="New Ids"
                        margin="dense"
                        size="small"
                        value={idsRaw}
                        onChange={(ev: any) => {setIdsRaw(ev.target.value.replace(/[^\d,\- ]/g,""));}}
                        variant="outlined"
                        required
                    />
                    <TextField
                        style={{width:"300px"}}
                        label="Start Priority"
                        margin="dense"
                        size="small"
                        value={orderStart}
                        onChange={(ev: any) => {setOrderStart(ev.target.value.replace(/[^\d]/g,""));}}
                        variant="outlined"
                    />
                    <TextField
                        style={{width:"300px"}}
                        label="Product Model"
                        margin="dense"
                        size="small"
                        select
                        value={productModel}
                        onChange={(ev: any) => {setProductModel(ev.target.value);}}
                        variant="outlined"
                        required
                    >
                        <MenuItem key="TCM1" value="TCM1">TCM1 (Tiny Line Marker Sport)</MenuItem>
                        <MenuItem key="TCM2" value="TCM2">TCM2 (Tiny Line Marker Sport 2)</MenuItem>
                        <MenuItem key="TCS1" value="TCS1">TCS1 (Tiny Compact Surveyor)</MenuItem>
                        <MenuItem key="TCP1" value="TCP1">TCP1 (Tiny Compact Premarker)</MenuItem>
                        <MenuItem key="TLP1" value="TLP1">TLP1 (Tiny Line Marker Pro X)</MenuItem>
                        <MenuItem key="TPC1" value='TPC1'>TPC1 (TinyLineMarker Prox CAD)</MenuItem>
                        <MenuItem key="BS2" value="BS2">BS2 (Base Station)</MenuItem>
                    </TextField>
                    <Button
                        className={classes.button}
                        size="medium"
                        variant="outlined"
                        onClick={() => {
                            const inRobot = (robotIds??[]).filter(r => idsSplitted.includes(r));
                            const inNewRobot = (newRobotIds??[]).filter(r => idsSplitted.includes(r));
                            if (inNewRobot.length > 0) {
                                displayDialog({negativeButtonText:"ok",positiveButtonText:"",dialogText:`The following devices are already deployed or waiting for deployment!\n${JSON.stringify(inNewRobot)}\nPlease resolve these conflicts before continuing!`});
                            } else {
                                (inRobot.length > 0
                                    ? displayDialog({negativeButtonText:"cancel",positiveButtonText:"continue",dialogText:`The following ids are already taken!\n${JSON.stringify(inRobot)}\nDeployment is still possible, but the Product Model will not be updated!\nMake sure that no more than 1 hardware is assigned to an id!\nDo you wish to persist changes?`})
                                    : displayDialog({negativeButtonText:"No",positiveButtonText:"Continue",dialogText:`You are about to add ${idsSplitted}. Are you sure?`})
                                ).then(r => {
                                    if (r) {
                                        setSending(true);
                                        ProductionService.postAvailableDeviceIds(idsSplitted, productModel, orderStart===""?undefined:Number(orderStart)).then(() => {
                                            setIdsRaw("");
                                            setOrderStart("");
                                            reloadData();
                                        })
                                        .catch(displayErrorToast)
                                        .finally(() => setSending(false));
                                    }
                                });
                            }
                        }}
                        disabled={productModel===""||idsRaw.trim()===""||error!==""||sending}
                    >
                        Add Ids
                    </Button>
                </div>
                <div key="2" style={{color:"#FF0000",fontWeight:"bold"}}>{error}</div>
                {(robotIdConflicts.length === 0) &&
                    <div key="4">Comma separated list, with optional range of ids. (e.g. <span style={{fontWeight:"bold"}}>1, 2, 5, 8-20, 25, 100-200</span>)</div>
                }
                {(robotIdConflicts.length > 0) &&
                    <div key="5" style={{display:"flex",flexDirection:"row"}}>
                        <div key="a" style={{whiteSpace:"nowrap"}}>Robots Table Conflicts (</div>
                        <div key="b" style={{color:"orange"}}>warning</div>
                        <div key="c">):&nbsp;</div>
                        {robotIdConflicts}
                    </div>
                }
                {(newRobotIdConflicts.length > 0) &&
                    <div key="6" style={{display:"flex",flexDirection:"row"}}>
                        <div key="a" style={{whiteSpace:"nowrap"}}>New Robots Table Conflicts (</div>
                        <div key="b" style={{color:"red"}}>error</div>
                        <div key="c">):&nbsp;</div>
                        {newRobotIdConflicts}
                    </div>
                }
            </div>
            <div key="redeployRobot" className={classes.redeployRobot}>
                <div key="1">redeploy or delete robot</div>
                <div key="2">
                    <TextField
                        style={{width:"300px"}}
                        label="Robot Id"
                        margin="dense"
                        size="small"
                        value={redeployIdsRaw}
                        onChange={(ev: any) => {setRedeployIdsRaw(ev.target.value.replace(/[^\d,\- ]/g,""));}}
                        variant="outlined"
                        required
                    />
                    <Button
                        className={classes.button}
                        size="medium"
                        variant="outlined"
                        onClick={() => {
                            if (redeployIdsNotPresentInNewRobots.length === redeployIdsSplitted.length) {
                                displayDialog({negativeButtonText:"ok",positiveButtonText:"",dialogText:`None of the following devices exist in the New Robots table\n${JSON.stringify(redeployIdsNotPresentInNewRobots)}\nPlease input valid robots!`});
                            } else {
                                displayDialog({negativeButtonText:"No",positiveButtonText:"Continue",dialogText:`You are about to redeploy ${redeployIdsSplitted}. Are you sure?`}).then((r)=>{
                                    if (r){
                                        setSending(true);
                                        ProductionService.redeployDevice(redeployIdsSplitted).then(() => {
                                            setRedeployIdsRaw("");
                                            reloadData();
                                        })
                                        .catch(displayErrorToast)
                                        .finally(() => setSending(false));
                                    }
                                })
                                
                            }
                        }}
                        disabled={redeployIdsRaw.trim()===""||sending||redeployIdsNotPresentInNewRobots.length === redeployIdsSplitted.length||redeployIdsRaw.trim()===""||redeployError!==""}
                    >
                        Redeploy
                    </Button>
                    <Button
                        className={classes.button}
                        size="medium"
                        variant="outlined"
                        onClick={() => {
                            if (redeployIdsNotPresentInNewRobots.length === redeployIdsSplitted.length) {
                                displayDialog({negativeButtonText:"ok",positiveButtonText:"",dialogText:`None of the following devices exist in the New Robots table\n${JSON.stringify(redeployIdsNotPresentInNewRobots)}\nPlease input valid robots!`});
                            } else {
                                displayDialog({negativeButtonText:"No",positiveButtonText:"Continue",dialogText:`You are about to delete ${redeployIdsSplitted}. Are you sure?`}).then((r)=>{
                                    if (r){
                                        setSending(true);
                                        ProductionService.deleteDevice(redeployIdsSplitted).then(() => {
                                            setRedeployIdsRaw("");
                                            reloadData();
                                        })
                                        .catch(displayErrorToast)
                                        .finally(() => setSending(false));
                                    }
                                })
                                
                                }
                            
                        }}
                        disabled={redeployIdsRaw.trim()===""||sending||redeployIdsNotPresentInNewRobots.length === redeployIdsSplitted.length||redeployIdsRaw.trim()===""||redeployError!==""}
                    >
                        Delete
                    </Button>
                </div>
                <div key="12" style={{color:"#FF0000",fontWeight:"bold"}}>{redeployError}</div>
                <div key="14">Comma separated list, with optional range of ids. (e.g. <span style={{fontWeight:"bold"}}>1, 2, 5, 8-20, 25, 100-200</span>)</div>
                {(redeployIdsRangesNotPresentInNewRobotsHTML.length > 0) && (redeployIdsNotPresentInNewRobots.length !== redeployIdsSplitted.length) &&
                    <div key="16" style={{display:"flex",flexDirection:"row"}}>
                        <div key="a" style={{whiteSpace:"nowrap"}}>Some robot ids do not exist in the New Robots table. They will be ignored (</div>
                        <div key="b" style={{color:"orange"}}>warning</div>
                        <div key="c">):&nbsp;</div>
                        {redeployIdsRangesNotPresentInNewRobotsHTML}
                    </div>
                }
                {(redeployIdsRangesNotPresentInNewRobotsHTML.length > 0) && (redeployIdsNotPresentInNewRobots.length === redeployIdsSplitted.length) && (redeployIdsNotPresentInNewRobots[0] > 0) &&
                    <div key="16" style={{display:"flex",flexDirection:"row"}}>
                        <div key="a" style={{whiteSpace:"nowrap"}}>These robots do not exist in the New Robots table. Nothing can be redeployed or deleted (</div>
                        <div key="b" style={{color:"orange"}}>warning</div>
                        <div key="c">):&nbsp;</div>
                        {redeployIdsRangesNotPresentInNewRobotsHTML}
                    </div>
                }
            </div>
            <div></div>
            <div key={"commands"} className={classes.commands}>
                <div key="1">robot/base station commands</div>
                <Commands key="2"/>
            </div>
        </div>
        
        <NewDevicesTable height={300} name={"production-new-devices"} innerRef={table}/>
        <ProductTable/>
        </div>
    );
}
