// credits to https://usehooks.com/useLocalStorage/

import { useCallback, useState, useRef } from "react";

export default function useSessionStorage<T>(
    key: string | undefined,
    initialValue: (() => T) | T
) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (key === undefined) {
            return key;
        }
        try {
            // Get from local storage by key
            const item = window.sessionStorage.getItem(key);
            // Parse stored json or if none return initialValue
            if (item)
                return JSON.parse(item) as T;
            if (typeof initialValue === "function")
                return (initialValue as any)() as T;
            return initialValue as T;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return (typeof initialValue === "function"
                ? (initialValue as any)()
                : initialValue) as T;
        }
    });

    const valueRef = useRef(storedValue);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T | ((t: T) => T)) => {
            if (key === undefined) {
                return;
            }
            try {
                const val =
                    typeof value !== "function"
                        ? value
                        : (value as any)(valueRef.current);

                if (val === valueRef.current) {
                    return;
                }

                valueRef.current = val;
                // Save state
                setStoredValue(val);
                // Save to local storage
                window.sessionStorage.setItem(key, JSON.stringify(val));
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        },
        [key]
    );

    return [storedValue, setValue] as [typeof storedValue, typeof setValue];
}
