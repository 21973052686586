import axios from "axios";
import qs from "qs";
import { handleError } from "./Errors";
import { FilterResult } from "./SupportService";

export interface UserActionsLog {
    actionType: string;
    timestamp: number;
    username: string;
    value: string;
    statusCode?: number;
}

interface LogFilterOptions {
    skip: number;
    limit: number;
    orderBy?: [
        keyof UserActionsLog,
        "desc" | "asc"
    ][];
    notBefore?: Date;
    notAfter?: Date;
    timestampFilterColumn?: "timestamp";
    globalSearch?: string;
    usernameSearch?: string;
    actionTypeSearch?: string;
    statusCodeSearch?: string;
}

export interface Operation {
    dataKey : string,
    translatedKey? : string,
    deviceTypeId : number,
    operationType : string,
}

export interface BulkOperation {
    values : Array<{id : number, value : string}>,
    operation : Operation,
}

export interface OperationConflicts {
    invalidIds : Array<number>,
    conflictingIds : Array<number>,
    matchingDataIds : Array<number>,
}

export default class AdministrationService {
    static async getUserActionsLog(
        options?: Partial<LogFilterOptions>
    ): Promise<FilterResult<UserActionsLog, LogFilterOptions>> {
        const query = qs.stringify(options || {});

        try {
            const response = await axios.get(
                `/api/administration/log${
                    (query && "?" + query) || ""
                }`
            );
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getAllowedOperations(): Promise<Operation[]> {
        try {
            const response = await axios.get(`/api/administration/operations`);
            return response.data as Operation[];
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getConflicts(op : BulkOperation): Promise<OperationConflicts> {
        try {
            const response = await axios.post(`/api/administration/operations/conflicts`, op);
            return response.data as OperationConflicts;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async executeOperation(op : BulkOperation): Promise<void> {
        try {
            await axios.post(`/api/administration/operations/execute`, op);
        } catch (err) {
            return handleError(err, false);
        }
    }
}