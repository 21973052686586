export default function getAbsoluteHeight(element?: HTMLElement | null) {
    if (!element) {
        return 0;
    }

    const styles = window.getComputedStyle(element);
    const margin =
        parseInt(styles.getPropertyValue("margin-top")) +
        parseInt(styles.getPropertyValue("margin-bottom"));
    return element.offsetHeight + margin;
}
