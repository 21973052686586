import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { basestationsSelector, basestationsSelectorUnfiltered, customersArraySelector, customersDtoSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { Customer } from "src/services/UserService";
import ConfigurationsService from "src/services/ConfigurationsService";
import { BaseStation } from "src/services/DeviceDataService";
import { setBaseStations } from "src/redux/app/actions";

type BaseStationInput = {
    customer: Customer | null;
    basestation: BaseStation | null;
};

export default function BaseStationWidget({ widgetName, saveChanges, favouriteWidgets, setFavouriteWidgets  }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        reset,
        watch,
        setValue
    } = useForm<BaseStationInput>({
        defaultValues: { customer: null, basestation: null },
    });

    const customers = useSelector(customersArraySelector);
    const customersDto = useSelector(customersDtoSelector);
    const basestations = useSelector(basestationsSelector);
    const basestationsUnfiltered = useSelector(basestationsSelectorUnfiltered);
    const { displayToast } = useToast();

    const [baseStationWidgetLastCustomerId,setBaseStationWidgetLastCustomerId]=useLocalStorage("BaseStationWidgetLastCustomerId",-1)
    const [baseStationWidgetLastStationId,setBaseStationWidgetLastStationId]=useLocalStorage("BaseStationWidgetLastStationId",-1)

    const onComplete = useCallback(
        (input: BaseStationInput) => {
            basestationsUnfiltered.find(b => b.id === input.basestation!.id)!.customerId = input.customer!.id;
            setBaseStations(basestationsUnfiltered);
            if (!saveChanges)
            reset();
            displayToast({
                message: `Successfully added reference station ${input.basestation!.id} to customer ${input.customer!.id}!`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [basestationsUnfiltered, displayToast, reset,saveChanges]
    );

    const addBaseStation = useCallback((input: BaseStationInput) => {
            return ConfigurationsService.addBaseStation(input.customer!.id, input.basestation!.id).then(() => input);
        }, []
    );

    const { exec, pending, error } = useAsync(
        addBaseStation,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = pending || customers.length === 0 || basestations.length === 0;

    const customer = watch("customer");
    const basestation = watch("basestation");

        useEffect(()=>{
            if (saveChanges){
                if (baseStationWidgetLastCustomerId && baseStationWidgetLastCustomerId>=0 && baseStationWidgetLastCustomerId!==customer?.id)
                setValue('customer',customers.find(c=>c.id===baseStationWidgetLastCustomerId))
                
                if (baseStationWidgetLastStationId && baseStationWidgetLastStationId >=0 && baseStationWidgetLastStationId!==basestation?.id)
                setValue('basestation',basestations.find(c=>c.id===baseStationWidgetLastStationId))
            }
        },[saveChanges,baseStationWidgetLastCustomerId,baseStationWidgetLastStationId,basestation,basestations,customer,customers,setValue])

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Add reference station"
            subTitle="Pair a reference station to a customer, granting them access to use the selected device."
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <Controller
            
                name="customer"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            value={customer}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (saveChanges){
                                    setBaseStationWidgetLastCustomerId(val?.id??-1)}
                               
                            }}
                            disabled={loading}
                            options={customers}
                            getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                            getOptionSelected={(a, b) => a.id === b.id}
                            noOptionsText="No such customer"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Customer"
                                        placeholder="Choose a customer"
                                        required
                                        {...getErrorProps(errors, "customer")}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="basestation"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            value={basestation}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (saveChanges){
                                    setBaseStationWidgetLastStationId(val?.id??-1)}
                               
                            }}
                            disabled={loading}
                            options={basestations}
                            getOptionLabel={(b) => {
                                const c = customersDto[b.customerId];
                                let ret = b.id+"";
                                if (c && c.name)
                                    ret += ` [${c.name}]`;
                                return ret;
                            }}
                            noOptionsText="No such reference station"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="ReferenceStation"
                                        placeholder="Choose a reference station"
                                        required
                                        {...getErrorProps(errors, "basestation")}
                                    />
                                );
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                        />
                    );
                }}
            />
        </BaseWidget>
    );
}
