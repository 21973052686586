import React, {
    createContext,
    useState,
    useRef,
    useEffect,
    useContext,
} from "react";

import {
    CommonSection,
    PrimarySection,
    SecondarySection,
} from "./DashboardProvider";

import type { DetailedDeviceDto } from "src/services/DeviceDataService";

export type SectionState = DetailedDeviceDto & CommonSection;

const SectionContext = createContext<SectionState>(undefined!);

function isSecondary(t: any): t is SecondarySection {
    return t.devices !== undefined;
}

function mapSectionState(
    section: PrimarySection | SecondarySection,
    deviceId: number
): SectionState {
    if (!isSecondary(section)) {
        const { device, ...rest } = section;
        return { ...device, ...rest };
    }

    const { dictionary, deviceName, deviceTypeId, groups, devices } = section;

    return {
        deviceTypeId,
        deviceName,
        dictionary,
        groups,
        ...devices[deviceId],
    };
}

export interface SectionProviderProps {
    section: PrimarySection | SecondarySection;
    deviceId: number;
    children: React.ReactNode;
}

export default function SectionProvider({
    section,
    deviceId,
    children,
}: SectionProviderProps) {
    const [sectionState, setSetctionState] = useState(() =>
        mapSectionState(section, deviceId)
    );
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current === false) {
            setSetctionState(mapSectionState(section, deviceId));
        }
    }, [section, deviceId]);

    useEffect(() => {
        firstRenderRef.current = false;
    }, []);

    return (
        <SectionContext.Provider value={sectionState}>
            {children}
        </SectionContext.Provider>
    );
}

export function useSection() {
    return useContext(SectionContext);
}
