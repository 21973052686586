type ClassNameValue = string | number;
type ClassNameSpecifier = undefined | null | boolean | Record<string, unknown>;
type ClassName =
    | (ClassNameValue | ClassNameSpecifier)
    | { [key in ClassNameValue]: ClassNameSpecifier };

export default function classNames(...args: ClassName[]) {
    return (args.reduce((acc, className) => {
        if (!className) {
            return acc;
        }

        if (typeof className === "object") {
            const toAdd = Object.entries(className).reduce(
                (prev, [name, shouldAdd]) => {
                    if (shouldAdd) {
                        return prev ? `${prev} ${name}` : name;
                    }
                    return prev;
                },
                ""
            );

            return (acc ? `${acc} ${toAdd}` : toAdd).trimEnd();
        }

        return acc ? `${acc} ${className}` : className;
    }, "") || undefined) as string | undefined;
}
