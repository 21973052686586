/* eslint-disable @typescript-eslint/ban-types */
import React, { FormEvent, useCallback, useMemo, useState } from "react";
import { Button, Typography, createStyles, useMediaQuery, makeStyles, Theme, TextField, Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GlobalFilter from "./GlobalFilter";
import { TableInstance, Filters as FiltersArray } from "react-table";
import { withInstance } from "../BasicTable/tableUtilities";
import AutocompleteV2 from "../AutocompleteV2";

const useStyles = makeStyles((theme) =>
    createStyles({
        popper: {
            boxShadow: "0 3px 12px rgba(27,31,35,.15)",
            borderRadius: 3,
            width: 550,
            zIndex: 50,
            fontSize: 13,
            color: "#586069",
            backgroundColor: "#f6f8fa",
        },
        popperSmall: {
            boxShadow: "0 3px 12px rgba(27,31,35,.15)",
            borderRadius: 3,
            maxwidth: 550,
            zIndex: 50,
            fontSize: 13,
            color: "#586069",
            backgroundColor: "#f6f8fa",
        },
        header: {
            display: "flex",
            borderBottom: "1px solid #e1e4e8",
            padding: "8px 10px",
            "& :first-child": {
                fontWeight: 600,
                fontSize: "14px",
            },
            "& :last-child": {
                marginLeft: "auto",
                fontWeight: 600,
            },
            alignItems: "center",
        },
        paper: {
            position: "relative",
            boxShadow: "none",
            margin: 0,
            color: "#586069",
            fontSize: 13,
            maxHeight: "40vh",
            overflowY: "auto",
            "& ul": {
                paddingTop: 2,
            },
            fontFamily: theme.typography.fontFamily,
            paddingBottom: theme.spacing(1),
        },
        hidden: {
            display: "none",
        },
        cell: {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(2),
            maxWidth: 400
        },
    })
);

export interface FiltersProps<T extends {}> {
    instance: TableInstance<T>;
    anchorEl?: Element;
    onClose: () => void;
    open: boolean;
    filters: FiltersArray<T>;
    enableStrictSearch?: boolean 
}

function Filters<T extends {}>({
    instance,
    anchorEl,
    onClose,
    open,
    enableStrictSearch,
}: FiltersProps<T>) {
    const classes = useStyles({});
    const [, forceRender] = useState(0);
    const {
        allColumns,
        setAllFilters,
        setGlobalFilter,
        state: { globalFilter },
    } = instance;

    const showGlobalFilter =
        !useMediaQuery((theme: Theme) => theme.breakpoints.up("sm")) &&
        !instance.disableGlobalFilter;

    const smallDisplay = !useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onClose();
        },
        [onClose]
    );
    const resetFilters = useCallback(() => {
        setAllFilters((f) => (f.length === 0 ? f : []));
        setDisplayedFilters([])
        if (globalFilter) {
            setGlobalFilter((globalFilter?.keyword !== undefined && globalFilter?.strict !== undefined) ? {keyword: "", strict: false}:null);
        }
        forceRender((s) => (s+1));
    }, [setAllFilters, setGlobalFilter, globalFilter]);


   const deleteDisplayedFilter=useCallback((id)=>{
    setAllFilters((f)=>f.filter((filt)=>filt.id!==id))
    setDisplayedFilters((df)=>df.filter((filt)=>filt.id!==id))
    forceRender((s) => (s+1));
   },[setAllFilters])

   const createOrFilter=useCallback((id)=>{
    setAllFilters((f)=>{
        if (f[f.findIndex((filt)=>filt.id===id)])
        f[f.findIndex((filt)=>filt.id===id)].value.push("")
        

        return f
    })
   },[setAllFilters])

    const cols = useMemo(
        () =>
            allColumns
                .filter((it) => !it.disableFilters && !it.placeFilterOnToolbar)
                .sort((a, b) => {
                    return (a.filterOrder ?? 1000) - (b.filterOrder ?? 1000);
                }),
        [allColumns]
    );
    const [displayedFilters,setDisplayedFilters]=useState(cols.filter(c=>c.filterValue));          
   const displayedFiltersComponent=[...displayedFilters].map((column,index) => {
    
    return (
        <Grid item key={`${column.id+index}`}  xs={12} style={{display:"flex",justifyContent:"space-between"}}>
            <div style={{flexGrow:1,marginBottom:"10px"}}>
                {column.render("Filter")}
            </div>
            <div>
            {Array.isArray( column?.filterValue) && column?.id!=='updated_at' && column?.id!=='created_at' && column?.id!=='created' && column?.id!=='inserted' && column?.id!=='timestamp' && <Button onClick={()=>createOrFilter(column.id)}
                variant="outlined"
                disabled={(column?.filterValue?.findIndex((fv:string)=>fv==='')!==-1 )}
                size="small"
                color="primary"
                style={{marginTop:"8px", marginBottom:"4px",marginLeft:"5px",height:"40px"}}>Multiselect</Button>
            }

                <Button onClick={()=>deleteDisplayedFilter(column.id)}
                variant="outlined"
                size="small"
                color="primary"
                style={{marginTop:"8px", marginBottom:"4px",marginLeft:"5px",height:"40px"}}>Delete</Button>
                
               
            </div>
        </Grid>
    )})
    
    return (
        <>
            <Popover
                anchorEl={anchorEl}
                id={"popover-filters"}
                onClose={onClose}
                open={open}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div className={smallDisplay ? classes.popperSmall : classes.popper}>
                    <form onSubmit={onSubmit}>
                        <div className={classes.header}>
                            <Typography variant="h6" component="p">
                                Filters
                            </Typography>
                            <Button
                                color="primary"
                                size="small"
                                onClick={resetFilters}
                            >
                                Reset
                            </Button>
                        </div>
                        <Paper className={classes.paper}>
                            <Grid container>
                                {showGlobalFilter && (
                                    <Grid item className={classes.cell} xs={12}>
                                        <GlobalFilter
                                            fullWidth
                                            instance={instance}
                                            enableStrictSearchOption={enableStrictSearch}
                                        />
                                    </Grid>
                                )}
                                {<Box style={{width:"100%",padding:'20px'}}>

                                        <AutocompleteV2
                                        options={cols}
                                        fullWidth
                                        renderInput={
                                            params => 
                                            { 
                                            return <TextField
                                                {...params}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                label="Add filter"
                                            />}
                                        }
                                        getOptionDisabled={(option) => {return displayedFilters.some((df)=>df.id===option.id)}}
                                        getOptionLabel={(c=>c.id)}
                                        getOptionSelected={(a, b) => a.id === b.id}
                                        renderOption={(c)=>{
                                        return <div>{c.id}</div>}}
                                        onChange={(_e, val) => {
                                            if (val){
                                            setDisplayedFilters((prev)=>[...prev,val])
                                            }
                                        }}
                                        style={{marginBottom:"10px"}}
                                        ></AutocompleteV2>
                                        {displayedFiltersComponent.length > 0 && displayedFiltersComponent }
                                        
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </form>
                </div>
            </Popover>
        </>
    );
}

export default withInstance(
    (instance) => ({
        instance,
        filters: instance.state.filters,
        globalFilter: instance.state.globalFilter,
    }),
    Filters,
    true
);
