import moment from "moment";
import { timezone } from "src/App";
import { TimeFormats } from "src/utils/constants";

export default function downloadOverview(currentGrid:any, currentTranslator:any) {
    return () => new Promise<void>(resolve => {
        const csv : any[][] = new Array<any>(currentGrid.columns.length);
        for (let i = 0; i < currentGrid.columns.length; i++)
            csv[i] = new Array<any>(currentGrid.filteredData.length + 1);
        for (let i = 0; i < currentGrid.columns.length; i++) {
            const current = currentGrid.columns[i];
            if (i === 0)
                csv[i][0] = current.name;
            else
                csv[i][0] = currentTranslator.translateKey(current.id);
            for (let j = 0; j < currentGrid.filteredData.length; j++)
                if (["from","to","lastLogin","created_at","updated_at"].includes(current.id) && currentGrid.filteredData[j].values[current.id] !== undefined)
                    csv[i][j + 1] = moment.unix(currentGrid.filteredData[j].values[current.id]).utcOffset(timezone).format(TimeFormats.Presentation2);
                else
                    csv[i][j + 1] = currentTranslator.translate(current.id,currentGrid.filteredData[j].values[current.id]);
        }
        let csvContent = "data:text/csv;charset=utf-8,";
        for (let j = 0; j < currentGrid.filteredData.length + 1; j++) {
            for (let i = 0; i < currentGrid.columns.length; i++) {
                if (csv[i][j] != null) {
                    if (typeof csv[i][j] === 'string' || csv[i][j] instanceof String)
                        csvContent += (csv[i][j] as string).replace(/[\r\n]+/g," ").replace(";",",");
                    else
                        csvContent += csv[i][j];
                }
                if (i !== currentGrid.columns.length - 1)
                    csvContent += ";";
            }
            if (j !== currentGrid.filteredData.length)
                csvContent += "\r\n";
        }
        const temp_link = document.createElement("a");
        document.body.appendChild(temp_link);
        temp_link.href = encodeURI(csvContent).replace(/#/g, '%23');
        temp_link.download = "overview.csv";
        temp_link.click();
        temp_link.remove();
        resolve();
    });
}