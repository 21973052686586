import React, { useCallback, useEffect, useState } from "react";
import SupportService, { SupportIssue } from "src/services/SupportService";
import { useAsync, useToast } from "src/hooks";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { TimeFormats } from "src/utils/constants";
import NotFound from "../NotFound";
import { isMobile } from "react-device-detect";
import ReadonlyField from "src/components/ReadonlyField";
import Linkify from "react-linkify";
import { timezone } from "src/App";
import { useSelector } from "react-redux";
import { robotsSelector, tabletsSelector, customersSelector } from "src/redux/app/selectors";

const useStyles = makeStyles((theme: any) => ({
    supportWrapper: isMobile ? {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        padding: "20px",
        '&>div:nth-child(1)': {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            '&>*': {
                marginBottom: "15px",
            },
        },
    } : {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "row",
        padding: "20px",
        '&>div:nth-child(1)': {
            width: "300px",
            marginRight: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            '&>*': {
                marginBottom: "15px",
            },
        },
        '&>div:nth-child(2)': {
            flex: 1,
        },
    },
    supportSubmit: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    updateControlWrapper: isMobile ? {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
    } : {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
    },
    updateControlLeft: isMobile ? {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        '&>*': {
            width: "100%",
            marginBottom: "10px",
        },
    } : {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        '&>*:nth-child(1)': {
            marginLeft: "0px",
        },
        '&>*': {
            marginLeft: "10px",
        },
    },
    updateControlRight: isMobile ? {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        '&>*': {
            width: "100%",
            marginBottom: "10px",
        },
    } : {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",
        '&>*:nth-last-child(1)': {
            marginRight: "0px",
        },
        '&>*': {
            marginRight: "10px",
        },
    },
    userField: {
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center",
        flexDirection:"row",
        '&>*:nth-child(1)': {
            width: "50%",
        },
        '&>*:nth-child(2)': {
            cursor: "default",
            marginLeft:"8px",
            marginTop:"3px",
        },
    },
    updatePost: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    issueClose: {
        marginTop: "30px",
        backgroundColor: "#008800",
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: "#00BB00",
        },
    },
    issueDelete: {
        marginTop: "30px",
        backgroundColor: "#9f0000",
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: "#e80000",
        },
    },
    readonly: {
        cursor: "default",
        color: "#444444",
        '& *': {
            cursor: "default",
            color: "#444444",
        },
    },
    secondaryButton: {
        backgroundColor: "#CBDEE7",
        color: theme.palette.common.black,
    },
    issueHistory: {
        cursor: "default",
        display: "flex",
        justifyContent: "flex-start",
        alignItems:"top", flexDirection:"row",
        marginBottom: "20px",
        '& *': {
            fontFamily: theme.typography.fontFamily,
        },
        '&:last-child': {
            marginBottom: "0px",
        },
        '&>*:nth-child(1)>*': {
            whiteSpace: "nowrap",
            fontStyle: "italic",
        },
        '&>*:nth-child(2)': {
            flexGrow: 1,
            whiteSpace: "pre-line",
            marginLeft: "10px"
        },
    }
}));

export function SupportIssueView() {
    const classes = useStyles();
    const { supportId } = useParams<{supportId:string}>();

    const { displayToast } = useToast();
    
    const robots = useSelector(robotsSelector);
    const tablets = useSelector(tabletsSelector);
    const customers = useSelector(customersSelector);

    const [notFound, setNotFound] = useState(false);
    const [issue, setIssue] = useState(undefined as SupportIssue | undefined);

    const fetchData_ = useCallback(
        () => {return SupportService.getSupportIssueById(Number(supportId));}, [supportId]
    );
    const { exec: fetchData } = useAsync(fetchData_, {
        immediate: false,
        clearValueOnExec: false,
        onError: (val : any) => {
            if (val.message === "Resource not found.")
                setNotFound(true);
            else
                displayToast({
                    message: val.message,
                    severity: "error",
                    withCloseIcon: true,
                });
        },
        onComplete: (val : any) => {
            setIssue(val);
        },
    });
    useEffect(() => {
        fetchData();
    }, [supportId]); //eslint-disable-line react-hooks/exhaustive-deps

    if (notFound) return (<NotFound />);

    const issueHistory = [<div className={classes.issueHistory} key="subject">{issue?.subject}</div>];
    if (issue?.comments !== null && issue?.comments !== undefined)
        for (let i = 0; i < issue?.comments.length; i++)
            issueHistory.push(
            <div className={classes.issueHistory} key={i}>
                <div>
                    <div>{moment.unix(issue?.comments[i].created_at as number).utcOffset(timezone).format(TimeFormats.Presentation2)}:</div>
                    <div>{issue?.comments[i].author}</div>
                </div>
                <div>
                    {issue?.comments[i].body}
                </div>
            </div>);
    
    let robotVal = "N/A";
    let robotLink = "";
    if (robots.length > 0 && issue?.robot_id !== undefined) {
        const r = robots.find(ro => ro.id === issue.robot_id)!;
        robotLink = "/devices/robots/"+r.id;
        robotVal = "("+r.id+") "+r.name;
    }
    let tabletVal = "N/A";
    let tabletLink = "";
    if (tablets.length > 0 && issue?.tablet_id !== undefined) {
        const t = tablets.find(ta =>ta.id === issue.tablet_id)!;
        if (t.connectedRobotId !== undefined && t.connectedRobotId !== null)
            tabletLink = "/devices/robots/"+t.connectedRobotId;
        else
            tabletLink = "/devices/tablets/"+t.id;
        tabletVal = t.id+"";
    }
    
    return (<>
        <Typography variant="h4" align="left" style={{marginLeft:"20px",marginTop:"20px"}}>Individual Support Issue View</Typography>
        <div className={classes.supportWrapper}>
            <div>
                <ReadonlyField
                    label="Issue ID"
                    value={issue?.id?.toString() ?? "N/A"}
                />
                <ReadonlyField
                    label="Category"
                    value={issue?.category ?? "N/A"}
                />
                <ReadonlyField
                    label="Status"
                    value={issue?.status ?? "N/A"}
                />
                <ReadonlyField
                    label="Requester"
                    value={issue?.requester ?? "N/A"}
                />
                <ReadonlyField 
                    label="Requester Phone"
                    value={issue?.requester_phone===""||issue?.requester_phone===undefined ? "N/A" : issue?.requester_phone}
                    isLink={issue?.requester_phone!==""&&issue?.requester_phone!==undefined&&isMobile}
                    onClick={() => {
                        if (issue&&issue.requester_phone&&isMobile)
                            window.open("tel:"+issue.requester_phone);
                    }}
                />
                <ReadonlyField 
                    label="Requester Email"
                    value={issue?.requester_email===""||issue?.requester_email===undefined ? "N/A" : issue?.requester_email}
                    isLink={issue?.requester_email!==""&&issue?.requester_email!==undefined}
                    onClick={() => {
                        if (issue&&issue.requester_email)
                            window.open("mailto:"+issue.requester_email);
                    }}
                />
                <ReadonlyField
                    label="Assignee"
                    value={issue?.assignee ?? "N/A"}
                />
                <ReadonlyField 
                    label="Assignee Phone"
                    value={issue?.assignee_phone===""||issue?.assignee_phone===undefined ? "N/A" : issue?.assignee_phone}
                    isLink={issue?.assignee_phone!==""&&issue?.assignee_phone!==undefined&&isMobile}
                    onClick={() => {
                        if (issue&&issue.assignee_phone&&isMobile)
                            window.open("tel:"+issue.assignee_phone);
                    }}
                />
                <ReadonlyField 
                    label="Assignee Email"
                    value={issue?.assignee_email===""||issue?.assignee_email===undefined ? "N/A" : issue?.assignee_email}
                    isLink={issue?.assignee_email!==""&&issue?.assignee_email!==undefined}
                    onClick={() => {
                        if (issue&&issue.assignee_email)
                            window.open("mailto:"+issue.assignee_email);
                    }}
                />
                <ReadonlyField
                    label="Organization"
                    value={issue?.organization ?? "N/A"}
                />
                <ReadonlyField
                    label="Robot"
                    value={robotVal}
                    link={robotLink}
                />
                <ReadonlyField
                    label="Tablet"
                    value={tabletVal}
                    link={tabletLink}
                />
                <ReadonlyField
                    label="Customer"
                    value={"("+(issue?.customer_id??1)+") "+(customers.dto[issue?.customer_id ?? 1]??{name:""}).name}
                />
            </div>
            <div>
                <div
                    style={{
                        border: "solid #AAAAAA 1px", 
                        padding: "10px",
                        borderRadius: "5px",
                    }}
                >
                    <Linkify componentDecorator={(href, content, key) => (<a target="blank" href={href} key={key}>{content}</a>)}>
                        {issueHistory}
                    </Linkify>
                </div>
            </div>
        </div>
    </>);
}