import axios from "axios";
import jwt from "jsonwebtoken";
import * as errors from "./Errors";
import { UserCredentials } from "../redux/auth/types";
import { getBrowser, getOS } from "src/utils/browserDetect";


/* Identifier for the JWT access token stored in local storage */
export const USER_IDENTIFIER = "tmr/user";
const TOKEN_IDENTIFIER = "tmr/token";

axios.interceptors.request.use((reqConfig) => {
    if (reqConfig.url !== "/api/token" && reqConfig.url?.[0]==='/') {
        reqConfig.headers!["Authorization"] = `Bearer ${
            localStorage.getItem(TOKEN_IDENTIFIER) || ""
        }`;
    }
    return reqConfig;
});

axios.interceptors.response.use(
    (res) => res,
    (err) => {
        const res = err.response;
        if (res && res.status === 401) {
            return Promise.reject(
                new errors.AuthenticationError(
                    (res.data && res.data.message) || "You are not signed in."
                )
            );
        } else if (res && res.status === 403) {
            return Promise.reject(
                new errors.AuthorizationError(
                    (res.data && res.data.message) ||
                        "You do not have access to this resource."
                )
            );
        }
        return Promise.reject(err);
    }
);

axios.defaults.timeout = 50000;

interface Claims {
    // Subject
    sub: string;
    // Issuer
    iss: string;
    // Audience
    aud: string;
    // Expiration time in seconds since 1970-01-01T00:00:00Z
    exp: number;
    // Issued at
    iat: number;
    // JWT ID
    jti: string;
    // Remaining
    customerId: number[];
    userAccess: string[];
    customerName: string[];
    isTwoFactorAuthenticationEnabled:boolean,
    twoFactorAuthentificationCode:string,

}

interface AcceptedCredentialsResult {
    success: true;
    expiresIn: number;
    token: string;
    TFANeeded?: boolean;
}

function decodeToken(token: string): UserCredentials | null {
    const claims = jwt.decode(token) as Claims | null;
    
    if (claims) {
        const expiresAt = new Date(claims.exp * 1000);
        if (expiresAt > new Date()) {
            return {
                username: claims.sub,
                customerId: claims.customerId,
                customerName: claims.customerName,
                userAccess: claims.userAccess,
                isTwoFactorAuthenticationEnabled: claims.isTwoFactorAuthenticationEnabled,
                twoFactorAuthentificationCode: claims.twoFactorAuthentificationCode
            };
        }
    }
    return null;
}
export type TFARequest={
    TFANeeded: boolean
}

export default class AuthService {
    static async signIn(user: {
        username: string;
        password: string;
        confirmationCode?:number
    }): Promise<UserCredentials | TFARequest> {
        let error = null;
        try {
            const response = await axios.post<AcceptedCredentialsResult>(
                "/api/token",
                {...user,browser:getBrowser(),os:getOS()},
                
            );

        
            if (response.data.token){
                const credentials = decodeToken(response.data.token);
                    if (credentials) {
                        localStorage.setItem('2FAEnabled',String(credentials.isTwoFactorAuthenticationEnabled))
                        localStorage.setItem(USER_IDENTIFIER, user.username);
                        localStorage.setItem(TOKEN_IDENTIFIER, response.data.token);
                        localStorage.setItem(TOKEN_IDENTIFIER, response.data.token);
                        return credentials;
                    }
            }else if (response.data.TFANeeded){
                
                return {
                    TFANeeded: true,

                }

            }
            
        } catch (err) {
           
            error = err; 
        }
        return errors.handleError(error, false);
    }
    
    static async signOut() {
        try {
            await axios.post("/api/token/revoke");
            localStorage.removeItem(TOKEN_IDENTIFIER);
            localStorage.removeItem(USER_IDENTIFIER);
            localStorage.removeItem("2FAEnabled")
        } catch (err) {
            errors.handleError(err, false);
        }
    }
    static getActiveUsername() {
        return localStorage.getItem(USER_IDENTIFIER);
    }
    static getActiveCredentials() {
        const token = localStorage.getItem(TOKEN_IDENTIFIER);
        return token !== null ? decodeToken(token) : token;
    }
    static getActiveToken() {
        return localStorage.getItem(TOKEN_IDENTIFIER);
    }
}
