import { useRef, useEffect, useCallback } from "react";

export function makeCancellablePromise<T>(promise: Promise<T>) {
    let isCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((val) => {
                return isCanceled ? reject({ isCanceled }) : resolve(val);
            })
            .catch((error) => {
                return isCanceled ? reject({ isCanceled }) : reject(error);
            });
    });
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        },
    };
}

export default function useMakeCancellablePromise() {
    const promises = useRef<ReturnType<typeof makeCancellablePromise>[]>([]);

    useEffect(() => {
        promises.current = promises.current || [];
        return function cancel() {
            promises.current.forEach((p) => p.cancel());
            promises.current = [];
        };
    }, []);

    return useCallback(function makeCancelable<T>(
        promise: Promise<T>
    ) {
        const wrapped = makeCancellablePromise(promise);
        promises.current.push(wrapped);
        return wrapped.promise as Promise<T>;
    },
    []);
}
