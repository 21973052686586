import { Autocomplete, AutocompleteProps, Value, createFilterOptions } from "@material-ui/lab";
import React, { useState } from "react";
import crypto from "crypto";
import { AutocompleteRenderInputParams } from "@mui/material";

/**
 * Extras: 
 * - autoHighlight default is now true
 * - clearOnSelectAndBlur when true, the input field is cleared on blur and value select (default is false)
 * - selectOnTab when true, pressing tab will select the highlighted value (default is false)
 * 
 * Demos: [Autocomplete](https://material-ui.com/components/autocomplete/)
 * 
 * API: [Autocomplete API](https://material-ui.com/api/autocomplete/)
 */
export default function AutocompleteV2<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {selectOnTab? : boolean, clearOnSelectAndBlur? : boolean}
) : JSX.Element {
    const eProps = {...props};
    const [key, setKey] = useState(crypto.randomBytes(32).toString("hex")+"a");
    const [inputText, setInputText] = useState(' ');
    const [highlighted, setHighlighted] = useState(null as T | null);
    //clearOnSelectAndBlur
    let clearOnSelectAndBlur = false;
    if (eProps.clearOnSelectAndBlur !== undefined)
        clearOnSelectAndBlur = eProps.clearOnSelectAndBlur;
    if (clearOnSelectAndBlur) {
        eProps.value = undefined;
        eProps.renderInput = (params) => {
            if ((params.inputProps as any).value === " ")
                (params.inputProps as any).value = "";
            (params.inputProps as any).onChange = (e : any) => {
                if (e.target.value === undefined || e.target.value === null || e.target.value === "")
                    setInputText(" ");
                else
                    setInputText(e.target.value);
            };
            const result = props.renderInput(params);
            //change label when loading
            if(React.isValidElement(result) && props.loading)
            {
                const inputProps = {...result.props}
                if(inputProps?.label)
                    inputProps.label = "Loading..."
                return <result.type {...inputProps}/>
            }
            return result;
        };
       
        eProps.inputValue = inputText;
        eProps.onOpen = (ev) => {
            setInputText(" ");
            setHighlighted(null);
            if (props.onOpen !== undefined)
                props.onOpen(ev);
        }
        eProps.onClose = (ev, re) => {
            setInputText(" ");
            setHighlighted(null);
            (document.activeElement as HTMLElement).blur();
            if (props.onClose !== undefined)
                props.onClose(ev, re);
            //reset state
            setKey(key.substring(0,key.length-1) + key.charAt(key.length-1) === "a" ? "b" : "a"); 
            
        }
    }
    else{
        if(props.loading)
        {
            //change label when loading and clearOnBlur is false (duplicate code because arrow function cannot be changed twice)
            eProps.renderInput = (params: AutocompleteRenderInputParams) => {
            const result = props.renderInput(params);
            if(React.isValidElement(result))
            {
                const inputProps = {...result.props}
                if(inputProps?.label)
                    inputProps.label = "Loading..."
               return <result.type {...inputProps}/>
            }
            return result;
            }
        }
    }
    //disable when loading
    if(eProps.loading)
        eProps.disabled = true;
    //selectOnTab
    let selectOnTab = false;
    if (props.selectOnTab !== undefined)
        selectOnTab = props.selectOnTab;
    if (selectOnTab) {
        eProps.onHighlightChange = (ev, op, re) => {
            setHighlighted(op);
            if (props.onHighlightChange !== undefined)
                props.onHighlightChange(ev, op, re);
        }
        eProps.onKeyDown = (e) => {
            if (e.key === "Tab") {
                if (props.onChange !== undefined)
                    if (!(props.value !== undefined && props.value !== null && props.value !== "" && (highlighted === null || highlighted === undefined)))
                        props.onChange(e, highlighted as Value<T, Multiple, DisableClearable, FreeSolo>, "blur", {option: highlighted as T});
            }
        }
    }
    
    //change default autoHighlight to true
    if (props.autoHighlight === undefined)
        eProps.autoHighlight = true;
    delete eProps["clearOnSelectAndBlur"];
    delete eProps["selectOnTab"];
    return (
        <Autocomplete
            key={key}
            {...eProps}
            filterOptions={(options, state) => {
                const defaultFilterOptions = createFilterOptions<T>();
                const filteredOptions = defaultFilterOptions(options, state);
                if(!state.inputValue || state.inputValue === " ")
                    return filteredOptions;
                const {strictFiltered, filtered} = filteredOptions.reduce((acc, option) => {
                  
                    const splitted = state.getOptionLabel(option)
                        .split(/[,(){[\]}]+/)
                        .map(s=>s.toLowerCase())

                    if (splitted.includes(state.inputValue.toLowerCase()))
                      return { ...acc, strictFiltered: [...acc.strictFiltered, option] }
                    return { ...acc, filtered: [...acc.filtered, option] }
                
                  }, { strictFiltered: [] as T[], filtered: [] as T[]})
                return strictFiltered.concat(filtered);
                }
            }
        />
    );
}