export interface TreeNode<T> {
    item: T;
    children: TreeNode<T>[];
}

export default class Tree<T> {
    top?: TreeNode<T>;

    constructor(node?: TreeNode<T>) {
        this.top = node;
    }

    find(pred: (item: T) => boolean) {
        if (!this.top) {
            return undefined;
        }

        const iter = (
            node: TreeNode<T>,
            acc?: TreeNode<T>
        ): TreeNode<T> | undefined => {
            if (acc) {
                return acc;
            }

            if (pred(node.item)) {
                return node;
            }

            if (node.children.length === 0) {
                return undefined;
            }

            return node.children.reduce(
                (a: TreeNode<T> | undefined, n) => iter(n, a),
                undefined
            );
        };

        const nextNode = iter(this.top);
        return nextNode && new Tree(nextNode);
    }

    toArray() {
        if (!this.top) {
            return [];
        }

        const iter = (n: TreeNode<T>): T[] => {
            const item = n.item;
            return [item, ...n.children.flatMap(iter)];
        };

        return iter(this.top);
    }

    empty() {
        return this.top === undefined;
    }
}
