//EditTabletFeaturePopup
import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

export default function EditTabletFeaturePopup(props) {
    const [open, setOpen] = React.useState(false);

    const initialState = {
        title: "",
        features: [],
        chosenFeatures: []
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const updateFeatures = () => {
        axios(`/api/dbtool/featuresandoptions`)
            .then(response => response.data[0])
            .then(state => {

                let enabledOptions = props.tablet.features.map(f => f.Options.map(o => o.Id)).flat();

                let dict = {};
                for (let d of state) {
                    if (!dict[d.FeatureId])
                        dict[d.FeatureId] = { Tag: d.Tag, Group: d.Group, GroupId: d.GroupId, Id: d.FeatureId, Options: [], Configations: {} };
                    if (d.OptionId) {
                        dict[d.FeatureId].Options.push(
                            {
                                Id: d.OptionId,
                                Tag: d.FeatureOption,
                                description: d.FeatureOptionDescription,
                                enabled: enabledOptions.indexOf(d.OptionId) >= 0
                            }
                        )
                    }
                }
                let data = Object.values(dict);
                data = data.map((d) => {
                    d.Configations = Object.values(d.Configations);
                    return d;
                });

                let chosenFeatureIds = props.tablet.features.map(f => f.Id);
                let chosenFeatures = data.filter(f => {
                    return chosenFeatureIds.indexOf(f.Id) >= 0;
                });
                chosenFeatures.push({ Options: [] });

                setState({
                    title: "Edit tablet " + props.tablet.Id + " features",
                    features: data,
                    chosenFeatures: chosenFeatures
                });

            })
    };

    const handleClickOpen = () => {

        // Update selects
        updateFeatures();

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeSelectedFeature = (listIndex, newFeature) => {
        if (newFeature)
            state.chosenFeatures[listIndex] = newFeature;
        else
            state.chosenFeatures.splice(listIndex, 1);
        state.chosenFeatures = state.chosenFeatures.filter(f => f.Id);

        state.chosenFeatures = state.chosenFeatures.filter((f, i) => {
            return state.chosenFeatures.indexOf(f) === i;
        });

        state.chosenFeatures.push({ Options: [] });
        setState({ chosenFeatures: state.chosenFeatures });
    }

    const setOptionEnable = (option, checked = false) => {
        option.enabled = checked;
        setState({ chosenFeatures: state.chosenFeatures });
    }

    const handleSubmit = () => {
        let features = state.chosenFeatures.slice(0, state.chosenFeatures.length - 1).map(f => {
            return {
                Id: f.Id,
                o: f.Options.filter(o => o.enabled).map(o => o.Id)
            };
        });
        let data = {
            t: props.tablet.Id,
            f: features
        };
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: data
        };
        axios("/api/dbtool/tabletfeatures", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Could not set tablet features");
            });
    };
    return (
        <>
            <IconButton aria-label="Edit tablet feature" component="span" onClick={handleClickOpen} >
                <Edit />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>{state.title}</DialogTitle>
                <DialogContent style={{ overflowY: "visible" }}>

                    <Grid container spacing={3}>
                        {(state.chosenFeatures.map((f, i) => (
                            <Grid item xs={12} key={i}>
                                <Autocomplete
                                    clearOnBlur={!f.Id}
                                    filterSelectedOptions
                                    style={{ minWidth: "290px" }}
                                    options={state.features}
                                    getOptionLabel={(option) => (option["Tag"] ? option["Tag"] : "")}
                                    getOptionSelected={(o, t) => { return o.Id === t.Id }}
                                    renderInput={(params) => <TextField {...params} label={f.Id ? "Feature" : "New feature"} />}
                                    onChange={(e, v) => changeSelectedFeature(i, v)}
                                    value={f.Id ? f : null}
                                />
                                {(f.Options.map((o, oi) => (
                                    <FormControlLabel
                                        key={o.Id}
                                        control={<Checkbox
                                            checked={!!o.enabled}
                                            onChange={(e) => setOptionEnable(o, e.target.checked)}
                                            color="primary" />}
                                        label={o.Tag}
                                    />
                                )))}
                            </Grid>
                        )))}

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        SAVE
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
