import axios, { AxiosRequestConfig } from "axios"


export default class HistoryService{
    static async getHistory(dateFrom:Date,dateTo:Date,field:string,prompt:string,deviceType:number,pageSize:number,pageNumber:number){
        try {
            const axiosOptions:AxiosRequestConfig ={
                method: 'GET',
                url: "/api/history",
                params:{dateFrom,
                    dateTo,
                    field,
                    prompt,
                deviceType,
            pageSize,
        pageNumber},
        timeout:30000
        
                
            }
            const response=await axios.request(axiosOptions)
            return await response.data;
        } catch (error) {

            return[]
        }
    }
    static async getFields(deviceType:number){
        try {
            const res=await axios.get("/api/history/fields",{params:{deviceType}})
            return res.data;
        } catch (error) {
            return[]
        }
        
    }    
}