import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { customersArraySelector, customersDtoSelector, usersSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { Customer, User } from "src/services/UserService";
import { setCustomersDto } from "src/redux/app/actions";
import ConfigurationsService from "src/services/ConfigurationsService";

type SalesRepInput = {
    customer: Customer | null;
    user: User | null;
};

export default function SalesRepWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        reset,
        setValue,
        watch,
    } = useForm<SalesRepInput>({
        defaultValues: { customer: null, user: null },
    });

    const customers = useSelector(customersArraySelector);
    const customersDto = useSelector(customersDtoSelector);
    const users = useSelector(usersSelector);
    const { displayToast } = useToast();

    
    const [salesRepWidgetLastCustomerId,setSalesRepWidgetLastCustomerId]=useLocalStorage("SalesRepWidgetLastCustomerId",-1)
    const [salesRepWidgetLastSalesRepId,setSalesRepWidgetLastSalesRepId]=useLocalStorage("SalesRepWidgetLastSalesRepId",-1)

    

    const onComplete = useCallback(
        (input: SalesRepInput) => {
            if (input.user != null)
                customersDto[input.customer!.id].salesRep = input.user.username;
            else
                customersDto[input.customer!.id].salesRep = null as any;
            setCustomersDto(customersDto);
            if (!saveChanges)
            reset();
            displayToast({
                message: `Successfully set sales rep of customer ${input.customer!.id}!`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [customersDto, displayToast, reset,saveChanges]
    );

    const updateSalesRep = useCallback((input: SalesRepInput) => {
            return ConfigurationsService.updateCustomerSalesRep(input.customer!.id, input.user?.username??"").then(() => input);
        }, []
    );

    const { exec, pending, error } = useAsync(
        updateSalesRep,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = pending || customers.length === 0 || users.length === 0;

    const customer = watch("customer");
    const user = watch("user");

    useEffect(()=>{
        if (saveChanges){
            
            if (salesRepWidgetLastCustomerId && salesRepWidgetLastCustomerId!==customer?.id)
            {
                const cst=customers.find(c=>c.id===salesRepWidgetLastCustomerId)
                if (cst)
                setValue('customer',cst);
                if (cst)
                    setValue("user", users.find(u => u.username === cst.salesRep) ?? null);
                else
                    setValue("user", null);
                
            }
            if (salesRepWidgetLastSalesRepId && salesRepWidgetLastSalesRepId>=0 && salesRepWidgetLastSalesRepId!==user?.customerId)
            setValue('user',users.find(u=>u.customerId===salesRepWidgetLastSalesRepId))
        }

    },[saveChanges,salesRepWidgetLastCustomerId,salesRepWidgetLastSalesRepId,customer,customers,setValue,user,users])

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Set customer sales rep"
            subTitle="Pick a service center user to be associated with a customer (or leave blank to dissociate)."
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <Controller
                name="customer"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            value={customer}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (val !== null)
                                    setValue("user", users.find(u => u.username === val.salesRep) ?? null);
                                else
                                    setValue("user", null);
                                if (saveChanges){
                                    setSalesRepWidgetLastCustomerId(val?.id??-1)
                                }    
                            }}
                            disabled={loading}
                            options={customers}
                            getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                            getOptionSelected={(a, b) => a.id === b.id}
                            noOptionsText="No such customer"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Customer"
                                        placeholder="Choose a customer"
                                        required
                                        {...getErrorProps(errors, "customer")}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
            <Controller
                name="user"
                control={control}
                rules={{}}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            value={user}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (saveChanges){
                                   setSalesRepWidgetLastSalesRepId(val?.customerId??-1)
                                }
                            }}
                            disabled={loading}
                            options={users}
                            getOptionLabel={(u) => {
                                const c =u.customerId.map(c => customersDto[c]) ;
                                return `(${u.username}) ${u.firstname??""} ${u.lastname??""} [${c.map(cc=>cc.name).join(',')}]`;
                            }}
                            noOptionsText="No such user"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Sales Rep"
                                        placeholder="Choose a service center user"
                                        {...getErrorProps(errors, "user")}
                                    />
                                );
                            }}
                            getOptionSelected={(a, b) => a.username === b.username}
                        />
                    );
                }}
            />
        </BaseWidget>
    );
}
