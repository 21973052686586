
import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OrangeButton from "../Components/OrangeButton.jsx"


export default function AssignServerDnsPopup(props) {
    const [open, setOpen] = React.useState(false);

    const initialState = {
        Robot: null,
        Robots: [],
        Server: null,
        Servers: []
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const updateData = () => {
        axios("/api/dbtool/servers")
            .then(response => response.data[0])
            .then(servers => {
                axios("/api/dbtool/robots")
                    .then(response => response.data[0])
                    .then(robots => {

                        robots.forEach(r => r.Name = ("tinybox" + r.RobotId));
                        servers.forEach(r => {
                            r.Secure = parseInt(r.Secure?.data ? r.Secure?.data[0]:0);
                            r.PrependHostname = parseInt(r.PrependHostname?.data?r.PrependHostname?.data[0]:0);
                            r.Tag = ((r.Secure ? "wss://" : "ws://") + (r.PrependHostname ? "[hostname]." : "") + r.Address + ":" + r.Port)
                            r.FriendlyName = r.Description ? r.Description : (r.Tag ? r.Tag : "")
                        });
                        servers = servers.sort((s1, s2) => {
                            if (s1.FriendlyName > s2.FriendlyName)
                                return 1;
                            else if (s1.FriendlyName < s2.FriendlyName)
                                return -1;
                            else
                                return 0;
                        });

                        let newState = {
                            Robots: robots,
                            Servers: servers,
                            Robot: null,
                            Server: null
                        }
                        setState(newState);
                    });
            });
    };


    const handleClickOpen = () => {

        // Update selects
        updateData();

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let data = {
            r: state.Robot.RobotId,
            s: state.Server.Id
        };

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        
        axios("/api/dbtool/robotaddress", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess) {
                        setTimeout(function () {
                            props.whenSuccess();
                        }, 1000);
                    }
                    handleClose();
                }
                else
                    alert("Could not assign tablet app version");
            });
    };

    return (

        <>
            <OrangeButton title="Assign server dns" onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'md'}
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Assign server dns</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Autocomplete
                                options={state.Robots}
                                getOptionLabel={(option) => (option["Name"] ? option["Name"] : "")}
                                getOptionSelected={(o, t) => { return o.RobotId === t.RobotId }}
                                renderInput={(params) => <TextField {...params} label="Robot" />}
                                onChange={(e, v) => setState({ Robot: v })}
                                value={state.Robot}
                                style={{ width: "150px" }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                options={state.Servers}
                                getOptionLabel={(option) => (option["FriendlyName"] ? option["FriendlyName"] : "")}
                                getOptionSelected={(o, t) => { return o.Server_AddressId === t.Server_AddressId }}
                                renderInput={(params) => <TextField {...params} label="Server" />}
                                onChange={(e, v) => setState({ Server: v })}
                                value={state.Server}
                                style={{ width: "500px" }}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        ASSIGN
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
