import { useState } from "react";
import { sleep } from "src/utils/common";

export default function useBatchPromise() {
    const [progress, setProgress] = useState(100);
    const setPromises = (promises : Array<Promise<void>>) => {
        const pending = new Array<boolean>(promises.length);
        for (let i = 0; i < promises.length; i++)
            promises[i].then(() => {pending[i] = true;});
        let count = 0;
        const temp = async () => {
            while (count < pending.length) {
                count = pending.filter((pr) => {return pr;}).length;
                setProgress(100 * count / pending.length)
                await sleep(100);
            }
        };
        temp();
    }
    return [progress, setPromises] as [number, (promises: Array<Promise<void>>) => void];
}