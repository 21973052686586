import React, { useCallback, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import PasswordField from "../../components/PasswordField";
import LoadingButton from "../../components/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import useFormStyles from "./useFormStyles";
import { useForm, Controller } from "react-hook-form";
import { authStatusSelector } from "../../redux/auth/selectors";
import { redirectedFromSelector } from "../../redux/router/selectors";
import { signIn } from "../../redux/auth/actions";
import { AuthenticationError } from "../../services/Errors";
import { Link as Link2 } from "react-router-dom";
import { Button } from "@material-ui/core";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://tinymobilerobots.com/">
                TinyMobileRobots
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

type SignInInputs = {
    username: string;
    password: string;
};

export default function SignIn() {
    const classes = useFormStyles();

    const dispatch = useDispatch();
    const { signingIn, authError,TFANeeded } = useSelector(authStatusSelector);
    const { message: redirectMessage } = useSelector(redirectedFromSelector);

    const { handleSubmit, errors, control } = useForm<SignInInputs>();

    const onSubmit = useCallback(
        ({ username, password }: SignInInputs) => {
            dispatch(signIn(username, password));
            },
        [dispatch]
    );
    
    const [triggerPrompt,setTriggerPrompt]=useState(false);
    const [code,setCode]=useState("")

    const onConfirm = useCallback(
        ({ username, password }: SignInInputs) => {
            dispatch(signIn(username, password,code));            
            },
        [dispatch,code]
    );


    useEffect(()=>{
        setTriggerPrompt(TFANeeded)
    },[TFANeeded])
    

    return (
        <Container component="main" maxWidth="xs">
            <Box
                display="flex"
                minHeight="50vh"
                flexDirection="column"
                justifyContent="flex-end"
            >
                <div className={classes.paper}>
                    {redirectMessage && (
                        <Box mb={1}>
                            <Typography align="center" color="error">
                                {redirectMessage}
                            </Typography>
                        </Box>
                    )}
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                    >
                        <Controller
                            name="username"
                            control={control}
                            defaultValue=""
                            rules={{ required: "This field is required" }}
                            render={(props) => (
                                <TextField
                                    {...props}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    autoComplete="username"
                                    autoFocus
                                    helperText={
                                        (errors.username &&
                                            errors.username.message) ||
                                        ""
                                    }
                                    error={!!errors.username}
                                    disabled={signingIn}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{ required: "This field is required" }}
                            render={(props) => (
                                <PasswordField
                                    {...props}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Password"
                                    id="password"
                                    autoComplete="current-password"
                                    helperText={(errors.password && errors.password.message) || ""}
                                    error={!!errors.password}
                                    disabled={signingIn}
                                />
                            )}
                        />
                        {authError && (
                            <Box mt={2} textAlign="center">
                                <Typography color="error">
                                    {(authError instanceof
                                        AuthenticationError &&
                                        authError.message) ||
                                        "An unknown error occured. Please try again."}
                                </Typography>
                            </Box>
                        )}
                        <LoadingButton
                            className={classes.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={signingIn}
                        >
                            Sign In
                        </LoadingButton>
                        <Link2 to={"/account/forgotpassword"}><Typography>forgot my password</Typography></Link2>

                        {triggerPrompt && <Box style={{display:"flex",justifyContent:"space-between",marginTop:"30px"}}>
                                     <TextField
                                        type="text"
                                        name="token"
                                        id="token"
                                        //inputMode="numeric"
                                        autoComplete="one-time-code"
                                        style={{width:"150px"}}
                                        variant="outlined"
                                        label="2FA code"
                                        onChange={(e)=>setCode(e.target.value)}
                                        />
                                    <Button variant="contained" color="primary" style={{width:"150px"}} onClick={handleSubmit(onConfirm)}>Confirm</Button>

                                 </Box>}
                    </form>
                   
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Box>
        </Container>
    );
}
