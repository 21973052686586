import { useEffect, useRef, useState } from "react";

export default function useScrollbarWidth(firstChild = false) {
    const ref = useRef<HTMLDivElement>();
    const [width, setWidth] = useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        let div: any = ref.current;
        if (firstChild) {
            div = div?.firstElementChild;
        }
        if (div && div.offsetWidth !== undefined) {
            const scrollbarWidth = div.offsetWidth - div.clientWidth;
            setWidth(scrollbarWidth);
        }
    });

    return { ref, width };
}
