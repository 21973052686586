import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { setCustomersDto } from "src/redux/app/actions";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Customer } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { customersDtoSelector, customersArraySelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";

type SetCustomerNameInput = {
    name: string;
    customer: Customer | null;
};

function setCustomerName(input: SetCustomerNameInput) {
    return ConfigurationsService.setCustomerName({
        name: input.name,
        customerId: input.customer!.id,
    }).then(() => input);
}

export default function CustomerNameWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<SetCustomerNameInput>({
        defaultValues: { customer: null, name: "" },
    });

    const { displayToast } = useToast();
    const customersDto = useSelector(customersDtoSelector);

    const name = watch("name");
    const customer = watch("customer");

    const [customerNameWidgetLastCustomerId,setCustomerNameWidgetLastCustomerId]=useLocalStorage("CustomerNameWidgetLastCustomerId",-1)

    
    useEffect(() => {
        register("customer", defaultRules);
        register("name", defaultRules);
    }, [register]);

    
    const onComplete = useCallback(
        (input: SetCustomerNameInput) => {
            if (input.customer != null) {
                input.customer.name = input.name
                customersDto[input.customer.id].name = input.customer.name;
                setCustomersDto(customersDto);
            }
            if (!saveChanges)
            reset();
            displayToast({
                message: `Successfully set the name of Customer ${
                    input.customer!.id
                } to ${input.name}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast, reset, customersDto,saveChanges]
    );

    const { exec: setName, pending: settingName, error } = useAsync(
        setCustomerName,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const customers : Customer[] = useSelector(customersArraySelector)!.filter((customer_) => {return customer_.id !== 1;});

    useEffect(()=>{
        if (saveChanges){
       
        if (customerNameWidgetLastCustomerId && customerNameWidgetLastCustomerId!==customer?.id)
        {
            const cstmr=customers.find(c=>c.id===customerNameWidgetLastCustomerId)
            if (cstmr){
                setValue('customer',cstmr)
              setValue('name',cstmr?.name)
            }
            
        }
        }
    },[customerNameWidgetLastCustomerId,customer,customers,setValue,saveChanges])


    const loading = settingName || customers.length === 0;

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Change customer name"
            subTitle={"Change the name of your customers. \n PLEASE USE LEGAL NAME"}
            onSubmit={handleSubmit(setName)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <AutocompleteV2
                selectOnTab
                value={customer}
                disabled={loading}
                options={customers}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such customer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Customer"
                            placeholder="Choose a customer"
                            required
                            {...getErrorProps(errors, "customer")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("name", val?.name ?? "");
                    setValue("customer", val);

                    if (saveChanges){setCustomerNameWidgetLastCustomerId(val?.id ?? -1)}}}
            />
            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                label="Name"
                placeholder="Choose a new name"
                value={name}
                onChange={(ev) => {
                    setValue("name", ev.target.value);
                }}
                {...getErrorProps(errors, "name")}
            />
        </BaseWidget>
    );
}
