import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { AutoSizer } from "react-virtualized";
import UncontrolledDialog from "src/components/UncontrolledDialog";
import { AllDataTable, LatestDataTable, TabletConnectionsTable, TableProps, LayoutsTable } from "./Tables";
import { useSection } from "./SectionProvider";
import deviceName from "src/utils/deviceName";
import { DeviceType } from "src/utils/constants";
import TabletOptions from "./TabletOptions";
import { useSelector } from "react-redux";
import useDialog from "src/hooks/useDialog";
import DeviceDataService from "src/services/DeviceDataService";
import { useToast } from "src/hooks";
import { toastError } from "src/utils/common";
import HistoricDiagram from "./HistoricDiagram";
import Commands from "./Commands";
import { userAccessSelector } from "src/redux/auth/selectors";
import { customersDtoSelector, customersSelector } from "src/redux/app/selectors";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(0.5),
        "& button, & a": {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    deleteButton: {
        backgroundColor: "#9f0000",
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: "#e80000",
        },
    },
}));

type TableModalType = "all" | "latest" | "tabletConnections" | "layouts";

const tableModals: Record<
    TableModalType,
    {
        Table: (props: TableProps) => JSX.Element;
        header: string;
        info: (deviceTypeId: number, deviceId: number,customer: string) => string;
    }
> = {
    all: {
        Table: AllDataTable,
        header: "Historic data",
        info(type: number, id: number) {
            return `Browse all data associated with ${deviceName(
                type
            ).toLowerCase()} ${id}`;
        },
    },
    latest: {
        Table: LatestDataTable,
        header: "Latest data",
        info(type: number, id: number) {
            return `Browse latest data associated with ${deviceName(
                type
            ).toLowerCase()} ${id}`;
        },
    },
    tabletConnections: {
        Table: TabletConnectionsTable,
        header: "Tablet connection history",
        info(_type: number, id: number) {
            return `Browse tablets that have connected to robot ${id}`;
        },
    },
    layouts: {
        Table: LayoutsTable,
        header: "Layouts history",
        info(_type: number, id: number,customer: string) {
            return `Browse layouts that have been layed by customer ${customer}`;
        },
    },
};

interface TableDialogProps {
    deviceTypeId: number;
    deviceId: number;
    type: TableModalType;
    customerId: number;
}

export const TableDialog = React.memo(function TableDialog(props: TableDialogProps) {
    const { type, deviceId, deviceTypeId,customerId } = props;
    const customerName=useSelector(customersDtoSelector)[customerId]?.name
    const { header, info, Table } = tableModals[type];
    const infoMessage = info(deviceTypeId, deviceId,customerName);
    const deleteDataAccess=useSelector(userAccessSelector).includes('DeleteData')
    const classes = useStyles();
    const { displayToast } = useToast();
    const { displayDialog } = useDialog();

    const clearData = useCallback(
        async () => {
            if (await displayDialog({dialogText: "Are you sure you want to clear the historic data of this device?"})) {
                await DeviceDataService.deleteHistoricData(deviceTypeId, deviceId).then(() => {
                    displayToast({
                        message: `Historic data successfully deleted!`,
                        severity: "success",
                        withCloseIcon: true,
                    });
                }).catch(toastError(displayToast));
            }
        },
        [displayToast, displayDialog, deviceTypeId, deviceId]
    );

    return (
        <UncontrolledDialog
            fullHeight
            fullWidth
            maxWidth="lg"
            Button={({ onClick }) => (
                <Box p={0.5}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={onClick}
                    >
                        {header}
                    </Button>
                </Box>
            )}
        >
            {{
                title: header,
                content: (
                    <>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                            <DialogContentText>{infoMessage}</DialogContentText>
                            {(type === "all" && deleteDataAccess) && <Button className={classes.deleteButton} onClick={clearData}>Clear Data</Button>}
                        </div>
                        <AutoSizer disableWidth>
                            {(p) => <Table {...p} customerId={customerId}/>}
                        </AutoSizer>
                    </>
                ),
            }}
        </UncontrolledDialog>
    );
});

export default React.memo(function DialogBar() {
    const classes = useStyles();
    const { deviceId, deviceTypeId, data } = useSection();
    const customerId=Number(data['customer_id']?.value)

    let childKeys: TableModalType[];
    switch (deviceTypeId) 
    {
        case 1:
        childKeys=["latest", "all", "tabletConnections"]; break;
        case 2:
        childKeys=["latest", "all", "layouts"]; break;
        default:
        childKeys=["latest", "all"]; break;
    }
    
    const commandsAcceess=useSelector(userAccessSelector).includes('Commands')
    const commandsSuperAccess=useSelector(userAccessSelector).includes('CommandsSuper')

    return (
        <div className={classes.root}>
            {childKeys.map((type) => (
                <TableDialog
                    key={type}
                    type={type}
                    deviceId={deviceId}
                    deviceTypeId={deviceTypeId}
                    customerId={customerId}
                />
            ))}
            {deviceTypeId === DeviceType.Tablet && <TabletOptions />}
            <HistoricDiagram />
            {((deviceTypeId===DeviceType.Robot || (deviceTypeId===3 && data && data["Robot type"]?.value.includes("BS")) )&& commandsAcceess) && <Commands atLeastSupport={commandsSuperAccess}/>}
        </div>
    );
});
