import axios from "axios";
import { TranslationsDictionary } from "src/views/Devices/TranslationProvider";
import { handleError } from "./Errors";

interface ZendeskComment {
    created_at: number,
    author_id?: number,
    body: string,
}

export interface ZendeskTicketPost {
    subject: string,
    category: string,
    body: string,
    requester_username?:string
}

interface ZendeskTicket {
    id: number,
    external_id?: number,
    subject: string,
    category: string,
    status: string,
    requester_id?: number,
    assignee_id?: number,
    organization_id?: number,
    robot_id?: number,
    tablet_id?: number,
    created_at: number,
    updated_at: number,
    comments: Array<ZendeskComment>,
}

export interface SupportIssue {
    id: number
    subject: string,
    category: string,
    status: string,
    requester: string,
    requester_phone: string,
    requester_email: string,
    assignee: string,
    assignee_phone: string,
    assignee_email: string,
    organization: string,
    robot_id?: number,
    robot_model: string,
    tablet_id?: number,
    customer_id?: number,
    dealer_id?: number,
    created_at: number,
    updated_at: number,
    comments: Array<SupportComment>,
}

export interface SupportComment {
    issueId?: number,
    created_at: number,
    author: string,
    body: string,
}

export interface SupportFilterOptions {
    skip: number;
    limit: number;
    orderBy?: [
        keyof Omit<ZendeskTicket, "comments">,
        "desc" | "asc"
    ][];
    notBefore?: moment.Moment;
    notAfter?: moment.Moment;
    timestampFilterColumn?: "updated_at" | "created_at";
    globalSearch?: string;
    robotId?: number;
    tabletId?: number;
}

export interface FilterResult<Data, Filter> {
    filter: Omit<Filter, "limit" | "skip">;
    pageOptions: { skip: number; limit: number };
    filteredCount: number;
    totalCount: number;
    pageCount: number;
    data: Data[];
}

type LatestDataResult<T> = {
    data: T[];
    keys: string[];
    dictionary: TranslationsDictionary;
};

export interface SupportDto {
    issueId: number;
    data: {
        [key: string]: string;
    };
}

export default class SupportService {

    static async getLatestSupportIssue(): Promise<LatestDataResult<SupportDto>> {
        try {
            const response : LatestDataResult<SupportDto> = (await axios.get("/api/support/latest",{timeout: 60000})).data as any;
            for (const key in response.dictionary)
                if (response.dictionary[key].translations === undefined)
                    response.dictionary[key].translations = {};
            return response;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async postClosedDeviceTicket(deviceTypeId: number, deviceId: number, ticket: ZendeskTicketPost): Promise<SupportIssue> {
        try {
            if (deviceTypeId===1)
            (ticket as any).robot_id = deviceId;
            if (deviceTypeId===2)
            (ticket as any).tablet_id = deviceId;
            const response = await axios.post(`/api/support/${deviceTypeId}/${deviceId}`,ticket,{timeout: 60000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async postClosedCustomerTicket(customerId: number, ticket: ZendeskTicketPost): Promise<SupportIssue> {
        try {
            (ticket as any).customer_id = customerId;
            const response = await axios.post(`/api/support/customer/${customerId}`,ticket,{timeout: 30000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getDeviceLogbookHistory(deviceTypeId: number, deviceId: number): Promise<SupportIssue[]> {
        try {
            const response = await axios.get(`/api/support/${deviceTypeId}/${deviceId}`);
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getSupportIssueById(
        id: number
    ): Promise<SupportIssue> {
        try {
            const response = await axios.get("/api/support/"+id,{timeout: 60000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }
}