/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useRef, useState, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { FilterProps } from "react-table";
import { useDebounced, usePrevious, useGetLatest } from "src/hooks";
import _ from "lodash";

export interface DebouncedColumnFilterOptions<T> {
    filterValue: T;
    setFilter: (t: T | null) => void;
    clearedValue?: any;
    renderOnFilterChange?: boolean;
    delay?: number;
}

export function useDebouncedColumnFilter<T>(
    options: DebouncedColumnFilterOptions<T>
) {
    const {
        filterValue,
        setFilter,
        clearedValue = "",
        delay = 250,
        renderOnFilterChange = false,
    } = options;

    const stateRef = useRef<{
        firstRender: boolean;
        filterValue: T;
        setFilter: typeof setFilter;
        clearedValue: any;
    }>({ ...{ firstRender: true, filterValue, setFilter, clearedValue } });

    const getRenderOnFilterChange = useGetLatest(renderOnFilterChange);
    const [, forceRerender] = useState(0);
    const [controlledFilter, setControlledFilter] = useState<T>(
        filterValue || clearedValue
    );

    useEffect(() => {
        stateRef.current.setFilter = setFilter;
    });

    useEffect(() => {
        stateRef.current.filterValue = filterValue;
    }, [filterValue]);

    const previousFilterValue = usePrevious(filterValue);
    useEffect(() => {
        const { clearedValue } = stateRef.current;
        if (
            (filterValue === undefined ||
                filterValue === null ||
                _.isEqual(filterValue, clearedValue)) &&
            !_.isEqual(previousFilterValue, filterValue) &&
            !_.isEqual(controlledFilter, clearedValue)
        ) {
            setControlledFilter(clearedValue);
        }
    }, [filterValue, previousFilterValue, controlledFilter]);

    const debouncedControlledFilter = useDebounced(controlledFilter, delay);
    useEffect(() => {
        const {
            firstRender,
            filterValue,
            setFilter,
            clearedValue,
        } = stateRef.current;
        if (
            !firstRender &&
            debouncedControlledFilter !== filterValue &&
            !(
                debouncedControlledFilter === clearedValue &&
                filterValue === null
            )
        ) {
            setFilter(debouncedControlledFilter || null);
            if (getRenderOnFilterChange()) {
                forceRerender((s) => (s+1));
            }
        }
    }, [getRenderOnFilterChange, debouncedControlledFilter]);

    useEffect(() => {
        stateRef.current.firstRender = false;
    }, []);

    return {
        value: controlledFilter,
        setValue: setControlledFilter,
    };
}

export function SearchColumnFilter<T extends object>({
    column,
    type,
}: FilterProps<T> & { type?: "number" }) {
    const { filterValue, setFilter, render } = column;
   
    return filterValue? filterValue.map((fv:any,index:number)=>{
        return  <TextField
        margin="dense"
        size="small"
        fullWidth
        value={fv}
        variant="outlined"
        label={render("Header")}
        onChange={(e:any)=>{
                const f=e.target.value
                return setFilter((prev:any)=>{
                    if (prev && prev[index])
                    {prev[index]=(f)
                    return prev}
                    else if (prev)
                    return [...prev, (f) ]
                    else if (f)
                    return [(f)]
                });
            }

        }
        key={index}
        type={type}
        InputProps={{
            endAdornment:fv && (
                <InputAdornment position="end">
                    <IconButton
                        size="small"
                        edge="end"
                        onClick={() => {
                            filterValue[index]=null
                            setFilter(filterValue.filter((fv:any)=>fv));
                        }}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    ></TextField>
    }) :(
        <TextField
            margin="dense"
            size="small"
            fullWidth
            value={filterValue}
            variant="outlined"
            label={render("Header")}
            onChange={(e:any)=>{
                const f=e.target.value
                setFilter([f]);
            }
}
            type={type}
            InputProps={{
                endAdornment: filterValue && (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            edge="end"
                            onClick={() => {
                                setFilter(null);                           
                             }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        ></TextField>
    );
}

export function NumberColumnFilter<T extends object>(props: FilterProps<T>) {
    return <SearchColumnFilter {...props} type="number" />;
}
