/* eslint-disable @typescript-eslint/no-var-requires */
import { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import type { ThemeOptions } from "@material-ui/core/styles";

let createMuiTheme;

if (process.env.NODE_ENV === "production") {
    createMuiTheme = require("@material-ui/core/styles").createMuiTheme;
} else {
    createMuiTheme = require("@material-ui/core/styles")
        .unstable_createMuiStrictModeTheme;
}

declare module "@material-ui/core/styles/createMuiTheme" {
    interface Theme {
        tmrPalette: {
            grey: CSSProperties["color"];
            lightGrey: CSSProperties["color"];
            ligtherGrey: CSSProperties["color"];
            whiteGrey: CSSProperties["color"];
        };
        tmrOptions: {
            drawerWidth: number;
            keyFrames: {
                defaultShow: string;
                defaultGrow: string;
            };
        };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        tmrPalette?: {
            grey: CSSProperties["color"];
            lightGrey: CSSProperties["color"];
            ligtherGrey: CSSProperties["color"];
            whiteGrey: CSSProperties["color"];
        };
        tmrOptions?: {
            drawerWidth: number;
            keyFrames: {
                defaultShow: string;
                defaultGrow: string;
            };
        };
    }
}

export const keyFrames = {
    defaultShow: "default-show",
    defaultGrow: "default-grow",
};

export const useGlobalStyles = makeStyles(() => ({
    "@global": {
        "@keyframes default-show": {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        },
        "@keyframes default-grow": {
            from: {
                transform: "scale(0.75)",
            },
            to: {
                transform: "scale(1)",
            },
        },
        ".MuiInputBase-input": {
            lineHeight:"1.3"
        }
        
    },
    
}));

export default createMuiTheme({
    spacing: 8,
    palette: {
        primary: {
            main: "#00648a",
            light: "#66a2b9",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#f26600",
            light: "#f7a366",
            contrastText: "#ffffff",
        },
        common: {
            white: "#ffffff",
            black: "#3C3C3C",
        },
    },
    tmrPalette: {
        grey: "#6F6B6A",
        lightGrey: "#C6C6C6",
        ligtherGrey: "#e8e8e8",
        whiteGrey: "#f4f4f4",
    },
    tmrOptions: {
        drawerWidth: 250,
        keyFrames,
    },
} as ThemeOptions);
