import {  Button, MenuItem, Select, TextField, makeStyles } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutocompleteV2 from "src/components/AutocompleteV2";
import HistoryService from "src/services/HistoryService";

const useStyles = makeStyles(() => ({
    searchForm: { display: "flex", gap: 50,padding:10,alignItems:"center", flexWrap:"wrap"} ,
    autocomplete: {width:350},
    inputHeight: {height:40,padding:0,"& input":{padding:10}},
}))

interface FieldType{
    name: string;
    translated_name: string;
    device_key_group_id:number;
}


export function SearchForm(props:any){

    const classes=useStyles()

    const {fetchData,clearTable}=props
    const [fieldOptions,setFieldOptions]=useState({robot:[] as FieldType[],tablet:[] as FieldType[],station:[] as FieldType[]});
    const getFieldOptions=(deviceType:number)=>{
        if (deviceType===1)
        return fieldOptions.robot
        if (deviceType===2)
        return fieldOptions.tablet
        if (deviceType===3)
        return fieldOptions.tablet
        return []
    }
    const defaultDateTo=new Date();
    const defaultDateFrom=new Date();
    defaultDateFrom.setDate(defaultDateFrom.getDate()-7)

    const [prompt,setPrompt]=useState('');
    const [type,setType]=useState(1)
    const [field,setField]=useState('')
    const [dateFrom,setDateFrom]=useState(defaultDateFrom);
    const [dateTo,setDateTo]=useState(defaultDateTo); 

    function sortKeyGroupFirst(fields: FieldType[]){
        return (fields).sort((a,b)=>{
            if (!a.device_key_group_id && b.device_key_group_id){
                
                return 1
            }else if (a.device_key_group_id && !b.device_key_group_id)
            return -1
            
            return 0

        });
    }
    
    useEffect(()=>{
        const setFields=async ()=>{
            const fieldsRBlackList=['system_message_1','system_message_2','system_message_3','system_message_4','system_message_5','Top state','TOPSTATE','deprecated']
            
            let fieldsR=await HistoryService.getFields(1);
            let fieldsT=await HistoryService.getFields(2);
            let fieldsS=await HistoryService.getFields(3);

            fieldsR=(fieldsR as FieldType[]).filter((e)=>!fieldsRBlackList.find((el)=>el===e.name))
            
            fieldsR=sortKeyGroupFirst(fieldsR as FieldType[]);
            fieldsT=sortKeyGroupFirst(fieldsT as FieldType[]);
            fieldsS=sortKeyGroupFirst(fieldsS as FieldType[]);
            
            setFieldOptions({robot:fieldsR as FieldType[],tablet:fieldsT as FieldType[],station: fieldsS as FieldType[]})

            
        }
        setFields()
    },[])

    const handleSearch=async(e:Event)=>{
        e.preventDefault();
         await fetchData(field,prompt,true,type,dateFrom,dateTo,0);
    }
    async function  handleTypeChange(event:SelectChangeEvent){
        const target=event.target as HTMLSelectElement;    
        setType(Number(target.value))
        setField('');
        setPrompt('');
        clearTable()
      
    }

    return (
    <form className={classes.searchForm}>
        
        <TextField variant="outlined" type="text" placeholder="Search" onChange={(event)=>{setPrompt(event.target.value)}} value={prompt} className={classes.inputHeight}></TextField>
        <Select variant="outlined" onChange={(event)=>handleTypeChange(event as SelectChangeEvent)} defaultValue={1} className={classes.inputHeight}  style={{width:150}}>
            <MenuItem value={1}>Robot</MenuItem>
            <MenuItem value={2}>Tablet</MenuItem>
            <MenuItem value={3}>Reference Station</MenuItem>
        </Select>
        <AutocompleteV2
            
            value={field===''?null:field}
            options={getFieldOptions(type).map(option=>option.name)}
            fullWidth={false}
            noOptionsText="No such field"
            renderInput={params => 
                {

                 return <TextField
                    {...params}
                    variant="outlined"
                    margin="dense"
                    fullWidth={false}
                    className={classes.autocomplete}
                    label="Field search"
                 />
                }
            }
            loading={getFieldOptions(type).length === 0}
            renderOption={(name)=>{return <p>{getFieldOptions(type).find(f=>f.name===name)?.translated_name ?? name }</p>}}
            onChange={(a,value)=>setField(value as string)}
            onInputChange={(a)=>clearTable()}
            getOptionLabel={(name)=>{ return getFieldOptions(type).find(f=>f.name===name)?.translated_name ?? name}}
            ></AutocompleteV2>

            <DateTimePicker  style={{width: "auto", marginRight: "10px"}}
                                    disableFuture
                                    fullWidth
                                    label="From"
                                    margin="dense"
                                    inputVariant="outlined"
                                    value={dateFrom}
                                    onChange={(date:MaterialUiPickersDate) => {
                                        if (date!=null){
                                       
                                        setDateFrom(date.toDate())

                                        }
                                    }}
                                    ampm={false}
                                    strictCompareDates
                                />
            <DateTimePicker  style={{width: "auto", marginRight: "10px"}}
                                    disableFuture
                                    fullWidth
                                    label="To"
                                    margin="dense"
                                    inputVariant="outlined"
                                    value={dateTo}
                                    onChange={(date:MaterialUiPickersDate) => {
                                        if (date!=null){
                                        
                                        setDateTo(date.toDate())

                                        }
                                       
                                       
                                    }}
                                    ampm={false}
                                    strictCompareDates
                                />                    

        <Button variant="outlined" type="submit" onClick={(e)=>handleSearch(e)} disabled={field===''}>Search</Button>

    </form>)

}