import { FieldError } from "react-hook-form";
import get from "lodash/get";

/**
 * Utility function to be used in context of 'react-hook-form'. Maps appropriate error properties for a given field.
 * Third argument can be used to specify return value property names.
 */
function getErrorProps<
    T extends Record<string, FieldError | undefined>,
    K extends keyof T
>(errors: T, name: K, opts = { message: "helperText", error: "error" }) {
    const err = get(errors, name);
    return {
        [opts.error]: err !== undefined,
        [opts.message]: err?.message,
    };
}

export default getErrorProps;
