import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import dateFormat from 'dateformat';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import DeprecateIcon from '@material-ui/icons/Archive';

import EditAppVersionConfigurationPopup from "./popups/EditAppVersionConfigurationPopup.jsx";
import moment from 'moment';

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

function TagCell(props) {
    let opt = props.options;
    return (<>
        <span key={opt.Id}>{opt.Tag}</span>
    </>
    );
}

function AppVersionConfigurationCell(props) {
    let now_s = dateFormat(new Date(), "yyyy-mm-dd");
    return (<>
        {props.data.Configations.map((conf, i) => {
            let period_lines = conf.From.map((a, i) => {
                if (conf.To[i] && (conf.To[i] < now_s))
                    return <div key={i} style={{ color: "yellow" }}>Available from: {conf.From[i]} to {(conf.To[i] ? conf.To[i] : "indefinitely")}<br /></div>
                else
                    return <div key={i}>Available from: {conf.From[i]} to {(conf.To[i] ? conf.To[i] : "indefinitely")}<br /></div>
            });
            let tooltopText = (
                <div>{period_lines}<br />
                    Priority: {conf.Priority}<br />
                    {conf.IsRC ? "This is released as an RC version" : ""} </div>);

            return (
                <EditAppVersionConfigurationPopup
                    key={conf.ConfigurationId}
                    config={conf}
                    parentData={props.data}
                    tooltip={tooltopText}
                    chipLabel={conf.AccessGroupsLabel}
                    whenSuccess={props.whenSuccessEdit}
                    chipStyleBtn
                />
            )
        })}
        <EditAppVersionConfigurationPopup
            chipLabel="+"
            whenSuccess={props.whenSuccessEdit}
            tooltip={"Create new feature configuration"}
            parentData={props.data}
            chipStyleBtn
            noIcon
            accessGroupsSaveId={"create_new_access_groups_for_app_version"}
        />
    </>);
}

class ManageAppVersionsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            app_versions: [],
            configurations: [],
            only_show_active: true,
            hide_depricated: true,
            versions_on_confs: false,
            only_show_release_versions: true,
        };
        this.refreshAppVersions = this.refreshAppVersions.bind(this);

        this.headers = [{
            Header: 'Tag',
            key: 'TagElement',
            align: "left",
            style: small_style,
            search: "Tag"
        },
        {
            Header: 'Deprecated',
            key: 'Deprecated',
            align: "left",
            style: small_style,
            search: "Tag"
        },
        {
            Header: 'Uploaded',
            key: 'Uploaded',
            align: "left",
            style: small_style,
            search: "Tag"
        },
        {
            Header: 'App version configuration',
            key: 'AppVersionConfigurationElement',
            align: "left",
            search: "Configations",
            ArraySeach: "AccessGroups",
            style: big_style
        },
        {
            Header: '',
            key: 'DeprecateElement',
            align: "right",
            style: small_style
        }


        ];

        this.confs_headers = [
            {
                Header: 'App version configuration',
                key: 'ConfLabel',
                align: "left",
                style: small_style,
                search: "ConfLabel"
            },
            {
                Header: 'Is RC',
                key: 'is_rc',
                align: "left",
                style: small_style
            },
            {
                Header: 'App version configuration',
                key: 'AppVersionConfigurationElement',
                align: "left",
                search: "Configations",
                ArraySeach: "AccessGroups"
            }
        ]
    }

    componentDidMount() {
        this.refreshAppVersions()
    }

    refreshAppVersions(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/appversion_accessgroups_collections`)
            .then(response => response.data[0])
            .then(state => {

                let dict = {};
                let confs_dict = {};
                let used_access_groups = {};
                let confi_id_to_label = {};
                for (let a of state) {
                    if (!dict[a.AppVersionId]) {
                        dict[a.AppVersionId] = { Tag: a.AppVersionTag, Id: a.AppVersionId, Deprecated:a.Deprecated? "Yes" : "", Uploaded: a.Uploaded.startsWith('0')? a.Uploaded: moment(a.Uploaded).format("YYYY-MM-DD hh:mm:ss") ,Configations: {} };
                        used_access_groups[a.AppVersionId] = {};
                    }
                    if (a.AccessGroupsCollectionId) {
                        let confi_id = a.AccessGroupsCollectionId + "_" + a.IsRC;
                        let add_time_frame = false;
                        if (!dict[a.AppVersionId].Configations[confi_id]) {
                            dict[a.AppVersionId].Configations[confi_id] = { IsRC: a.IsRC, Priority: a.Priority, AccessGroupsCollectionId: a.AccessGroupsCollectionId, ConfigurationId: a.ConfigurationId, From: [], To: [], AccessGroups: [] };
                            used_access_groups[a.AppVersionId][confi_id] = [];
                            add_time_frame = true;
                        }
                        if ((used_access_groups[a.AppVersionId][confi_id].indexOf(a.AccessGroupId) === -1)) {
                            dict[a.AppVersionId].Configations[confi_id].AccessGroups.push({ Id: a.AccessGroupId, Short: a.ShortName, Long: a.Name });
                            used_access_groups[a.AppVersionId][confi_id].push(a.AccessGroupId);

                            if (dict[a.AppVersionId].Configations[confi_id].AccessGroupsLabel)
                                dict[a.AppVersionId].Configations[confi_id].AccessGroupsLabel += ","
                            else
                                dict[a.AppVersionId].Configations[confi_id].AccessGroupsLabel = ""
                            dict[a.AppVersionId].Configations[confi_id].AccessGroupsLabel += a.ShortName
                            confi_id_to_label[confi_id] = dict[a.AppVersionId].Configations[confi_id].AccessGroupsLabel;
                        } else
                            add_time_frame = true;

                        if (add_time_frame) {
                            dict[a.AppVersionId].Configations[confi_id].From.push(a.From.split("T")[0]);
                            if (a.To)
                                dict[a.AppVersionId].Configations[confi_id].To.push(a.To.split("T")[0]);
                            else
                                dict[a.AppVersionId].Configations[confi_id].To.push("");
                        }


                        if (!confs_dict[confi_id])
                            confs_dict[confi_id] = [];
                        if (confs_dict[confi_id].indexOf(a.AppVersionId) === -1)
                            confs_dict[confi_id].push(a.AppVersionId)

                    }
                }

                let app_versions = [];
                for (let v in dict) {
                    let confs = [];
                    for (let c in dict[v].Configations) {
                        confs.push(dict[v].Configations[c]);
                    }
                    dict[v].Configations = confs;
                    app_versions.push(dict[v]);
                }
                app_versions.reverse();

                let configurations = [];
                for (let c in confs_dict) {
                    let p = c.split("_");
                    let AccessGroupsCollectionId = parseInt(p[0])
                    let is_rc = p[1] === "1";
                    let ConfLabel = confi_id_to_label[c];

                    let AppVersionIds = confs_dict[c];
                    let AppVersions = AppVersionIds.map(AppVersionId => {
                        let AppVersionTag = dict[AppVersionId].Tag;
                        return { AppVersionId: AppVersionId, AppVersionTag: AppVersionTag };
                    });

                    configurations.push({
                        ConfLabel: ConfLabel,
                        AccessGroupsCollectionId: AccessGroupsCollectionId,
                        is_rc: is_rc,
                        app_versions: AppVersions
                    });
                }

                
                this.setState({
                    app_versions: app_versions.map((r, i) => {
                        r.TagElement = (<TagCell options={{ Tag: r.Tag, Id: r.Id }} app_version={r} refreshAppVersions={this.refreshAppVersions} />);
                        r.AppVersionConfigurationElement = (<AppVersionConfigurationCell data={r} whenSuccessEdit={this.refreshAppVersions} />);
                        r.DeprecateElement = r.Deprecated ? <></> : <IconButton component="span" onClick={this.deprecateVersion.bind(this, r.Id)} ><DeprecateIcon /></IconButton>;
                        return r;
                    }),
                    configurations: configurations
                });

            });
    }

    deprecateVersion(AppVersionId) {
        axios.delete("/api/dbtool/deprecateappversion?ai="+AppVersionId)
            .then(response =>{return response.data})
            .then(state => {
                if (state.success) {
                    this.refreshAppVersions();
                }
                else
                    alert("Could not deprecate appversion");
            });
    }

    render() {
        let showAppVersions = this.state.app_versions;
        if (this.state.only_show_active) {
            showAppVersions = showAppVersions.filter(f => {
                return f.Configations.length;
            });
        }
        if (this.state.hide_depricated) {
            showAppVersions = showAppVersions.filter(f => {
                return f.Configations.length || !f.Deprecated;
            });

        }
        if (this.state.only_show_release_versions) {
            showAppVersions = showAppVersions.filter(f => {
                return f.Configations.length || f.Tag.match(/^v\d+\.\d+\.\d+$/);
            });
        }



        return (
            <React.Fragment>

                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.versions_on_confs}
                            onChange={event => this.setState({ versions_on_confs: event.target.checked })}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="versions on configurations"
                /> */}
                <br></br>

                {!this.state.versions_on_confs ?
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.only_show_active}
                                    onChange={event => this.setState({ only_show_active: event.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Only show used app versions"
                        />
                        <br></br>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.hide_depricated}
                                    onChange={event => this.setState({ hide_depricated: event.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Hide deprecated"
                        /> <br></br>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.only_show_release_versions}
                                    onChange={event => this.setState({ only_show_release_versions: event.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Only show release versions"
                        />
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                }

                <br></br>
                {
                    this.state.versions_on_confs ?
                        <Table id="app_versions_table" headeres={this.confs_headers} data={this.state.configurations} refresh={this.refreshAppVersions} />
                        :
                        <Table id="configurations_table" headeres={this.headers} data={showAppVersions} refresh={this.refreshAppVersions} />
                }


            </React.Fragment>
        );
    }
}

export default ManageAppVersionsSection;