import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
    paper: {
        height: "100%",
        maxHeight: "100%",
        marginTop: "0px",
        marginBottom: "0px",
    },
    title: {
        display: "flex",
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        alignContent: "center",
        "& > :first-child": {
            flex: 1,
        },
        "& > :last-child": {
            marginTop: "auto",
            marginBottom: "auto",
        },
    },
    closeButton: {
        color: theme.palette.primary.contrastText,
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

export interface UncontrolledDialogProps
    extends Pick<DialogProps, "maxWidth" | "fullWidth"> {
    children: {
        title: React.ReactNode;
        content: React.ReactNode;
        actions?: React.ReactNode;
    };
    Button: React.ElementType<{ onClick: () => void }>;
    className?: string;
    fullHeight?: boolean;
    closeOnClickAway?: boolean;
    onClose?: () => void;
}

const titleId = "uncontrolled-modal-title";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
export default function UncontrolledDialog(
    props: UncontrolledDialogProps
) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const { children, Button, className, fullHeight = false, closeOnClickAway = true, onClose = () => {/*noop*/}, ...rest } = props;
    const { title, content, actions } = children;

    const close = useCallback(() => {onClose();setOpen(false);}, [onClose]);
    const toggle = useCallback(() => setOpen((old) => !old), []);

    const Btn: any = Button;

    const PaperComponent = useCallback(
        (p: PaperProps) => (
            <ClickAwayListener onClickAway={closeOnClickAway?close:()=>{/*noop*/}}>
                <Paper {...p} />
            </ClickAwayListener>
        ),
        [close, closeOnClickAway]
    );

    return (
        <>
            <Btn onClick={toggle} />
            <Dialog
                
                open={open}
                aria-labelledby={titleId}
                {...rest}
                classes={{
                    paper: fullHeight ? classes.paper : undefined,
                }}
                className={className}
                PaperComponent={PaperComponent}
            >
                <div className={classes.title}>
                    <DialogTitle id={titleId}>{title}</DialogTitle>
                    <div>
                        <IconButton
                            size="small"
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={close}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent >{content}</DialogContent>
                {actions && <DialogActions>{actions}</DialogActions>}
            </Dialog>
        </>
    );
}
