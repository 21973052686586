import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import { useAsync, useToast } from "src/hooks";
import { robotsSelector } from "src/redux/app/selectors";
import { defaultRules } from "src/views/Settings/User/Widgets/BaseWidget";
import { Box, Button, FormControl } from "@material-ui/core";

type SetNTRIPInput = {
    username: string;
    password: string;
    address: string;
    port: number;
    mountpoint: string;
    version: number;
    robot: number;
    deviceTypeId:number
};

function setRobotNTRIP(input: SetNTRIPInput){
    return ConfigurationsService.setNTRIP({
        ...input,
    }).then(()=>input)
}

export default function NTRIPCommand(props:{robotId:number,historyRef:any,atLeastSupport: boolean,deviceTypeId:number}) {
    const {robotId,historyRef,atLeastSupport,deviceTypeId}=props
    const {
        handleSubmit,
        errors,
        reset,
        register,
        setValue,
        watch,
    } = useForm<SetNTRIPInput>({
        defaultValues: { robot: robotId, username: "",password:"",address:"",port:0,mountpoint:"",version:1,deviceTypeId:deviceTypeId },
    });
    const fetchCommands = useCallback(() => {
        if (historyRef && historyRef.current)
            (historyRef.current as any).fetchCommands();
    },[historyRef])
   

    const robots = useSelector(robotsSelector);
    const loadedDeviceInfo = robots.length > 0;
    const { displayToast } = useToast();
    const username = watch("username");
    const password = watch("password");
    const address = watch("address");
    const port = watch("port");
    const mountpoint = watch("mountpoint");

    useEffect(() => {
        register("username", defaultRules);
        register("password", defaultRules);
        register("port", defaultRules);
        register("address", defaultRules);
        register("mountpoint", defaultRules);
        register("version",defaultRules)
        register("robot",defaultRules)
        register('deviceTypeId',defaultRules)
        setValue("version",1)
        setValue('deviceTypeId',deviceTypeId)
    }, [register,setValue,deviceTypeId]);

    const onComplete = useCallback(
        (input: SetNTRIPInput) => {
            reset();
            displayToast({
                message: `Successfully set the NTRIP of Robot ${
                    input.robot
                } `,
                severity: "success",
                withCloseIcon: true,
            });
             fetchCommands();
        },[displayToast,reset,fetchCommands]
    );

    const { exec: setNTRIP, pending: settingNTRIP } = useAsync(
        setRobotNTRIP,
        {
            immediate: false,
            onComplete,
        }
    );
    const loading = settingNTRIP || !loadedDeviceInfo;

    return (
        <Box style={{padding:"20px 0"}} >
        {atLeastSupport && <FormControl
            title="Set robot NTRIP"
            onSubmit={()=>{handleSubmit(setNTRIP)}}
            style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}
        >
            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                placeholder="Enter username"
                value={username}
                onChange={(ev) => {
                    setValue("username", ev.target.value);
                }}
                {...getErrorProps(errors, "username")}
            />

            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                placeholder="Enter password"
                value={password}
                onChange={(ev) => {
                    setValue("password", ev.target.value);
                }}
                {...getErrorProps(errors, "password")}
            />

            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                label="address"
                placeholder="Enter address"
                value={address}
                onChange={(ev) => {
                    setValue("address", ev.target.value);
                }}
                {...getErrorProps(errors, "address")}
            />

            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                label="Port"
                type="number"
                placeholder="Enter port"
                value={port}
                onChange={(ev) => {
                    setValue("port", Number(ev.target.value));
                }}
                {...getErrorProps(errors, "port")}
            />

            <TextField
                variant="outlined"
                margin="dense"
                required
                disabled={loading}
                fullWidth
                label="Mountpoint"
                placeholder="Enter mountpoint"
                value={mountpoint}
                onChange={(ev) => {
                    setValue("mountpoint", ev.target.value);
                }}
                {...getErrorProps(errors, "mountpoint")}
            />    
          
             <Button
                type="submit"
                size="medium"
                variant="contained"
                color="primary"
                style={{marginTop:"20px"}}
                onClick={handleSubmit(setNTRIP)}
            >
                Send command
            </Button>

        </FormControl>}
            <div style={{marginTop:"20px"}}>
                <span style={{marginTop:"5px",fontWeight:"bold",marginBottom:"5px"}}>Command Description:</span>
                <p>Send information for NTRIP provider to the robot.<br/>The information will be updated instantly if the robot is online. If the robot is not online, the information will be sent and updated next time the robot comes online.</p>
            </div>
        </Box>
    )
}
