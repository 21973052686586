import React from 'react';
import axios from 'axios';
import Check from '@material-ui/icons/Check';
import Table from "./Components/Table.jsx"

import CreateAccessGroupPopup from "./popups/CreateAccessGroupPopup.jsx";
import EditAccessGroupPopup from "./popups/EditAccessGroupPopup.jsx";

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

class ManageAccessGroupsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: []
        };
        this.refreshGroups = this.refreshGroups.bind(this);

        this.headers = [{
            Header: 'Short name',
            key: 'ShortNameElement',
            align: "left",
            style: small_style,
            search: "ShortName"
        },
        {
            Header: 'Access group',
            key: 'NameElement',
            align: "left",
            style: small_style,
            search: "Name"
        },
        {
            Header: 'Type',
            key: 'TypeElement',
            align: "left",
            style: big_style,
            search: "Type"
        },
        {
            Header: 'Inheritable',
            key: 'InheritableElement',
            align: "left",
            style: small_style
        },
        {
            Header: '',
            key: 'EditElement',
            align: "left",
            style: small_style
        }
        ];
    }

    componentDidMount() {
        this.refreshGroups()
    }

    refreshGroups(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/accessgroups`)
            .then(response => response.data[0])
            .then(state => this.setState({
                groups: state.map(r => {
                    r.NameElement = r.Name;
                    r.ShortNameElement = r.ShortName;
                    r.TypeElement = r.Type;
                    r.InheritableElement = r.Inheritable ? (<Check />) : null;
                    r.EditElement = <EditAccessGroupPopup accessGroup={r} whenSuccess={this.refreshGroups.bind(this)} />
                    return r;
                })
            }));
    }

    render() {
        return (
            <React.Fragment>
                <Table id="access_groups_table" headeres={this.headers} data={this.state.groups} refresh={this.refreshGroups} />
                <div style={{ margin: "30px" }}>
                    <CreateAccessGroupPopup whenSuccess={this.refreshGroups.bind(this)} />
                </div>
            </React.Fragment>
        );
    }
}

export default ManageAccessGroupsSection;