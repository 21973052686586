import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import ListBox from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { TableInstance, ColumnInstance } from "react-table";
import { isMobile } from "react-device-detect";
import classNames from "src/utils/classNames";
import { useDebounced } from "src/hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            boxShadow: "0 3px 12px rgba(27,31,35,.15)",
            borderRadius: 3,
            width: 350,
            zIndex: 50,
            fontSize: 13,
            color: "#586069",
            backgroundColor: "#f6f8fa",
        },
        header: {
            display: "flex",
            borderBottom: "1px solid #e1e4e8",
            padding: "8px 10px",
            "& :first-child": {
                fontWeight: 600,
                fontSize: "14px",
            },
        },
        paper: {
            position: "relative",
            boxShadow: "none",
            margin: 0,
            color: "#586069",
            fontSize: 13,
            maxHeight: "40vh",
            overflowY: "auto",
            "& ul": {
                padding: "2px 0px 2px 0px",
            },
            fontFamily: theme.typography.fontFamily,
        },
        option: {
            minHeight: "auto",
            alignItems: "flex-start",
            padding: 8,
            "&:not($notFoundOption)": {
                '&[aria-selected="true"]': {
                    backgroundColor: "transparent",
                },
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        },
        notFoundOption: {},
        iconSelected: {
            width: 17,
            height: 17,
            marginRight: theme.spacing(2),
            marginLeft: -2,
        },
        text: {
            flexGrow: 1,
        },
        close: {
            opacity: 0.6,
            width: 18,
            height: 18,
        },
        invisible: {
            visibility: "hidden",
        },
        buttonBox: {
            display: "flex",
            "& > *": {
                flexGrow: 1,
            },
            "& > :first-child": {
                borderRight: "1px solid #e1e4e8",
            },
        },
    })
);

const id_ = "table-hide-columns";

// eslint-disable-next-line @typescript-eslint/ban-types
export interface HideColumnsProps<T extends {}> {
    instance: TableInstance<T>;
    anchorEl?: HTMLElement;
    onClose: () => void;
    open: boolean;
    filter?: (
        columns: ColumnInstance<T>[],
        filter: string
    ) => ColumnInstance<T>[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
function HideColumns<T extends {}>(props: HideColumnsProps<T>) {
    const classes = useStyles();
    const { instance, anchorEl, onClose, open, filter: userFilter } = props;
    const { allColumns, visibleColumns, toggleHideColumn } = instance;

    const [focused, setFocused] = useState(!isMobile);
    const [search, setSearch] = useState("");

    const [state, setState] = useState({
        all: [],
        visible: [],
        toggled: {},
    } as {
        all: typeof allColumns;
        visible: typeof allColumns;
        toggled: Record<string, boolean>;
    });

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open === true) {
            setSearch("");
            setFocused(!isMobile);
        }
    }, [open]);

    useEffect(() => {
        const all = allColumns.filter((column) =>
            column.hideable === undefined ? true : column.hideable
        );
        const visible = all.filter((c) => c.isVisible);
        setState({
            all,
            visible,
            toggled: {},
        });
    }, [allColumns]);

    const handleClick = (column: ColumnInstance<any>) => (
        _: React.MouseEvent<any>
    ) => {
        const { id } = column;
        // Hide the column
        if (state.toggled[id] !== undefined) {
            const { [id]: isToggled, ...rest } = state.toggled;
            const visible = isToggled
                ? [...state.visible, column]
                : [...state.visible.filter((c) => c !== column)];
            setState({ ...state, visible, toggled: rest });
        } else {
            const visible = column.isVisible
                ? [...state.visible.filter((c) => c.id !== column.id)]
                : [...state.visible, column];
            setState({
                ...state,
                visible,
                toggled: {
                    ...state.toggled,
                    [id]: column.isVisible,
                },
            });
        }
        if (focused && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleClose = (_ev: React.ChangeEvent<any>) => {
        Object.keys(state.toggled).forEach((key) =>
            toggleHideColumn(key, state.toggled[key])
        );
        if (anchorEl) {
            anchorEl.focus();
        }
        onClose();
        setSearch("");
        setState((s) => ({ ...s, toggled: {} }));
    };

    const hideAll = (_ev: React.MouseEvent<any>) => {
        const cleared = state.all.reduce(
            (acc, c) => (c.isVisible ? { ...acc, [c.id]: c.isVisible } : acc),
            {}
        );
        setState({ ...state, visible: [], toggled: cleared });
        setSearch("");
    };

    const showAll = (_ev: React.MouseEvent<any>) => {
        const shown = state.all.reduce(
            (acc, c) => (!c.isVisible ? { ...acc, [c.id]: c.isVisible } : acc),
            {}
        );
        setState({ ...state, visible: state.all, toggled: shown });
        setSearch("");
    };

    const isVisible = (column: ColumnInstance<any>) =>
        state.toggled[column.id] !== undefined
            ? !column.isVisible
            : column.isVisible;

    const filter = useCallback(
        (
            columns: ColumnInstance<any>[],
            filter_: string
        ): ColumnInstance<any>[] => {
            if (filter_) {
                if (userFilter !== undefined) {
                    return userFilter(columns, filter_);
                }
                const inputValue = filter_.toLowerCase().trim();
                return columns.filter((o) =>
                    (o.name || o.id).toLowerCase().startsWith(inputValue)
                );
            }
            return columns;
        },
        [userFilter]
    );

    const debouncedSearch = useDebounced(search, 350);
    const allOrdered = useMemo(() => {
        return state.all.sort((a, b) => {
            // Display the selected labels first.
            let ai = visibleColumns.indexOf(a);
            ai = ai === -1 ? visibleColumns.length + state.all.indexOf(a) : ai;
            let bi = visibleColumns.indexOf(b);
            bi = bi === -1 ? visibleColumns.length + state.all.indexOf(b) : bi;
            return ai - bi;
        });
    }, [state.all, visibleColumns]);
    const filtered = useMemo(() => filter(allOrdered, debouncedSearch), [
        filter,
        allOrdered,
        debouncedSearch,
    ]);

    return (
        <>
            <Popover
                key={id_}
                id={id_}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={handleClose}
            >
                <div>
                    <div className={classes.popper}>
                        <div className={classes.header}>
                            <Typography variant="h6" component="p">
                                Toggle column visibility
                            </Typography>
                        </div>

                        <div>
                            {state.all.length > 4 && (
                                <>
                                    <div className={classes.buttonBox}>
                                        <Button onClick={hideAll}>
                                            Hide all
                                        </Button>
                                        <Button onClick={showAll}>
                                            Show all
                                        </Button>
                                    </div>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        value={search}
                                        inputRef={inputRef}
                                        onChange={(ev) =>
                                            setSearch(ev.target.value)
                                        }
                                        placeholder={"Filter columns..."}
                                        variant="outlined"
                                        autoFocus={!isMobile}
                                        focused={focused}
                                        onFocus={(_ev) => setFocused(true)}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Paper className={classes.paper}>
                        <ListBox>
                            {(filtered.length > 0 &&
                                filtered.map((option, _idx) => {
                                    const visible = isVisible(option);
                                    return (
                                        <ListItem
                                            key={option.id}
                                            className={classes.option}
                                            onClick={handleClick(option)}
                                            aria-selected={visible}
                                        >
                                            <>
                                                <DoneIcon
                                                    className={classNames(
                                                        classes.iconSelected,
                                                        !visible &&
                                                            classes.invisible
                                                    )}
                                                />

                                                <div className={classes.text}>
                                                    {option.render("Header")}
                                                </div>
                                                <CloseIcon
                                                    className={classNames(
                                                        classes.close,
                                                        !visible &&
                                                            classes.invisible
                                                    )}
                                                />
                                            </>
                                        </ListItem>
                                    );
                                })) || (
                                <ListItem
                                    className={classNames(
                                        classes.option,
                                        classes.notFoundOption
                                    )}
                                >
                                    <>
                                        <DoneIcon
                                            className={classNames(
                                                classes.iconSelected,
                                                classes.invisible
                                            )}
                                        />
                                        <div className={classes.text}>
                                            No columns found
                                        </div>
                                        <CloseIcon
                                            className={classNames(
                                                classes.close,
                                                classes.invisible
                                            )}
                                        />
                                    </>
                                </ListItem>
                            )}
                        </ListBox>
                    </Paper>
                </div>
            </Popover>
        </>
    );
}

export default (React.memo(HideColumns) as unknown) as typeof HideColumns;
