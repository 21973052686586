import {
    call,
    take,
    takeEvery,
    put,
    select,
    fork,
    takeLatest,
} from "redux-saga/effects";
import {
    replace,
    LocationChangeAction,
    LOCATION_CHANGE,
} from "connected-react-router";
import {
    SignInAction,
    SignedInAction,
    SIGN_IN,
    INIT_AUTH,
    SIGNED_IN,
    SIGN_OUT,
    SignOutAction,
} from "./types";
import { signingIn, signedIn, initAuth } from "./actions";
import { isAuthenticatedSelector } from "./selectors";
import { redirectedFromSelector, locationSelector } from "../router/selectors";

import AuthService from "../../services/AuthService";
import { setAccessFields, setBaseStations, setCompanies, setContacts, setGoogleMapsToken, setOperations, setQuotes, setRobots, setTablets, setUser, setUsers } from "../app/actions";
import AdministrationService from "src/services/AdministrationService";
import DeviceDataService from "src/services/DeviceDataService";
import UserService, { User } from "src/services/UserService";
import QuoteService from "src/services/QuoteService";
import HubspotService from "src/services/HubspotService";
import ConfigurationsService from "src/services/ConfigurationsService";

function* onSignIn(action: SignInAction) {
    yield put(signingIn());

    try {
        const userCredentials = yield call(AuthService.signIn, {
            ...action.payload,
        });
        if (userCredentials.TFANeeded)
        yield put(signedIn(userCredentials,null,false,{TFANeeded:true}));
        else
        yield put(signedIn(userCredentials));
    } catch (error) {
        yield put(signedIn(undefined, error));
    }
}

function* signOut(action: SignOutAction, currentLocation: ReturnType<typeof locationSelector>) {
    try {
        // Clear token and navigate to signin
        yield call(AuthService.signOut);
        yield put(
            replace("/account/signin", {
                from:
                    (currentLocation?.state as any)?.from ||
                    currentLocation,
                message: action?.meta?.message,
            })
        );
    } catch (err) {
        // This will make it seem like the user is still logged in
        // What to do here?
        yield put(initAuth());
    }
}

function* onStart() {
    try {
        
        const operations = yield call(AdministrationService.getAllowedOperations);
        yield put(setOperations(operations));
        const userDeviceInfo = yield call(DeviceDataService.getDeviceInfo);
        yield put(setRobots(userDeviceInfo.robots));
        yield put(setTablets(userDeviceInfo.tablets));
        yield put(setBaseStations(userDeviceInfo.basestations??[]));
        const users = yield call(() => UserService.getUsers());
        yield put(setUsers(users));
        const user = users.find((u : User) => u.username === AuthService.getActiveUsername());
        yield put(setUser(user));

        const googleMapsToken=yield call(()=>ConfigurationsService.getGoogleMapsToken())
        yield put(setGoogleMapsToken(googleMapsToken))

        const accessFields = yield call(() => UserService.getAccessFields());
        yield put(setAccessFields(accessFields));
        
        const quotes = yield call(() => QuoteService.getContracts());
        yield put(setQuotes(quotes));

        if (quotes?.contracts?.length>0){
            const contacts = yield call(() => HubspotService.getContacts());
            yield put(setContacts(contacts));
            const companies = yield call(() => HubspotService.getCompanies());
            yield put(setCompanies(companies));
            
        }
        
    } catch (e) {
        console.error(e);
        yield signOut();
    }
}

function* onSignOut() {
    while (true) {
        const action: SignOutAction = yield take(SIGN_OUT);
        const currentLocation: ReturnType<typeof locationSelector> = yield select(
            locationSelector
        );

        yield signOut(action, currentLocation);
    }
}

// Check credentials on app boot
function* onInit() {
    yield take(INIT_AUTH);
    const activeCredentials = AuthService.getActiveCredentials();
    if (activeCredentials) {
        yield put(signedIn(activeCredentials, undefined, false));
        yield onStart();
    }
}

// Navigate when signed in
function* onSignedIn(action: SignedInAction) {
   if (!action.payload.success && (action.payload).TFANeeded){
        return;
    }else
    if (action.payload.success && action.payload.redirect) {
        const {
            from,
        }: ReturnType<typeof redirectedFromSelector> = yield select(
            redirectedFromSelector
        );
        yield put(replace((from && from.pathname) || "/home"));
        yield onStart();
    }
}

// Navigate away from signin page if already authenticated
function* onNavigate(action: LocationChangeAction) {
    if (action.payload.location.pathname === "/account/signin") {
        const authenticated = yield select(isAuthenticatedSelector);
        if (authenticated) {
            const {
                from,
            }: ReturnType<typeof redirectedFromSelector> = yield select(
                redirectedFromSelector
            );
            yield put(replace((from && from.pathname) || "/home"));
        }
    }
}

export default function* sagas() {
    yield fork(onInit);
    yield fork(onSignOut);
    yield takeLatest(SIGN_IN, onSignIn);
    yield takeLatest(SIGNED_IN, onSignedIn);
    yield takeEvery(LOCATION_CHANGE, onNavigate);
}