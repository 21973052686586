/* eslint-disable @typescript-eslint/ban-types */
import matchSorter from "match-sorter";
import { FilterValue, IdType, Row } from "react-table";

function fuzzyTextFilter<T extends object>(
    rows: Array<Row<T>>,
    id: IdType<T>,
    filterValue: FilterValue
) {
    return matchSorter(rows, filterValue, {
        keys: [
            (row: Row<T>) => {
                return row.values[id];
            },
        ],
    });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilter.autoRemove = (val: any) => {
    return !val;
};

function matchesFilter<T extends object>(
    rows: Array<Row<T>>,
    id: IdType<T>,
    filterValue: FilterValue[] | string
) {
    if (
        !Array.isArray(filterValue) &&
        (typeof filterValue !== "string" || filterValue.trim() === "")
    ) {
        return rows;
    }

    const regex = new RegExp(
        `^${
            typeof filterValue === "string"
                ? filterValue
                : filterValue
                      .map((v) => `(${v === null ? "undefined" : v})`)
                      .join("|")
        }`,
        "i"
    );

    return rows.filter((r) => regex.test(r.values[id]));
}

matchesFilter.autoRemove = (val: FilterValue[] | string | RegExp) => {
    return (
        val === undefined ||
        val === null ||
        (typeof val === "string" && val === "") ||
        (Array.isArray(val) && val.length === 0)
    );
};

export interface MultiColumnFilterOptions {
    columnIds: string[];
    value: string;
}

function multiColumn<T extends object>(
    rows: Array<Row<T>>,
    _id: IdType<T>,
    filterValue: MultiColumnFilterOptions | undefined
) {
    if (filterValue === undefined) {
        return rows;
    }

    const regex = new RegExp(filterValue.value, "i");

    return rows.filter((row) => {
        const searchString = filterValue.columnIds
            .map((id_) => row.values[id_])
            .join(" ");
        return regex.test(searchString);
    });
}

multiColumn.autoRemove = (val: MultiColumnFilterOptions | undefined) => {
    return val === undefined || val === null;
};

function translatedTextFilter<T extends object>(
    rows: Array<Row<T>>,
    id: IdType<T>,
    { value }: { value: FilterValue } = { value: undefined }
) {
    return fuzzyTextFilter(rows, id, value);
}

translatedTextFilter.autoRemove = (val: any) =>
    !!val === false || !!val.value === false;

export interface DateColumnFilterOptions {
    columnIds?: string[];
    values: (number | null)[];
}
    
function dateColumn<T extends object>(
    rows: Array<Row<T>>,
    _id: IdType<T>,
    filterValue: DateColumnFilterOptions | undefined
) {
    if (filterValue === undefined)
        return rows;
    let lower = filterValue.values[0];
    let upper = filterValue.values[1];
    if (lower === null)
        lower = 0;
    if (upper === null)
        upper = Number.MAX_SAFE_INTEGER;

    return rows.filter((row) => {
        return filterValue.columnIds!.map((id_) => row.values[id_]).some((date) => {return date >= lower! && date <= upper!;});
    });
}
    
dateColumn.autoRemove = (val: DateColumnFilterOptions | undefined) => {
    return val === undefined || val === null;
};

export default {
    fuzzyText: fuzzyTextFilter,
    translatedFuzzyText: translatedTextFilter,
    matches: matchesFilter,
    multiColumn: multiColumn,
    dateColumn: dateColumn,
};
