import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, makeStyles, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import QuoteService, { Contract, Plan } from "src/services/QuoteService";
import { useAsync, useToast } from "src/hooks";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { companiesSelector, contactsSelector, quotesSelector, userSelector } from "src/redux/app/selectors";
import { setCompanies as setCompaniesAction, setContacts as setContactsAction } from "src/redux/app/actions";
import { useAsyncDebounce } from "react-table";
import moment from "moment";
import { formatAsMoney } from "src/utils/common";
import PdfPreview from "src/components/PdfPreview";
import ArrowIcon from "@material-ui/icons/ArrowUpward";
import { timezone } from "src/App";
import HubspotService, { HubSpotCompany, HubSpotContact } from "src/services/HubspotService";
import Select from "react-select-virtualized";
import ReadonlyField from "src/components/ReadonlyField";
import { createPdf } from "./PdfGenerator";
import useDialog from "src/hooks/useDialog";
import {Select as SelectMui} from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers";

const isChrome = (window as any).chrome !== undefined && (window as any).chrome !== null;

const useStyles = makeStyles((theme) => ({
    quoteWrapper: isMobile ? {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        '&>div': {
            marginLeft: "20px",
            marginRight: "20px",
            width: "calc(100% - 40px)",
        },
        '&>div:nth-child(1)': {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            '&>*': {
                '&>*': {
                    width: "100%",
                    boxSizing: "border-box",
                    marginBottom: "15px",
                }
            },
        },
        '&>div:nth-child(2)': {
            marginLeft: "0px",
            marginRight: "0px",
            width: "100%",
            marginTop: "40px",
            height: "calc(80vh - 10px)",
        },
        '& label': {
            zIndex: 0
        },
    } : {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "row",
        padding: "20px",
        width: "calc(100% - 40px)",
        height: "calc(100vh - 70px)",
        '&>div:nth-child(1)': {
            width: "350px",
            height: "100%",
            marginRight: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "top",
            flexDirection: "column",
            overflowY: "auto",
            '&>*': {
                '&>*': {
                    width: "calc(100% - 10px)",
                    boxSizing: "border-box",
                    marginBottom: "15px",
                }
            },
        },
        '&>div:nth-child(2)': {
            flex: 1,
        },
        '& label': {
            zIndex: 0
        },
    },
    product: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        '&>*': {
            width: "100%",
        },
    },
    template: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    },
    extra: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "top",
        flexDirection: "column",
        '&>*:nth-child(1)': {
            marginTop: "5px",
            marginBottom: "-10px",
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:disabled':{
            color: theme.palette.common.white,
            opacity:0.7
        }
    },
}));

function getCountryLanguage(quoteName:string){
    if (quoteName==="TinyMobileRobots US LLC")
    return {code:"eng_us","label":"English US"}
    else if (quoteName==="TinyMobileRobots UK")
    return {code:'eng_uk',label:"English UK"}
    else if (quoteName==="TinyMobileRobots FR" || quoteName==="TinyMobileRobots BE(FR)")
    return {code:'fr',label:"French"}
    else if (quoteName==="TinyMobileRobots DE")
    return {code:'de',label:"German"}
    else if (quoteName==="TinyMobileRobots NL" || quoteName==="TinyMobileRobots BE(NL)")
    return {code:'nl',label:"Dutch"}
    else if (quoteName==='TinyMobileRobots AU')
    return {code:'eng_au', label:"English AU"}
    return {code:'',label:""}
}

const NEW_COMPANY = {id:"new",name:"NEW",country:"",city:"",address:"",zip:"",state:"",phone:"",contacts:[],lastmodified:0} as HubSpotCompany;
const NEW_CONTACT = {id:"new",firstname:"NEW",lastname:"",phone:"",email:"",companies:[],lastmodified:0,country:""} as HubSpotContact;

export default function QuoteGenerator() {
    const classes = useStyles();

    const { displayToast } = useToast();
    const {displayDialog } = useDialog();

    const quotes = useSelector(quotesSelector);

    const fetchItemNumbers = useCallback(
        () => QuoteService.getItemNumbers(),
        []
    );
    const { value:itemNumbers,} = useAsync(fetchItemNumbers, {
        defaultPending: true,
    });

    const fetchTranslations = useCallback(
        () => QuoteService.getTranslations(),
        []
    );
    const { value:translations, } = useAsync(fetchTranslations, {
        defaultPending: true,
    });

    const [contract, setContract] = useState(undefined as undefined | Contract);
    const [language,setLanguage]= useState({code:"",label:""})

    const dispatch = useDispatch();
    const com = useSelector(companiesSelector);
    const [companies, setCompanies] = useState([] as HubSpotCompany[]);
    const setCompaniesWrapper = (includeNew:boolean,c?:HubSpotCompany[]) => {
        let tmp = c;
        if (!c)
            tmp = com.filter(c => (contract?.countryDropdown??[]).includes(c.country));
        if (includeNew)
            tmp = [NEW_COMPANY].concat(tmp!);
        setCompanies(tmp!.map(c => {return {...c, label: (c.name+" "+c.phone).toLowerCase()};}));
    };
    useEffect(() => {
        if (contact === null)
            setCompaniesWrapper(true);
    }, [com, contract]); //eslint-disable-line react-hooks/exhaustive-deps
    const con = useSelector(contactsSelector);
    const [contacts, setContacts] = useState([] as HubSpotContact[]);
    const setContactsWrapper = (includeNew:boolean,c?:HubSpotContact[]) => {
        let tmp = c;
        if (!c)
            tmp = con.filter(c => (contract?.countryDropdown??[]).includes(c.country));
        if (includeNew)
            tmp = [NEW_CONTACT].concat(tmp!);
        setContacts(tmp!.map(c => {return {...c, label: (c.firstname+" "+c.lastname+" "+c.email+" "+c.phone).toLowerCase()};}));
    };
    useEffect(() => {
        if (company === null)
            setContactsWrapper(true);
    }, [con, contract]); //eslint-disable-line react-hooks/exhaustive-deps

    const [date] = useState(moment.unix(moment.now()/1000));
    const [pdfs, setPdfs] = useState({} as { [name: string]: Blob });
    const [loadingPdfs, setLoadingPdfs] = useState(true);
    useEffect(() => {
        //if only one contract is available, then select it
        if (contract === undefined && quotes?.contracts.length === 1)
            {setContract(quotes!.contracts[0]);
            setLanguage(getCountryLanguage(quotes!.contracts[0].name))}
        //download pdfs
        setLoadingPdfs(true);
        if (quotes) {
            const files = new Array<string>(0);
            for (const c of quotes.contracts)
                for (const t of ["pdfCover","pdfPayment","pdfContract","pdfFinal","pdfBudgetary"])
                    if ((c as any)[t] && !files.includes((c as any)[t]))
                        files.push((c as any)[t]);
            Promise.all(files.map(f => QuoteService.getPdf(f).then(p => {return {name: f, file: p};}))).then(r => {
                setPdfs(Object.fromEntries(r.map(f => [f.name, f.file])));
                setLoadingPdfs(false);
            });
        }
    }, [quotes]); //eslint-disable-line react-hooks/exhaustive-deps

    const user = useSelector(userSelector);

    const [companyName, setCompanyName] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyCountry, setCompanyCountry] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyZip, setCompanyZip] = useState("");
    const [companyState, setCompanyState] = useState("");
    const [company, setCompany] = useState(null as null | HubSpotCompany);
    const [overrideCompany, setOverrideCompnay] = useState(false);
    const setCompanyWrapper = (c:null|HubSpotCompany) => {
        if (c === null || c.id === "new") {
            setCompanyName("");
            setCompanyPhone("");
            setCompanyCountry("");
            setCompanyCity("");
            setCompanyAddress("");
            setCompanyZip("");
            setCompanyState("");
            setOverrideCompnay(false);
        } else {
            setCompanyName(c.name);
            setCompanyPhone(c.phone);
            setCompanyCountry(c.country);
            setCompanyCity(c.city);
            setCompanyAddress(c.address);
            setCompanyZip(c.zip);
            setCompanyState(c.state);
        }
        setCompany(c);
    };

    const [closedateLoading,setClosedateLoading]=useState(false)
    const fetchClosedate = useCallback(
        () => {
            setClosedateLoading(true)
            return (company?.id?HubspotService.getClosedate(company?.id??'').then((t)=>setCloseDate((t===''||!t)?null:new Date(t))):Promise.resolve(null)).then(()=>setClosedateLoading(false))
        },
        [company?.id]
    );
    const { value:closedate,  } = useAsync(fetchClosedate, {
        defaultPending: false,
    });

    const [contactFirstname, setContactFirstname] = useState("");
    const [contactLastname, setContactLastname] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contact, setContact] = useState(null as null | HubSpotContact);
    const [overrideContact, setOverrideContact] = useState(false);
    const setContactWrapper = (c:null|HubSpotContact) => {
        if (c === null || c.id === "new") {
            setContactFirstname("");
            setContactLastname("");
            setContactEmail("");
            setContactPhone("");
            setOverrideContact(false);
        } else {
            setContactFirstname(c.firstname);
            setContactLastname(c.lastname);
            setContactEmail(c.email);
            setContactPhone(c.phone);
        }
        setContact(c);
    };
    const [quoteType,setQuoteType]=useState(undefined as unknown as string)

    const [validDays, setValidDays] = useState(30);
    const [closeDate,setCloseDate]=useState(null as unknown as Date)
    const [comments, setComments] = useState("");
    const [robotCount, setRobotCount] = useState({} as  { [name: string]: number });
    const [plans, setPlans] = useState({} as { [name: string]: Array<Plan> });
    const [plan,setPlan]=useState(null as unknown as string)
    const [templates, setTemplates] = useState({} as { [name: string]: Array<Array<number>> });
    const [extras, setExtras] = useState({} as { [name: string]: Array<{ [name: number]: number }> });
    const [sendToCustomer, setSendToCustomer] = useState(false);
    const [splitExtras, setSplitExtras] = useState(false);
    useEffect(() => {
        setCompanyWrapper(null);
        setContactWrapper(null);
        setCompaniesWrapper(true);
        setContactsWrapper(true);
        if (contract === undefined)
            return;
        setCompanyCountry(contract.countryDropdown[0]);
        const rCnt = {} as  { [name: string]: number };
        for (const plan of contract.plans)
            if (!Object.keys(rCnt).includes(plan.product))
                rCnt[plan.product] = 0;
        setRobotCount(rCnt);
        const pls = {} as { [name: string]: Array<Plan> };
        for (const prod of Object.keys(rCnt))
            pls[prod] = new Array<Plan>(0);
        setPlans(pls);
        if (contract.plans.length===2)
            setPlan(contract.plans[0].title)
        const tes = {} as { [name: string]: Array<Array<number>> };
        for (const prod of Object.keys(rCnt))
            tes[prod] = new Array<Array<number>>(0);
        setTemplates(tes);
        const exs = {} as { [name: string]: Array<{ [name: number]: number }> };
        for (const prod of Object.keys(rCnt))
            exs[prod] = new Array<{ [name: number]: number }>(0);
        setExtras(exs);
    }, [contract]); //eslint-disable-line react-hooks/exhaustive-deps

    const contracts = useMemo(() => {
        return (quotes?.contracts ?? []).map((q) => {
            return (
                <MenuItem key={q.name} value={q.name}>
                    {q.name}
                </MenuItem>
            );
        });
    }, [quotes]);

    const [quotationNumber, setQuotationNumber] = useState(0);
    useEffect(() => {
        if (quotes !== undefined)
            setQuotationNumber(quotes.quotationNumber);
    }, [quotes]);
    
    const lineItemss=useRef([] as Array<{name:string,qty:number,price:number}>);
    const [pdfPreview, setPdfPreview] = useState("");
    
    const createPreviewDebounced =( useAsyncDebounce(async (q)=>createPdf(q).then(r => {
        lineItemss.current=r.lineItems
        URL.revokeObjectURL(pdfPreview);
        setPdfPreview(r.url);
    }), 300));
    useEffect(() => {        
        if (!loadingPdfs && contract)
                createPreviewDebounced({user,quotes,quotationNumber,date,timezone,pdfs,contract,validDays,comments,companyName,companyPhone,companyCountry,companyCity,companyAddress,companyZip,companyState,contactFirstname,contactLastname,contactEmail,contactPhone,robotCount,plans,templates,extras,splitExtras,language:language.code,quoteTranslator:translations??[],itemNumbers:itemNumbers??[],quoteType})
    }, [loadingPdfs,user,quotes,quotationNumber,date,timezone,pdfs,contract,validDays,comments,companyName,companyPhone,companyCountry,companyCity,companyAddress,companyZip,companyState,contactFirstname,contactLastname,contactEmail,contactPhone,robotCount,plans,templates,extras,splitExtras,quoteType]); //eslint-disable-line react-hooks/exhaustive-deps

    const [uploading, setUploading] = useState(false);
    let errors = "";
    const generateErrors = () => {
        if (contract === undefined || contract === null) {
            errors += "Select a contract type!";
            return;
        }
        let correctDetails = true;
        if (company?.id === "new") {
            if (companyName === "") {
                errors += "Fill out Company Name!\n";
                correctDetails = false;
            }
        } else {
            if (company === null) {
                errors += "Select a Company!\n";
                correctDetails = false;
            }
        }
        if (contact?.id === "new") {
            if (contactFirstname === "" || contactLastname === "") {
                errors += "Fill out Contact Name!\n";
                correctDetails = false;
            }
            if (contactEmail === "" && contactPhone === "") {
                errors += "Fill out Contact Phone or Email!\n";
                correctDetails = false;
            }
        } else {
            if (contact === null) {
                errors += "Select a Contact!\n";
                correctDetails = false;
            }
        }
        if (!quoteType)
        {
            errors+='Select a Quote Type!\n'
            correctDetails=false
        }
        if ( !closeDate || (closeDate && closeDate.valueOf() <( new Date()).valueOf())){

            errors+='Select a Proper Close Date!\n'
            correctDetails=false
        }
        if (!correctDetails) {
            errors = errors.substring(0,errors.length-1);
            return;
        }
        let cnt = 0;
        let plansSelected = true;
        for (const key of Object.keys(robotCount))
            cnt += robotCount[key];
        if (cnt === 0)
            errors += "Add at least 1 robot to the quote!\n";
        else {
            for (const prod of Object.keys(robotCount))
                for (let i = 0; i < robotCount[prod]; i++)
                    if (plans[prod] === undefined || plans[prod] === null || plans[prod][i] === undefined || plans[prod][i] === null)
                        plansSelected = false;
            if (!plansSelected) {
                errors += "Select payment plan for every added robot!";
                return;
            }
            for (const prod of Object.keys(robotCount))
                if (robotCount[prod] > 0 && prod.toLowerCase().includes("sport"))
                    if(templates[prod][0].length < quotes.freeTemplates.length+quotes.freeTemplatesQty)
                        errors += "Select at least "+(quotes.freeTemplates.length+quotes.freeTemplatesQty-templates[prod][0].length)+" more templates for the Sport robot(s)!\n";
        }
        if (errors.length > 0)
            errors = errors.substring(0,errors.length-1);
    }
    generateErrors();

    const generateContract= async (lineItems: Array<{name:string,qty:number,price:number}>)=>{
            try {
                setUploading(true);
                const qn = await QuoteService.getQuotationNumber();
                const { file } = await createPdf({user,quotes,quotationNumber: qn,date,timezone,pdfs,contract:contract!,validDays,comments,companyName,companyPhone,companyCountry,companyCity,companyAddress,companyZip,companyState,contactFirstname,contactLastname,contactEmail,contactPhone,robotCount,plans,templates,extras,splitExtras,language:language.code,quoteTranslator:translations??[],itemNumbers:itemNumbers??[],quoteType});
                let currency = "USD";
                const productsPlansArrays = Object.keys(robotCount).map(k => {
                    const arr = [] as any[];
                    for (let l = 0; l < robotCount[k]; l++) {
                        arr.push(plans[k][l].product+" "+plans[k][l].title);
                        currency = plans[k][l].currency;
                    }
                    return arr;
                });
                let productsPlans = productsPlansArrays[0];
                for (let l = 1; l < productsPlansArrays.length; l++)
                    productsPlans = productsPlans.concat(productsPlansArrays[l]);
                const ret = await QuoteService.postPdf(
                    file!,
                    {
                        quotationNumber: file!.name.substring(0,file!.name.length-4),
                        company: company?.id==="new" ? {
                            id: "new",
                            name: companyName,
                            country: companyCountry,
                            city: companyCity,
                            address: companyAddress,
                            zip: companyZip,
                            state: companyState,
                            phone: companyPhone,
                        } : company!,
                        contact: contact?.id==="new" ? {
                            id: "new",
                            firstname: contactFirstname,
                            lastname: contactLastname,
                            phone: contactPhone,
                            email: contactEmail,
                            country: "",
                        } : contact!,
                        expDays: validDays,
                        comments: comments,
                        productsPlans: productsPlans,
                        lineItems: lineItems,
                        currency: currency,
                    },
                    sendToCustomer,
                    true,
                    quoteType as unknown as string,
                    (closeDate as unknown as Date).toString()
                );
                if (ret.newCompany) {
                    dispatch(setCompaniesAction([ret.newCompany].concat(com)));
                    if (ret.newContact)
                        setContactsWrapper(true,[ret.newContact]);
                    setCompaniesWrapper(false,[ret.newCompany]);
                    setCompanyWrapper(ret.newCompany);
                }
                if (ret.newContact) {
                    dispatch(setContactsAction([ret.newContact].concat(con)));
                    if (!ret.newCompany)
                        setContactsWrapper(true,[ret.newContact]);
                    setContactWrapper(ret.newContact);
                }
                const quotationNumberChanged = (qn !== quotationNumber)
                setQuotationNumber(qn+1);
                setUploading(false);
                displayToast({
                    message: "Quote successfully issued",
                    severity: "success",
                    withCloseIcon: true,
                });
                if(quotationNumberChanged)
                    await displayDialog({negativeButtonText:"",positiveButtonText:"Ok",dialogText:`Quotation number of created quote has been updated: ${file!.name.substring(0,file!.name.length-4)}`});
            } catch(e) {
                setUploading(false);
                displayToast({
                    message: (e as any).message,
                    severity: "error",
                    withCloseIcon: true,
                });
            }
        }
    return (
        <div className={classes.quoteWrapper}>
            <div><div>
                <Typography variant="h5">Quote Generator</Typography>
                {(quotes?.contracts.length > 1) &&
                    <TextField
                        label="Contract Type"
                        margin="dense"
                        size="small"
                        select
                        value={contract?.name ?? ""}
                        onChange={(ev: any) => {setContract(quotes?.contracts.find(q => q.name === ev.target.value));setLanguage(getCountryLanguage(quotes?.contracts.find(q => q.name === ev.target.value)?.name??''))}}
                        variant="outlined"
                        disabled={uploading}
                    >
                        {contracts}
                    </TextField>
                }

                
                {(contract ) &&
                    <Select
                        options={companies}
                        value={company}
                        isLoading={com.length===0}
                        placeholder="Select Company..."
                        filterOption={(a:any,b:any) => {
                            if (a.label.toLowerCase().indexOf(b.toLowerCase()) >= 0)
                                return true;
                            if (a.id === "new")
                                return true;
                            return false;
                        }}
                        getOptionValue={(c: HubSpotCompany) => c.id}
                        getOptionLabel={(c: HubSpotCompany) => c.name}
                        formatOptionLabel={(c: HubSpotCompany) => c.id==="new"?<div style={{fontWeight:"bold"}}>NEW</div>:<div style={{whiteSpace:"nowrap"}}>{c.name}</div>}
                        onChange={(inp: HubSpotCompany) => {
                            setCompanyWrapper(inp);
                            if (inp && inp.id !== "new")
                                setContactsWrapper(true,con.filter(co => co.companies.includes(inp.id)));
                            else if (inp && inp.id === "new") {
                                setContactsWrapper(true,[]);
                                setContactWrapper(NEW_CONTACT);
                            } else
                                setContactsWrapper(true);
                        }}
                        isDisabled={uploading}
                    />
                }
                {(company !== null && company.id !== "new") &&
                    <FormControlLabel
                        style={{marginLeft:"2px",marginBottom:"8px",marginRight:"0px",marginTop:"-8px"}}
                        label="Override Company"
                        control={
                            <Checkbox
                                checked={overrideCompany}
                                onChange={(ev: any) => {
                                    setCompanyName(company.name);
                                    setCompanyPhone(company.phone);
                                    setCompanyCountry(company.country);
                                    setCompanyCity(company.city);
                                    setCompanyAddress(company.address);
                                    setCompanyZip(company.zip);
                                    setCompanyState(company.state);
                                    setOverrideCompnay(ev.target.checked);
                                }}
                                disabled={uploading}
                            />
                        }
                    />
                }
                {(company !== null && !overrideCompany && company.id !== "new") && [
                    <ReadonlyField
                        key="name"
                        label="Company Name"
                        value={company.name}
                    />,
                    <ReadonlyField
                        key="phone"
                        label="Phone"
                        value={company.phone}
                    />,
                    <ReadonlyField
                        key="address"
                        label="Address"
                        value={company.address}
                    />,
                    <ReadonlyField
                        key="city"
                        label="City"
                        value={company.city}
                    />,
                    <ReadonlyField
                        key="state"
                        label="State"
                        value={company.state}
                    />,
                    <ReadonlyField
                        key="zip"
                        label="Zip"
                        value={company.zip}
                    />,
                    <ReadonlyField
                        key="country"
                        label="Country"
                        value={company.country}
                    />,
                ]}
                {(company?.id === "new" || overrideCompany) && [
                    <TextField
                        key="name"
                        label="Company Name"
                        margin="dense"
                        size="small"
                        value={companyName}
                        onChange={(ev: any) => {setCompanyName(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="phone"
                        label="Phone"
                        margin="dense"
                        size="small"
                        value={companyPhone}
                        onChange={(ev: any) => {setCompanyPhone(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="address"
                        label="Address"
                        margin="dense"
                        size="small"
                        value={companyAddress}
                        onChange={(ev: any) => {setCompanyAddress(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="city"
                        label="City"
                        margin="dense"
                        size="small"
                        value={companyCity}
                        onChange={(ev: any) => {setCompanyCity(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="state"
                        label="State"
                        margin="dense"
                        size="small"
                        value={companyState}
                        onChange={(ev: any) => {setCompanyState(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="zip"
                        label="Zip"
                        margin="dense"
                        size="small"
                        value={companyZip}
                        onChange={(ev: any) => {setCompanyZip(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="country"
                        label="Country"
                        margin="dense"
                        size="small"
                        value={companyCountry}
                        onChange={(ev: any) => {setCompanyCountry(ev.target.value);}}
                        variant="outlined"
                        select
                    >
                        {contract!.countryDropdown.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
                    </TextField>,
                ]}
                {(contract) &&
                    <Select
                        options={contacts}
                        value={contact}
                        isLoading={con.length===0}
                        placeholder="Select Contact..."
                        filterOption={(a:any,b:any) => {
                            if (a.label.toLowerCase().indexOf(b.toLowerCase()) >= 0)
                                return true;
                            if (a.id === "new")
                                return true;
                            return false;
                        }}
                        getOptionValue={(c: HubSpotContact) => c.id}
                        //the search behaviour is very sensitive to this method
                        //(must be some under the hood memoization parameters that change the behaviour based on the complexity/nature of this method)
                        getOptionLabel={(c: HubSpotContact) => c.firstname&&c.lastname&&c.email && (c.firstname+" "+c.lastname+' '+ c.email)||c.email} //eslint-disable-line no-mixed-operators
                        formatOptionLabel={(c: HubSpotContact) => {
                            if (c.id==="new")
                                return (<div style={{fontWeight:"bold"}}>NEW</div>);
                            const t = (c.firstname+" "+c.lastname+' ['+c.email+']').trim();
                            return (<div style={{whiteSpace:"nowrap"}}>{t?t:c.email}</div>);
                        }}
                        onChange={(inp: HubSpotContact) => {
                            setContactWrapper(inp);
                            if (inp) {
                                const temp = com.filter(co => co.contacts.includes(inp.id));
                                if (temp.length === 1) {
                                    setCompanyWrapper(temp[0]);
                                    setContactsWrapper(true,con.filter(co => co.companies.includes(temp[0].id)));
                                    setCompaniesWrapper(false,temp);
                                } else if (temp.length === 0 && inp.id !== "new") {
                                    setCompanyWrapper(NEW_COMPANY);
                                    setCompaniesWrapper(true);
                                } else if (inp.id === "new")
                                    setCompaniesWrapper(true);
                            } else
                                setCompaniesWrapper(true);
                        }}
                        isDisabled={uploading}
                    />
                }
                {(contact !== null && contact.id !== "new") &&
                    <FormControlLabel
                        style={{marginLeft:"2px",marginBottom:"8px",marginRight:"0px",marginTop:"-8px"}}
                        label="Override Contact"
                        control={
                            <Checkbox
                                checked={overrideContact}
                                onChange={(ev: any) => {
                                    setContactFirstname(contact.firstname);
                                    setContactLastname(contact.lastname);
                                    setContactEmail(contact.email);
                                    setContactPhone(contact.phone);
                                    setOverrideContact(ev.target.checked);
                                }}
                                disabled={uploading}
                            />
                        }
                    />
                }
                {(contact !== null && !overrideContact && contact.id !== "new") && [
                    <ReadonlyField
                        key="firstname"
                        label="First Name"
                        value={contact.firstname}
                    />,
                    <ReadonlyField
                        key="lastname"
                        label="Last Name"
                        value={contact.lastname}
                    />,
                    <ReadonlyField
                        key="email"
                        label="Email"
                        value={contact.email}
                    />,
                    <ReadonlyField
                        key="phone"
                        label="Phone"
                        value={contact.phone}
                    />,
                ]}
                {(contact?.id === "new" || overrideContact) && [
                    <TextField
                        key="firstname"
                        label="First Name"
                        margin="dense"
                        size="small"
                        value={contactFirstname}
                        onChange={(ev: any) => {setContactFirstname(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="lastname"
                        label="Last Name"
                        margin="dense"
                        size="small"
                        value={contactLastname}
                        onChange={(ev: any) => {setContactLastname(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="email"
                        label="Email"
                        margin="dense"
                        size="small"
                        value={contactEmail}
                        onChange={(ev: any) => {setContactEmail(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                    <TextField
                        key="phone"
                        label="Phone"
                        margin="dense"
                        size="small"
                        value={contactPhone}
                        onChange={(ev: any) => {setContactPhone(ev.target.value);}}
                        variant="outlined"
                        disabled={uploading}
                    />,
                ]}
                <FormControl size="small" >
                    <InputLabel id="quotetype-label" style={{marginLeft:12,marginTop:-8}}>Quote Type</InputLabel>
                    <SelectMui disabled={uploading} placeholder="Quote Type" label="Quote Type" labelId="quotetype-label" onChange={(e:any)=>{
                        setQuoteType(e.target.value)
                        }} value={quoteType??''} variant="outlined" required>
                        <MenuItem value='budgetary' >Budgetary</MenuItem>
                        <MenuItem value='signature'>Final</MenuItem>
                    </SelectMui>                    
                </FormControl>
                
                <TextField
                    label="Valid Until"
                    margin="dense"
                    size="small"
                    select
                    value={validDays}
                    onChange={(ev: any) => setValidDays(ev.target.value)}
                    variant="outlined"
                    disabled={uploading}
                >
                    <MenuItem key="30_days" value={30}>30 Days</MenuItem>
                    <MenuItem key="60_days" value={60}>60 Days</MenuItem>
                    <MenuItem key="90_days" value={90}>90 Days</MenuItem>
                </TextField>

                 <DatePicker
                    label={"Close Date"+(closedateLoading?" (Loading)":"")}
                    margin="dense"
                    size="small"
                    value={closeDate}
                    onChange={(ev: any) => setCloseDate(ev)}
                    defaultValue={undefined}
                    inputVariant="outlined"
                    disabled={uploading || closedateLoading}
                    disablePast
                    format="DD-MM-YYYY"
                ></DatePicker>  

                <TextField
                    label="Comments"
                    margin="dense"
                    size="small"
                    multiline
                    rows={3}
                    value={comments}
                    onChange={(ev: any) => {setComments(ev.target.value);}}
                    variant="outlined"
                    disabled={uploading}
                />

                            <TextField
                                label="Payment Plan"
                                margin="dense"
                                size="small"
                                select
                                // value={(plans[key] && plans[key][0]) ? plans[key][0].id : ""}
                                value={plan??''}
                                onChange={(ev: any) => {
                                    setPlan(ev.target.value)
                                    
                                    const pls = JSON.parse(JSON.stringify(plans));
                                    for (const kkey of Object.keys(robotCount)){
                                    pls[kkey]=Array.from({length:robotCount[kkey]},()=> contract?.plans.find(p => p.title === ev.target.value && p.product===kkey));
                                    setPlans(pls);
                                    const tes = JSON.parse(JSON.stringify(templates));
                                    tes[kkey]=Array.from({length:robotCount[kkey]},() => JSON.parse(JSON.stringify(quotes?.freeTemplates ?? [])));
                                    setTemplates(tes);
                                    const exs = JSON.parse(JSON.stringify(extras));
                                    exs[kkey]=Array.from({length:robotCount[kkey]},() =>{return {}});
                                    for (const [planindx,plan] of pls[kkey].entries())
                                    for (const ex of plan.extras)
                                        exs[kkey][planindx][ex.id] = 0;
                                    setExtras(exs);
                                    }
                                }}
                                variant="outlined"
                                disabled={uploading || ! contract}
                            >
                                {
                                // contract?.plans.filter(p => p.product === key)
                                [...new Set(contract?.plans.map((p)=>p.title))]
                                .sort((a,b)=>{
                            if (b.includes('Standard') && !a.includes('Standard'))
                            return 1;
                            if (a.includes('Standard') && !b.includes('Standard'))
                            return -1;
                            if (b==='All Inclusive')
                            return 1;
                            if (a==='All Inclusive')
                            return -1;
                                    
                            return a>b?1:-1;
                                }).map(p => {
                                    return (<MenuItem key={p} value={p}>
                                        {p}
                                    </MenuItem>);
                                })}
                            </TextField>

                {Object.keys(robotCount).map((key, idx) => {
                    return (<div key={key+"_"+idx} style={{display: "flex", flexDirection: "row"}}>
                        <TextField
                            style={{flexGrow: 1}}
                            label={key}
                            margin="dense"
                            size="small"
                            type="number"
                            value={robotCount[key]}
                            variant="outlined"
                            onChange={(ev: any) => {
                                let num = Number(ev.target.value);
                                if (num < 0)
                                    num = 0;
                                const val = {...robotCount};
                                val[key] = Math.floor(num);
                                setRobotCount(val);
                                if (plans[key].length<Math.floor(num))
                                {
                                    if (contract?.plans.find(p => p.title === plan && p.product===key)){
                                        plans[key].push( contract?.plans.find(p => p.title === plan && p.product===key) as Plan)//plans[key][0])
                                        setPlans({...plans})
                                    }
                                    extras[key].push([])
                                    setExtras({...extras})
                                    templates[key].push([...quotes.freeTemplates])
                                    setTemplates({...templates})
                                }
                                if (plans[key].length>Math.floor(num) )
                                {
                                    plans[key].pop()
                                    setPlans({...plans})
                                    extras[key].pop()
                                    setExtras({...extras})
                                    templates[key].pop()
                                    setTemplates({...templates})
                                }
                            }}
                            disabled={uploading}
                        />
                        {(isMobile) &&
                            <IconButton
                                onClick={() => {
                                    const val = {...robotCount};
                                    val[key] = robotCount[key] + 1;
                                    setRobotCount(val);
                                    if (plans[key].length<Math.floor(val[key]))
                                {
                                    if (contract?.plans.find(p => p.title === plan && p.product===key)){
                                        plans[key].push( contract?.plans.find(p => p.title === plan && p.product===key) as Plan)//plans[key][0])
                                        setPlans({...plans})
                                    }
                                    extras[key].push([])
                                    setExtras({...extras})
                                    templates[key].push([...quotes.freeTemplates])
                                    setTemplates({...templates})
                                }
                               
                                    
                                }}
                                disabled={uploading}
                            >
                                <ArrowIcon/>
                            </IconButton>
                        }
                        {(isMobile) &&
                            <IconButton
                                onClick={() => {
                                    if (robotCount[key] <= 0)
                                        return;
                                    const val = {...robotCount};
                                    val[key] = robotCount[key] - 1;
                                    setRobotCount(val);
                                    if (plans[key].length>Math.floor(val[key]) )
                                {
                                    plans[key].pop()
                                    setPlans({...plans})
                                    extras[key].pop()
                                    setExtras({...extras})
                                    templates[key].pop()
                                    setTemplates({...templates})
                                }
                                }}
                                disabled={uploading}
                            >
                                <ArrowIcon style={{transform: "scaleY(-1)"}}/>
                            </IconButton>
                        }
                    </div>);
                })}
                {
                Object.keys(robotCount).map((key) => {
                    const robots = new Array<JSX.Element>(0);
                    for (let i = 0; i < (robotCount[key]>0?1:0); i++) {
                        robots.push(
                        <div className={classes.product} key={i}>
                            <Typography>{key}</Typography>
                            {(i === 0 && plans[key] && plans[key][i]) && <div>
                                {plans[key][i].templates.filter(t => !(!key.toLowerCase().includes("sport") || t.name.toLowerCase().includes("custom"))).map((t, idx) => {
                                    const curTemplates=templates[key][i].filter(t => t!==29)
                                    const freeTemplatesQuantity = (quotes?.freeTemplatesQty ?? 0)*robotCount[key];
                                    const free = (quotes?.freeTemplates ?? []).includes(t.id) || t.price === 0;
                                    const includedForFree = (!curTemplates.includes(t.id) && curTemplates.length >= ((quotes?.freeTemplates ?? []).length + freeTemplatesQuantity + (quotes?.allFreeAfterQty ?? 0)));
                                    const cheapest = plans[key][i].templates.filter(t => !(!key.toLowerCase().includes("sport") || t.name.toLowerCase().includes("custom"))).filter(te => templates[key][i].includes(te.id) && te.price > 0 && !(quotes?.freeTemplates ?? []).includes(te.id)).sort((a, b) => {
                                        if (a.price > b.price)
                                            return -1;
                                        if (a.price < b.price)
                                            return 1;
                                        return 0;
                                    });
                                    const selectedFree = cheapest.slice(0, cheapest.length<freeTemplatesQuantity?cheapest.length:freeTemplatesQuantity).map(te => te.id).includes(t.id);
                                    return(<div className={classes.template} key={"template_"+key+"_"+i+"_"+idx}>
                                    <Checkbox
                                        key={t.id}
                                        checked={curTemplates.includes(t.id) || curTemplates.length >= ((quotes?.freeTemplates ?? []).length + freeTemplatesQuantity + (quotes?.allFreeAfterQty ?? 0))}
                                        disabled={free || includedForFree || uploading}
                                        onChange={(ev: any) => {
                                            const tes = JSON.parse(JSON.stringify(templates));
                                            if (ev.target.checked && !tes[key][i].includes(t.id))
                                                tes[key][i].push(t.id);
                                            else if (!ev.target.checked && tes[key][i].includes(t.id))
                                                tes[key][i] = tes[key][i].filter((id: number) => id !== t.id); 
                                            setTemplates(tes);
                                        }}
                                    />
                                    <Typography>{t.name} <span style={{fontStyle:"italic"}}>{(free ? "FREE" : (formatAsMoney(t.price)+" "+plans[key][i].currency))}<span style={{fontWeight:"bold"}}>{((includedForFree || selectedFree) ? " (included\u00A0for\u00A0free)" : "")}</span></span></Typography>
                                </div>);}).concat(plans[key][i].templates.filter(t => t.name.toLowerCase().includes("custom")).map((t, idx) => {
                                        return(<div className={classes.template} key={"custom_template_"+key+"_"+i+"_"+idx}>
                                        <Checkbox
                                            key={t.id}
                                            checked={templates[key][i].includes(t.id)}
                                            disabled={uploading}
                                            onChange={(ev: any) => {
                                                const tes = JSON.parse(JSON.stringify(templates));
                                                
                                                if (ev.target.checked && !tes[key][i].includes(t.id))
                                                    tes[key][i].push(t.id);
                                                else if (!ev.target.checked && tes[key][i].includes(t.id))
                                                    tes[key][i] = tes[key][i].filter((id: number) => id !== t.id);
                                                
                                                setTemplates(tes);
                                            }}
                                        />
                                        <Typography>{t.name} <span style={{fontStyle:"italic"}}>{(formatAsMoney(t.price)+" "+plans[key][i].currency)}</span></Typography>
                                </div>);}))}
                            </div>}
                            {(plans[key] !== undefined && plans[key] !== null && plans[key][i] !== undefined && plans[key][i] !== null) && <div>
                                {plans[key][i].extras.map((e, idx) => {
                                    return(<div className={classes.extra} key={"extra_"+key+"_"+i+"_"+idx}>
                                        <Typography>{e.name+(e.id===30?(contract?.countryDropdown.includes("United States")?" (2.6 gal)":" (10L)"):"")+(e.id===34?(contract?.countryDropdown.includes("United States")?" (Max 5)":" (Max 4)"):"")} <span style={{fontStyle:"italic"}}>{e.price+"\u00A0"+plans[key][i].currency}</span></Typography>
                                        <div key={key+"_"+idx} style={{display: "flex", flexDirection: "row"}}>
                                            <TextField
                                                style={{flexGrow: 1}}
                                                margin="dense"
                                                size="small"
                                                type="number"
                                                value={extras[key][i][e.id]}
                                                variant="outlined"
                                                onChange={(ev: any) => {
                                                    let num = Number(ev.target.value);
                                                    if (num < 0)
                                                        num = 0;
                                                    const exs = JSON.parse(JSON.stringify(extras));
                                                    exs[key][i][e.id] = Math.floor(num);
                                                    setExtras(exs);
                                                }}
                                                inputProps={{min:0,max:e.id===34?(contract?.countryDropdown.includes("United States")?5:4):100}}
                                                disabled={uploading}
                                            />
                                            {(isMobile) &&
                                                <IconButton
                                                    onClick={() => {
                                                        const exs = JSON.parse(JSON.stringify(extras));
                                                        exs[key][i][e.id] = extras[key][i][e.id] + 1;
                                                        setExtras(exs);
                                                    }}
                                                    disabled={uploading}
                                                >
                                                    <ArrowIcon/>
                                                </IconButton>
                                            }
                                            {(isMobile) &&
                                                <IconButton
                                                    onClick={() => {
                                                        if (extras[key][i][e.id] <= 0)
                                                            return;
                                                        const exs = JSON.parse(JSON.stringify(extras));
                                                        exs[key][i][e.id] = extras[key][i][e.id] - 1;
                                                        setExtras(exs);
                                                    }}
                                                    disabled={uploading}
                                                >
                                                    <ArrowIcon style={{transform: "scaleY(-1)"}}/>
                                                </IconButton>
                                            }
                                        </div>
                                </div>);})}
                            </div>}
                        </div>
                        );
                    }
                    return robots;
                }
                )}
                {(errors !== "") &&
                    <div><ul>
                        {errors.split("\n").map((e, idx) => {
                            return (<li style={{color: "red"}} key={"error_"+idx}><Typography style={{color: "red"}}>{e}</Typography></li>);
                        })}
                    </ul></div>
                }
                {(
                    (Object.keys(extras).map(k => extras[k].slice(0, robotCount[k]).map(e => Object.keys(e).filter(k2 => e[k2 as any] > 0).length).filter(e => e > 0).length).filter(e => e > 0).length > 0 ||
                    Object.keys(templates).map(k => templates[k].slice(0, robotCount[k]).map(t => t.length).filter(t => t > 3).length).filter(t => t > 0).length > 0) &&
                    Object.keys(plans).map(k => plans[k].slice(0, robotCount[k]).map(p => p.plan.length).filter(p => p > 1).length).filter(t => t > 0).length > 0
                ) &&
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                        <Checkbox
                            checked={splitExtras}
                            onChange={(ev: any) => {setSplitExtras(ev.target.checked)}}
                            disabled={uploading}
                        />
                        <Tooltip title="If not checked, extras and templates will be included in Year 1, otherwise it is split evenly amongst all."><Typography>Split Extras</Typography></Tooltip>
                    </div>
                }
                <FormControlLabel
                    style={{marginLeft:"2px",marginBottom:"0px",marginRight:"0px"}}
                    label="Send to Customer in Email"
                    control={
                        <Checkbox
                            checked={sendToCustomer}
                            onChange={(ev: any) => {setSendToCustomer(ev.target.checked)}}
                            disabled={uploading}
                        />
                    }
                />
                <Button
                    className={classes.button}
                    size="medium"
                    variant="outlined"
                    disabled={!!errors || uploading || closedateLoading}
                    onClick={()=>generateContract(lineItemss.current)}

                >
                    Generate Contract
                </Button>
            </div></div>
            <div>
                <object style={{width: "100%", height: "100%"}} title="pdf preview">
                    {/* {(isChrome && !isMobile) */}
                        {/* ?  */}
                        {/* <iframe src={pdfPreview+'#toolbar=0'} style={{width: "100%", height: "100%"}} title="pdf preview"/> */}
                        {/* :  */}
                        <PdfPreview src={pdfPreview} style={{width: "100%", height: "100%"}}/>
                    {/* } */}
                </object>
            </div>
        </div>
    );
}
