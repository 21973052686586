import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import {Chip as MuiChip} from '@mui/material';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import OrangeButton from "../Components/OrangeButton.jsx"

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

import dateFormat from 'dateformat';
import moment from 'moment';
export default function EditFeaturePopup(props) {
    const [open, setOpen] = React.useState(false);

    const [tag, setTag] = React.useState('');
    const [featureDescription, setFeatureDescription] = React.useState('');
    const [featureGroup, setFeatureGroup] = React.useState(null);
    const [featureGroups, setFeatureGroups] = React.useState([]);

    const [options, setOptions] = React.useState([]);
    const [chosenOptionTag, setChosenOptionTag] = React.useState('');
    const [chosenDescription, setChosenDescription] = React.useState('');
    const [chosenOption, setChosenOption] = React.useState(-1);

    function compareMetadata(a, b) {
        if (a.o < b.o) {
            return -1;
        }
        if (a.o > b.o) {
            return 1;
        }

        if (a.k < b.k) {
            return -1;
        }
        if (a.k > b.k) {
            return 1;
        }

        if (a.v < b.v) {
            return -1;
        }
        if (a.v > b.v) {
            return 1;
        }
        return 0;
    }
    const [metadata, setMetadata] = React.useState([]);
    const [chosenMetadataObject, setChosenMetadataObject] = React.useState(null);

    let now = new Date();
    let now_s = dateFormat(now, "yyyy-mm-dd");

    const updateFeatureGroups = () => {
        axios("/api/dbtool/featuregroups")
            .then(response => response.data[0])
            .then(state => {
                if (props.feature) {
                    let chosenGroup = state.filter(f => {
                        return f.Id === props.feature.GroupId;
                    })[0];
                    setFeatureGroup(chosenGroup);
                }

                setFeatureGroups(state);
            });
    };

    const handleClickOpen = () => {
        now = new Date();
        now_s = dateFormat(now, "yyyy-mm-dd");
        // Update selects
        updateFeatureGroups();

        if (props.feature) {
            setTag(props.feature.Tag);
            setFeatureDescription(props.feature.Description);
            setOptions(props.feature.Options.map(o => {
                return { t: o.Tag, d: o.description, Id: o.Id, created: o.created, deleted: o.deleted };
            }));
            setMetadata([...props.feature.MetaData]);
        } else {
            setTag('');
            setFeatureDescription('');
            setFeatureGroup(null);
            setOptions([]);
            setMetadata([]);
        }

        setChosenOptionTag('');
        setChosenDescription('');
        setChosenOption(-1);
        setChosenMetadataObject(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeChosenOptionTag = (value) => {
        setChosenOptionTag(value);
        if (value && chosenOption === -1) {
            let new_element = {
                t: value,
                d: chosenDescription
            }
            options.push(new_element);
            let new_id = options.length - 1;
            setOptions(options);
            setChosenOption(new_id);
        } else if (chosenOption >= 0) {
            options[chosenOption].t = value;
        }
    };
    const changeChosenOptionDescription = (value) => {
        setChosenDescription(value);
        if (chosenOption >= 0) {
            options[chosenOption].d = value;
            setOptions(options);
        }
    };

    const changeChosenMetaDataKey = (new_key) => {
        if (new_key && chosenMetadataObject === null) {
            let new_element = {
                k: new_key,
                v: '',
                o: 0,
                f: now_s,
                t: ''
            }
            metadata.push(new_element);
            let new_metadata = metadata.sort(compareMetadata);
            setMetadata(new_metadata);
            setChosenMetadataObject(new_element);
        } else if (chosenMetadataObject) {
            let replace_index = metadata.indexOf(chosenMetadataObject);
            let new_cmo = { ...chosenMetadataObject };
            new_cmo.k = new_key;

            metadata.splice(replace_index, 1, new_cmo);
            let new_metadata = metadata.sort(compareMetadata);

            setChosenMetadataObject(new_cmo);
            setMetadata(new_metadata);
        }
    };
    const changeChosenMetaDataValue = (value) => {
        if (chosenMetadataObject) {
            let replace_index = metadata.indexOf(chosenMetadataObject);
            let new_cmo = { ...chosenMetadataObject };
            new_cmo.v = value;
            metadata.splice(replace_index, 1, new_cmo);
            let new_metadata = metadata.sort(compareMetadata);

            setChosenMetadataObject(new_cmo);
            setMetadata(new_metadata);
        }
    };
    const changeChosenMetaDataOrder = (value) => {
        if (chosenMetadataObject) {
            let replace_index = metadata.indexOf(chosenMetadataObject);
            let new_cmo = { ...chosenMetadataObject };
            new_cmo.o = parseInt(value);
            metadata.splice(replace_index, 1, new_cmo);
            let new_metadata = metadata.sort(compareMetadata);

            setChosenMetadataObject(new_cmo);
            setMetadata(new_metadata);
        }
    };
    const changeChosenMetaDataFrom = (value) => {
        if (!value)
            value = now_s;
        if (chosenMetadataObject) {
            let replace_index = metadata.indexOf(chosenMetadataObject);
            let new_cmo = { ...chosenMetadataObject };
            new_cmo.f = value;
            if (chosenMetadataObject.t && value > chosenMetadataObject.t)
                new_cmo.t = value;
            metadata.splice(replace_index, 1, new_cmo);
            let new_metadata = metadata.sort(compareMetadata);

            setChosenMetadataObject(new_cmo);
            setMetadata(new_metadata);

        }
    };
    const changeChosenMetaDataTo = (value) => {
        if (chosenMetadataObject) {
            let replace_index = metadata.indexOf(chosenMetadataObject);
            let new_cmo = { ...chosenMetadataObject };
            new_cmo.t = value;
            if (value && value < chosenMetadataObject.f)
                new_cmo.f = value;
            metadata.splice(replace_index, 1, new_cmo);
            let new_metadata = metadata.sort(compareMetadata);

            setChosenMetadataObject(new_cmo);
            setMetadata(new_metadata);
        }
    };

    const deleteOption = (optionId) => {
        let new_options = options.slice();
        if (optionId >= 0) {
            new_options[optionId].deleted = moment().utc().format("YYYY-MM-DD HH:mm:ss");
            setOptions(new_options);
        }
    };
    const restoreOption = (optionId) => {
        let new_options = options.slice();
        if (optionId >= 0) {
            new_options[optionId].deleted = null;
            setOptions(new_options);
        }
    };

    const removeOption = (optionId) => {
        let new_options = options.slice();
        new_options.splice(optionId, 1);
        setOptions(new_options);
        if (optionId === chosenOption) {
            optionClick(-1);
        } else if (optionId < chosenOption) {
            optionClick(chosenOption - 1);
        }
    };

    const optionClick = (optionId) => {
        if (optionId === -1) {
            setChosenOption(-1);
            setChosenOptionTag('');
            setChosenDescription('');
        } else {
            setChosenOption(optionId);
            setChosenOptionTag(options[optionId].t);
            setChosenDescription(options[optionId].d);
        }
    };

    const MetadataClick = (metadataObject) => {
        setChosenMetadataObject(metadataObject);
    };

    const removeMetaData = (metadataId) => {
        let new_options = metadata.slice();
        new_options.splice(metadataId, 1);
        setMetadata(new_options);
        if (metadataId === metadata.indexOf(chosenMetadataObject)) {
            MetadataClick(null);
        }
    };

    const handleSubmit = () => {
        let data = {
            t: tag,
            d: featureDescription,
            fgid: featureGroup.Id,
            o: options,
            m: metadata
        }
        if (props.feature)
            data.Id = props.feature.Id;

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: data
        };
        axios("/api/dbtool/feature", requestOptions)
            .then(response => response.data)
            .then(state => {
                if (state.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert(state.msg??"Could not edit or create feature");
            });
    };
    return (
        <>
            {
                props.feature ? (<IconButton aria-label="Edit feature" component="span" onClick={handleClickOpen} >
                    <Edit />
                </IconButton>) : (<OrangeButton title="Create feature" onClick={handleClickOpen} />)
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>{props.feature ? "Edit feature" : "Create feature"}</DialogTitle>
                <DialogContent style={{ overflowY: "visible" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                id="tag"
                                label="Tag"
                                type="text"
                                style={{ width: "100%" }}
                                value={tag}
                                onChange={(e) => setTag(e.target.value.toLowerCase().replace(" ", "_"))}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={featureGroups}
                                getOptionLabel={(option) => option["Name"]}
                                renderInput={(params) => <TextField {...params} label="Feature group" />}
                                onChange={(e, v) => setFeatureGroup(v)}
                                value={featureGroup}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                label="Description"
                                type="text"
                                multiline
                                style={{ width: "100%" }}
                                value={featureDescription ? featureDescription : ""}
                                onChange={(e) => setFeatureDescription(e.target.value)}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            {(options.map((o, i) => {
                                if (o.Id >= 0 && !o.deleted)
                                    return (<Chip style={{ margin: "5px" }} label={o.t} icon={<Edit />} onDelete={deleteOption.bind(this, i)} onClick={optionClick.bind(this, i)} key={i} />)
                                else if (!o.deleted)
                                    return (<Chip style={{ margin: "5px" }} label={o.t} onDelete={removeOption.bind(this, i)} onClick={optionClick.bind(this, i)} key={i} />)
                                return <></>
                            }
                            ))}

                            <Chip style={{ margin: "5px" }} label="New option" onClick={optionClick.bind(this, -1)} />
                        </Grid>

                        <Paper elevation={3}
                            style={{ width: "100%", padding: "20px" }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {chosenOption >= 0 ? <>Edit option</> : <>Create new option</>}
                            </Typography>
                            <TextField
                                id="option_tag"
                                label="Option"
                                type="text"
                                style={{ width: "100%" }}
                                value={chosenOptionTag}
                                onChange={(e) => changeChosenOptionTag(e.target.value.toLowerCase().replace(" ", "_"))}
                            />
                            <TextField
                                id="open_description"
                                label="Description"
                                type="text"
                                style={{ width: "100%" }}
                                value={chosenDescription}
                                onChange={(e) => changeChosenOptionDescription(e.target.value)}
                            />                            
                        </Paper>

                        <Grid item xs={12}>
                            {options.filter(o => o.deleted).length !== 0 && <Typography variant="h6" gutterBottom>Options to delete:</Typography>}
                            {(options.map((o, i) => {
                                if (o.Id >= 0 && o.deleted)
                                    return (<MuiChip style={{ margin: "5px" }} label={o.t} color='error' onDelete={restoreOption.bind(this, i)} key={i} />)
                                else
                                    return <></>
                            }
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            {(metadata.map((m, i) => {
                                let color = "primary";
                                if (m.f > now_s)
                                    color = "secondary";
                                return (<Chip style={{ margin: "5px" }} color={color} label={m.k} onDelete={removeMetaData.bind(this, i)} onClick={MetadataClick.bind(this, m)} key={i} />)
                            }
                            ))}

                            <Chip style={{ margin: "5px" }} label="New metadata" onClick={MetadataClick.bind(this, null)} />
                        </Grid>

                        <Paper elevation={3}
                            style={{ width: "100%", padding: "20px" }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {chosenMetadataObject ? <>Edit metadata</> : <>Create new metadata</>}
                            </Typography>
                            <TextField
                                id="metadata_key"
                                label="Key"
                                type="text"
                                style={{ width: "100%" }}
                                value={chosenMetadataObject ? chosenMetadataObject.k : ''}
                                onChange={(e) => changeChosenMetaDataKey(e.target.value)}
                            />
                            <TextField
                                id="metadata_value"
                                disabled={chosenMetadataObject ? false : true}
                                label="Value"
                                type="text"
                                style={{ width: "100%" }}
                                value={chosenMetadataObject ? chosenMetadataObject.v : ''}
                                onChange={(e) => changeChosenMetaDataValue(e.target.value)}
                            />
                            <TextField
                                id="metadata_order"
                                disabled={chosenMetadataObject ? false : true}
                                label="Order"
                                type="number"
                                style={{ width: "100%" }}
                                value={(chosenMetadataObject && chosenMetadataObject.o) ? chosenMetadataObject.o : 0}
                                onChange={(e) => changeChosenMetaDataOrder(e.target.value)}
                            />
                            <TextField
                                id="date"
                                disabled={chosenMetadataObject ? false : true}
                                label="Start date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={chosenMetadataObject ? chosenMetadataObject.f : now_s}
                                onChange={(e) => changeChosenMetaDataFrom(e.target.value)}
                                style={{ width: "100%" }}
                            />
                            <TextField
                                id="date"
                                disabled={chosenMetadataObject ? false : true}
                                label="End date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={(chosenMetadataObject && chosenMetadataObject.t) ? chosenMetadataObject.t : ''}
                                onChange={(e) => changeChosenMetaDataTo(e.target.value)}
                                style={{ width: "100%" }}
                                required
                            />
                        </Paper>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        {props.feature ? "SAVE" : "CREATE"}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
