import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { isArray } from "lodash";
import { setQueryStringWithoutPageReload } from "src/hooks/useQueryString";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
);

export interface NavListItemProps
    extends Omit<ListItemProps, "component" | "selected"> {
    text: string;
    textProps?: ListItemTextProps;
    TextIcon?: React.ElementType;
    activeOnlyWhenExact?: boolean;
    to: string;
    activeOn?: string | string[];
    inactiveOn?: string | string[];
    clearQuery?: boolean;
}

export default function NavListItem(props: NavListItemProps) {
    const classes = useStyles();
    const {
        text,
        textProps,
        TextIcon,
        activeOnlyWhenExact,
        to,
        activeOn = [],
        inactiveOn = [],
        clearQuery = false,
        disabled = false,
        ...rest
    } = props;
    const activeList = Array.isArray(activeOn) ? activeOn.concat([to]) : [activeOn, to];
    let match = (useRouteMatch({path: activeList, exact: activeOnlyWhenExact})) !== null;
    const path = window.location.pathname;
    const inactiveList = isArray(inactiveOn) ? inactiveOn : [inactiveOn];
    if (inactiveList.includes(path))
        match = false;
    (rest as any).disabled = disabled;
    let styleOverride = {};
    if (clearQuery && window.location.pathname === to)
        styleOverride = {cursor: "pointer"};
    if (disabled)
        styleOverride = {cursor: "default"};
    return (
        <ListItem
            style={styleOverride}
            onClick={() => {if (clearQuery && !disabled) setQueryStringWithoutPageReload("");}}
            className={classes.root}
            component={(disabled || (clearQuery && window.location.pathname === to)) ? undefined : RouterLink}
            to={to}
            selected={match}
            {...(rest as any)}
        >
            {TextIcon && (
                <ListItemIcon color="black">
                    <TextIcon />
                </ListItemIcon>
            )}
            <ListItemText {...(textProps ? textProps : {})}>
                {text}
            </ListItemText>
        </ListItem>
    );
}
