import React from "react";
import { TableResizerProps } from "react-table";
import classNames from "../../utils/classNames";

export interface ColumnResizerProps {
    getResizerProps: (props?: TableResizerProps) => TableResizerProps;
    isResizing?: boolean;
}

export default React.memo(function ColumnResizer(props: ColumnResizerProps) {
    const { getResizerProps, isResizing } = props;

    return (
        <div
            {...getResizerProps({
                className: classNames(
                    {
                        "handle-active": isResizing,
                    },
                    "dg-resizer"
                ),
            })}
        />
    );
});
