import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import {  useSelector } from "react-redux";
import type { Tablet } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler,  useToast } from "src/hooks";
import { customersDtoSelector,  tabletsSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import KnoxService from "src/services/KnoxService";

interface SendTabletNotificationInput {
    tablet: Tablet | null;
    msg: string;
}


function sendTabletNotification(input: SendTabletNotificationInput) {
    const { tablet,msg} = input;
    if (tablet)
    return KnoxService.sendNotification(
        tablet.id,
        "Missing Payment",
        msg
).then(() => input);
else return Promise.reject("Tablet not found");
}

export default function SendTabletNotificationWidget({ widgetName, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        reset,
        watch,
        register
    } = useForm<SendTabletNotificationInput>({
        defaultValues: { tablet: null,msg:"" },
    });

    const tablets = useSelector(tabletsSelector);
    const customersDto = useSelector(customersDtoSelector);

    useEffect(()=>{
        register("msg",{})
    },[register])

    const messageUs=`
    We need to speak with you regarding a past-due invoice.  To avoid interruption in your service, please contact us ASAP.

    finance@tinymobilerobots.com
    404-595-1145

    `
    const messageRest=`
    We need to speak with you regarding a missing invoice payment. To avoid interruption in your service, please contact us ASAP.

    finance@tinymobilerobots.com
    +45 35 15 62 29

    `        
    const { displayToast } = useToast();
    const onComplete = useCallback(
        (input: SendTabletNotificationInput) => {
            displayToast({
                message: `Successfully sent notofication to Tablet ${
                    input.tablet!.id
                }`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast]
    );

    const { exec, pending: sendingNotification, error } = useAsync(
        sendTabletNotification,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = sendingNotification || tablets === null;
    
    const message = watch("msg");
    return (
        <BaseWidget
            widgetName={widgetName}
            title="Send notification"
            subTitle="Send notification to a tablet about missed payment"
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
            
        >
            <Controller
                name="tablet"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...rest }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...rest}
                            disabled={loading}
                            options={tablets}
                            getOptionLabel={(t) => {
                                const customer = customersDto[t.customerId ?? -1];
                                let ret = t.id;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+t.customerId+"]";
                                return ret;
                            }}
                            getOptionSelected={(a, b) => a.id === b.id}
                            noOptionsText="No such tablet"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Tablet"
                                        placeholder="Choose a tablet"
                                        required
                                        {...getErrorProps(errors, "tablet")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                onChange(val);
                                if (customersDto[val.customerId ?? -1]?.countries?.includes("United States"))
                                    setValue("msg", messageUs);
                                else
                                    setValue("msg", messageRest);
                            }}
                        />
                    );
                }}
            />
            <TextField value={message} disabled fullWidth multiline></TextField>
            
        </BaseWidget>
    );
}
