import React from "react";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import GrowIn from "src/components/GrowIn";
import { useSelector } from "react-redux";
import { userAccessSelector } from "src/redux/auth/selectors";
import NotFound from "../NotFound";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    titleText: {
        marginBottom: "30px",
    },
}));

export default function DealerMaterials() {
    const classes = useStyles();
    const dealerMaterialsAccess = useSelector(userAccessSelector).includes("DealerMaterials")
    if(!dealerMaterialsAccess)
        return <NotFound/>
    return (
        <GrowIn>
            <Container
                className={classes.root}
                component="main"
                maxWidth={false}
            >
                <Typography
                    component="h1"
                    variant="h5"
                    className={classes.titleText}
                >
                    Dealer Materials
                </Typography>
                <Box mt={1}>
                    <Typography>Click below to access:</Typography>
                    <ul>
                        <li><Typography>Sales and Marketing Materials (images, videos, animations, flyers etc.)</Typography></li>
                        <li><Typography>Customer Materials (User Manuals, Quick Start Guides, Instruction videos etc.)</Typography></li>
                        <li><Typography>General Information (Internal Guides, Application Notes etc.)</Typography></li>
                    </ul>
                </Box>
                <Box>
                    <a href="https://drive.google.com/drive/folders/13lxuWDNIGbBJqAGnQ1uE5civjfh762ip" target="_blank" rel="noopener noreferrer">TinyLineMarker Materials</a>
                    <br/><br/>
                    <a href="https://drive.google.com/drive/folders/11lOuyTWqcHxXGi_QtRDNPezf58hdyadf" target="_blank" rel="noopener noreferrer">TinySurveyor Materials</a>
                </Box>
            </Container>
        </GrowIn>
    );
}
