import React from "react";
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
} from "@material-ui/core/styles";
import classNames from "../utils/classNames";
import { keyFrames } from "../theme";

export interface GrowInProps {
    duration?: number | string;
}

function mapDuration(keyFrame: string, props: GrowInProps) {
    if (typeof props.duration === "string") {
        return props.duration;
    }

    const animationDuration = props.duration || 500;
    return keyFrame === keyFrames.defaultGrow
        ? animationDuration * 0.66
        : animationDuration;
}

const styles = (theme: Theme) =>
    createStyles({
        growAnimation: {
            animation: (props: GrowInProps) => {
                return [keyFrames.defaultGrow, keyFrames.defaultShow]
                    .map((keyFrame) =>
                        theme.transitions.create(keyFrame, {
                            duration: mapDuration(keyFrame, props),
                        })
                    )
                    .join(",");
            },
        },
    });

export default withStyles(styles)(function GrowIn({
    classes,
    children,
}: { children: React.ReactNode } & WithStyles<typeof styles>) {
    const childElement = React.Children.only(children) as React.ReactElement;
    return React.cloneElement(childElement, {
        className: classNames(
            childElement.props.className,
            classes.growAnimation
        ),
    });
});
