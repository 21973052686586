import React from 'react';

import SectionCollapse from "./Components/SectionCollapse.jsx";
import ManageAccessGroupsSection from "./ManageAccessGroupsSection.jsx";
import ManageFeatureAndPricingsSection from "./ManageFeatureAndPricingsSection.jsx";
import ManageFeaturesSection from "./ManageFeaturesSection.jsx";
import ManageVatSection from "./ManageVatSection.jsx";
import ManageTabletsFeaturesSection from "./ManageTabletsFeaturesSection.jsx";
import ManagePurchasesSection from "./ManagePurchasesSection.jsx";
import ManageServerDnsSection from "./ManageServerDnsSection.jsx";
import ManageCustomerAccessGroupsSections from "./ManageCustomerAccessGroupsSections.jsx";
import ManageRobotAccessGroupsSections from "./ManageRobotAccessGroupsSections.jsx";
import ManageRobotProductModelOverwrite from "./ManageRobotProductModelOverwrite.jsx";
import ManageAppVersions from "./ManageAppVersionsSection.jsx";
import ManageRobotSoftwareVersionSection from "./ManageRobotSoftwareVersionSection.jsx";
import {userAccessSelector } from "src/redux/auth/selectors";
import { useSelector } from "react-redux";
import NotFound from "../NotFound";



export default function Sections(props) {
     
    const content_style = { margin:"0px 50px"}
    
    const access=useSelector(userAccessSelector);
    
    if (!access.some(str => str.includes("DbTool"))) return (<NotFound />);
    
    return (
        <>
            {/* <Topbar /> */}
            <div style={content_style}>
                { access.includes("DbToolAppVersions") && <SectionCollapse header={"Manage App versions"} content={(<ManageAppVersions />)} />}
                { access.includes("DbToolRobotSoftwareVersion") && <SectionCollapse header={"Manage RobotSoftware versions"} content={(<ManageRobotSoftwareVersionSection />)} />}
                { access.includes("DbToolServerDNS") && <SectionCollapse header={"Manage Server DNS"} content={(<ManageServerDnsSection />)} />}
                { access.includes("DbToolPurchases") && <SectionCollapse header={"Manage Purchases"} content={(<ManagePurchasesSection />)} />}
                { access.includes("DbToolAccessGroups") && <SectionCollapse header={"Manage access groups"} content={(<ManageAccessGroupsSection />)} />}
                { access.includes("DbToolFeatureGroupsAndPricings") && <SectionCollapse header={"Manage feature groups and their pricings"} content={(<ManageFeatureAndPricingsSection />)} />}
                { access.includes("DbToolFeatures") && <SectionCollapse header={"Manage features"} content={(<ManageFeaturesSection />)} />}
                { access.includes("DbToolTabletFeatures") && <SectionCollapse header={"Manage Tablet Features"} content={(<ManageTabletsFeaturesSection />)} />}
                { access.includes("DbToolVAT") && <SectionCollapse header={"Manage VAT"} content={(<ManageVatSection />)} />}
                { access.includes("DbToolCustomerAccessGroups") && <SectionCollapse header={"Customer Accessgroups"} content={(<ManageCustomerAccessGroupsSections />)} />}
                { access.includes("DbToolRobotExtraAccessGroups") && <SectionCollapse header={"Robot Extra Accessgroups"} content={(<ManageRobotAccessGroupsSections />)} />}
                { access.includes("DbToolRobotModelOverwrite") && <SectionCollapse header={"Manage Robot model overwrite"} content={(<ManageRobotProductModelOverwrite />)} />}
            </div>
        </>
    );
}