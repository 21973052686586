import React, { useMemo } from "react";
import { makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import KeyGroup from "./KeyGroup";
import { useSection } from "./SectionProvider";
import { DeviceKeyGroup } from "src/services/DeviceDataService";

const useStyles = makeStyles(() => ({
    group: {
        flexGrow: 0,
    },
}));

function Items({ deviceKeyGroup }: { deviceKeyGroup: DeviceKeyGroup[] }) {
    const classes = useStyles();
    return (
        <>
            {deviceKeyGroup.map((group) => (
                <Grid key={group.id} item xs className={classes.group}>
                    <KeyGroup {...group} />
                </Grid>
            ))}
        </>
    );
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
}));

function SectionBody() {
    const classes = useStyles1();
    const isLargeScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg")
    );

    const { groups, deviceTypeId, deviceId } = useSection();

    const columns_ = useMemo(() => {
        const tempGroups = [...groups];
        const desiredColumns = isLargeScreen ? 3 : 2;
        const columns: DeviceKeyGroup[][] = [];

        for (let i = 0; i < desiredColumns; ++i) {
            const columnSize = i
                ? Math.round(groups.length / desiredColumns)
                : Math.ceil(groups.length / desiredColumns);
            columns.push(tempGroups.splice(0, columnSize));
        }

        return columns.map((col, idx) => (
            <Grid
                key={idx}
                container
                direction="column"
                justify="flex-start"
                spacing={1}
                item
                xs={12}
                md={6}
                lg={4}
            >
                <Items deviceKeyGroup={col} />
            </Grid>
        ));
    }, [groups, isLargeScreen]);

    return (
        <Grid
            key={`body-${deviceTypeId}/${deviceId}`}
            container
            direction="row"
            spacing={1}
            justify="center"
            className={classes.root}
        >
            {columns_}
        </Grid>
    );
}

SectionBody.whyDidYouRender = true;

export default React.memo(SectionBody);
