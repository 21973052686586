import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import EditTabletFeaturePopup from "./popups/EditTabletFeaturePopup.jsx";

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

function FeatureCell(props) {
    return (<>
        {props.features.map((feat, i) => {

            let opts = "";
            if (feat.Options.length) {
                let option_lines = feat.Options.map((o, i) => {
                    return <div key={o.Id}>{o.Name}<br /></div>
                });

                opts = <div>Options:<br />{option_lines}</div>
            } else
                opts = "No options";
            return (
                <Tooltip title={opts} key={feat.Id}>
                    <Chip label={feat.Name} />
                </Tooltip>)
        }
        )}
    </>);
}

class ManageTabletsFeaturesSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tablets: []
        };
        this.refreshTabletFeatures = this.refreshTabletFeatures.bind(this);

        this.headers = [{
            Header: 'TabletID',
            key: 'idElement',
            align: "left",
            style: small_style,
            search: "idElement"
        },
        {
            Header: 'Features',
            key: 'FeatureElement',
            align: "left",
            style: big_style,
            search: "features",
            ArraySeach: "Name"
        },
        {
            Header: '',
            key: 'EditElement',
            align: "left",
            style: small_style
        }
        ];
    }

    componentDidMount() {
        this.refreshTabletFeatures()
    }

    refreshTabletFeatures(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/tabletfeatures`)
            .then(response => response.data[0])
            .then(state => {
                let tablets = {};
                for (let f of state) {
                    if (!tablets[f.id]) {
                        tablets[f.id] = { Id: f.id, idElement: f.id, features: {} };
                        tablets[f.id].EditElement = <EditTabletFeaturePopup tablet={tablets[f.id]} whenSuccess={this.refreshTabletFeatures.bind(this)} />;
                    }
                    if (f.FeatureId) {
                        if (!tablets[f.id].features[f.FeatureId]) {
                            tablets[f.id].features[f.FeatureId] = {
                                Id: f.FeatureId,
                                Name: f.FeatureName,
                                Options: []
                            }
                        }
                        if (f.FeatureOptionId)
                            tablets[f.id].features[f.FeatureId].Options.push({ Id: f.FeatureOptionId, Name: f.Option });
                    }
                }
                tablets = Object.values(tablets);
                tablets.forEach(t => {
                    t.features = Object.values(t.features);
                    t.FeatureElement = <FeatureCell features={t.features} />
                });
                this.setState({ tablets: Object.values(tablets) })
            })
    }


    render() {
        return (
            <React.Fragment>
                <Table id="tablet_features_table" headeres={this.headers} data={this.state.tablets} refresh={this.refreshTabletFeatures} />
            </React.Fragment>
        );
    }
}

export default ManageTabletsFeaturesSection;