import { Location } from "history";
import { RouterState } from "connected-react-router";
import { createSelector } from "reselect";

type StateProxy = { router: RouterState };

export const locationSelector = (s: StateProxy) => s.router.location;
export const locationStateSelector = createSelector(
    locationSelector,
    (loc) => loc.state
);

export const redirectedFromSelector = createSelector(
    locationStateSelector,
    (state: any) =>
        state
            ? {
                  from: state.from as Location,
                  message: state.message as string | undefined,
              }
            : {}
);
