import {  makeStyles, MenuItem,  TextField} from "@material-ui/core";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync,  useResizeObserver, useToast } from "src/hooks";
import MondayService from "src/services/MondayService";
import { TimeFormats } from "src/utils/constants";
import { timezone } from "src/App";
import {  userAccessSelector } from "src/redux/auth/selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "100%",
        width: "calc(100% - 20px)",
        display: "flex",
        flexDirection: "column",
        '& *': {
            fontFamily: theme.typography.fontFamily,
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    control: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginBottom: "10px",
    },
    header: {
        width: "calc(100% - 2px)",
        border: "solid 2px black",
        '&>div': {
            width: "calc(100% - 2px)",
            display: "flex",
            flexDirection: "row",
            '&>div': {
                fontWeight: "bold",
                borderRight: "solid 2px black",
                padding: "1px",
            },
            '&>div:nth-child(1)': {
                width: "80px",
                flexShrink: 0,
            },
            '&>div:nth-child(2)': {
                width: "80px",
                flexShrink: 0,
            },
            '&>div:nth-child(3)': {
                flexGrow: 1,
                display: "flex",
                flexDirection: "row",
                '&>div': {
                    padding: "1px",
                    borderRight: "solid 2px black",
                },
                '&>div:last-child': {
                    borderRight: "none",
                },
                padding: "0px",
                borderRight: "none",
            },
        },
    },
    table: {
        width: "100%",
        flexGrow: 1,
        maxHeight: "calc(100vh - 130px)",
        overflowY: "auto",
        borderRight: "solid 2px black",
        borderBottom: "solid 2px black",
        '&>div': {
            width: "calc(100% - 2px)",
            display: "flex",
            flexDirection: "column",
            '&>div': {
                backgroundColor: "#EEEEEE",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                border: "solid 2px black",
                borderTop: "none",
                borderRight: "none",
                '&>div:nth-child(1)': {
                    width: "80px",
                    flexShrink: 0,
                    padding: "1px",
                    borderRight: "solid 2px black",
                    paddingTop: "5px",
                },
                '&>div:nth-child(2)': {
                    width: "80px",
                    flexShrink: 0,
                    padding: "1px",
                    borderRight: "solid 2px black",
                    paddingTop: "5px",
                },
                '&>div:nth-child(3)': {
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "row",
                    '&>div': {
                        borderRight: "solid 2px black",
                        paddingTop: "5px",
                        paddingLeft: "20px",
                        paddingRight: "10px",
                        paddingBottom: "10px",
                    },
                    '&>div:last-child': {
                        borderRight: "none",
                    },
                },
            },
            '&>div:last-child': {
                borderBottom: "none",
            },
            '&>div:nth-child(2n+1)>div': {
                backgroundColor: "#CCCCCC",
            },
        },
    },
    filter: {
        minHeight: "40px",
        maxHeight: "72px",
        marginTop: "5px",
        marginBottom: "5px",
        display: "flex",
        justifyContent: "start",
        alignItems: "top",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: "5px",
        width: "auto",
        minWidth: "300px",
        overflowX: "visible",
        overflowY: "auto",
        marginRight: "10px",
        "&>*": {
            cursor: "pointer",
            textAlign: "center",
            paddingLeft: "3px",
            paddingRight: "3px",
            height: "20px",
            border: "1px solid black",
            borderRadius: "10px",
            fontFamily: theme.typography.fontFamily,
            marginRight: "3px",
            marginBottom: "1px",
            marginTop: "1px",
            whiteSpace: "nowrap",
        },
        "&>*:hover": {
            backgroundColor: "#FF9999",
        },
        "&>*:active": {
            backgroundColor: "#FF8888",
        },
    },
}));

export default function Changelog() {
    const classes = useStyles();

    const { displayToast } = useToast();

    
    
    const [deviceTypeId, setDeviceTypeId] = useState(2);
    const isTechnical  = useSelector(userAccessSelector).includes('ChangelogTecnical');
    
    const [hideProductTags, setHideProductTags] = useState(false);
    const [filteredTypes, setFilteredTypes] = useState([] as string[]);
    const [types, setTypes] = useState([] as Array<string>);

    const fetchData = useCallback(() => MondayService.getSoftwareVersions(deviceTypeId ?? 2), [deviceTypeId]);
    const { value: data, exec } = useAsync(fetchData, {
        immediate: false,
        onComplete: (val) => {
            const t = new Array<string>(0);
            for (const entry of val ?? []) {
                for (const issue of entry.issues)
                    for (const type of issue.product)
                        if (!t.includes(type))
                            t.push(type);
                for (const feature of entry.features)
                    for (const type of feature.product)
                        if (!t.includes(type))
                            t.push(type);
            }
            t.sort();
            setTypes(t);
        },
        onError: (val : any) => displayToast({message: val.message, severity: "error", withCloseIcon: true }),
    });

    useEffect(() => {exec();},[deviceTypeId]); //eslint-disable-line react-hooks/exhaustive-deps

    const filter = useCallback((product: Array<string> | undefined) => {
        if (product === undefined || filteredTypes === undefined || product.length === 0 || filteredTypes.length === 0)
            return true;
        for (const p of product)
            if (filteredTypes.includes(p))
                return true;
        return false;
    }, [filteredTypes]);

    const table = useMemo<JSX.Element[]>(() => {
        const rows = new Array<JSX.Element>(0);
        for (const entry of data ?? []) {
            const issues = new Array<JSX.Element>(0);
            let key = 0;
            for (const issue of entry.issues.filter((is: any) => filter(is.product))) {
                if ((!isTechnical) && (issue.translated === undefined || issue.translated === null || issue.translated.trim() === ""))
                    continue;
                const colored = isTechnical && (issue.translated === undefined || issue.translated === null || issue.translated.trim() === "");
                if (issue.product.length === 0 || hideProductTags)
                    issues.push(<li key={key++} style={colored?{color:"red"}:{}}>{isTechnical ? issue.name : issue.translated ?? issue.name}</li>);
                else {
                    let tmp = "[";
                    for (const p of issue.product)
                        tmp += p+", ";
                    tmp = tmp.substring(0,tmp.length-2)+"] ";
                    issues.push(<li key={key++} style={colored?{color:"red"}:{}}><span style={{fontWeight:"bold"}}>{tmp}</span>{isTechnical ? issue.name : issue.translated ?? issue.name}</li>);
                }
            }
            const features = new Array<JSX.Element>(0);
            key = 0;
            for (const feature of entry.features.filter((fe: any) => filter(fe.product))) {
                if ((!isTechnical) && (feature.translated === undefined || feature.translated === null || feature.translated.trim() === ""))
                    continue;
                const colored = isTechnical && (feature.translated === undefined || feature.translated === null || feature.translated.trim() === "");
                if (feature.product.length === 0 || hideProductTags)
                    features.push(<li key={key++} style={colored?{color:"red"}:{}}>{isTechnical ? feature.name : feature.translated ?? feature.name}</li>);
                else {
                    let tmp = "[";
                    for (const p of feature.product)
                        tmp += p+", ";
                    tmp = tmp.substring(0,tmp.length-2)+"] ";
                    features.push(<li key={key++} style={colored?{color:"red"}:{}}><span style={{fontWeight:"bold"}}>{tmp}</span>{isTechnical ? feature.name : feature.translated ?? feature.name}</li>);
                }
            }
            if (deviceTypeId === 1) {
                rows.push(<div key={entry.name}>
                    <div>{entry.name}</div>
                    <div>{entry.released === undefined ? "-" : moment.unix(entry.released).utcOffset(timezone).format(TimeFormats.PresentationShort2)}</div>
                    <div>
                        <div style={{width:"50%"}}>{issues}</div>
                        <div style={{width:"50%"}}>{features}</div>
                    </div>
                </div>);
            } else {
                const templates = new Array<JSX.Element>(0);
                key = 0;
                for (const template of entry.templates) {
                    if ((!isTechnical) && (template.translated === undefined || template.translated === null || template.translated.trim() === ""))
                        continue;
                    const colored = isTechnical && (template.translated === undefined || template.translated === null || template.translated.trim() === "");
                    templates.push(<li key={key++} style={colored?{color:"red"}:{}}>{isTechnical ? template.name : template.translated ?? template.name}</li>);
                }
                rows.push(<div key={entry.name}>
                    <div>{entry.name}</div>
                    <div>{entry.released === undefined ? "-" : moment.unix(entry.released).utcOffset(timezone).format(TimeFormats.PresentationShort2)}</div>
                    <div>
                        <div style={{width:"35%"}}>{issues}</div>
                        <div style={{width:"35%"}}>{features}</div>
                        <div style={{width:"calc(30% - 4px)"}}>{templates}</div>
                    </div>
                </div>);
            }
        }
        return rows;
    }, [data, deviceTypeId, isTechnical, hideProductTags, filter]);

    const filterVisual = useMemo(() => {
        if (filteredTypes === undefined || filteredTypes.length === 0)
            return [];
        return filteredTypes.map((t) => {
            return (
                <div
                    key={t}
                    onClick={() => {setFilteredTypes(filteredTypes.filter((t_) => {return t_ !== t;}));}}
                >
                    &nbsp;{t+" ✕"}&nbsp;
                </div>
            );
        });
    }, [filteredTypes, setFilteredTypes]);

    const scrollableRef = React.useRef<HTMLDivElement>(null);

    useResizeObserver({
        ref: scrollableRef,
        callback: () => {
            const w = scrollableRef.current?.clientWidth;
            const header = document.getElementById("changelog-table-header");
            if (w !== undefined && header !== undefined && header !== null)
                header.setAttribute("style", "width:"+w+"px");
        },
    });

    return (<div className={classes.root}>
        <div className={classes.control}>
            <TextField
                label="Device Type"
                margin="dense"
                size="small"
                select
                value={deviceTypeId}
                onChange={(ev: any) => {setDeviceTypeId(ev.target.value);}}
                variant="outlined"
            >
                <MenuItem key={2} value={2}>App Versions</MenuItem>
                <MenuItem key={1} value={1}>Robot Software Versions</MenuItem>
            </TextField>
        </div>
        <div className={classes.header}><div id="changelog-table-header">
            <div>Version</div>
            <div>Date</div>
            {deviceTypeId === 1 ?
                <div>
                    <div style={{width:"50%"}}>Issues</div>
                    <div style={{width:"50%"}}>Features</div>
                </div>
            :
                <div>
                    <div style={{width:"35%"}}>Issues</div>
                    <div style={{width:"35%"}}>Features</div>
                    <div style={{width:"30%"}}>Templates</div>
                </div>
            }
        </div></div>
        <div className={classes.table}><div ref={scrollableRef}>{table}</div></div>
    </div>);
}
