import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
    createStyles({
        dialogOverlay: {
            position: "fixed",
            backgroundColor: "rgba(50, 50, 50, 0.6)",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: 99999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

export default function Loading() {
    const classes = useStyles();

    return (
        <div className={classes.dialogOverlay}>
            <CircularProgress
                variant={"indeterminate"}
                size={100}
            />
        </div>
    );
}
