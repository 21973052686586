export enum ConnectionState {
    // Not connected and not trying.
    Idle,
    /**
     * The client has disconnected or timed out and will reconnect.
     */
    Waiting,
    /**
     * The client is connecting.
     */
    Connecting,
    /**
     * The client is connected.
     */
    Connected,
}

interface BaseEvent {
    id: string;
    type: string;
}

interface BaseEventWithPayload<T> {
    id: string;
    type: string;
    payload: T;
}

export type Event<T = void> = T extends void
    ? BaseEvent
    : BaseEventWithPayload<T>;

export interface BulkedEvent<T> {
    events: Event<T>[];
    type: string;
}

export type GroupedEvent = {
    readonly [P in keyof EventMap]?: Parameters<EventMap[P]>[0];
};

export type BulkedEventHandler<T = void> = (ev: BulkedEvent<T>) => void;
export type GroupedEventHandler = (ev: GroupedEvent) => void;
export type ConnectionChangedCallback = (state: ConnectionState) => void;

/**
 * Event payloads
 */

/**
 * This payload is received when data is pushed to the device data tables
 */
export interface DataPayload {
    /**
     * The device id
     */
    id: number;
    /**
     * The device type
     */
    type: number;
    /**
     * Key, value, created, inserted
     */
    entries: [string, string, number, number][];
}

export interface TabletConnectionPayload {
    /**
     * The tablet id
     */
    id: number;
    /**
     * The currently connected robot's id
     */
    connectionId: number;
    /**
     * The previously connected robot's id
     */
    previousConnectionId?: number;
}

/**
 * Type map of available events
 */
export type EventMap = {
    logout: BulkedEventHandler<void>;
    data: BulkedEventHandler<DataPayload>;
    tabletConnection: BulkedEventHandler<TabletConnectionPayload>;
    "connection-change": ConnectionChangedCallback;
};

export type EventTypes = keyof EventMap;
