import { useRef, useState, useCallback } from "react";
import useGetLatest from "../useGetLatest";
import useMountedLayoutEffect from "../useMountedLayoutEffect";
import "./highlightedUpdate.scss";

interface TransitionState {
    running: boolean;
    direction: "in" | "out";
}

const classes = {
    in: "hl-base updated",
    out: "hl-base",
};

export default function useHighlightedUpdate(deps: React.DependencyList) {
    const stateRef = useRef<TransitionState>({
        running: false,
        direction: "in",
    });
    const getState = useGetLatest(stateRef.current);
    const [className, setClassName] = useState<string | undefined>();

    const onTransitionEnd = useCallback(
        (_: React.TransitionEvent) => {
            const state = getState();
            if (state.direction === "in") {
                state.direction = "out";
                setClassName(classes[(state.direction)]);
            } else {
                state.running = false;
                setClassName(undefined);
            }
        },
        [getState]
    );

    useMountedLayoutEffect(() => {
        const state = getState();
        if (!state.running || state.direction === "out") {
            state.running = true;
            state.direction = "in";
            setClassName(classes[(state.direction)]);
        }
    }, [...deps, getState]);

    return {
        className,
        onTransitionEnd,
    };
}
