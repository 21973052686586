import React, { useMemo } from "react";
import { makeStyles, useMediaQuery, Theme, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TemplateGroup, TemplatesDto } from "src/services/DeviceDataService";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[1],
        borderTopLeftRadius: theme.spacing(1) / 2,
        borderTopRightRadius: theme.spacing(1) / 2,
        borderBottomLeftRadius: theme.spacing(1) / 2,
        borderBottomRightRadius: theme.spacing(1) / 2,
    },
    header: {
        maxHeight: 30,
        minHeight: 30,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderTopLeftRadius: "inherit",
        borderTopRightRadius: "inherit",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        "& > :first-child": {
            fontWeight: "bold",
        },
    },
    body: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            display: "flex",
            backgroundColor: theme.tmrPalette.ligtherGrey,
            maxHeight: 24,
            alignItems: "center",
            paddingLeft: "3px",
            paddingRight: "3px",
        },
        "& > :nth-child(even)": {
            backgroundColor: theme.tmrPalette.whiteGrey,
        },
        borderBottom: `1px solid ${theme.tmrPalette.grey}`,
        borderRight: `1px solid ${theme.tmrPalette.grey}`,
        borderLeft: `1px solid ${theme.tmrPalette.grey}`,
    },
}));

function Items({ deviceKeyGroup }: { deviceKeyGroup: TemplateGroup[] }) {
    const classes = useStyles();
    return (
        <>
            {deviceKeyGroup.map((group) => (
                <Grid key={group.name} item xs style={{flexGrow: 0}}>
                    <div className={classes.root}>
                        <div className={classes.header}>
                            <Typography component="h6" variant="h6">
                                {group.name}
                            </Typography>
                            <Typography component="h6" variant="h6">
                                {(group.purchased)
                                    ? <span style={{fontWeight: "normal", fontStyle: "italic"}}> purchased</span>
                                    : <span style={{fontWeight: "normal", fontStyle: "italic"}}> free</span>
                                }
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            {group.templates.map((template, idx) => 
                            <div style={{display:"flex",justifyContent:"space-between"}} key={idx}>
                                <Typography variant="body1" style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace:"nowrap"}} key={idx}>{template}</Typography>
                                <div>{(group.counts?.[idx]>0?`(${group.counts[idx]} layouts)`:"")}</div>
                            </div>
                            )}
                        </div>
                    </div>
                </Grid>
            ))}
        </>
    );
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}));

export default function TemplatesGrid(props: {data: TemplatesDto}) {
    const groups = props.data.groups;

    const classes = useStyles1();
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

    const columns_ = useMemo(() => {
        const desiredColumns = isLargeScreen ? 4 : 3;
        let columns: TemplateGroup[][] = [];

        if (desiredColumns >= groups.length) {
            for (const g of groups)
                columns.push([g]);
            while (desiredColumns > columns.length)
                columns.push([]);
        } else {
            //solve linear optimization problem to have the most even columns
            const shootOver = new Array<boolean[]>(0);
            for (let i = 0; i < Math.pow(2, desiredColumns); i++) {
                let str = i.toString(2);
                while (str.length < desiredColumns)
                    str = "0"+str;
                const splitted = str.split("");
                shootOver.push(splitted.map(s => s === "1"));
            }
            const variations = new Array<{columns: TemplateGroup[][], deviation: number, sumDeviation: number}>(0);
            let sumLines = 0;
            for (const group of groups)
                sumLines += group.templates.length+1;
            const desiredLines = Math.ceil(sumLines / desiredColumns);
            for (const variant of shootOver) {
                let index = 0;
                const v = new Array<TemplateGroup[]>(0);
                for (let i = 0; i < desiredColumns; i++) {
                    const c = new Array<TemplateGroup>(0);
                    let lineCnt = 0;
                    while ((index < groups.length && (variant[i] ? lineCnt <= desiredLines : lineCnt+groups[index].templates.length+1 <= desiredLines))) {
                        c.push(groups[index]);
                        lineCnt += groups[index].templates.length+1;
                        index++;
                    }
                    while (i === desiredColumns-1 && index < groups.length) {
                        c.push(groups[index]);
                        lineCnt += groups[index].templates.length+1;
                        index++;
                    }
                    v.push(c);
                }
                let deviation = 0;
                let sumDeviation = 0;
                for (const c of v) {
                    let tmp = 0;
                    for (const t of c)
                        tmp += t.templates.length+1;
                    const dev = Math.abs(tmp-desiredLines);
                    sumDeviation += dev;
                    if (deviation < dev)
                        deviation = dev;
                }
                variations.push({columns: v, deviation, sumDeviation});
            }
            let minDev = Number.MAX_SAFE_INTEGER;
            let minSumDev = Number.MAX_SAFE_INTEGER;
            if (variations.length > 0) {
                columns = variations[0].columns;
                minDev = variations[0].deviation;
                minSumDev = variations[0].sumDeviation;
            }
            for (const v of variations)
                if (minDev > v.deviation || (minDev === v.deviation && minSumDev > v.sumDeviation)) {
                    columns = v.columns;
                    minDev = v.deviation;
                    minSumDev = v.sumDeviation;
                }
        }

        return columns.map((col, idx) => (
            <Grid
                key={idx}
                container
                direction="column"
                justify="flex-start"
                spacing={1}
                item
                xs={12}
                md={4}
                lg={3}
            >
                <Items deviceKeyGroup={col} />
            </Grid>
        ));
    }, [groups, isLargeScreen]);

    return (
        <Grid
            key={`body-2/templates`}
            container
            direction="row"
            spacing={1}
            justify="center"
            className={classes.root}
        >
            {columns_}
        </Grid>
    );
}