/* eslint-disable @typescript-eslint/ban-types */
import React, { useMemo } from "react";
import { GridChildComponentProps } from "react-window";
import { ColumnInstance, Row } from "react-table";
import { useHoveredRow } from "./HoveredRowProvider";
import { useHighlightedUpdate } from "src/hooks";
import classNames from "../../utils/classNames";
import { SupportDto } from "src/services/SupportService";
import moment from "moment";

export interface GridCellData<T extends {}> {
    rows: Row<T>[];
    headers: ColumnInstance<T>[];
    fixed: boolean;
    onCellClick?: (original: T, value?: any) => void;
}

export interface GridCellProps<T extends {}> extends GridChildComponentProps {
    data: GridCellData<T>;
}

function GridCell<T>({ style, data, rowIndex, columnIndex }: GridCellProps<T>) {
    const { headers, rows, fixed, onCellClick } = data;

    const row = rows[rowIndex];
    const column = headers[columnIndex];

    const contentProps = useMemo(() => {
        if (onCellClick) {
            return {
                onClick: (ev: any) => {
                    ev.preventDefault();
                    onCellClick(row.original, column && row.values[column.id]);
                },
                style: {
                    cursor: "pointer" as const,
                },
            };
        }
        return {};
    }, [onCellClick, column, row]);

    const {
        rowIndex: hoveredRowIndex,
        setRowIndex: setHoveredRowIndex,
    } = useHoveredRow();

    const onMouseOver = (ev: React.MouseEvent) => {
        ev.preventDefault();
        if (hoveredRowIndex !== rowIndex) {
            setHoveredRowIndex(() => rowIndex);
        }
    };
    if (rows.length === 0) {
        return (
            <div
                key={`not-found${fixed ? "-fixed" : ""}`}
                style={(fixed && { ...style, width: "200px" }) || style}
            >
                <div className="dg-cell">
                    {(fixed && <>No results found</>) || <>&shy;</>}
                </div>
            </div>
        );
    }

    if (columnIndex === headers.length) {
        return (
            <GridCellContentWrapper
                key={`${row.id}/last`}
                style={style}
                original={row.original}
            >
                <div
                    data-hovered={hoveredRowIndex === rowIndex}
                    className="dg-cell"
                    data-no-pad={style.width === 0}
                    {...{ onMouseOver, ...contentProps }}
                >
                    &shy;
                </div>
            </GridCellContentWrapper>
        );
    }

    let isOpenSupportIssue = undefined;
    if (Object.keys(row.original).includes("issueId")) {
        try {
            const issue = (row.original as unknown as SupportDto).data;
            isOpenSupportIssue = (!["closed","solved","pending"].includes(issue["status"])).toString();
            if (isOpenSupportIssue === "true" && moment.now()/1000 - Number(issue["created_at"]) >= 7*24*60*60)
                isOpenSupportIssue = "warning";
        } catch(_e) {/*ignored*/}
    }
    
    const renderedCell = column.render("Cell", {
        row,
        value: row.values[column.id],
    });

    return (
        <GridCellContentWrapper
            key={`${row.id}/${column.id}`}
            style={style}
            className="dg-cell-w"
            data-col={columnIndex}
            data-fixed={fixed}
            original={row.original}
        >
            <div
                {...{ onMouseOver, ...contentProps }}
                data-hovered={hoveredRowIndex === rowIndex}
                data-isopen={isOpenSupportIssue}
                className="dg-cell"
            >
                <div>
                    {renderedCell}
                </div>
            </div>
        </GridCellContentWrapper>
    );
}

function GridCellContentWrapper<T extends {}>(
    props: React.BaseHTMLAttributes<HTMLDivElement> & { original: T }
) {
    const { original, className, ...rest } = props;
    const { className: transition, onTransitionEnd } = useHighlightedUpdate([
        original,
    ]);

    return (
        <div
            {...rest}
            className={classNames(className, transition)}
            onTransitionEnd={onTransitionEnd}
        />
    );
}

export default GridCell;
