import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AutoSizer from "react-virtualized-auto-sizer";
import DataGridV2 from "src/components/DataGrid";
import BaseView from "src/views/BaseView";
import ToggleTranslate from "../Devices/ToggleTranslate";
import useColumns from "./useColumns";
import TranslationProvider, { useTranslator } from "../Devices/TranslationProvider";
import { useAsync, useGetLatest } from "src/hooks";
import { customersSelector } from "src/redux/app/selectors";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { isMobile } from "react-device-detect";
import { Row, IdType, ColumnInstance } from "react-table";
import ExportButton from "src/components/ExportButton";
import UserService from "src/services/UserService";
import { Button } from "@material-ui/core";
import { setUsers } from "src/redux/app/actions";
import ExportCSV from "../Devices/Overview/ExportCSV";
import { userAccessSelector } from "src/redux/auth/selectors";

export interface UserOv {
    username: string;
    data: {
        customer_id: number[];
        userAccess: string;
        email?: string,
        firstname?: string,
        lastname?: string,
        verified?: boolean,
        contractAccess?: string
    },
}

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginBottom: theme.spacing(2),
        "& + *": {
            marginBottom: theme.spacing(2),
        },
    },
    container: {
        minHeight: 600,
        height: `calc(100vh - 100px)`,
        [theme.breakpoints.up("md")]: {
            height: "calc(98.5vh - 16px)",
        },
        [theme.breakpoints.up("xl")]: {
            height: "calc(99vh - 16px)",
        },
        "& > :last-child": {
            height: "calc(100% - 48px - 48px - 12px)",
            "& > :first-child": {
                width: "unset !important",
            },
        },
    },
    containerWrapper: {
        [theme.breakpoints.up("md")]: {
            overflow: "hidden",
        },
    },
    newUserButton: {
        marginLeft: "20px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));

const AccountOverview = React.memo(function Overview() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { dto: customers, loaded: customersLoaded } = useSelector(
        customersSelector
    );

    const userAccess=useSelector(userAccessSelector).includes("User")
    const fetchAccessFields = useCallback((() => UserService.getAccessFields()
    .then((afs)=>
    afs.sort((a,b)=>a.access_lvl>b.access_lvl?1:-1))
    ),[])
    const { value: accessFields, exec: fetchAccessFields_ } = useAsync(fetchAccessFields, {
        immediate: false,
    });
    useEffect(() => {
        fetchAccessFields_();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const getUserLvl=(lvl:number)=>{
        switch(lvl)
        {
            case 0: return 'Customer'
            case 1: return "Dealer"
            case 2: return "Support"
            case 3: return "Admin"
            default: return "Other"
        }
    }
    const getLvl=useCallback( (uAccess:string[])=>{
        const lvls= [...new Set(accessFields?.map(af=>af.access_lvl))].map((lvl)=>{return {lvl:lvl,coverage: (accessFields?.filter((af)=>(af.access_lvl===lvl && uAccess.includes(af.tag)))?.length ?? 0)/(accessFields?.filter((af)=>af.access_lvl===lvl).length??1)}}).sort(
        (a,b)=>{
            if (a.coverage>b.coverage)
            return -1
            else if (b.coverage>a.coverage)
            return 1
            else return a.lvl>b.lvl?-1:1
        }
    )
    // eslint-disable-next-line
    return `${Number((lvls[0]?.coverage>0 && lvls[0]?.lvl))} LVL (${getUserLvl(Number((lvls[0]?.coverage>0 && lvls[0]?.lvl)))}) ${(lvls?.find((lvl)=>lvl.lvl>(lvls[0].lvl))?.coverage??0)>0?'+':''}`
    },[accessFields])

    const fetchData = useCallback(
        () => UserService.getUsers().then(users => {
            dispatch(setUsers(users));            
            const da = users.map(u => {return {username: u.username, data: {customer_id: u.customerId, userAccess: getLvl(u.userAccess), email: u.email, firstname: u.firstname, lastname: u.lastname, verified: u.verified, to: u.to, from: u.from, lastLogin: u.lastLogin,contractAccess: u.userAccess.filter((af)=>af.includes('TinyMobileRobots')).join(',')}};}) as UserOv[];
            const ke = ["customer_id", "userAccess", "email", "firstname", "lastname", "verified", "from", "to", "lastLogin", "contractAccess"];
            const di = {customer_id: {translations: {}, alias: "Customer"},userAccess:{translations:{},alias:"User Access"},  email: {translations: {}, alias: "Email"}, firstname: {translations: {}, alias: "First Name"}, lastname: {translations: {}, alias: "Last Name"}, verified: {translations: {}, alias: "Verified"}, from: {translations: {}, alias: "Valid From"}, to: {translations: {}, alias: "Valid To"}, lastLogin: {translations: {}, alias: "Last Login"}, contractAccess: {translations: {}, alias: "Quote Access"}};
            return {data: da, keys: ke, dictionary: di};        
        }), [dispatch,getLvl]
    );

    const { value, error, exec, pending } = useAsync(fetchData, {
        defaultPending: true,
    });
    const { data, keys, dictionary } = value! || {};
    const loading = pending || customersLoaded === false;

    const [currentTranslator, setCurrentTranslator] = useState({} as any);
    const [currentGrid, setCurrentGrid] = useState({columns: [], filteredData : []} as {columns: ColumnInstance<any>[], filteredData : Row<any>[]});

   
    return (
        <TranslationProvider
            deviceTypeId={-1}
            customers={customers}
            translationsDictionary={dictionary as any || {}}
        >
            <BaseView loading={loading} error={error} retry={exec}>
                <Container
                    component="main"
                    maxWidth={false}
                    className={classes.container}
                >
                    <div style={{display: "flex",justifyContent: "space-between",flexDirection: "row"}}>
                        <Typography
                            component="h1"
                            variant="h5"
                            className={classes.titleText}
                        >
                            {"User Overview"}
                        </Typography>
                        <div style={{display: "flex",justifyContent: "flex-start",flexDirection: "row"}}>
                            <ExportButton
                                onClick={ExportCSV(currentGrid,currentTranslator)}
                            />
                            {(userAccess) && 
                                <Button
                                    className={classes.newUserButton}
                                    size="medium"
                                    variant="outlined"
                                    onClick={() => {dispatch(push("/accounts/create"));}}
                                >
                                    New User
                                </Button>
                            }
                        </div>
                    </div>
                    <Typography component="section">
                        ServiceCenter Users can be seen below. Clicking on{" "}
                        {isMobile ? "the Username column" : "a row"} will bring you to
                        the detailed view of the user.
                    </Typography>
                    <div>
                        <OverviewContent
                            {...{ keys, data, setCurrentTranslator, setCurrentGrid }}
                        />
                    </div>
                </Container>
            </BaseView>
        </TranslationProvider>
    );
});

interface OverviewContentProps {
    keys: string[];
    data: UserOv[];
    setCurrentTranslator?: React.Dispatch<any>;
    setCurrentGrid?: React.Dispatch<any>;
}

function OverviewContent(props: OverviewContentProps) {
    const { keys, data, setCurrentTranslator, setCurrentGrid } = props;

    const { fixed: fixedHeader, keys: keyHeaders } = useColumns(keys)!;

    // Navigate on click
    const dispatch = useDispatch();
    const navigate = useCallback(
        (original: UserOv) => {
            const url = "/accounts/" + original.username;
            dispatch(push(url));
        },
        [dispatch]
    );

    const translator = useTranslator();
    const getTranslator = useGetLatest(translator);
    useEffect(() => {
        if (setCurrentTranslator !== undefined)
            setCurrentTranslator(getTranslator());
    }, [setCurrentTranslator, getTranslator]);
    const globalFilter = useCallback(
        (
            rows: Row<UserOv>[],
            columnIds: IdType<UserOv>[],
            filterValue: any
        ) => {
            if(filterValue?.keyword !== undefined)
            {
                if(filterValue.strict && filterValue.keyword.length){
                    return rows.filter((row) => {
                        return columnIds.some((id) => {
                            return String(getTranslator().translate(id, row.values[id])) === filterValue.keyword;
                        });
                    });
                }
                else{ 
                    const keyword = String(filterValue.keyword).toLowerCase();
                    return rows.filter((row) => {
                        return columnIds.some((id) => {
                            return String(getTranslator().translate(id, row.values[id]))
                                .toLowerCase()
                                .includes(keyword);
                        });
                    });
                }
            }
            else{
                filterValue = String(filterValue).toLowerCase();
                return rows.filter((row) => {
                    return columnIds.some((id) => {
                        return String(getTranslator().translate(id, row.values[id]))
                            .toLowerCase()
                            .includes(filterValue);
                    });
                });
            }

        },
        [getTranslator]
    );

    (globalFilter as any).autoRemove = useCallback((val: any) => !val, []);

    const toolbarProps = useMemo(
        () => ({
            LeftAdornment: <ToggleTranslate />,
            hideColumnsProps: {
                filter: (
                    columns: ColumnInstance<UserOv>[],
                    filter: string
                ) => {
                    if (filter) {
                        const reg = new RegExp(filter.trim(), "i");
                        return columns.filter((c) =>
                            reg.test(getTranslator().translateKey(c.id))
                        );
                    }
                    return columns;
                },
            },
            enableStrictSearch: true,
        }),
        [getTranslator]
    );

    return (
        <AutoSizer>
            {({ height, width }) => (
                <DataGridV2<UserOv>
                    name={"tmr/overview-table/tmrmi-users"}
                    fixedColumn={fixedHeader}
                    columns={keyHeaders}
                    data={data}
                    toolbarProps={toolbarProps}
                    onCellClick={navigate}
                    limitClickToFixed={isMobile}
                    globalFilter={globalFilter}
                    height={height}
                    width={width}
                    setCurrentGrid={setCurrentGrid}
                />
            )}
        </AutoSizer>
    );
}
export default AccountOverview;