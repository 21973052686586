import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import CreatePurchasePopup from "./popups/CreatePurchasePopup.jsx"
import EditPurchacePopup from "./popups/EditPurchacePopup.jsx"

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = { whiteSpace: "nowrap" };

class ManagePurchasesSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            purchases: []
        };
        this.refreshPurchases = this.refreshPurchases.bind(this);

        this.headers = [{
            Header: 'Customer',
            key: 'Customer',
            align: "left",
            style: small_style,
            search: "Customer"
        }, {
            Header: 'Dealer',
            key: 'Dealer',
            align: "left",
            style: small_style,
            search: "Dealer"
        }, {
            Header: 'TabletId',
            key: 'TabletId',
            align: "left",
            style: small_style,
            search: "TabletId"
        }, {
            Header: 'Feature group',
            key: 'FeatureGroup',
            align: "left",
            style: small_style,
            search: "FeatureGroup"
        }, {
            Header: 'Transaction id',
            key: 'TransactionId',
            align: "left",
            style: small_style,
            search: "TransactionId"
        }, {
            Header: 'Running from',
            key: 'FromElement',
            align: "left",
            style: small_style,
            search: "From"
        }, {
            Header: 'Expires At',
            key: 'ToElement',
            align: "left",
            style: small_style,
            search: "To"
        }, {
            Header: 'State',
            key: 'State',
            align: "left",
            style: big_style,
            search: "State"
        },
        {
            Header: '',
            key: 'EditElement',
            align: "left",
            style: small_style
        }
        ];
    }

    componentDidMount() {
        this.refreshPurchases()
    }

    refreshPurchases(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/purchases`)
            .then(response => response.data[0])
            .then(resp => this.setState({
                purchases: resp.map(r => {
                    r.FromElement = (r.From ? r.From : "").replace("T", " ").replace(".000Z", "");
                    r.ToElement = (r.To ? r.To : "").replace("T", " ").replace(".000Z", "");
                    if (r.State === "COMPLETE")
                        r.EditElement = <EditPurchacePopup purchase={r} whenSuccess={this.refreshPurchases.bind(this)} />;
                    else
                        r.EditElement = <div style={{ height: "48px" }}></div>;
                    return r;
                })
            }));
    }

    render() {
        return (
            <React.Fragment>
                <Table id="purchases_table" headeres={this.headers} data={this.state.purchases} refresh={this.refreshPurchases} />
                <div style={{ margin: "30px" }}>
                    <CreatePurchasePopup whenSuccess={this.refreshPurchases.bind(this)} />
                </div>
            </React.Fragment>
        );
    }
}

export default ManagePurchasesSection;