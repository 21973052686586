import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { OnlineIcon, BatteryIcon, LampIcon } from "../Icons";
import { useHighlightedUpdate } from "src/hooks";
import classNames from "../../../utils/classNames";
import ToggleTranslate from "../ToggleTranslate";
import { useSection } from "./SectionProvider";
import ExportButtonV2 from "src/ExportButtonV2";

const commonKeys = {
    online: "Server online",
    online2: "vpn_status",
    online3: "connected",
    systemMessage: "system_message",
    battery: "Battery",
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        display: "flex",
        alignContent: "center",
        
        "& > :first-child": {
            marginTop: 2,
        },
        "& > :last-child": {
            [theme.breakpoints.down("xs")]: {
                justifyContent: "flex-end",
            },
        },
    },
    icon: {
        display: "flex",
        marginLeft: theme.spacing(1) / 2,
        alignItems: "center",
        "& > :first-child": {
            fontSize: theme.typography.pxToRem(27),
            marginRight: theme.spacing(1) / 2,
            [theme.breakpoints.up("md")]: {
                marginBottom: 2,
            },
        },
        "& > :last-child:not(:first-child)": {
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
            marginRight: theme.spacing(1),
        },
    },
    toggleTranslateLabel: {},
}));

export interface VitalsBarProps {
    disableOnline?: boolean;
    disableMessage?: boolean;
    disableBattery?: boolean;

    onlineStatus?: "Online" | "Offline";

    message?: string;

    batteryStatus?: number | string;
}



const VitalsBar = React.memo(function VitalsBar(props: VitalsBarProps) {
    const classes = useStyles();

    const { disableOnline, disableMessage, disableBattery } = props;
    const { onlineStatus, message, batteryStatus } = props;
    const { data: values, deviceName: deviceType,deviceId } = useSection();

    const disableAll = disableOnline && disableMessage && disableBattery;

    const { className: transition, onTransitionEnd } = useHighlightedUpdate([
        onlineStatus,
        message,
        batteryStatus,
    ]);

    if (disableAll) {
        return <></>;
    }

    return (
        <Box
            onTransitionEnd={onTransitionEnd}
            className={classNames(classes.root, transition)}
            position={"relative"}
        >
            <Box whiteSpace="nowrap" position="absolute" ml={2}>          
                  <ToggleTranslate label="Translate data" />
            </Box>
            <Box display="flex" justifyContent="center" width={"100%"}>
                {!disableOnline && (
                    <>
                        <div className={classes.icon}>
                            <OnlineIcon status={onlineStatus} />
                            {onlineStatus && (
                                <Typography align="justify">
                                    {onlineStatus}
                                </Typography>
                            )}
                        </div>
                        {!disableMessage && (
                            <Divider orientation="vertical" flexItem />
                        )}
                    </>
                )}
                {!disableMessage && (
                    <>
                        <div className={classes.icon}>
                            <LampIcon status={message} />
                            {message && <Typography style={{maxWidth:"200px",textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap"}}>{message}</Typography>}
                        </div>
                    </>
                )}
                {!disableBattery && (
                    <>
                        <Divider orientation="vertical" flexItem />
                        <div className={classes.icon}>
                            <BatteryIcon status={batteryStatus} />
                            {batteryStatus !== undefined && (
                                <Typography>{batteryStatus}%</Typography>
                            )}
                        </div>
                    </>
                )}
            </Box>
            <Box  position={"absolute"} mr={2} right={0}>
                <ExportButtonV2 dataRecord={values} index={deviceId} name={deviceType}></ExportButtonV2>
            </Box>
        </Box>
    );
});

export default function VitalsBarWrapped() {
    const { data: values, deviceTypeId: deviceType } = useSection();
    
    const batteryStatus = values[commonKeys.battery];
    const systemMessage = values[commonKeys.systemMessage];

    let onlineStatus: any = values[commonKeys.online];
    if (onlineStatus && onlineStatus.value.toLowerCase() !== "online")
        if (values[commonKeys.online2] !== undefined)
            onlineStatus = values[commonKeys.online2];
    if (onlineStatus === undefined && values[commonKeys.online3] !== undefined)
        onlineStatus = {...values[commonKeys.online3], value: values[commonKeys.online3].value.toLowerCase() === "true" ? "Online" : "Offline"};

    const notRobot = deviceType !== 1;
    const notTablet = deviceType !== 2;
    const notBaseStation = deviceType !== 3;

    const virtalsBarProps: VitalsBarProps = {
     
        disableBattery: notRobot,
        disableMessage: notRobot,
        disableOnline: notRobot && notTablet && notBaseStation,
        onlineStatus: onlineStatus?.value ?? undefined,
        batteryStatus: batteryStatus?.value ?? undefined,
        message: systemMessage?.value ?? undefined,
    };

    return <VitalsBar {...virtalsBarProps} />;
}
