import { Button } from '@material-ui/core';
import { DeviceData } from './services/DeviceDataService/types';
import { DeviceData as Device} from './views/HistoryOverview/HistoryOverview';
import DownloadIcon from "@material-ui/icons/GetAppRounded";


import generateCsv, { generateCsvArray } from './utils/csvGenerator'
import React, { useEffect, useState } from 'react';
import { displayToast } from './redux/app/actions';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';


export interface ExportButtonV2Props{
    dataRecord?: (Record<string, DeviceData>),
    dataArray?: Device[],
    loadData?: ()=>Promise<any>,
    index?:number,
    name:string,
    label?:string,
    disabled?: boolean
}

async function getData(dataRecord?:(Record<string, DeviceData>),dataArray?:Device[],loadData?:()=>Promise<any>){
   
    
    if (dataRecord)
    return generateCsv(dataRecord)
    if (dataArray)
    return generateCsvArray(dataArray)
    if (loadData){
        const data=(await loadData())
    return generateCsvArray(data[0])}
    return '#'

}
async function load(props:ExportButtonV2Props,dispatch:Dispatch,setDisable:(b:boolean)=>void){
        const {dataRecord,dataArray,index,name,loadData}=props
        setDisable(true);
        const data=await getData(dataRecord,dataArray,loadData)
    
        const temp_link = document.createElement("a");
        document.body.appendChild(temp_link);
        const size=((data).split(/%..|./).length - 1)/1024/1024
        setDisable(false)
        if (size>9){
        dispatch(displayToast({
            message: `size is ${size.toFixed(1)} mb. \n it should be less then 10mb to download.`,
            severity: "info",
            withCloseIcon: true,
        }));
        return;
        }
        temp_link.href = data
        temp_link.download = `status-${name}${index?"-"+index:""}.csv`;
        temp_link.click();
        temp_link.remove();
}
export default function ExportButtonV2(props:ExportButtonV2Props){
    const {label,disabled}=props
    const [disable,setDisable]=useState(disabled);
    useEffect(()=>{
        setDisable(disabled)
    },[disabled])
    const dispatch=useDispatch();

    return <Button onClick={()=>load(props,dispatch,setDisable)} disabled={disable}><DownloadIcon style={{verticalAlign:"text-bottom"}}/>{label??""}</Button>

}