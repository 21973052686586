import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import type { Robot } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import { customersDtoSelector, robotsSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import useDialog from "src/hooks/useDialog";
import ConfigurationsService from "src/services/ConfigurationsService";

interface WarrantySubscription {
    warranty: boolean;
    subscription: boolean;
}
type WarrantySubscriptionInput = {
    start: Date | null,
    end: Date | null,
    robot: Robot | null;
    ws: WarrantySubscription | null;
};


export default function WarrantySubscriptionWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<WarrantySubscriptionInput>({
        defaultValues: { robot: null, start: null,end: null, ws: {warranty: false, subscription: false}},
    });

    const robots = useSelector(robotsSelector);
    const loadedDeviceInfo = robots.length > 0;
    const { displayToast } = useToast();
    const customersDto = useSelector(customersDtoSelector);
    const { displayDialog } = useDialog();


    const start = watch("start");
    const end = watch('end');
    const robot = watch("robot");
    const ws = watch("ws");
   

    function setWarrantySubscription(input: WarrantySubscriptionInput) {
        
        return displayDialog({negativeButtonText:"Cancel",positiveButtonText:"Continue",dialogText:`Change ${ws?.warranty ? "warranty" : ""}${ws?.warranty && ws?.subscription ? " and " : ""}${ws?.subscription ? "subscription" : ""} dates to the following?\n\nRobot (${input.robot?.id}) ${input.robot?.name} [${customersDto[input.robot!.customerId ?? -1].name}]\nStart: ${input.start!.getFullYear()}/${input.start!.getMonth()+1}/${input.start!.getDate()}\nEnd: ${input.end!.getFullYear()}/${input.end!.getMonth()+1}/${input.end!.getDate()}`})
        .then((r) => {
            if(!r)
            {
                displayToast({
                    message: `Warrany/subscription change cancelled.`,
                    severity: "warning",
                    withCloseIcon: true,
                });
                throw new Error("")
            }
        })
        .then(() => input.ws!.warranty ? ConfigurationsService.setWarranty(input.robot?.id as number,input.start!,input.end!) : Promise.resolve())
        .then(() => input.ws!.subscription ? ConfigurationsService.setSubscription(input.robot?.id as number,input.start!,input.end!) : Promise.resolve())
        .then(() => input)
                
    }
    useEffect(() => {
        register("robot", defaultRules);
        register("start", defaultRules);
        register('end',defaultRules);
        register("ws", {required: "This field is required", validate: (value) => {
            if(value.warranty === false && value.subscription === false)
                return "Pick at least Warranty or Subscription";
            else
                return true;
        }});
        
    }, [register]);

    const onComplete = useCallback(

        (input: WarrantySubscriptionInput) => {
            if (!saveChanges) reset();
            displayToast({
                message: `Successfully set warranty/subscription of Robot ${
                    input.robot!.id
                } to ${input.start!.getFullYear()}/${input.start!.getMonth()+1}/${input.start!.getDate()} - ${input.end!.getFullYear()}/${input.end!.getMonth()+1}/${input.end!.getDate()}`,

                severity: "success",
                withCloseIcon: true,
            });
        },
        [ displayToast, reset,saveChanges]
    );

    const { exec: setWS, pending: settingWS, error } = useAsync(
        setWarrantySubscription,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const loading = settingWS || !loadedDeviceInfo;


    return (
        <BaseWidget
            widgetName={widgetName}
            title="Change warranty and subscription dates"
            subTitle="Change start and end dates of subscription/warranty of a robot"
            onSubmit={handleSubmit(setWS)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            <FormControl
                required
                error={errors.ws !== undefined}
            >
                <FormGroup row={true} >
                    <FormControlLabel 
                        control={<Checkbox
                            checked={ws?.warranty}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>{setValue("ws", {warranty: event.target.checked, subscription: ws?.subscription ?? false})}}
                        />} 
                        label={"Warranty"}
                    /> 
                    <FormControlLabel 
                        control={<Checkbox
                            checked={ws?.subscription}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>{setValue("ws", {warranty: ws?.warranty ?? false, subscription: event.target.checked})}}
                        />} 
                        label={"Subscription"}
                    /> 
                </FormGroup>
            <FormHelperText>{errors.ws?.message}</FormHelperText>
            </FormControl>

                   
            <AutocompleteV2
                selectOnTab
                value={robot}
                disabled={loading}
                options={robots}
                getOptionLabel={(r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    return ret;
                }}
                noOptionsText="No such robot"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Robot"
                            placeholder="Choose a robot"
                            required
                            {...getErrorProps(errors, "robot")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("robot", val);
                }}
                
                getOptionSelected={(a, b) => a.id === b.id}
                renderOption={(r) => {
                    const customer = customersDto[r.customerId ?? -1];
                    let ret = "("+r.id+") "+r.name;
                    if (customer !== undefined)
                        ret += " ["+customer.name+"]";
                    else
                        ret += " ["+r.customerId+"]";
                    if (r.deprecated !== undefined && r.deprecated !== null)
                        return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                    return (<div>{ret}</div>);
                }}
            />

            <KeyboardDatePicker
                    fullWidth
                    required
                    label="Start"
                    margin="dense"
                    inputVariant="outlined"
                    format="YYYY/MM/DD"
                    value={start}
                    onChange={(date) => {
                        if (end === null || date?.isBefore(end)) {
                            setValue("start", date?.toDate())
                        }
                    }}
                    maxDate={end || undefined}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: true,
                        endAdornment: start !== null && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setValue("start", null)
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    KeyboardButtonProps={{ size: "small" }}
                    InputAdornmentProps={{ position: "start" }}
                    {...getErrorProps(errors, "start")}
                />
            
                <KeyboardDatePicker
                    fullWidth
                    required
                    label="End"
                    inputVariant="outlined"
                    margin="dense"
                    format="YYYY/MM/DD"
                    value={end}
                    onChange={(date) => {
                        if (start === null || date?.isAfter(start)) {
                            setValue("end", date?.toDate())
                        }
                    }}
                    minDate={start || undefined}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: true,
                        endAdornment: end !== null && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setValue("end", null)
                                }}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    KeyboardButtonProps={{ size: "small" }}
                    InputAdornmentProps={{ position: "start" }}
                    {...getErrorProps(errors, "end")}
                />
            
            
        </BaseWidget>
    );
}
