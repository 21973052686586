import React from "react";
import CircularProgress, {
    CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import Button from "@material-ui/core/Button";
import Fade, { FadeProps } from "@material-ui/core/Fade";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as errors from "../services/Errors";
import classNames from "../utils/classNames";

function mapErrorMessage(err: any) {
    if (
        err instanceof errors.AuthenticationError ||
        err instanceof errors.AuthorizationError ||
        err instanceof errors.NotFoundError ||
        err instanceof errors.UnknownError
    ) {
        const msg = err.message;
        if (msg[msg.length - 1] === ".") {
            return msg.substring(0, msg.length - 1);
        }
        return msg;
    }

    if (typeof err === "string") {
        return err;
    }

    return "An error occured...";
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        wrapper: {
            position: "relative",
            width: "100%",
        },
        progress: {
            margin: theme.spacing(2),
        },
    })
);

export interface LoaderProps
    extends CircularProgressProps,
        Pick<
            FadeProps,
            | "onExited"
            | "onExiting"
            | "onExit"
            | "onEnter"
            | "onEntering"
            | "onEntered"
        > {
    loading: boolean;
    error?: any;
    retry?: (event: React.MouseEvent<HTMLElement>) => void;
    timedOut?: boolean;
    pastDelay?: boolean;
}

const Loader = React.forwardRef(function Loader(props: LoaderProps, ref) {
    const {
        loading,
        error,
        retry,
        timedOut,
        pastDelay,
        className,
        onExited,
        onExiting,
        onExit,
        onEnter,
        onEntered,
        onEntering,
        ...rest
    } = props;
    const classes = useStyles();
    return (
        <Fade
            in={loading || error !== undefined}
            appear
            unmountOnExit
            ref={ref}
            {...{ onExit, onExited, onExiting, onEnter, onEntered, onEntering }}
        >
            <div className={classes.wrapper}>
                <div className={classNames(classes.root, className)}>
                    {error && (
                        <>
                            <Typography variant="subtitle1">
                                {mapErrorMessage(error)}
                                {!(error instanceof errors.NotFoundError) &&
                                    !(
                                        error instanceof
                                        errors.AuthorizationError
                                    ) &&
                                    retry !== undefined && (
                                        <>
                                            {" "}
                                            -
                                            <Button
                                                size="small"
                                                variant="text"
                                                color="primary"
                                                onClick={retry}
                                            >
                                                Retry
                                            </Button>
                                        </>
                                    )}
                            </Typography>
                        </>
                    )}
                    {timedOut && (
                        <Typography variant="subtitle1">
                            Taking a long time...
                            <Button
                                size="small"
                                variant="text"
                                color="primary"
                                onClick={retry}
                            >
                                Retry
                            </Button>
                        </Typography>
                    )}
                    {pastDelay && !error && (
                        <Typography variant="subtitle1">Loading...</Typography>
                    )}
                    {(error && (
                        <ErrorIcon
                            fontSize="large"
                            color={
                                (error instanceof errors.NotFoundError &&
                                    "disabled") ||
                                "error"
                            }
                            className={classes.progress}
                        />
                    )) || (
                        <CircularProgress
                            {...rest}
                            className={classes.progress}
                        />
                    )}
                </div>
            </div>
        </Fade>
    );
});

export default Loader;
