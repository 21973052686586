import React from "react";
import { FormControlLabel, Switch, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import SharedStateProvider from "src/components/SharedStateProvider";
import { useSelector } from "react-redux";
import { credentialsSelector } from "src/redux/auth/selectors";
import { customersLoadedSelector } from "src/redux/app/selectors";
import BaseView from "src/views/BaseView";
import plugins from "./plugins";
import WidgetLayout from "./WidgetLayout";
import { useLocalStorage } from "src/hooks";

const useStyles = makeStyles((theme) => ({
    titleText: {},
    header:{
        display: "flex",
        justifyContent: "space-between",
        
    },
    headerTitle: {
        display: "flex",
        flexDirection: "column",
        "& > :last-child": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    content: {
        marginTop: theme.spacing(1),
        margin: -theme.spacing(1),
    },
}));

export default function UserSettings() {
    const classes = useStyles();
    const { customerName } = useSelector(credentialsSelector)! || {};
    const loading = !useSelector(customersLoadedSelector);

    
    const [saveChanges,setSaveChanges]=useLocalStorage('SaveChanges',false);
    
    
    return (
        <BaseView loading={loading}>
            <Container component="main" maxWidth={false}>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <Typography
                            component="h1"
                            variant="h5"
                            className={classes.titleText}
                        >
                            Control Panel
                        </Typography>
                        <Typography variant="subtitle1">
                            You are configuring devices and customers belonging to{" "}
                            <b>
                                <i>{customerName.join('; ')}</i>
                            </b>
                        </Typography>
                    </div>
                <FormControlLabel control={<Switch color="primary" onChange={(a)=>{setSaveChanges((a.target.checked ))}} checked={saveChanges}/>} label="Save Fields" />
                </div>    
                <SharedStateProvider plugins={plugins}>
                    <div className={classes.content}>
                        <WidgetLayout saveChanges={saveChanges??false}/>
                    </div>
                </SharedStateProvider>
            </Container>
        </BaseView>
    );
}
