/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { makeStyles } from "@material-ui/core";
import { HeaderProps } from "react-table";
import { OnlineIcon } from "../Icons";
import { useTranslator } from "../TranslationProvider";
import { CellRendererProps } from "src/components/DataGrid";
import { DeviceDto } from "src/services/DeviceDataService";

// eslint-disable-next-line @typescript-eslint/ban-types
export type IdCellProps<T extends {}> = CellRendererProps<T>;

export function HeaderCell<T extends {}>(props: HeaderProps<T>) {
    const {
        column: { id, name },
    } = props;

    const translator = useTranslator();
    const value = id === "deviceId" ? name : translator.translateKey(id);
    return <>{value}</>;
}

const useIdCellStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    value: {
        marginLeft: 4,
    },
    icon: {
        marginBottom: 1.5,
    },
}));

export function IdCell<T extends {}>(props: IdCellProps<T>) {
    const {
        row: { values },
        value,
    } = props;

    const classes = useIdCellStyles();

    let onlineValue = values["Server online"];

    if (onlineValue && onlineValue.toLowerCase() !== "online") {
        if (values["vpn_status"] !== undefined) {
            onlineValue = values["vpn_status"];
        }
    }
    if (onlineValue === undefined && values["connected"] !== undefined)
        onlineValue = values["connected"].toLowerCase() === "true" ? "Online" : "Offline";
    
    const isDeprecated = values["deprecated"] !== undefined && values["deprecated"] !== null;
    if (isDeprecated)
        onlineValue = undefined;

    return (
        <div className={classes.root}>
            <OnlineIcon
                className={classes.icon}
                fontSize="small"
                status={onlineValue}
                msgOverride={isDeprecated?"Deprecated":undefined}
            />
            <div className={classes.value} style={isDeprecated?{/*textDecoration: "line-through",*/ color: "#555555"}:{}}>{value}</div>
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function TranslatedCell(props: CellRendererProps<DeviceDto>) {
    const {
        value,
        column: { id },
    } = props;

    const translator = useTranslator();
    const translatedResult = translator.translate(id, value);
    return <>{translatedResult}</>;
}
