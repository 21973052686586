import { useEffect, useRef, useCallback } from "react";
import type { EventTypes, EventMap, GroupedEvent } from "../events";
import events from "../events";

function useEvents<T extends EventTypes>(
    type: T[],
    handler: (ev: GroupedEvent) => void
): void;
function useEvents<T extends EventTypes>(type: T, handler: EventMap[T]): void;
function useEvents(type: any, handler: any) {
    const shouldUpdateRef = useRef(true);

    const cb = useCallback(
        (ev: any) => {
            if (shouldUpdateRef.current) {
                handler(ev);
            }
        },
        [handler]
    );

    useEffect(() => {
        const subscription = events.on(type, cb);
        return () => {
            shouldUpdateRef.current = false;
            subscription.then((unsubscribe) => unsubscribe());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(Array.isArray(type) ? type : [type]), cb]);
}

export default useEvents;
