import { useLayoutEffect, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";
import useGetLatest from "./useGetLatest";

export default function useResizeObserver<T extends Element>(options: {
    callback: () => void;
    ref: React.RefObject<T>;
}) {
    const { callback, ref } = options;

    const getCallback = useGetLatest(callback);
    const cb = useCallback(() => {
        getCallback()();
    }, [getCallback]);

    useLayoutEffect(() => {
        const resizeObserverOrPolyfill = ResizeObserver;

        const obs = new resizeObserverOrPolyfill(cb);
        const el = ref && ref.current;
        if (el) {
            obs.observe(el);
            cb();
        }
        return () => {
            if (el) {
                obs.unobserve(el);
            }
        };
    }, [ref, cb]);
}
