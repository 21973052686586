import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { CategoryCell, DateCell, HeaderCell, IdCell, SelectorIdCell, TranslatedCell } from "./Cells";
import { AssignedToMeColumnFilter, OpenColumnFilter, TimestampColumnFilter } from "./Filters";
import { NumberColumnFilter } from "src/components/FilteredTable/ColumnFilters";
import { GridColumn } from "src/components/DataGrid";
import { SupportDto } from "src/services/SupportService";
import moment from "moment";
import { TimeFormats } from "src/utils/constants";
import { timezone } from "src/App";
import { AutocompleteColumnFilter } from "../Devices/Overview/Filters";
import { useTranslator } from "../Devices/TranslationProvider";
import { equalsSome } from "../Devices/Overview/useColumns";

function getColumnProps(key: string): Partial<GridColumn<SupportDto>> {
    switch (key) {
       
        case "dealer_id":
        case "customer_id": {
            return {
                disableFilters: false,
                Filter: AutocompleteColumnFilter,
                filterOrder: key === "dealer_id" ? 4 : 5,
                filter: equalsSome,
                filterShouldRenderCell: true,
                FilterChip: ({ filterValue, column: { id } }) => {
                    id = id+"";
                    const translator = useTranslator();
                    return (
                        <>
                            {translator.translateKey(id!)}:{" "}
                            {translator.translate(id!, filterValue)}
                        </>
                    );
                },
            };
        }
        case "updated_at":
        case "created_at" : {
            return {
                disableFilters: false,
                filterOrder: 6,
                filter: "dateColumn",
                Filter: TimestampColumnFilter,
                FilterChip: (val) => {
                    let label = "";
                    if (val.filterValue.values[0] !== null)
                        label += "Omit before " + moment.unix(val.filterValue.values[0]).utcOffset(timezone).format(TimeFormats.Presentation2);
                    if (val.filterValue.values[1] !== null) {
                        if (label.length !== 0)
                            label += " ";
                        label += "Omit after " + moment.unix(val.filterValue.values[1]).utcOffset(timezone).format(TimeFormats.Presentation2);
                    }
                    return label;
                },
            };
        }
        case "status": {
            return {
                disableFilters: false,
                Filter: OpenColumnFilter,
                filterOrder: 10,
                filter: "multiColumn",
                placeFilterOnToolbar: true,
                FilterChip: () => "Hide closed issues",
            };
        }
        case "assignee_email": {
            return {
                disableFilters: false,
                Filter: AssignedToMeColumnFilter,
                filterOrder: 11,
                filter: "multiColumn",
                placeFilterOnToolbar: true,
                FilterChip: () => "Only show mine",
            };
        }
        default:{
            return {
                disableFilters: false,
                filterOrder: 1,
                filter: equalsSome,
                Filter: AutocompleteColumnFilter,
            };
        }
    }
    
}

function makeColumns(keys: string[], isSelectorMode: boolean) {
    return {
        fixed: {
            Header: HeaderCell,
            id: "issueId",
            accessor: "issueId",
            width: isMobile ? 110 : 120,
            name: "Issue ID",
            Cell: isSelectorMode ? SelectorIdCell : IdCell,
            ...(getColumnProps("issueId") as any),
        } as GridColumn<SupportDto> & { width: number },
        keys: keys.map(
            (key) => {
                switch (key) {
                    case "category":
                        return {
                            Header: HeaderCell,
                            accessor: `data.${key}`,
                            Cell: CategoryCell,
                            id: key,
                            name: key,
                            ...getColumnProps(key),
                        };
                    case "created_at":
                    case "updated_at":
                        return {
                            Header: HeaderCell,
                            accessor: `data.${key}`,
                            Cell: DateCell,
                            id: key,
                            name: key,
                            ...getColumnProps(key),
                        };
                    default:
                        return {
                            Header: HeaderCell,
                            accessor: `data.${key}`,
                            Cell: TranslatedCell,
                            id: key,
                            name: key,
                            ...getColumnProps(key),
                        };
                }
            }
        ) as GridColumn<SupportDto>[],
    };
}

export interface ColumnOptions {
    keys: string[];
    isSelectorMode?: boolean;
}

export default function useColumns(options: ColumnOptions) {
    const { keys, isSelectorMode = false } = options;
    return useMemo(() => makeColumns(keys, isSelectorMode), [keys, isSelectorMode]);
}