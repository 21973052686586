import { useReducer, useEffect, useCallback, useMemo } from "react";
import { DataPayload, TabletConnectionPayload, Event, GroupedEvent, EventTypes } from "../../../events";
import { DeviceDto } from "../../../services/DeviceDataService";
import { useEvents } from "src/hooks";

interface DataState {
    lookUp: Record<number, DeviceDto>;
    data: DeviceDto[];
}

const SET = "SET";
const UPDATE = "UPDATE";
const CLEAR = "CLEAR";

const set = (data: DeviceDto[]) => ({
    type: SET as typeof SET,
    data,
});

const update = (deviceType: number, events: GroupedEvent) => ({
    type: UPDATE as typeof UPDATE,
    deviceType,
    events,
});

const clear = () => ({ type: CLEAR as typeof CLEAR });

type Action =
    | ReturnType<typeof set>
    | ReturnType<typeof update>
    | ReturnType<typeof clear>;

function mapDataEvents(
    deviceType: number,
    lookUp: Record<number, DeviceDto>,
    events: Event<DataPayload>[]
) {
    return events.reduce((acc, event) => {
        const {
            payload: { entries, id, type },
        } = event;

        if (type !== deviceType) return acc;

        const device = acc?.[id] ?? lookUp[id];

        if (device && entries.length > 0) {
            const newDevice = entries.reduce(
                (acc_, [key, value]) =>
                    acc_.data[key] === value
                        ? acc_
                        : { ...acc_, data: { ...acc_.data, [key]: value } },
                device
            );

            if (device !== newDevice) {
                return {
                    ...(acc || {}),
                    [id]: newDevice,
                };
            }
        }

        return acc;
    }, undefined as Record<number, DeviceDto> | undefined);
}

const currentTabletsKey = "Current Tablets";

function mapConnectionEvents(
    lookUp: Record<number, DeviceDto>,
    events: Event<TabletConnectionPayload>[]
) {
    const updateEntry = (
        entry: DeviceDto,
        connectedTablets: (string | number)[]
    ) => {
        return {
            ...entry,
            data: {
                ...entry.data,
                [currentTabletsKey]: connectedTablets.join(", "),
            },
        };
    };

    return events.reduce((acc, event) => {
        const {
            payload: { id: tabletId, connectionId, previousConnectionId },
        } = event;

        if (connectionId === previousConnectionId) {
            return acc;
        }

        let updates: Record<number, DeviceDto> | undefined;

        // Remove the tablet from its previously connected robot
        if (previousConnectionId !== undefined) {
            const ent = acc?.[previousConnectionId] ?? lookUp[previousConnectionId];
            const tabletConnections = ent?.data[currentTabletsKey];

            if (tabletConnections) {
                updates = {
                    [previousConnectionId]: updateEntry(
                        ent,
                        tabletConnections
                            .split(", ")
                            .filter((v) => +v !== tabletId)
                    ),
                };
            }
        }

        // Append the tablet to target robot
        const entry = acc?.[connectionId] ?? lookUp[connectionId];
        if (entry) {
            updates = {
                ...(updates || {}),
                [connectionId]: updateEntry(entry, [
                    ...((entry.data[currentTabletsKey] &&
                        entry.data[currentTabletsKey].split(", ")) ||
                        []),
                    tabletId,
                ]),
            };
        }

        if (updates) {
            return {
                ...(acc || {}),
                ...updates,
            };
        }

        return acc;
    }, undefined as Record<number, DeviceDto> | undefined);
}

function dataReducer(state: DataState, action: Action): DataState {
    switch (action.type) {
        case SET: {
            const { data } = action;
            if (data.length === state.data.length) {
                break;
            }
            return {
                lookUp: data.reduce(
                    (acc, next) => {
                        acc[next.deviceId] = next;
                        return (acc[next.deviceId]) && acc;
                    },
                    {} as Record<number, DeviceDto>
                ),
                data,
            };
        }
        case UPDATE: {
            const { deviceType, events: eventGroup } = action;
            let { lookUp } = state;
            if (eventGroup["data"]) {
                const dataUpdates = mapDataEvents(
                    deviceType,
                    lookUp,
                    eventGroup["data"].events
                );
                if (dataUpdates) {
                    lookUp = {
                        ...lookUp,
                        ...dataUpdates,
                    };
                }
            }
            if (eventGroup["tabletConnection"]) {
                const connectionUpdates = mapConnectionEvents(
                    lookUp,
                    eventGroup["tabletConnection"].events
                );
                if (connectionUpdates) {
                    lookUp = {
                        ...lookUp,
                        ...connectionUpdates,
                    };
                }
            }
            if (lookUp === state.lookUp) {
                break;
            }
            return {
                lookUp,
                data: Object.values(lookUp),
            };
        }
        case CLEAR: {
            if (state.data.length === 0) {
                break;
            }
            return {
                lookUp: {},
                data: [],
            };
        }
        default:
            break;
    }

    return state;
}

export default function useControlledData(
    deviceType: number,
    uncontrolled: DeviceDto[] | undefined
): { data: DeviceDto[] } {
    const [{ data }, dispatch] = useReducer(dataReducer, {
        lookUp: {},
        data: [],
    });

    useEffect(() => {
        if (uncontrolled === undefined) {
            dispatch(clear());
            return;
        }

        dispatch(set(uncontrolled));
    }, [uncontrolled]);

    const updateData = useCallback(
        (events: GroupedEvent) => {
            dispatch(update(deviceType, events));
        },
        [deviceType]
    );

    const eventTypes: EventTypes[] = useMemo(() => {
        if (deviceType === 1) {
            return ["data", "tabletConnection"];
        }
        return ["data"];
    }, [deviceType]);

    useEvents(eventTypes, updateData);

    return {
        data,
    };
}
