import axios from "axios";
import moment from "moment";
import qs from "qs";
import { sleep } from "src/utils/common";
import { handleError } from "./Errors";

export interface RobotMap {
    id : number,
    robotName : string,
    type: string,
    customer? : number,
    customerName? : string,
    dealer? : number,
    dealerName? : string,
    lat : number,
    lng : number,
    timestamp : number,
    historyDisplacement? : number,
    marker? : google.maps.Marker,
    enableStandalone : () => void,
    enableCluster : () => void,
    disable : () => void,
}

export interface Address {
    country: string,
    state: string,
    city: string,
    road: string,
    postcode: string,
}

export interface LocationFilterOptions {
    history? : boolean;
    from? : Date;
    until? : Date;
    robots? : number[];
}

export interface Point{
    lat: number;
    lng: number
}
export interface IonospherePolygon{
    color: string,
    coordinates: Point[]
}

export default class LocationService {
    static async getRobotsLocation(
        options?: Partial<LocationFilterOptions>
    ): Promise<RobotMap[]> {
        const query = qs.stringify(options || {});

        try {
            const response = await axios.get(
                `/api/location${
                    (query && "?" + query) || ""
                }`
            ,{timeout:30000});
            return (response.data as any[]).map((robot : any) => {
                if (robot.lat > 90 || robot.lat < -90) {
                    robot.lat = 0;
                    robot.lng = 0;
                } else {
                    while (robot.lng > 180)
                        robot.lng -= 360;
                    while (robot.lng < -180)
                        robot.lng += 360;
                }
                return {...robot,
                    enableStandalone : () => {/*noop*/},
                    enableCluster : () => {/*noop*/},
                    disable : () => {/*noop*/}
                };
            });
        } catch (err) {
            return handleError(err, false);
        }
    }
    static async getIonosphere(timestamp: Date):Promise<IonospherePolygon[]>{
        try {
            const query=qs.stringify({timestamp})
            const response=await axios(`/api/location/ionosphere${(query && "?" + query) || ""}`)
            return response.data as IonospherePolygon[]
        } catch (error) {
            return handleError(error, false);
        }
    }

    static geocodeQueue = [Promise.resolve()] as Array<Promise<Address|void>>;
    static geocodeRequestThrottle = 499;
    static geocodeLastRequest = 0;
    static async geocode( lat : number, lng : number ): Promise<Address> {
        //bug => not rejecting properly because of async
        const pr = new Promise<Address>(async (resolve) => { //eslint-disable-line no-async-promise-executor
            //chain execution (wait for last request to finish)
            await this.geocodeQueue[this.geocodeQueue.length-1];
            let res : any;
            do {
                //limit number of requests
                if (moment.now() - this.geocodeLastRequest < this.geocodeRequestThrottle)
                    await sleep(this.geocodeRequestThrottle - (moment.now() - this.geocodeLastRequest));
                this.geocodeLastRequest = moment.now();
                res = await fetch(new Request("https://us1.locationiq.com/v1/reverse.php?key=pk.a3d8fa6660e34e78c579e8324280b69b&format=json&lat="+lat+"&lon="+lng)).catch();
            } while (res !== undefined && !res.ok && res.status === 429)
            if (res === undefined || !res.ok) {
                resolve ({
                    country: "?",
                    state: "?",
                    city: "?",
                    road: "?",
                    postcode: "?",
                });
            }
            else {
                const temp = (await res.json()).address;
                resolve ({
                    country: temp.country ?? "?",
                    state: temp.state ?? "?",
                    city: temp.city ?? temp.town ?? temp.village ?? "?",
                    road: temp.road ?? "?",
                    postcode: temp.postcode ?? "?",
                });
            }
        });
        this.geocodeQueue.push(pr);
        return pr;
    }
}

export function distance(lat1 : number, lng1 : number, lat2 : number, lng2 : number) : number {
    const r = 6371e3;
    const phi1 = lat1 * Math.PI/180;
    const phi2 = lat2 * Math.PI/180;
    const deltaPhi = (lat2-lat1) * Math.PI/180;
    const deltaLambda = (lng2-lng1) * Math.PI/180;
    const a = Math.sin(deltaPhi/2) * Math.sin(deltaPhi/2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda/2) * Math.sin(deltaLambda/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return r * c;
}