import {
    SIGNING_IN,
    SIGNED_IN,
    SIGN_OUT,
    AuthAction,
    AuthState,
    Identity,
} from "./types";

const initialState: AuthState = {
    identity: {
        authenticated: false,
    },
    signingIn: false,
    error: null,
    TFANeeded: false,
};

export default function reducer(
    state = initialState,
    action?: AuthAction
): AuthState {
    switch (action?.type) {
        case SIGNING_IN:
            return {
                ...state,
                signingIn: true,
                error: null,
            };
        case SIGNED_IN: {
            const identity: Identity = {
                authenticated: action.payload.success,
            } as any;

            if (identity.authenticated) {
                identity.credentials = (action.payload as any).credentials;
            }

            return {
                identity,
                signingIn: false,
                error: (action.payload as any).error || null,
                TFANeeded: !action.payload.success?action.payload.TFANeeded:false
            };
        }
        case SIGN_OUT: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
}
