import { useEffect, useState } from "react";

export default function useDebounced<T>(value: T, delay?: number) {
    const [debounced, setDebounced] = useState(value);
    useEffect(() => {
        const timeoutId = setTimeout(setDebounced, delay, value);
        // Cancel the timeout on value change, delay change or unmount
        return () => clearTimeout(timeoutId);
    }, [value, delay]);
    return debounced;
}
