/* eslint-disable @typescript-eslint/ban-types */
import React, { FunctionComponent } from "react";
import { TableInstance, TableState, TableRowProps, TableBodyProps, Row, TableKeyedProps, MetaBase } from "react-table";
import { isMobile } from "react-device-detect";
import { createSelector } from "reselect";

export type WithInstanceProps<T extends {}> = Omit<T, "instance">;

export function withInstance<Select, Props>(
    select: (instance: TableInstance<any>) => Select,
    Component: FunctionComponent<Props>,
    whyDidYouRender = false
) {
    (Component as any).whyDidYouRender = whyDidYouRender;

    const MemoizedComponent = (React.memo(
        Component
    ) as unknown) as typeof Component;

    return function WithInstance({
        instance,
        forwardRef,
        ...rest
    }: Omit<Props, keyof Select> & {
        instance: TableInstance<any>;
        forwardRef?: React.Ref<any> | React.RefObject<any>;
    }) {
        const props = ({
            ...rest,
            ...select(instance),
        } as unknown) as Props;

        return <MemoizedComponent ref={forwardRef} {...props} />;
    } as <DD extends {}>(
        props: Omit<Props, keyof Select> & {
            instance: TableInstance<DD>;
            forwardRef?: React.Ref<any> | React.RefObject<any>;
        }
    ) => JSX.Element;
}

export const defaultStorageMapper = createSelector(
    (state: TableState<any>) => state.sortBy,
    (s:any) => s.filters,
    (s:any) => s.pageSize,
    (s:any) => s.hiddenColumns,
    (s:any) => s.columnOrder,
    (sortBy, filters, pageSize, hiddenColumns, columnOrder) => ({
        sortBy,
        filters,
        pageSize,
        hiddenColumns,
        columnOrder,
    })
);

export const defaultRowsPerPageOptions = isMobile
    ? [10, 15, 20, 25]
    : [10, 25, 50, 100];

export type PropGetter<T extends {}, Props, MetaArgs> = (
    props: Props,
    meta: MetaBase<T> & MetaArgs
) => Record<string, any>[];

export type GetRowProps<T extends {}> = PropGetter<
    T,
    TableRowProps<T>,
    { row: Row<T> }
>;

export type GetBodyProps<T extends {}> = PropGetter<T, TableBodyProps, {}>;

export function applyUserProps<
    GetProps extends (
        props: any,
        meta: any
    ) =>
        | (Partial<TableKeyedProps> & Partial<Record<string, any>>)
        | (Partial<TableKeyedProps> & Partial<Record<string, any>>)[],
    Rest extends (Record<string, any> | GetProps)[]
>(propGetter: GetProps | undefined, ...rest: Rest) {
    if (propGetter === undefined) {
        return function (...[props, meta]: any[]) {
            return ([
                props,
                ...mapPropGetters({}, meta, rest),
            ] as unknown) as (Partial<TableKeyedProps> & Record<string, any>)[];
        };
    }
    return function (...[props, meta]: any[]) {
        return ([
            ...mapPropGetters(props, meta, [propGetter]),
            ...mapPropGetters({}, meta, rest),
        ] as unknown) as (Partial<TableKeyedProps> & Record<string, any>)[];
    };
}

function isPropGetter(
    arg: any
): arg is (
    props: any,
    meta: any
) =>
    | (Partial<TableKeyedProps> & Partial<Record<string, any>>)
    | (Partial<TableKeyedProps> & Partial<Record<string, any>>)[] {
    return typeof arg === "function";
}

function mapPropGetters<
    Meta,
    Props,
    PropGetter extends
        | (Partial<TableKeyedProps> & Record<string, any>)
        | ((
              p: any,
              m: any
          ) =>
              | (Partial<TableKeyedProps> & Partial<Record<string, any>>)
              | (Partial<TableKeyedProps> & Partial<Record<string, any>>)[])
>(
    props: Props,
    meta: Meta,
    propGetters: PropGetter[]
): (Partial<TableKeyedProps> & Record<string, any>)[] {
    return propGetters.flatMap((r) => (isPropGetter(r) ? r(props, meta) : r));
}
