import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TextField from '@material-ui/core/TextField';

import Search from '@material-ui/icons/Search';
import Refresh from '@material-ui/icons/Refresh';
import { FormControlLabel, Switch } from '@material-ui/core';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CustomPaginationActionsTable(props) {

  let table_id = props.id;
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(window.localStorage.getItem(table_id + "_row_count")) || 25);
  const [filterText, setFilter] = React.useState('');
  const [strictSearch, setStrictSearch] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let new_table_count = parseInt(event.target.value, 10);
    window.localStorage.setItem(table_id + "_row_count", new_table_count);
    setRowsPerPage(new_table_count);
    setPage(0);
  };

  let lowerFilterText = filterText.toLowerCase();
  let filterData = props.data.filter((r) => {

    let show = true;
    if(strictSearch && filterText){
        let show_this = false;
        props.headeres.forEach((column) => {
          if (column.search) {
            if (r[column.search] instanceof Array) {
              r[column.search].forEach((e) => {
                show_this |= (e[column.ArraySeach] ? e[column.ArraySeach] : "").toString()===filterText;
              });
            }
            else
              show_this |= (r[column.search] ? r[column.search] : "").toString()===filterText;
          }

        });
        show &= show_this;
      return show;
    }
    else{
      lowerFilterText.split(" ").forEach(splitSearch => {
        let show_this = false;
        props.headeres.forEach((column) => {
          if (column.search) {
            if (r[column.search] instanceof Array) {
              r[column.search].forEach((e) => {
                show_this |= (e[column.ArraySeach] ? e[column.ArraySeach] : "").toString().toLowerCase().indexOf(splitSearch) >= 0;
              });
            }
            else
              show_this |= (r[column.search] ? r[column.search] : "").toString().toLowerCase().indexOf(splitSearch) >= 0;
          }

        });
        show &= show_this;
      })
    return show;
    }
  });


  return (
    <>
      <IconButton style={{ "float": "left" }} color="primary" aria-label="refresh groups" component="span" onClick={props.refresh} >
        <Refresh />
      </IconButton>
      <div style={{ "float": "right" }} >
        <TextField label="Filter" value={filterText} onChange={(e) => setFilter(e.target.value)} />
        <Search style={{ "marginTop": "20px" }} />
        <FormControlLabel style={{ "marginLeft": "5px", "marginTop": "-15px" }}
          control={
              <Switch size="small" checked={strictSearch} onChange={(ev) => {setStrictSearch(ev.target.checked)}} />
          }
          label={"Strict search"}
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table pagination" size="small">
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100, { label: 'All', value: -1 }]}
                colSpan={100}
                count={filterData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Table className={classes.table} aria-label="custom pagination table" size="small">
          <TableHead>
            <TableRow>
              {props.headeres.map((column) => (
                <TableCell
                  key={column.key}
                  align={column.align}
                  style={column.style}
                >
                  <div style={{ "fontWeight": "bold" }}>{column.Header}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filterData
            ).map((row) => (
              <TableRow key={row.Id} style={{backgroundColor:(row.setSecondayColor ? "red": "")}}>

                {props.headeres.map((column, i) => (
                  <TableCell component="th" scope="row" style={column.style} key={i} >
                    {row[column.key]}
                  </TableCell>
                ))}

              </TableRow>
            ))}

          </TableBody>

        </Table>
      </TableContainer>
    </>
  );
}