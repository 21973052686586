import React, { useState, useMemo, useContext } from "react";

export interface HoveredRowInstance {
    rowIndex: number | null;
    setRowIndex: (set: (previousIndex: number | null) => number | null) => void;
}

const HoveredRowContext = React.createContext<HoveredRowInstance>(undefined!);

export default function HoveredRowProvider(
    props: React.PropsWithChildren<unknown>
) {
    const [rowIndex, setRowIndex] = useState<number | null>(null);

    const instance = useMemo(
        () => ({
            rowIndex,
            setRowIndex,
        }),
        [rowIndex]
    );

    return (
        <HoveredRowContext.Provider value={instance}>
            {props.children}
        </HoveredRowContext.Provider>
    );
}

export function useHoveredRow() {
    return useContext(HoveredRowContext);
}
