
export interface UserCredentials {
    userAccess: string[];
    username: string;
    customerId: number[];
    customerName: string[];
    isTwoFactorAuthenticationEnabled:boolean,
    twoFactorAuthentificationCode:string
}

interface Authenticated {
    authenticated: true;
    credentials: UserCredentials;
}

interface Unauthenticated {
    authenticated: false;
}

export type Identity = Authenticated | Unauthenticated;

export interface AuthState {
    identity: Identity;
    signingIn: boolean;
    error: any;
    TFANeeded: boolean;
}

export const SIGN_IN = "SIGN_IN";
export const SIGNING_IN = "SIGNING_IN";
export const SIGNED_IN = "SIGNED_IN";
export const SIGN_OUT = "SIGN_OUT";
export const INIT_AUTH = "INIT_AUTH";

export interface SignInAction {
    type: typeof SIGN_IN;
    payload: {
        username: string;
        password: string;
        confirmationCode?: string;
    };
}

export interface SigningInAction {
    type: typeof SIGNING_IN;
}

interface SignedInActionSuccess {
    type: typeof SIGNED_IN;
    payload: {
        success: true;
        credentials: UserCredentials;
        redirect: boolean;
    };
}

interface SignedInActionFailed {
    type: typeof SIGNED_IN;
    payload: {
        success: false;
        error: any | undefined;
        TFANeeded: boolean;
    };
}

export type SignedInAction = SignedInActionSuccess | SignedInActionFailed;

export interface SignOutAction {
    type: typeof SIGN_OUT;
    meta?: {
        message: string;
    };
}

export interface InitAuthAction {
    type: typeof INIT_AUTH;
}

export type AuthAction =
    | SignInAction
    | SigningInAction
    | SignedInAction
    | SignOutAction
    | InitAuthAction;
