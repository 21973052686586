import React, { useMemo } from "react";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { Link, useLocation } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import matchSorter from "match-sorter";
import levenshtein from "../utils/levenshtein";

const suggestedPaths = [
    { path: "/devices/robots", withIndex: true },
    {
        path: "/devices/tablets",
        withIndex: true,
    },
    {
        path: "/support/documents",
        withIndex: false,
    },
    { path: "/settings/user", withIndex: false },
];

function getSuggestedPaths(pathName: string, threshold = 5) {
    const paths = suggestedPaths
        .map((p) => ({ ...p, distance: levenshtein(p.path, pathName) }))
        .filter((i) => i.distance <= threshold);
    return paths.sort((a, b) => a.distance - b.distance);
}

const getPathSuggestions = (pathName: string) => {
    const paths = pathName.split("/");
    if (paths.length && /^(\d+)[\\?.+]?/.test(paths[paths.length - 1])) {
        const index = +paths[paths.length - 1]!.split("?")[0];
        const remainingPaths = paths.slice(0, paths.length - 1);
        let suggested = getSuggestedPaths(remainingPaths.join("/"), 4);
        if (suggested.length === 0) {
            suggested = matchSorter(suggestedPaths, pathName, {
                keys: ["path"],
            });
        }
        if (suggested.length) {
            return suggested.map((s) =>
                s.withIndex ? `${s.path}/${index}` : s.path
            );
        }
        return undefined;
    } else {
        let suggested = getSuggestedPaths(pathName, 4);
        if (suggested.length === 0) {
            suggested = matchSorter(suggestedPaths, pathName, {
                keys: ["path"],
            });
        }
        return suggested.length > 0 ? suggested.map((s) => s.path) : undefined;
    }
};

const useStyles = makeStyles((theme) => ({
    header2: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    list: {
        "& a": {
            color: "black",
            textDecoration: "none",
        },
    },
    listItem: {
        marginLeft: theme.spacing(1),
        marginBottom: 0,
        paddingBottom: 0,
    },
}));

export default function NotFound() {
    const { pathname } = useLocation();
    const classes = useStyles();
    const pathSuggestions = useMemo(() => {
        return getPathSuggestions(pathname);
    }, [pathname]);

    const list = pathSuggestions !== undefined && (
        <>
            <List
                className={classes.list}
                subheader={
                    <ListSubheader>
                        Did you mean one of the following?
                    </ListSubheader>
                }
            >
                {pathSuggestions.map((sug) => (
                    <ListItem key={sug} className={classes.listItem}>
                        <ListItemText>
                            <Link to={sug}>
                                {"\u2022"} {sug}
                            </Link>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </>
    );

    return (
        <Container component="main" maxWidth={false}>
            <Typography component="h1" variant="h5">
                Page not found
            </Typography>
            <Typography component="h3" variant="h6" className={classes.header2}>
                No match for path <code>{pathname}</code>
            </Typography>
            {list}
        </Container>
    );
}
