import React from 'react';
import axios from 'axios';
import Table from "./Components/Table.jsx"
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';

import EditRobotAccessGroupsPopup from "./popups/EditRobotAccessGroupsPopup.jsx"

const small_style = {
    width: 1,
    whiteSpace: "nowrap"
}

const big_style = {};

function AccessGroupRow(props) {
    return (
        <>
            {props.AccessGroups.map(ag => <Chip label={ag.Name} key={ag.Id} />)}
        </>
    )
}
function RobotsRow(props) {
    return (
        <>
            {props.Robots.map(c => <Chip label={c.Id} key={c.Id} />)}
        </>
    )
}

class ManageRobotAccessGroupsSections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            robots: [],
            accessGroups: [],
            showAccessGroups: false,
            onlyShowEmpty: false
        };
        this.refresh = this.refresh.bind(this);
        this.refreshAfterEdit = this.refreshAfterEdit.bind(this);

        this.RobotHeaders = [
            {
                Header: 'Robot',
                key: 'RobotIdName',
                align: "left",
                style: small_style,
                search: "RobotIdName"
            },
            {
                Header: 'Customer',
                key: 'CustomerName',
                align: "left",
                style: small_style,
                search: "CustomerName"
            },
            {
                Header: 'Dealer',
                key: 'DealerName',
                align: "left",
                style: small_style,
                search: "DealerName"
            },
            {
                Header: 'AccessGroups',
                key: 'AccessGroupsElement',
                align: "left",
                style: big_style
            },
            {
                Header: '',
                key: 'EditElement',
                align: "left",
                style: small_style
            }
        ];

        this.AccessGroupHeaders = [
            {
                Header: 'AccessGroup',
                key: 'Name',
                align: "left",
                style: small_style,
                search: "Name"
            },
            {
                Header: 'Robots',
                key: 'RobotElement',
                align: "left",
                style: big_style
            },
            {
                Header: '',
                key: 'EditElement',
                align: "left",
                style: small_style
            }
        ];

    }



    componentDidMount() {
        this.refresh()
    }

    refreshAfterEdit() {
        setTimeout(this.refresh, 1000);
    }

    refresh(event) {
        if (event)
            event.preventDefault();
        axios(`/api/dbtool/robot_accessgroups`)
            .then(response => response.data[0])
            .then(state => {

                let robots = {};
                let accessGroups = {};

                for (let r of state) {
                    if (r.AccessGroupId && !accessGroups[r.AccessGroupId])
                        accessGroups[r.AccessGroupId] = { Id: r.AccessGroupId, Name: r.AccessGroupName, Robots: {} };
                    if (r.RobotId) {
                        if (!robots[r.RobotId])
                            robots[r.RobotId] = { Id: r.RobotId, Name: r.RobotName, CustomerName: r.CustomerName, DealerName: r.DealerName, AccessGroups: {} };
                        if (r.AccessGroupId) {
                            accessGroups[r.AccessGroupId].Robots[r.RobotId] = robots[r.RobotId];
                            robots[r.RobotId].AccessGroups[r.AccessGroupId] = accessGroups[r.AccessGroupId];
                        }
                    }
                }

                robots = Object.values(robots);
                accessGroups = Object.values(accessGroups);


                for (let r of robots) {
                    r.AccessGroups = Object.values(r.AccessGroups);
                    r.AccessGroupsElement = AccessGroupRow({ AccessGroups: r.AccessGroups });
                    r.RobotIdName = r.Id + " (" + r.Name + ")";
                    r.EditElement = <EditRobotAccessGroupsPopup Robot={r} AccessGroups={r.AccessGroups} whenSuccess={this.refreshAfterEdit} />
                }

                for (let a of accessGroups) {
                    a.Robots = Object.values(a.Robots);
                    a.RobotElement = RobotsRow({ Robots: a.Robots });
                }

                robots = robots.sort((a, b) => {
                    return a.Name > b.Name;
                });

                this.setState({ robots: robots, accessGroups: accessGroups });
            }
            );
    }

    render() {
        return (
            <React.Fragment>
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Accessgroups on robots</Grid>
                    <Grid item>
                        <Switch checked={this.state.showAccessGroups} onChange={event => this.setState({ showAccessGroups: event.target.checked })} name="checkedC" />
                    </Grid>
                    <Grid item>Robots on Accessgroups</Grid>
                </Grid>
                {
                    !this.state.showAccessGroups ?
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>Only show empty</Grid>
                            <Grid item>
                                <Switch checked={this.state.onlyShowEmpty} onChange={event => this.setState({ onlyShowEmpty: event.target.checked })} name="checkedCB" />
                            </Grid>

                        </Grid>
                        :
                        <React.Fragment></React.Fragment>
                }


                {
                    this.state.showAccessGroups ?
                        <Table id="robot_access_groups_table" headeres={this.AccessGroupHeaders} data={this.state.accessGroups} refresh={this.refresh} />
                        :
                        <Table id="robot_access_groups_table" headeres={this.RobotHeaders} data={this.state.onlyShowEmpty ? this.state.robots.filter(c => !(Object.keys(c.AccessGroups)?.length)) : this.state.robots} refresh={this.refresh} />
                }

            </React.Fragment>
        );
    }
}

export default ManageRobotAccessGroupsSections;