import { Box, Checkbox, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingButton from "src/components/LoadingButton";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import ConfigurationsService, { DDQueryExecuteParam, DDQueryParam, DeviceDataQuery } from "src/services/ConfigurationsService";
import theme from "src/theme";
import getErrorProps from "src/utils/getErrorProps";
import DynamicColumnsTable from "./DynamicColumnsTable";
import NotFound from "../NotFound";
import { useSelector } from "react-redux";
import { userAccessSelector } from "src/redux/auth/selectors";
import { TabContext, TabPanel } from "@material-ui/lab";
import AutocompleteV2 from "src/components/AutocompleteV2";
import useFormStyles from "../Account/useFormStyles";
import { userSelector } from "src/redux/app/selectors";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, FormControlLabel, Switch } from "@mui/material";

type ExecuteQueryInput = {
    whQuery: string;
    dbQuery: string;
    params: DDQueryExecuteParam[];
    dbQueryFirst: boolean;
};
type SaveQueryInput = Omit<DeviceDataQuery, "id"|"username">
function fetchDataCallback(input: ExecuteQueryInput) {
    return ConfigurationsService.executeDataQuery(input.whQuery, input.dbQuery, input.params, input.dbQueryFirst);
}
function saveQueriesCallback(input: SaveQueryInput) {
    return ConfigurationsService.postDataQuery(input);
}
function getQueries() {
    return ConfigurationsService.getAccessibleDataQueries();
}

export default function DataExtractor() {
    const executeQueryForm = useForm<ExecuteQueryInput>({
        defaultValues: { whQuery: "", dbQuery: "", params: [], dbQueryFirst: false},
    });

    const saveQueryForm = useForm<SaveQueryInput>({
        defaultValues: {name: "", description: undefined, whQuery: "", dbQuery: "", isPublic: false, params: []},
    });

    const formClasses = useFormStyles();
    const { displayToast } = useToast();
    const [tabSwitch, setTabSwitch] = useState("QueryOverview");
    const [selectedQuery, setSelectedQuery] = useState<DeviceDataQuery | null>(null);
    const [latestError, setLatestError] = useState("");

    const currentUser = useSelector(userSelector);

    const paramsExecute = executeQueryForm.watch("params");

    const name = saveQueryForm.watch("name");
    const description = saveQueryForm.watch("description");
    const whQuery = saveQueryForm.watch("whQuery");
    const dbQuery = saveQueryForm.watch("dbQuery");
    
    const isPublic = saveQueryForm.watch("isPublic");
    const params = saveQueryForm.watch("params");
    const dbQueryFirst = saveQueryForm.watch("dbQueryFirst");

    useEffect(() => {
        executeQueryForm.register("whQuery", {required: "This field is required"});
        executeQueryForm.register("dbQuery", {required: "This field is required"});
        executeQueryForm.register("params", );
        executeQueryForm.register("dbQueryFirst", );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeQueryForm.register]);

    useEffect(() => {
        saveQueryForm.register("name", {required: "This field is required"});
        saveQueryForm.register("description", );
        saveQueryForm.register("whQuery", {required: "This field is required"});
        saveQueryForm.register("dbQuery", {required: "This field is required"});
        saveQueryForm.register("isPublic", );
        saveQueryForm.register("dbQueryFirst", );
        saveQueryForm.register("params", {validate: (ps: DDQueryParam[]) => {
            if(ps.some((p) => p.name === ""))
                return "All paramters must have a name";
            else
                return true;
        }});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveQueryForm.register]);

    const onCompleteExecute = useCallback(
        () => {
            setLatestError("");
            displayToast({
                message: `Query result retrieved. Check the table below`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast]
    );
    
    const {value: queries,  exec: fetchQueries, pending: loadingQueries } = useAsync(
        getQueries,
        {
            immediate: false,
        }
    );

    const onCompleteSave = useCallback(
        () => {
            fetchQueries();
            setLatestError("");
            displayToast({
                message: `Queries saved successfully`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast, fetchQueries]
    );

    const onCompleteDelete = useCallback(
        () => {
            displayToast({
                message: `Query deleted successfully`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast]
    );

    const {value: data,  exec: fetchData, pending: loading, error } = useAsync(
        fetchDataCallback,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: executeQueryForm.setError as any }),
            onComplete: onCompleteExecute,
        }
    );
    const {  exec: saveQueries, pending: saving, error: saveError } = useAsync(
        saveQueriesCallback,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: saveQueryForm.setError as any }),
            onComplete: onCompleteSave,
        }
    );

    function deleteQueryCallback(){
        return ConfigurationsService.deleteDataQuery(selectedQuery?.id ?? 0).then(() => fetchQueries()).then(() => setSelectedQuery(null));
    }

    const {  exec: deleteQuery, pending: deleting, } = useAsync(
        deleteQueryCallback,
        {
            immediate: false,
            onComplete: onCompleteDelete,
        }
    );

    useEffect(() => {
        fetchQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onNewQuery = useCallback(() => {
        setTabSwitch("QueryEditor");
        saveQueryForm.reset();
        executeQueryForm.reset();
        setLatestError("");
    },[executeQueryForm, saveQueryForm])

    const onNewQueryFromSelection = useCallback(() => {
        setTabSwitch("QueryEditor");

        saveQueryForm.setValue("name",selectedQuery?.name)
        saveQueryForm.setValue("description",selectedQuery?.description)
        saveQueryForm.setValue("whQuery",selectedQuery?.whQuery)
        saveQueryForm.setValue("dbQuery",selectedQuery?.dbQuery)
        saveQueryForm.setValue("isPublic",selectedQuery?.isPublic)
        saveQueryForm.setValue("dbQueryFirst",selectedQuery?.dbQueryFirst)
        saveQueryForm.setValue("params",selectedQuery?.params.map(p => {return {...p}}))

        executeQueryForm.setValue("whQuery",selectedQuery?.whQuery)
        executeQueryForm.setValue("dbQuery",selectedQuery?.dbQuery)
        executeQueryForm.setValue("dbQueryFirst",selectedQuery?.dbQueryFirst)
        executeQueryForm.setValue("params", selectedQuery?.params?.map((ddqp) => { 
            return {
                order: ddqp.order,
                value: paramsExecute.find((p) => {
                    return p.order === ddqp.order
                })?.value ?? new Date()
            }
        }))

        setLatestError("");
    },[executeQueryForm, paramsExecute, saveQueryForm, selectedQuery?.dbQuery, selectedQuery?.dbQueryFirst, selectedQuery?.description, selectedQuery?.isPublic, selectedQuery?.name, selectedQuery?.params, selectedQuery?.whQuery])

    const onBackToOverview = useCallback(() => {
        setTabSwitch("QueryOverview");
        saveQueryForm.reset();

        executeQueryForm.setValue("whQuery",selectedQuery?.whQuery)
        executeQueryForm.setValue("dbQuery",selectedQuery?.dbQuery)
        executeQueryForm.setValue("dbQueryFirst",selectedQuery?.dbQueryFirst)
        executeQueryForm.setValue("params", selectedQuery?.params?.map((ddqp) => { 
            return {
                order: ddqp.order,
                value: new Date()
            }
        }))
        setLatestError("");

    },[executeQueryForm, saveQueryForm, selectedQuery?.dbQuery, selectedQuery?.dbQueryFirst, selectedQuery?.params, selectedQuery?.whQuery])
    
    useEffect(() => {
        setLatestError(error?.message ?? "");
    },[error?.message])
    useEffect(() => {
        setLatestError(saveError?.message ?? "");
    },[saveError?.message])
    const dataExtractorReadAccess = useSelector(userAccessSelector).includes('DataExtractorRead');
    const dataExtractorWriteAccess = useSelector(userAccessSelector).includes('DataExtractor');
    
    const queryInputFields = [ (<Grid item xs={12} xl={6}>
        <Typography align="center" variant="h6">Data warehouse query</Typography>
            <TextField 
            fullWidth
            multiline 
            rows={6} 
            rowsMax={30}
            variant="outlined"
            value={whQuery}
            placeholder="eg. SELECT * FROM device_data_warehouse WHERE created > ddparam_1"
            onChange={(ev) => {
                executeQueryForm.setValue("whQuery", ev.target.value);
                saveQueryForm.setValue("whQuery", ev.target.value);
            }}
            {...getErrorProps(saveQueryForm.errors as any, "whQuery")}

        />
        </Grid>),
        (<Grid item xs={12} xl={6}>
            <Typography align="center" variant="h6">Database query</Typography>
            <TextField 
                fullWidth
                multiline
                rows={6} 
                rowsMax={30} 
                variant="outlined"
                value={dbQuery}
                placeholder="eg. SELECT ddwh.device_id FROM device_data_wh as ddwh WHERE ddwh.inserted > ddparam_2"
                onChange={(ev) => {
                    executeQueryForm.setValue("dbQuery", ev.target.value);
                    saveQueryForm.setValue("dbQuery", ev.target.value);
                }}
                {...(getErrorProps(saveQueryForm.errors as any, "dbQuery") )}
            />
        </Grid>)]

    if (!dataExtractorReadAccess)
    return (<NotFound/>)
    return(
   
    <Box marginX={1}>
        
        <TabContext value={tabSwitch}>
            <Typography variant="h4">Data Extractor</Typography>
            <TabPanel value="QueryOverview">
                <Typography variant="h5">Query Overview</Typography>
                <Typography variant="subtitle1">
                    <ul>
                        <li>Execute and retrieve results from "public" queries created by other users</li>
                        {dataExtractorWriteAccess && <li> Execute and manage your private queries</li>}
                        <li>Public queries are only visible to users with 'DataExtractorRead' access</li>
                        <li>Some queries have cutomizable date-time parameters which can be adjusted via the DateTime pickers</li>
                    </ul>
                </Typography>
                {dataExtractorWriteAccess && <Button variant="contained" color="success" style={{margin: theme.spacing(2)}} onClick={onNewQuery}>New query</Button>}
                <AutocompleteV2
                    style={{margin: theme.spacing(2)}}
                    selectOnTab
                    value={selectedQuery}
                    disabled={loadingQueries}
                    options={queries || []}
                    getOptionLabel={(r) => `${r.name} [${r.username}] (${r.isPublic ? "Public" : "Private"})`}
                    getOptionSelected={(a, b) => a.id === b.id}
                    noOptionsText="No saved queries"
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                label="Choose a query to view its details"
                                placeholder="Choose a query"
                            />
                        );
                    }}
                    onChange={(_ev, val) => {
                        setSelectedQuery(val);
                        executeQueryForm.setValue("whQuery", val?.whQuery);
                        executeQueryForm.setValue("dbQuery", val?.dbQuery);
                        executeQueryForm.setValue("dbQueryFirst", val?.dbQueryFirst);
                        executeQueryForm.setValue("params", val?.params?.map((ddqp) => { 
                            return {
                                order: ddqp.order,
                                value: new Date()
                            }
                        }))
                    }}
                />
                <Box marginX={2} marginBottom={4}>
                    <Typography variant="h6">Name:</Typography>
                    <Typography variant="body1">{selectedQuery?.name}</Typography>

                    <Typography variant="h6">Description:</Typography>
                    <Typography variant="body1">{selectedQuery?.description}</Typography>
                
                    <Typography variant="h6">Parameters:</Typography>
                    {selectedQuery?.params?.map((param, index) => {
                        return (
                            <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} gridGap={theme.spacing(2)} maxWidth={800} key={index}>
                                <Typography style={{width: 150}} align="right" variant="body1" color="textPrimary">
                                    {param.name}:
                                </Typography>
                                
                                <KeyboardDateTimePicker
                                    style={{width: 280}}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    inputVariant="outlined"
                                    format="MMMM Do YYYY HH:mm"
                                    value={paramsExecute[index]?.value}
                                    onChange={(date) => {
                                        paramsExecute[index].value = date?.toDate() ?? new Date(Date.now())
                                        executeQueryForm.setValue(`params`, paramsExecute);
                                    }}
                                    ampm={false}
                                    InputLabelProps={{
                                        //shrink: true
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    KeyboardButtonProps={{ size: "small" }}
                                    InputAdornmentProps={{ 
                                        position: "start" 
                                        
                                    }}
                                />
                                
                            </Box>)
                    })}
                </Box>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} margin={2}>
                    {selectedQuery && <form onSubmit={executeQueryForm.handleSubmit(fetchData)} noValidate>
                        <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>Execute selected query</LoadingButton>
                    </form>}

                    {dataExtractorWriteAccess && selectedQuery && <Button variant="contained" color="success" onClick={onNewQueryFromSelection}>New query from selection</Button>}

                    {currentUser.username === selectedQuery?.username &&
                        <Button color="error" variant="contained" onClick={deleteQuery} disabled={deleting}>Delete query</Button>
                   }
                </Box>
            </TabPanel>

            <TabPanel value="QueryEditor">
                <Button variant="outlined" size="small" style={{marginBottom: theme.spacing(2)}} startIcon={<ArrowBackIosNewIcon/>} onClick={onBackToOverview}>Back to overview</Button>
                <Typography variant="h5">Query Editor</Typography>
                {/* <Typography variant="h6">How it works</Typography> */}
                <Typography variant="subtitle1">
                    <ul>
                        <li>Data Warehouse Query -{">"} Database Query: results from <b><i>Data warehouse query</i></b> will be available as a table for <b><i>Database query</i></b>. To access this table from <b><i>Database query</i></b> use the following: <i>"device_data_wh as ddwh"</i></li>
                        <li>Database Query -{">"} Data Warehouse Query: results from <b><i>Database query</i></b> will be available as a table for <b><i>Data warehouse query</i></b>. To access this table from <b><i>Data warehouse query</i></b> use the following: <i>"db_data as dbd"</i></li>
                        <li>Device data is stored in the <b><i>device_data_warehouse</i></b> table from the data warehouse. </li>
                        <li>The <b><i>Data warehouse</i></b> does not supprt the "0000-00-00 00:00:00.000" timestamp.</li>
                        <li>The first query will have "limit 10000" automatically appended at the end</li>
                        <li>The result table from the first query must have an alias for each column represented by a function (eg. <i>select COUNT(*) as count</i>)</li>
                        <li>Query results do not need to follow any particular table structure</li>
                        <li>This tool only works with SELECT queries</li>
                        <li>Query Parameters can be added so that queries can be executed from Query Overview with different values</li>
                        <li>Only timestamps are supported as paramteters</li>
                        <li>Any parameter can be used in both queries in an unlimited amount of places</li>
                        <li>Parameters can placed in queries with: "ddparam_1" or "ddparam_2"  etc. (it will be written in front of each of them)</li>
                        <li>Parameters must either have a name when saving the queries OR a date-time value when executing. (values are not saved and names are not used when executing)</li>
                    </ul>
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2} xl={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                        <Typography variant="body1" align="right">Name: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                        style={{maxWidth: 500}}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={(ev) => {
                            saveQueryForm.setValue("name", ev.target.value);
                        }} 
                        {...getErrorProps(saveQueryForm.errors as any, "name")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2} xl={1}>
                        <Typography variant="body1" align="right">Description: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                        style={{maxWidth: 700}}
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={6}
                        variant="outlined"
                        value={description}
                        onChange={(ev) => {
                            saveQueryForm.setValue("description", ev.target.value);
                        }}
                        {...getErrorProps(saveQueryForm.errors as any, "description")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2} xl={1} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                        <Typography variant="body1" align="right">Make public: </Typography>
                    </Grid>
                    <Grid item xs>
                        <Checkbox
                        checked={isPublic ? true: false}
                        onChange={(ev) => {
                            saveQueryForm.setValue("isPublic", ev.target.checked)
                        }}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <Typography variant="h6" style={{marginBottom: theme.spacing(1)}}>Query parameters</Typography>
                    {params?.map((param, index) => {
                        return (
                            <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"} maxWidth={1000}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    ddparam_{param.order}
                                </Typography>
                                <TextField
                                    style={{maxWidth: 400}}
                                    margin="dense"
                                    fullWidth
                                    placeholder="Parameter name..."
                                    label="Parameter name"
                                    variant="outlined"
                                    value={params[index].name}
                                    onChange={(ev) => {
                                        params[index].name = ev.target.value
                                        saveQueryForm.setValue(`params`, params);
                                    }}
                                    //{...getErrorProps(saveQueryForm.errors as any, `params`)}
                                /> 
                                <KeyboardDateTimePicker
                                    style={{width: 280}}
                                    fullWidth
                                    margin="dense"
                                    inputVariant="outlined"
                                    format="MMMM Do YYYY HH:mm"
                                    value={paramsExecute[index].value}
                                    onChange={(date) => {
                                        paramsExecute[index].value = date?.toDate() ?? new Date(Date.now())
                                        executeQueryForm.setValue(`params`, paramsExecute);
                                    }}
                                    ampm={false}
                                    InputLabelProps={{
                                        //shrink: true
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    KeyboardButtonProps={{ size: "small" }}
                                    InputAdornmentProps={{ 
                                        position: "start" 
                                        
                                    }}
                                />
                                
                                
                                <IconButton 
                                    onClick={() => {
                                        saveQueryForm.setValue("params", params.filter((p, i) =>  index !== i).map((p, index) => {return {...p, order: index+1}}))
                                        executeQueryForm.setValue("params", paramsExecute.filter((p, i) =>  index !== i).map((p, index) => {return {...p, order: index+1}}))
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>)
                    })}
                    <Button style={{marginBottom: theme.spacing(1), marginTop: theme.spacing(1)}}variant="outlined" onClick={() => {
                        saveQueryForm.setValue(`params`, params?.concat({name: "", order: params.length+1}));
                        executeQueryForm.setValue(`params`, paramsExecute?.concat({value: new Date(), order: params.length+1}));
                    }}>Add parameter</Button>
                </Box>
                <Box>
                <FormControlLabel
                    control={
                        <Switch
                            size="small"
                            checked={dbQueryFirst ? true: false}
                            onChange={(ev) => {
                                executeQueryForm.setValue("dbQueryFirst",ev.target.checked);
                                saveQueryForm.setValue("dbQueryFirst",ev.target.checked);
                            }}
                        />
                    }
                    label="Execute Database Query before Data Warehouse Query"
                />
                </Box>
                <Grid container spacing={1}>
                    {dbQueryFirst? [queryInputFields[1], queryInputFields[0]] : [queryInputFields[0],queryInputFields[1]]}
                </Grid>

                <Typography align="center" variant="subtitle1" color="error">{saveQueryForm.errors.params?.message}</Typography>
                <Typography align="center" variant="subtitle1" color="error">{latestError}</Typography>
                
                <Box style={{display: 'flex', justifyContent: "space-around"}} margin={2} marginBottom={2}>
                    <Box>
                        <form onSubmit={saveQueryForm.handleSubmit(saveQueries)} noValidate>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={saving}
                                >
                                Save new queries
                            </LoadingButton>
                        </form>
                    </Box>
                    <Box>
                        <form onSubmit={executeQueryForm.handleSubmit(fetchData)} noValidate>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={loading}
                            >
                            Execute queries
                            </LoadingButton>
                        </form>
                    </Box>
                </Box>
                    
            </TabPanel>

        </TabContext>
       
        <Typography align="center" variant="h5">Query Result</Typography>
        <Box paddingX={2}>
            <DynamicColumnsTable data={data} loading={loading}/>
        </Box>
    </Box>
   
    )
}