import React, { useState } from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import OnIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";

export interface ToggleVisibilityProps
    extends Omit<IconButtonProps, "onClick" | "disabled"> {
    checked?: boolean;
    onClick?: (isToggled : boolean) => void;
    title?: string;
    tooltipProps?: Partial<TooltipProps>;
    disabled?: boolean;
}

export default function ToogleVisibility(props: ToggleVisibilityProps) {
    const {
        checked = true,
        onClick = () => {/*noop*/},
        tooltipProps = {},
        title = "",
        disabled = false,
        ...rest
    } = props;

    const [isToggled, setToggled] = useState(checked);

    return (
        <Tooltip
            {...tooltipProps}
            title={title}
        >
            <IconButton
                aria-label={title.toLowerCase()}
                onClick={() => {
                    const temp = !isToggled;
                    setToggled(temp);
                    onClick(temp);
                }}
                disabled={disabled}
                {...rest}
            >
                {(isToggled) 
                    ?
                        <OnIcon />
                    :
                        <OffIcon />
                }
            </IconButton>
        </Tooltip>
    );
}
