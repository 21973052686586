import React, { useState } from "react";
import Loader from "src/components/Loader";
import GrowIn from "src/components/GrowIn";

export interface BaseViewProps {
    children: React.ReactNode;
    loading: boolean;
    error?: any;
    retry?: () => void;
}

export default function BaseView({
    children,
    loading,
    error,
    retry,
}: BaseViewProps) {
    const [mount, setMount] = useState(!loading);

    return (
        <>
            <Loader
                loading={loading}
                error={error}
                retry={retry}
                pastDelay
                onEnter={() => setMount(false)}
                onExited={() => {
                    setMount(true);
                }}
            />
            {mount && (
                <GrowIn>
                    <div>{children}</div>
                </GrowIn>
            )}
        </>
    );
}
