import React, { useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PasswordField from "src/components/PasswordField";
import LoadingButton from "src/components/LoadingButton";

import BaseView from "src/views/BaseView";
import useFormStyles from "./useFormStyles";
import { useAsync, useErrorHandler } from "src/hooks";
import getErrorProps from "src/utils/getErrorProps";
import UserService from "src/services/UserService";
import { useForm, Controller } from "react-hook-form";
import useQueryString from "src/hooks/useQueryString";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        left: "0px",
        top: "0px",
    },
}));

type ResetPasswordInput = {
    password: string;
    confirmPassword: string;
};

const defaultRules = {
    required: "This field is required",
};

export default function ResetPassword(props: {isReset? : boolean}) {
    const isReset = props.isReset ?? true;
    const notLoggedInClasses = useStyles();
    const classes = useFormStyles();
    const [token, _setToken] = useQueryString("token", "");
    const [passwordResetMessage, setPasswordResetMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const fetchData = useCallback(
        () => UserService.confirmPasswordResetToken(token), [token]
    );
    const { exec, pending: confirmationPending } = useAsync(fetchData, {
        onError: useCallback((_val : any) => {
            setPasswordResetMessage(isReset ? "Invalid link! \n\nMake sure that the link is not expired or already used (password reset links are one time use and expire after 24 hours)." : "Invalid link! \n\nMake sure that the link is not used already (as this is a one time use password setting link).");
            setInvalid(true);
        }, [isReset]),
        defaultPending: true,
    });
    useEffect(() => {
        if (token !== undefined && token !== null && token !== "")
            exec();
    }, [token]);//eslint-disable-line react-hooks/exhaustive-deps

    const {
        handleSubmit,
        getValues,
        errors,
        setError,
        control,
    } = useForm<ResetPasswordInput>();

    const onResetPassword = useCallback(
        (input: ResetPasswordInput) => {
            setPasswordResetMessage("");
            return UserService.resetPassword(token, input.password, input.confirmPassword);
        },
        [token]
    );

    const onPasswordReseted = useCallback(
        () => {
            setSuccess(true);
            setPasswordResetMessage(isReset ? "Your password has been successfully changed!" : "Your password has been successfully set!");
        }, [isReset]
    );

    const onPasswordResetError = useErrorHandler({
        onValidationError: setError as any,
        fallback: useCallback(() => {
            setPasswordResetMessage("An unknown error has occurred. Please try again.");
        }, []),
    });

    const { exec: resetPassword, pending } = useAsync(
        onResetPassword,
        {
            immediate: false,
            onComplete: onPasswordReseted,
            onError: onPasswordResetError,
            defaultPending: false,
        }
    );

    type MappedInput = [keyof ResetPasswordInput, string, any];

    return (<div className={notLoggedInClasses.root}>
        <BaseView loading={false}>
            <Box component="main" className={classes.container}>
                <div className={classes.paperFlex}>
                    <Typography component="h1" variant="h4">
                        {isReset ? "Reset Your Password" : "Set Your Password"}
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(resetPassword)}
                        noValidate
                    >
                        {([
                            ["password", "Password"],
                            [
                                "confirmPassword",
                                "Confirm Password",
                                {
                                    required: "Please confirm password",
                                    validate: {
                                        matchesPreviousPassword: (
                                            value: string
                                        ) => {
                                            const { password } = getValues();
                                            return (
                                                value === password ||
                                                "Passwords should match!"
                                            );
                                        },
                                    },
                                },
                            ],
                        ] as MappedInput[]).map(([name, label, rule]) => {
                            return (
                                <Controller
                                    key={name}
                                    name={name}
                                    control={control}
                                    defaultValue=""
                                    rules={rule || defaultRules}
                                    render={(p) => {
                                        return (
                                            <PasswordField
                                                {...p}
                                                variant="outlined"
                                                margin="normal"
                                                required={true}
                                                disabled={pending || confirmationPending || (passwordResetMessage !== "" && success) || invalid}
                                                fullWidth
                                                id={name}
                                                label={label}
                                                {...getErrorProps(errors, name)}
                                            />
                                        );
                                    }}
                                />
                            );
                        })}
                        {(passwordResetMessage !== "") && (<div>
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={2}
                                color={success ? "success.main" : "error.main"}
                            >
                                <Typography style={{whiteSpace:"pre-wrap"}}>{passwordResetMessage}</Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={2}
                            >
                                <Typography variant="body1" style={{textAlign: "center"}}>{isReset ? "back to " : "go to "}<a href={window.location.protocol+"//"+window.location.host}>{isReset ? "home page" : "login"}</a></Typography>
                            </Box>
                        </div>)}
                        <LoadingButton
                            className={classes.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={pending || confirmationPending}
                            disabled={(passwordResetMessage !== "" && success) || invalid}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                </div>
            </Box>
        </BaseView>
    </div>);
}