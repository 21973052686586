import { SharedStateInstance, useSharedState } from "src/components/SharedStateProvider";
import useAccessGroups, { UseAccessGroups } from "./useAccessGroups";
import useReload, { UseReload } from "./useReload";
import useAppVersions, { UseAppVersions } from "./useAppVersions";
import useTabletAppVersions, { UseTabletAppVersions } from "./useTabletAppVersions";
import useCompanies, { UseCompanies } from "./useCompanies";
import useDeprecatedAppVersions, { UseDeprecatedAppVersions } from "./useDeprecatedAppVersions";

export type WidgetStateInstance = 
    SharedStateInstance &
    UseAccessGroups &
    UseAppVersions &
    UseDeprecatedAppVersions &
    UseTabletAppVersions &
    UseCompanies &
    UseReload;

export default [useAccessGroups, useAppVersions, useDeprecatedAppVersions, useTabletAppVersions, useCompanies, useReload];

export const useWidgetState = useSharedState as () => WidgetStateInstance;
