import axios from "axios";
import { handleError } from "./Errors";
import { HubSpotCompany, HubSpotContact } from "./HubspotService";

export interface Quote {
    freeTemplates: Array<number>,
    freeTemplatesQty: number,
    allFreeAfterQty: number,
    quotationNumber: number,
    contracts: Array<Contract>,
}

export interface Contract {
    name: string,
    pdfCover: string,
    pdfPayment: string,
    pdfContract: string,
    pdfFinal:string,
    pdfBudgetary:string,
    plans: Array<Plan>,
    countryDropdown: Array<string>,
}

export interface Plan {
    id: number,
    title: string,
    currency: string,
    product: string,
    items: Array<{
        desc: string,
        qty: number,
        price: number,
    }>,
    plan: Array<number>,
    templates: Array<{
        id: number,
        name: string,
        price: number,
    }>,
    extras: Array<{
        id: number,
        name: string,
        price: number,
    }>,
    isMonthly: boolean,
}

interface QuoteDetails {
    quotationNumber: string,
    company: Omit<HubSpotCompany,"contacts"|"lastmodified">,
    contact: Omit<HubSpotContact,"companies"|"lastmodified">,
    expDays: number,
    comments: string,
    productsPlans: string[],
    lineItems: Array<{name:string,qty:number,price:number}>,
    currency: string,
}

export default class QuoteService {
    static async getContracts(): Promise<Quote> {
        try {
            const response = await axios.get("/api/quote",{timeout:15000});
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }


    static async getQuotationNumber(): Promise<number> {
        try {
            const response = await axios.get("/api/quote/number");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getPdf(name: string): Promise<Blob> {
        try {
            const response = await axios.get("/api/quote/pdf/"+name,{responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'}});
            return new Blob([response.data as any]) as Blob;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getSavingsPdf(): Promise<Blob> {
        try {
            const response = await axios.get("/api/quote/savings",{responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'}});
            return new Blob([response.data as any]) as Blob;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async postPdf(pdf: File, quote: QuoteDetails, sendEmail: boolean, syncHubSpotDeal=false,quoteType:string,closedate:string): Promise<{newCompany?: HubSpotCompany, newContact?: HubSpotContact}> {
        try {
            return (await axios.post("/api/quote/pdf", {
                pdf: Buffer.from(await pdf.arrayBuffer()).toString('base64'),
                quotationNumber: quote.quotationNumber,
                company: quote.company,
                contact: quote.contact,
                expDays: quote.expDays,
                comments: quote.comments,
                sendEmail,
                productsPlans: quote.productsPlans,
                lineItems: quote.lineItems,
                currency: quote.currency,
                syncHubSpotDeal,
                stage:quoteType,
                closedate:closedate
            },{timeout:60000})).data as any;
        } catch (err) {
            if ((err as any).response !== undefined)
                throw new Error((err as any).response.data.message);
            return handleError(err, false);
        }
    }

    static async getItemNumbers():Promise<Array<{lang:string,key:string,value:string}>>{
        try {
            const response = await axios.get("/api/quote/itemnumbers");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }

    static async getTranslations():Promise<Array<{lang:string,key:string,value:string}>>{
        try {
            const response = await axios.get("/api/quote/translations");
            return response.data as any;
        } catch (err) {
            return handleError(err, false);
        }
    }
}