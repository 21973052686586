import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() =>
    createStyles({
        readonlyWrapper: {
            cursor: "default",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "8px",
            paddingBottom: "8px",
            border: "solid 1px",
            borderRadius: "4px",
            borderColor: "#888888",
            position: "relative",
        },
        readonlyLabel: {
            fontSize: "12px",
            position: "absolute",
            color: "#666666",
            marginTop: "-0.5em",
            top: "-2px",
            left: "8px",
            backgroundColor: "#FFFFFF",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
        readonlyContent: {
            whiteSpace: "pre-wrap",
            marginTop: "3px",
            color: "#444444",
        },
        readonlyContentLink: {
            marginTop: "3px",
            cursor: "pointer",
            color: "#0000EE",
            textDecoration: "underline",
            '&:hover': {
                color: "#551A8B",
            },
        },
    })
);

export default function ReadonlyField(props : {value? : string | JSX.Element, label? : string, link?: string, isLink?: boolean, onClick?: React.MouseEventHandler<HTMLSpanElement>}) {
    const classes = useStyles();
    const link = props.link;
    const isLink = props.isLink ?? false;

    let str : any = props.value ?? "";
    if (str === "")
        str = <>&nbsp;</>;
    
    if (link)
        return (
            <div className={classes.readonlyWrapper}>
                <Typography className={classes.readonlyLabel}>{props.label}</Typography>
                <Link target="_blank" to={link!}><Typography className={classes.readonlyContentLink}>{str}</Typography></Link>
            </div>
        );

    return (
        <div className={classes.readonlyWrapper}>
            <Typography className={classes.readonlyLabel}>{props.label}</Typography>
            <Typography className={isLink ? classes.readonlyContentLink : classes.readonlyContent} onClick={props.onClick}>{str}</Typography>
        </div>
    );
}