import React, { useMemo, FormEvent, useCallback, useState } from "react";
import { FilterProps } from "react-table";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DeviceDto } from "../../../services/DeviceDataService";
import { useTranslator } from "../TranslationProvider";
import { Button, Typography, createStyles, useMediaQuery, makeStyles, Theme, Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { AccessGroup } from "src/services/ConfigurationsService";

const columnIds = ["Server online", "vpn_status"];
export function OnlineColumnFilter({
    column: { filterValue, setFilter },
}: FilterProps<DeviceDto>) {
    const checked : boolean = filterValue;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setFilter(
            newChecked
                ? {
                      columnIds,
                      value: "online",
                  }
                : undefined
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!!checked}
                    onChange={handleChange}
                    name="hide-offline"
                    size="small"
                />
            }
            label="Hide offline devices"
        />
    );
}

export function OnlineColumnFilter2({
    column: { filterValue, setFilter },
}: FilterProps<DeviceDto>) {
    const checked : boolean = filterValue;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setFilter(
            newChecked
                ? {
                      columnIds: ["connected"],
                      value: "True",
                  }
                : undefined
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!!checked}
                    onChange={handleChange}
                    name="hide-offline"
                    size="small"
                />
            }
            label="Hide offline devices"
        />
    );
}

export function AutocompleteColumnFilter({
    column: { filterValue = null, setFilter, preFilteredRows, id, render },
}: FilterProps<DeviceDto>) {

    const translator = useTranslator();
    const options_ = useMemo(() => {
        const options = new Set<any>();
        preFilteredRows.forEach((row) => {
            const value = (row.values[id]);
            if (value !== undefined) {
                options.add(value);
            }
        });
        return Array.from(options.values()).map((val) => ({
            value: val,
            display: translator.translate(id, val),
        }));
    }, [translator, preFilteredRows, id]);

    return (
        
            (filterValue?filterValue:[null]).map((filt:any,index:number)=>{

                return  <Autocomplete
                    value={filt}
                    options={options_}
                    clearOnBlur={false}
                    onChange={(_ev, opt) => {
                        setFilter((prevValue:any)=>{
                            if (filterValue){        
                                prevValue[index]=opt?.value;
                                return prevValue.filter((e:any)=>e).length===0?undefined:prevValue.filter((e:any)=>e)}
                            else 
                               return opt?.value?[opt?.value]:undefined
                        });
                    }}
                    key={index}
                    renderInput={(props) => {
                        return (
                            <TextField
                                margin="dense"
                                variant="outlined"
                                label={render("Header")}
                                {...props}
                            />
                        );
                    }}
                    getOptionLabel={(l) => {
                        if (l === null) {
                            return "";
                        }
                        return String( typeof l === "object"
                            ? l.display
                            : translator.translate(id, l));
                    }}
                    getOptionSelected={(opt, val) => {
                        return opt.value === val;
                    }}
                />
            
       })
     );
}

const useStyles = makeStyles((theme) =>
    createStyles({
        popper: {
            boxShadow: "0 3px 12px rgba(27,31,35,.15)",
            borderRadius: 3,
            width: 550,
            zIndex: 50,
            fontSize: 13,
            color: "#586069",
            backgroundColor: "#f6f8fa",
        },
        popperSmall: {
            boxShadow: "0 3px 12px rgba(27,31,35,.15)",
            borderRadius: 3,
            maxwidth: 550,
            zIndex: 50,
            fontSize: 13,
            color: "#586069",
            backgroundColor: "#f6f8fa",
        },
        header: {
            display: "flex",
            borderBottom: "1px solid #e1e4e8",
            padding: "8px 10px",
            "& :first-child": {
                fontWeight: 600,
                fontSize: "14px",
            },
            "& :last-child": {
                marginLeft: "auto",
                fontWeight: 600,
            },
            alignItems: "center",
        },
        paper: {
            position: "relative",
            boxShadow: "none",
            margin: 0,
            color: "#586069",
            fontSize: 13,
            maxHeight: "40vh",
            overflowY: "auto",
            "& ul": {
                paddingTop: 2,
            },
            fontFamily: theme.typography.fontFamily,
            paddingBottom: theme.spacing(1),
        },
        hidden: {
            display: "none",
        },
        cell: {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(2),
            maxWidth: 400
        },
    })
);

export function AccessGroupsColumnFilter({
    column
}: FilterProps<DeviceDto>, accessGroups: AccessGroup[] | undefined) {
    const classes = useStyles({});
    const [, forceRender] = useState(0);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(
        undefined
    );
    const [filterOpen, setFilterOpen] = useState(false);

    const handleFilterClick = useCallback(
        (event: any) => {
            setAnchorEl(event.currentTarget);
            setFilterOpen(true);
        },
        [setAnchorEl, setFilterOpen]
    );

    const onClose = useCallback(() => {
        setFilterOpen(false);
        setAnchorEl(undefined);
    }, []);

    const smallDisplay = !useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onClose();
        },
        [onClose]
    );
    const resetFilters = useCallback(() => {
        column.setFilter(undefined);
        forceRender((s) => (s+1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column.setFilter]);

   const createOrFilter=useCallback(()=>{
    column.setFilter((f: any)=>{
        if (f.length!==0)
            f.push([])
        return f
    })
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[column.setFilter])

   console.log("fs",column.filterValue)
        
   const displayedFiltersComponent=() => {
    
    return (
        <Grid item key={`${column.id}`}  xs={12} style={{display:"flex",justifyContent:"space-between"}}>
            <div style={{flexGrow:1,marginBottom:"10px"}}>
            {
                (column.filterValue?column.filterValue:[[]]).map((filt:any,index:number)=>{

                    return  <Autocomplete
                        multiple
                        filterSelectedOptions
                        value={filt}
                        options={accessGroups?.map(ag => ag.Id)??[]}
                        clearOnBlur={false}
                        onChange={(_ev, opt) => {
                            column.setFilter((prevValue:any)=>{
                                if (column.filterValue){        
                                    prevValue[index]=opt;
                                    return prevValue.filter((e:Array<number>)=>e.length !== 0).length===0?undefined:prevValue.filter((e:Array<number>)=>e.length !== 0)}
                                else 
                                   return opt.length?[opt]:undefined
                            });
                        }}
                        key={index}
                        renderInput={(props) => {
                            return (
                                <TextField
                                    margin="dense"
                                    variant="outlined"
                                    label={column.render("Header")}
                                    {...props}
                                />
                            );
                        }}
                        getOptionLabel={(l) => {
                            const ag = accessGroups?.find(ag=>ag.Id===l);
                            return ag?.Name+" (" + ag?.AccessGroupType + ")";
                        }}
                        getOptionSelected={(opt, val) => {
                            return opt === val;
                        }}
                    />
                })
            }
            </div>
            <div>
            {Array.isArray( column?.filterValue) && column?.filterValue.length > 0 && <Button onClick={()=>createOrFilter()}
                variant="outlined"
                disabled={(column?.filterValue?.findIndex((fv:Array<number>)=>fv.length === 0)!==-1 )}
                size="small"
                color="primary"
                style={{marginTop:"8px", marginBottom:"4px",marginLeft:"5px",height:"40px"}}>Multiselect</Button>
            }               
            </div>
        </Grid>
    )};
    
    return (
        <>
            
              
            <Button
                onClick={handleFilterClick}
                variant="outlined"
                size="small"
            >{column.render("Header")}</Button>
            <Popover
                anchorEl={anchorEl}
                id={"popover-accessgroups"}
                onClose={onClose}
                open={filterOpen}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div className={smallDisplay ? classes.popperSmall : classes.popper}>
                    <form onSubmit={onSubmit}>
                        <div className={classes.header}>
                            <Typography variant="h6" component="p">
                                {column.render("Header")}
                            </Typography>
                            <Button
                                color="primary"
                                size="small"
                                onClick={resetFilters}
                            >
                                Reset
                            </Button>
                        </div>
                        <Paper className={classes.paper}>
                            <Grid container>
                                {<Box style={{width:"100%",padding:'20px'}}>

                                        {displayedFiltersComponent()}
                                        
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </form>
                </div>
            </Popover>
        </>
    );
}

