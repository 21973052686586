/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import { HeaderProps } from "react-table";
import { ResolvedIcon } from "src/views/Devices/Icons";
import { useTranslator } from "src/views/Devices/TranslationProvider";
import { CellRendererProps } from "src/components/DataGrid";
import { DeviceDto } from "src/services/DeviceDataService";
import { TimeFormats } from "src/utils/constants";
import moment from "moment";
import useSessionStorage from "src/hooks/useSessionStorage";
import { timezone } from "src/App";

export const SUPPORT_SELECTION_PREFIX = "tmr/overview-table/support-issues/selection-id=";

// eslint-disable-next-line @typescript-eslint/ban-types
export type IdCellProps<T extends {}> = CellRendererProps<T>;

export function HeaderCell<T extends {}>(props: HeaderProps<T>) {
    const {
        column: { id, name },
    } = props;

    const translator = useTranslator();
    const value = id === "issueId" ? name : translator.translateKey(id);
    return <>{value}</>;
}

const useIdCellStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    value: {
        marginLeft: 4,
    },
    icon: {
        marginBottom: 1.5,
    },
}));

export function SelectorIdCell<T extends {}>(props: IdCellProps<T>) {
    const {
        row: { values },
        value,
    } = props;

    const classes = useIdCellStyles();
    const [isSelected, setSelected] = useSessionStorage(SUPPORT_SELECTION_PREFIX+value, false);

    return (
        <div className={classes.root} data-no-left-pad="true">
            <Checkbox
                checked={isSelected}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelected(!isSelected);
                }}
            />
            <ResolvedIcon
                className={classes.icon}
                fontSize="small"
                values={values}
            />
            <div className={classes.value}>{value}</div>
        </div>
    );
}

export function IdCell<T extends {}>(props: IdCellProps<T>) {
    const {
        row: { values },
        value,
    } = props;

    const classes = useIdCellStyles();

    return (
        <div className={classes.root}>
            <ResolvedIcon
                className={classes.icon}
                fontSize="small"
                values={values}
            />
            <div className={classes.value}>{value}</div>
        </div>
    );
}

export function DateCell(props: CellRendererProps<DeviceDto>) {
    const {
        value,
    } = props;

    const dateString = moment.unix(value).utcOffset(timezone).format(TimeFormats.Presentation2);

    return <>{dateString}</>;
}

export function CategoryCell(props: CellRendererProps<DeviceDto>) {
    const {
        value,
    } = props;

    return <div style={{color: value==="Missing"?"red":undefined}}>{value}</div>;
}

export function TranslatedCell(props: CellRendererProps<DeviceDto>) {
    const {
        value,
        column: { id },
    } = props;
    const translator = useTranslator();
    const translatedResult = translator.translate(id, value);
    return <>{translatedResult}</>;
}