import React from "react";
import { useParams } from "react-router-dom";
import { deviceTypeIdToString, deviceTypeStringToId } from "src/utils/common";
import Overview from "./DeviceOverview";

interface OverviewParams {
    device: "tablets" | "robots" | string;
}

export default function OverviewIndex() {
    const { device } = useParams<OverviewParams>();

    const deviceType = deviceTypeStringToId(device);
    const deviceName = deviceTypeIdToString(deviceType);

    return (
        <Overview
            key={deviceType}
            deviceType={deviceType}
            deviceName={deviceName}
        />
    );
}