/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useEffect, useMemo, useLayoutEffect, useCallback, useRef } from "react";
import { VariableSizeGrid, GridOnScrollProps } from "react-window";
import { useTable, useFilters, useColumnOrder, useResizeColumns, useGlobalFilter, useSortBy, Column, TableInstance, ColumnInstance, Row, Renderer, Hooks, IdType, SortByFn, defaultOrderByFn } from "react-table";
import GridCell, { GridCellData } from "./GridCell";
import GridHeaderCell, { GridHeaderData } from "./GridHeaderCell";
import Pagination from "./Pagination";
import HoveredRowProvider, { useHoveredRow } from "./HoveredRowProvider";
import TableToolbar, { TableToolbarProps } from "../FilteredTable/TableToolbar";
import Chipbar from "../FilteredTable/Chipbar";
import filterTypes from "../FilteredTable/filterTypes";
import "./DataGrid.scss";
import classNames from "src/utils/classNames";
import { useLocalStorage, useDebounced, useResizeObserver } from "src/hooks";
import getAbsoluteHeight from "src/utils/getAbsoluteHeight";
import { isChrome } from "src/utils/browserDetect";
import { useTranslator } from "src/views/Devices/TranslationProvider";
import { useHistory, useLocation } from "react-router-dom";

const hooks = [
    useColumnOrder,
    useResizeColumns,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useSyncScroll,
];

export interface CellRendererProps<T extends {}> {
    value: any;
    row: Row<T>;
    column: ColumnInstance<T>;
    instance: TableInstance<T>;
}

export type CellRenderer<T extends {}> = Renderer<CellRendererProps<T>>;

export type GridColumn<T extends {}> = Omit<
    Column<T>,
    "columns" | "Cell" | "width"
> & {
    Cell: CellRenderer<T>;
    width?: number;
};

export interface DataGridProps<T extends {}> {
    name?: string;
    data: T[];
    columns: GridColumn<T>[];
    fixedColumn: Omit<GridColumn<T>, "width"> & { width: number };
    onCellClick?: (original: T, value?: any) => void;
    limitClickToFixed?: boolean;
    toolbarProps?: Pick<
        TableToolbarProps<T>,
        "LeftAdornment" | "RightAdornment" | "hideColumnsProps"
    >;
    globalFilter?: (
        rows: Row<T>[],
        columnIds: IdType<T>[],
        filterValue: any
    ) => Row<T>[];
    height: number;
    width: number;
    setCurrentGrid?: React.Dispatch<any>;
}

const defaultColumn = {
    width: 200,
    disableFilters: true,
};

function DataGrid<T extends {}>(props: DataGridProps<T>) {
    const {
        name,
        data,
        columns,
        fixedColumn,
        height,
        width,
        onCellClick,
        limitClickToFixed = false,
        toolbarProps,
        globalFilter,
        setCurrentGrid,
    } = props;

   
    const translate=useTranslator();
    const location=useLocation()
    const history=useHistory()


    const [initialState, setInitialState] = useLocalStorage(name, name==="tmr/overview-table/support-issues-readonly" ? JSON.parse(`{"sortBy":[],"filters":[],"columnOrder":["issueId","robot_id","tablet_id","customer_id","assignee","category","requester","created_at","subject"],"hiddenColumns":["isOpen","lastUpdate","tabletId","robotModel","robotId","closedBy","closedBy","openedByCustomer","openedBy","contactEmail","contactPhone","assignedToCustomer","customer","state","dealer","dealerName","requester_phone","requester_email","assignee_phone","assignee_email","status","robot_model","dealer_id","updated_at","organization"],"columnSizes":{"robot_id":86.00000000000001,"tablet_id":85.00000000000001,"customer_id":227,"assignee":160,"category":127,"requester":178,"created_at":128,"subject":467}}`) : {});

    
    if ((initialState as {[key: string]: {[id: string]: number}})["columnSizes"] !== undefined)
        for (const column of columns)
            column.width = (initialState as {[key: string]: {[id: string]: number}})["columnSizes"][column.id as string] ?? 200;

      const orderByFn =  useCallback( (sortRows:Row<T>[],sortFns: Array<SortByFn<D>>, directions: boolean[])=>{
           
            let avalue,bvalue;
            const sortField=initialState.sortBy[0]?.id ?? 'device_id';
            const asc=directions[0];
            sortFns=[
                (a,b)=>{
                    avalue=a.values[sortField];
                    bvalue=b.values[sortField];
                    if (!bvalue)
                    return asc?-1:1
                    if (!avalue)
                    return asc?1:-1

                    if (translate.enabled){
                        avalue=translate.translate(sortField, avalue);
                        bvalue=translate.translate(sortField, bvalue);
                    }
                        avalue=isNaN(Number(avalue))?avalue:Number(avalue);
                        bvalue=isNaN(Number(bvalue))?bvalue:Number(bvalue);
                    
                    if (avalue>bvalue){
                        return 1
                    }else if (bvalue>avalue){
                        return -1
                    }else return 0
                },
                ...sortFns
            ];
            

        
        return defaultOrderByFn(sortRows,sortFns,directions)
    },[initialState.sortBy,translate])
    const filterObj=location.search.replace("?filters=",'')===''?{}:JSON.parse(decodeURIComponent( location.search.replace("?filters=",'')))

    let initState=initialState;
    if (location.search.replace("?filters=",'')===''){
        if (initState.filters){
            initState={
                ...initialState,filters: initialState.filters.filter((filt:any)=>Array.isArray(filt.value))
            }
        }
    }
    else{
        initState={...initialState,
            filters:Object.keys(filterObj).map((key:any)=>{return {"id":key,"value":filterObj[key]}})
            .filter((filt:any)=>
            {
            return columns.find((c:any)=>c.id===filt.id)
        })}
    }
    const instance = useTable(
        {
            data,
            columns: columns as any,
            defaultColumn,
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            autoResetSortBy: false,
            initialState: initState,
            filterTypes,
            globalFilter,
            orderByFn: orderByFn
            
        },
         ...hooks,
         
        (h) => {
            h.columns.push((c) => {
                return [
                    {
                        ...(fixedColumn as any),
                        disableResizing: true,
                        hideable: false,
                        orderable: false,
                    },
                    ...c,
                ];
            });
            h.columnsDeps.push((deps) => [fixedColumn, ...deps]);
        }
    );

    const { rows, visibleColumns, totalColumnsWidth, state } = instance;
    const {
        sortBy: debouncedSortBy,
        filters: debouncedFilters,
        globalFilter: debouncedGlobalFilter,
        columnOrder: debouncedColumnOrder,
        hiddenColumns: debouncedHiddenColumns,
    } = useDebounced(state, 500);
    
    const { fixedHeader, dataHeaders } = useMemo(() => {
        const [fixed, ...d] = visibleColumns;
        return {
            fixedHeader: fixed,
            dataHeaders: d,
        };
    }, [visibleColumns]);

    useEffect(() => {
        const columnSizes : {[id: string]: number} = {};
        for (const dataHeader of dataHeaders)
            columnSizes[dataHeader.id] = Number(dataHeader.width);
        setInitialState({
            sortBy: debouncedSortBy,
            filters:  debouncedFilters,
            globalFilter: debouncedGlobalFilter,
            columnOrder: debouncedColumnOrder,
            hiddenColumns: debouncedHiddenColumns,
            columnSizes,

        });
        

        const filter=debouncedFilters.filter((df)=>Array.isArray(df.value)).length===0?'' :"{"+debouncedFilters.filter((df)=>Array.isArray(df.value)).map((df)=>{
            return `"${df.id}":[${df.value.filter((dfv:string | number[])=>typeof dfv === "string" ? (dfv && dfv!=='') :(Array.isArray(dfv) && dfv.length !== 0)).map((dfv:string | number[])=>typeof dfv === "string" ? `"${dfv}"` : `[${dfv}]`)}]`
        }).join(",")+'}'

        try {
            JSON.parse(filter)
            history.replace({pathname:location.pathname,search:debouncedFilters.filter((df)=>Array.isArray(df.value)).length>0? "?filters="+filter:""})
        } catch (error) {
            history.replace({pathname:location.pathname,search:""})

        }
          
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        debouncedSortBy,
        debouncedFilters,
        debouncedGlobalFilter,
        debouncedColumnOrder,
        debouncedHiddenColumns,
        setInitialState,
        dataHeaders,
        state,
    ]);

    useEffect(() => {
        if (setCurrentGrid !== undefined)
            setCurrentGrid({columns: visibleColumns, filteredData : rows});
    });

    const {
        headerRef,
        fixedRef,
        fixedHeaderRef,
        scrollbarWidth,
        scrollbarHeight,
        outerDataRef,
        dataRef,
        onBodyScroll,
    } = instance as any;

    const lastCellWidth = Math.max(
        0,
        width - totalColumnsWidth - scrollbarWidth
    );

    const getFixedWidth = useCallback(() => {
        return fixedHeader.width as number;
    }, [fixedHeader]);

    const getHeaderWidth = useCallback(
        (index: number) => {
            if (index === dataHeaders.length) {
                return scrollbarWidth;
            }
            return dataHeaders[index].width as number;
        },
        [dataHeaders, scrollbarWidth]
    );

    const getCellWidth = useCallback(
        (index: number) => {
            if (index === dataHeaders.length) {
                return lastCellWidth;
            }
            return dataHeaders[index].width as number;
        },
        [dataHeaders, lastCellWidth]
    );

    const fixedHeaderData: GridHeaderData<T> = useMemo(
        () => ({
            headers: [fixedHeader],
            fixed: dataHeaders.length !== 0,
            instance,
        }),
        [fixedHeader, dataHeaders, instance]
    );

    const headerData: GridHeaderData<T> = useMemo(
        () => ({
            headers: dataHeaders,
            fixed: false,
            instance,
        }),
        [dataHeaders, instance]
    );

    const fixedCellData: GridCellData<T> = useMemo(
        () => ({
            rows,
            headers: [fixedHeader],
            fixed: dataHeaders.length !== 0 || rows.length === 0,
            onCellClick,
        }),
        [rows, fixedHeader, dataHeaders, onCellClick]
    );

    const cellData: GridCellData<T> = useMemo(
        () => ({
            rows,
            headers: dataHeaders,
            fixed: false,
            onCellClick: limitClickToFixed ? undefined : onCellClick,
        }),
        [rows, dataHeaders, onCellClick, limitClickToFixed]
    );

    const { totalHeight, toolbarRef, chipbarRef } = useTotalHeight(height);

    const hideDataGridXScrollbar = lastCellWidth > 0;
    const dataGridStyles = useMemo(() => {
        if (hideDataGridXScrollbar) {
            return {
                overflowX: "hidden" as const,
            };
        }
        return {
            overflowX: "auto" as const,
        };
    }, [hideDataGridXScrollbar]);

    const estimatedColumnWidth = dataHeaders.length
        ? (totalColumnsWidth - getFixedWidth()) / dataHeaders.length
        : 0;
    return (
        <>
            <TableToolbar
                forwardRef={toolbarRef}
                instance={instance}
                {...(toolbarProps || {})}
            />
            <Chipbar forwardRef={chipbarRef} instance={instance} />
            <div className="dg-wrapper top">
                <VariableSizeGrid
                    width={getFixedWidth()}
                    height={getRowHeight()}
                    rowCount={1}
                    columnCount={1}
                    rowHeight={getRowHeight}
                    columnWidth={getFixedWidth}
                    itemData={fixedHeaderData}
                    className="dg-grid"
                    ref={fixedHeaderRef}
                >
                    {GridHeaderCell}
                </VariableSizeGrid>
                <VariableSizeGrid
                    width={width - getFixedWidth()}
                    height={getRowHeight()}
                    rowCount={1}
                    columnCount={dataHeaders.length + 1}
                    rowHeight={getRowHeight}
                    columnWidth={getHeaderWidth}
                    itemData={headerData}
                    className="dg-grid header"
                    ref={headerRef}
                    estimatedColumnWidth={estimatedColumnWidth}
                    estimatedRowHeight={getRowHeight()}
                >
                    {GridHeaderCell}
                </VariableSizeGrid>
            </div>
            <HoveredRowProvider>
                <GridDataWrapper className="dg-wrapper bottom">
                    <VariableSizeGrid
                        width={getFixedWidth()}
                        height={totalHeight - getRowHeight() - scrollbarHeight}
                        rowCount={rows.length || 1}
                        columnCount={1}
                        rowHeight={getRowHeight}
                        columnWidth={getFixedWidth}
                        itemData={fixedCellData}
                        className={classNames(
                            "dg-grid fixed",
                            rows.length === 0 && "visible-overflow"
                        )}
                        ref={fixedRef}
                        outerElementType={("div")}
                    >
                        {GridCell}
                    </VariableSizeGrid>
                    <VariableSizeGrid
                        width={width - getFixedWidth()}
                        height={totalHeight - getRowHeight()}
                        rowCount={rows.length || 1}
                        columnCount={dataHeaders.length + 1}
                        rowHeight={getRowHeight}
                        columnWidth={getCellWidth}
                        itemData={cellData}
                        className="dg-scroll dg-grid body"
                        ref={dataRef}
                        outerRef={outerDataRef}
                        onScroll={onBodyScroll}
                        style={dataGridStyles}
                        estimatedColumnWidth={estimatedColumnWidth}
                        estimatedRowHeight={getRowHeight()}
                        outerElementType={GridOuterComponent}
                    >
                        {GridCell}
                    </VariableSizeGrid>
                </GridDataWrapper>
            </HoveredRowProvider>
            <Pagination instance={instance} />
        </>
    );
}

function getRowHeight() {
    return 45;
}

function useSyncScroll<T extends {}>(h: Hooks<T>) {
    h.useInstance.push(useSyncScrollInstance);
}

function useSyncScrollInstance<T extends {}>(instance: TableInstance<T>) {
    const headerRef = useRef<VariableSizeGrid>();
    const fixedRef = useRef<VariableSizeGrid>();
    const fixedHeaderRef = useRef<VariableSizeGrid>();

    const {
        width: scrollbarWidth,
        height: scrollbarHeight,
        ref: outerDataRef,
    } = useScrollbarSize();
    const dataRef = useRef<VariableSizeGrid>();

    const onBodyScroll = useCallback(
        ({
            scrollLeft,
            scrollTop,
            scrollUpdateWasRequested,
        }: GridOnScrollProps) => {
            if (!scrollUpdateWasRequested) {
                headerRef.current?.scrollTo({ scrollLeft, scrollTop: 0 });
                fixedRef.current?.scrollTo({ scrollLeft: 0, scrollTop });
            }
        },
        []
    );

    const {
        state: { columnResizing, columnOrder, filters, globalFilter, sortBy },
        visibleColumns,
    } = instance;

    useEffect(() => {
        headerRef.current?.resetAfterColumnIndex(0);
        dataRef.current?.resetAfterColumnIndex(0);
    }, [columnResizing, columnOrder, visibleColumns]);

    useEffect(() => {
        headerRef.current?.resetAfterColumnIndex(0);
        fixedHeaderRef.current?.resetAfterColumnIndex(0);
    }, [sortBy]);

    useLayoutEffect(() => {
        dataRef.current?.scrollToItem({ rowIndex: 0 });
        fixedRef.current?.scrollToItem({ rowIndex: 0 });
    }, [filters, globalFilter, sortBy]);

    Object.assign(instance, {
        headerRef,
        fixedRef,
        fixedHeaderRef,
        scrollbarWidth,
        scrollbarHeight,
        outerDataRef,
        dataRef,
        onBodyScroll,
    });
}

function useScrollbarSize() {
    const refObj = React.useRef<HTMLDivElement>();
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const div = refObj.current;
        if (div) {
            const scrollbarWidth = div.offsetWidth - div.clientWidth;
            const scrollbarHeight = div.offsetHeight - div.clientHeight;
            setWidth(scrollbarWidth);
            setHeight(scrollbarHeight);
        }
    });

    return { ref: refObj, width, height };
}

function useTotalHeight(height: number) {
    const [state, setState] = useState({ max: height, toolbar: 0, chipbar: 0 });

    const toolbarRef = React.useRef<HTMLDivElement>(null);
    const chipbarRef = React.useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        setState((s) => (s.max === height ? s : { ...s, max: height }));
    }, [height]);

    useResizeObserver({
        ref: toolbarRef,
        callback: () => {
            const toolbarHeight = getAbsoluteHeight(toolbarRef.current);
            setState((s) =>
                s.toolbar === toolbarHeight
                    ? s
                    : { ...s, toolbar: toolbarHeight }
            );
        },
    });

    useResizeObserver({
        ref: chipbarRef,
        callback: () => {
            const chipbarHeight = getAbsoluteHeight(chipbarRef.current);
            setState((s) =>
                s.chipbar === chipbarHeight
                    ? s
                    : { ...s, chipbar: chipbarHeight }
            );
        },
    });

    const { max, toolbar, chipbar } = state;

    return {
        // 40 accounts for the pagination bar
        totalHeight: Math.max(max - toolbar - chipbar - 40, 300),
        toolbarRef,
        chipbarRef,
    };
}

const GridOuterComponent = React.forwardRef(function GridOuterComponent(
    props: React.PropsWithChildren<any>,
    ref
) {
    return <div ref={ref} {...props} data-is-chrome={isChrome} />;
});

function GridDataWrapper(
    props: React.PropsWithChildren<{ className: string }>
) {
    const { setRowIndex } = useHoveredRow();
    return (
        <div
            className={props.className}
            onMouseLeave={() => setRowIndex(() => null)}
        >
            {props.children}
        </div>
    );
}

export default (React.memo(DataGrid) as unknown) as typeof DataGrid;
