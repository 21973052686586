import React from "react";
import { makeStyles } from "@material-ui/core";
import SortIcon from "src/components/SortIcon";
import { TableSortByToggleProps } from "react-table";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(0.5),
        display: "inline-flex",
        verticalAlign: "sub",
    },
}));

export interface ColumnSorterProps {
    getSortByToggleProps: (
        props?: TableSortByToggleProps
    ) => TableSortByToggleProps;
    isSorted: boolean;
    isSortedDesc?: boolean;
}

export default React.memo(function ColumnSorter(props: ColumnSorterProps) {
    const { getSortByToggleProps, isSorted, isSortedDesc } = props;
    const classes = useStyles();
    let sortDirection = undefined as "desc"|"asc"|undefined;
    if (isSorted)
        sortDirection = isSortedDesc ? "desc" : "asc";
    return (
        <div className={classes.root}>
            <SortIcon
                {...getSortByToggleProps()}
                title="Sort column"
                sortDirection={sortDirection}
            />
        </div>
    );
});
