import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function RobotIcon() {
    return (
        <SvgIcon>
            <g
                fill="#f9f9f9"
                stroke="#221f20"
                strokeMiterlimit="10"
                strokeWidth="1.11px"
            >
                <path d="m13.7 9.29 6.36 0.0334a0.561 0.561 0 0 1 0.554 0.491l0.769 5.99-6.29-2-1.51-3.22-0.145-0.996a0.264 0.264 0 0 1 0.26-0.301z" />
                <path d="m15 13-0.896 4.26a0.32 0.32 0 0 1-0.294 0.338h-12.3v-0.186h-0.0334l-0.907-5.15a0.334 0.334 0 0 1 0.23-0.398l12.2-2.79 1.92 4.11" />
                <circle cx="18.6" cy="16.9" r="4.86" />
                <circle cx="2.45" cy="19.9" r="1.49" />
            </g>
            <g
                fill="none"
                stroke="#221f20"
                strokeMiterlimit="10"
                strokeWidth="1.11px"
            >
                <line x1="18" x2="18" y1="8.26" y2="8.21" />
                <line x1="18.1" x2="18.2" y1="3.42" y2="9.35" />
                <ellipse cx="18.1" cy="2.76" rx="3.11" ry=".569" />
            </g>
        </SvgIcon>
    );
}
