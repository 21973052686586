import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

//import OrangeButton from "../Components/OrangeButton.jsx"
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

export default function EditAccessGroupPopup(props) {
    const [open, setOpen] = React.useState(false);

    const initialState = {
        name: "",
        shortName: "",
        inheritable: false,
        type: "",
        types: [],
        createNewType: false,
        newTypeName: "",
        newTypeDescription: ""
    };
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const updateAccessGroupTypes = () => {
        axios("/api/dbtool/accessgrouptypes")
            .then(response => response.data[0])
            .then(state => {
                let newState = {
                    name: props.accessGroup.Name,
                    shortName: props.accessGroup.ShortName,
                    inheritable: !!props.accessGroup.Inheritable,
                    type: props.accessGroup.Type,
                    types: state
                }
                setState(newState);
            });
    };

    const handleClickOpen = () => {

        let newState = {
            name: props.accessGroup.Name,
            shortName: props.accessGroup.ShortName,
            inheritable: !!props.accessGroup.Inheritable,
            type: props.accessGroup.Type
        }
        setState(newState);

        // Update selects
        updateAccessGroupTypes();

        // open the popup
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let tid = state.types.filter(t => t.Key === state.type)[0].Id;

        let data = {
            id: props.accessGroup.Id,
            n: state.name,
            s: state.shortName,
            i: state.inheritable,
            t: tid
        }

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: (data)
        };
        axios("/api/dbtool/accessgroup", requestOptions)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    if (props.whenSuccess)
                        props.whenSuccess();
                    handleClose();
                }
                else
                    alert("Access group " + state.name + " allready exists");
            });
    };

    return (

        <>
            <IconButton component="span" onClick={handleClickOpen} >
                <Edit />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#3f51b5", color: "white" }}>Edit access group</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Name"
                                value={state.name}
                                onChange={(e) => setState({ name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Short name"
                                value={state.shortName}
                                onChange={(e) => setState({ shortName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={state.inheritable}
                                    onChange={(e) => setState({ inheritable: e.target.checked })}
                                    color="primary" />}
                                label="Inheritable"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="type-select-lbl">Type</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                labelId="type-select-lbl"
                                id="type-select"
                                value={state.type ? state.type : ""}
                                onChange={(e) => setState({ type: e.target.value })}
                            >
                                {(state.types.map(t => (
                                    <MenuItem key={t.Id} value={t.Key}>{t.Key}</MenuItem>
                                )))}
                            </Select>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        SAVE
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
