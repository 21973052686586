import {
    ToastMessage,
    ConnectionState,
    AppAction,
    DISPLAY_TOAST,
    CONTROL_TOAST,
    SET_CONNECTION_STATE,
    LOAD_CUSTOMERS_DTO,
    LOADING_CUSTOMERS_DTO,
    SET_CUSTOMERS_DTO,
    CREATE_CUSTOMER,
    DELETE_CUSTOMER,
    DialogPrompt,
    CONTROL_DIALOG,
    SET_OPERATIONS,
    SET_ROBOTS,
    SET_TABLETS,
    SET_USERS,
    SET_USER,
    UPDATE_USER,
    REMOVE_USER,
    ADD_USER,
    SET_QUOTES,
    SET_TIMEZONE,
    SET_CONTACTS,
    SET_COMPANIES,
    SET_BASESTATIONS,
    SET_EXCLUDE_DEPRECATED,
    SET_GOOGLE_MAPS_TOKEN,
    SET_ACCESS_FIELDS,
} from "./types";
import { CustomersDto, Customer, User, AccessField } from "../../services/UserService";
import { Operation } from "src/services/AdministrationService";
import { Quote } from "src/services/QuoteService";
import { BaseStation, Robot, Tablet } from "src/services/DeviceDataService";
import { HubSpotCompany, HubSpotContact } from "src/services/HubspotService";

export const setTimezone = (
    data: {timezone: number}
): AppAction => ({
    type: SET_TIMEZONE,
    payload: data,
});

export const setExcludeDeprecated = (
    data: {excludeDeprecated: boolean}
): AppAction => ({
    type: SET_EXCLUDE_DEPRECATED,
    payload: data,
});

export const updateUser = (
    data: {username: string, user: User}
): AppAction => ({
    type: UPDATE_USER,
    payload: data,
});

export const removeUser = (
    user: User
): AppAction => ({
    type: REMOVE_USER,
    payload: user,
});

export const addUser = (
    user: User
): AppAction => ({
    type: ADD_USER,
    payload: user,
});

export const setUsers = (
    users: User[]
): AppAction => ({
    type: SET_USERS,
    payload: users,
});

export const setUser = (
    user: User
): AppAction => ({
    type: SET_USER,
    payload: user,
});

export const setRobots = (
    robots: Robot[]
): AppAction => ({
    type: SET_ROBOTS,
    payload: robots,
});

export const setTablets = (
    tablets: Tablet[]
): AppAction => ({
    type: SET_TABLETS,
    payload: tablets,
});

export const setBaseStations = (
    basestations: BaseStation[]
): AppAction => ({
    type: SET_BASESTATIONS,
    payload: basestations,
});

export const setOperations = (
    operations: Operation[]
): AppAction => ({
    type: SET_OPERATIONS,
    payload: operations,
});

export const setQuotes = (
    quotes: Quote
): AppAction => ({
    type: SET_QUOTES,
    payload: quotes,
});

export const setContacts = (
    contacts: HubSpotContact[]
): AppAction => ({
    type: SET_CONTACTS,
    payload: contacts,
});

export const setCompanies = (
    companies: HubSpotCompany[]
): AppAction => ({
    type: SET_COMPANIES,
    payload: companies,
});

export const setAccessFields = (
    accessFields: AccessField[]
): AppAction => ({
    type: SET_ACCESS_FIELDS,
    payload: accessFields,
});

export const setGoogleMapsToken=(
    token: string
):AppAction=>({
    type: SET_GOOGLE_MAPS_TOKEN,
    payload: token
})

export const displayDialog = (active: boolean, prompt? : DialogPrompt, positiveButtonAction : () => void = () => {/*noop*/}, negativeButtonAction : () => void = () => {/*noop*/}): AppAction => {
    return {
        type: CONTROL_DIALOG,
        payload: {
            active,
            prompt,
            positiveButtonAction,
            negativeButtonAction,
        },
    };
};

export const displayToast = (toast: ToastMessage): AppAction => {
    return {
        type: DISPLAY_TOAST,
        payload: {
            ...toast,
            severity: toast.severity || "default",
        },
    };
};

export const controlToast = (
    open: boolean,
    toast?: ToastMessage
): AppAction => {
    return {
        type: CONTROL_TOAST,
        payload: {
            open,
            message: toast,
        },
    };
};

export const setConnectionState = (
    connectionState: ConnectionState
): AppAction => ({
    type: SET_CONNECTION_STATE,
    payload: {
        connectionState,
    },
});

export const loadCustomersDto = (): AppAction => ({ type: LOAD_CUSTOMERS_DTO });
export const loadingCustomersDto = (): AppAction => {
    return {
        type: LOADING_CUSTOMERS_DTO,
    };
};
export const setCustomersDto = (
    customersDto: CustomersDto,
    error?: any
): AppAction => ({
    type: SET_CUSTOMERS_DTO,
    payload: {
        dto: customersDto,
        error,
    },
});

export const createCustomer = (payload: Customer) => ({
    type: CREATE_CUSTOMER,
    payload,
});

export const deleteCustomer = (payload: {id: number;}) => ({
    type: DELETE_CUSTOMER,
    payload,
});