/* eslint-disable @typescript-eslint/ban-types */
import React, { useMemo, useEffect, useCallback } from "react";
import { TableInstance, Hooks, useGetLatest } from "react-table";
import BasicTable, { BasicTableProps } from "../BasicTable";
import TableToolbar, { TableToolbarProps } from "./TableToolbar";
import Chipbar from "./Chipbar";
import { SearchColumnFilter } from "./ColumnFilters";
import defaultFilterTypes from "./filterTypes";
import { useDebounced } from "src/hooks";
import Box from "@material-ui/core/Box";
import RefreshButton from "../RefreshButton";
import ExportButton, { ExportButtonProps } from "../ExportButton";

export interface FilteredTableProps<T extends {}>
    extends Omit<BasicTableProps<T>, "Toolbar"> {
    innerRef?: any,
    h?: number | string,
    autoRefresh?: boolean,
    toolbarProps?: Partial<Omit<TableToolbarProps<T>, "instance">>;
    exportData?: (state: any) => Promise<void>;
    exportButtonProps?: Partial<ExportButtonProps>;
    // Only works if controlled
    loading?: boolean;
}

export default function FilteredTable<T extends {}>({
    toolbarProps,
    loading = false,
    defaultColumn,
    filterTypes: passedFilterTypes,
    hooks: passedHooks,
    ...rest
}: FilteredTableProps<T>) {
    const filterTypes = useMemo(
        () => ({
            ...defaultFilterTypes,
            ...(passedFilterTypes ?? {}),
        }),
        [passedFilterTypes]
    );

    const Toolbar = useCallback(
        (props: { instance: TableInstance<T> }) => {
            return (
                <>
                    <TableToolbar
                        {...props}
                        LeftAdornment={
                            props.instance.controlled && (
                                <Box flexGrow={1}>
                                    <RefreshButton
                                        edge="start"
                                        loading={props.instance.loading}
                                        onClick={props.instance.reloadData}
                                    />
                                </Box>
                            )
                        }
                        RightAdornment={
                            props.instance.canExport && (
                                <Box ml={1.8}>
                                    <ExportButton
                                        onClick={props.instance.exportTableData}
                                        edge="end"
                                    />
                                </Box>
                            )
                        }
                        {...(toolbarProps || {})}
                    />
                    <Chipbar {...props} />
                </>
            );
        },
        [toolbarProps]
    );

    return (
        <BasicTable
            Toolbar={Toolbar}
            defaultColumn={{
                ...(defaultColumn || {}),
                disableFilters: true,
                Filter: SearchColumnFilter,
            }}
            filterTypes={filterTypes}
            loading={loading}
            hooks={[useControlledTable, ...(passedHooks || [])]}
            {...rest}
        />
    );
}

function useControlledTable<T extends {}>(hooks: Hooks<T>) {
    hooks.useInstance.push(useControlledTableInstance);
}

useControlledTable.pluginName = "useControlledTable";

function useControlledTableInstance<T extends {}>(instance: TableInstance<T>) {
    const {
        state,
        onStateChange,
        mapStateChange,
        mapStorage,
        gotoPage,
        autoResetPage = true,
        exportData,
    } = instance;

    const controlled = onStateChange !== undefined;

    let importantState = null as any; 
    if (controlled)
        importantState = mapStateChange ? mapStateChange(state) : mapStorage(state);

    const getImportantState = useGetLatest(importantState);
    const shouldAutoReset = useGetLatest(autoResetPage);

    const debouncedImportantState = useDebounced(importantState, 300);
    useEffect(() => {
        if (controlled && shouldAutoReset()) {
            onStateChange!(debouncedImportantState);
        }
    }, [controlled, onStateChange, debouncedImportantState, shouldAutoReset]);

    const { sortBy, globalFilter, filters } = state;

    useEffect(() => {
        if (controlled && shouldAutoReset()) {
            gotoPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlled, gotoPage, sortBy, globalFilter, filters, shouldAutoReset]);

    const reloadData = useCallback(() => {
        if (controlled) {
            const currentImportantState = getImportantState();
            onStateChange!(currentImportantState);
        }
    }, [controlled, getImportantState, onStateChange]);

    const exportTableData = useCallback(() => {
        if (exportData) {
            return exportData(getImportantState());
        }
        return Promise.resolve();
    }, [exportData, getImportantState]);

    instance.reloadData = reloadData;
    instance.exportTableData = exportTableData;
    instance.canExport = exportData !== undefined;
    instance.controlled = onStateChange !== undefined;

    instance.manualFilters = controlled;
    instance.manualGlobalFilter = controlled;
    instance.manualPagination = controlled;
    instance.manualSortBy = controlled;
}
