import { makeStyles, Theme } from "@material-ui/core";

export const scrollbarStyles = (theme: Theme) => ({
    "&::-webkit-scrollbar": {
        backgroundColor: theme.tmrPalette.whiteGrey,
        height: 8,
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.tmrPalette.lightGrey,
        borderRadius: theme.spacing(1) / 2,
    },
});

export default makeStyles((theme) => ({
    scrollContainer: {
        ...scrollbarStyles(theme),
    },
    cell: {
        backgroundColor: theme.palette.common.white,
        "&[data-sticky-td='true']": {
            zIndex: "5 !important",
        },
        "& > div": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            boxSizing: "border-box",
            margin: "auto 0px auto 0px",
        },
        display: "flex",
        "&:not(:last-child)": {
            borderRight: "1px solid " + theme.palette.divider,
        },
    },
    header: {},
    body: {
        "& $row:hover": {
            "& $cell": {
                backgroundColor: theme.tmrPalette.whiteGrey,
                transition: "none",
            },
        },
        "& $row:last-child": {
            "& $cell": {
                borderBottom: "none",
            },
        },
        
    },
    table: {},
    row: {
        "&:hover": {
            "& $resizer": {
                opacity: 1,
            },
        },
        "&:nth-child(even)": {
            "& $cell": {
                backgroundColor: theme.tmrPalette.whiteGrey,
                transition: "none",
            },
        }
    },
    rowWhiteGreyBckg:{
        "& $cell": {
            backgroundColor: theme.tmrPalette.whiteGrey,
        },
    },
    rowWhiteBckg:{
        "& $cell": {
            backgroundColor: "#fff",
        },
    },
    
    footer: {},
    resizer: {},
}));
